export enum PositionPriorityEnum {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  INTERNSHIP = 'INTERNSHIP',
  SEASONAL = 'SEASONAL',
}
export enum NeededJobPerMonthEnum {
  ONE_TO_FIVE = 'ONE_TO_FIVE',
  FIVE_TO_TEN = 'FIVE_TO_TEN',
  ELEVEN_TO_TWENTY = 'ELEVEN_TO_TWENTY',
  TWENTY_PLUS = 'TWENTY_PLUS',
}
export enum CandidateExperiencePriorityEnum {
  ENTRY_LEVEL = 'ENTRY_LEVEL',
  MID_LEVEL = 'MID_LEVEL',
  SENIOR_LEVEL = 'SENIOR_LEVEL',
  ALL_LEVELS = 'ALL_LEVELS',
}
export enum AdditionalServicesEnum {
  YES_CANDIDATE_SCREENING = 'YES_CANDIDATE_SCREENING',
  YES_PREMIUM_JOB_POSTING_VISIBILITY = 'YES_PREMIUM_JOB_POSTING_VISIBILITY',
  YES_BOTH = 'YES_BOTH',
  NO_STANDARD_POSTING = 'NO_STANDARD_POSTING',
}
export enum OrganizationSizeEnum {
  ONE_TO_TEN_EMPLOYEES = 'ONE_TO_TEN_EMPLOYEES',
  ELEVEN_TO_FIFTY_EMPLOYEES = 'ELEVEN_TO_FIFTY_EMPLOYEES',
  FIFTY_ONE_TO_TWO_HUNDRED_EMPLOYEES = 'FIFTY_ONE_TO_TWO_HUNDRED_EMPLOYEES',
  TWO_HUNDRED_PLUS_EMPLOYEES = 'TWO_HUNDRED_PLUS_EMPLOYEES',
}

export enum PrimaryCareersGoalEnum {
  SECURE_POSITION_IN_PROFESSIONAL_TEAM = 'SECURE_POSITION_IN_PROFESSIONAL_TEAM',
  TRANSITION_TO_COACHING = 'TRANSITION_TO_COACHING',
  ENHANCE_SKILLS_TRAINING_EDUCATION = 'ENHANCE_SKILLS_TRAINING_EDUCATION',
  EXPLORE_SPORTS_MANAGEMENT_ADMINISTRATION = 'EXPLORE_SPORTS_MANAGEMENT_ADMINISTRATION',
  OTHER = 'OTHER',
}

export enum CurrentLevelEnum {
  EXCELLENT = 'EXCELLENT',
  GOOD = 'GOOD',
  POOR = 'POOR',
  FAIR = 'FAIR',
}

export enum TypeOfSupportEnum {
  MENTORSHIP = 'MENTORSHIP',
  ACCESS_TO_EXCLUSIVE = 'ACCESS_TO_EXCLUSIVE',
  PROFESSIONAL_TRAINER = 'PROFESSIONAL_TRAINER',
  ASSISTANCE_WITH = 'ASSISTANCE_WITH',
}

export enum CareerArrearsEnum {
  TECHNICAL_SKILLS = 'TECHNICAL_SKILLS',
  COMMUNICATION_SKILLS = 'COMMUNICATION_SKILLS',
  KNOWLEDGE_OF_SPORTS = 'KNOWLEDGE_OF_SPORTS',
  PERSONAL_BRANDING = 'PERSONAL_BRANDING',
}

export enum CareerAdviceSupportEnum {
  ONE_ON_ONE = 'ONE_ON_ONE',
  COURSES = 'COURSES',
  GROUP_WORKSHOPS = 'GROUP_WORKSHOPS',
  RESOURCES = 'RESOURCES',
}

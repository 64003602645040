import { Box, ListItem, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { JobPostItem } from './JobPostsList';
import JobCompanyIcon from '../../../../components/Icons/JobCompanyIcon';
import JobPositionIcon from '../../../../components/Icons/JobPositionIcon';
import formatEnumValue from '../../../../utils/formatEnumValue';
import JobLocationIcon from '../../../../components/Icons/JobLocationIcon';
import HTMLContent from '../../../../components/HTMLcontent/HTMLcontent';
import CollapsedContent from '../../../../components/CollapsedContent/CollapsedContent';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../../enums/cookie-enum';
import {
  applicationStatusHelper,
  jobStatusHelper,
} from '../../../../utils/helper/status-helper';

interface IJobPostItem {
  jobPost: JobPostItem;
  jobPostTitleClickPath: string;
  showStatus?: boolean;
  showNewAppliedUser?: boolean;
}

const JobPostsItem: React.FC<IJobPostItem> = ({
  jobPost,
  jobPostTitleClickPath,
  showStatus = false,
  showNewAppliedUser = false,
}) => {
  const isAppliedUser = jobPost.type === 'APPLIED_USER';
  const isCompany = Cookies.get(CookieEnum.ROLE) === 'COMPANY';

  return (
    <ListItem
      sx={{
        p: { xs: '28px 0', lg: '36px 0' },
        display: 'block',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'background.dark',
        border: 'solid background.dark 1px',
      }}
    >
      <Stack gap={'28px'}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          gap={'16px'}
        >
          <Box>
            {isAppliedUser && (
              <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                borderRadius="20px"
                border={`1px solid ${applicationStatusHelper(jobPost.appliedUser).color}`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="6px 12px"
                mb={'16px'}
                width="fit-content"
              >
                <Typography
                  variant="body4"
                  textTransform="uppercase"
                  color={applicationStatusHelper(jobPost.appliedUser).color}
                >
                  {applicationStatusHelper(jobPost.appliedUser).message}
                </Typography>
              </Box>
            )}

            <Typography
              component={Link}
              color={'text.primary'}
              variant={'h2'}
              mb={'16px'}
              to={
                isCompany
                  ? `/my-job-post/${'jobpost' in jobPost ? jobPost?.jobpost?.id : jobPost?.appliedUser?.jobpost?.id}`
                  : `/${jobPostTitleClickPath}/${
                      isAppliedUser
                        ? jobPost.appliedUser.id
                        : jobPost.jobpost.id
                    }`
              }
            >
              {isAppliedUser
                ? jobPost.appliedUser.jobpost.title
                : jobPost.jobpost.title}
            </Typography>

            <Stack
              direction={'row'}
              gap={{ xs: '28px', lg: '8px', xl: '28px' }}
              mt={'16px'}
              flexWrap={'wrap'}
            >
              <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                <JobCompanyIcon />
                <Typography variant="body3">
                  {isAppliedUser
                    ? jobPost.appliedUser.jobpost.company.companyInfo
                        ?.companyName
                    : jobPost.jobpost.company.companyInfo?.companyName}
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                <JobPositionIcon />
                <Typography variant="body3">
                  {formatEnumValue(
                    isAppliedUser
                      ? jobPost.appliedUser.jobpost.position
                      : jobPost.jobpost.position
                  )}
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                <JobLocationIcon />
                <Typography variant="body3">
                  {formatEnumValue(
                    isAppliedUser
                      ? jobPost.appliedUser.jobpost.location
                      : jobPost.jobpost.location
                  )}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={'8px'}
            alignItems={'center'}
          >
            {showNewAppliedUser &&
            'jobpost' in jobPost &&
            jobPost?.jobpost?.newAppliedCount ? (
              <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                borderRadius="20px"
                border={`1px solid #727F93`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="6px 12px"
                width="fit-content"
              >
                <Typography
                  variant="body4"
                  textTransform="uppercase"
                  color={'text.secondary'}
                >
                  {'jobpost' in jobPost
                    ? `${jobPost?.jobpost?.newAppliedCount} New Application${jobPost?.jobpost?.newAppliedCount > 1 ? 's' : ''} `
                    : ''}
                </Typography>
              </Box>
            ) : (
              <Box></Box>
            )}
            {showStatus ? (
              <Box
                borderRadius="20px"
                borderColor={
                  jobStatusHelper('jobpost' in jobPost ? jobPost.jobpost : null)
                    .color
                }
                border={'1px solid'}
                color={
                  jobStatusHelper('jobpost' in jobPost ? jobPost.jobpost : null)
                    .color
                }
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="6px 12px"
                width="fit-content"
              >
                <Typography variant="body4" textTransform="uppercase">
                  {
                    jobStatusHelper(
                      'jobpost' in jobPost ? jobPost.jobpost : null
                    ).message
                  }
                </Typography>
              </Box>
            ) : (
              (isAppliedUser
                ? jobPost.appliedUser.jobpost.company?.avatar?.fileUrl
                : jobPost.jobpost.company?.avatar?.fileUrl) && (
                <img
                  src={`${
                    isAppliedUser
                      ? jobPost?.appliedUser?.jobpost?.company?.avatar?.fileUrl
                      : jobPost?.jobpost?.company?.avatar?.fileUrl
                  }`}
                  width={100}
                  height={60}
                  alt={'Company logo'}
                />
              )
            )}
          </Box>
        </Stack>

        <CollapsedContent isExpandable={false}>
          <HTMLContent
            content={
              isAppliedUser
                ? jobPost.appliedUser.jobpost.description
                : jobPost.jobpost.description
            }
          />
        </CollapsedContent>

        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent={{ xs: 'none', lg: 'space-between' }}
          color={'text.primary'}
          gap={{ xs: '24px', lg: 0 }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
            <Typography variant="body3" fontSize={'12px'}>
              Industry:
            </Typography>
            {(isAppliedUser
              ? jobPost.appliedUser.jobpost.industries.length > 0
              : jobPost.jobpost.industries.length > 0) && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0 }}>
                {(isAppliedUser
                  ? jobPost.appliedUser.jobpost.industries
                  : jobPost.jobpost.industries
                ).map(({ industry, id }, index) => (
                  <Typography
                    key={id}
                    component={'span'}
                    variant="body4"
                    fontSize={'14px'}
                    lineHeight={'normal'}
                  >
                    {industry}
                    {index <
                      (isAppliedUser
                        ? jobPost.appliedUser.jobpost.industries.length
                        : jobPost.jobpost.industries.length) -
                        1 && ' /\u00A0'}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
            <Typography variant="body3" fontSize={'12px'}>
              Salary:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0 }}>
              <Typography
                component={'span'}
                variant="body4"
                fontSize={'14px'}
                lineHeight={'normal'}
              >
                {`$${
                  isAppliedUser
                    ? jobPost.appliedUser.jobpost.minSalary
                    : jobPost.jobpost.minSalary
                } - $${
                  isAppliedUser
                    ? jobPost.appliedUser.jobpost.minSalary
                    : jobPost.jobpost.maxSalary
                } ${formatEnumValue(
                  isAppliedUser
                    ? jobPost.appliedUser.jobpost.salaryPeriod
                    : jobPost.jobpost.salaryPeriod,
                  false
                )}, commensurate with experience.`}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </ListItem>
  );
};

export default JobPostsItem;

import * as yup from 'yup';

export enum RepostMethod {
  ON_WALL = 'ON_WALL',
  BY_MESSAGE = 'BY_MESSAGE',
}

export const createPostSchema = yup.object().shape({
  content: yup.string().required('Content is required'),
  title: yup.string().nullable(),
  repostedPostId: yup.string().nullable(),
});

export const repostSchema = yup.object().shape({
  title: yup.string().nullable(),
  repostedPostId: yup.string().nullable(),
  shareOption: yup
    .string()
    .oneOf(
      [RepostMethod.ON_WALL, RepostMethod.BY_MESSAGE],
      'Invalid share option'
    )
    .required('Share option is required'),
});

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  IApproveIntroductionResponse,
  ICancelIntroductionRequest,
  ICancelIntroductionResponse,
  ICreateIntroductionRequest,
  ICreateIntroductionResponse,
  IGetIntroductionByIdRequest,
  IGetIntroductionByIdResponse,
} from '../../interfaces/friend-introduction.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const friendIntroductionApi = createApi({
  reducerPath: 'friendIntroductionApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_FRIEND_MICROSERVICE_API_URL}/introduction`
  ),
  tagTypes: ['friendIntroduction'],

  endpoints: builder => ({
    getIntroductionById: builder.query<
      IGetIntroductionByIdResponse,
      IGetIntroductionByIdRequest
    >({
      query: ({ id }) => `/${id}`,
      providesTags: ['friendIntroduction'],
    }),
    createIntroduction: builder.mutation<
      ICreateIntroductionResponse,
      ICreateIntroductionRequest
    >({
      query: data => ({
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['friendIntroduction'],
    }),
    approveIntroduction: builder.mutation<IApproveIntroductionResponse, string>(
      {
        query: introductionId => ({
          url: '/approve/' + introductionId,
          method: 'PUT',
        }),
        invalidatesTags: ['friendIntroduction'],
      }
    ),
    cancelIntroduction: builder.mutation<
      ICancelIntroductionResponse,
      ICancelIntroductionRequest
    >({
      query: ({ introductionId, reason }) => ({
        url: '/' + introductionId,
        method: 'DELETE',
        body: { reason },
      }),
      invalidatesTags: ['friendIntroduction'],
    }),
  }),
});

export const {
  useGetIntroductionByIdQuery,
  useCreateIntroductionMutation,
  useApproveIntroductionMutation,
  useCancelIntroductionMutation,
} = friendIntroductionApi;

export default friendIntroductionApi;

import { SvgIcon, SvgIconProps } from '@mui/material';

const SettingsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 18, height: 18, fill: 'none' }}
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M3.21484 4.5H10.2863"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12.8574 4.5L14.786 4.5"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M10.2852 4.50001C10.2852 4.841 10.4206 5.16803 10.6617 5.40915C10.9029 5.65026 11.2299 5.78572 11.5709 5.78572C11.9119 5.78572 12.2389 5.65026 12.48 5.40915C12.7211 5.16803 12.8566 4.841 12.8566 4.50001C12.8566 4.15902 12.7211 3.83199 12.48 3.59087C12.2389 3.34975 11.9119 3.21429 11.5709 3.21429C11.2299 3.21429 10.9029 3.34975 10.6617 3.59087C10.4206 3.83199 10.2852 4.15902 10.2852 4.50001Z"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71484 9H14.7863"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M3.21484 9L5.14342 9"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.14258 9.00001C5.14258 9.341 5.27804 9.66803 5.51915 9.90915C5.76027 10.1503 6.0873 10.2857 6.42829 10.2857C6.76929 10.2857 7.09631 10.1503 7.33743 9.90915C7.57855 9.66803 7.71401 9.341 7.71401 9.00001C7.71401 8.65902 7.57855 8.33199 7.33743 8.09087C7.09631 7.84975 6.76929 7.71429 6.42829 7.71429C6.0873 7.71429 5.76027 7.84975 5.51915 8.09087C5.27804 8.33199 5.14258 8.65902 5.14258 9.00001Z"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.21484 13.5H10.2863"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12.8574 13.5L14.786 13.5"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M10.2852 13.5C10.2852 13.841 10.4206 14.168 10.6617 14.4091C10.9029 14.6503 11.2299 14.7857 11.5709 14.7857C11.9119 14.7857 12.2389 14.6503 12.48 14.4091C12.7211 14.168 12.8566 13.841 12.8566 13.5C12.8566 13.159 12.7211 12.832 12.48 12.5909C12.2389 12.3498 11.9119 12.2143 11.5709 12.2143C11.2299 12.2143 10.9029 12.3498 10.6617 12.5909C10.4206 12.832 10.2852 13.159 10.2852 13.5Z"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SettingsIcon;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  ICareerResponse,
  ICareerUpdateRequest,
  ICreateCareerRequest,
  ILeagueBySportNameResponse,
  ITeamsByLeagueNameResponse,
} from '../../interfaces/career.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const careerApi = createApi({
  reducerPath: 'careerApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/career`
  ),
  tagTypes: ['career'],

  endpoints: builder => ({
    getLeagueBySportName: builder.query<ILeagueBySportNameResponse[], string>({
      query: name => `/leagues/sportName/${name}`,
      providesTags: ['career'],
    }),
    getTeamsByLeagueName: builder.query<ITeamsByLeagueNameResponse[], string>({
      query: name => `/teams/leagueName/${name}`,
      providesTags: ['career'],
    }),
    createCareer: builder.mutation<ICareerResponse, ICreateCareerRequest>({
      query: data => ({
        url: ``,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['career'],
    }),
    updateCareer: builder.mutation<
      ICareerResponse,
      { id: string; data: ICareerUpdateRequest }
    >({
      query: ({ id, data }) => ({
        url: '/' + id,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['career'],
    }),
    deleteCareer: builder.mutation<ICareerResponse, string>({
      query: id => ({
        url: '/' + id,
        method: 'DELETE',
      }),
      invalidatesTags: ['career'],
    }),
  }),
});

export const {
  useGetLeagueBySportNameQuery,
  useGetTeamsByLeagueNameQuery,
  useCreateCareerMutation,
  useUpdateCareerMutation,
  useDeleteCareerMutation,
} = careerApi;

export default careerApi;

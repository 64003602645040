import { Box } from '@mui/material';
import GetStarted from '../../../components/Auth/VerifyAccount/GetStarted/GetStarted';
import Header from '../../../components/Header/Header';
import { HeaderTypeEnum } from '../../../enums/header-type';

const GetStartedPage = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'1.75rem'}
      pb={'2rem'}
      height={'100dvh'}
    >
      <Header type={HeaderTypeEnum.VERIFY_ACCOUNT} />
      <Box my={'auto'}>
        <GetStarted />
      </Box>
    </Box>
  );
};

export default GetStartedPage;

import { useMediaQuery, ListItem, IconButton } from '@mui/material';
import { IFriend } from '../../../interfaces/friend.interface';
import { useUnblockMyFriendMutation } from '../../../store/apiSlice/friendApi';
import { errorHelper } from '../../../utils/helper/error-helper';
import ConnectionAvatarWithText from '../../../pages/ConnectionsPage/components/ConnectionsTabs/ConnectionList/ConnectionAvatarWithText';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import CustomButton from '../../MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import UnblockIcon from '../../Icons/UnblockIcon';

interface BlockedUsersItemProps {
  blockedFriend: IFriend;
  onUnblockSuccess: (friendUserId: string) => void;
  onUnblockError: (error: string) => void;
}

const BlockedUsersItem: React.FC<BlockedUsersItemProps> = ({
  blockedFriend,
  onUnblockSuccess,
  onUnblockError,
}) => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [unblockMyFriendMutation, { isLoading }] = useUnblockMyFriendMutation();

  const handleUnblockMyFriend = async () => {
    try {
      await unblockMyFriendMutation(blockedFriend.userId).unwrap();
      onUnblockSuccess(blockedFriend.userId);
    } catch (error) {
      onUnblockError(errorHelper(error));
      console.error('Unblocking failed: ', error);
    }
  };

  return (
    <ListItem
      alignItems="center"
      sx={{ justifyContent: 'space-between', p: '0px' }}
    >
      <ConnectionAvatarWithText connection={blockedFriend} />
      {matches ? (
        <CustomButton
          variant="outlined"
          variantType={ButtonTypeEnum.SECONDARY}
          onClick={handleUnblockMyFriend}
          disabled={isLoading}
          sx={{ p: '13px 16px' }}
        >
          unblock
        </CustomButton>
      ) : (
        <IconButton
          disabled={isLoading}
          onClick={handleUnblockMyFriend}
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'background.lightDark',
          }}
        >
          <UnblockIcon />
        </IconButton>
      )}
    </ListItem>
  );
};

export default BlockedUsersItem;

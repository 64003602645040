import { useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Skeleton } from '@mui/material';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import JobPostsList from '../../../../JobPostsPage/components/JobPostsList/JobPostsList';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import {
  IAppliedUser,
  IJobpost,
} from '../../../../../interfaces/jobpost.interface';
import { IListSortPops } from '../../../../../components/ListToolbar/ListSort';
import ListToolBar from './ListToolbar/ListToolBar';
import SkeletonList from '../../../../../components/MUIComponents/SkeletonList';

interface JobPostsProps {
  listToolBarSubtitle: string;
  jobPosts: { appliedUser: IAppliedUser }[] | { jobpost: IJobpost }[];
  sortMenuItems: IListSortPops['sortMenuItems'];
  jobPostTitleClickPath: string;
  isLoadMore: boolean;
  isLoading: boolean;
  showStatus?: boolean;
  showNewAppliedUser?: boolean;
}

const JobPosts = ({
  listToolBarSubtitle,
  jobPosts,
  sortMenuItems,
  jobPostTitleClickPath,
  isLoadMore,
  isLoading,
  showStatus = false,
  showNewAppliedUser = false,
}: JobPostsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleLoadMore = () => {
    setSearchParams(prev => {
      const currentPage = parseInt(prev.get('page') ?? '1', 10);
      const nextPage = currentPage + 1;
      prev.set('page', nextPage.toString());
      return prev;
    });
  };

  return (
    <>
      <ListToolBar
        sortMenuItems={sortMenuItems}
        sortMenuLabel={'Show:'}
        subTitle={listToolBarSubtitle}
        paddingBottom={'16px'}
      />
      {isLoading && (
        <SkeletonList
          sx={{
            width: '100%',
            marginTop: '20px',
            minHeight: {
              xs: '310px',
              lg: '420px',
            },
          }}
          count={3}
        />
      )}
      {jobPosts.length > 0 && (
        <JobPostsList
          jobPosts={jobPosts}
          jobPostTitleClickPath={jobPostTitleClickPath}
          showStatus={showStatus}
          showNewAppliedUser={showNewAppliedUser}
        />
      )}
      {isLoadMore && (
        <CustomButton
          fullWidth
          sx={{ width: '100%', color: 'text.primary', mt: '36px' }}
          variant="outlined"
          variantType={ButtonTypeEnum.SECONDARY}
          onClick={handleLoadMore}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Load more'}
        </CustomButton>
      )}
    </>
  );
};

export default JobPosts;

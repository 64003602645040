import { Box, useMediaQuery } from '@mui/material';
import SendInvite from '../../../components/SendInvite/SendInvite';
import SidebarLayout from '../../../layouts/SidebarLayout';
import ConnectionsSidebar from '../../ConnectionsPage/components/ConnectionsSidebar/ConnectionsSidebar';
import SectionLayout from '../../../layouts/SectionLayout';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const InvitationsPage = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <SidebarLayout
      sidebar={<ConnectionsSidebar />}
      flexDirection={matches ? 'row' : 'column'}
    >
      <Box display="flex" flexDirection="column" flexGrow="1">
        <SectionLayout>
          <SendInvite />
        </SectionLayout>
      </Box>
    </SidebarLayout>
  );
};

export default InvitationsPage;

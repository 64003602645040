import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useGetMyChats from '../../../../hooks/chat/useGetMyChats';
import useGetMyPersonaAndGroupChats from '../../../../hooks/chat/useGetMyPersonaAndGroupChats';
import { IChat } from '../../../../store/apiSlice/chat/chatApi';
import {
  getChatsLoaded,
  getSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import { IGroupChat } from '../../../../store/apiSlice/chat/groupApi';
import Chat from './Chat';

interface IChatListProps {
  isHighlightSelectedChat?: boolean;
  isBothTypeOfChats?: boolean;
  searchValue: string;
  isShowLastMessage?: boolean;
  setSearchValue: (_: string) => void;
  handleSelectChat: (chat: IChat | IGroupChat) => void;
}

const ChatList = ({
  isHighlightSelectedChat = true,
  isBothTypeOfChats = false,
  searchValue,
  isShowLastMessage = true,
  handleSelectChat,
}: IChatListProps) => {
  const selectedChat = useSelector(getSelectedChat);
  const chatsLoaded = useSelector(getChatsLoaded);

  const singleTypeChats = useGetMyChats({ searchValue });
  const bothTypeChats = useGetMyPersonaAndGroupChats({ searchValue });

  const { myChats } = useMemo(() => {
    return !isBothTypeOfChats ? singleTypeChats : bothTypeChats;
  }, [singleTypeChats, bothTypeChats, isBothTypeOfChats]);

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        scrollbarWidth: 'none',
      }}
      flexGrow={1}
      p={'0 1rem'}
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
      gap={'0.375rem'}
    >
      {chatsLoaded &&
        myChats?.map(chat => (
          <Box
            bgcolor={
              isHighlightSelectedChat && selectedChat?.id === chat.id
                ? 'background.light'
                : ''
            }
            width={'100%'}
            key={chat.id}
            onClick={() => handleSelectChat(chat)}
          >
            <Chat chat={chat} isShowLastMessage={isShowLastMessage} />
          </Box>
        ))}
    </Box>
  );
};

export default ChatList;

import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SubscriptionPlanCard from './components/SubscriptionPlanCard';
import { getIncludedFeaturesHelper } from '../../utils/helper/subscription-helper';
import { CompanyProductDefaultNameEnum } from '../../enums/subscription-enum';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../../store/selectors/authSelector';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../enums/cookie-enum';
import { config } from '../../config/config';
import SubscriptionPayment from './components/SubscriptionPayment';

const SubscriptionPaymentPage = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const authData = useSelector(selectAuthData);
  const { product, clientSecret } = location.state || {};

  if (!product || !clientSecret) {
    return null;
  }

  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

  const options = {
    clientSecret,
  };

  const isCompany =
    authData?.user?.role === 'COMPANY' ||
    Cookies.get(CookieEnum.ROLE) === 'COMPANY';

  if (!isCompany) {
    return null;
  }

  return (
    <Container disableGutters maxWidth={false} sx={{ m: 0 }}>
      <Grid
        container
        flexDirection={mobileView ? 'column' : 'row'}
        gap="22px"
        padding={'0'}
      >
        <Grid item width="100%" maxWidth={mobileView ? '100%' : '314px'}>
          <SubscriptionPlanCard
            sx={{
              backgroundColor: {
                xs: theme.palette.background.light,
                lg: 'white',
              },
            }}
            isFooterEnabled={false}
            key={product.id}
            planName={product.name}
            price={`${product.price}`}
            period={`${
              product.interval === 'year'
                ? 'annual'
                : product.interval === 'month' && product.intervalCount === 6
                  ? 'semi-annual'
                  : 'quarterly'
            }`}
            asDropdown
            maxCardWidth={mobileView ? '100%' : '314px'}
            showBorder={false}
            pricePlanAudience={product.description}
            isMostPopular={product.isMostPopular}
            includedFeatures={getIncludedFeaturesHelper(product.name)}
            showBack={true}
          />
        </Grid>
        <Grid
          item
          flex={'1'}
          maxWidth={mobileView ? '100%' : '648px'}
          p={mobileView ? '28px 16px' : '36px 36px 48px'}
          bgcolor={theme.palette.background.white}
        >
          <Elements stripe={stripePromise} options={options}>
            <SubscriptionPayment
              clientSecret={clientSecret}
              planName={product.name}
              price={product.price}
            />
          </Elements>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubscriptionPaymentPage;

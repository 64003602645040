import { Box, MenuItem, SxProps, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useModal } from '../../../../../hooks/useModal';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import BaseModal from '../../../../../components/MUIComponents/BaseModal';
import IconWithBackground from '../../../../../components/MUIComponents/IconWithBackground';
import CloseX from '../../../../../components/Icons/CloseX';

interface IBaseMenuItemProps {
  icon: ReactNode;
  title: string;
  onSelected: () => void;
  sx?: SxProps;
  modalSx?: SxProps;
}
const BaseMenuItem: React.FC<PropsWithChildren<IBaseMenuItemProps>> = ({
  icon,
  title,
  children,
  onSelected,
  sx = {},
  modalSx = {},
}) => {
  const { toggleModal, isOpen, openModal } = useModal();
  const { theme } = useThemeContext();

  const chatActionsMenuItemSx = useMemo(
    () => ({
      p: '0.75rem',
      gap: '0.375rem',
      '&:hover': {
        '*': {
          color: theme.palette.button.primary,
          stroke: theme.palette.button.primary,
        },
        backgroundColor: 'inherit',
      },
    }),
    [theme]
  );

  return (
    <MenuItem
      divider
      sx={chatActionsMenuItemSx}
      onClick={() => {
        openModal();
      }}
    >
      {icon}
      <Typography variant="body4" color={'text.primary'}>
        {title}
      </Typography>

      <BaseModal
        boxSx={{ p: '3rem 2.25rem', ...modalSx }}
        isOpen={isOpen}
        toggle={toggleModal}
        disableContentMargin
        disableDefaultFooter
        disableEscapeButton
      >
        <Box
          position={'absolute'}
          top={'1rem'}
          right={'1rem'}
          onClick={onSelected}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <IconWithBackground size={'2.5rem'} icon={<CloseX />} />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          textAlign={'center'}
          gap={'2.25rem'}
          maxWidth={'40%'}
          mx={'auto'}
          sx={sx}
        >
          {children}
        </Box>
      </BaseModal>
    </MenuItem>
  );
};

export default BaseMenuItem;

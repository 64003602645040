import { Box, useMediaQuery } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { colors } from '../../../../../../theme/theme';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';
import { a11yProps } from '../../../../../../utils/a11yProps';

const STATUS_FILTERS: Record<
  'all' | 'open' | 'closed' | 'draft' | 'archived',
  string
> = {
  all: 'ALL POSTS',
  open: 'OPEN',
  closed: 'CLOSED',
  draft: 'DRAFT',
  archived: 'ARCHIVED',
};

const StatusFilter = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialQuery = searchParams.get('status') || 'all';

  const handleSortChange = (query: string) => {
    setSearchParams(prevParams => {
      prevParams.set('status', query);
      return prevParams;
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const selectedStatus = Object.keys(STATUS_FILTERS)[
      newValue
    ] as keyof typeof STATUS_FILTERS;

    setSelectedTab(newValue);
    handleSortChange(selectedStatus);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="start"
      margin="1px 0"
      sx={{
        width: '100%',
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="profile tabs"
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        sx={{
          backgroundColor: '#FFFFFF',
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {(
          Object.keys(STATUS_FILTERS) as Array<keyof typeof STATUS_FILTERS>
        ).map((key, index) => (
          <Tab
            key={key}
            {...a11yProps(index)}
            label={STATUS_FILTERS[key]}
            wrapped
            sx={{
              height: 40,
              padding: '22px 16px',
              flex: { xs: 'none', lg: 1 },
              borderRadius: '1px',
              whiteSpace: 'nowrap',
              color: '#142237',
              fontSize: '10px',
              fontWeight: 800,
              '&.Mui-selected': {
                bgcolor: '#F5431D',
                color: colors.white,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default StatusFilter;

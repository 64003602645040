import { Box, Collapse, Typography, useMediaQuery } from '@mui/material';
import StatFilter from './StatFilter';
import StatLabel from './StatLabel';
import React, { useEffect, useState } from 'react';
import { IAnalyticFilterValues } from '../../../interfaces/analytic.interface';
import {
  useCountOfApprovedCandidatesQuery,
  useCountOfCandidatesByDateQuery,
  useCountOfJobPostsQuery,
  useCountOfRejectedCandidatesByDateQuery,
} from '../../../store/apiSlice/analitics/analiticsApi';
import {
  addDays,
  differenceInCalendarDays,
  format,
  startOfDay,
} from 'date-fns';
import SnackbarCustom from '../../../components/MUIComponents/SnackbarCustom';
import Loader from '../../../components/MUIComponents/Loader';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../utils/helper/error-helper';
import {
  Control,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormWatch,
} from 'react-hook-form';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import ArrowDownIcon from '../../Icons/ArrowDownIcon';
import AnalyticsChart from './AnalyticsChart';
import {
  endDateFormatted,
  startDateFormatted,
} from '../../../utils/date-time-formats.ts/formatDate';

const defaultDataSets = [
  {
    label: 'Candidates',
    backgroundColor: '#1866DB',
    borderColor: '#1866DB',
    data: [],
  },
  {
    label: 'Rejected',
    backgroundColor: '#F5431D',
    borderColor: '#F5431D',
    data: [],
  },
  {
    label: 'Accepted',
    backgroundColor: '#28BA2E',
    borderColor: '#28BA2E',
    data: [],
  },
  {
    label: 'Job Posts',
    backgroundColor: '#FFC620',
    borderColor: '#FFC620',
    data: [],
  },
];

interface IAnalyticsChartSectionProps {
  control: Control<IAnalyticFilterValues>;
  handleSubmit: UseFormHandleSubmit<IAnalyticFilterValues>;
  reset: UseFormReset<IAnalyticFilterValues>;
  watch: UseFormWatch<IAnalyticFilterValues>;
  errors: any;
  isValid: boolean;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  filters: IAnalyticFilterValues | null;
  setFilters: (filters: IAnalyticFilterValues | null) => void;
}
const AnalyticsChartSection = (props: IAnalyticsChartSectionProps) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    errors,
    isValid,
    loading,
    setLoading,
    filters,
    setFilters,
  } = props;

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      borderColor: string;
      data: number[];
    }[];
  }>({
    labels: [],
    datasets: defaultDataSets,
  });

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const [isScoreExpanded, setIsScoreExpanded] = useState(false);
  const toggleExpand = () => {
    setIsScoreExpanded(prevState => !prevState);
  };

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const {
    data: candidatesData,
    error: candidatesError,
    isLoading: candidatesLoading,
  } = useCountOfCandidatesByDateQuery(
    filters
      ? {
          startDate: new Date(filters.startDate).toISOString(),
          endDate: new Date(filters.endDate).toISOString(),
        }
      : {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        }
  );

  const {
    data: approvedData,
    error: approvedError,
    isLoading: approvedLoading,
  } = useCountOfApprovedCandidatesQuery(
    filters
      ? {
          startDate: new Date(filters.startDate).toISOString(),
          endDate: new Date(filters.endDate).toISOString(),
        }
      : {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        }
  );

  const {
    data: rejectedData,
    error: rejectedError,
    isLoading: rejectedLoading,
  } = useCountOfRejectedCandidatesByDateQuery(
    filters
      ? {
          startDate: new Date(filters.startDate).toISOString(),
          endDate: new Date(filters.endDate).toISOString(),
        }
      : {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        }
  );

  const {
    data: jobPostsData,
    error: jobPostsError,
    isLoading: jobPostsLoading,
  } = useCountOfJobPostsQuery(
    filters
      ? {
          startDate: new Date(filters.startDate).toISOString(),
          endDate: new Date(filters.endDate).toISOString(),
        }
      : {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        }
  );

  const generateLabelsByInterval = (startDate: Date, endDate: Date) => {
    const labels = [];
    let currentDate = startOfDay(startDate);

    const dayInterval = calculateDayInterval(startDate, endDate);
    const daysDifference = differenceInCalendarDays(endDate, startDate);

    for (let i = 0; i <= daysDifference; i += dayInterval) {
      labels.push(format(currentDate, 'dd MMM yyyy'));
      currentDate = addDays(currentDate, dayInterval);
    }

    return labels;
  };

  const calculateDayInterval = (startDate: Date, endDate: Date): number => {
    const daysDifference = differenceInCalendarDays(endDate, startDate);

    if (daysDifference <= 7) {
      return 1;
    } else if (daysDifference <= 30) {
      return 1;
    } else if (daysDifference <= 90) {
      return 5;
    } else if (daysDifference <= 365) {
      return 7;
    } else {
      return 7;
    }
  };

  useEffect(() => {
    if (filters) {
      setLoading(
        candidatesLoading ||
          approvedLoading ||
          rejectedLoading ||
          jobPostsLoading
      );

      if (candidatesError || approvedError || rejectedError || jobPostsError) {
        setSnackbarMessage(
          errorHelper(
            candidatesError || approvedError || rejectedError || jobPostsError
          )
        );
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
      } else {
        const labels = generateLabelsByInterval(
          new Date(filters.startDate),
          new Date(filters.endDate)
        );

        const newData = {
          labels: labels,
          datasets: [
            {
              label: 'Candidates',
              backgroundColor: '#1866DB',
              borderColor: '#1866DB',
              data: filters.numberOfCandidates
                ? candidatesData?.data || []
                : [],
            },
            {
              label: 'Rejected',
              backgroundColor: '#F5431D',
              borderColor: '#F5431D',
              data: filters.numberOfRejectedCandidates
                ? rejectedData?.data || []
                : [],
            },
            {
              label: 'Accepted',
              backgroundColor: '#28BA2E',
              borderColor: '#28BA2E',
              data: filters.numberOfAcceptedCandidates
                ? approvedData?.data || []
                : [],
            },
            {
              label: 'Job Posts',
              backgroundColor: '#FFC620',
              borderColor: '#FFC620',
              data: filters.numberOfJobPosting ? jobPostsData?.data || [] : [],
            },
          ],
        };
        setChartData(newData);
      }
    }
  }, [
    filters?.numberOfJobPosting,
    filters?.numberOfAcceptedCandidates,
    filters?.numberOfRejectedCandidates,
    filters?.numberOfCandidates,
    filters?.startDate,
    filters?.endDate,
    candidatesData,
    approvedData,
    rejectedData,
    jobPostsData,
    candidatesError,
    approvedError,
    rejectedError,
    jobPostsError,
    candidatesLoading,
    approvedLoading,
    rejectedLoading,
    jobPostsLoading,
  ]);

  const onApplyFilters = async (data: IAnalyticFilterValues) => {
    setFilters(data);
  };
  const resetFilters = () => {
    reset({
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      numberOfCandidates: false,
      numberOfAcceptedCandidates: false,
      numberOfJobPosting: false,
      numberOfRejectedCandidates: false,
    });

    setFilters(null);

    setChartData({
      labels: [],
      datasets: defaultDataSets,
    });
  };

  return (
    <Box width="100%" minHeight="650px" bgcolor="#fff">
      <Box
        width="100%"
        display="flex"
        flexDirection={isMediumScreen ? 'column' : 'row'}
        gap={
          filters?.numberOfCandidates ||
          filters?.numberOfRejectedCandidates ||
          filters?.numberOfAcceptedCandidates ||
          filters?.numberOfJobPosting
            ? '12px'
            : '0'
        }
        justifyContent="space-between"
        alignItems={isMediumScreen ? 'flex-start' : 'center'}
        borderBottom="1px solid #E8EBEF"
        padding={isSmallScreen ? ' 16px' : '22px 36px'}
      >
        <Typography
          sx={{
            fontSize: '20px',
          }}
          variant={'h3'}
        >
          Stats
        </Typography>
        <Box
          display="flex"
          flexWrap={isMediumScreen ? 'wrap' : 'nowrap'}
          alignItems={isMediumScreen ? 'flex-end' : 'center'}
          gap={isMediumScreen ? '12px' : '22px'}
        >
          {filters?.numberOfCandidates && (
            <StatLabel text="Candidates" color="#1866DB" />
          )}
          {filters?.numberOfRejectedCandidates && (
            <StatLabel text="Rejected" color="#F5431D" />
          )}
          {filters?.numberOfAcceptedCandidates && (
            <StatLabel text="Accepted" color="#28BA2E" />
          )}
          {filters?.numberOfJobPosting && (
            <StatLabel text="Job Posts" color="#FFC620" />
          )}
        </Box>
      </Box>

      {mobileView && (
        <Box
          display={'flex'}
          sx={{
            gap: '22px',
          }}
        >
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            padding={isSmallScreen ? '22px 16px' : '22px 36px'}
            borderBottom={'1px solid #E8EBEF'}
          >
            <Typography
              component="h3"
              variant="h3"
              color={'text.primary'}
              textAlign={'left'}
              display={'flex'}
              alignItems={'center'}
            >
              Filters
            </Typography>
            <Box display="flex" gap="12px" onClick={toggleExpand}>
              <ArrowDownIcon
                sx={{
                  transform: isScoreExpanded ? 'rotate(180deg)' : '',
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      {mobileView ? (
        <Collapse in={isScoreExpanded}>
          <StatFilter
            control={control}
            onApplyFilters={onApplyFilters}
            handleSubmit={handleSubmit}
            resetFilters={resetFilters}
            errors={errors}
            isValid={isValid}
            loading={loading}
          />
        </Collapse>
      ) : (
        <Box display="flex" flexDirection="row" width="100%" minHeight="570px">
          <StatFilter
            control={control}
            onApplyFilters={onApplyFilters}
            handleSubmit={handleSubmit}
            resetFilters={resetFilters}
            errors={errors}
            isValid={isValid}
            loading={loading}
          />
          {loading ? (
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              minHeight={'570px'}
              height={'100%'}
            >
              <Loader size={50} color={'primary'} />
            </Box>
          ) : (
            <Box
              id={mobileView ? 'none' : 'pdf-graphs'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <AnalyticsChart chartData={chartData} />
            </Box>
          )}
        </Box>
      )}

      {mobileView && (
        <Box>
          {loading ? (
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              minHeight={'570px'}
              height={'100%'}
            >
              <Loader size={50} color={'primary'} />
            </Box>
          ) : (
            <Box
              id={mobileView ? 'pdf-graphs' : 'none'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <AnalyticsChart chartData={chartData} />
            </Box>
          )}
        </Box>
      )}

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default AnalyticsChartSection;

import React from 'react';
import { IFriend } from '../../../../../interfaces/friend.interface';
import ConnectionAvatarWithText from './ConnectionAvatarWithText';
import { ListItem } from '@mui/material';
import ConnectionActions from './ConnectionActions';
import { FriendRequest } from '../../../../../interfaces/friend-invitation.interface';
import {
  useBlockMyFriendMutation,
  useDeleteFriendshipMutation,
} from '../../../../../store/apiSlice/friendApi';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import {
  useApproveFriendInvitationMutation,
  useCancelFriendInvitationMutation,
  useDeleteInviteToFriendMutation,
  useInviteToFriendMutation,
} from '../../../../../store/apiSlice/friendInvitationApi';
import { UserDataFiledEnum } from '../../../../../enums/user-data-field-enum';
import {
  ConnectionPrimaryButtonLabelEnum,
  ConnectionSecondaryButtonLabelEnum,
} from '../../../../../enums/button-type-enum';
import {
  useCreateChatMutation,
  useGetAllMyChatsQuery,
} from '../../../../../store/apiSlice/chat/chatApi';
import { useDispatch } from 'react-redux';
import {
  setChatsLoaded,
  setChatState,
  setChatType,
  setSelectedChat,
} from '../../../../../store/apiSlice/chat/chatSlice';
import { useNavigate } from 'react-router-dom';
import { ChatStateEnum, ChatTypeEnum } from '../../../../../enums/chat-enum';

interface IConnectionItemProps {
  activeTab: number;
  connection: IFriend | FriendRequest;
  matches: boolean;
  userDataField: UserDataFiledEnum;
  setConnections: React.Dispatch<
    React.SetStateAction<IFriend[] | FriendRequest[]>
  >;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >;
  setNotificationData: React.Dispatch<React.SetStateAction<IFriend>>;
}

const ConnectionItem: React.FC<IConnectionItemProps> = ({
  activeTab,
  connection,
  matches,
  userDataField,
  setConnections,
  setPage,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  setNotificationData,
}) => {
  const { data: myChats } = useGetAllMyChatsQuery();
  const [createChat, { isLoading: isCreatingChat }] = useCreateChatMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRemoveLocaly = (friendUserId: string) => {
    setConnections(prev => {
      if (activeTab === 0 || activeTab === 3) {
        return (prev as IFriend[]).filter(
          (connection: IFriend) => connection.userId !== friendUserId
        );
      } else {
        return (prev as FriendRequest[]).filter(
          (connection: FriendRequest) =>
            connection.friendUserId !== friendUserId
        );
      }
    });

    setPage(1);
  };

  const handleMessage = (friendUserId: string) => {
    dispatch(setChatState(ChatStateEnum.CHAT));

    if (myChats)
      for (const chat of myChats)
        if (chat.withUserId === friendUserId) {
          dispatch(setChatType(ChatTypeEnum.PERSONAL));
          dispatch(setSelectedChat(chat));
          dispatch(setChatsLoaded());
          return navigate('/chat');
        }

    createChat(friendUserId)
      .unwrap()
      .then(chat => {
        dispatch(setSelectedChat(chat));
        navigate('/chat');
      })
      .catch(console.log);
  };

  const [deleteFriendshipMutation, { isLoading: isDeletingFriendship }] =
    useDeleteFriendshipMutation();

  const handleDeleteFriendship = async (friendUserId: string) => {
    try {
      await deleteFriendshipMutation(friendUserId).unwrap();

      handleRemoveLocaly(friendUserId);

      setSnackbarOpen(true);
      setSnackbarMessage(`Successfully deleted!`);
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Deleting friend failed: ', error);
    }
  };

  const [blockMyFriendMutation, { isLoading: isBlocking }] =
    useBlockMyFriendMutation();

  const handleBlockMyFriend = async (friendUserId: string) => {
    try {
      await blockMyFriendMutation(friendUserId).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage(`Successfully blocked!`);
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Blocking failed: ', error);
    }
  };

  const [inviteToFriendMutation, { isLoading: isInviting }] =
    useInviteToFriendMutation();

  const handleInvite = async (friendUserId: string) => {
    try {
      await inviteToFriendMutation({ friendUserId }).unwrap();

      handleRemoveLocaly(friendUserId);

      setSnackbarOpen(true);
      setSnackbarMessage('Invite successful');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Invite failed: ', error);
    }
  };

  const [cancelFriendInvitation, { isLoading: isDeclining }] =
    useCancelFriendInvitationMutation();

  const handleDecline = async (
    friendInviteToken: string,
    friendData: IFriend
  ) => {
    try {
      await cancelFriendInvitation({ friendInviteToken }).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('connection request is declined.');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setNotificationData(friendData);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to decline invitation: ', error);
    }
  };

  const [approveFriendInvitation, { isLoading: isAccepting }] =
    useApproveFriendInvitationMutation();

  const handleAccept = async (
    friendInviteToken: string,
    friendData: IFriend
  ) => {
    try {
      await approveFriendInvitation({ friendInviteToken });

      setSnackbarOpen(true);
      setSnackbarMessage('is now in your contacts list. Send him a message.');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setNotificationData(friendData);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to accept invitation: ', error);
    }
  };

  const [deleteInviteToFriend, { isLoading: isDeletingInviteToFriend }] =
    useDeleteInviteToFriendMutation();

  const handleDeleteInviteToFriend = async (
    friendUserId: string,
    friendData: IFriend
  ) => {
    try {
      await deleteInviteToFriend({ friendUserId });

      handleRemoveLocaly(friendUserId);

      setSnackbarOpen(true);
      setSnackbarMessage('Invitation canceled');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setNotificationData(friendData);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to cancel invitation: ', error);
    }
  };

  const isFriendRequest = (
    item: IFriend | FriendRequest
  ): item is FriendRequest => 'friendInviteToken' in item;

  const renderConnectionActions = (connection: IFriend | FriendRequest) => {
    switch (activeTab) {
      case 0:
        return (
          <ConnectionActions
            matches={matches}
            isLoading={
              isInviting || isBlocking || isDeletingFriendship || isCreatingChat
            }
            onPrimaryAction={() => handleMessage(connection.userId)}
            onMoreRemove={() => handleDeleteFriendship(connection.userId)}
            onMoreBlock={() => handleBlockMyFriend(connection.userId)}
            primaryLabel={ConnectionPrimaryButtonLabelEnum.SEND_MESSAGE}
            showSecondary={false}
            isMyConnectionsTab={activeTab === 0}
          />
        );
      case 1:
        if (isFriendRequest(connection)) {
          return (
            <ConnectionActions
              matches={matches}
              isLoading={isDeletingInviteToFriend}
              onPrimaryAction={() => {
                handleDeleteInviteToFriend(
                  connection.friendUserId,
                  connection[userDataField]
                );
              }}
              primaryLabel={ConnectionPrimaryButtonLabelEnum.CANCEL_REQUEST}
              showSecondary={false}
            />
          );
        }
        return null;
      case 2:
        if (isFriendRequest(connection)) {
          return (
            <ConnectionActions
              matches={matches}
              isLoading={isAccepting || isDeclining}
              onPrimaryAction={() => {
                handleAccept(
                  connection.friendInviteToken,
                  connection[userDataField]
                );
              }}
              onSecondaryAction={() => {
                handleDecline(
                  connection.friendInviteToken,
                  connection[userDataField]
                );
              }}
              primaryLabel={ConnectionPrimaryButtonLabelEnum.ACCEPT}
              secondaryLabel={ConnectionSecondaryButtonLabelEnum.DECLINE}
              showSecondary={true}
            />
          );
        }
        return null;
      case 3:
        return (
          <ConnectionActions
            matches={matches}
            isLoading={isInviting}
            onPrimaryAction={() => handleInvite(connection.userId)}
            onSecondaryAction={() => handleRemoveLocaly(connection.userId)}
            primaryLabel={ConnectionPrimaryButtonLabelEnum.SEND_REQUEST}
            secondaryLabel={ConnectionSecondaryButtonLabelEnum.REMOVE}
            showSecondary={true}
          />
        );
      default:
        return null;
    }
  };

  if (activeTab === 0 || activeTab === 3)
    return (
      <ListItem
        alignItems="center"
        sx={{ justifyContent: 'space-between', p: '0px' }}
      >
        <ConnectionAvatarWithText connection={connection as IFriend} />
        {renderConnectionActions(connection)}
      </ListItem>
    );

  if (activeTab === 1 || activeTab === 2) {
    if (isFriendRequest(connection)) {
      return (
        <ListItem
          alignItems="center"
          sx={{ justifyContent: 'space-between', p: '0px' }}
        >
          {userDataField === UserDataFiledEnum.FRIEND ? (
            <ConnectionAvatarWithText connection={connection.friend} />
          ) : (
            <ConnectionAvatarWithText connection={connection.user} />
          )}
          {renderConnectionActions(connection)}
        </ListItem>
      );
    }
    return null;
  }

  return null;
};

export default ConnectionItem;

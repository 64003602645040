import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MagnifyingGlassIcon from '../Icons/MagnifyingGlassIcon';
import useDebounce from '../../hooks/useDebounce';

const ListSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const [tab, position, location, industry] = useMemo(
    () => [
      searchParams.get('tab'),
      searchParams.get('position'),
      searchParams.get('location'),
      searchParams.get('industry'),
    ],
    [searchParams]
  );

  useEffect(() => {
    setSearchQuery('');
  }, [tab, position, location, industry]);

  useEffect(() => {
    setSearchParams(prevParams => {
      prevParams.set('query', debouncedSearchQuery);
      return prevParams;
    });
  }, [debouncedSearchQuery, setSearchParams]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <TextField
      variant="outlined"
      placeholder="Search..."
      onChange={handleSearchChange}
      value={searchQuery}
      sx={{
        flex: 1,
        paddingLeft: 0,
        fontSize: '14px',
        backgroundColor: 'transparent',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ p: 0 }}>
            <MagnifyingGlassIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ListSearch;

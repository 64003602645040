import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from '../../../../theme/theme';
import { a11yProps } from '../../../../utils/a11yProps';
import { AboutTab, PhotosTab, PostsTab, VideosTab } from '../tabs';
import MyJobsPostsTab from '../tabs/MyJobsPostsTab/MyJobsPostsTab';
import { useLocation, useNavigate } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const CompanyTabs = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    const tabFromUrl = new URLSearchParams(location.search).get('tab');
    if (tabFromUrl && !isNaN(+tabFromUrl)) {
      setSelectedTab(+tabFromUrl);
    }
  }, [location.search]);

  const tabs = useMemo(
    () => [
      { name: 'POSTS', container: <PostsTab /> },
      { name: 'JOB POSTS', container: <MyJobsPostsTab /> },
      { name: 'ABOUT', container: <AboutTab /> },
      { name: 'PHOTOS', container: <PhotosTab /> },
      { name: 'VIDEOS', container: <VideosTab /> },
    ],
    []
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    navigate(`?tab=${newValue}`);
  };

  return (
    <Box sx={theme => ({ width: '100%', color: theme.palette.text.primary })}>
      <Box
        sx={{
          padding: { xs: '16px 0 16px 16px', lg: '22px 0' },
          width: '100%',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="profile tabs"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          sx={{
            // bgcolor: colors.bgGrey,
            backgroundColor: '#FFFFFF',
          }}
          // variant="fullWidth"
          variant="scrollable"
          // scrollButtons={false}
          scrollButtons="auto"
        >
          {tabs.map((item, index) => (
            <Tab
              key={item.name}
              {...a11yProps(index)}
              label={item.name}
              wrapped
              sx={{
                flex: { xs: 'none', lg: 1 },
                borderRadius: '1px',
                // color: colors.white,
                color: '#142237',
                fontSize: '10px',
                fontWeight: 800,
                '&.Mui-selected': {
                  // bgcolor: 'primary.main',
                  bgcolor: '#F5431D',
                  color: colors.white,
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {tabs.map((item, index) => (
        <TabPanel key={item.name} value={selectedTab} index={index}>
          <Container
            disableGutters
            className="profile-tab-container"
            maxWidth={false}
          >
            <Grid container columnGap={{ lg: '16px', xl: '22px' }}>
              {/* minWidth is must have rule to shrink the container 
            properly if the content of the nested span are too large */}
              <Grid
                item
                className="profile-tab-content"
                flex={1}
                width="100%"
                sx={mobileView ? { order: 1 } : {}}
                minWidth="1px" // greeter then 0 for proper placement on mobile devices
              >
                {item.container}
              </Grid>
            </Grid>
          </Container>
        </TabPanel>
      ))}
    </Box>
  );
};

export default CompanyTabs;

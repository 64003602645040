import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArchiveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 18, height: 18, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M15.75 3H2.25V6H15.75V3Z"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.75 6V15H14.25V6"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M7.5 9H10.5"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ArchiveIcon;

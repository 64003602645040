import { useSelector } from 'react-redux';
import { getSelectedChat } from '../../../store/apiSlice/chat/chatSlice';
import { Avatar, Box, Typography } from '@mui/material';
import { isGroupChat } from './ChatNavigation/Chat';
import { useMemo } from 'react';

const EmptyChat = () => {
  const selectedChat = useSelector(getSelectedChat)!;
  const src = useMemo(() => {
    if (isGroupChat(selectedChat))
      return selectedChat.thumb
        ? `data:image/png;base64, ${selectedChat.thumb}`
        : '';
    else
      return selectedChat.withUser.thumbnail
        ? `data:image/png;base64, ${selectedChat.withUser.thumbnail}`
        : '';
  }, [selectedChat]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      flexGrow={1}
    >
      <Avatar
        sx={{ width: '8.75rem', height: '8.75rem', mb: '1.75rem' }}
        src={src.endsWith('undefined') ? '' : src}
      />
      <Typography variant="h2" pb={'0.375rem'}>
        {isGroupChat(selectedChat)
          ? selectedChat.name
          : `${selectedChat.withUser.firstName} ${selectedChat.withUser.lastName}`}
      </Typography>
      <Typography variant="body2">Status</Typography>
    </Box>
  );
};

export default EmptyChat;

import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';

interface ButtonProps extends MUIButtonProps {
  width?: string | number;
  height?: string | number;
  text?: string;
  children?: React.ReactNode;
}

const Button = ({
  width,
  height,
  text,
  children,
  sx,
  onClick,
  ...rest
}: ButtonProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    try {
      if (onClick) await onClick(event);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MUIButton
      color="primary"
      sx={{
        width,
        height,
        padding: '12px 16px',
        borderRadius: 0,
        ...sx,
      }}
      onClick={handleClick}
      disabled={loading}
      {...rest}
    >
      <Typography
        variant="badge2"
        sx={{
          color: theme.palette.background.white,
        }}
      >
        {!loading ? (text ?? children) : 'Loading...'}
      </Typography>
    </MUIButton>
  );
};

export default Button;

const formatEnumValue = (
  value: string,
  capitalizeFirstLetter: boolean = true
): string => {
  const formattedValue = value.toLowerCase().replace(/_/g, ' ');

  if (capitalizeFirstLetter) {
    return formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
  }

  return formattedValue;
};

export default formatEnumValue;

import { SvgIcon, SvgIconProps } from '@mui/material';

const SortIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{ width: 24, height: 24, fill: 'none' }}
      {...props}
    >
      <path
        d="M4.28613 6H13.7147"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.28613 12H11.1433"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.28613 18H11.1433"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M15.4287 15.4285L18.4287 18.4285L21.4287 15.4285"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M18.4287 6V18"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default SortIcon;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    user: any; // Define the shape of your user data
    token: string | null;
}

const initialState: AuthState = {
    user: null,
    token: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData(state, action: PayloadAction<{ user: any; }>) {
            state.user = action.payload.user;
        },
        clearAuthData(state) {
            state.user = null;
        },
    },
});

export const { setAuthData, clearAuthData } = authSlice.actions;
export default authSlice.reducer;
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordSchema } from '../../../validation/auth-validation';
import { useForgotPasswordMutation } from '../../../store/apiSlice/auth/authApi';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CustomButton from '../../MUIComponents/CustomButton';
import Input from '../../MUIComponents/Input';
import { errorHelper } from '../../../utils/helper/error-helper';
import { useNavigate } from 'react-router-dom';
import SendLetterIcon from '../../Icons/SendLetterIcon';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';

interface ForgotPasswordFormInputs {
  email: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarError, setSnackbarError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormInputs>({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const [forgotPassword, { isLoading, data: responseData, isError, error }] =
    useForgotPasswordMutation();

  const onSubmit = async (data: ForgotPasswordFormInputs) => {
    try {
      await forgotPassword({ email: data.email }).unwrap();
      setSnackbarMessage('Check your email for the password reset link.');
      setSnackbarError(false);
      navigate('/update-password', {
        replace: true,
        state: { email: data.email || '' },
      });
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
      console.error('Forgot password request failed:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="3rem"
      width={'min(26.5625rem,100%)'}
    >
      <Typography variant="h2">Forgot Password</Typography>
      <Box display="flex" flexDirection="column" gap="1rem">
        <Input
          sx={{
            '& .MuiInputBase-input::placeholder': {
              color: 'text.secondary',
              opacity: 1,
            },
          }}
          caption="Email:"
          type="email"
          variant="outlined"
          fullWidth
          placeholder="Enter email address"
          register={register('email')}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
        />
        <Typography typography="body4">
          A link to reset your old password will be sent to your email.
        </Typography>
      </Box>

      <CustomButton
        buttonWidth={mobileView ? '100%' : '52%'}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit(onSubmit)}
        disabled={isLoading}
        icon={<SendLetterIcon />}
      >
        Send
      </CustomButton>
      <SnackbarCustom
        open={!!snackbarMessage}
        onClose={handleCloseSnackbar}
        message={snackbarMessage || ''}
        severity={
          snackbarError
            ? SnackbarSeverityEnum.ERROR
            : SnackbarSeverityEnum.SUCCESS
        }
      />
    </Box>
  );
};

export default ForgotPassword;

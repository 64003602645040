import { Box, Typography } from '@mui/material';
import { MessageTypeEnum } from '../../../enums/message-type-enum';
import Message from './Message/Message';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getToken } from '../../../store/apiSlice/chat/chatSlice';
import EmptyChat from './EmptyChat';
import useTwilioClient from '../../../hooks/chat/useTwilioClient';
import useChatToken from '../../../hooks/chat/useChatToken';
import useGetChatMessages from '../../../hooks/chat/useGetChatMessages';
import { getCurrentUserId } from '../../../utils/helper/getCurrentUserId';

const Messages = () => {
  const { messages, isMessagesLoading, handleScroll } = useGetChatMessages();
  useChatToken();
  useTwilioClient();
  const { token, isTokenLoading } = useSelector(getToken);
  const userId = useMemo(() => getCurrentUserId(), []);

  return (
    <Box
      position={'relative'}
      onScroll={handleScroll}
      borderBottom={'1px solid'}
      borderColor={'background.dark'}
      p={'1rem 1rem 2.375rem 1rem'}
      flexGrow={'1'}
      sx={{ overflowY: 'scroll', scrollbarWidth: 'none' }}
      gap={'2.25rem'}
      display={'flex'}
      flexDirection={'column-reverse'}
    >
      {isMessagesLoading && <div className="loader" />}
      {messages &&
        token &&
        !isTokenLoading &&
        Object.keys(messages).map(date => (
          <Fragment key={date}>
            {messages[date].map(message => (
              <Message
                key={message.id}
                message={message}
                type={
                  message.from === userId
                    ? MessageTypeEnum.OWN
                    : MessageTypeEnum.INCOMING
                }
              />
            ))}

            <Box
              alignSelf={'center'}
              p={'0.375rem 0.75rem'}
              bgcolor={'background.default'}
              borderRadius={'20px'}
            >
              <Typography typography={'badge3'} sx={{ color: 'text.primary' }}>
                {date.toLocaleUpperCase()}
              </Typography>
            </Box>
          </Fragment>
        ))}
      {!isMessagesLoading && Object.keys(messages || {}).length === 0 && (
        <EmptyChat />
      )}
    </Box>
  );
};

export default Messages;

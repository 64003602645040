import { Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useMemo, useState } from 'react';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useNavigate } from 'react-router-dom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { errorHelper } from '../../../../utils/helper/error-helper';
import {
  useArchiveMyJobpostByIdMutation,
  useCloseMyJobpostByIdMutation,
  useDeleteMyJobpostByIdMutation,
  useMoveToDraftMyJobpostByIdMutation,
} from '../../../../store/apiSlice/jobpost/jobpostApi';

interface MyCompanyJobPostActionConfirmationModalProps {
  isOpen?: boolean;
  onClose: () => void;
  jobpostId: string;
  type: 'DELETE' | 'DRAFT' | 'CLOSE' | 'ARCHIVE';
}

const MyCompanyJobPostActionConfirmationModal = ({
  onClose,
  isOpen = false,
  jobpostId,
  type,
}: MyCompanyJobPostActionConfirmationModalProps) => {
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [deleteJobpost, { isLoading: isDeleting }] =
    useDeleteMyJobpostByIdMutation();

  const handleDeleteJobpost = async (jobpostId: string) => {
    try {
      await deleteJobpost(jobpostId).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Jobpost deleted!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      onClose();
      navigate('/?tab=1');
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to delete the job post: ', error);
    }
  };

  const [moveJobpostToDraft, { isLoading: isMovingToDraft }] =
    useMoveToDraftMyJobpostByIdMutation();

  const handleMoveJobpostToDraft = async (jobpostId: string) => {
    try {
      await moveJobpostToDraft(jobpostId).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Jobpost moved to drafts!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      onClose();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to delete the job post: ', error);
    }
  };

  const [archiveJobpost, { isLoading: isArchiving }] =
    useArchiveMyJobpostByIdMutation();

  const handleArchiveJobpost = async (jobpostId: string) => {
    try {
      await archiveJobpost(jobpostId).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Jobpost move to archive!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      onClose();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to archive the job post: ', error);
    }
  };

  const [closeJobpost, { isLoading: isClosing }] =
    useCloseMyJobpostByIdMutation();

  const handleCloseJobpost = async (jobpostId: string) => {
    try {
      await closeJobpost(jobpostId).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Jobpost closed!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      onClose();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to close the job post: ', error);
    }
  };

  const getIconSrc = () => {
    if (type === 'ARCHIVE') return '/images/archive-icon.png';
    if (type === 'DELETE') return '/images/delete-icon.png';
    if (type === 'CLOSE') return '/images/delete-icon.png';
    if (type === 'DRAFT') return '/images/draft-icon.png';
  };

  const renderDescriptionText = () => {
    if (type === 'CLOSE')
      return 'Are you sure you want to close this job post? You can restore it later if needed.';
    if (type === 'ARCHIVE')
      return 'Are you sure you want to archive this job post? You can restore it later if needed.';
    if (type === 'DELETE')
      return 'Are you sure you want to delete this job post? This action cannot be undone.';
    if (type === 'DRAFT')
      return 'Are you sure you want to move this job post to drafts? You can edit and publish it again later.';
  };

  const modalTitle = useMemo(() => {
    return (
      <Stack gap={'36px'} alignItems={'center'}>
        <img
          loading="lazy"
          width="120"
          height="120"
          alt={type}
          src={getIconSrc()}
        />
        <Typography
          variant="h2"
          textAlign="center"
          fontSize={'26px'}
          fontWeight={600}
          maxWidth={'430px'}
        >
          {type !== 'DRAFT'
            ? `Are you sure you want to ${type.toLowerCase()} this job post?`
            : 'Are you sure you want to move this job post to drafts?'}
        </Typography>
        <Typography variant="body3">{renderDescriptionText()}</Typography>
      </Stack>
    );
  }, [type]);

  const modalFooter = useMemo(() => {
    return (
      <Box mt={3}>
        <Grid
          container
          justifyContent="center"
          flexDirection="row"
          gap={'16px'}
          width={'100%'}
        >
          <Grid item flex={{ xs: 1, lg: 'none' }}>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item flex={{ xs: 1, lg: 'none' }}>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={async () => {
                type === 'DELETE' && (await handleDeleteJobpost(jobpostId));
                type === 'DRAFT' && (await handleMoveJobpostToDraft(jobpostId));
                type === 'ARCHIVE' && (await handleArchiveJobpost(jobpostId));
                type === 'CLOSE' && (await handleCloseJobpost(jobpostId));
              }}
              disabled={
                isDeleting || isMovingToDraft || isArchiving || isClosing
              }
            >
              {type === 'DRAFT' ? 'move' : type}
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleting, jobpostId, onClose]);

  return (
    <BaseModal
      modalSx={{ borderColor: 'transparent !important' }}
      header={{ component: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
    >
      <Stack spacing="36px"></Stack>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default MyCompanyJobPostActionConfirmationModal;

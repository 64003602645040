import React, { useLayoutEffect, useRef } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import ButtonsOverlay from '../../../../../components/Media/Layouts/ButtonsOverlay';
import { useModal } from '../../../../../hooks/useModal';
import PostVideoModal from './PostVideoModal';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

interface SingleVideoProps {
  file: {
    id: string;
    fileUrl: string;
    fileName: string;
  };
}

const PostVideo: React.FC<SingleVideoProps> = ({ file }) => {
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('md'));
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isOpen, toggleModal, closeModal } = useModal();

  useLayoutEffect(() => {
    if (videoRef.current) {
      const aspectRatio = 3 / 5;
      videoRef.current.style.maxHeight = `${videoRef.current.clientWidth * aspectRatio}px`;
      videoRef.current.style.minHeight = `${videoRef.current.clientWidth * aspectRatio}px`;
    }
  }, [videoRef]);

  const handleOpen = () => {
    toggleModal();
  };

  const handleButtonClick = () => {
    handleOpen();
  };

  return (
    <Box>
      <Box
        ref={videoRef}
        position="relative"
        bgcolor="secondary2.main"
        sx={{
          width: '100%',
          maxHeight: '460px',
          maxWidth: '100%',
          textAlign: 'center',

          '&:hover .video-buttons-overlay': { opacity: 1 },
          '&:hover .video-duration-overlay': { opacity: 0 },
        }}
        onClick={!tabletAndBigger ? () => handleOpen() : undefined}
      >
        <video
          loop
          muted
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            minHeight: '135px',
            minWidth: '216px',
            maxHeight: '460px',
          }}
        >
          <source src={file.fileUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <ButtonsOverlay
          isVideo
          nameOfClass="video-buttons-overlay"
          hasRemove={false}
          openHandler={handleButtonClick}
        />
      </Box>
      <PostVideoModal
        file={file}
        isOpen={isOpen}
        onClose={() => toggleModal()}
      />
    </Box>
  );
};

export default PostVideo;

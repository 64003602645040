import { SvgIcon, SvgIconProps } from '@mui/material';

const PersonCloneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 32, height: 32, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <g strokeLinejoin="round" strokeWidth="1.5">
        <path
          stroke="#434E5F"
          strokeLinecap="round"
          d="M6.667 9.333a5.333 5.333 0 1010.666 0 5.333 5.333 0 00-10.666 0z"
        ></path>
        <path
          stroke="#434E5F"
          strokeLinecap="square"
          d="M4 28v-2.667A5.333 5.333 0 019.333 20h5.334A5.333 5.333 0 0120 25.333V28"
        ></path>
        <path
          stroke="#434E5F"
          strokeLinecap="square"
          d="M21.334 4.173a5.334 5.334 0 010 10.334"
        ></path>
        <path
          stroke="#727F93"
          strokeLinecap="square"
          d="M28 28v-2.667a5.334 5.334 0 00-4-5.133"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default PersonCloneIcon;

import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import CustomButton from '../../MUIComponents/CustomButton';
import { Link } from 'react-router-dom';
import {
  ButtonIconPositionEnum,
  ButtonTypeEnum,
} from '../../../enums/button-type-enum';
import BurgerMenuIcon from '../../Icons/BurgerMenuIcon';
import { IconButton } from '@mui/material';
import CloseX from '../../Icons/CloseX';
import { FC } from 'react';

interface MobileMenuPage {
  label: string;
  to: string;
  navigateFunction?: () => void;
}

interface NavigationMobileMenuProps {
  pages: MobileMenuPage[];
}

const NavigationMobileMenu: FC<NavigationMobileMenuProps> = ({ pages }) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {pages.map(page => (
          <ListItem key={page.label} disablePadding>
            <Link
              onClick={page?.navigateFunction && page.navigateFunction}
              to={page.to}
            >
              <CustomButton
                sx={{
                  p: '18px',
                  fontSize: '24px',
                }}
                variantType={ButtonTypeEnum.MENU}
                iconPosition={ButtonIconPositionEnum.NONE}
              >
                {page.label}
              </CustomButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <Button
        onClick={toggleDrawer(true)}
        sx={{ p: '0px', m: '0px', minWidth: 'fit-content' }}
      >
        <BurgerMenuIcon />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <IconButton
          sx={{ ml: 'auto' }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseX />
        </IconButton>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default NavigationMobileMenu;

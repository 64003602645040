import { SvgIcon, SvgIconProps } from '@mui/material';

const AddImgsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{ width: 24, height: 24, fill: 'none' }}
      {...props}
    >
      <path
        fill="none"
        d="M8 7H3V20H21V7H16V4H8V7Z"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fill="none"
        d="M9 13C9 13.7956 9.31607 14.5587 9.87868 15.1213C10.4413 15.6839 11.2044 16 12 16C12.7956 16 13.5587 15.6839 14.1213 15.1213C14.6839 14.5587 15 13.7956 15 13C15 12.2044 14.6839 11.4413 14.1213 10.8787C13.5587 10.3161 12.7956 10 12 10C11.2044 10 10.4413 10.3161 9.87868 10.8787C9.31607 11.4413 9 12.2044 9 13Z"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default AddImgsIcon;

import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 120, height: 120, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 120"
      {...props}
    >
      <g clipPath="url(#clip0_2279_14486)">
        <path
          d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z"
          fill="#DAF0DB"
        />
        <path
          d="M39.0833 59.5L53.6666 74.0833L82.8333 44.9166"
          stroke="#28BA2E"
          strokeWidth="3"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_2279_14486">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CheckedIcon;

import { SvgIcon, SvgIconProps } from '@mui/material';

const VolumeOnIcon = ({ width, height, sx, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: width || 28,
        height: height || 28,
        fill: 'none',
        ...sx,
      }}
      {...rest}
    >
      <path
        d="M17.5 9.33337C18.2245 9.87673 18.8125 10.5813 19.2175 11.3913C19.6225 12.2013 19.8333 13.0944 19.8333 14C19.8333 14.9056 19.6225 15.7988 19.2175 16.6088C18.8125 17.4188 18.2245 18.1234 17.5 18.6667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M20.6504 5.83337C21.8683 6.81763 22.8507 8.06185 23.5256 9.47492C24.2005 10.888 24.5507 12.4341 24.5507 14C24.5507 15.566 24.2005 17.1121 23.5256 18.5252C22.8507 19.9382 21.8683 21.1824 20.6504 22.1667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 17.8182H7L11.0833 22.75H12.8333V5.25H11.0833L7 10.5L3.5 10.5V17.8182Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default VolumeOnIcon;

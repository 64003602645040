import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Logo from '../Logo/Logo';
import CustomButton from '../MUIComponents/CustomButton';
import {
  ButtonIconPositionEnum,
  ButtonTypeEnum,
} from '../../enums/button-type-enum';
import CommentIcon from '../Icons/CommentIcon';
import { Link } from 'react-router-dom';
import AccountMenu from './AccountMenu/AccountMenu';

import ArrowDownIcon from '../Icons/ArrowDownIcon';
import NavigationMobileMenu from './NavigationMobileMenu/NavigationMobileMenu';
import { HeaderTypeEnum } from '../../enums/header-type';
import { Typography } from '@mui/material';
import NotificationsMenu from './NotificationsMenu/NotificationsMenu';
import { useMemo } from 'react';
import {
  useGetMyProfileQuery,
  useGetUserCompanyInfoQuery,
} from '../../store/apiSlice/userApi';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { CookieEnum } from '../../enums/cookie-enum';
import { selectAuthData } from '../../store/selectors/authSelector';
import { setAuthData } from '../../store/apiSlice/auth/authSlice';
import {
  setChatState,
  setSelectedChat,
} from '../../store/apiSlice/chat/chatSlice';
import { ChatStateEnum } from '../../enums/chat-enum';

const userPages = [
  {
    label: 'My feed',
    to: '/',
  },
  {
    label: 'Connections',
    to: '/connections',
  },
  {
    label: 'Groups',
    to: '/',
  },
  { label: 'Job board', to: '/job-posts' },
];

const companyPages = [
  { label: 'Followers', to: '/' },
  { label: 'Find employee', to: '/candidates' },
];

export const iconButtonStyle = {
  width: '30px',
  height: '30px',
  backgroundColor: 'background.light',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mr: { md: '4px', lg: '8px', xl: '12px' },
};

interface IHeaderProps {
  type?: HeaderTypeEnum;
}

const Header = ({ type = HeaderTypeEnum.BASE }: IHeaderProps) => {
  const authData = useSelector(selectAuthData);
  const isCompany =
    authData?.user?.role == 'COMPANY' ||
    Cookies.get(CookieEnum.ROLE) == 'COMPANY';
  const dispatch = useDispatch();

  const headerTabs = useMemo(() => {
    switch (type) {
      case HeaderTypeEnum.VERIFY_ACCOUNT: {
        return <Typography variant="body3">Verify account</Typography>;
      }
      case HeaderTypeEnum.COMPANY_REGISTRATION: {
        return <Typography variant="body3">Company registration</Typography>;
      }
      default: {
        return (isCompany ? companyPages : userPages).map(page => (
          <Link to={page.to} key={page.label}>
            <CustomButton
              sx={{
                p: { md: '6px', lg: '10px', xl: '14px' },
              }}
              variantType={ButtonTypeEnum.MENU}
              iconPosition={ButtonIconPositionEnum.NONE}
            >
              {page.label}
            </CustomButton>
          </Link>
        ));
      }
    }
  }, [type]);

  let avatarUrl = '';
  const { data: companyInfo } = useGetUserCompanyInfoQuery();
  const { data: profileInfo, isLoading } = useGetMyProfileQuery();
  if (isCompany) {
    avatarUrl = companyInfo?.avatar?.fileUrl || '';
    dispatch(setAuthData({ user: companyInfo }));
  } else {
    avatarUrl = profileInfo?.avatar?.fileUrl || '';
    dispatch(setAuthData({ user: profileInfo }));
  }

  const navigateToChats = (type: ChatStateEnum) => {
    dispatch(setChatState(type));
    dispatch(setSelectedChat(undefined));
  };

  const userMobilePages = [
    {
      label: 'Chats',
      to: '/chat',
      navigateFunction: () => navigateToChats(ChatStateEnum.ALL_CHATS),
    },
  ];

  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: 'background.white',
        boxShadow: 0,
        height: '72px',
        justifyContent: 'center',
        borderBottom: '1px solid',
        borderColor: 'background.light',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{ px: { md: '12px', lg: '24px', xl: '60px' } }}
      >
        <Toolbar disableGutters>
          <Box mr={'12px'} display={{ xs: 'block', md: 'none' }}>
            <NavigationMobileMenu pages={[...userPages, ...userMobilePages]} />
          </Box>

          <Box component={Link} to={'/'} mr={{ xs: 'auto', lg: '0px' }}>
            <Logo />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              height: '32px',
              m: { md: 'auto 10px', lg: 'auto 16px', xl: 'auto 22px' },
              display: { xs: 'none', lg: 'inline-block' },
            }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {headerTabs}
          </Box>

          <Box
            sx={{
              display: { xs: 'none', lg: 'flex' },
              alignItems: 'center',
              flexGrow: 0,
            }}
          >
            {type === HeaderTypeEnum.BASE && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    height: '32px',
                    m: { md: 'auto 10px', lg: 'auto 16px', xl: 'auto 22px' },
                  }}
                />
                <Box display={'flex'} alignItems={'center'}>
                  <Box sx={{ ...iconButtonStyle }}>
                    <NotificationsMenu />
                  </Box>
                  <Link
                    onClick={() => navigateToChats(ChatStateEnum.NEW_PERSONAL)}
                    to="/chat"
                  >
                    <Box sx={{ ...iconButtonStyle }}>
                      <CommentIcon />
                    </Box>
                  </Link>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    height: '32px',
                    m: { md: 'auto 10px', lg: 'auto 16px', xl: 'auto 22px' },
                  }}
                />
              </>
            )}

            <Link to={'/me'}>
              <CustomButton
                variantType={ButtonTypeEnum.MENU}
                iconPosition={ButtonIconPositionEnum.LEFT}
                icon={<Avatar sx={{ mr: 1 }} alt="Profile" src={avatarUrl} />}
              >
                My Profile
              </CustomButton>
            </Link>
            <Box display={{ xs: 'none', lg: 'block' }}>
              <AccountMenu>
                <ArrowDownIcon />
              </AccountMenu>
            </Box>
          </Box>
          <Box display={{ xs: 'block', lg: 'none' }}>
            <AccountMenu>
              <Avatar sx={{ mr: 1 }} alt="Profile" src={avatarUrl} />
            </AccountMenu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useModal } from '../../../../../hooks/useModal';
import { Video } from '../../../../../interfaces/media.interface';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import ButtonsOverlay from '../../../Layouts/ButtonsOverlay';
import VideoDurationOverlay from '../../../Layouts/VideoDurationOverlay';
import MediaPreviewModal from '../../../Modals/MediaPreviewModal';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';

interface VideoItem {
  id: string;
  element: HTMLDivElement | null;
}
interface VideoGridProps {
  files: Video[];
  handleFileRemove: ((id: string) => Promise<void>) | ((id: string) => void);
  hideViews?: boolean;
}

const VideoGrid = ({ files, handleFileRemove, hideViews }: VideoGridProps) => {
  const { isOpen, toggleModal } = useModal();
  const videoItemRef = useRef<VideoItem[]>([]);
  const { theme } = useThemeContext();
  const { isOwnPage } = useCurrentPageUserId();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('md'));
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [loadedVideos, setLoadedVideos] = useState<
    {
      vidID: string;
      duration: number;
    }[]
  >([]);

  useLayoutEffect(() => {
    if (videoItemRef.current.length) {
      videoItemRef.current.forEach(({ element }) => {
        if (!element) {
          return;
        }
        element.style.maxHeight = (element.clientWidth * 3) / 5 + 'px';
        element.style.minHeight = (element.clientWidth * 3) / 5 + 'px';
      });
    }
  }, [videoItemRef.current.length]);

  const handleVideoItemRef = useCallback(
    (file: Video) => (el: HTMLDivElement) => {
      if (el) {
        videoItemRef.current.push({
          id: file.id,
          element: el as HTMLDivElement,
        });
      } else {
        videoItemRef.current = videoItemRef.current.filter(
          el => el.id !== file.id
        );
      }
    },
    []
  );

  const handleOpen = (index: number) => {
    setCurrentIndex(index);
    toggleModal();
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex < files.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : files.length - 1
    );
  };

  const handleDelete = () => {
    toggleModal();
    handleFileRemove(files[currentIndex].id);
  };

  return (
    <Box sx={{ textAlign: 'center' }} mt="36px">
      <Grid
        container
        spacing={2}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(232px, 1fr))',
        }}
      >
        {files?.map((file, index) => (
          <Grid
            item
            minHeight={198}
            display="flex"
            flexDirection="column"
            key={file.id}
          >
            <Box
              ref={handleVideoItemRef(file)}
              position="relative"
              bgcolor="secondary2.main"
              sx={{
                minHeight: '135px',
                minWidth: 216,
                maxHeight: '100%',
                maxWidth: '100%',

                '&:hover .video-buttons-overlay': { opacity: 1 },
                '&:hover .video-duration-overlay': { opacity: 0 },
              }}
              onClick={!tabletAndBigger ? () => handleOpen(index) : undefined}
            >
              <video
                // poster="https://platinumlist.net/guide/wp-content/uploads/2023/03/IMG-worlds-of-adventure.webp"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  minHeight: '135px',
                  minWidth: '216px',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
                onLoadedMetadata={({ target }) =>
                  setLoadedVideos(prev => [
                    ...prev,
                    {
                      vidID: file.id,
                      duration: (target as HTMLVideoElement).duration,
                    },
                  ])
                }
              >
                <source src={file.fileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <ButtonsOverlay
                isVideo
                nameOfClass="video-buttons-overlay"
                openHandler={() => handleOpen(index)}
                removeHandler={() => handleFileRemove(file.id)}
                hasRemove={isOwnPage}
              />
              <VideoDurationOverlay
                nameOfClass="video-duration-overlay"
                duration={
                  loadedVideos.find(vid => vid.vidID === file.id)?.duration
                }
              />
            </Box>
            <Typography
              variant="h4"
              sx={theme => ({
                mt: '12px',
                color: theme.palette.text.primary,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
              })}
            >
              {file.fileName}
            </Typography>
            {!hideViews && (
              <Typography
                mt="6px"
                alignSelf="flex-start"
                variant="caption"
                sx={theme => ({ color: theme.palette.text.secondary })}
              >
                0 views
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
      <MediaPreviewModal
        isVideo
        files={files}
        currentIndex={currentIndex}
        isOpen={isOpen}
        onClose={() => toggleModal()}
        clickNext={handleNext}
        clickPrev={handlePrev}
        deleteFile={handleDelete}
      />
    </Box>
  );
};

export default VideoGrid;

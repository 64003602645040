import { Avatar, Box, Typography } from '@mui/material';

interface ISenderInfoProps {
  firstname?: string;
  lastname?: string;
  avatar?: string;
}

const SenderInfo = ({
  firstname = 'firstname',
  lastname = 'lastname',
  avatar,
}: ISenderInfoProps) => {
  return (
    <Box display={'flex'} gap={'1rem'}>
      <Avatar
        sx={{ width: '3.125rem', height: '3.125rem' }}
        src={
          !avatar
            ? ''
            : avatar.startsWith('https')
              ? avatar
              : `data:image/jpeg;base64, ${avatar}`
        }
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'0.375rem'}
        justifyContent={'center'}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '16.94px',
          }}
        >
          {firstname} {lastname}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '14.52px',
            color: '#727F93',
          }}
        >
          Status
        </Typography>
      </Box>
    </Box>
  );
};

export default SenderInfo;

import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}
function NoteBookIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      fill="none"
      viewBox="0 0 29 28"
      className={cn({ [className!]: className !== undefined })}
    >
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M23.833 23.333V4.667H7.5v18.666h16.333zM12.166 18.667h7"
      ></path>
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M13.334 12.833a2.333 2.333 0 104.665 0 2.333 2.333 0 00-4.665 0zM5.167 9.333h3.5M5.167 14h3.5M5.167 18.667h3.5"
      ></path>
    </svg>
  );
}

export default NoteBookIcon;

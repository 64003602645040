import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  PaginatedResponse,
  Photo,
  PhotoAlbum,
  UpdatePhotosAlbumReq,
  UpdateVideoAlbumReq,
  Video,
  VideoAlbum,
} from '../../interfaces/media.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const profileMediaApi = createApi({
  reducerPath: 'profileMediaApi',
  baseQuery: baseQueryWithInterceptor(config.BASE_USER_MICROSERVICE_API_URL),
  tagTypes: ['profileMedia'],

  endpoints: builder => ({
    getAllUserPhotosByUserId: builder.query<
      PaginatedResponse<Photo>,
      {
        id: string;
        page?: number;
        limit?: number;
        search?: string;
        sort?: 'asc' | 'desc';
      }
    >({
      query: ({ id, page = 1, limit = 10, search = '', sort = 'asc' }) => ({
        url: `/photo-album/all/photo/userid/` + id,
        params: { page, limit, search, sort },
      }),
      providesTags: ['profileMedia'],
    }),
    getAllUserPhotos: builder.query<
      PaginatedResponse<Photo>,
      { page?: number; limit?: number; search?: string; sort?: 'asc' | 'desc' }
    >({
      query: ({ page = 1, limit = 10, search = '', sort = 'asc' }) => ({
        url: `/photo-album/all/photo`,
        params: { page, limit, search, sort },
      }),
      providesTags: ['profileMedia'],
    }),
    addUserPhoto: builder.mutation<Photo, FormData>({
      query: (data: FormData) => ({
        url: `/photo-album/add-photo`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['profileMedia'],
    }),
    deletePhoto: builder.mutation<Photo, string>({
      query(id) {
        return {
          url: `/photo-album/photo/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['profileMedia'],
    }),
    getAllUserPhotoAlbums: builder.query<PhotoAlbum[], void>({
      query: () => '/photo-album',
      providesTags: ['profileMedia'],
    }),
    getAllUserPhotoAlbumsById: builder.query<PhotoAlbum[], string>({
      query: id => `/photo-album/userid/${id}`,
      providesTags: ['profileMedia'],
    }),
    getPhotoAlbumById: builder.query<
      PaginatedResponse<Photo>,
      {
        id: string;
        page?: number;
        limit?: number;
        search?: string;
        sort?: 'asc' | 'desc';
      }
    >({
      query: ({ id, page = 1, limit = 10, search = '', sort = 'asc' }) => ({
        url: `/photo-album/${id}/photos`,
        params: { page, limit, search, sort },
      }),
      providesTags: ['profileMedia'],
    }),
    createPhotoAlbum: builder.mutation<Omit<PhotoAlbum, 'photos'>, FormData>({
      query: (data: FormData) => ({
        url: `/photo-album`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['profileMedia'],
    }),
    updatePhotoAlbum: builder.mutation<
      Omit<PhotoAlbum, 'photos'>,
      UpdatePhotosAlbumReq
    >({
      query: ({ id, data }) => ({
        url: `/photo-album/` + id,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['profileMedia'],
    }),
    deletePhotoAlbum: builder.mutation<PhotoAlbum[], string>({
      query(id) {
        return {
          url: `/photo-album/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['profileMedia'],
    }),

    // Videos
    getAllUserVideosByUserId: builder.query<
      PaginatedResponse<Video>,
      {
        id: string;
        page?: number;
        limit?: number;
        search?: string;
        sort?: 'asc' | 'desc';
      }
    >({
      query: ({ id, page = 1, limit = 10, search = '', sort = 'asc' }) => ({
        url: `/video-album/all/video/userId/` + id,
        params: { page, limit, search, sort },
      }),
      providesTags: ['profileMedia'],
    }),
    getAllUserVideos: builder.query<
      PaginatedResponse<Video>,
      { page?: number; limit?: number; search?: string; sort?: 'asc' | 'desc' }
    >({
      query: ({ page = 1, limit = 10, search = '', sort = 'asc' }) => ({
        url: `/video-album/all/video`,
        params: { page, limit, search, sort },
      }),
      providesTags: ['profileMedia'],
    }),
    addUserVideo: builder.mutation<Video, FormData>({
      query: (data: FormData) => ({
        url: `/video-album/add-video`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['profileMedia'],
    }),
    deleteVideo: builder.mutation<Video, string>({
      query(id) {
        return {
          url: `/video-album/video/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['profileMedia'],
    }),
    getAllUserVideoAlbums: builder.query<VideoAlbum[], void>({
      query: () => '/video-album',
      providesTags: ['profileMedia'],
    }),
    getAllUserVideoAlbumsById: builder.query<VideoAlbum[], string>({
      query: id => `/video-album/userid/${id}`,
      providesTags: ['profileMedia'],
    }),
    getVideoAlbumById: builder.query<
      PaginatedResponse<Video>,
      {
        id: string;
        page?: number;
        limit?: number;
        search?: string;
        sort?: 'asc' | 'desc';
      }
    >({
      query: ({ id, page = 1, limit = 10, search = '', sort = 'asc' }) => ({
        url: `/video-album/${id}/videos`,
        params: { page, limit, search, sort },
      }),
      providesTags: ['profileMedia'],
    }),
    createVideoAlbum: builder.mutation<Omit<VideoAlbum, 'videos'>, FormData>({
      query: (data: FormData) => ({
        url: `/video-album`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['profileMedia'],
    }),
    updateVideoAlbum: builder.mutation<
      Omit<VideoAlbum, 'videos'>,
      UpdateVideoAlbumReq
    >({
      query: ({ id, data }) => ({
        url: `/video-album/` + id,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['profileMedia'],
    }),
    deleteVideoAlbum: builder.mutation<VideoAlbum[], string>({
      query(id) {
        return {
          url: `/video-album/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['profileMedia'],
    }),
  }),
});

export const {
  // photo
  useGetAllUserPhotosByUserIdQuery,
  useGetAllUserPhotosQuery,
  useAddUserPhotoMutation,
  useDeletePhotoMutation,
  useGetAllUserPhotoAlbumsQuery,
  useGetAllUserPhotoAlbumsByIdQuery,
  useGetPhotoAlbumByIdQuery,
  useCreatePhotoAlbumMutation,
  useUpdatePhotoAlbumMutation,
  useDeletePhotoAlbumMutation,
  // video
  useGetAllUserVideosByUserIdQuery,
  useGetAllUserVideosQuery,
  useAddUserVideoMutation,
  useDeleteVideoMutation,
  useGetAllUserVideoAlbumsQuery,
  useGetAllUserVideoAlbumsByIdQuery,
  useGetVideoAlbumByIdQuery,
  useCreateVideoAlbumMutation,
  useUpdateVideoAlbumMutation,
  useDeleteVideoAlbumMutation,
} = profileMediaApi;

export default profileMediaApi;

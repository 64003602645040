import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import {
  ICreateJobpostRequest,
  IJobpost,
  IPaginatedJobpostResponse,
  TIndustry,
} from '../../../interfaces/jobpost.interface';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const jobpostApi = createApi({
  reducerPath: 'jobpostApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_COMPANY_MICROSERVICE_API_URL}/jobpost`
  ),
  tagTypes: ['jobpost'],

  endpoints: builder => ({
    getIndustries: builder.query<TIndustry[], void>({
      query: () => '/all-industries',
      providesTags: ['jobpost'],
    }),

    getAllJobposts: builder.query<
      IPaginatedJobpostResponse,
      {
        page?: number;
        limit?: number;
        search?: string;
        sort?: string;
        industries?: string;
        position?: string;
        location?: string;
      } | void
    >({
      query: (
        args = {
          page: 1,
          limit: 10,
          search: '',
          sort: 'desc',
          industries: '',
          position: '',
          location: '',
        }
      ) => {
        const {
          page = 1,
          limit = 10,
          search = '',
          sort = 'desc',
          industries = '',
          position = '',
          location = '',
        } = args || {};
        return `?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}&industries=${industries}&position=${position}&location=${location}`;
      },
      providesTags: ['jobpost'],
    }),

    getMyJobposts: builder.query<
      IPaginatedJobpostResponse,
      {
        page?: number;
        limit?: number;
        search?: string;
        sort?: string;
        industries?: string;
        position?: string;
        location?: string;
        status?: string;
      } | void
    >({
      query: (
        args = {
          page: 1,
          limit: 10,
          search: '',
          sort: 'desc',
          industries: '',
          position: '',
          location: '',
          status: 'all',
        }
      ) => {
        const {
          page = 1,
          limit = 10,
          search = '',
          sort = 'desc',
          industries = '',
          position = '',
          location = '',
          status = 'all',
        } = args || {};
        return `/my-jobposts?page=${page}&limit=${limit}&status=${status}&search=${encodeURIComponent(search)}&sort=${sort}&industries=${industries}&position=${position}&location=${location}`;
      },
      providesTags: ['jobpost'],
    }),

    createJobpost: builder.mutation<IJobpost, ICreateJobpostRequest>({
      query: data => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['jobpost'],
    }),
    updateJobpost: builder.mutation<void, any>({
      query: ({ jobpostId, data }) => ({
        url: `update-my-jobpost/${jobpostId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['jobpost'],
    }),

    deleteMyJobpostById: builder.mutation<void, string>({
      query: jobpostId => ({
        url: `${jobpostId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['jobpost'],
    }),

    moveToDraftMyJobpostById: builder.mutation<void, string>({
      query: jobpostId => ({
        url: `/move-to-draft/${jobpostId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['jobpost'],
    }),

    archiveMyJobpostById: builder.mutation<void, string>({
      query: jobpostId => ({
        url: `/move-to-archive/${jobpostId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['jobpost'],
    }),

    closeMyJobpostById: builder.mutation<void, string>({
      query: jobpostId => ({
        url: `/close-job-post/${jobpostId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['jobpost'],
    }),
    getJobpostById: builder.query<{ jobpost: IJobpost }, string | undefined>({
      query: jobpostId => `/${jobpostId}`,
      providesTags: ['jobpost'],
    }),
  }),
});

export const {
  useGetIndustriesQuery,
  useGetAllJobpostsQuery,
  useGetMyJobpostsQuery,
  useCreateJobpostMutation,
  useDeleteMyJobpostByIdMutation,
  useMoveToDraftMyJobpostByIdMutation,
  useArchiveMyJobpostByIdMutation,
  useCloseMyJobpostByIdMutation,
  useGetJobpostByIdQuery,
  useUpdateJobpostMutation,
} = jobpostApi;

export default jobpostApi;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  ITeamResponse,
  ICreateTeamRequest,
  IUpdateTeamRequest,
} from '../../interfaces/team.interface';
import { IUser } from '../../interfaces/user.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const teamApi = createApi({
  reducerPath: 'teamApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/team`
  ),
  tagTypes: ['team'],

  endpoints: builder => ({
    getTeamById: builder.query<ITeamResponse, string>({
      query: id => `/${id}`,
      providesTags: ['team'],
    }),
    getTeamByName: builder.query<ITeamResponse, string>({
      query: name => `/name/${name}`,
      providesTags: ['team'],
    }),
    getTeams: builder.query<ITeamResponse[], void>({
      query: () => '',
      providesTags: ['team'],
    }),
    createTeam: builder.mutation<ITeamResponse, ICreateTeamRequest>({
      query: (data: ICreateTeamRequest) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['team'],
    }),
    updateTeamById: builder.mutation<
      ITeamResponse,
      { id: string; data: IUpdateTeamRequest }
    >({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['team'],
    }),
    deleteTeamById: builder.mutation<ITeamResponse, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['team'],
    }),
    addUserToMultipleTeams: builder.mutation<
      IUser,
      { userId: string; teamIds: string[] }
    >({
      query: ({ userId, teamIds }) => ({
        url: `/userId/${userId}`,
        method: 'PUT',
        body: { teamIds },
      }),
      invalidatesTags: ['team'],
    }),
  }),
});

export const {
  useGetTeamByIdQuery,
  useGetTeamByNameQuery,
  useGetTeamsQuery,
  useCreateTeamMutation,
  useUpdateTeamByIdMutation,
  useDeleteTeamByIdMutation,
  useAddUserToMultipleTeamsMutation,
} = teamApi;

export default teamApi;

import CommentNotificationIcon from '../../components/Icons/CommentNotificationIcon';
import FriendNotificationIcon from '../../components/Icons/FriendNotificationIcon';
import LikeNotificationIcon from '../../components/Icons/LikeNotificationIcon';
import { NotificationTypeEnum } from '../../enums/notification-type.enum';

const getNotificationDetails = (type: NotificationTypeEnum) => {
  switch (type) {
    case NotificationTypeEnum.POST_LIKE:
      return {
        message: 'Liked your post',
        Icon: LikeNotificationIcon,
        color: 'primary.main',
      };
    case NotificationTypeEnum.COMMENT_LIKE:
      return {
        message: 'Liked your comment',
        Icon: LikeNotificationIcon,
        color: 'primary.main',
      };
    case NotificationTypeEnum.COMMENT_POST:
      return {
        message: 'Commented on your post',
        Icon: CommentNotificationIcon,
        color: 'text.primary',
      };
    case NotificationTypeEnum.FRIEND_INVITATION:
      return {
        message: 'Sent you a friend invitation',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_REQUEST_APPROVE:
      return {
        message: 'Approved your friend request',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_REQUEST_REJECT:
      return {
        message: 'Rejected your friend request',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_INTRODUCTION:
      return {
        message: 'Introduced you to a friend',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_INTRODUCTION_REQUEST_APPROVE:
      return {
        message: 'Approved your friend introduction request',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_INTRODUCTION_REQUEST_REJECT:
      return {
        message: 'Rejected your friend introduction request',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.APPROVE_JOB_APPLICATION:
      return {
        message: 'Approved your job application',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.REJECT_JOB_APPLICATION:
      return {
        message: 'Rejected your job application',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.APPLY_JOB:
      return {
        message: 'Applied for a job',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.REJECT_APPLY_JOB:
      return {
        message: 'Cancelled your job application',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.CHAT_MESSAGE:
      return {
        message: 'New message in chat',
        Icon: CommentNotificationIcon,
        color: 'text.primary',
      };
    default:
      return { message: 'Unknown notification', Icon: null };
  }
};

export default getNotificationDetails;

import { useTheme } from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';
import {
  TDemographicsInsight,
  TLocation,
  TPosition,
  TReason,
  TRejectionReason,
  TSalaryPeriod,
} from '../../../../../interfaces/jobpost.interface';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import formatEnumValue from '../../../../../utils/formatEnumValue';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

type ISelectItems = (
  | TLocation
  | TPosition
  | TReason
  | TSalaryPeriod
  | TRejectionReason
  | TDemographicsInsight
)[];

type TLabel =
  | 'position'
  | 'location'
  | 'reason'
  | 'salaryPeriod'
  | 'rejectionReason'
  | 'demographicsInsight';

interface IJobPostFilterSingleSelect {
  label: TLabel;
  selectItems: ISelectItems;
  placeholder: string;
  filterState: string;
  setFilterState: React.Dispatch<React.SetStateAction<string>>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    sx: {
      '& .MuiMenu-list': {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
  },
};

function capitalizeAndAddColon(str: string) {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }

  if (str === 'demographicsInsight') {
    return 'Choose type of statistics:';
  }

  return str.charAt(0).toUpperCase() + str.slice(1) + ':';
}

const JobPostFilterSingleSelect: React.FC<IJobPostFilterSingleSelect> = ({
  selectItems,
  label,
  placeholder,
  filterState,
  setFilterState,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (event.target) {
      const {
        target: { value },
      } = event;
      setFilterState(value);
    }
  };

  const hasProperty = <K extends PropertyKey>(
    obj: object,
    key: K
  ): obj is Record<K, unknown> => {
    return key in obj;
  };

  return (
    <div>
      <Typography
        component={'p'}
        fontSize={'12px'}
        fontWeight={700}
        mb={'12px'}
      >
        {capitalizeAndAddColon(label)}
      </Typography>
      <FormControl sx={{ width: '100%' }}>
        <Select
          value={filterState}
          displayEmpty={true}
          IconComponent={props => <ArrowDownIcon {...props} />}
          renderValue={selected => {
            if (filterState) {
              return formatEnumValue(selected);
            } else return placeholder;
          }}
          onChange={handleChange}
          sx={{
            '& .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input':
              {
                display: 'flex',
                alignItems: 'center',
                height: 'fit-content',
                p: '12px 25px 12px 14px',
              },
            '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input':
              {
                alignItems: 'center',
                height: 'fit-content',
                p: '12px 25px 12px 14px',
              },
            '& .MuiSelect-select:focus': {
              outline: 'none',
            },
            '& .MuiSelect-select:focusWithin': {
              outline: 'none',
            },
            '& .MuiSelect-icon': {
              color: theme.palette.text.primary,
              backgroundColor: 'transparent',
            },
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 0,
              },
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {selectItems.map(item => (
            <MenuItem
              key={item.id}
              value={item.id}
              sx={{ fontSize: '14px', fontWeight: 400 }}
            >
              {hasProperty(item, label) ? (item[label] as ReactNode) : item.id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default JobPostFilterSingleSelect;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import {
  DateRequest,
  IAnalyticCountResponse,
  IAthletesDistributionAnalyticResponse,
  INumberOfApprovedCandidatesAnalyticResponse,
  INumberOfCandidatesAnalyticResponse,
  ITimeToFillAnalyticResponse,
} from '../../../interfaces/analytic.interface';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const analyticApi = createApi({
  reducerPath: 'analyticApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_COMPANY_MICROSERVICE_API_URL}/analytic`
  ),
  tagTypes: ['analytic'],

  endpoints: builder => ({
    getNumberOfCandidates: builder.query<
      INumberOfCandidatesAnalyticResponse,
      void
    >({
      query: () => '/number-of-categories',
      providesTags: ['analytic'],
    }),
    getNumberOfApprovedCandidates: builder.query<
      INumberOfApprovedCandidatesAnalyticResponse,
      void
    >({
      query: () => '/number-of-approved-candidates',
      providesTags: ['analytic'],
    }),
    getTimeToFill: builder.query<ITimeToFillAnalyticResponse, void>({
      query: () => '/time-to-fill',
      providesTags: ['analytic'],
    }),
    countOfCandidatesByDate: builder.query<IAnalyticCountResponse, DateRequest>(
      {
        query: date => ({
          url: '/number-of-candidates-by-date',
          method: 'POST',
          body: date,
        }),
        providesTags: ['analytic'],
      }
    ),
    countOfJobPosts: builder.query<IAnalyticCountResponse, DateRequest>({
      query: date => ({
        url: '/number-of-job-posts',
        method: 'POST',
        body: date,
      }),
      providesTags: ['analytic'],
    }),
    countOfApprovedCandidates: builder.query<
      IAnalyticCountResponse,
      DateRequest
    >({
      query: date => ({
        url: '/number-of-accepted-candidates-by-date',
        method: 'POST',
        body: date,
      }),
      providesTags: ['analytic'],
    }),
    countOfRejectedCandidatesByDate: builder.query<
      IAnalyticCountResponse,
      DateRequest
    >({
      query: date => ({
        url: '/number-of-rejected-candidates-by-date',
        method: 'POST',
        body: date,
      }),
      providesTags: ['analytic'],
    }),
    athletesDistributionBySport: builder.query<
      IAthletesDistributionAnalyticResponse,
      void
    >({
      query: () => ({
        url: '/athletes-distribution-by-sport',
      }),
      providesTags: ['analytic'],
    }),
  }),
});

export const {
  useGetNumberOfCandidatesQuery,
  useGetNumberOfApprovedCandidatesQuery,
  useGetTimeToFillQuery,
  useCountOfCandidatesByDateQuery,
  useCountOfJobPostsQuery,
  useCountOfApprovedCandidatesQuery,
  useCountOfRejectedCandidatesByDateQuery,
  useAthletesDistributionBySportQuery,
} = analyticApi;

export default analyticApi;

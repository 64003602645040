import { Box, Typography } from '@mui/material';
import { MessageTypeEnum } from '../../../../enums/message-type-enum';
import { useRef } from 'react';

const MessageVideoPreview = ({
  src,
  filename,
  filesize,
  type,
}: {
  src: string;
  filename: string;
  filesize: string;
  type: MessageTypeEnum;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <Box display={'flex'} gap={'1rem'} flexDirection={'column'}>
      <video
        controls
        preload="none"
        poster=""
        style={{ width: '100%' }}
        ref={videoRef}
      >
        <source src={src} onLoad={() => videoRef.current?.load()} />
      </video>
      <Box display={'flex'} flexDirection={'column'} gap="0.375rem">
        <Typography
          variant="body3"
          fontWeight={700}
          sx={{
            color:
              type === MessageTypeEnum.OWN
                ? 'background.white'
                : 'text.primary',
          }}
        >
          {filename}
        </Typography>
        <Typography variant="body4" fontWeight={500} sx={{ color: '#A1ABBA' }}>
          {filesize}
        </Typography>
      </Box>
    </Box>
  );
};

export default MessageVideoPreview;

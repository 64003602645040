import { Box, useMediaQuery } from '@mui/material';
import { useModal } from '../../../hooks/useModal';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { useState } from 'react';
import { Photo } from '../../../interfaces/media.interface';
import ButtonsOverlay from '../../Media/Layouts/ButtonsOverlay';
import MediaPreviewModal from '../../Media/Modals/MediaPreviewModal';

interface CompanyAboutFileProps {
  files: Photo[];
  handleFileRemove: ((id: string) => Promise<void>) | ((id: string) => void);
  needNextPrevButtons?: boolean;
}

const CompanyAboutPhotoFile = ({
  files,
  handleFileRemove,
  needNextPrevButtons = false,
}: CompanyAboutFileProps) => {
  const { isOpen, toggleModal } = useModal();
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('md'));
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleOpen = (index: number) => {
    setCurrentIndex(index);
    toggleModal();
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex < files.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : files.length - 1
    );
  };

  const handleDelete = () => {
    toggleModal();
    handleFileRemove(files[currentIndex].id);
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box display={'flex'} flexDirection={'column'} gap={'28px'}>
        {files?.map((file, index) => (
          <Box key={file.id}>
            <Box
              position="relative"
              width="100%"
              bgcolor="secondary2.main"
              sx={{
                height: {
                  xs: '200px',
                  sm: '300px',
                  md: '400px',
                  lg: '500px',
                },
                '@media (max-width: 320px)': {
                  height: '150px',
                },
                padding: '0 20px',
                '&:hover .photo-buttons-overlay': { opacity: 1 },
              }}
              onClick={!tabletAndBigger ? () => handleOpen(index) : undefined}
            >
              <img
                src={file.fileUrl}
                alt="Preview"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  maxWidth: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
              <ButtonsOverlay
                nameOfClass="photo-buttons-overlay"
                openHandler={() => handleOpen(index)}
                removeHandler={() => handleFileRemove(file.id)}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <MediaPreviewModal
        files={files}
        currentIndex={currentIndex}
        isOpen={isOpen}
        onClose={() => toggleModal()}
        clickNext={handleNext}
        clickPrev={handlePrev}
        deleteFile={handleDelete}
        needNextPrevButtons={needNextPrevButtons}
      />
    </Box>
  );
};

export default CompanyAboutPhotoFile;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  FormControl,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import Input from '../../../../components/MUIComponents/Input';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import TextField from '../../../../components/MUIComponents/TextField';
import { CancelIntroductionReasonEnum } from '../../../../enums/cancel-introduction-reason-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import {
  ICancelIntroductionRequest,
  ICreateIntroductionRequest,
} from '../../../../interfaces/friend-introduction.interface';
import { useGetMyFriendsToIntroduceQuery } from '../../../../store/apiSlice/friendApi';
import {
  useApproveIntroductionMutation,
  useCancelIntroductionMutation,
  useCreateIntroductionMutation,
  useGetIntroductionByIdQuery,
} from '../../../../store/apiSlice/friendIntroductionApi';
import { useGetUserInfoQuery } from '../../../../store/apiSlice/userApi';
import { colors } from '../../../../theme/theme';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { requestIntroductionSchema } from '../../../../validation/request-introduction';

const CANT_INTRODUCE_MSG =
  "Oops, you don't have any friends to whom you could introduce this user";

enum SubmitType {
  SENT_INTRODUCTION,
  ACCEPT_INTRODUCTION,
  DENY_INTRODUCTION,
}

interface RequestIntroductionFormInputs {
  introducedFriend?: string;
  introducingToFriend?: string;
  introductionText?: string;
  reasonToDeny?: CancelIntroductionReasonEnum;
}

interface SelectItemProps {
  userId: string;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  sport: string;
}
export const SelectItem = ({
  avatarUrl,
  firstName,
  lastName,
  sport,
}: SelectItemProps) => (
  <Stack direction="row" spacing="12px" alignItems="center">
    <Avatar alt="Profile" src={avatarUrl} sx={{ width: 40, height: 40 }} />
    <Stack spacing={1}>
      <Typography
        variant="h4"
        sx={theme => ({ color: theme.palette.text.secondary })}
      >
        {`${firstName} ${lastName}`}
      </Typography>
      <Typography variant="h4">{sport}</Typography>
    </Stack>
  </Stack>
);

interface RequestIntroductionModalProps {
  isOpen?: boolean;
  onClose: () => void;
  introductionId?: string;
  senderFirstName?: string;
  senderLastName?: string;
}

const RequestIntroductionModal = ({
  onClose,
  isOpen = true,
  introductionId,
  senderFirstName,
  senderLastName,
}: RequestIntroductionModalProps) => {
  const { id: pageId } = useParams();
  const [isDenyBtnPressed, setDenyBtnPressed] = useState<boolean>(false);
  const theme = useTheme();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const { data: introduction } = useGetIntroductionByIdQuery(
    { id: introductionId || '' },
    { skip: !introductionId }
  );

  const { data: introducedFriend, isLoading: isIntroducedFriendLoading } =
    useGetUserInfoQuery(introduction?.firstFriendId || '', {
      skip: !introductionId || !introduction,
    });

  const { data: myFriends, isLoading: isMyFriendsLoading } =
    useGetMyFriendsToIntroduceQuery(
      { representedFriendId: pageId || '' },
      { skip: !pageId }
    );

  const [
    createIntroduction,
    {
      isLoading: isCreateIntroductionLoading,
      // eslint-disable-next-line
      isError: isCreateIntroductionError,
    },
  ] = useCreateIntroductionMutation();

  const [approveIntroduction, { isLoading: isApproveIntroductionLoading }] =
    useApproveIntroductionMutation();
  const [denyIntroduction, { isLoading: isDenyIntroductionLoading }] =
    useCancelIntroductionMutation();

  const {
    setValue,
    register,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<RequestIntroductionFormInputs>({
    resolver: yupResolver(requestIntroductionSchema),
  });

  useEffect(() => {
    if (introducedFriend) {
      setValue('introducedFriend', introducedFriend.id);
    }
  }, [introducedFriend, setValue]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onSubmitModal = useCallback(
    (submitType: SubmitType) => async (data: RequestIntroductionFormInputs) => {
      const handleIntroductionResponse = (res: any, successMessage: string) => {
        if (res.data) {
          setSnackbarMessage(successMessage);
          setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
          setSnackbarOpen(true);
        } else if (res.error) {
          setSnackbarMessage(errorHelper(res.error));
          setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
          setSnackbarOpen(true);
        }
      };

      if (submitType === SubmitType.SENT_INTRODUCTION && pageId) {
        if (!data.introducingToFriend) {
          return setError('introducingToFriend', {
            message: 'field is required',
          });
        }
        const createIntroductionReq: ICreateIntroductionRequest = {
          firstFriendId: pageId,
          secondFriendId: data.introducingToFriend,
          message: data.introductionText,
        };

        const res = await createIntroduction(createIntroductionReq);
        handleIntroductionResponse(
          res,
          'Request introduction successfully sent'
        );
        return;
      }

      if (submitType === SubmitType.ACCEPT_INTRODUCTION && introductionId) {
        const res = await approveIntroduction(introductionId);
        handleIntroductionResponse(
          res,
          'Introduction request successfully approved'
        );
        return;
      }

      if (submitType === SubmitType.DENY_INTRODUCTION && introductionId) {
        if (!data.reasonToDeny) {
          return setError('reasonToDeny', {
            message: 'field is required',
          });
        }
        const denyIntroductionRequest: ICancelIntroductionRequest = {
          introductionId,
          reason: data.reasonToDeny,
        };
        const res = await denyIntroduction(denyIntroductionRequest);
        handleIntroductionResponse(
          res,
          'Introduction request successfully denied'
        );
        return;
      }
    },
    [
      pageId,
      setError,
      introductionId,
      denyIntroduction,
      createIntroduction,
      approveIntroduction,
    ]
  );

  const onDecline = useCallback(() => {
    if (pageId) return onClose();

    setDenyBtnPressed(prev => !prev);
    setValue('reasonToDeny', undefined);
    clearErrors('reasonToDeny');
  }, [pageId, onClose, setDenyBtnPressed, setValue, clearErrors]);

  const footer = useMemo(() => {
    // own profile
    if (!pageId && !isDenyBtnPressed) {
      return {
        okText: 'accept',
        declineText: 'deny',
        isOkButtonDisabled: isApproveIntroductionLoading,
      };
    }
    // other profile
    if (pageId) {
      return {
        okText: 'send request',
        declineText: 'cancel',
        isOkButtonDisabled: isCreateIntroductionLoading,
      };
    }

    // confirm deny
    return {
      okText: 'confirm deny',
      declineText: 'cancel',
      isOkButtonDisabled: isDenyIntroductionLoading,
    };
  }, [
    pageId,
    isDenyBtnPressed,
    isApproveIntroductionLoading,
    isDenyIntroductionLoading,
    isCreateIntroductionLoading,
  ]);

  const title = useMemo(() => {
    return (
      <Stack justifyContent="center" alignItems="center" rowGap="12px">
        <Typography variant="h2">Introduction Request</Typography>
        {senderFirstName && senderLastName && (
          <Stack direction="row" columnGap="6px">
            <Typography variant="h4">From</Typography>
            <Typography variant="h4" color={colors.bgDark}>
              {`${senderFirstName} ${senderLastName}`}
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  }, [senderFirstName, senderLastName]);

  const getSubmitType = useCallback(() => {
    if (pageId) {
      return SubmitType.SENT_INTRODUCTION;
    }
    return isDenyBtnPressed
      ? SubmitType.DENY_INTRODUCTION
      : SubmitType.ACCEPT_INTRODUCTION;
  }, [pageId, isDenyBtnPressed]);

  return (
    <>
      <BaseModal
        boxSx={{ width: '875px', maxHeight: 756, overflowY: 'scroll' }}
        header={{
          component: !pageId ? title : null,
          text: 'Request introductions',
        }}
        isOpen={isOpen}
        toggle={onClose}
        footer={footer}
        onAccept={handleSubmit(onSubmitModal(getSubmitType()))}
        onDecline={onDecline}
      >
        <SnackbarCustom
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
        <Stack spacing="36px" mb="36px">
          <FormControl
            sx={{
              width: '50%',
              [theme.breakpoints.down('lg')]: { width: '100%' },
            }}
          >
            {!pageId && (isIntroducedFriendLoading || !introducedFriend) && (
              <Box>
                {/* Skeleton for Typography */}
                <Skeleton
                  variant="text"
                  width={120}
                  height={20}
                  sx={{ mb: '10px' }}
                />

                {/* Skeleton for SelectItem fields */}
                <Box display="flex" alignItems="center">
                  <Skeleton variant="circular" width={40} height={40} />
                  <Box ml={2}>
                    <Skeleton variant="text" width={120} height={20} />
                    <Skeleton variant="text" width={100} height={20} />
                  </Box>
                </Box>
              </Box>
            )}
            {introducedFriend && (
              <>
                <Typography variant="body4" mb="16px">
                  Introduce to:
                </Typography>
                <SelectItem
                  userId={introducedFriend.id}
                  avatarUrl={introducedFriend.avatar?.fileUrl || ''}
                  firstName={introducedFriend.firstName || ''}
                  lastName={introducedFriend.lastName || ''}
                  sport={introducedFriend.sports[0].name}
                />
              </>
            )}
            {!introductionId && (
              <>
                <Input
                  select
                  containerFlex="1"
                  caption="Introduce to:"
                  variant="outlined"
                  fullWidth
                  disabled={!myFriends?.friends.length}
                  placeholder="choose one"
                  loading={isMyFriendsLoading}
                  name="introducingToFriend"
                  error={!!errors.introducingToFriend}
                  helperText={errors.introducingToFriend?.message}
                  onSelectFieldChange={(value: any) => {
                    setValue(
                      'introducingToFriend',
                      value.props.userId as string
                    );
                  }}
                >
                  {/* to do: remove from friend interface case null, object must return fields not null */}
                  {!!myFriends?.friends.length &&
                    myFriends.friends.map(item => (
                      <SelectItem
                        key={item.userId}
                        userId={item.userId}
                        avatarUrl={item.avatarUrl || ''}
                        firstName={item.firstName || ''}
                        lastName={item.lastName || ''}
                        sport={item.sports[0]?.name || ''}
                      />
                    ))}
                </Input>
                {myFriends?.friends.length === 0 && (
                  <Typography
                    variant="body4"
                    mb="16px"
                    pt="20px"
                    color={theme.palette.text.accent}
                  >
                    {CANT_INTRODUCE_MSG}
                  </Typography>
                )}
              </>
            )}
          </FormControl>
          <Stack spacing="12px">
            <Typography variant="caption">Introduction text:</Typography>
            <TextField
              name="introductionText"
              sx={{
                '& .MuiOutlinedInput-input:disabled': {
                  opacity: 'unset !important',
                  WebkitTextFillColor: 'unset',
                },
              }}
              defaultValue={introduction ? introduction.message : ''}
              disabled={!!introduction || !pageId}
              register={register('introductionText')}
              error={!!errors.introductionText}
              helperText={errors.introductionText?.message}
              placeholder="Introduction text"
              height={160}
            />
          </Stack>
          {isDenyBtnPressed && (
            <FormControl fullWidth>
              <Input
                select
                selectItems={Object.values(CancelIntroductionReasonEnum).map(
                  val => ({ label: val, value: val })
                )}
                containerFlex="1"
                caption="Reason to deny:"
                variant="outlined"
                fullWidth
                placeholder="Choose one"
                register={register('reasonToDeny')}
                error={!!errors.reasonToDeny}
                helperText={errors.reasonToDeny?.message || ''}
              />
            </FormControl>
          )}
        </Stack>
      </BaseModal>
    </>
  );
};

export default RequestIntroductionModal;

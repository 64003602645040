export enum SubscriptionStatusesEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CompanyProductDefaultNameEnum {
  BASIC_COMPANY_PLAN = 'BASIC_COMPANY_PLAN',
  PRO_COMPANY_PLAN = 'PRO_COMPANY_PLAN',
  ADVANCED_COMPANY_PLAN = 'ADVANCED_COMPANY_PLAN',
}

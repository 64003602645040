import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

interface ISectionLayoutProps extends BoxProps {
  children: ReactNode;
}

const SectionLayout = ({ children, ...props }: ISectionLayoutProps) => {
  return (
    <Box
      component="section"
      sx={{
        bgcolor: 'background.white',
        p: { xs: '36px 16px', md: '24px', lg: '36px' },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default SectionLayout;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAuthData } from '../store/selectors/authSelector';

interface IuseCurrentPageUserId {
  userId: string | undefined;
  isOwnPage: boolean;
}

export const useCurrentPageUserId = (): IuseCurrentPageUserId => {
  const { id } = useParams();
  const authData = useSelector(selectAuthData);
  const [userDetails, setUserDetails] = useState<IuseCurrentPageUserId>({
    userId: undefined,
    isOwnPage: false,
  });

  useEffect(() => {
    try {
      // own page
      if (!id) {
        // authData.user may be undefined when user logs out
        return setUserDetails({
          userId: authData.user?.id,
          isOwnPage: true,
        });
      }
      // another user page
      setUserDetails({ userId: id, isOwnPage: false });
    } catch (e) {
      debugger;
    }
  }, [id, authData]);

  return userDetails;
};

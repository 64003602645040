import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import BlockUserIcon from '../../../../components/Icons/BlockUserIcon';
import RemoveConnectionIcon from '../../../../components/Icons/RemoveConnectionIcon';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { IPost } from '../../../../interfaces/post.interface';
import { useDeletePostMutation } from '../../../../store/apiSlice/post/postApi';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { selectAuthData } from '../../../../store/selectors/authSelector';

interface IProfilePostMoreMenuProps {
  post: IPost;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >;
}

const ProfilePostMoreMenu: React.FC<IProfilePostMoreMenuProps> = ({
                                                                    post,
                                                                    setSnackbarOpen,
                                                                    setSnackbarMessage,
                                                                    setSnackbarSeverity,
                                                                  }) => {
  const authData = useSelector(selectAuthData);
  const isMyPost = authData?.user?.id === post.userId;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deletePost] = useDeletePostMutation();

  const handleDeletePost = async (postId: string) => {
    try {
      await deletePost(postId).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Post deleted!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to delete post:', error);
    }
  };

  const myPostMenuItems = [
    {
      label: 'Remove post',
      icon: <RemoveConnectionIcon />,
      action: () => handleDeletePost(post.id),
    },
  ];

  const notMyPostMenuItems = [
    {
      label: 'Complain',
      icon: <BlockUserIcon />,
      action: () => {},
    },
  ];
  // TODO set logic for not my post when it will be implemented
  return (
    <>
      {isMyPost && (
        <>
          <IconButton
            aria-label="More"
            sx={{
              p: 0,
              width: 'auto',
              height: 'auto',
              minWidth: 'auto',
              minHeight: 'auto',
              '&:hover': { background: 'transparent' },
            }}
            id="show-more-button"
            aria-controls={open ? 'show-more' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ color: 'text.secondary' }} />
          </IconButton>

          <Menu
            sx={{
              pt: '0px',
              pb: '0px',
            }}
            id="show-more"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'show-more-button',
              disablePadding: true,
            }}
          >
            {isMyPost
              ? myPostMenuItems.length > 0 &&
              myPostMenuItems.map((item, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() => {
                    item.action();
                    handleClose();
                  }}
                  sx={{
                    display: 'flex',
                    gap: '6px',
                    fontSize: '14px',
                    p: '15px',
                  }}
                >
                  {item.icon} {item.label}
                </MenuItem>
              ))
              : notMyPostMenuItems.length > 0 &&
              notMyPostMenuItems.map((item, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() => {
                    item.action();
                    handleClose();
                  }}
                  sx={{
                    display: 'flex',
                    gap: '6px',
                    fontSize: '14px',
                    p: '15px',
                  }}
                >
                  {item.icon} {item.label}
                </MenuItem>
              ))}
          </Menu>
        </>
      )}
    </>
  );
};

export default ProfilePostMoreMenu;

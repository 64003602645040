import React, { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import PhotosIcon from '../Icons/PhotosIcon';
import UploadedFileIconElement from '../Auth/Elements/UploadedFileIconElement';

interface EmptyMediaBoxProps {
  text: string;
  icon: ReactNode;
}

const EmptyMediaBox: FC<EmptyMediaBoxProps> = ({ text, icon }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
        padding: '80px 0',
        bgcolor: 'background.light',
        border: '1px dashed',
        borderColor: 'background.dark',
      }}
    >
      {icon}
      <Typography variant="body4" sx={{}}>
        {text}
      </Typography>
    </Box>
  );
};

export default EmptyMediaBox;

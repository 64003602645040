import * as yup from 'yup';

const today = new Date();

export const sportSchema = yup.object().shape({
  sportIds: yup
    .array()
    .of(yup.string().required('Sport is required'))
    .min(1, 'At least one sport is required')
    .required('Sports are required'),
});

export const teamSchema = yup.object().shape({
  leagueId: yup
    .string()
    .required('League is required'),
  teamId: yup
    .string()
    .required('Team is required'),
  startDateInTeam: yup
    .date()
    .required('Start career date in team is required')
    .max(new Date(), 'Start career date in team cannot be in the future'),
  endDateInTeam: yup
    .date()
    .required('End career date in team is required')
    .min(
      yup.ref('startDateInTeam'),
      'End career date in team must be greater than start career in team date'
    )
    .test(
      'is-greater',
      'End career date in team must be greater than start career date in team ',
      function (value) {
        const { startDateInTeam } = this.parent;
        return value > startDateInTeam;
      }
    ),
});

export const sportBackgroundSchema = yup.object().shape({
  startCareerDate: yup
    .date()
    .required('Start career date is required')
    .max(today, 'Start career date cannot be in the future'),
  endCareerDate: yup
    .date()
    .required('End career date is required')
    .min(
      yup.ref('startCareerDate'),
      'End career date must be greater than start career date'
    )
    .test(
      'is-greater',
      'End career date must be greater than start career date',
      function (value) {
        const { startCareerDate } = this.parent;
        return value > startCareerDate;
      }
    ),

  description: yup
    .string()
    .nullable()
    .notRequired()
    .min(10, 'Description should be at least 10 characters long.')
    .max(500, 'Description should be at most 500 characters long.'),

  teams: yup
    .array()
    .of(teamSchema)
    .min(1, 'At least one team is required')
    .required('Teams are required'),
});

import { Box, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldError,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { ITeam } from '../../../../../interfaces/user-sport-background.interface';
import { useGetSportsQuery } from '../../../../../store/apiSlice/sportApi';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import DateInput from '../../../../MUIComponents/DateInput';
import Input from '../../../../MUIComponents/Input';
import { SportBackgroundFormInputs } from '../SportBackgroundItem/SportBackgroundItem';
import SportBackgroundTeamsBlock from '../SportBackgroundTeamsBlock/SportBackgroundTeamsBlock';

export interface ITeamWithPosition {
  position: number;
  team: ITeam | null;
}

interface IProps {
  sportBg: SportBackgroundFormInputs | null;
  register: UseFormRegister<SportBackgroundFormInputs>;
  errors: FieldErrors<SportBackgroundFormInputs>;
  onFieldChange: (field: keyof SportBackgroundFormInputs, value: any) => void;
  disabled: boolean;
  control: Control<SportBackgroundFormInputs, any>;
  selectedSportId: string | null;
}

const SportBackgroundFormBlock: React.FC<IProps> = ({
  sportBg,
  register,
  errors,
  onFieldChange,
  disabled,
  control,
  selectedSportId,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: sports = [],
    isLoading: isSportsLoading,
    isError: isSportsError,
  } = useGetSportsQuery();

  const [teamWithPosition, setTeamWithPosition] = useState<ITeamWithPosition[]>(
    sportBg
      ? sportBg?.teams.map((team, index) => ({
          position: index + 1, // p.1 position must start from 1
          team: team,
        }))
      : [{ position: 1, team: null }]
  );

  useEffect(() => {
    const teams = teamWithPosition.map(item => item.team);
    onFieldChange('teams', teams);
  }, [teamWithPosition]);

  const [teamElements, setTeamElements] = useState<number[]>(
    sportBg?.teams.length
      ? Array.from(Array(sportBg?.teams.length + 1).keys()).slice(1)
      : [1]
  );

  useEffect(() => {
    if (teamElements?.length <= 0) {
      setTeamElements([1]);
    }
  }, [teamElements]);

  const addOneMoreTeam = () => {
    setTeamElements(prevState => {
      const maxElement = Math.max(...prevState);

      return [...prevState, maxElement + 1];
    });
  };

  const deleteTeam = (value: number) => {
    setTeamElements(prev =>
      prev.filter(backgroundValue => backgroundValue !== value)
    );
    setTeamWithPosition(prev =>
      prev.filter(teamWithPosition => teamWithPosition.position !== value)
    );
  };

  return (
    <Box display="flex" flexDirection={'column'}>
      <Box>
        <Typography variant="body4" fontWeight={700} padding={'0 2px'}>
          Your career timeline:
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        rowGap={mobileView ? '1.75rem' : '36px'}
        columnGap={'16px'}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            gap: '1.375rem',
            [theme.breakpoints.down('md')]: {
              gridColumn: 'span 2',
              display: 'block',
              gap: '1rem',
            },
          })}
        >
          <DateInput<SportBackgroundFormInputs>
            control={control}
            name="startCareerDate"
            error={errors.startCareerDate as FieldError}
            helperText={errors.startCareerDate?.message}
            placeholder="Your start date"
            onChange={value => onFieldChange('startCareerDate', dayjs(value))}
            disabled={disabled}
          />
          <DateInput<SportBackgroundFormInputs>
            control={control}
            name="endCareerDate"
            error={errors.endCareerDate as FieldError}
            helperText={errors.endCareerDate?.message}
            placeholder="Your end date"
            onChange={value => onFieldChange('endCareerDate', dayjs(value))}
            disabled={disabled}
          />
        </Box>
        <Box display="flex" gridColumn="span 2">
          <Input
            caption="Describe your sport background:"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            placeholder="Few words about your sports experience"
            register={register('description', {
              onChange: e => onFieldChange('description', e.target.value),
            })}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ''}
            disabled={disabled}
            containerFlex="1"
          />
        </Box>
      </Box>
      {teamElements.map(value => {
        return (
          <Box key={value}>
            <SportBackgroundTeamsBlock
              savedTeam={sportBg?.teams[value - 1]} //p.2 index must match with teamElements index
              selectedSportName={
                sports.find(sport => sport.id === selectedSportId)?.name ?? null
              }
              teamElementPosition={value}
              deleteTeam={deleteTeam}
              teamWithPosition={teamWithPosition}
              setTeamWithPosition={setTeamWithPosition}
              register={register}
              control={control}
              errors={errors}
              disabled={disabled}
            />
          </Box>
        );
      })}
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        border={'1px solid'}
        borderColor={'background.dark'}
        borderRadius={'4px'}
        padding={'1.375rem 0'}
        mt="28px"
        onClick={addOneMoreTeam}
        style={{ cursor: 'pointer' }}
      >
        <Typography typography={'badge2'}>ADD ONE MORE TEAM</Typography>
      </Box>
    </Box>
  );
};

export default SportBackgroundFormBlock;

import { SvgIcon, SvgIconProps } from '@mui/material';

const VolumeOffIcon = ({ width, height, sx, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: width || 28,
        height: height || 28,
        fill: 'none',
        ...sx,
      }}
      {...rest}
    >
      <path
        d="M3.5 17.8182H7L11.0833 22.75H12.8333V5.25H11.0833L7 10.5H3.5V17.8182Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.666 11.6667L23.3327 16.3334M23.3327 11.6667L18.666 16.3334"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default VolumeOffIcon;

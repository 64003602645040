import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface EditIconProps extends SvgIconProps {
  iconColor?: string;
}

const EditIcon = ({ iconColor, width, height, sx, ...rest }: EditIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{
        width: width || 24,
        height: height || 24,
        fill: 'none',
        ...sx,
      }}
      {...rest}
    >
      <path
        d="M7 7H4L4 20L17 20L17 17"
        stroke={iconColor ? iconColor : '#727F93'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M20.385 6.58499C20.7788 6.19114 21.0001 5.65697 21.0001 5.09998C21.0001 4.543 20.7788 4.00883 20.385 3.61498C19.9912 3.22114 19.457 2.99988 18.9 2.99988C18.343 2.99988 17.8088 3.22114 17.415 3.61498L9 12V15H12L20.385 6.58499Z"
        stroke={iconColor ? iconColor : '#727F93'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M16 5L19 8"
        stroke={iconColor ? iconColor : '#727F93'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default EditIcon;

import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  useGetNumberOfApprovedCandidatesQuery,
  useGetNumberOfCandidatesQuery,
  useGetTimeToFillQuery,
} from '../../../store/apiSlice/analitics/analiticsApi';
import PersonCloneIcon from '../../Icons/PersonCloneIcon';
import PortfolioIcon from '../../Icons/PortfolioIcon';
import ClockIcon from '../../Icons/ClockIcon';
import { IFormattedTimeToFillAnalytic } from '../../../interfaces/analytic.interface';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const AnalyticsCards = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const formatTimeToFill = (timeToFill?: IFormattedTimeToFillAnalytic) => {
    return `${timeToFill?.days ?? 0}d ${timeToFill?.hours ?? 0}h ${timeToFill?.minutes ?? 0}m`;
  };

  const {
    data: numberOfCandidatesData,
    isLoading: isLoadingNumberOfCandidates,
    error: errorNumberOfCandidates,
  } = useGetNumberOfCandidatesQuery();

  const {
    data: numberOfApprovedCandidatesData,
    isLoading: isLoadingNumberOfApprovedCandidates,
    error: errorNumberOfApprovedCandidates,
  } = useGetNumberOfApprovedCandidatesQuery();

  const {
    data: timeToFillData,
    isLoading: isLoadingTimeToFill,
    error: errorTimeToFill,
  } = useGetTimeToFillQuery();

  return (
    <Box
      padding={isSmallScreen ? '16px' : '36px'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'start'}
      alignItems={'start'}
      width={'100%'}
      bgcolor="#fff"
      gap={isSmallScreen ? '28px' : '48px'}
    >
      <Typography
        sx={{
          fontSize: isSmallScreen ? '26px' : mobileView ? '28px' : '34px',
        }}
        variant={'h2'}
      >
        Analytics
      </Typography>
      <Box
        id="pdf-top-statisctics"
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
      >
        <Box
          gap={'22px'}
          display={'flex'}
          flexDirection={mobileView ? 'column' : 'row'}
          width={'100%'}
          bgcolor={'#fff'}
        >
          <Box
            flex={1}
            gap={'22px'}
            border="1px solid #E8EBEF"
            padding={isSmallScreen ? '16px' : '22px 22px 28px 22px'}
            bgcolor={'background.light'}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              marginBlock={'22px'}
              alignItems={'center'}
              gap={'12px'}
            >
              <PersonCloneIcon />
              <Typography
                component={'span'}
                variant="body4"
                fontSize={'14px'}
                lineHeight={'normal'}
              >
                Number <br />
                of candidates
              </Typography>
            </Box>
            {isLoadingNumberOfCandidates ? (
              <CircularProgress />
            ) : errorNumberOfCandidates ? (
              <Typography color="error">Failed to load data</Typography>
            ) : (
              <Typography
                component={'span'}
                variant="body4"
                fontSize={'42px'}
                lineHeight={'normal'}
              >
                {numberOfCandidatesData?.data?.currentMonthApplications ??
                  'N/A'}
              </Typography>
            )}
            <Box
              display={'flex'}
              flexDirection={'row'}
              marginTop={'28px'}
              gap={'4px'}
            >
              <Typography
                variant={'caption'}
                sx={{
                  fontSize: '12px',
                  color:
                    numberOfCandidatesData?.data?.percentageChange !==
                      undefined &&
                    numberOfCandidatesData.data.percentageChange >= 0
                      ? '#19851D'
                      : '#E92726',
                  fontWeight: 500,
                }}
              >
                {numberOfCandidatesData?.data?.percentageChange !== undefined
                  ? `${numberOfCandidatesData.data.percentageChange.toFixed(2)}%`
                  : 'N/A'}
              </Typography>
              <Typography
                variant={'caption'}
                sx={{ fontSize: '12px', fontWeight: 500 }}
              >
                {' '}
                This month
              </Typography>
            </Box>
          </Box>

          {/* Application Completion Rates Card */}
          <Box
            flex={1}
            gap={'22px'}
            border="1px solid #E8EBEF"
            padding={isSmallScreen ? '16px' : '22px 22px 28px 22px'}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              marginBlock={'22px'}
              alignItems={'center'}
              gap={'12px'}
            >
              <PortfolioIcon />
              <Typography
                component={'span'}
                variant="body4"
                fontSize={'14px'}
                lineHeight={'normal'}
              >
                Application <br />
                completion rates
              </Typography>
            </Box>
            {isLoadingNumberOfApprovedCandidates ? (
              <CircularProgress />
            ) : errorNumberOfApprovedCandidates ? (
              <Typography color="error">Failed to load data</Typography>
            ) : (
              <Typography
                component={'span'}
                variant="body4"
                fontSize={'42px'}
                lineHeight={'normal'}
              >
                {numberOfApprovedCandidatesData?.data
                  ?.approvalPercentageThisMonth ?? 'N/A'}
                %
              </Typography>
            )}
            <Box
              display={'flex'}
              flexDirection={'row'}
              marginTop={'28px'}
              gap={'4px'}
            >
              <Typography
                variant={'caption'}
                sx={{
                  fontSize: '12px',
                  color:
                    numberOfApprovedCandidatesData?.data?.percentageChange !==
                      undefined &&
                    numberOfApprovedCandidatesData.data.percentageChange >= 0
                      ? '#19851D'
                      : '#E92726',
                  fontWeight: 500,
                }}
              >
                {numberOfApprovedCandidatesData?.data?.percentageChange !==
                undefined
                  ? `${numberOfApprovedCandidatesData.data.percentageChange.toFixed(2)}%`
                  : 'N/A'}
              </Typography>
              <Typography
                variant={'caption'}
                sx={{ fontSize: '12px', fontWeight: 500 }}
              >
                {' '}
                This month
              </Typography>
            </Box>
          </Box>

          {/* Time to Fill Card */}
          <Box
            flex={1}
            gap={'22px'}
            border="1px solid #E8EBEF"
            padding={isSmallScreen ? '16px' : '22px 22px 28px 22px'}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              marginBlock={'22px'}
              alignItems={'center'}
              gap={'12px'}
            >
              <ClockIcon />
              <Typography
                component={'span'}
                variant="body4"
                fontSize={'14px'}
                lineHeight={'normal'}
              >
                Time to fill
              </Typography>
            </Box>
            {isLoadingTimeToFill ? (
              <CircularProgress />
            ) : errorTimeToFill ? (
              <Typography color="error">Failed to load data</Typography>
            ) : (
              <Typography
                component={'span'}
                variant="body4"
                fontSize={'42px'}
                lineHeight={'normal'}
              >
                {formatTimeToFill(
                  timeToFillData?.data?.quickestTimeToFillThisMonth
                )}
              </Typography>
            )}
            <Box
              display={'flex'}
              flexDirection={'row'}
              marginTop={'28px'}
              gap={'4px'}
            >
              <Typography
                variant={'caption'}
                sx={{
                  fontSize: '12px',
                  color:
                    timeToFillData?.data?.percentageChange !== undefined &&
                    timeToFillData.data.percentageChange >= 0
                      ? '#19851D'
                      : '#E92726',
                  fontWeight: 500,
                }}
              >
                {timeToFillData?.data?.percentageChange !== undefined
                  ? `${timeToFillData.data.percentageChange.toFixed(2)}%`
                  : 'N/A'}
              </Typography>
              <Typography
                variant={'caption'}
                sx={{ fontSize: '12px', fontWeight: 500 }}
              >
                {' '}
                This month
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AnalyticsCards;

import React, { FC, useRef, useState } from 'react';
import { AcceptedFileTypeEnum } from './UploadFileMenu';
import { IconButton } from '@mui/material';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface IUploadedVideoBlockProps {
  acceptedFileType: AcceptedFileTypeEnum;
  file: File;
}

const UploadedVideoBlock: FC<IUploadedVideoBlockProps> = ({
  acceptedFileType,
  file,
}) => {
  const { theme } = useThemeContext();
  const videoPreviewRef = useRef<HTMLVideoElement>(null);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const handlePlayButtonClick = () => {
    setShowControls(true);
    setIsPlaying(true);
    if (videoPreviewRef.current) {
      videoPreviewRef.current.play();
    }
  };

  const handleVideoClick = () => {
    setShowControls(prev => !prev);
  };

  return (
    <>
      {acceptedFileType === AcceptedFileTypeEnum.VIDEO && (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <video
            ref={videoPreviewRef}
            controls={showControls}
            onClick={handleVideoClick}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <source src={URL.createObjectURL(file)} type={file.type} />
          </video>

          {!isPlaying && (
            <IconButton
              onClick={handlePlayButtonClick}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '40px',
                height: '40px',
                backgroundColor: theme.palette.text.accent,
                color: 'white',
              }}
            >
              <PlayArrowOutlinedIcon
                sx={{
                  minWidth: '32px',
                  minHeight: '32px',
                }}
              />
            </IconButton>
          )}
        </div>
      )}
    </>
  );
};

export default UploadedVideoBlock;

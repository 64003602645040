import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { companySchema } from '../../../../validation/company-validation';
import {
  loginSchema,
  verifyCodeCompanySchema,
} from '../../../../validation/auth-validation';
import {
  useLoginMutation,
  useRegistrationMutation,
  useResendVerifyCodeMutation,
  useVerifyCodeMutation,
} from '../../../../store/apiSlice/auth/authApi';
import { errorHelper } from '../../../../utils/helper/error-helper';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../../enums/cookie-enum';
import { useLocation } from 'react-router-dom';
import {
  ILoginRequest,
  IRegistrationRequest,
} from '../../../../interfaces/auth.interface';
import { CompanyRegistrationForm } from '../../../../components/Company/CompanyAuth/CompanyRegistrationForm';
import { CompanyVerifyCodeForm } from '../../../../components/Company/CompanyAuth/CompanyVerifyCodeForm';
import CompanyLoginForm from '../../../../components/Company/CompanyAuth/CompanyLoginForm';
import { setAuthData } from '../../../../store/apiSlice/auth/authSlice';
import { useDispatch } from 'react-redux';

interface RegistrationFormInputs {
  email: string;
  password: string;
  confirmPassword: string;
}

interface VerifyCodeFormInputs {
  verificationCode: string;
}

interface LoginFormInputs {
  email: string;
  password: string;
}

interface AccountSetupFormProps {
  onSubmitAccountSetup: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
}

const AccountSetupForm: React.FC<AccountSetupFormProps> = ({
  onSubmitAccountSetup,
  setStep,
  step,
}) => {
  const dispatch = useDispatch();
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarError, setSnackbarError] = useState<boolean>(false);
  const [isRegistrationComplete, setIsRegistrationComplete] =
    useState<boolean>(false);
  const [isShowLogin, setIsShowLogin] = useState<boolean>(false);
  const [savedEmail, setSavedEmail] = useState<string>('');
  const [savedPassword, setSavedPassword] = useState<string>('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inviteEmail = searchParams.get('inviteEmail');
  const inviteToken = searchParams.get('inviteToken');

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  // Logic for registration

  const [registration] = useRegistrationMutation();

  const {
    reset: resetRegistrationForm,
    register: registerRegistration,
    handleSubmit: handleRegistrationSubmit,
    formState: {
      errors: registrationErrors,
      dirtyFields,
      isValid: isRegistrationValid,
    },
  } = useForm<RegistrationFormInputs>({
    criteriaMode: 'all',
    resolver: yupResolver(companySchema, { abortEarly: false }),
    mode: 'onChange',
  });

  const registrationFormSubmit = async (data: RegistrationFormInputs) => {
    try {
      const registrationData: IRegistrationRequest = {
        email: data.email,
        password: data.password,
      };

      if (!inviteEmail || !inviteToken) {
        throw new Error('Invite email or token is missing');
      }
      await registration({
        inviteToken,
        inviteEmail,
        registrationData,
      }).unwrap();

      setSavedEmail(data.email);
      setSavedPassword(data.password);
      setIsRegistrationComplete(true);
      setSnackbarMessage(
        'Registration complete please verify your email to continue'
      );
      setSnackbarError(false);
      resetRegistrationForm();
    } catch (error) {
      setIsRegistrationComplete(false);
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
    }
  };

  // Logic for verification code

  const {
    reset: resetVerifyCodeForm,
    register: registerVerifyCode,
    handleSubmit: handleVerifyCodeSubmit,
    formState: { errors: verifyCodeErrors, isValid: isVerifyCodeValid },
  } = useForm<VerifyCodeFormInputs>({
    resolver: yupResolver(verifyCodeCompanySchema),
  });

  const [verifyCode] = useVerifyCodeMutation();

  const verifyCodeFormSubmit = async (data: VerifyCodeFormInputs) => {
    try {
      if (!savedEmail || !savedPassword) {
        throw new Error('Email or password is missing');
      }

      const verifyCodeData = {
        ...data,
        email: savedEmail,
        password: savedPassword,
      };

      const result = await verifyCode(verifyCodeData).unwrap();

      const userId = result.user.id;
      const email = result.user.email;
      const role = result.user.role || '';

      Cookies.set(CookieEnum.ACCESS_TOKEN, result.accessToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.REFRESH_TOKEN, result.refreshToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.USER, JSON.stringify({ id: userId, email }), {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.ROLE, role, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });

      dispatch(setAuthData({ user: result.user }));

      setIsRegistrationComplete(false);
      setSnackbarMessage('Verification successful');
      setSnackbarError(false);
      resetVerifyCodeForm();
      onSubmitAccountSetup();
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
    }
  };

  // Logic for login

  const [login] = useLoginMutation();

  const {
    register: registerLogin,
    handleSubmit: handleLoginSubmit,
    formState: { errors: loginErrors, isValid: isLoginValid },
    reset: resetLoginForm,
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema),
  });

  const onLoginSubmit = async (data: LoginFormInputs) => {
    try {
      const loginData: ILoginRequest = {
        email: data.email,
        password: data.password,
      };
      const result = await login(loginData).unwrap();
      const userId = result.user.id;
      const email = result.user.email || '';
      const role = result.user.role || '';

      Cookies.set(CookieEnum.ACCESS_TOKEN, result.accessToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.REFRESH_TOKEN, result.refreshToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.USER, JSON.stringify({ id: userId, email }), {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.ROLE, role, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });

      dispatch(setAuthData({ user: result.user }));

      setSnackbarMessage('Login successful');
      setIsRegistrationComplete(false);
      setSnackbarError(false);
      resetLoginForm();
      onSubmitAccountSetup();
    } catch (error: any) {
      if (
        error.hasOwnProperty('data') &&
        error.data.statusCode === 401 &&
        error?.data?.message?.toLowerCase().trim() ===
          'User is not confirmed.'.toLowerCase().trim()
      ) {
        setSnackbarMessage('User is not confirmed. Please verify your email');
        setSnackbarError(true);
        setIsShowLogin(false);
        setIsRegistrationComplete(true);
        setSavedEmail(data.email);
        setSavedPassword(data.password);
        return;
      }
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
    }
  };

  // Logic for resend verification code

  const [resendVerificationCode] = useResendVerifyCodeMutation();

  const resendVerificationCodeSubmit = async (email: string): Promise<void> => {
    try {
      await resendVerificationCode({ email }).unwrap();
      setSnackbarMessage(
        `Verification code resent, please check your email: ${savedEmail}`
      );
      setSnackbarError(false);
      setIsShowLogin(false);
      setIsRegistrationComplete(true);
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
    }
  };

  return (
    <>
      {isShowLogin && (
        <CompanyLoginForm
          registerLogin={registerLogin}
          loginErrors={loginErrors}
          loginFormSubmit={onLoginSubmit}
          handleLoginSubmit={handleLoginSubmit}
          isLoginValid={isLoginValid}
          step={step}
          setStep={setStep}
          setIsShowRegistration={setIsShowLogin}
          setIsShowLogin={setIsShowLogin}
          inviteEmail={inviteEmail}
        />
      )}

      {!isShowLogin && isRegistrationComplete && (
        <CompanyVerifyCodeForm
          registerVerifyCode={registerVerifyCode}
          verifyCodeErrors={verifyCodeErrors}
          savedEmail={savedEmail}
          verifyCodeFormSubmit={verifyCodeFormSubmit}
          handleVerifyCodeSubmit={handleVerifyCodeSubmit}
          isVerifyCodeValid={isVerifyCodeValid}
          step={step}
          setStep={setStep}
          setIsRegistrationComplete={setIsRegistrationComplete}
          resendVerificationCodeSubmit={resendVerificationCodeSubmit}
        />
      )}

      {!isShowLogin && !isRegistrationComplete && (
        <CompanyRegistrationForm
          registerRegistration={registerRegistration}
          registrationErrors={registrationErrors}
          registrationFormSubmit={registrationFormSubmit}
          handleRegistrationSubmit={handleRegistrationSubmit}
          isRegistrationValid={isRegistrationValid}
          step={step}
          setStep={setStep}
          inviteEmail={inviteEmail}
          setIsRegistrationComplete={setIsRegistrationComplete}
          setIsShowLogin={setIsShowLogin}
          dirtyFields={dirtyFields}
        />
      )}

      <SnackbarCustom
        open={!!snackbarMessage}
        onClose={handleCloseSnackbar}
        message={snackbarMessage || ''}
        severity={
          snackbarError
            ? SnackbarSeverityEnum.ERROR
            : SnackbarSeverityEnum.SUCCESS
        }
      />
    </>
  );
};

export default AccountSetupForm;

import { SvgIcon, SvgIconProps } from '@mui/material';

const JobLocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 18, height: 18, fill: 'none' }}
      {...props}
      viewBox="0 0 18 18"
    >
      <path
        d="M6.75 8.25C6.75 8.84674 6.98705 9.41903 7.40901 9.84099C7.83097 10.2629 8.40326 10.5 9 10.5C9.59674 10.5 10.169 10.2629 10.591 9.84099C11.0129 9.41903 11.25 8.84674 11.25 8.25C11.25 7.65326 11.0129 7.08097 10.591 6.65901C10.169 6.23705 9.59674 6 9 6C8.40326 6 7.83097 6.23705 7.40901 6.65901C6.98705 7.08097 6.75 7.65326 6.75 8.25Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00001 16.0714L13.2428 12.4928C14.0818 11.6536 14.6533 10.5845 14.8847 9.42067C15.1162 8.25679 14.9974 7.05041 14.5433 5.95407C14.0891 4.85773 13.3201 3.92068 12.3334 3.2614C11.3467 2.60213 10.1867 2.25024 9 2.25024C7.81333 2.25024 6.6533 2.60213 5.66661 3.2614C4.67993 3.92068 3.91089 4.85773 3.45676 5.95407C3.00262 7.05041 2.88378 8.25679 3.11527 9.42067C3.34675 10.5845 3.91817 11.6536 4.75725 12.4928L9.00001 16.0714Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default JobLocationIcon;

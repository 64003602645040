import { SvgIcon, SvgIconProps } from '@mui/material';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface BlockUserIconProps extends SvgIconProps {
  active?: boolean;
}

const BlockUserIcon = ({ active, ...svgProps }: BlockUserIconProps) => {
  const { theme } = useThemeContext();

  let color;

  if (!svgProps.stroke)
    color = active
      ? theme.palette.button.primary
      : theme.palette.text.secondary;

  return (
    <SvgIcon
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 18, height: 18, fill: 'none' }}
      viewBox="0 0 18 18"
    >
      <path
        d="M4.22703 13.773C3.60023 13.1462 3.10303 12.4021 2.76381 11.5831C2.42459 10.7642 2.25 9.88642 2.25 9C2.25 8.11358 2.42459 7.23583 2.76381 6.41689C3.10303 5.59794 3.60023 4.85382 4.22703 4.22703C4.85382 3.60023 5.59794 3.10303 6.41689 2.76381C7.23583 2.42459 8.11358 2.25 9 2.25C9.88642 2.25 10.7642 2.42459 11.5831 2.76381C12.4021 3.10303 13.1462 3.60023 13.773 4.22703M4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703M4.22703 13.773L13.773 4.22703"
        stroke={svgProps.stroke || color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default BlockUserIcon;

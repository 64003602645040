import { useCallback, useEffect, useState } from 'react';
import ChatInfoForm from './ChatInfoForm';
import ChatMembersForm from './ChatMembersForm';
import { useCreateGroupChatMutation } from '../../../../store/apiSlice/chat/groupApi';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../../enums/cookie-enum';
import { IUser } from '../../../../store/apiSlice/chat/chatApi';
import { useDispatch } from 'react-redux';
import {
  setChatsLoaded,
  setChatState,
  setChatType,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import { ChatStateEnum, ChatTypeEnum } from '../../../../enums/chat-enum';
import { useNavigate } from 'react-router-dom';

export interface ICreateGroupChat {
  name: string;
  description?: string;
  thumbnail?: File;
  members: IUser[];
}

const NewGroupChat = () => {
  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState<Partial<ICreateGroupChat>>({});
  const [createGroupChat] = useCreateGroupChatMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreateGroup = useCallback(async () => {
    const fd = new FormData();
    fd.append('name', formData.name!);
    fd.append('description', formData.description || '');
    fd.append('userId', JSON.parse(Cookies.get(CookieEnum.USER) || '').id);
    fd.append('thumbnail', formData.thumbnail!);

    formData.members?.forEach((member, index) =>
      Object.keys(member).forEach(key =>
        fd.append(`members[${index}][${key}]`, member[key])
      )
    );

    createGroupChat(fd)
      .unwrap()
      .then(group => {
        dispatch(setSelectedChat(group));
        dispatch(setChatType(ChatTypeEnum.GROUP));
        dispatch(setChatState(ChatStateEnum.CHAT));
        dispatch(setChatsLoaded());
      })
      .catch(console.log);
  }, [formData, createGroupChat, dispatch]);

  useEffect(() => {
    if (step === 3) handleCreateGroup().catch(console.log);
  }, [step, handleCreateGroup]);

  return (
    <>
      {step === 1 ? (
        <ChatInfoForm
          formData={formData}
          setFormData={setFormData}
          setStep={setStep}
        />
      ) : (
        <ChatMembersForm setFormData={setFormData} setStep={setStep} />
      )}
    </>
  );
};

export default NewGroupChat;

import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}
function UploadDocumentIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      className={cn({ [className!]: className !== undefined })}
    >
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M16.333 3.5v5.833h5.834"
      ></path>
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M5.833 24.5h16.334V9.009L16.864 3.5H5.834v21zM14 12.833v7"
      ></path>
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M11.083 15.114L14 12.197l2.917 2.917"
      ></path>
    </svg>
  );
}

export default UploadDocumentIcon;

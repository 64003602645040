import { SvgIcon, SvgIconProps } from '@mui/material';

const CommentNotificationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 14, height: 14, fill: 'none' }}
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M12.25 10.5V2.33334H1.75V10.5H4.66667V12.25L7.58333 10.5H12.25Z"
        stroke="white"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default CommentNotificationIcon;

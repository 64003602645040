import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import BaseModal from '../../../../components/MUIComponents/BaseModal';
import TextField from '../../../../components/MUIComponents/TextField';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import JobPostFilterSingleSelect from '../../../JobPostsPage/components/JobPostsSidebar/JobPostsFilters/JobPostFilterSingleSelect';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';

import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { RejectReasonReasonEnum } from '../../../../enums/reject-reason-type-enum';
import { useRejectJobApplyMutation } from '../../../../store/apiSlice/jobpost/jobUserApplyApi';
import { IJobPostApplyRejectRequest } from '../../../../interfaces/jobpost.interface';
import createArrayFromEnum from '../../../../utils/createArrayFromEnum';
import { IUser } from '../../../../interfaces/user.interface';
import { RejectJobpostApplySchema } from '../../../../validation/cancel-jobpost-apply-validation';
import { errorHelper } from '../../../../utils/helper/error-helper';

interface RejectJobpostApplyFormInputs {
  rejectedReason?: RejectReasonReasonEnum | undefined;
  rejectedNote?: string | undefined;
}

interface RejectionInfoModalProps {
  isOpen?: boolean;
  onClose: () => void;
  applicationId: string;
  userData: IUser;
}

const RejectionInfoModal = ({
  isOpen = false,
  onClose,
  applicationId,
  userData,
}: RejectionInfoModalProps) => {
  const [rejectJob, { isLoading }] = useRejectJobApplyMutation();
  const rejectReasonArray = createArrayFromEnum(
    RejectReasonReasonEnum,
    'rejectionReason'
  );
  const [reasonState, setReasonState] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RejectJobpostApplyFormInputs>({
    resolver: yupResolver(RejectJobpostApplySchema),
  });

  const handleCloseSnackbar = () => setSnackbarOpen(false);

  const handleReject = async (data: RejectJobpostApplyFormInputs) => {
    if (!reasonState) {
      setSnackbarMessage('Please select a rejection reason');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    const rejectData: IJobPostApplyRejectRequest = {
      rejectedReason: reasonState as RejectReasonReasonEnum,
      rejectedNote: data.rejectedNote,
    };

    try {
      await rejectJob({ applicationId, data: rejectData }).unwrap();
      setSnackbarMessage('Rejection successful');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const modalTitle = useMemo(() => {
    return (
      <>
        <Typography variant="h2" textAlign="left">
          Reject Application
        </Typography>
      </>
    );
  }, []);

  const modalFooter = useMemo(
    () => (
      <Box mt={3}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap="16px"
          width="100%"
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={handleSubmit(handleReject)}
              disabled={isLoading}
            >
              {isLoading ? 'Rejecting...' : 'Reject'}
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    ),
    [onClose, handleSubmit, handleReject, isLoading]
  );

  return (
    <BaseModal
      modalSx={{ borderColor: 'transparent !important' }}
      isOpen={isOpen}
      toggle={onClose}
      header={{ component: modalTitle }}
      footer={{ component: modalFooter }}
      styleEscapeBtnStylesBelow={{ top: 26 }}
    >
      <Stack spacing="36px">
        <Box width="50%">
          <JobPostFilterSingleSelect
            label={'rejectionReason'}
            placeholder="Select Rejection Reason"
            selectItems={rejectReasonArray}
            setFilterState={setReasonState}
            filterState={reasonState}
          />
          {errors.rejectedReason && (
            <Typography color="error" variant="caption">
              {errors.rejectedReason.message}
            </Typography>
          )}
        </Box>
        <Box width="100%">
          <Typography component="h4" variant="badge2" mb="10px">
            Add notes:
          </Typography>
          <TextField
            placeholder="Write your message here"
            height={160}
            register={register('rejectedNote')}
            error={!!errors.rejectedNote}
            helperText={errors.rejectedNote ? errors.rejectedNote.message : ''}
          />
        </Box>
      </Stack>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default RejectionInfoModal;

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { companyDetailsSchema } from '../../../../validation/company-validation';
import StepForm from '../../../../components/Auth/VerifyAccount/StepForm';

import { CompanyDetailsFormInputs } from './CompanyRegistration';
import CompanyDetails, { RegistrationFormInputs } from './CompanyDetails';
import { Box } from '@mui/material';

interface CompanyDetailsFormProps {
  onSubmitCompanyDetails: SubmitHandler<CompanyDetailsFormInputs>;
  formData: Partial<CompanyDetailsFormInputs>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
}

const CompanyDetailsForm: React.FC<CompanyDetailsFormProps> = ({
  onSubmitCompanyDetails,
  formData,
  setStep,
  step,
}) => {
  const [industryFilterState, setIndustryFilterState] = useState<string>('');
  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RegistrationFormInputs>({
    resolver: yupResolver(companyDetailsSchema),
    defaultValues: formData,
    mode: 'onChange',
  });


  const handleCompanyDetailsSubmit = (data: RegistrationFormInputs) => {
    onSubmitCompanyDetails({
      ...data,
      industryIds: industryFilterState.split(','),
    });
  };

  return (
    <StepForm
      title="Company details"
      onNext={handleSubmit(handleCompanyDetailsSubmit)}
      nextDisabled={!isValid || industryFilterState.length === 0}
      step={step}
      setStep={setStep}
      needBackButton={false}
    >
      <Box
        component="form"
        onSubmit={() => handleSubmit(handleCompanyDetailsSubmit)}
        display="flex"
        flexDirection="column"
      >
        <CompanyDetails
          setIndustryFilterState={setIndustryFilterState}
          setValue={setValue}
          watch={watch}
          register={register}
          industryFilterState={industryFilterState}
          errors={errors}
          control={control}
        />
      </Box>
    </StepForm>
  );
};

export default CompanyDetailsForm;

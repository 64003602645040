import { Box, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CustomStepper from '../../../../components/MUIComponents/CustomStepper';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { IUserFileDB } from '../../../../interfaces/file.interface';
import AccountSetupForm from './AccountSetupForm';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import { useModalManager } from '../../../../hooks/useModalManager';
import FlagIcon from '../../../../components/Icons/FlagIcon';
import Button from '../../../../components/MUIComponents/Button';
import { useUploadIdConfirmationFilesMutation } from '../../../../store/apiSlice/userFilesApi';
import { errorHelper } from '../../../../utils/helper/error-helper';
import {
  useCreateCompanyInfoMutation,
  useGetMyCompanyInfoMutation,
} from '../../../../store/apiSlice/userCompanyInfoApi';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../../enums/cookie-enum';
import Loader from '../../../../components/MUIComponents/Loader';
import CompanyStepsNeededAuth from './CompanyStepsNeededAuth';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

export interface CompanyDetailsFormInputs {
  companyName: string;
  industryIds: string[];
  address: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  phone: string;
  contactEmail: string;
  website?: string;
}

interface CompanyFormsData extends CompanyDetailsFormInputs {
  documents: File[];
}

interface FileUploadFormInputs {
  documents: File[];
}

const CompanyRegistration = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { currentModal, toggleModal } = useModalManager();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<IUserFileDB[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const [isCompanyInfoExists, setIsCompanyInfoExists] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [
    createCompanyInfo,
    {
      isLoading: createCompanyInfoIsLoading,
      isError: createCompanyInfoIsError,
      error: createCompanyInfoError,
      data: createCompanyInfoResponseData,
    },
  ] = useCreateCompanyInfoMutation();

  const [
    uploadIdConfirmationFiles,
    {
      isLoading: idConfirmationFilesIsLoading,
      isError: idConfirmationFilesIsError,
      error: idConfirmationFilesError,
      data: idConfirmationFilesResponseData,
    },
  ] = useUploadIdConfirmationFilesMutation();

  const [
    getMyCompanyInfo,
    {
      isLoading: getMyCompanyInfoIsLoading,
      isError: getMyCompanyInfoIsError,
      error: getMyCompanyInfoError,
      data: getMyCompanyInfoResponseData,
    },
  ] = useGetMyCompanyInfoMutation();

  useEffect(() => {
    (async () => {
      try {
        if (Cookies.get(CookieEnum.ACCESS_TOKEN)) {
          const response = await getMyCompanyInfo();
          if (response.hasOwnProperty('data') && response.data !== null) {
            setSnackbarMessage(
              'You have already verified company info.\nYou can upload documents to verify your company if needed.'
            );

            setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
            setSnackbarOpen(true);
            setIsCompanyInfoExists(true);
            setStep(3);
          }
        }
      } catch (error) {
        const errorMessage = errorHelper(error);
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setIsCompanyInfoExists(false);
        setSnackbarOpen(true);
      }
    })();
  }, []);

  const onSubmitAccountSetup = (): void => {
    setStep(step + 1);
  };

  const onSubmitCompanyDetails: SubmitHandler<
    CompanyDetailsFormInputs
  > = data => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };

  const onSubmitFiles: SubmitHandler<FileUploadFormInputs> = async data => {
    try {
      const finalData = { ...formData, ...data } as CompanyFormsData;

      if (!isCompanyInfoExists) {
        const companyInfoData = {
          companyName: finalData.companyName,
          industryIds: finalData.industryIds,
          address: finalData.address,
          city: finalData.city,
          state: finalData.state,
          country: finalData.country,
          zipCode: finalData.zipCode,
          phone: finalData.phone,
          contactEmail: finalData.contactEmail,
          website: finalData?.website,
        };

        const companyInfoResponse = await createCompanyInfo(companyInfoData);
        if (companyInfoResponse.hasOwnProperty('error')) {
          throw companyInfoResponse.error;
        }
      }

      if (finalData.documents.length > 0) {
        const formData = new FormData();
        finalData.documents.forEach(file => {
          formData.append('documents', file);
        });
        const filesResponse = await uploadIdConfirmationFiles(formData);
        if (filesResponse.hasOwnProperty('error')) {
          throw filesResponse.error;
        }
      }

      setSnackbarMessage('Company registration successful');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setFiles([]);
      setUploadedFiles([]);
      setSnackbarOpen(true);
      toggleModal('WELCOME_MODAL');
    } catch (error) {
      const errorMessage = errorHelper(error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const steps = useMemo(
    () => ['Account setup', 'Company details', 'Upload documents'],
    []
  );

  const title = useMemo(
    () => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box m="24px 0 36px">
          <FlagIcon />
        </Box>
        <Typography variant="h2">Welcome to Free Agent!</Typography>
      </Box>
    ),
    []
  );

  const footer = useMemo(
    () => (
      <Box
        display="flex"
        flexDirection={'row'}
        gap={'22px'}
        justifyContent="center"
        m="36px 0 36px"
      >
        <Button
          onClick={() => navigate('/company-questionnaire', { replace: true })}
          variant="contained"
          width={220}
          height={50}
        >
          start now
        </Button>
        <CustomButton
          onClick={() => navigate('/subscription-plans', { replace: true })}
          variantType={ButtonTypeEnum.SECONDARY}
          buttonWidth={'220px'}
        >
          i’ll do it later
        </CustomButton>
      </Box>
    ),
    [navigate]
  );

  const handleCloseModal = () => {
    toggleModal('');
    navigate('/subscription-plans', { replace: true });
  };

  return (
    <>
      {currentModal === 'WELCOME_MODAL' && (
        <BaseModal
          boxSx={{ width: '875px', maxHeight: 756, overflowY: 'scroll' }}
          header={{
            component: title,
          }}
          isOpen={true}
          toggle={() => handleCloseModal()}
          footer={{ component: footer }}
        >
          <Typography variant="body2" textAlign="center">
            To tailor the best experience for you, please answer a few quick
            questions. Your responses will help us recommend the perfect
            subscription plan to meet your hiring needs.
          </Typography>
        </BaseModal>
      )}

      <Box
        display={'flex'}
        maxWidth={'875px'}
        margin={'0 auto'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        marginBottom={'200px'}
      >
        <CustomStepper steps={steps} step={step} />

        {getMyCompanyInfoIsLoading ? (
          <Loader size={50} color={'primary'} />
        ) : (
          <>
            {step === 1 && (
              <AccountSetupForm
                onSubmitAccountSetup={onSubmitAccountSetup}
                setStep={setStep}
                step={step}
              />
            )}
            <CompanyStepsNeededAuth
              step={step}
              onSubmitCompanyDetails={onSubmitCompanyDetails}
              formData={formData as CompanyDetailsFormInputs}
              setStep={setStep}
              onSubmitFiles={onSubmitFiles}
              formDataFiles={formData as FileUploadFormInputs}
              files={files}
              setFiles={setFiles}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              idConfirmationFilesIsLoading={idConfirmationFilesIsLoading}
              idConfirmationFilesIsError={idConfirmationFilesIsError}
              idConfirmationFilesError={idConfirmationFilesError}
              idConfirmationFilesResponseData={idConfirmationFilesResponseData}
              isCompanyInfoExists={isCompanyInfoExists}
            />

            <SnackbarCustom
              open={snackbarOpen}
              onClose={handleCloseSnackbar}
              message={snackbarMessage}
              severity={snackbarSeverity}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default CompanyRegistration;

import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { useGetMyProfileScoreQuery } from '../../../store/apiSlice/userApi';
import { IUserScore } from '../../../interfaces/user.interface';
import GreenCheckedIcon from '../../Icons/GreenCheckedIcon';
import { Link } from 'react-router-dom';
import Label from '../../MUIComponents/Label';
import { LabelTypeEnum } from '../../../enums/label-enum';
import ArrowDownIcon from '../../Icons/ArrowDownIcon';
import { useState } from 'react';

const getProfileItems = (data: IUserScore | undefined) => [
  {
    title: 'Verify account',
    points: '20',
    done: data?.accountVerified,
    to: '/verify-account',
  },
  {
    title: 'Add avatar',
    points: '20',
    done: data?.addedAvatar,
    to: '/profile',
  },
  {
    title: 'Complete personal info',
    points: '20',
    done: data?.completePersonalInfo,
    to: '/profile',
  },
  {
    title: 'Upload photos',
    points: '20',
    done: data?.uploadedPhotos,
    to: '/profile',
  },
  {
    title: 'Upload videos',
    points: '20',
    done: data?.uploadedVideos,
    to: '/profile',
  },
];

const ProfileSidebarProgress = () => {
  const YOUR_PROFILE_SCORE = 'Your profile score';

  const [isScoreExpanded, setIsScoreExpanded] = useState(false);

  const { theme } = useThemeContext();
  const { data, isLoading } = useGetMyProfileScoreQuery();

  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const percentage = data ? Object.values(data).filter(Boolean).length * 20 : 0;
  const profileItems = getProfileItems(data);

  const toggleExpand = () => {
    setIsScoreExpanded(prevState => !prevState);
  };

  const ProfileScore = (
    <Box
      display="flex"
      flexDirection="column"
      gap="36px"
      sx={{
        p: { xs: '22px 16px', lg: '0' },
      }}
    >
      <Typography component="h3" variant="h3">
        {YOUR_PROFILE_SCORE}
      </Typography>
      <Box display="flex" justifyContent="center">
        <CircularProgressbar
          value={percentage}
          text={`${percentage}`}
          styles={{
            root: {
              width: '210px',
              height: '210px',
            },
            path: {
              stroke: theme.palette.stepperConnector.light,
              strokeLinecap: 'round',
            },
            trail: {
              stroke: theme.palette.stepperConnector.dark,
              strokeWidth: 4,
            },
            text: {
              fill: theme.palette.stepperConnector.light,
              fontSize: '30px',
              textAnchor: 'middle',
              dominantBaseline: 'central',
            },
          }}
        />
      </Box>
      <Typography variant="body3" fontWeight={700} color={'text.primary'}>
        Next things to do:
      </Typography>
      <List sx={{ p: 0 }}>
        {profileItems.map(({ title, points, done, to }, index) => (
          <Link to={to} key={title}>
            <ListItem
              sx={{
                p: 0,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                typography={'body4'}
                sx={{
                  color: done ? 'text.primary' : 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {done && (
                  <GreenCheckedIcon sx={{ width: 12, height: 12, mr: '8px' }} />
                )}
                {title}
              </Typography>
              <Typography
                typography={'body4'}
                sx={{
                  color: done ? 'text.done' : 'text.secondary',
                }}
              >
                {points} points
              </Typography>
            </ListItem>
            <Divider
              sx={{
                m: index === profileItems.length - 1 ? '22px 0 0' : '22px 0',
              }}
            />
          </Link>
        ))}
      </List>
    </Box>
  );

  const ScoreOrLoader = isLoading
    ? skeletonProfileSidebarProgress
    : ProfileScore;

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={'background.white'}
      sx={{
        borderRadius: '1px',
        p: { xs: '0', lg: '36px 22px' },
      }}
      onClick={toggleExpand}
    >
      {mobileView && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderBottom: '1px solid',
            borderColor: 'background.light',
            p: '16px',
          }}
        >
          <Typography variant="badge2" textTransform={'uppercase'}>
            {YOUR_PROFILE_SCORE}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Label
              labelType={LabelTypeEnum.VERIFIED}
              sx={{
                fontSize: '12px',
                fontWeight: 800,
                letterSpacing: '0.5px',
                lineHeight: '12px',
              }}
            >
              {percentage}
            </Label>
            <ArrowDownIcon
              sx={{
                width: '28px',
                height: '28px',
                transform: isScoreExpanded ? 'rotate(180deg)' : '',
              }}
            />
          </Box>
        </Box>
      )}
      {mobileView ? (
        <Collapse in={isScoreExpanded}>{ScoreOrLoader}</Collapse>
      ) : (
        ScoreOrLoader
      )}
      <Box
        sx={{
          display: mobileView ? 'block' : 'none',
          backgroundColor: 'background.default',
          height: '16px',
        }}
      />
    </Box>
  );
};

export default ProfileSidebarProgress;

const skeletonProfileSidebarProgress = (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '36px',
      p: { xs: '22px 16px', lg: '0' },
    }}
  >
    <Skeleton
      variant="circular"
      sx={{
        width: '100%',
        height: 'auto',
        aspectRatio: '1',
      }}
    />
    <Skeleton
      variant="rectangular"
      sx={{
        width: '100%',
        height: '25rem',
      }}
    />
  </Box>
);

import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckMarkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 13, height: 13, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        d="M2.8335 6.5L5.3335 9L10.3335 4"
        stroke="#28BA2E"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default CheckMarkIcon;

import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckedIcon from '../../../../../components/Icons/CheckedIcon';

interface ThanksCardProps extends BoxProps {
  closeCard: () => void;
}
const ThanksCard = ({ closeCard, ...rest }: ThanksCardProps) => {
  const theme = useTheme();

  return (
    <Box {...rest}>
      <Typography
        component="header"
        variant="h2"
        pb="48px"
        alignSelf="flex-start"
      >
        Career development
      </Typography>

      <Box
        bgcolor={theme.palette.background.light}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={1}
        p="48px 0"
      >
        <CheckedIcon />
        <Typography variant="h2" textAlign="center" m="36px 0 28px">
          Thank You for Completing the Questionnaire!
        </Typography>
        <Typography variant="body2" textAlign="center" mb="28px">
          We are now matching you with a dedicated career agent. You’ll receive
          an email notification <br />
          once your agent has been assigned.
        </Typography>
        <Link
          to="#"
          onClick={closeCard}
          style={{ color: '#000', textDecoration: 'none' }}
        >
          <Typography color={theme.palette.text.accent}>
            press to continue with career development
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default ThanksCard;

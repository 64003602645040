import { Box, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import CandidatesFilters from './CandidatesFilters/CandidatesFilter';

const CandidatesSidebar = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="22px"
      marginTop={!matches ? '-20px' : 0}
    >
      <CandidatesFilters />
    </Box>
  );
};

export default CandidatesSidebar;

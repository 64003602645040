import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CustomButton from '../../MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { Controller } from 'react-hook-form';
import { IAnalyticFilterValues } from '../../../interfaces/analytic.interface';
import ArrowDownIcon from '../../Icons/ArrowDownIcon';
import React, { useState } from 'react';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import DateInputISO from '../../MUIComponents/DateInputISO';

interface IStatFilterProps {
  control: any;
  onApplyFilters: (data: IAnalyticFilterValues) => void;
  handleSubmit: any;
  resetFilters: () => void;
  errors: any;
  isValid: boolean;
  loading: boolean;
}

const StatFilter = (props: IStatFilterProps) => {
  const {
    control,
    onApplyFilters,
    handleSubmit,
    resetFilters,
    errors,
    isValid,
    loading,
  } = props;

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [isScoreExpanded, setIsScoreExpanded] = useState(true);

  const toggleExpand = () => {
    setIsScoreExpanded(prevState => !prevState);
  };

  return (
    <form onSubmit={handleSubmit(onApplyFilters)}>
      <Box
        height="100%"
        width={mobileView ? '100%' : '385px'}
        borderRight={mobileView ? 'none' : '1px solid #E8EBEF'}
        borderBottom={mobileView ? '1px solid #E8EBEF' : 'none'}
      >
        <Box
          padding={isSmallScreen ? '22px 16px' : '22px 36px'}
          borderBottom="1px solid #E8EBEF"
        >
          <Typography component={'h4'} variant="badge2">
            TIME RANGE
          </Typography>

          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={'12px'}
            marginTop={'22px'}
            width={'100%'}
          >
            <Box width={'50%'}>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateInputISO
                    control={control}
                    label="Start:"
                    placeholder="Start Date"
                    {...field}
                    error={errors.startDate}
                    helperText={
                      errors.startDate ? errors.startDate.message : ''
                    }
                  />
                )}
              />
            </Box>

            <Box width={'50%'}>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DateInputISO
                    control={control}
                    label="End:"
                    placeholder="End Date"
                    {...field}
                    error={errors.endDate}
                    helperText={errors.endDate ? errors.endDate.message : ''}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          padding={isSmallScreen ? '22px 16px' : '22px 36px'}
          gap={'12px'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              gap: '28px',
              padding: '0 2px',
              cursor: 'pointer',
            }}
          >
            <Typography
              component="h4"
              variant="badge2"
              color={'text.primary'}
              textAlign={'left'}
              display={'flex'}
              alignItems={'center'}
              textTransform={'uppercase'}
            >
              show stats
            </Typography>
            <Box display="flex" gap="12px">
              <ArrowDownIcon
                sx={{
                  transform: isScoreExpanded ? 'rotate(180deg)' : '',
                }}
                onClick={toggleExpand}
              />
            </Box>
          </Box>
          <Collapse in={isScoreExpanded} sx={{ marginTop: '22px' }}>
            <Box display="flex" flexDirection={'column'} gap={'22px'}>
              <Controller
                name="numberOfCandidates"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        sx={{
                          padding: 0,
                          margin: 0,
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={'caption'}
                        sx={{ fontWeight: 400, fontSize: '14px' }}
                      >
                        Number of candidates
                      </Typography>
                    }
                    sx={{
                      padding: 0,
                      margin: 0,
                      gap: '8px',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  />
                )}
              />
              {errors.numberOfCandidates && (
                <Typography color="error" variant="caption">
                  {errors.numberOfCandidates.message}
                </Typography>
              )}

              <Divider />

              <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
                <Controller
                  name="numberOfAcceptedCandidates"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={'caption'}
                          sx={{ fontWeight: 400, fontSize: '14px' }}
                        >
                          Number of accepted candidates
                        </Typography>
                      }
                      sx={{
                        padding: 0,
                        margin: 0,
                        gap: '8px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    />
                  )}
                />
                {errors.numberOfAcceptedCandidates && (
                  <Typography color="error" variant="caption">
                    {errors.numberOfAcceptedCandidates.message}
                  </Typography>
                )}

                <Controller
                  name="numberOfJobPosting"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={'caption'}
                          sx={{ fontWeight: 400, fontSize: '14px' }}
                        >
                          Number of job postings
                        </Typography>
                      }
                      sx={{
                        padding: 0,
                        margin: 0,
                        gap: '8px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    />
                  )}
                />
                {errors.numberOfJobPosting && (
                  <Typography color="error" variant="caption">
                    {errors.numberOfJobPosting.message}
                  </Typography>
                )}

                {/* Number of rejected candidates */}
                <Controller
                  name="numberOfRejectedCandidates"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={'caption'}
                          sx={{ fontWeight: 400, fontSize: '14px' }}
                        >
                          Number of rejected candidates
                        </Typography>
                      }
                      sx={{
                        padding: 0,
                        margin: 0,
                        gap: '8px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    />
                  )}
                />
                {errors.numberOfRejectedCandidates && (
                  <Typography color="error" variant="caption">
                    {errors.numberOfRejectedCandidates.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Collapse>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          borderTop="1px solid #E8EBEF"
          padding={isSmallScreen ? '22px 16px' : '22px 36px'}
          gap="12px"
        >
          <CustomButton
            buttonWidth={'100%'}
            variantType={ButtonTypeEnum.PRIMARY}
            type="submit"
            disabled={!isValid || loading}
          >
            {loading ? 'Applying...' : 'APPLY FILTERS'}
          </CustomButton>

          <CustomButton
            buttonWidth={'100%'}
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={resetFilters}
          >
            RESET FILTERS
          </CustomButton>
        </Box>
      </Box>
    </form>
  );
};

export default StatFilter;

import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import SearchBar from './SearchBar';
import FilterButtons from './FilterButtons';
import ChatList from './ChatList';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ChatStateEnum } from '../../../../enums/chat-enum';
import {
  getChatState,
  getSelectedChat,
  resetForwardingFrom,
  resetPage,
  resetReplyingTo,
  resetToken,
  setChatState,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import { useDispatch, useSelector } from 'react-redux';
import useChatToken from '../../../../hooks/chat/useChatToken';
import { IChat } from '../../../../store/apiSlice/chat/chatApi';
import { IGroupChat } from '../../../../store/apiSlice/chat/groupApi';
import NewChatButton from './NewChatButton';

const ChatNavigation = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchValue, setSearchValue] = useState<string>('');
  const selectedChat = useSelector(getSelectedChat);
  const chatState = useSelector(getChatState);
  const { deleteToken } = useChatToken();
  const dispatch = useDispatch();

  const handleSelectChat = useCallback(
    (chat: IChat | IGroupChat) => {
      if (!selectedChat?.id) {
        dispatch(setSelectedChat(chat));
      } else if (selectedChat?.id !== chat.id) {
        deleteToken({
          chatId: selectedChat?.id,
          userId: selectedChat?.userId,
        })
          .unwrap()
          .then(() => {
            dispatch(resetToken());
            dispatch(resetPage());
            dispatch(resetReplyingTo());
            dispatch(resetForwardingFrom());
            dispatch(setSelectedChat(chat));
            setSearchValue('');
          })
          .catch(console.log);
      }
      dispatch(setChatState(ChatStateEnum.CHAT));
    },
    [deleteToken, dispatch, selectedChat?.id, selectedChat?.userId]
  );

  useEffect(() => {
    // Disable scrolling when navigation is visible on mobile view
    if (chatState === ChatStateEnum.ALL_CHATS && mobileView) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup on unmount or when conditions change
    return () => {
      document.body.style.overflow = '';
    };
  }, [chatState, mobileView]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {(chatState === ChatStateEnum.ALL_CHATS || !mobileView) && (
        <Box
          width={'100%'}
          height={
            chatState === ChatStateEnum.ALL_CHATS && mobileView
              ? 'calc(100vh - 80px)'
              : 'auto'
          }
          bgcolor={'white'}
          display={'flex'}
          flexDirection={'column'}
          p={'1rem 0'}
          gap={'1rem'}
          alignSelf={'start'}
          borderRadius={'1px'}
          flexGrow={1}
          sx={{
            overflow: 'hidden',
          }}
        >
          <SearchBar
            onSearch={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
          />
          <FilterButtons />
          <ChatList
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            handleSelectChat={handleSelectChat}
          />
          {!mobileView && (
            <>
              <Divider />
              <NewChatButton />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ChatNavigation;

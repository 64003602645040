import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import {
  OrganizationSizeEnum,
  PositionPriorityEnum,
  AdditionalServicesEnum,
  NeededJobPerMonthEnum,
  CandidateExperiencePriorityEnum,
} from '../../enums/questionnaires-enum';

interface QuestionnairesFormProps {
  answers:
    | PositionPriorityEnum[]
    | NeededJobPerMonthEnum[]
    | CandidateExperiencePriorityEnum[]
    | AdditionalServicesEnum[]
    | OrganizationSizeEnum[];
  selectedAnswer:
    | PositionPriorityEnum
    | NeededJobPerMonthEnum
    | CandidateExperiencePriorityEnum
    | AdditionalServicesEnum
    | OrganizationSizeEnum
    | null;
  onAnswerChange: (
    answer:
      | PositionPriorityEnum
      | NeededJobPerMonthEnum
      | CandidateExperiencePriorityEnum
      | AdditionalServicesEnum
      | OrganizationSizeEnum
  ) => void;
}

const QuestionnairesForm: React.FC<QuestionnairesFormProps> = ({
  answers,
  selectedAnswer,
  onAnswerChange,
}) => {
  const theme = useTheme();

  const getTitleFromEnum = (answer: string) => {
    switch (answer) {
      case PositionPriorityEnum.FULL_TIME:
        return 'Full-time Position';
      case PositionPriorityEnum.PART_TIME:
        return 'Part-time Position';
      case PositionPriorityEnum.INTERNSHIP:
        return 'Internship Position';
      case PositionPriorityEnum.SEASONAL:
        return 'Seasonal Position';
      case NeededJobPerMonthEnum.ONE_TO_FIVE:
        return '1 to 5 Job Postings';
      case NeededJobPerMonthEnum.FIVE_TO_TEN:
        return '5 to 10 Job Postings';
      case NeededJobPerMonthEnum.ELEVEN_TO_TWENTY:
        return '11 to 20 Job Postings';
      case NeededJobPerMonthEnum.TWENTY_PLUS:
        return '20+ Job Postings';
      case CandidateExperiencePriorityEnum.ENTRY_LEVEL:
        return 'Entry Level';
      case CandidateExperiencePriorityEnum.MID_LEVEL:
        return 'Mid Level';
      case CandidateExperiencePriorityEnum.SENIOR_LEVEL:
        return 'Senior Level';
      case CandidateExperiencePriorityEnum.ALL_LEVELS:
        return 'All Levels';
      case AdditionalServicesEnum.YES_CANDIDATE_SCREENING:
        return 'Yes, Candidate Screening';
      case AdditionalServicesEnum.YES_PREMIUM_JOB_POSTING_VISIBILITY:
        return 'Yes, Premium Job Posting Visibility';
      case AdditionalServicesEnum.YES_BOTH:
        return 'Yes, Both';
      case AdditionalServicesEnum.NO_STANDARD_POSTING:
        return 'No, Standard Posting';
      case OrganizationSizeEnum.ONE_TO_TEN_EMPLOYEES:
        return '1 to 10 Employees';
      case OrganizationSizeEnum.ELEVEN_TO_FIFTY_EMPLOYEES:
        return '11 to 50 Employees';
      case OrganizationSizeEnum.FIFTY_ONE_TO_TWO_HUNDRED_EMPLOYEES:
        return '51 to 200 Employees';
      case OrganizationSizeEnum.TWO_HUNDRED_PLUS_EMPLOYEES:
        return '200+ Employees';
      default:
        return 'Question';
    }
  };

  return (
    <FormGroup sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '16px',
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr', // Один стовпець на малих екранах
            gap: '8px',
          },
        }}
      >
        {answers.map(answer => (
          <Box
            key={answer}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedAnswer === answer}
                  onChange={() => onAnswerChange(answer)}
                  sx={{
                    padding: 0,
                    margin: 0,
                  }}
                />
              }
              label={
                <Typography
                  variant="h4"
                  color={'text.primary'}
                  sx={{ width: '100%' }}
                >
                  {getTitleFromEnum(answer)}
                </Typography>
              }
              sx={{
                border: '1px solid',
                borderColor: 'background.darker',
                borderRadius: '4px',
                padding: '22px 16px',
                margin: 0,
                gap: '8px',
                width: '100%',
              }}
            />
          </Box>
        ))}
      </Box>
    </FormGroup>
  );
};

export default QuestionnairesForm;

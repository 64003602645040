import { Box, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';
import WelcomeToPlatform from '../../components/Auth/VerifyAccount/WelcomeToPlatform/WelcomeToPlatform';

const SubscriptionSuccessfullyPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      pt={'1rem'}
      display={'flex'}
      justifyContent={'center'}
      width={'100%'}
      height={'100dvh'}
    >
      <Logo />
      <Modal
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            alignItems: 'flex-end',
          },
        })}
        open={true}
      >
        <WelcomeToPlatform
          title="Subscription successfully paid!"
          text="Thank you for choosing our subscription! We're excited to have you on our platform.
  Your decision helps us continue providing the best solutions to meet your hiring needs.
  We’re confident you’ll find everything you need to succeed. Enjoy your experience!"
          textLastLine="Join our community and never miss a moment of the action!"
          buttonText="Start Now"
          onProceed={() =>
            navigate('/', {
              replace: true,
            })
          }
          onClose={() => navigate('/', { replace: true })}
        />
      </Modal>
    </Box>
  );
};

export default SubscriptionSuccessfullyPage;

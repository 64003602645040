import { Box, Input, Typography } from '@mui/material';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { ElementIdEnum } from '../../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { Video, VideoAlbum } from '../../../../../interfaces/media.interface';
import {
  useAddUserVideoMutation,
  useDeleteVideoMutation,
  useGetAllUserVideosQuery,
  useGetVideoAlbumByIdQuery,
  useGetAllUserVideosByUserIdQuery,
} from '../../../../../store/apiSlice/profileMediaApi';
import { isSupportedIdConfirmationVideoFileHelper } from '../../../../../utils/helper/file-validation-helper';
import { triggerFileInput } from '../../../../../utils/helper/triggerInputHelper';
import UploadedFileIconElement from '../../../../Auth/Elements/UploadedFileIconElement';
import VideosIcon from '../../../../Icons/VideosIcon';
import CustomButton from '../../../../MUIComponents/CustomButton';
import DragAndDropBox from '../../../../MUIComponents/DragAndDropBox/DragAndDropBox';
import Loader from '../../../../MUIComponents/Loader';
import VideoGrid from './VideoGrid';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import EmptyMediaBox from '../../../EmptyMediaBox';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import GridSkeleton from '../../../GridSkeleton';

interface VideoAreaProps {
  album?: VideoAlbum;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setSnackbarSeverity: Dispatch<SetStateAction<SnackbarSeverityEnum>>;
}

const VideoArea: FC<VideoAreaProps> = ({
  album = null,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [limit, setLimit] = useState<number>(12);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  // eslint-disable-next-line
  const [dragOver, setDragOver] = useState(false);
  const { userId, isOwnPage } = useCurrentPageUserId();

  const useGetAllVideos = useMemo(
    () =>
      album
        ? useGetVideoAlbumByIdQuery
        : isOwnPage
          ? useGetAllUserVideosQuery
          : useGetAllUserVideosByUserIdQuery,
    [album, isOwnPage]
  );

  const {
    data: videoData,
    isLoading,
    isFetching,
  } = useGetAllVideos(
    {
      id: album?.id || userId || '',
      page,
      limit,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [addUserVideo, { isLoading: isUpdatingFiles }] =
    useAddUserVideoMutation();
  const [deleteVideo] = useDeleteVideoMutation();

  useEffect(() => {
    if (videoData) {
      const newVideos = videoData.data || [];
      const total = videoData.total || 0;

      const count = total - videos.length;
      //to do: need to check perhaps this logic is broken
      // case you have already 12 'videos' and total = 300, then 300 - 12 = 288
      // newVideos with limit = 12 would be 12 elements, hence newVideos.slice(-288)???
      const newItemsToAdd = newVideos.slice(-count);
      if (count > 0) {
        setVideos(prevVideos =>
          page === 1 ? newVideos : [...prevVideos, ...newItemsToAdd]
        );

        const hasNext = total !== 0 && page * limit < total;
        setHasMore(hasNext);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoData]);

  const handleNextPage = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const addFile = async (files: FileList) => {
    const newFiles = Array.from(files);
    const validFiles = newFiles.filter(file =>
      isSupportedIdConfirmationVideoFileHelper(file)
    );
    const invalidFiles = newFiles.filter(
      file => !isSupportedIdConfirmationVideoFileHelper(file)
    );

    if (validFiles.length > 5) {
      setSnackbarMessage('You can upload up to 5 files at once');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (invalidFiles.length > 0) {
      setSnackbarMessage('Invalid file type. Only videos (mp4, ...)');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }
    try {
      await Promise.all(
        validFiles.map(async file => {
          const formData = new FormData();
          formData.append('file', file);
          // if triggered from specific album, add photo to this album
          album && formData.append('videoAlbumId', album.id);
          await addUserVideo(formData).unwrap();
        })
      );
      if (validFiles.length + videos.length > limit * page) {
        setPage(1);
        const total = videoData?.total ? videoData?.total : 0;
        setLimit(validFiles.length + total);
      }
      setSnackbarMessage('All files uploaded successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(`Error uploading files: ${errorHelper(error)}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      console.error('Error uploading files:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFile(event.dataTransfer.files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleFileRemove = async (id: string) => {
    setVideos(prevVideos => prevVideos.filter(video => video.id !== id));
    await deleteVideo(id);
    setSnackbarMessage('Video deleted successfully');
    setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    setSnackbarOpen(true);
  };

  const mainContent = !videos.length ? (
    isOwnPage ? (
      <DragAndDropBox
        handleDragOver={handleDragOver}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        handleFileChange={handleFileChange}
        triggerFileInput={() =>
          triggerFileInput(ElementIdEnum.ADD_VIDEOS_DRUG_AND_DROP)
        }
        fileInputId={ElementIdEnum.ADD_VIDEOS_DRUG_AND_DROP}
        isVideoInput
        text="Drag and drop your videos here"
        icon={
          <UploadedFileIconElement>
            <VideosIcon active />
          </UploadedFileIconElement>
        }
      />
    ) : (
      <EmptyMediaBox
        text={`There are no playlists`}
        icon={
          <UploadedFileIconElement>
            <VideosIcon active />
          </UploadedFileIconElement>
        }
      />
    )
  ) : (
    <VideoGrid files={videos} handleFileRemove={handleFileRemove} />
  );

  return (
    <>
      {isLoading || (isUpdatingFiles && videos.length === 0) ? (
        <GridSkeleton
          xs={12}
          sm={6}
          lg={4}
          xl={4}
          itemCount={6}
          aspectRatio={'video'}
        />
      ) : (
        mainContent
      )}
      {videos.length !== 0 && (isUpdatingFiles || isFetching) && (
        <Loader size={30} color="primary" />
      )}
      {hasMore && (
        <Box>
          <CustomButton
            sx={{
              height: 56,
              width: '100%',
              mt: { xs: 3, md: 6 },
            }}
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={handleNextPage}
            disabled={isFetching}
          >
            show more
          </CustomButton>
        </Box>
      )}
      <Input
        id={ElementIdEnum.ADD_VIDEOS_BUTTON}
        type="file"
        sx={{ display: 'none' }}
        inputProps={{ multiple: true, accept: 'video/*' }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default VideoArea;

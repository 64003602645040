import { Box, Divider, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { ChatStateEnum, ChatTypeEnum } from '../../../../enums/chat-enum';
import useChatToken from '../../../../hooks/chat/useChatToken';
import {
  IChat,
  IMessage,
  useGetAllMyChatsQuery,
} from '../../../../store/apiSlice/chat/chatApi';
import {
  ForwardingFrom,
  getChatType,
  getSelectedChat,
  getToken,
  resetForwardingFrom,
  resetReplyingTo,
  resetToken,
  setChatState,
  setChatType,
  setForwardingFrom,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import {
  IGroupChat,
  useGetAllMyGroupChatsQuery,
} from '../../../../store/apiSlice/chat/groupApi';
import { isGroupChat } from '../ChatNavigation/Chat';
import ChatList from '../ChatNavigation/ChatList';
import ForwardToSearchBar from './ForwardToSearchBar';

interface ForwardToModalProps {
  isOpen?: boolean;
  message: IMessage;
  onClose: () => void;
}

const ForwardToModal = ({
  isOpen = false,
  message,
  onClose,
}: ForwardToModalProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const selectedChat = useSelector(getSelectedChat)!;
  const dispatch = useDispatch();

  const chatType = useSelector(getChatType);
  const { token } = useSelector(getToken);

  const { data: myPersonalChats } = useGetAllMyChatsQuery();
  const { data: myGroupChats } = useGetAllMyGroupChatsQuery();
  const { deleteToken } = useChatToken();

  const handleSelectChat = useCallback(
    (message: IMessage) => async (forwardToChat: IChat | IGroupChat) => {
      const handleSelectPersonalChats = async (chat: IChat | IGroupChat) => {
        if (chatType !== ChatTypeEnum.PERSONAL) {
          if (token && selectedChat) {
            try {
              await deleteToken({
                chatId: selectedChat.id,
                userId: selectedChat.userId,
              }).unwrap();
              dispatch(resetToken());
            } catch (error) {
              console.log(error);
            }
          }

          if (myPersonalChats && myPersonalChats.length > 0) {
            dispatch(
              setSelectedChat(
                myPersonalChats.find(
                  personalChat => personalChat.id === chat.id
                )
              )
            );
            dispatch(setChatState(ChatStateEnum.CHAT));
          }

          dispatch(resetToken());
          dispatch(resetReplyingTo());
          dispatch(resetForwardingFrom());
          dispatch(setChatType(ChatTypeEnum.PERSONAL));
        } else {
          if (myPersonalChats && myPersonalChats.length > 0) {
            dispatch(
              setSelectedChat(
                myPersonalChats.find(
                  personalChat => personalChat.id === chat.id
                )
              )
            );
            dispatch(setChatState(ChatStateEnum.CHAT));
          }
          dispatch(resetToken());
          dispatch(resetReplyingTo());
          dispatch(resetForwardingFrom());
        }
      };

      const handleSelectGroupChats = async (chat: IChat | IGroupChat) => {
        if (chatType !== ChatTypeEnum.GROUP) {
          if (token && selectedChat) {
            try {
              await deleteToken({
                chatId: selectedChat.id,
                userId: selectedChat.userId,
              }).unwrap();
              dispatch(resetToken());
            } catch (error) {
              console.log(error);
            }
          }

          if (myGroupChats && myGroupChats.length > 0) {
            dispatch(
              setSelectedChat(
                myGroupChats.find(myGroupChat => myGroupChat.id === chat.id)
              )
            );
            dispatch(setChatState(ChatStateEnum.CHAT));
          }

          dispatch(resetToken());
          dispatch(resetReplyingTo());
          dispatch(resetForwardingFrom());
          dispatch(setChatType(ChatTypeEnum.GROUP));
        } else {
          if (myGroupChats && myGroupChats.length > 0) {
            dispatch(setSelectedChat(myGroupChats[0]));
            dispatch(setChatState(ChatStateEnum.CHAT));
          }

          dispatch(resetToken());
          dispatch(resetReplyingTo());
          dispatch(resetForwardingFrom());
        }
      };

      if (
        myPersonalChats &&
        myPersonalChats.findIndex(chat => chat.id === forwardToChat.id) >= 0
      ) {
        await handleSelectPersonalChats(forwardToChat);
      }

      if (
        myGroupChats &&
        myGroupChats.findIndex(chat => chat.id === forwardToChat.id) >= 0
      ) {
        await handleSelectGroupChats(forwardToChat);
      }

      // user who sent message in personalChat: you or your friend
      const personalChatMessageSender = () =>
        (selectedChat as IChat).userId === message.from
          ? (selectedChat as IChat).user
          : (selectedChat as IChat).withUser;

      const forwardFromUser = isGroupChat(selectedChat)
        ? selectedChat.members.find(member => member.id === message.from)
        : personalChatMessageSender();

      if (!forwardFromUser) {
        return;
      }

      const forwardingFrom: ForwardingFrom = {
        user: forwardFromUser,
        message,
      };
      dispatch(setForwardingFrom(forwardingFrom));
    },
    [
      token,
      chatType,
      myGroupChats,
      selectedChat,
      myPersonalChats,
      dispatch,
      deleteToken,
    ]
  );

  const title = useMemo(() => {
    return (
      <Typography variant="h3" textAlign="left">
        Forward to...
      </Typography>
    );
  }, []);

  const footer = useMemo(() => {
    return (
      <Box display="flex" justifyContent="flex-end">
        <CustomButton
          onClick={onClose}
          variantType={ButtonTypeEnum.SECONDARY}
          buttonWidth={100}
        >
          Cancel
        </CustomButton>
      </Box>
    );
  }, [onClose]);

  return (
    <BaseModal
      boxSx={{
        width: '475px',
        p: '20px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',

        '& .content': {
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      disableEscapeButton
      header={{
        component: title,
      }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: footer }}
    >
      <ForwardToSearchBar
        onSearch={(e: ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      />
      <Divider sx={{ m: '20px -36px 0 -36px' }} />
      <Box
        className="chats-list"
        sx={{
          flexGrow: 1, // Fills the remaining height
          overflowY: 'auto',
          p: '20px 0',
        }}
      >
        <ChatList
          isBothTypeOfChats
          isHighlightSelectedChat={false}
          isShowLastMessage={false}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          handleSelectChat={handleSelectChat(message)}
        />
      </Box>
      <Divider sx={{ m: '0 -36px 20px -36px' }} />
    </BaseModal>
  );
};

export default ForwardToModal;

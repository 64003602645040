import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  IUserCompanyInfoRequest,
  IUserCompanyInfoResponse,
} from '../../interfaces/user-company-info.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const userCompanyInfoApi = createApi({
  reducerPath: 'userCompanyApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/user-company-info`
  ),
  tagTypes: ['user-company-info'],

  endpoints: builder => ({
    createCompanyInfo: builder.mutation<
      IUserCompanyInfoResponse,
      IUserCompanyInfoRequest
    >({
      query: (data: IUserCompanyInfoRequest) => ({
        url: `/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['user-company-info'],
    }),
    updateCompanyInfo: builder.mutation<
      IUserCompanyInfoResponse,
      { id: any; data: IUserCompanyInfoRequest }
    >({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['user-company-info'],
    }),
    getMyCompanyInfo: builder.mutation<IUserCompanyInfoResponse, void>({
      query: () => ({
        url: `/my`,
        method: 'GET',
      }),
      invalidatesTags: ['user-company-info'],
    }),
    getMyCompany: builder.query<IUserCompanyInfoResponse, void>({  // duplicated route, remove
      query: () => ({
        url: `/my`,
        method: 'GET',
      }),
    }),
    getMyCompanyAbout: builder.query<IUserCompanyInfoResponse, void>({
      query: () => {
        return {
          url: `/about-company/my`,
          method: 'GET',
        };
      },
    }),
    createMyCompanyAbout: builder.mutation<IUserCompanyInfoResponse, FormData>({
      query: (data: FormData) => ({
        url: `/about-company`,
        method: 'POST',
        body: data,
      }),
    }),
    updateMyCompanyAbout: builder.mutation<
      IUserCompanyInfoResponse,
      { id: string; data: FormData }
    >({
      query: ({ id, data }) => ({
        url: `/about-company/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteMyCompanyAbout: builder.mutation<
      IUserCompanyInfoResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/about-company/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateCompanyInfoMutation,
  useGetMyCompanyInfoMutation,
  useUpdateCompanyInfoMutation,
  useGetMyCompanyQuery,
  useGetMyCompanyAboutQuery,
  useUpdateMyCompanyAboutMutation,
  useCreateMyCompanyAboutMutation,
  useDeleteMyCompanyAboutMutation,
} = userCompanyInfoApi;

export default userCompanyInfoApi;

import React from 'react';
import { Skeleton, SkeletonProps } from '@mui/material';

interface SkeletonListProps extends SkeletonProps {
  count?: number;
}

const SkeletonList: React.FC<SkeletonListProps> = ({
  count = 2,
  sx,
  ...rest
}) => {
  return (
    <>
      {[...new Array(count)].map((_, index) => (
        <Skeleton
          variant="rounded"
          sx={{
            width: '100%',
            minHeight: {
              xs: '300px',
              lg: '350px',
            },
            ...sx,
          }}
          key={index}
          {...rest}
        />
      ))}
    </>
  );
};

export default SkeletonList;


const BASE_URL = process.env.REACT_APP_BASE_API_URL || 'https://r82vjvbu6k.execute-api.us-west-1.amazonaws.com';

export const config = {
  BASE_USER_MICROSERVICE_API_URL:
    BASE_URL + '/user/api',
  BASE_POST_MICROSERVICE_API_URL:
    BASE_URL + '/post/api',
  BASE_COMMENT_MICROSERVICE_API_URL:
    BASE_URL + '/comment/api',
  BASE_FRIEND_MICROSERVICE_API_URL:
    BASE_URL + '/friend/api',
  BASE_NOTIFICATION_MICROSERVICE_API_URL:
    BASE_URL + '/notification/api',
  BASE_COMPANY_MICROSERVICE_API_URL:
    BASE_URL + '/company/api',
  BASE_CHAT_MICROSERVICE_API_URL:
    BASE_URL + '/chat/api/chats',
  BASE_GROUP_CHAT_MICROSERVICE_API_URL:
    BASE_URL + '/chat/api/group-chat',

  STRIPE_PUBLIC_KEY:
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_51PyrOY2KL8HOcPrQ4GD0S0Fm4fvPMZq6OGDvBahi8reQTIfyEOZiISdotTu9C3ERoT7tRdBCH9pW1qejoVGAs8vU00vVgdXyzS',

  //Refetch token once every 55 sec
  TWILIO_TOKEN_POLLING_INTERVAL: 55 * 1000,
};

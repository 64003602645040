import { Box, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import MagnifyingGlassIcon from '../../../../components/Icons/MagnifyingGlassIcon';

interface ISearchContactsProps {
  onSearch: (_: ChangeEvent<HTMLInputElement>) => void;
}

const SearchContacts = ({ onSearch }: ISearchContactsProps) => {
  return (
    <Box p={'1rem 1rem 0 1rem'}>
      <TextField
        placeholder="Enter the name..."
        fullWidth
        autoFocus
        sx={{
          bgcolor: 'background.light',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MagnifyingGlassIcon />
            </InputAdornment>
          ),
          onChange: onSearch,
        }}
      />
    </Box>
  );
};

export default SearchContacts;

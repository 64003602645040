import React from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../theme/theme';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface CustomStepIconProps {
  active: boolean;
  completed?: string;
  icon: number;
}

interface CustomStepConnectorProps {
  completed?: string;
}

const CustomStepConnector = styled(StepConnector)<CustomStepConnectorProps>(
  ({ completed }) => ({
    '& .MuiStepConnector-line': {
      borderColor: completed ? colors.stepperCompletedBg : colors.dark15,
      borderTopWidth: 2,
      margin: '0 16px',
      borderRadius: 1,
    },
    top: '24px',
    transform: 'translateY(-50%)',
  })
);

const CustomStepIconRoot = styled('div')<CustomStepIconProps>(
  ({ active, completed }) => ({
    backgroundColor: active || completed ? colors.white : colors.dark15,
    zIndex: 1,
    color: completed ? colors.white : 'text.primary',
    width: '50px',
    height: '50px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '0 8px',
  })
);

const InnerCircle = styled('div')<{ completed?: string }>(({ completed }) => ({
  width: '40px',
  height: '40px',
  backgroundColor: completed ? colors.stepperCompletedBg : 'transparent',
  borderRadius: '50%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const CustomStepIcon: React.FC<CustomStepIconProps> = ({
  active,
  completed,
  icon,
}) => (
  <CustomStepIconRoot icon={icon} active={active} completed={completed}>
    {completed && <InnerCircle completed={completed ? 'true' : undefined} />}
    <Typography
      variant="body4"
      fontWeight={700}
      sx={{
        position: 'relative',
        zIndex: 2,
      }}
    >
      0{icon}
    </Typography>
  </CustomStepIconRoot>
);

interface CustomStepperProps {
  step: number;
  steps: string[];
}

const CustomStepper: React.FC<CustomStepperProps> = ({ step, steps }) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{ width: '100%' }}
      margin={`32px 0 ${mobileView ? '0' : '32px'} 0`}
    >
      <Stepper alternativeLabel activeStep={step - 1} connector={null}>
        {steps.map((label, index) => (
          <Step key={label} completed={index < step ? true : undefined}>
            {index > 0 && (
              <CustomStepConnector
                completed={index < step ? 'true' : undefined}
              />
            )}
            <StepLabel
              StepIconComponent={() => (
                <CustomStepIcon
                  icon={index + 1}
                  active={index === step - 1}
                  completed={index < step - 1 ? 'true' : undefined}
                />
              )}
            >
              {!mobileView && <Typography variant="body4">{label}</Typography>}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {mobileView && (
        <Typography
          variant="body3"
          display={'flex'}
          justifyContent={'center'}
          p={'1rem 0 2rem 0'}
          color={'text.primary'}
        >
          {`Step 0${step} of 03: ${steps[step - 1]}`}
        </Typography>
      )}
    </Box>
  );
};

export default CustomStepper;

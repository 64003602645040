import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ArrowDownIcon from '../../../../../../components/Icons/ArrowDownIcon';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';

interface ISortMenuItem {
  label: string;
  value: string;
}

export interface IListSortPops {
  sortMenuItems: ISortMenuItem[];
  sortMenuLabel: string;
}

const ListSort: React.FC<IListSortPops> = ({
  sortMenuItems,
  sortMenuLabel,
}) => {
  const { theme } = useThemeContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const initialQuery = searchParams.get('sortBy') || sortMenuItems[0].value;
  const [sortQuery, setSortQuery] = useState<string>(initialQuery);

  useEffect(() => {
    setSortQuery(initialQuery);
  }, [initialQuery]);

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    const query = event.target.value as string;
    setSortQuery(query);

    setSearchParams(prevParams => {
      prevParams.set('sortBy', query);
      return prevParams;
    });
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: theme.palette.text.primary,
          }}
          component={'label'}
          id={sortMenuLabel}
        >
          {sortMenuLabel}
        </Typography>
        <Box>
          <Select
            IconComponent={props => <ArrowDownIcon {...props} />}
            MenuProps={{
              sx: {
                '& .MuiMenu-list': {
                  paddingTop: '0px',
                  paddingBottom: '0px',
                },
              },
            }}
            labelId={sortMenuLabel}
            value={sortQuery}
            onChange={handleSortChange}
            displayEmpty
            inputProps={{ 'aria-label': sortMenuLabel }}
            sx={{
              fontSize: '14px',
              color: theme.palette.text.primary,
              border: 'none',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              textDecoration: 'underline',
              textUnderlinePosition: 'under',
              '& .MuiSelect-select': {
                color: theme.palette.text.primary,
                fontWeight: 600,
                background: 'transparent',
                paddingLeft: '1px',
                paddingRight: '0px',
                paddingTop: '0px',
                paddingBottom: '2px',
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiSelect-select:focus': {
                outline: 'none',
              },
              '& .MuiSelect-select:focusWithin': {
                outline: 'none',
              },
              '& .MuiSelect-icon': {
                color: theme.palette.text.primary,
                backgroundColor: 'transparent',
              },
              boxShadow: 'none',

              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                {
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '0px',
                  border: 0,
                },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '0px',
                },
            }}
          >
            {sortMenuItems.length > 0 &&
              sortMenuItems.map(({ value, label }) => (
                <MenuItem
                  key={value}
                  value={value}
                  sx={{ fontSize: '14px', fontWeight: 400 }}
                >
                  {label}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </FormControl>
    </Box>
  );
};

export default ListSort;

import {
  endOfMonth,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfMonth,
} from 'date-fns';

export const formatDate = (date: Date) => {
  const optionsDate = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  } as const;
  const optionsTime = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  } as const;

  const formatterDate = new Intl.DateTimeFormat('en-US', optionsDate);
  const formatterTime = new Intl.DateTimeFormat('en-US', optionsTime);

  const dateString = formatterDate.format(date);
  const timeString = formatterTime.format(date);

  // Combine them with a space
  return `${dateString}\u00A0\u00A0\u00A0${timeString}`;
};

export function formatDateWithOffset(date: Date) {
  const offset = '+03:00';
  return new Date(`${date.toISOString().slice(0, -1)}${offset}`);
}

export const startDateFormatted = formatDateWithOffset(
  setMilliseconds(
    setSeconds(setMinutes(setHours(startOfMonth(new Date()), 11), 29), 0),
    0
  )
);

export const endDateFormatted = formatDateWithOffset(
  setMilliseconds(
    setSeconds(setMinutes(setHours(endOfMonth(new Date()), 23), 59), 59),
    0
  )
);

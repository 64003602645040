import {
  Box,
  CircularProgress,
  Divider,
  List,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import JobPostApply from '../../components/JobPostApply/JobPostApply';
import ListSort from '../../components/ListToolbar/ListSort';
import { IJobpost } from '../../interfaces/jobpost.interface';
import SectionLayout from '../../layouts/SectionLayout';
import SidebarLayout from '../../layouts/SidebarLayout';
import { useGetAppliedUsersByJobpostIdQuery } from '../../store/apiSlice/jobpost/jobUserApplyApi';
import { useGetJobpostByIdQuery } from '../../store/apiSlice/jobpost/jobpostApi';
import { JobPostItem } from '../JobPostsPage/components/JobPostsList/JobPostsList';
import ApplicationItem from './components/ApplicationItem';
import MyJobPostSidebar from './sidebar/MyJobPostSidebar';

const applicationsTabsLabels = ['all', 'new', 'accepted', 'rejected'];

const sectionStyles = {
  p: { xs: '36px 16px', md: '22px', lg: '22px 36px 22px 36px' },
};

const sortMenuItems = [
  { label: 'Newest first', value: 'desc' },
  { label: 'Oldest', value: 'asc' },
];

const sortMenuLabel = 'Show:';

const LIMIT = 100;

const MyJobPostPage = () => {
  const { id } = useParams();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [status, setStatus] = useState<string>('all');
  const [newAppliedCount, setNewAppliedCount] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('desc');
  const [activeTab, setActiveTab] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = {
    jobpostId: id as string,
    status,
    limit: LIMIT,
    sort: sortBy,
  };

  const {
    data: appliedUsers,
    isLoading: isAppliedUsersLoading,
    refetch: refetchApplications,
  } = useGetAppliedUsersByJobpostIdQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: jobPostData,
    isLoading: isJobPostDataLoading,
    error,
    refetch: refetchJobpostData,
  } = useGetJobpostByIdQuery(id);

  useEffect(() => {
    if (jobPostData) setNewAppliedCount(jobPostData.jobpost.newAppliedCount);
  }, [jobPostData]);

  useEffect(() => {
    setSortBy(searchParams.get('sortBy') || 'desc');
  }, [searchParams]);

  useEffect(() => {
    setStatus(applicationsTabsLabels[activeTab]);
  }, [activeTab]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSearchParams({
      query: '',
      sortBy: '',
      page: '1',
      tab: newValue.toString(),
    });

    setActiveTab(newValue);
  };

  let mappedJobpost: JobPostItem | undefined = undefined;

  if (jobPostData && 'jobpost' in jobPostData) {
    mappedJobpost = { type: 'COMPANY_JOBPOST', jobpost: jobPostData.jobpost };
  }

  return (
    <SidebarLayout
      sx={{
        maxWidth: {
          lg: 'calc(1440px - 32px)',
          xl: 'calc(1440px - 120px)',
        },
        marginX: 'auto',
      }}
      sidebar={
        jobPostData && (
          <MyJobPostSidebar
            jobPost={jobPostData.jobpost}
            refetchJobpostData={refetchJobpostData}
          />
        )
      }
      flexDirection={{ xs: 'column', lg: 'row' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow="1"
        gap={'22px'}
        pt={{ xs: '16px', lg: 'unset' }}
      >
        <SectionLayout
          height={'100%'}
          sx={{
            bgcolor: 'background.white',
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{
              ...sectionStyles,
            }}
            alignItems={'center'}
          >
            <Typography variant="h3">Applications</Typography>
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              borderRadius="20px"
              border={`1px solid #727F93`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="6px 12px"
              width="fit-content"
            >
              <Typography
                variant="body4"
                textTransform="uppercase"
                color={'text.secondary'}
              >
                {newAppliedCount} new{' '}
                {newAppliedCount <= 1 ? 'application' : 'applications'}
              </Typography>
            </Box>
          </Stack>

          {!mobileView && <Divider />}

          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            p={{ xs: '0 16px', lg: '22px 36px 22px 36px' }}
            justifyContent="space-between"
            alignItems={{
              lg: 'center',
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs"
              sx={{
                bgcolor: 'background.light',
                maxWidth: 'fit-content',
                borderRadius: '1px',
              }}
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
            >
              {applicationsTabsLabels.map((label, index) => (
                <Tab
                  key={index}
                  label={label}
                  sx={{
                    color: 'text.primary',
                    fontSize: '10px',
                    fontWeight: 800,
                    '&.Mui-selected': {
                      bgcolor: 'primary.main',
                      color: 'background.white',
                    },
                  }}
                />
              ))}
            </Tabs>
            <ListSort
              sortMenuItems={sortMenuItems}
              sortMenuLabel={sortMenuLabel}
            />
          </Stack>
          <Divider />

          {!isAppliedUsersLoading && !!appliedUsers?.data.length && (
            <List sx={{ p: 0 }}>
              {appliedUsers?.data.map(application => (
                <ApplicationItem
                  key={application.appliedUser.id}
                  application={application.appliedUser}
                  sectionStyles={sectionStyles}
                  refetchJobpostData={refetchJobpostData}
                  refetchApplications={refetchApplications}
                />
              ))}
            </List>
          )}
          {isAppliedUsersLoading && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
              marginTop={'20px'}
            >
              <CircularProgress color="primary" />
            </Box>
          )}
        </SectionLayout>

        <SectionLayout
          height={'100%'}
          sx={{
            bgcolor: 'background.white',
          }}
        >
          <Typography
            sx={{
              ...sectionStyles,
            }}
            variant="h3"
          >
            Job description
          </Typography>
          <Divider />
          {!isJobPostDataLoading && mappedJobpost && (
            <JobPostApply
              jobPost={
                mappedJobpost as { jobpost: IJobpost; type: 'COMPANY_JOBPOST' }
              }
              sectionStyles={sectionStyles}
            />
          )}
        </SectionLayout>
      </Box>
    </SidebarLayout>
  );
};

export default MyJobPostPage;

import { SvgIcon, SvgIconProps } from '@mui/material';

const JobPositionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 18, height: 18, fill: 'none' }}
      {...props}
      viewBox="0 0 18 18"
    >
      <path
        d="M15 3.75H3V15.75H15V3.75Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12 2.25V5.25"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6 2.25V5.25"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M3 8.25H15"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default JobPositionIcon;

import React from 'react';
import { Grid, Skeleton } from '@mui/material';

interface GridSkeletonProps {
  xs?: number;
  sm?: number;
  lg?: number;
  xl?: number;
  itemCount?: number;
  aspectRatio?: 'square' | 'video';
}

const GridSkeleton: React.FC<GridSkeletonProps> = ({
  xs = 6,
  sm = 4,
  lg = 3,
  xl = 2,
  itemCount = 12,
  aspectRatio = 'square',
}) => {
  const aspectRatioValue = aspectRatio === 'video' ? '16 / 9' : '1 / 1';

  return (
    <Grid container spacing={2}>
      {[...new Array(itemCount)].map((_, index) => (
        <Grid item xs={xs} sm={sm} lg={lg} xl={xl} key={index}>
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: 'auto',
              aspectRatio: aspectRatioValue,
              transform: 'none',
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GridSkeleton;

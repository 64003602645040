import { SvgIcon, SvgIconProps } from '@mui/material';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface SubscriptionIconProps extends SvgIconProps {
  active?: boolean;
}

const SubscriptionIcon = ({ active, ...svgProps }: SubscriptionIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      sx={{ width: 28, height: 28, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      {...svgProps}
    >
      <path
        d="M10.5 16.3334C10.5 18.2665 13.6337 19.8334 17.5 19.8334C21.3663 19.8334 24.5 18.2665 24.5 16.3334C24.5 14.4002 21.3663 12.8334 17.5 12.8334C13.6337 12.8334 10.5 14.4002 10.5 16.3334Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 16.3334V21C10.5 22.932 13.6337 24.5 17.5 24.5C21.3663 24.5 24.5 22.932 24.5 21V16.3334"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 7C3.5 8.25067 4.83467 9.40567 7 10.031C9.16533 10.6563 11.8347 10.6563 14 10.031C16.1653 9.40567 17.5 8.25067 17.5 7C17.5 5.74933 16.1653 4.59433 14 3.969C11.8347 3.34367 9.16533 3.34367 7 3.969C4.83467 4.59433 3.5 5.74933 3.5 7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 7L3.5 18.6667C3.5 19.7027 4.40067 20.3583 5.83333 21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 12.8334C3.5 13.8694 4.40067 14.525 5.83333 15.1667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SubscriptionIcon;

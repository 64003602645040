import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';
import PersonalInfoIcon from '../../Icons/PersonalInfoIcon';
import SportBackgroundIcon from '../../Icons/SportBackgroundIcon';
import PhotosIcon from '../../Icons/PhotosIcon';
import VideosIcon from '../../Icons/VideosIcon';
import { cloneElement, Fragment, useState } from 'react';
import DotIcon from '../../Icons/DotIcon';

const menuItems = [
  {
    title: 'Personal information',
    icon: <PersonalInfoIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
    href: '#personal-info',
  },
  {
    title: 'Sport background',
    icon: <SportBackgroundIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
    href: '#sport-background',
  },
  {
    title: 'Photos',
    icon: <PhotosIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
    href: '#photos',
  },
  {
    title: 'Videos',
    icon: <VideosIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
    href: '#videos',
  },
];

const ProfileSidebarMenu = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={{ minWidth: { lg: 236, xl: 314 } }}>
      <Box
        component={'ul'}
        display={'grid'}
        gridTemplateRows={'repeat(1fr, 4)'}
        gap={'1px'}
      >
        {menuItems.map(({ title, icon, href }, index) => (
          <Fragment key={title}>
            <ListItem
              component="a"
              href={href}
              onClick={() => handleItemClick(index)}
              sx={{ bgcolor: 'background.white', borderRadius: '1px', p: 0 }}
            >
              <ListItemButton sx={{ p: '22px' }}>
                <ListItemIcon
                  sx={{
                    minWidth: 'unset',
                    mr: '12px',
                    alignItems: 'center',
                  }}
                >
                  {selectedIndex === index && (
                    <DotIcon sx={{ width: 8, height: 8, mr: '12px' }} />
                  )}
                  {cloneElement(icon, {
                    active: selectedIndex === index ? true : false,
                  })}
                </ListItemIcon>
                <Typography
                  variant="body4"
                  fontWeight={700}
                  color={
                    selectedIndex === index
                      ? 'button.primary'
                      : 'text.secondary'
                  }
                >
                  {title}
                </Typography>
              </ListItemButton>
            </ListItem>
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default ProfileSidebarMenu;

import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}
const InviteIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={cn({ [className!]: className !== undefined })}
    >
      <g stroke="#fff" strokeLinecap="square" strokeWidth="1.5">
        <path d="M12 18H3V4h18v9.5"></path>
        <path strokeLinejoin="round" d="M3.429 6.429L12 12l8.572-5.571"></path>
        <path strokeLinejoin="round" d="M15 18h5.571"></path>
        <path d="M18 15l3 3-3 3"></path>
      </g>
    </svg>
  );
};

export default InviteIcon;

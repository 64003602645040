import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileLeftBar from '../PersonalInfoPage/components/leftbar/ProfileLeftBar';
import ProfileScoreHeader from '../shared-components/profile-score/ProfileScoreHeader';
import MyFriendsPosts from './components/MyFriendsPosts';
import { colors } from '../../../theme/theme';
import { useCurrentPageUserId } from '../../../hooks/useCurrentPageUserId';
import { useGetMyProfileScoreQuery } from '../../../store/apiSlice/userApi';
import ProfileScoreList from '../shared-components/profile-score/ProfileScoreList';

interface MyFeedPageProps {
  children?: ReactNode;
}

const MyFeedPage = ({ children }: MyFeedPageProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const { isOwnPage } = useCurrentPageUserId();

  const { data: myProfileScoreData, isLoading: myProfileScoreIsLoading } =
    useGetMyProfileScoreQuery();

  const percentage = myProfileScoreData
    ? Object.values(myProfileScoreData).filter(Boolean).length * 20
    : 0;

  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="fit-content"
      sx={{
        width: '100%',
        pt: { xs: '40px', lg: '22px', xl: '36px' },
        marginTop: {
          xs: '-20px',
          md: '-24px',
          lg: '-28px',
          xl: '-48px',
        },
      }}
    >
      <Box
        display="flex"
        color="#DDE1E8"
        maxWidth="1440px"
        width="100%"
        alignSelf="center"
        flexDirection={mobileView ? 'column' : 'row'}
      >
        <ProfileLeftBar
          styles={{ top: { xs: '20px', lg: 0 } }}
          navigatePhotos={() => navigate(`/me?tab=${4}`)}
          navigateVideos={() => navigate(`/me?tab=${5}`)}
        />
        <Box
          className="profile-info-page-main-content"
          flex={2}
          display="flex"
          flexDirection="column"
          pr={{ lg: '16px', xl: '62px' }}
          mt={{ xs: '16px', lg: 0 }}
          sx={mobileView ? { padding: 0 } : {}}
        >
          <Box width={'100%'}>
            <Container
              disableGutters
              className="profile-tab-container"
              maxWidth={false}
            >
              <Grid container columnGap={{ lg: '16px', xl: '22px' }}>
                <Grid
                  item
                  className="profile-tab-content"
                  flex={1}
                  width="100%"
                  sx={mobileView ? { order: 1 } : {}}
                >
                  <MyFriendsPosts />
                </Grid>

                <Grid
                  item
                  className="profile-tab-rightbar"
                  width="100%"
                  maxWidth={{ lg: 236, xl: 314 }}
                  height="fit-content"
                  sx={
                    mobileView
                      ? { order: 0, width: '100%', marginBottom: '20px' }
                      : {}
                  }
                >
                  {isOwnPage && (
                    <Stack sx={{ bgcolor: colors.white }}>
                      {myProfileScoreIsLoading ? (
                        skeletonProfileScoreHeader
                      ) : (
                        <ProfileScoreHeader percentage={percentage} />
                      )}
                      {myProfileScoreData && (
                        <ProfileScoreList profileScore={myProfileScoreData} />
                      )}
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyFeedPage;

const skeletonProfileScoreHeader = (
  <Box
    width={'100%'}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
  >
    <Skeleton
      sx={{
        width: '90%',
        height: '130px',
      }}
    />
  </Box>
);

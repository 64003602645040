import React from 'react';
import { List, ListItem } from '@mui/material';
import { IFriend } from '../../../../interfaces/friend.interface';
import ConnectionAvatarWithText from '../../../ConnectionsPage/components/ConnectionsTabs/ConnectionList/ConnectionAvatarWithText';

interface IRecommendationFriendList {
  suggestions: IFriend[];
}
const RecommendationFriendList = (props: IRecommendationFriendList) => {
  const { suggestions } = props;

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '12px', lg: '16px' },
      }}
    >
      {suggestions.map((suggestion: IFriend) => (
        <ListItem
          key={suggestion.userId}
          alignItems="center"
          sx={{ justifyContent: 'space-between', p: '0px' }}
        >
          <ConnectionAvatarWithText connection={suggestion as IFriend} />
        </ListItem>
      ))}
    </List>
  );
};

export default RecommendationFriendList;

import {
  Avatar,
  Box,
  BoxProps,
  Collapse,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import Rating from '../../../../../components/MUIComponents/Rating';
import { Review } from '../../../../../interfaces/review.interface';

interface ReviewCardProps extends BoxProps {
  cardData: Review;
}

const ReviewCard = ({ cardData, ...rest }: ReviewCardProps) => {
  const theme = useTheme();
  const reviewTextRef = useRef<HTMLSpanElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isContentLarge, setIsContentLarge] = useState(false);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (!reviewTextRef.current) {
      return;
    }

    setContentHeight(reviewTextRef.current.offsetHeight);
    setIsContentLarge(reviewTextRef.current.offsetHeight > 70);
  }, [reviewTextRef]);

  const collapsedSize = useMemo(() => {
    if (!contentHeight) {
      return 30;
    }

    return contentHeight > 70 ? 70 : contentHeight + 5;
  }, [contentHeight]);

  const handleToggleCollapse = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <Box
      className="career-developement-page__review-card"
      p="20px 16px"
      bgcolor={theme.palette.background.light}
      {...rest}
    >
      <Grid container flexDirection="column">
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          columnGap="16px"
        >
          <Grid item>
            <Avatar
              alt="Profile"
              src={cardData.reviewer.optimizedUserAvatarBaseUrl || ''}
              sx={{
                width: 40,
                height: 40,
                //   [theme.breakpoints.up('xs')]: { width: 100, height: 100 },
                //   [theme.breakpoints.up('lg')]: { width: 140, height: 140 },
              }}
            />
          </Grid>

          <Grid item display="flex" flexDirection="column" rowGap="2px">
            <Typography
              variant="body2"
              color={theme.palette.text.primary}
            >{`${cardData.reviewer.firstName} ${cardData.reviewer.lastName}`}</Typography>
            <Rating
              readOnly
              value={cardData.rating || 0}
              sx={{ fontSize: 16 }}
            />
          </Grid>
        </Grid>

        <Grid item mt="16px">
          <Collapse in={isExpanded} collapsedSize={collapsedSize}>
            <Typography ref={reviewTextRef} variant="body3">
              {cardData.review}
            </Typography>
          </Collapse>
        </Grid>
        {isContentLarge && (
          <Grid item mt="12px">
            <ShowMoreButton onClick={handleToggleCollapse}>
              {isExpanded ? 'Show less' : 'Show more'}
            </ShowMoreButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ReviewCard;

const ShowMoreButton = styled(Box)<BoxProps>(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '140%',
}));

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useOutlet } from 'react-router-dom';
import CompanyTabs from './components/CompanyTabs';
import CompanyLeftBar from './components/leftbar/CompanyLeftBar';
import ProfileBackground from '../shared-components/ProfileBackground';
import { createPortal } from 'react-dom';

interface CompanyInfoPageProps {
  children?: ReactNode;
}

const CompanyInfoPage = ({ children }: CompanyInfoPageProps) => {
  const outlet = useOutlet();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const profileBanner = document.getElementById('profile-banner');
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="fit-content"
      sx={{
        position: 'static',
        width: '100%',
        marginTop: {
          xs: '-20px',
          md: '-24px',
          lg: '-28px',
          xl: '-48px',
        },
      }}
    >
      {profileBanner && createPortal(<ProfileBackground />, profileBanner)}

      <Box
        display="flex"
        color="#DDE1E8"
        maxWidth="1440px"
        width="100%"
        alignSelf="center"
        flexDirection={mobileView ? 'column' : 'row'}
      >
        <CompanyLeftBar />
        <Box
          className="profile-info-page-main-content"
          flex={2}
          display="flex"
          flexDirection="column"
          pr={{ lg: '16px', xl: '62px' }}
          sx={mobileView ? { padding: 0 } : {}}
        >
          {outlet || <CompanyTabs />}
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyInfoPage;

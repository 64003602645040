import React, { useEffect, useState } from 'react';
import SidebarLayout from '../../layouts/SidebarLayout';
import { Box, useMediaQuery } from '@mui/material';
import SectionLayout from '../../layouts/SectionLayout';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import CandidatesSidebar from './components/CandidatesSidebar/CandidatesSidebar';
import Candidates from './components/Candidates/Candidates';
import { IUser } from '../../interfaces/user.interface';
import { useSearchParams } from 'react-router-dom';
import { useGetAllCandidatesQuery } from '../../store/apiSlice/userApi';

const sortMenuItems = [
  { label: 'Recently Added', value: 'desc' },
  { label: 'Oldest', value: 'asc' },
];

const LIMIT = 10;

const CandidatesPage = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [candidates, setCandidates] = useState<IUser[]>([]);
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [totalPosts, setTotalPosts] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get('query') ?? '';
  const page = parseInt(searchParams.get('page') || '1', 10);
  const sort = searchParams.get('sortBy') ?? 'desc';
  const country = searchParams.get('country') ?? '';
  const city = searchParams.get('city') ?? '';
  const sports = searchParams.get('sports') ?? '';
  const from = parseInt(searchParams.get('from') || '0', 10);
  const to = parseInt(searchParams.get('to') || '0', 10);

  const queryParams = {
    page,
    search: query,
    limit: LIMIT,
    sort,
    country,
    city,
    sports,
    from,
    to,
  };

  const { data: candidatesData, isLoading } = useGetAllCandidatesQuery(
    queryParams,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (candidatesData && candidatesData?.data) {
      if (page === 1) setCandidates(candidatesData.data);
      else setCandidates(prev => [...prev, ...candidatesData.data]);

      setTotalPosts(candidatesData.total);

      const hasNext =
        candidatesData.limit * (candidatesData.page - 1) +
          candidatesData.limit <
        candidatesData.total;

      setIsLoadMore(hasNext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatesData]);

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('page', '1');
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, sort]);

  return (
    <SidebarLayout
      sx={{
        maxWidth: {
          lg: 'calc(1440px - 32px)',
          xl: 'calc(1440px - 120px)',
        },
        marginX: 'auto',
      }}
      sidebar={<CandidatesSidebar />}
      flexDirection={matches ? 'row' : 'column'}
    >
      <Box display="flex" flexDirection="column" flexGrow="1">
        <SectionLayout
          height={'100%'}
          sx={{
            bgcolor: 'background.white',
          }}
        >
          <Candidates
            listToolBarSubtitle={
              totalPosts === 0
                ? 'No candidates found'
                : `${totalPosts} candidate${totalPosts === 1 ? '' : 's'}`
            }
            sortMenuItems={sortMenuItems}
            candidates={candidates}
            isLoadMore={isLoadMore}
            isLoading={isLoading}
          />
        </SectionLayout>
      </Box>
    </SidebarLayout>
  );
};

export default CandidatesPage;

import { Box, useMediaQuery } from '@mui/material';
import ProfileSidebarMenu from './PrifileSidebarMenu';
import ProfileSidebarProgress from './ProfileSidebarProgress';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const ProfileSidebar = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box display="flex" flexDirection="column" gap="22px">
      {!mobileView && <ProfileSidebarMenu />}
      <ProfileSidebarProgress />
    </Box>
  );
};

export default ProfileSidebar;

import { Box, Typography } from '@mui/material';

const GroupNameChangedNotification = ({ name }: { name: string }) => {
  return (
    <Box
      width={'100%'}
      bgcolor={'background.light'}
      textAlign={'center'}
      p={'0.375rem'}
    >
      <Typography
        typography={'body3'}
        sx={{ color: 'text.primary' }}
        component={'span'}
      >
        Name of this group has been changed to
        <Typography
          typography={'body3'}
          sx={{ color: 'text.primary' }}
          fontWeight={700}
          component={'span'}
        >
          {` '${name}' `}
        </Typography>
      </Typography>
    </Box>
  );
};

export default GroupNameChangedNotification;

const TrashBoxIcon = ({ color, size }: { color?: string; size?: string }) => {
  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.75C9.9337 3.75 9.87011 3.77634 9.82322 3.82322C9.77634 3.87011 9.75 3.9337 9.75 4V6.25H14.25V4C14.25 3.9337 14.2237 3.87011 14.1768 3.82322C14.1299 3.77634 14.0663 3.75 14 3.75H10ZM15.75 6.25V4C15.75 3.53587 15.5656 3.09075 15.2374 2.76256C14.9092 2.43437 14.4641 2.25 14 2.25H10C9.53587 2.25 9.09075 2.43437 8.76256 2.76256C8.43438 3.09075 8.25 3.53587 8.25 4V6.25H5.00877C5.00349 6.24994 4.9982 6.24994 4.9929 6.25H4C3.58579 6.25 3.25 6.58579 3.25 7C3.25 7.41421 3.58579 7.75 4 7.75H4.3099L5.25021 19.0337C5.25897 19.7508 5.54767 20.4368 6.05546 20.9445C6.57118 21.4603 7.27065 21.75 8 21.75H16C16.7293 21.75 17.4288 21.4603 17.9445 20.9445C18.4523 20.4368 18.741 19.7508 18.7498 19.0337L19.6901 7.75H20C20.4142 7.75 20.75 7.41421 20.75 7C20.75 6.58579 20.4142 6.25 20 6.25H19.0071C19.0018 6.24994 18.9965 6.24994 18.9912 6.25H15.75ZM5.8151 7.75L6.74741 18.9377C6.74914 18.9584 6.75 18.9792 6.75 19C6.75 19.3315 6.8817 19.6495 7.11612 19.8839C7.35054 20.1183 7.66848 20.25 8 20.25H16C16.3315 20.25 16.6495 20.1183 16.8839 19.8839C17.1183 19.6495 17.25 19.3315 17.25 19C17.25 18.9792 17.2509 18.9584 17.2526 18.9377L18.1849 7.75H5.8151ZM9.46967 12.5303C9.17678 12.2374 9.17678 11.7626 9.46967 11.4697C9.76256 11.1768 10.2374 11.1768 10.5303 11.4697L12 12.9393L13.4697 11.4697C13.7626 11.1768 14.2374 11.1768 14.5303 11.4697C14.8232 11.7626 14.8232 12.2374 14.5303 12.5303L13.0607 14L14.5303 15.4697C14.8232 15.7626 14.8232 16.2374 14.5303 16.5303C14.2374 16.8232 13.7626 16.8232 13.4697 16.5303L12 15.0607L10.5303 16.5303C10.2374 16.8232 9.76256 16.8232 9.46967 16.5303C9.17678 16.2374 9.17678 15.7626 9.46967 15.4697L10.9393 14L9.46967 12.5303Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default TrashBoxIcon;

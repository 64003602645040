import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import {
  ILeague,
  ILeagueBySportNameResponse,
} from '../../../../../interfaces/career.interface';
import ArrowDownIcon from '../../../../Icons/ArrowDownIcon';
import { TeamFormInputs } from '../SportBackgroundItem/SportBackgroundItem';

interface LeaguesProps {
  leagues: ILeagueBySportNameResponse[];
  isLoading: boolean;
  isErrors: boolean;
  control: Control<TeamFormInputs, any>;
  setFormValue: UseFormSetValue<TeamFormInputs>;
  formStateErrors: FieldErrors<TeamFormInputs>;
  disabled: boolean;
  setSelectedLeague: (league: ILeague) => void;
  handleFieldChange: (field: keyof TeamFormInputs, value: any) => void;
}

const Leagues = ({
  leagues,
  isLoading,
  isErrors,
  control,
  setFormValue,
  formStateErrors,
  disabled,
  setSelectedLeague,
  handleFieldChange,
}: LeaguesProps) => {
  // convert form field leagueId to Autocomplete option
  const leagueValue = useCallback(
    (field: ControllerRenderProps<TeamFormInputs, 'leagueId'>) => {
      return leagues.find(el => el.idLeague === field.value) || null;
    },
    [leagues]
  );

  return (
    <Controller
      name="leagueId"
      control={control}
      render={({ field }) => {
        return (
          <FormControl
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
            variant="outlined"
            fullWidth
            error={!!formStateErrors.leagueId}
          >
            <Typography
              variant="caption"
              sx={{
                padding: '0 2px',
                mb: '12px',
                color: field.disabled ? 'text.disabled' : 'text.primary',
              }}
            >
              League:
            </Typography>
            <Autocomplete
              value={leagueValue(field)}
              loading={isLoading}
              options={leagues ?? []}
              getOptionLabel={option => option.strLeague || ''}
              isOptionEqualToValue={(option, value) => {
                return option.idLeague === value?.idLeague;
              }}
              defaultValue={null}
              onChange={(event, newValue) => {
                newValue && setSelectedLeague(newValue);
                field.onChange(newValue ? newValue.idLeague : null);
                handleFieldChange('leagueId', newValue?.idLeague);

                // Clear the team if the league is removed
                if (!newValue) {
                  setFormValue('teamId', '');
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Select a league"
                  error={!!formStateErrors.leagueId}
                  helperText={
                    formStateErrors.leagueId
                      ? formStateErrors.leagueId.message
                      : ''
                  }
                  disabled={disabled}
                />
              )}
              disabled={disabled}
              sx={{
                '.MuiInputBase-root': {
                  height: '50px',
                },
                '.MuiSvgIcon-root': {
                  bgcolor: 'transparent !important',
                },
              }}
              popupIcon={<ArrowDownIcon />}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default Leagues;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface PhotosIconProps extends SvgIconProps {
  iconColor?: string;
  active?: boolean;
}

const CompanyAboutFilesIcon = ({
  iconColor,
  active,
  ...svgProps
}: PhotosIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      sx={{
        width: svgProps.width ?? 32,
        height: svgProps.height ?? 32,
        fill: 'none',
        '& > path': {
          stroke: `${iconColor} !important`,
        },
      }}
      {...svgProps}
    >
      <g
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_1210_28249)"
      >
        <path d="M9.722 30.76l20.284-5.436M11.975 30.155L7.748 14.38l7.807-6.924 5.436 20.285M27.753 25.928l-3.02-11.27-7.97-2.695M12.86 15.425l.002.01M13.766 18.805l.003.012M14.672 22.186l.003.011M15.577 25.567l.003.011"></path>
      </g>
      <defs>
        <clipPath id="clip0_1210_28249">
          <path
            fill="#fff"
            d="M0 0H28V28H0z"
            transform="rotate(-15 30.383 4)"
          ></path>
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CompanyAboutFilesIcon;

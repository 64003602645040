import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatStateEnum } from '../../enums/chat-enum';
import {
  IChat,
  useGetAllMyChatsQuery,
} from '../../store/apiSlice/chat/chatApi';
import {
  getChatsLoaded,
  getChatType,
  resetChatsLoaded,
  resetPage,
  resetToken,
  setChatsLoaded,
  setChatState,
} from '../../store/apiSlice/chat/chatSlice';
import {
  IGroupChat,
  useGetAllMyGroupChatsQuery,
} from '../../store/apiSlice/chat/groupApi';
import { useMediaQuery, useTheme } from '@mui/material';

const useGetMyPersonaAndGroupChats = ({
  searchValue = '',
}: {
  searchValue?: string;
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [myChats, setMyChats] = useState<(IChat | IGroupChat)[] | undefined>(
    undefined
  );

  const { data: myPersonalChats, isFetching: personalFetching } =
    useGetAllMyChatsQuery();
  const { data: myGroupChats, isFetching: groupFetching } =
    useGetAllMyGroupChatsQuery();

  const chatType = useSelector(getChatType);
  const chatsLoaded = useSelector(getChatsLoaded);
  const dispatch = useDispatch();

  const filteredPersonalChats = useMemo(() => {
    return myPersonalChats?.filter(chat => {
      const firstName = chat.withUser.firstName?.toLocaleLowerCase();
      const lastName = chat.withUser.lastName?.toLocaleLowerCase();
      const lastMessage = chat.lastMessageText?.toLocaleLowerCase();

      return [firstName, lastName, lastMessage].some(item =>
        item?.includes(searchValue.toLocaleLowerCase())
      );
    });
  }, [searchValue, myPersonalChats]);

  const filteredGroupChats = useMemo(() => {
    return myGroupChats?.filter(chat => {
      const name = chat.name?.toLocaleLowerCase();
      const lastMessage = chat.lastMessageText?.toLocaleLowerCase();

      return [name, lastMessage].some(item =>
        item?.includes(searchValue.toLocaleLowerCase())
      );
    });
  }, [searchValue, myGroupChats]);

  useEffect(() => {
    let chats: (IChat | IGroupChat)[] = [];

    if (filteredGroupChats && filteredGroupChats.length > 0) {
      chats.push(...filteredGroupChats);
    }
    if (filteredPersonalChats && filteredPersonalChats.length > 0) {
      chats.push(...filteredPersonalChats);
    }

    setMyChats(chats);
  }, [filteredGroupChats, filteredPersonalChats, chatType]);

  useEffect(() => {
    if (!chatsLoaded) {
      let chats: (IChat | IGroupChat)[] = [];

      if (
        myPersonalChats &&
        myPersonalChats.length > 0 &&
        !personalFetching &&
        filteredPersonalChats
      ) {
        chats.push(...filteredPersonalChats);
      }
      if (
        myGroupChats &&
        myGroupChats.length > 0 &&
        !groupFetching &&
        filteredGroupChats
      ) {
        chats.push(...filteredGroupChats);
      }

      if (!chats.length) {
        dispatch(resetChatsLoaded());
        setMyChats(undefined);
        return;
      }

      dispatch(resetPage());
      dispatch(
        setChatState(mobileView ? ChatStateEnum.ALL_CHATS : ChatStateEnum.CHAT)
      );
      dispatch(setChatsLoaded());
      dispatch(resetToken());
    }
  }, [
    chatType,
    myPersonalChats,
    myGroupChats,
    dispatch,
    filteredGroupChats,
    filteredPersonalChats,
    chatsLoaded,
    personalFetching,
    groupFetching,
  ]);

  return { myChats };
};

export default useGetMyPersonaAndGroupChats;

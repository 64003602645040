import {
  Box,
  Grid,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import UploadedFileIconElement from '../../../../../components/Auth/Elements/UploadedFileIconElement';
import CompanyAboutPdfFile from '../../../../../components/Company/CompanyAboutPdfFile/CompanyAboutPdfFile';
import CompanyAboutPhotoFile from '../../../../../components/Company/CompanyAboutPhotoFile/CompanyAboutPhotoFile';
import CompanyAboutFilesIcon from '../../../../../components/Icons/CompanyAboutFilesIcon';
import DragAndDropBox from '../../../../../components/MUIComponents/DragAndDropBox/DragAndDropBox';
import Loader from '../../../../../components/MUIComponents/Loader';
import SnackbarCustom from '../../../../../components/MUIComponents/SnackbarCustom';
import { ElementIdEnum } from '../../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { Photo, Video } from '../../../../../interfaces/media.interface';
import { ICompanyAbout } from '../../../../../interfaces/user-company-info.interface';
import {
  useCreateMyCompanyAboutMutation,
  useDeleteMyCompanyAboutMutation,
  useUpdateMyCompanyAboutMutation,
} from '../../../../../store/apiSlice/userCompanyInfoApi';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import { isSupportedCompanyAboutFileHelper } from '../../../../../utils/helper/file-validation-helper';
import { triggerFileInput } from '../../../../../utils/helper/triggerInputHelper';

const removeHTMLTags = (htmlString: string): string => {
  return htmlString.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
};

interface FileWithId {
  id: string;
  file: File;
}

interface DescriptionProps {
  data: ICompanyAbout | undefined;
  isLoading: boolean;
  refetch: () => void;
}

const Description = ({ data, isLoading, refetch }: DescriptionProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [about, setAbout] = useState<string>('');
  const [dragOver, setDragOver] = useState(false);
  const [media, setMedias] = useState<(Photo | Video)[]>([]);
  const [files, setFiles] = useState<FileWithId[]>([]);

  const noData = 'No information';

  const [
    createCompanyAboutInfo,
    { isLoading: createCompanyAboutInfoIsLoading },
  ] = useCreateMyCompanyAboutMutation();

  const [
    updateCompanyAboutInfo,
    { isLoading: updateCompanyAboutInfoIsLoading },
  ] = useUpdateMyCompanyAboutMutation();

  const [
    deleteCompanyAboutInfo,
    { isLoading: deleteCompanyAboutInfoIsLoading },
  ] = useDeleteMyCompanyAboutMutation();

  useEffect(() => {
    if (data) {
      setAbout(data.about || '');
      setMedias([
        {
          id: data.id,
          fileName: data.fileName,
          fileUrl: data.fileUrl,
          uniqueKey: data.uniqueKey,
        },
      ]);
    }
  }, [data]);

  const onDeleteCompanyAboutInfoSubmit = async (id: string) => {
    try {
      await deleteCompanyAboutInfo({ id }).unwrap();
      refetch();
      setAbout('');
      setMedias([]);
      setFiles([]);
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarMessage('Company info deleted successfully');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarMessage(errorHelper(error));
      setSnackbarOpen(true);
    }
  };

  const onSubmit = async () => {
    const companyAboutInfoRequest = new FormData();
    companyAboutInfoRequest.append('about', about);

    try {
      if (data) {
        if (!media.length) {
          setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
          setSnackbarMessage('Please add a file');
          setSnackbarOpen(true);
          return;
        }

        if (files.length === 1) {
          companyAboutInfoRequest.append('file', files[0].file);
        }

        await updateCompanyAboutInfo({
          id: data.id,
          data: companyAboutInfoRequest,
        }).unwrap();
      } else {
        if (!files.length || !media.length) {
          setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
          setSnackbarMessage('Please add a file');
          setSnackbarOpen(true);
          return;
        }

        if (files.length === 1) {
          companyAboutInfoRequest.append('file', files[0].file);
        }

        await createCompanyAboutInfo(companyAboutInfoRequest).unwrap();
        refetch();
      }
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarMessage('Company info updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarMessage(errorHelper(error));
      setSnackbarOpen(true);
    }
  };

  const addFile = async (files: FileList) => {
    const newFiles = Array.from(files);
    const invalidFiles = newFiles.filter(
      file => !isSupportedCompanyAboutFileHelper(file)
    );

    if (invalidFiles.length > 0) {
      setSnackbarMessage(
        'Invalid file type. Only images (JPEG, PNG, WebP) or PDF'
      );
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (newFiles.length > 1) {
      setSnackbarMessage('Only one file allowed');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    const mapedFiles: Photo[] = [];
    const filesWithIds: FileWithId[] = [];

    newFiles.forEach(file => {
      const id = uuidv4();
      mapedFiles.push({
        id,
        fileUrl: URL.createObjectURL(file),
      });
      filesWithIds.push({
        id,
        file,
      });
    });

    setFiles(filesWithIds);
    setMedias(mapedFiles);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFile(event.dataTransfer.files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleFileRemove = (id: string) => {
    setFiles(prevFiles => prevFiles.filter(fileWithId => fileWithId.id !== id));
    setMedias(prevMedias => prevMedias.filter(media => media.id !== id));
  };

  const isPdfFile = (fileName: string) =>
    fileName.toLowerCase().endsWith('.pdf') ||
    (files.length && files[0].file.type === 'application/pdf');

  return (
    <Box>
      <Grid container sx={gridContainerStyles} mb="28px">
        <Grid item display="flex" flexDirection="row">
          <Typography className="label" variant="caption">
            Description:
          </Typography>
          <Typography className="plain-text" variant="body3">
            {(data?.about && removeHTMLTags(data?.about)) ?? noData}
          </Typography>
        </Grid>
      </Grid>

      <div>
        {isLoading ? (
          <Loader size={30} color="primary" />
        ) : (
          <Box display={'flex'} flexDirection={'column'}>
            {!media.length && (
              <DragAndDropBox
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleDrop={handleDrop}
                handleFileChange={handleFileChange}
                triggerFileInput={() =>
                  triggerFileInput(ElementIdEnum.ADD_COMPANY_IMAGE_ABOUT_BUTTON)
                }
                fileInputId={ElementIdEnum.ADD_COMPANY_IMAGE_ABOUT_BUTTON}
                text={
                  <>
                    Drag and drop your file here
                    <br />
                    Only one file allowed. Only images (JPEG, PNG, WebP) or PDF
                    are accepted.
                  </>
                }
                icon={
                  <UploadedFileIconElement>
                    <CompanyAboutFilesIcon active />
                  </UploadedFileIconElement>
                }
              />
            )}
            <Box>
              {!!media.length &&
                media.map(file =>
                  isPdfFile(file.fileUrl) ? (
                    <CompanyAboutPdfFile
                      key={file.id}
                      files={[file]}
                      handleFileRemove={handleFileRemove}
                    />
                  ) : (
                    <CompanyAboutPhotoFile
                      key={file.id}
                      files={[file]}
                      handleFileRemove={handleFileRemove}
                    />
                  )
                )}
            </Box>
          </Box>
        )}
      </div>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default Description;

export const gridContainerStyles: SxProps<Theme> = theme => ({
  '& .label': {
    width: 165,
    flexShrink: 0,
  },
  '& .plain-text': {
    fontWeight: 400,
    lineHeight: '140%',
    color: theme.palette.text.primary,

    textAlign: 'left',
    flexGrow: '1',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    minWidth: 0,
  },
  '& .MuiGrid-item': {
    width: '100%',
  },
});

import { Box, Typography } from '@mui/material';
import { MessageTypeEnum } from '../../../../enums/message-type-enum';
import IconWithBackground from '../../../../components/MUIComponents/IconWithBackground';
import FileIcon from '../../../../components/Icons/FileIcon';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

const MessageDocumentPreview = ({
  src,
  filename,
  filesize,
  type,
}: {
  src: string;
  filename: string;
  filesize: string;
  type: MessageTypeEnum;
}) => {
  const { theme } = useThemeContext();

  return (
    <Box
      display={'flex'}
      borderRadius={'1px'}
      p={'0.375rem 0'}
      gap={'0.75rem'}
      alignItems={'center'}
    >
      <a href={src} download={filename} target="_blank" rel="noreferrer">
        <IconWithBackground
          bgcolor={'text.secondary'}
          size={'2.5rem'}
          icon={<FileIcon color={theme.palette.background.darker} />}
        />
      </a>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Typography
          color={
            type === MessageTypeEnum.INCOMING
              ? 'text.primary'
              : 'background.white'
          }
          variant={'body3'}
          fontWeight={700}
          maxWidth={'20ch'}
          noWrap
        >
          {filename}
        </Typography>
        <Typography color={'text.secondary'} variant="body4" fontWeight={400}>
          {filesize}
        </Typography>
      </Box>
    </Box>
  );
};

export default MessageDocumentPreview;

import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import EditIcon from '../../../../../components/Icons/EditIcon';
import SquareIconButton from '../../../../../components/MUIComponents/SquareIconButton';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import ArticleLayout from '../../../../../layouts/ArticleLayout';
import { useGetUserCompanyProfileInfoByIdQuery } from '../../../../../store/apiSlice/userApi';
import Description from './Description';

const AboutTab = () => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  const {
    data: companyInfoData,
    isLoading,
    refetch,
  } = useGetUserCompanyProfileInfoByIdQuery(userId ?? '', {
    skip: !userId,
  });

  const noData = 'No information';
  const companyInfo = companyInfoData?.companyInfo;

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={{ xs: '16px', xl: '22px' }}
    >
      {isLoading && (
        <Skeleton
          variant="rounded"
          sx={{
            width: '100%',
            marginTop: '20px',
            minHeight: {
              xs: '310px',
              lg: '420px',
            },
          }}
        />
      )}
      {!isLoading && (
        <ArticleLayout
          className="about-article"
          rowGap={8}
          display="flex"
          flexDirection="column"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" textTransform="capitalize">
              about company
            </Typography>
            {/*{isOwnPage && (*/}
            {/*  <SquareIconButton>*/}
            {/*    <EditIcon />*/}
            {/*  </SquareIconButton>*/}
            {/*)}*/}
          </Stack>

          <Grid container rowGap="28px" sx={gridContainerStyles}>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Company name:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.companyName ?? noData}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Address:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.address ?? noData}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                City:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.city ?? noData}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                State:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.state ?? noData}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Country:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.country ?? noData}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Zip code:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.zipCode ?? noData}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Phone:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.phone ?? noData}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Contact email:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.contactEmail ?? noData}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Website:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {companyInfo?.website ?? noData}
              </Typography>
            </Grid>
            <Grid item>
              <Description
                data={companyInfoData?.companyAbout}
                isLoading={isLoading}
                refetch={refetch}
              />
            </Grid>
          </Grid>
        </ArticleLayout>
      )}
    </Box>
  );
};

export default AboutTab;

export const gridContainerStyles: SxProps<Theme> = theme => ({
  '& .label': {
    width: 165,
    flexShrink: 0,
  },
  '& .plain-text': {
    fontWeight: 400,
    lineHeight: '140%',
    color: theme.palette.text.primary,

    textAlign: 'left',
    flexGrow: '1',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    minWidth: 0,
  },
  '& .MuiGrid-item': {
    width: '100%',
  },
});

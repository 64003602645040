import { Box, Fade, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IUserFileDB } from '../../../../interfaces/file.interface';
import ConfirmIcon from '../../../Icons/ConfirmIcon';
import DeleteFileRedIcon from '../../../Icons/DeleteFileRedIcon';
import IconWithBackground from '../../../MUIComponents/IconWithBackground';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface IProps {
  file: File;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedFile?: File;
  setUploadedFiles: React.Dispatch<React.SetStateAction<IUserFileDB[]>>;
  isLoading: boolean;
  isUploaded: boolean;
  handleDelete: (file: File, uploadedFile?: IUserFileDB) => Promise<void>;
}

const UploadDocumentImageFileItem: React.FC<IProps> = ({
  file,
  setFiles,
  uploadedFile,
  setUploadedFiles,
  isLoading,
  isUploaded,
  handleDelete,
}) => {
  const [showUploaded, setShowUploaded] = useState(false);
  const { theme } = useThemeContext();

  useEffect(() => {
    if (isUploaded) {
      setShowUploaded(true);
    }
  }, [isUploaded]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        flexGrow={0}
        gap={'12px'}
      >
        <Box
          style={{
            position: 'relative',
            width: '50px',
            minWidth: '50px',
            height: '50px',
            minHeight: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/*{!showUploaded && <EmptyFileIcon stroke={colors.lightGreyBg} />}*/}

          {/* TODO This image will be deleted when create logic for part route file reques and will be use EmptyFileIcon*/}
          <img
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
              position: 'absolute',
            }}
            src={URL.createObjectURL(file)}
            alt={file.name}
          />
        </Box>
        <Typography variant={'body3'} noWrap maxWidth={'25ch'}>
          {file.name}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" gap="8px">
        {isUploaded && (
          <Fade in={showUploaded} timeout={300}>
            <IconButton
              className={'confirm'}
              disabled
              sx={{
                backgroundColor: `${theme.palette.secondary2.main} !important`,
                stroke: theme.palette.success.light,
              }}
            >
              <ConfirmIcon />
            </IconButton>
          </Fade>
        )}
        <IconButton
          onClick={() => handleDelete(file)}
          className={'delete'}
          // disabled={isLoading}
        >
          <IconWithBackground icon={<DeleteFileRedIcon />} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default UploadDocumentImageFileItem;

import {
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { VideoAlbum } from '../../../../../interfaces/media.interface';
import SectionLayout from '../../../../../layouts/SectionLayout';
import ArrowLeftIcon from '../../../../Icons/ArrowLeftIcon';
import VideoArea from '../videos-tab/VideoArea';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';

interface SingleVideoAlbumProps {
  album: VideoAlbum;
  addButton: React.ReactNode;
  handleBackToAlbums: () => void;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setSnackbarSeverity: Dispatch<SetStateAction<SnackbarSeverityEnum>>;
}

const SingleVideoAlbum = ({
  album,
  addButton,
  handleBackToAlbums,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}: SingleVideoAlbumProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { isOwnPage } = useCurrentPageUserId();

  return (
    <SectionLayout
      display="flex"
      flexDirection="column"
      gap={mobileView ? '28px' : '36px'}
    >
      <Link
        to=""
        style={{ color: '#000', textDecoration: 'none' }}
        onClick={handleBackToAlbums}
      >
        <Typography variant="body1" display="flex" alignItems="center">
          <ArrowLeftIcon />
          &nbsp;&nbsp;All albums
        </Typography>
      </Link>
      <Divider sx={{ mt: '-10px' }} />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{album.name}</Typography>
        {isOwnPage && addButton}
      </Stack>

      <VideoArea
        album={album}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
      />
    </SectionLayout>
  );
};

export default SingleVideoAlbum;

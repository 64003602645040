const FileIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 3V8H19"
        stroke={color || '#142237'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5 21H19V7.72159L14.4546 3H5V21Z"
        stroke={color || '#142237'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default FileIcon;

import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import BaseLayout from './layouts/BaseLayout';
import BlankLayout from './layouts/BlankLayout';
import AccountSettingsPage from './pages/AccountSettingPage/AccountSettingsPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage/ForgotPasswordPage';
import GetStartedPage from './pages/auth/GetStartedPage/GetStartedPage';
import LoginPage from './pages/auth/LoginPage/LoginPage';
import ProfilePage from './pages/auth/ProfilePage/ProfilePage';
import RegistrationPage from './pages/auth/RegistrationPage/RegistrationPage';
import UpdatePasswordPage from './pages/auth/UpdatePasswordPage/UpdatePasswordPage';
import VerifyAccountPage from './pages/auth/VerifyAccountPage/VerifyAccountPage';
import VerifyCodePage from './pages/auth/VerifyCodePage/VerifyCodePage';
import WelcomeToPlatformPage from './pages/auth/WelcomeToPlatformPage/WelcomeToPlatformPage';
import ChatPage from './pages/ChatPage/ChatPage';
import CompanyRegistrationPage from './pages/company/CompanyRegistrationPage/CompanyRegistrationPage';
import ConnectionsPage from './pages/ConnectionsPage/ConnectionsPage';
import MyJobApplicationDetailsPage from './pages/home/MyJobApplicationDetailsPage/MyJobApplicationDetailsPage';
import PersonalInfoPage from './pages/home/PersonalInfoPage/PersonalInfoPage';
import JobPostApplyPage from './pages/JobPostApplyPage/JobPostApplyPage';
import JobPostsPage from './pages/JobPostsPage/JobPostsPage';
import SubscriptionPaymentPage from './pages/subscription/SubscriptionPaymentPage';
import SubscriptionPage from './pages/subscription/SubscriptionPlansPage';
import { useThemeContext } from './theme/ThemeContextProvider';
import InvitationsPage from './pages/auth/InvitationsPage/InvitationsPage';
import MyFeedPage from './pages/home/MyFeedPage/MyFeedPage';
import MyJobPostPage from './pages/MyJobPostPage/MyJobPostPage';
import CompanyPage from './pages/auth/CompanyPage/CompanyPage';
import CompanyInfoPage from './pages/home/CompanyInfoPage/CompanyInfoPage';
import { CookieEnum } from './enums/cookie-enum';
import Cookies from 'js-cookie';
import AddNewJobPostForm from './components/Company/AddNewJobPostForm/AddNewJobPostForm';
import { useSelector } from 'react-redux';
import { selectAuthData } from './store/selectors/authSelector';
import CandidatesPage from './pages/CandidatesPage/CandidatesPage';
import CompanyQuestionnairePage from './pages/CompanyQuestionnairePage/CompanyQuestionnairePage';
import Analytics from './components/Company/Analytics/Analytics';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import SubscriptionSuccessfullyPage from './pages/SubscriptionSuccessfullyPage/SubscriptionSuccessfullyPage';
import PersonalizedCareerModalPage from './pages/CareerPage/PersonalizedCareerModalPage';
import CareerQuestionnairePage from './pages/CareerPage/CareerQuestionnairePage';
import CareerDevelopmentPage from './pages/CareerPage/CareerDevelopmentPage/CareerDevelopmentPage';
import SubscriptionPaymentProtectedRoute from './components/Auth/SubscriptionPaymentProtectedRoute';

Chart.register(CategoryScale);

const App = () => {
  const { theme } = useThemeContext();
  const authData = useSelector(selectAuthData);
  const isCompany =
    authData?.user?.role === 'COMPANY' ||
    Cookies.get(CookieEnum.ROLE) === 'COMPANY';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Routes>
          <Route path="/" element={<BaseLayout />}>
            <Route
              path=""
              element={
                isCompany ? (
                  <ProtectedRoute>
                    {' '}
                    <CompanyInfoPage />{' '}
                  </ProtectedRoute>
                ) : (
                  <ProtectedRoute>
                    <MyFeedPage />{' '}
                  </ProtectedRoute>
                )
              }
            ></Route>
            <Route
              path="me"
              element={
                isCompany ? (
                  <ProtectedRoute>
                    {' '}
                    <CompanyInfoPage />{' '}
                  </ProtectedRoute>
                ) : (
                  <ProtectedRoute>
                    <PersonalInfoPage />{' '}
                  </ProtectedRoute>
                )
              }
            >
              <Route
                path="job-application/:applicationId"
                element={
                  <ProtectedRoute>
                    <MyJobApplicationDetailsPage />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="candidates"
              element={
                <ProtectedRoute>
                  <CandidatesPage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path=":id"
              element={
                <ProtectedRoute>
                  <PersonalInfoPage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path="profile"
              element={
                isCompany ? (
                  <ProtectedRoute>
                    {' '}
                    <CompanyPage />{' '}
                  </ProtectedRoute>
                ) : (
                  <ProtectedRoute>
                    {' '}
                    <ProfilePage />{' '}
                  </ProtectedRoute>
                )
              }
            />
            <Route
              path="account-settings"
              element={
                <ProtectedRoute>
                  <AccountSettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="connections"
              element={
                <ProtectedRoute>
                  <ConnectionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="company-questionnaire"
              element={
                <ProtectedRoute>
                  <CompanyQuestionnairePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="subscription-plans"
              element={
                <SubscriptionPaymentProtectedRoute>
                  <SubscriptionPage />{' '}
                </SubscriptionPaymentProtectedRoute>
              }
            />
            <Route
              path="subscription-payment"
              element={
                <SubscriptionPaymentProtectedRoute>
                  <SubscriptionPaymentPage />{' '}
                </SubscriptionPaymentProtectedRoute>
              }
            />
            <Route
              path="subscription-successfully-paid"
              element={
                <SubscriptionPaymentProtectedRoute>
                  <SubscriptionSuccessfullyPage />{' '}
                </SubscriptionPaymentProtectedRoute>
              }
            />{' '}
            <Route
              path="personalized-career-modal"
              element={
                <ProtectedRoute>
                  <PersonalizedCareerModalPage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path="career-questionnaire"
              element={
                <ProtectedRoute>
                  <CareerQuestionnairePage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path="career-development"
              element={
                <ProtectedRoute>
                  <CareerDevelopmentPage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path="invitations"
              element={
                <ProtectedRoute>
                  <InvitationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="chat"
              element={
                <ProtectedRoute>
                  <ChatPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="job-posts"
              element={
                <ProtectedRoute>
                  <JobPostsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="job-post-apply/:id"
              element={
                <ProtectedRoute>
                  <JobPostApplyPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="my-job-post/:id"
              element={
                <ProtectedRoute>
                  <MyJobPostPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="manage-job-post/:id"
              element={
                <ProtectedRoute>
                  <AddNewJobPostForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="analytics"
              element={
                <ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route>
            <Route path="login" element={<LoginPage />} />

            <Route element={<BlankLayout />}>
              <Route path="registration" element={<RegistrationPage />} />
              <Route path="verify-code" element={<VerifyCodePage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="update-password" element={<UpdatePasswordPage />} />
            </Route>
            <Route
              path="welcome"
              element={
                <ProtectedRoute>
                  <WelcomeToPlatformPage step={1} />
                </ProtectedRoute>
              }
            />
            <Route
              path="welcome-to-platform"
              element={
                <ProtectedRoute>
                  <WelcomeToPlatformPage step={2} />
                </ProtectedRoute>
              }
            />
            <Route
              path="verify-account"
              element={
                <ProtectedRoute>
                  <VerifyAccountPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="registration/company"
              element={<CompanyRegistrationPage />}
            />
            <Route
              path="get-started"
              element={
                <ProtectedRoute>
                  <GetStartedPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;

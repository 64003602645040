import { Box, Popper } from '@mui/material';
import { Dispatch, RefObject, SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MessageForwardIcon from '../../../../components/Icons/MessageForwardIcon';
import MessageLikeIcon from '../../../../components/Icons/MessageLikeIcon';
import MessageReplyIcon from '../../../../components/Icons/MessageReplyIcon';
import { MessageTypeEnum } from '../../../../enums/message-type-enum';
import { useModalManager } from '../../../../hooks/useModalManager';
import {
  IMessage,
  useUpdateChatMessageMutation,
} from '../../../../store/apiSlice/chat/chatApi';
import {
  getSelectedChat,
  setReplyingTo,
} from '../../../../store/apiSlice/chat/chatSlice';
import { useUpdateGroupChatMessageMutation } from '../../../../store/apiSlice/chat/groupApi';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { getCurrentUserId } from '../../../../utils/helper/getCurrentUserId';
import { isGroupChat } from '../ChatNavigation/Chat';
import ForwardToModal from './ForwardToModal';
import { IMessageAttributes } from './Message';

export enum MessageActionModals {
  FORWARD_TO_MODALS = 'FORWARD_TO_MODALS',
}

interface IMessageActionsProps {
  messageRef: RefObject<HTMLDivElement>;
  message: IMessage;
  messageType: MessageTypeEnum;
  open: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  attributes: IMessageAttributes;
}

const MessageActions = ({
  messageRef,
  message,
  messageType,
  open,
  setMenuOpen,
  attributes,
}: IMessageActionsProps) => {
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const [updatePersonalMessage, { isLoading: personalUpdating }] =
    useUpdateChatMessageMutation();
  const [updateGroupMessage, { isLoading: groupUpdating }] =
    useUpdateGroupChatMessageMutation();
  const selectedChat = useSelector(getSelectedChat);
  const { currentModal, toggleModal } = useModalManager();

  const handleLike = useCallback(async () => {
    if (selectedChat && !personalUpdating && !groupUpdating) {
      const currentUserId = getCurrentUserId();
      let likedBy = attributes.likedBy || [];

      if (likedBy.includes(currentUserId)) {
        likedBy = likedBy.filter(id => id !== currentUserId);
      } else {
        likedBy.push(currentUserId);
      }

      if (isGroupChat(selectedChat)) {
        await updateGroupMessage({
          messageId: message.id,
          chatId: selectedChat.id,
          attributes: JSON.stringify({ likedBy }),
        }).unwrap();
      } else {
        await updatePersonalMessage({
          messageId: message.id,
          chatId: selectedChat.id,
          attributes: JSON.stringify({ likedBy }),
        }).unwrap();
      }

      setMenuOpen(false);
    }
  }, [
    attributes,
    groupUpdating,
    personalUpdating,
    selectedChat,
    message,
    updateGroupMessage,
    updatePersonalMessage,
    setMenuOpen,
  ]);

  const handleReply = () => {
    setMenuOpen(false);
    dispatch(setReplyingTo(message));
  };

  const handleForward = () => {
    toggleForwardToModal();
    setMenuOpen(false);
  };

  const toggleForwardToModal = useCallback(
    () => toggleModal(MessageActionModals.FORWARD_TO_MODALS),
    [toggleModal]
  );

  return (
    <>
      {currentModal === MessageActionModals.FORWARD_TO_MODALS && (
        <ForwardToModal
          isOpen
          onClose={toggleForwardToModal}
          message={message}
        />
      )}
      <Popper
        id={message.id}
        open={open}
        anchorEl={messageRef.current}
        placement={
          messageType === MessageTypeEnum.OWN ? 'left-end' : 'right-end'
        }
        disablePortal
        onMouseOver={() => setMenuOpen(true)}
        onMouseLeave={() => setMenuOpen(false)}
        sx={{ m: '0 1px !important' }}
      >
        <Box
          border={'1px solid'}
          borderColor={'background.dark'}
          borderRadius={'0.75rem'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          mt={'2px'}
        >
          <Box
            display={'flex'}
            p={'0.375rem'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={handleLike}
            sx={{
              '*': {
                fill: attributes.likedBy?.includes(getCurrentUserId())
                  ? 'red'
                  : 'background.white',
              },
              '&:hover': {
                cursor: 'pointer',
                '*': {
                  stroke: theme.palette.button.primary,
                },
              },
            }}
          >
            <MessageLikeIcon />
          </Box>
          <Box
            display={'flex'}
            p={'0.375rem'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={handleReply}
            sx={{
              '&:hover': {
                cursor: 'pointer',
                '*': { stroke: theme.palette.button.primary },
              },
            }}
          >
            <MessageReplyIcon />
          </Box>
          <Box
            display={'flex'}
            p={'0.375rem'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={handleForward}
            sx={{
              '&:hover': {
                cursor: 'pointer',
                '*': { stroke: theme.palette.button.primary },
              },
            }}
          >
            <MessageForwardIcon />
          </Box>
        </Box>
      </Popper>
    </>
  );
};

export default MessageActions;

import { SvgIcon, SvgIconProps } from '@mui/material';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface SecurityIconProps extends SvgIconProps {
  active?: boolean;
}

const SecurityIcon = ({ active, ...svgProps }: SecurityIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;
  return (
    <SvgIcon
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g transform="translate(0, 3)" fill="none">
        <path
          stroke={color}
          strokeWidth="1.5"
          d="M 17.166295 7.469429 L 0.833705 7.469429 L 0.833705 19.137228 L 17.166295 19.137228 Z M 17.166295 7.469429 "
          transform="matrix(1.166667,0,0,1.15,0,0)"
        />
        <path
          stroke={color}
          strokeWidth="1.5"
          d="M 7.834821 13.30163 C 7.834821 13.610734 7.955357 13.909647 8.176339 14.127038 C 8.393973 14.347826 8.691964 14.470109 9 14.470109 C 9.308036 14.470109 9.606027 14.347826 9.823661 14.127038 C 10.044643 13.909647 10.165179 13.610734 10.165179 13.30163 C 10.165179 12.992527 10.044643 12.697011 9.823661 12.476223 C 9.606027 12.258832 9.308036 12.136549 9 12.136549 C 8.691964 12.136549 8.393973 12.258832 8.176339 12.476223 C 7.955357 12.697011 7.834821 12.992527 7.834821 13.30163 Z M 7.834821 13.30163 "
          transform="matrix(1.166667,0,0,1.15,0,0)"
        />
        <path
          stroke={color}
          strokeWidth="1.5"
          d="M 4.332589 7.469429 L 4.332589 0.998641 L 13.667411 0.998641 L 13.667411 7.469429 "
          transform="matrix(1.166667,0,0,1.15,0,0)"
        />
      </g>
    </SvgIcon>
  );
};

export default SecurityIcon;

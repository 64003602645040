import React from 'react';

const DotsVerticalIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1426 6.00003C11.1426 6.22735 11.2329 6.44537 11.3936 6.60612C11.5544 6.76686 11.7724 6.85717 11.9997 6.85717C12.227 6.85717 12.4451 6.76686 12.6058 6.60612C12.7666 6.44537 12.8569 6.22735 12.8569 6.00003C12.8569 5.7727 12.7666 5.55468 12.6058 5.39393C12.4451 5.23319 12.227 5.14288 11.9997 5.14288C11.7724 5.14288 11.5544 5.23319 11.3936 5.39393C11.2329 5.55468 11.1426 5.7727 11.1426 6.00003Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M11.1426 12C11.1426 12.2274 11.2329 12.4454 11.3936 12.6061C11.5544 12.7669 11.7724 12.8572 11.9997 12.8572C12.227 12.8572 12.4451 12.7669 12.6058 12.6061C12.7666 12.4454 12.8569 12.2274 12.8569 12C12.8569 11.7727 12.7666 11.5547 12.6058 11.3939C12.4451 11.2332 12.227 11.1429 11.9997 11.1429C11.7724 11.1429 11.5544 11.2332 11.3936 11.3939C11.2329 11.5547 11.1426 11.7727 11.1426 12Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M11.1426 18C11.1426 18.2274 11.2329 18.4454 11.3936 18.6061C11.5544 18.7669 11.7724 18.8572 11.9997 18.8572C12.227 18.8572 12.4451 18.7669 12.6058 18.6061C12.7666 18.4454 12.8569 18.2274 12.8569 18C12.8569 17.7727 12.7666 17.5547 12.6058 17.3939C12.4451 17.2332 12.227 17.1429 11.9997 17.1429C11.7724 17.1429 11.5544 17.2332 11.3936 17.3939C11.2329 17.5547 11.1426 17.7727 11.1426 18Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default DotsVerticalIcon;

import React from 'react';
import StepForm from '../../Auth/VerifyAccount/StepForm';
import { Box, Typography } from '@mui/material';
import Input from '../../MUIComponents/Input';

export const CompanyVerifyCodeForm: React.FC<any> = ({
  registerVerifyCode,
  verifyCodeErrors,
  savedEmail,
  verifyCodeFormSubmit,
  handleVerifyCodeSubmit,
  isVerifyCodeValid,
  step,
  setStep,
  setIsRegistrationComplete,
  resendVerificationCodeSubmit,
}) => (
  <StepForm
    title="Verify your email"
    onNext={handleVerifyCodeSubmit(verifyCodeFormSubmit)}
    nextDisabled={!isVerifyCodeValid}
    step={step}
    setStep={setStep}
  >
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="center"
      gap="14px"
      sx={{ maxWidth: 392, width: '100%' }}
    >
      <Box display="flex" flexDirection="column" gap="36px">
        <Box display="flex" flexDirection="column" gap="1rem">
          <Input
            caption="Verification Code:"
            variant="outlined"
            fullWidth
            placeholder="Enter verification code"
            defaultValue={''}
            register={registerVerifyCode('verificationCode')}
            error={!!verifyCodeErrors.verificationCode}
            helperText={
              verifyCodeErrors.verificationCode
                ? verifyCodeErrors.verificationCode.message
                : ''
            }
          />
          {savedEmail && (
            <Typography variant="body4">
              Verification code has been sent to {savedEmail}
            </Typography>
          )}
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'right'} marginTop={'16px'}>
        <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          <Typography
            onClick={() => setIsRegistrationComplete(false)}
            variant="body4"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              transition: 'color 0.3s',
              ':hover': { color: 'button.primary' },
            }}
          >
            Back to registration?{' '}
          </Typography>
          <Typography
            onClick={() => resendVerificationCodeSubmit(savedEmail)}
            variant="body4"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              transition: 'color 0.3s',
              ':hover': { color: 'button.primary' },
            }}
          >
            Resend verification code?{' '}
          </Typography>
        </Box>
      </Box>
    </Box>
  </StepForm>
);

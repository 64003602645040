import {
  Box,
  CircularProgress,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useCurrentPageUserId } from '../../../../hooks/useCurrentPageUserId';
import ArticleLayout from '../../../../layouts/ArticleLayout';
import { useGetUserAchievementsByUserIdQuery } from '../../../../store/apiSlice/sportBackgroundApi';
import AchievementFileEntry from './components/AchievementFileEntry';

const AchievementsTab = () => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  const { data: achievements, isLoading } = useGetUserAchievementsByUserIdQuery(
    userId ?? '',
    {
      skip: !userId,
    }
  );

  const filteredAchivements = useMemo(
    () => achievements?.filter(item => !!item.userSportBackgroundFiles.length),
    [achievements]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={{ xs: '16px', xl: '22px' }}
    >
      {isLoading && (
        <Skeleton
          variant="rounded"
          sx={{
            minHeight: '530px',
          }}
        />
      )}
      {!!filteredAchivements?.length &&
        filteredAchivements.map(achievement => (
          <ArticleLayout
            key={achievement.id}
            className="about-article"
            rowGap={8}
            display="flex"
            flexDirection="column"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" textTransform="capitalize">
                achievements
              </Typography>
            </Stack>
            {!!achievement.userSportBackgroundFiles.length &&
              achievement.userSportBackgroundFiles.map(
                (userSportBackgroundFile, index) => {
                  return (
                    <Fragment key={userSportBackgroundFile.id}>
                      <AchievementFileEntry
                        achievementFile={userSportBackgroundFile}
                      />
                      {index !==
                        achievement.userSportBackgroundFiles.length - 1 && (
                        <Divider />
                      )}
                    </Fragment>
                  );
                }
              )}
          </ArticleLayout>
        ))}
    </Box>
  );
};

export default AchievementsTab;

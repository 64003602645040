import { useMemo } from 'react';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { IMenuItem } from '../../sidebar/JobPostSidebarMoreMenu';
import { Box } from '@mui/material';

interface ManageJobPostsModalProps {
  isOpen?: boolean;
  onClose: () => void;
  jobpostMenuItems: IMenuItem[];
}

const ManageJobPostsModal = ({
  isOpen = false,
  jobpostMenuItems,
  onClose,
}: ManageJobPostsModalProps) => {
  const footer = useMemo(() => {
    return (
      <CustomButton
        variantType={ButtonTypeEnum.SECONDARY}
        buttonWidth="100%"
        onClick={onClose}
      >
        cancel
      </CustomButton>
    );
  }, [onClose]);

  return (
    <BaseModal isOpen={isOpen} toggle={onClose} footer={{ component: footer }}>
      {jobpostMenuItems.length > 0 &&
        jobpostMenuItems.map((item, idx) => (
          <Box
            key={idx}
            onClick={() => {
              item.action();
            }}
            sx={{
              display: 'flex',
              gap: '6px',
              fontSize: '12px',
              p: '15px',
              borderBottom: item.isDivider ? '1px solid #E8EBEF' : 'none',
            }}
          >
            {item.icon} {item.label}
          </Box>
        ))}
    </BaseModal>
  );
};

export default ManageJobPostsModal;

import { List } from '@mui/material';
import JobPostsItem from './JobPostsItem';
import {
  IAppliedUser,
  IJobpost,
} from '../../../../interfaces/jobpost.interface';

interface IJobPostWithAppliedUser {
  type: 'APPLIED_USER';
  appliedUser: IAppliedUser;
}

interface IJobPostRegular {
  type: 'REGULAR';
  jobpost: IJobpost;
}

interface ICompanyJobPost {
  type: 'COMPANY_JOBPOST';
  jobpost: IJobpost;
}

export type JobPostItem =
  | IJobPostWithAppliedUser
  | IJobPostRegular
  | ICompanyJobPost;

interface IJobPostsListProps {
  jobPosts: { appliedUser: IAppliedUser }[] | { jobpost: IJobpost }[];
  jobPostTitleClickPath: string;
  showStatus?: boolean;
  showNewAppliedUser?: boolean;
}

const JobPostsList: React.FC<IJobPostsListProps> = ({
  jobPosts,
  jobPostTitleClickPath,
  showStatus = false,
  showNewAppliedUser = false,
}) => {
  return (
    <List sx={{ p: 0 }}>
      {jobPosts.map(jobPost => {
        const mappedJobPost: JobPostItem =
          'jobpost' in jobPost
            ? { type: 'REGULAR', jobpost: jobPost.jobpost }
            : { type: 'APPLIED_USER', appliedUser: jobPost.appliedUser };

        const key =
          mappedJobPost.type === 'REGULAR'
            ? mappedJobPost.jobpost.id
            : mappedJobPost.appliedUser.id;

        return (
          <JobPostsItem
            key={key}
            jobPost={mappedJobPost}
            jobPostTitleClickPath={jobPostTitleClickPath}
            showStatus={showStatus}
            showNewAppliedUser={showNewAppliedUser}
          />
        );
      })}
    </List>
  );
};

export default JobPostsList;

import { useDispatch, useSelector } from 'react-redux';
import {
  IMessage,
  useGetAllChatMessagesQuery,
} from '../../store/apiSlice/chat/chatApi';
import {
  getChatType,
  getMessageSending,
  getPage,
  getSelectedChat,
  getToken,
  setPage,
} from '../../store/apiSlice/chat/chatSlice';
import {
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { groupMessagesByDate } from '../../utils/helper/groupMessages';
import { ChatTypeEnum } from '../../enums/chat-enum';
import { useGetAllGroupChatMessagesQuery } from '../../store/apiSlice/chat/groupApi';

const useGetChatMessages = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState<string>('');
  const [messages, setMessages] = useState<{ [index: string]: IMessage[] }>({});

  const selectedChat = useSelector(getSelectedChat);
  const chatType = useSelector(getChatType);
  const { isTokenLoading } = useSelector(getToken);
  const page = useSelector(getPage);
  const isMessageSending = useSelector(getMessageSending);
  const dispatch = useDispatch();

  const {
    data: personalMessagesData,
    isLoading: personalMessagesLoading,
    isFetching: personalMessagesFetching,
  } = useGetAllChatMessagesQuery(
    { chatId: selectedChat?.id || '', page },
    {
      skip:
        chatType === ChatTypeEnum.GROUP ||
        !selectedChat ||
        isMessageSending ||
        isTokenLoading,
    }
  );

  const {
    data: groupMessagesData,
    isLoading: groupMessagesLoading,
    isFetching: groupMessagesFetching,
  } = useGetAllGroupChatMessagesQuery(
    { chatId: selectedChat?.id || '', page },
    {
      skip:
        chatType === ChatTypeEnum.PERSONAL ||
        !selectedChat ||
        isMessageSending ||
        isTokenLoading,
    }
  );

  const personalMessages = useMemo(() => {
    if (personalMessagesData?.messages)
      return groupMessagesByDate(personalMessagesData.messages);
  }, [personalMessagesData]);

  const groupMessages = useMemo(() => {
    if (groupMessagesData?.messages)
      return groupMessagesByDate(groupMessagesData.messages);
  }, [groupMessagesData]);

  useEffect(() => {
    if (chatType === ChatTypeEnum.PERSONAL) {
      setLoading(personalMessagesFetching || personalMessagesLoading);
      setMessages(personalMessages || {});
      setNextPage(personalMessagesData?.nextPage || '');
    } else if (chatType === ChatTypeEnum.GROUP) {
      setLoading(groupMessagesFetching || groupMessagesLoading);
      setMessages(groupMessages || {});
      setNextPage(groupMessagesData?.nextPage || '');
    } else {
      setMessages({});
      setNextPage('');
    }
  }, [
    groupMessages,
    personalMessages,
    personalMessagesFetching,
    personalMessagesLoading,
    groupMessagesFetching,
    groupMessagesLoading,
    personalMessagesData,
    groupMessagesData,
    chatType,
  ]);

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
    e => {
      if (
        -e.currentTarget.scrollTop > e.currentTarget.scrollHeight / 2.5 &&
        nextPage &&
        !isLoading
      ) {
        dispatch(setPage(nextPage));
      }
    },
    [nextPage, isLoading, dispatch]
  );

  return { messages, handleScroll, isMessagesLoading: isLoading };
};

export default useGetChatMessages;

import ImageList, { ImageListProps } from '@mui/material/ImageList';
import ImageListItem, { ImageListItemProps } from '@mui/material/ImageListItem';
import React from 'react';

type ItemData = {
  src: string;
  srcSet: string;
  title: string;
};

interface StandardImageListProps {
  itemData: ItemData[];
  imageListProps?: Omit<ImageListProps, 'children'>; // Omit 'children' to avoid conflict
  imageListItemProps?: Omit<ImageListItemProps, 'children'>; // Omit 'children' to avoid conflict
  overlayButtons?: React.ReactNode;
}

const StandardImageList = ({
  itemData,
  imageListProps = {},
  imageListItemProps = {},
  overlayButtons,
}: StandardImageListProps) => {
  return (
    <ImageList {...imageListProps}>
      {itemData.map((item, index) => (
        <ImageListItem rows={1} {...imageListItemProps} key={item.src + index}>
          <img
            srcSet={item.srcSet}
            src={item.src}
            alt={item.title}
            loading="lazy"
            style={{ overflow: 'hidden' }}
          />
          {overlayButtons}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default StandardImageList;

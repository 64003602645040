import { IconButton, IconButtonProps } from '@mui/material';
import { ReactNode } from 'react';

interface SquareIconButtonProps extends IconButtonProps {
  children?: ReactNode;
}

const SquareIconButton = ({
  children,
  sx,
  ...restProps
}: SquareIconButtonProps) => {
  return (
    <IconButton
      sx={{
        borderRadius: '8px',
        minWidth: '0',
        minHeight: '0',
        padding: '6px',
        height: 'auto',
        width: 'auto',

        '& .MuiTouchRipple-child': { borderRadius: 'inherit' },

        ...sx,
      }}
      {...restProps}
    >
      {children}
    </IconButton>
  );
};

export default SquareIconButton;

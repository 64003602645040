import { City, Country, ICountry, IState, State } from 'country-state-city';

export interface CityOption {
  name: string;
  id: string;
}

export const getCountries = (): ICountry[] => Country.getAllCountries();

export const getCitiesOfCountry = (
  countryCode: string | undefined
): CityOption[] | undefined => {
  return countryCode
    ? City.getCitiesOfCountry(countryCode)?.map(
        ({ name, latitude, longitude }, index) => ({
          name,
          id: `${latitude}${longitude}${index}`,
        })
      )
    : undefined;
};

export const getStatesOfCountry = (
  countryCode: string | undefined
): IState[] | undefined => {
  return countryCode ? State.getStatesOfCountry(countryCode) : undefined;
};

export const getCitiesOfState = (
  countryCode: string | undefined,
  stateCode: string | undefined
): CityOption[] | undefined => {
  return countryCode && stateCode
    ? City.getCitiesOfState(countryCode, stateCode)?.map(
        ({ name, latitude, longitude }, index) => ({
          name,
          id: `${latitude}${longitude}${index}`,
        })
      )
    : undefined;
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { IRegistrationRequest } from '../../../interfaces/auth.interface';
import { useRegistrationMutation } from '../../../store/apiSlice/auth/authApi';
import { registrationSchema } from '../../../validation/auth-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CustomButton from '../../MUIComponents/CustomButton';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import Input from '../../MUIComponents/Input';
import { errorHelper } from '../../../utils/helper/error-helper';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import PasswordValidation from '../Elements/PasswordValidation';

interface RegistrationFormInputs {
  email: string;
  password: string;
  confirmPassword: string;
}

const Registration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const inviteEmail = searchParams.get('inviteEmail');
  const inviteToken = searchParams.get('inviteToken');

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarError, setSnackbarError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<RegistrationFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      email: inviteEmail ?? '',
    },
    resolver: yupResolver(registrationSchema, { abortEarly: false }),
    mode: 'onChange',
  });
  // eslint-disable-next-line
  const [registration, { isLoading, isError, error, data: responseData }] =
    useRegistrationMutation();

  const onSubmit = async (data: RegistrationFormInputs) => {
    try {
      const registrationData: IRegistrationRequest = {
        email: data.email,
        password: data.password,
      };

      if (!inviteEmail || !inviteToken) {
        throw new Error('Invite email or token is missing');
      }
      await registration({
        inviteToken,
        inviteEmail,
        registrationData,
      }).unwrap();
      setSnackbarMessage('Registration successful');
      setSnackbarError(false);
      navigate('/verify-code', {
        replace: true,
        state: { email: data.email || '', password: data.password || '' },
      });
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
      console.error('Registration failed:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  return (
    <Box
      component={'form'}
      display="flex"
      flexDirection="column"
      gap="3rem"
      width={'min(26.5625rem,100%)'}
    >
      <Typography variant="h2">Create account</Typography>
      <Box display="flex" flexDirection="column" gap="2.25rem" width={'100%'}>
        <Input
          caption="Email:"
          type="email"
          variant="outlined"
          fullWidth
          placeholder="Enter email address"
          defaultValue={inviteEmail ?? ''}
          register={register('email')}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
          disabled
        />
        <Input
          caption="New password:"
          type="password"
          variant="outlined"
          fullWidth
          placeholder="Enter new password"
          register={register('password')}
          error={!!errors.password}
        />
        <PasswordValidation
          isDirty={Object.keys(dirtyFields).length > 0}
          errorMessages={
            Object.values(errors?.password?.types ?? {})
              .filter(Boolean)
              .flat() as string[]
          }
        />
        <Input
          caption="Confirm new password:"
          type="password"
          variant="outlined"
          fullWidth
          placeholder="Confirm new password"
          register={register('confirmPassword', {
            validate: value =>
              value === watch('password') || 'Passwords do not match',
          })}
          error={!!errors.confirmPassword}
          helperText={
            errors.confirmPassword ? errors.confirmPassword.message : ''
          }
        />
      </Box>
      <CustomButton
        type={'submit'}
        buttonWidth={mobileView ? '100%' : '50%'}
        variantType={ButtonTypeEnum.PRIMARY}
        fullWidth
        onClick={handleSubmit(onSubmit)}
        disabled={isLoading}
        icon={<RightArrowIcon />}
      >
        Sign Up
      </CustomButton>
      <SnackbarCustom
        open={!!snackbarMessage}
        onClose={handleCloseSnackbar}
        message={snackbarMessage || ''}
        severity={
          snackbarError
            ? SnackbarSeverityEnum.ERROR
            : SnackbarSeverityEnum.SUCCESS
        }
      />
    </Box>
  );
};

export default Registration;

import { useEffect, useMemo, useState } from 'react';
import {
  IChat,
  useGetAllMyChatsQuery,
} from '../../store/apiSlice/chat/chatApi';
import {
  IGroupChat,
  useGetAllMyGroupChatsQuery,
} from '../../store/apiSlice/chat/groupApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatsLoaded,
  getChatType,
  resetChatsLoaded,
  resetPage,
  resetToken,
  setChatsLoaded,
  setChatState,
} from '../../store/apiSlice/chat/chatSlice';
import { ChatStateEnum, ChatTypeEnum } from '../../enums/chat-enum';

const useGetMyChats = ({ searchValue = '' }: { searchValue?: string }) => {
  const [myChats, setMyChats] = useState<IChat[] | IGroupChat[] | undefined>(
    undefined
  );

  const { data: myPersonalChats, isFetching: personalFetching } =
    useGetAllMyChatsQuery();
  const { data: myGroupChats, isFetching: groupFetching } =
    useGetAllMyGroupChatsQuery();

  const chatType = useSelector(getChatType);
  const chatsLoaded = useSelector(getChatsLoaded);
  const dispatch = useDispatch();

  const filteredPersonalChats = useMemo(() => {
    return myPersonalChats?.filter(chat => {
      const firstName = chat.withUser.firstName?.toLocaleLowerCase();
      const lastName = chat.withUser.lastName?.toLocaleLowerCase();
      const lastMessage = chat.lastMessageText?.toLocaleLowerCase();

      return [firstName, lastName, lastMessage].some(item =>
        item?.includes(searchValue.toLocaleLowerCase())
      );
    });
  }, [searchValue, myPersonalChats]);

  const filteredGroupChats = useMemo(() => {
    return myGroupChats?.filter(chat => {
      const name = chat.name?.toLocaleLowerCase();
      const lastMessage = chat.lastMessageText?.toLocaleLowerCase();

      return [name, lastMessage].some(item =>
        item?.includes(searchValue.toLocaleLowerCase())
      );
    });
  }, [searchValue, myGroupChats]);

  useEffect(() => {
    if (
      chatType === ChatTypeEnum.GROUP &&
      filteredGroupChats &&
      filteredGroupChats.length > 0
    ) {
      setMyChats(filteredGroupChats);
    } else if (
      chatType === ChatTypeEnum.PERSONAL &&
      filteredPersonalChats &&
      filteredPersonalChats.length > 0
    ) {
      setMyChats(filteredPersonalChats);
    } else setMyChats([]);
  }, [filteredGroupChats, filteredPersonalChats, chatType]);

  useEffect(() => {
    if (!chatsLoaded)
      if (
        chatType === ChatTypeEnum.PERSONAL &&
        myPersonalChats &&
        myPersonalChats.length > 0 &&
        !personalFetching
      ) {
        setMyChats(filteredPersonalChats);
        dispatch(resetPage());
        dispatch(setChatState(ChatStateEnum.CHAT));
        dispatch(setChatsLoaded());
        dispatch(resetToken());
      } else if (
        chatType === ChatTypeEnum.GROUP &&
        myGroupChats &&
        myGroupChats.length > 0 &&
        !groupFetching
      ) {
        setMyChats(filteredGroupChats);
        dispatch(resetPage());
        dispatch(setChatState(ChatStateEnum.CHAT));
        dispatch(setChatsLoaded());
        dispatch(resetToken());
      } else {
        dispatch(resetChatsLoaded());
        setMyChats(undefined);
      }
  }, [
    chatType,
    myPersonalChats,
    myGroupChats,
    dispatch,
    filteredGroupChats,
    filteredPersonalChats,
    chatsLoaded,
    personalFetching,
    groupFetching,
  ]);

  return { myChats };
};

export default useGetMyChats;

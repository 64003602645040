import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { Photo, PhotoAlbum } from '../../../../../interfaces/media.interface';
import { useUpdatePhotoAlbumMutation } from '../../../../../store/apiSlice/profileMediaApi';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import { mediaSchema } from '../../../../../validation/media-validation';
import RepostIcon from '../../../../Icons/RepostIcon';
import BaseModal from '../../../../MUIComponents/BaseModal';
import CustomButton from '../../../../MUIComponents/CustomButton';
import IconRoundBackground from '../../../../MUIComponents/IconRoundBackground';
import Input from '../../../../MUIComponents/Input';
import SnackbarCustom from '../../../../MUIComponents/SnackbarCustom';

const ERROR_MESSAGES = {
  GENERIC_FILE_ERROR: "Can't update cover image, something went wrong...",
  GENERIC_UPDATE_ERROR: "Can't update album, something went wrong...",
  SUCCESS_MESSAGE: 'Album successfully updated',
};

interface UpdateAlbumInputs {
  albumName: string;
}

interface EditAlbumModalProps {
  album: PhotoAlbum;
  title: string;
  newCoverImage: Photo | null;
  isOpen?: boolean;
  onClose: () => void;
  openChooseCoverImageModal: () => void;
}

const EditAlbumModal = ({
  album,
  title,
  newCoverImage,
  onClose,
  isOpen = false,
  openChooseCoverImageModal,
}: EditAlbumModalProps) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [updatePhotoAlbum, { isLoading: isUpdatingPhotoAlbum }] =
    useUpdatePhotoAlbumMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateAlbumInputs>({
    resolver: yupResolver(mediaSchema),
    defaultValues: {
      albumName: album.name ?? '',
    },
    mode: 'onSubmit',
  });

  const onSubmit = useCallback(
    async (data: UpdateAlbumInputs) => {
      try {
        let coverImage: File | null = null;

        const createFileFromUrl = async (
          fileName: string,
          url: string
        ): Promise<File | null> => {
          try {
            const response = await fetch(url, { cache: "no-cache" });

            if (!response.ok) {
              throw new Error('Failed to fetch file from URL');
            }
            const blob = await response.blob();
            return new File([blob], fileName, { type: blob.type });
          } catch (error) {
            console.error('Error creating file from URL:', error);
            return null;
          }
        };

        const fileUrl = newCoverImage
          ? newCoverImage.fileUrl || ''
          : album.fileUrl;
        const fileName = newCoverImage
          ? newCoverImage.fileName
          : album.fileName;

        coverImage = await createFileFromUrl(fileName ?? '', fileUrl);

        if (!coverImage) {
          throw new Error(ERROR_MESSAGES.GENERIC_FILE_ERROR);
        }

        const updateAlbumFormData = new FormData();
        if (data.albumName !== album.name) {
          updateAlbumFormData.append('name', data.albumName);
        }
        updateAlbumFormData.append('file', coverImage);

        const { error } = await updatePhotoAlbum({
          id: album.id,
          data: updateAlbumFormData,
        });

        if (error) {
          throw new Error(ERROR_MESSAGES.GENERIC_UPDATE_ERROR);
        }

        setSnackbarMessage(ERROR_MESSAGES.SUCCESS_MESSAGE);
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
        setSnackbarOpen(true);

        setTimeout(() => {
          onClose();
          setSnackbarOpen(false);
        }, 1000);
      } catch (error) {
        const errorMessage = errorHelper(error);
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
      }
    },
    [onClose, album, newCoverImage, updatePhotoAlbum]
  );

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const footer = useMemo(() => {
    return (
      <Box
        sx={{
          mt: '36px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <CustomButton
          disabled={isUpdatingPhotoAlbum}
          onClick={onClose}
          buttonWidth={189}
          variantType={ButtonTypeEnum.SECONDARY}
        >
          cancel
        </CustomButton>
        <CustomButton
          disabled={isUpdatingPhotoAlbum}
          buttonWidth={189}
          variantType={ButtonTypeEnum.PRIMARY}
          onClick={handleSubmit(onSubmit)}
        >
          save
        </CustomButton>
      </Box>
    );
  }, [onClose, handleSubmit, onSubmit, isUpdatingPhotoAlbum]);

  return (
    <BaseModal
      boxSx={{ width: { xs: 1, lg: 466 } }}
      header={{ text: title }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: footer }}
    >
      <Grid container rowGap="28px" className="profile-info-page__edit-album">
        <Grid item container flexDirection="column" rowGap="12px">
          <Input
            caption="Playlist name:"
            variant="outlined"
            width={1}
            name="albumName"
            containerFlex={1}
            placeholder="Enter album name"
            register={register('albumName')}
            error={!!errors.albumName}
            helperText={errors.albumName ? errors.albumName.message : ''}
          />
        </Grid>
        <Grid item container flexDirection="column" rowGap="12px">
          <Typography variant="caption">Choose a cover image:</Typography>
          <Box position="relative">
            <Box
              component="img"
              src={
                newCoverImage?.fileUrl
                  ? newCoverImage.fileUrl || ''
                  : album.fileUrl
              }
              alt={album.fileName || newCoverImage?.fileName || ''}
              width={1}
              height={1}
              maxHeight={300}
              sx={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
            <IconButton
              onClick={openChooseCoverImageModal}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'fit-content',
                height: 'fit-content',
                p: 0,
              }}
            >
              <IconRoundBackground
                width={40}
                height={40}
                backgroundColor="secondary.main"
              >
                <RepostIcon stroke="white" />
              </IconRoundBackground>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default EditAlbumModal;

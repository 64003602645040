import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import {
  IComment,
  ICommentResponse,
  ICreateCommentRequest,
  IUpdateCommentRequest,
} from '../../../interfaces/comment.interface';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const commentApi = createApi({
  reducerPath: 'commentApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_COMMENT_MICROSERVICE_API_URL}/comment`
  ),
  tagTypes: ['comment'],

  endpoints: builder => ({
    createComment: builder.mutation<ICommentResponse, ICreateCommentRequest>({
      query: ({ postId, ...data }) => ({
        url: `/postId/${postId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['comment'],
    }),

    getComments: builder.query<ICommentResponse, void>({
      query: () => '',
      providesTags: ['comment'],
    }),

    getPostComments: builder.query<
      ICommentResponse,
      {
        postId: string;
        page?: number;
        limit?: number;
        sort?: 'asc' | 'desc';
      }
    >({
      query: ({ postId, page = 1, limit = 10, sort = 'desc' }) => {
        return `/postId/${postId}?page=${page}&limit=${limit}&sort=${sort}`;
      },
      providesTags: ['comment'],
    }),

    getCommentById: builder.query<IComment, string>({
      query: id => `/${id}`,
      providesTags: ['comment'],
    }),

    updateCommentById: builder.mutation<
      IComment,
      { id: string; data: IUpdateCommentRequest }
    >({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['comment'],
    }),

    deleteCommentById: builder.mutation<IComment, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['comment'],
    }),

    getCommentReplies: builder.query<
      ICommentResponse,
      {
        commentId: string;
        page?: number;
        limit?: number;
        sort?: 'asc' | 'desc';
      }
    >({
      query: ({ commentId, page = 1, limit = 10, sort = 'desc' }) => {
        return `/replies/comment/${commentId}?page=${page}&limit=${limit}&sort=${sort}`;
      },
      providesTags: ['comment'],
    }),
  }),
});

export const {
  useCreateCommentMutation,
  useGetCommentsQuery,
  useGetPostCommentsQuery,
  useGetCommentByIdQuery,
  useUpdateCommentByIdMutation,
  useDeleteCommentByIdMutation,
  useGetCommentRepliesQuery,
} = commentApi;

export default commentApi;

import { useDispatch, useSelector } from 'react-redux';
import {
  useDeleteChatTokenMutation,
  useGetChatTokenQuery,
} from '../../store/apiSlice/chat/chatApi';
import {
  getChatType,
  getSelectedChat,
  getToken,
  resetToken,
  setToken,
  setTokenLoading,
} from '../../store/apiSlice/chat/chatSlice';
import { ChatTypeEnum } from '../../enums/chat-enum';
import {
  useDeleteGroupChatTokenMutation,
  useGetGroupChatTokenQuery,
} from '../../store/apiSlice/chat/groupApi';
import { useEffect } from 'react';
import { config } from '../../config/config';

const useChatToken = () => {
  const dispatch = useDispatch();
  const chatType = useSelector(getChatType);
  const selectedChat = useSelector(getSelectedChat);
  const { token } = useSelector(getToken);

  const [deletePersonalChatToken] = useDeleteChatTokenMutation();
  const [deleteGroupChatToken] = useDeleteGroupChatTokenMutation();

  const {
    data: personalChatToken,
    isLoading: personalChatTokenLoading,
    isFetching: personalChatTokenFetching,
  } = useGetChatTokenQuery(
    { chatId: selectedChat?.id || '', userId: selectedChat?.userId || '' },
    {
      skip: chatType === ChatTypeEnum.GROUP || !selectedChat,
      pollingInterval: config.TWILIO_TOKEN_POLLING_INTERVAL,
    }
  );
  const {
    data: groupChatToken,
    isLoading: groupChatTokenLoading,
    isFetching: groupChatTokenFetching,
  } = useGetGroupChatTokenQuery(
    { chatId: selectedChat?.id || '', userId: selectedChat?.userId || '' },
    {
      skip: chatType === ChatTypeEnum.PERSONAL || !selectedChat,
      pollingInterval: config.TWILIO_TOKEN_POLLING_INTERVAL,
    }
  );

  useEffect(() => {
    if (chatType === ChatTypeEnum.GROUP && groupChatToken) {
      dispatch(setToken(groupChatToken));
      dispatch(
        setTokenLoading(groupChatTokenFetching || groupChatTokenLoading)
      );
    } else if (chatType === ChatTypeEnum.PERSONAL && personalChatToken) {
      dispatch(setToken(personalChatToken));
      dispatch(
        setTokenLoading(personalChatTokenFetching || personalChatTokenLoading)
      );
    } else {
      dispatch(resetToken());
    }
  }, [
    chatType,
    groupChatToken,
    personalChatToken,
    personalChatTokenFetching,
    personalChatTokenLoading,
    groupChatTokenFetching,
    groupChatTokenLoading,
    dispatch,
    token,
  ]);

  return {
    deleteToken:
      chatType === ChatTypeEnum.GROUP
        ? deleteGroupChatToken
        : deletePersonalChatToken,
  };
};

export default useChatToken;

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckMarkIcon from '../../components/Icons/CheckMarkIcon';
import SubscriptionPlanCard from './components/SubscriptionPlanCard';
import {
  useCreateCompanySubscriptionMutation,
  useGetCompanyProductsQuery,
} from '../../store/apiSlice/subscription/subscriptionApi';
import {
  ICompanyProduct,
  ICreateCompanySubscriptionRequest,
} from '../../interfaces/subscription.interface';
import { getIncludedFeaturesHelper } from '../../utils/helper/subscription-helper';
import { errorHelper } from '../../utils/helper/error-helper';
import { SnackbarSeverityEnum } from '../../enums/snackbar-severity-enum';
import SnackbarCustom from '../../components/MUIComponents/SnackbarCustom';
import { useModalManager } from '../../hooks/useModalManager';
import SubscriptionModal from './components/SubscriptionModal';
import { CompanyProductDefaultNameEnum } from '../../enums/subscription-enum';

const subscriptionTabsLabels = ['Annual', 'Semi-Annual', 'Quarterly'];
const SubscriptionPlansPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { currentModal, toggleModal } = useModalManager();

  const [searchParams, setSearchParams] = useSearchParams();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const [createCompanySubscription] = useCreateCompanySubscriptionMutation();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const { data, isLoading, isError } = useGetCompanyProductsQuery();

  const [selectedProduct, setSelectedProduct] =
    useState<ICompanyProduct | null>(null);

  useEffect(() => {
    if (data?.data?.length) {
      setSelectedProduct(
        data?.data?.find(
          (product: ICompanyProduct) => product.interval === 'year'
        ) || null
      );
    }
  }, [data]);

  const order = [
    CompanyProductDefaultNameEnum.BASIC_COMPANY_PLAN,
    CompanyProductDefaultNameEnum.ADVANCED_COMPANY_PLAN,
    CompanyProductDefaultNameEnum.PRO_COMPANY_PLAN,
  ];

  const sortedData = [...(data?.data || [])].sort((a, b) => {
    return (
      order.indexOf(a.name as CompanyProductDefaultNameEnum) -
      order.indexOf(b.name as CompanyProductDefaultNameEnum)
    );
  });

  const handleChoosePlan = async (product: ICompanyProduct) => {
    const subscriptionData: ICreateCompanySubscriptionRequest = {
      apiProductId: product.apiProductId,
    };

    const response = await createCompanySubscription(subscriptionData);

    if (response.error) {
      setSnackbarMessage(`Error: ${errorHelper(response.error)}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }
    navigate('/subscription-payment', {
      state: {
        product: product,
        clientSecret: response.data.data.clientSecret,
      },
    });
  };

  const [activeTab, setActiveTab] = useState<number>(
    parseInt(searchParams.get('tab') || '0', 10)
  );

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleOpenModal = () => {
    toggleModal('SUBSCRIPTION_INFORMATION_MODAL');
  };

  const handleClose = () => {
    toggleModal('');
  };

  if (isLoading) {
    return (
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          m: 0,
          padding: isSmallScreen ? '36px 16px' : '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          bgcolor: theme.palette.background.white,
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (isError || !data) {
    return (
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          m: 0,
          padding: isSmallScreen ? '36px 16px' : '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          bgcolor: theme.palette.background.white,
        }}
      >
        <Typography variant="h6" color="error">
          Failed to load subscription plans.
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        m: 0,
        padding: isSmallScreen ? '36px 16px' : '36px',
        bgcolor: theme.palette.background.white,
      }}
    >
      {currentModal === 'SUBSCRIPTION_INFORMATION_MODAL' && (
        <SubscriptionModal isOpen onClose={handleClose} />
      )}

      <Grid
        container
        direction="column"
        rowGap={isSmallScreen ? '28px' : '36px'}
      >
        <Grid
          item
          container
          component="header"
          justifyContent="center"
          alignItems="center"
          display="flex"
          direction="column"
          gap="22px"
        >
          <Typography variant="h1" fontSize={isMediumScreen ? '26px' : '34px'}>
            Subscription plans
          </Typography>
          <Typography
            lineHeight={'1.4'}
            maxWidth={{
              lg: '800px',
            }}
            variant="body3"
            textAlign="center"
          >
            Choose the plan that fits your needs and leverage subscription
            credits for additional services like featured job posts, advanced
            analytics, and more. Start optimizing your recruitment strategy
            today!
          </Typography>

          <Tabs
            value={activeTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs"
            sx={{
              marginTop: isSmallScreen ? '6px' : '0',
              bgcolor: 'background.light',
              width: `${matches ? '100%' : '92vw'}`,
              maxWidth: 'fit-content',
              borderRadius: '1px',
            }}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {subscriptionTabsLabels.map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{
                  minWidth: matches ? '140px' : '140px',
                  color: 'text.primary',
                  fontSize: '10px',
                  fontWeight: 800,
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'background.white',
                  },
                }}
              />
            ))}
          </Tabs>
        </Grid>

        <Grid
          container
          direction={isSmallScreen ? 'column-reverse' : 'column'}
          rowGap={isSmallScreen ? '28px' : '36px'}
        >
          <Grid
            item
            container
            component="main"
            display="flex"
            justifyContent="center"
            alignItems="stretch"
            gap="22px"
          >
            {sortedData
              ?.filter((product: ICompanyProduct) => {
                if (activeTab === 0) {
                  return product.interval === 'year';
                }
                if (activeTab === 1) {
                  return (
                    product.interval === 'month' && product.intervalCount === 6
                  );
                }

                if (activeTab === 2) {
                  return (
                    product.interval === 'month' && product.intervalCount < 6
                  );
                }
              })
              ?.map((product: ICompanyProduct) => (
                <SubscriptionPlanCard
                  key={product.id}
                  planName={product.name}
                  price={`${product.price}`}
                  period={`${
                    product.interval === 'year'
                      ? 'annual'
                      : product.interval === 'month' &&
                          product.intervalCount === 6
                        ? 'semi-annual'
                        : 'quarterly'
                  }`}
                  pricePlanAudience={product.description}
                  isMostPopular={product.isMostPopular}
                  sx={{ cursor: 'pointer' }}
                  includedFeatures={getIncludedFeaturesHelper(product.name)}
                  isSelected={selectedProduct?.id === product.id}
                  onClick={() => setSelectedProduct(product)}
                  handleChoosePlan={() => handleChoosePlan(product)}
                  showInfoIcon={true}
                  iconClick={handleOpenModal}
                />
              ))}
          </Grid>

          {selectedProduct && (
            <Grid
              item
              component="footer"
              p={isMediumScreen ? '16px' : '36px'}
              bgcolor={theme.palette.background.light}
            >
              <Typography variant="h3">Subscription credits usage:</Typography>
              <Divider sx={{ m: isSmallScreen ? '22px 0' : '28px 0' }} />

              <Grid
                container
                gap="16px"
                sx={{
                  display: 'grid',
                  gridTemplateColumns: isMediumScreen ? '1fr' : '2fr 3fr',
                  gridAutoRows: 'min-content',

                  '& > .MuiGrid-root': {
                    gap: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.text.secondary,

                    '& > .MuiTypography-root': {
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: 'normal',
                    },
                  },
                }}
              >
                <>
                  <Grid item>
                    <CheckMarkIcon />
                    <Typography variant="body3">Featured job post:</Typography>
                    <Typography
                      variant="body3"
                      color={theme.palette.text.primary}
                    >
                      {selectedProduct?.companyProductLimit.featuredJobPost ??
                        0}{' '}
                      credits
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckMarkIcon />
                    <Typography variant="body3">Profile boost:</Typography>
                    <Typography
                      variant="body3"
                      color={theme.palette.text.primary}
                    >
                      {selectedProduct?.companyProductLimit.profileBoost ?? 0}{' '}
                      credits per boost
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckMarkIcon />
                    <Typography variant="body3">Sponsored job post:</Typography>
                    <Typography
                      variant="body3"
                      color={theme.palette.text.primary}
                    >
                      {selectedProduct?.companyProductLimit.sponsoredJobPost ??
                        0}{' '}
                      credits
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckMarkIcon />
                    <Typography variant="body3">Advanced analytics:</Typography>
                    <Typography
                      variant="body3"
                      color={theme.palette.text.primary}
                    >
                      {selectedProduct?.companyProductLimit
                        .accessToAdvancedAnalytics ?? 0}{' '}
                      credits per report
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckMarkIcon />
                    <Typography variant="body3">Candidate messages:</Typography>
                    <Typography
                      variant="body3"
                      color={theme.palette.text.primary}
                    >
                      {selectedProduct?.companyProductLimit.candidateMessage ??
                        0}{' '}
                      credits per message
                    </Typography>
                  </Grid>
                </>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Container>
  );
};

export default SubscriptionPlansPage;

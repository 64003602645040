import { SvgIcon, SvgIconProps } from '@mui/material';

const UnblockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 24, height: 24, fill: 'none' }}
      viewBox="0 0 24 24"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.34315 17.6569C3.21895 14.5327 3.21895 9.46734 6.34315 6.34315C9.46734 3.21895 14.5327 3.21895 17.6569 6.34315M6.34315 17.6569L17.6569 6.34315M6.34315 17.6569C7.90524 19.219 9.95262 20 12 20M17.6569 6.34315C19.732 8.41826 20.4288 11.3498 19.7473 14"
        stroke="#727F93"
        strokeWidth="1.5"
      />
      <path
        d="M14 17H20C21.1046 17 22 17.8954 22 19V19C22 20.1046 21.1046 21 20 21H19M14 17L16 15M14 17L16 19"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default UnblockIcon;

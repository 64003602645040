import { Box, Grid, Skeleton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { SORT_OPTIONS } from '../../../../enums/sort-options-enum';
import { useCurrentPageUserId } from '../../../../hooks/useCurrentPageUserId';
import { useGetUserPostsByUserIdQuery } from '../../../../store/apiSlice/post/postApi';
import NewPublicationTrigger from '../../shared-components/posts/NewPublicationTrigger';
import PostsToolbar from '../../shared-components/posts/PostsToolbar';
import ProfilePost from '../../shared-components/posts/ProfilePost';

export enum PostType {
  NONE = 'NONE',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
}

const LIMIT = 103;

const PostsTab = () => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  const [sort, setSort] = useState<SORT_OPTIONS>(SORT_OPTIONS.DESC);

  const queryParams = useMemo(
    () => ({
      id: userId as string,
      tags: '',
      page: 1,
      limit: LIMIT,
      search: '',
      sort,
    }),
    [userId, sort]
  );

  const {
    data: postsData,
    isLoading,
    // eslint-disable-next-line
    error,
    refetch: refetchPosts,
  } = useGetUserPostsByUserIdQuery(queryParams, { skip: !userId });

  const [postsCount, setPostsCount] = useState<number>(0);

  useEffect(() => {
    if (postsData) setPostsCount(postsData.total);
  }, [postsData]);

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: '16px', xl: '22px' },
          }}
        >
          <Skeleton sx={{ width: '100%', transform: 'none', height: '50px' }} />
          <Skeleton sx={{ width: '100%', transform: 'none', height: '50px' }} />
          <Skeleton
            sx={{ width: '100%', transform: 'none', height: '800px' }}
          />
        </Box>
      )}
      {!isLoading && (
        <Box className="profile-my-posts-tab">
          <Grid container direction="row">
            <Grid
              item
              flex={2}
              minWidth={0}
              rowGap={{ xs: '16px', xl: '22px' }}
              display="flex"
              flexDirection="column"
            >
              {isOwnPage && (
                <NewPublicationTrigger refetchPosts={refetchPosts} />
              )}

              <PostsToolbar
                sort={sort}
                setSort={setSort}
                postsCount={postsCount}
              />

              {!!postsData?.data.length &&
                postsData.data.map(post => (
                  <Box
                    key={post.id}
                    className="profile-posts"
                    display="flex"
                    padding="12px 22px"
                    alignItems="center"
                    sx={{
                      backgroundColor: '#FFFFFF',
                      // marginBottom: '20px',
                    }}
                  >
                    <ProfilePost post={post} refetchPosts={refetchPosts} />
                  </Box>
                ))}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default PostsTab;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { IFriend } from '../../../../interfaces/friend.interface';
import RecommendationFriendList from './RecommendationFriendList';
import RightArrowIcon from '../../../../components/Icons/RightArrowIcon';
import { Link } from 'react-router-dom';

interface IRecommendationFriend {
  suggestions: IFriend[];
}
const RecommendationFriend = (props: IRecommendationFriend) => {
  const { suggestions } = props;

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
      <Box
        component={Link}
        to={`/connections?tab=3`}
        display={'flex'}
        flexDirection={'row'}
        gap={'24px'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography variant="h3" color={'text.primary'}>
          People you may know
        </Typography>
        <RightArrowIcon sx={{ stroke: '#727F93' }} />
      </Box>

      <RecommendationFriendList suggestions={suggestions} />
    </Box>
  );
};

export default RecommendationFriend;

import { Box, Button, Stack, Typography } from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../../../components/Icons/ArrowLeftIcon';

import { IJobpost } from '../../../interfaces/jobpost.interface';
import JobPostShortInfo from './JobPostShortInfo';
import JobPostSidebarActions from './JobPostSidebarActions';
import { jobStatusHelper } from '../../../utils/helper/status-helper';
import React, { useCallback } from 'react';

export interface IMyJobPostSidebarProps {
  jobPost: IJobpost;
  refetchJobpostData?: () => void;
}

const MyJobPostSidebar: React.FC<IMyJobPostSidebarProps> = ({
  jobPost,
  refetchJobpostData,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="22px">
      <Box
        sx={{
          minWidth: 227,
          width: { xs: '100%', lg: 236, xl: 314 },
          borderRadius: '1px',
          bgcolor: 'background.white',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          p={'21px 16px'}
          alignItems="center"
        >
          <Link
            to={`/?tab=1`}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <Typography
              variant="h4"
              display="flex"
              alignItems="center"
              color={'text.primary'}
            >
              <ArrowLeftIcon />
              &nbsp;&nbsp;Go back
            </Typography>
          </Link>
          <Box
            borderRadius="20px"
            borderColor={jobStatusHelper(jobPost).color}
            border={'1px solid'}
            color={jobStatusHelper(jobPost).color}
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="6px 12px"
            width="fit-content"
          >
            <Typography variant="body4" textTransform="uppercase">
              {jobStatusHelper(jobPost).message}
            </Typography>
          </Box>
        </Stack>

        <JobPostShortInfo jobPost={jobPost} />

        <JobPostSidebarActions
          jobpostId={jobPost.id}
          refetchJobpostData={
            refetchJobpostData ? refetchJobpostData : () => {}
          }
        />
      </Box>
    </Box>
  );
};

export default MyJobPostSidebar;

import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MagnifyingGlassIcon from '../../../../../../components/Icons/MagnifyingGlassIcon';
import useDebounce from '../../../../../../hooks/useDebounce';
import CloseIcon from '../../../../../../components/Icons/CloseIcon';

interface ListSearchProps {
  closeSearch?: () => void;
}

const ListSearch = ({ closeSearch }: ListSearchProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    setSearchQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('tab')]);
  useEffect(() => {
    setSearchQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('position')]);
  useEffect(() => {
    setSearchQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('location')]);
  useEffect(() => {
    setSearchQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('industry')]);

  useEffect(() => {
    setSearchParams(prevParams => {
      prevParams.set('query', debouncedSearchQuery);
      return prevParams;
    });
  }, [debouncedSearchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchReset = useCallback(() => {
    setSearchQuery('');
    closeSearch && closeSearch();
  }, [closeSearch]);

  return (
    <TextField
      variant="outlined"
      placeholder="Search..."
      onChange={handleSearchChange}
      value={searchQuery}
      sx={{
        minWidth: {
          xs: '100%',
          lg: '470px',
        },
        flex: 1,
        paddingLeft: 0,
        fontSize: '14px',
        backgroundColor: 'transparent',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ p: 0 }}>
            <MagnifyingGlassIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ p: 0 }}>
            <IconButton
              onClick={handleSearchReset}
              sx={{
                p: 0,
                width: 24,
                height: 24,
                minWidth: 24,
                minHeight: 24,
                '& > .MuiSvgIcon-root': {
                  backgroundColor: 'unset',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ListSearch;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface BellIconProps extends SvgIconProps {
  active?: boolean;
}

export default function BellIcon({ active, ...svgProps }: BellIconProps) {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon {...svgProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M8.33301 4.16667C8.33301 3.72464 8.5086 3.30072 8.82116 2.98816C9.13372 2.67559 9.55765 2.5 9.99967 2.5C10.4417 2.5 10.8656 2.67559 11.1782 2.98816C11.4907 3.30072 11.6663 3.72464 11.6663 4.16667C12.6233 4.61919 13.4392 5.32361 14.0264 6.20442C14.6136 7.08523 14.95 8.10923 14.9997 9.16667V11.6667C15.0624 12.1848 15.2459 12.6809 15.5354 13.1151C15.8248 13.5493 16.2122 13.9095 16.6663 14.1667H3.33301C3.78712 13.9095 4.17452 13.5493 4.464 13.1151C4.75348 12.6809 4.93696 12.1848 4.99967 11.6667V9.16667C5.04931 8.10923 5.38576 7.08523 5.97297 6.20442C6.56017 5.32361 7.376 4.61919 8.33301 4.16667Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
        <path
          d="M7.5 14.1666V15C7.5 15.663 7.76339 16.2989 8.23223 16.7677C8.70107 17.2366 9.33696 17.5 10 17.5C10.663 17.5 11.2989 17.2366 11.7678 16.7677C12.2366 16.2989 12.5 15.663 12.5 15V14.1666"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

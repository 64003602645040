import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}
function SmallRightIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
      className={cn({ [className!]: className !== undefined })}
    >
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M4.182 9h10.227M11.75 12l3-3M11.75 6l3 3"
      ></path>
    </svg>
  );
}

export default SmallRightIcon;

import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import {
  useGetPhotoAlbumByIdQuery,
  useGetVideoAlbumByIdQuery,
} from '../../../store/apiSlice/profileMediaApi';
import BaseModal from '../../MUIComponents/BaseModal';
import CustomButton from '../../MUIComponents/CustomButton';
import Loader from '../../MUIComponents/Loader';

const LIMIT = 12;

interface ChooseCoverImageModalProps<T> {
  albumId: string | null;
  selectNewCoverImage: (file: T) => void;
  title: string;
  mediaType: 'photo' | 'video';
  isOpen?: boolean;
  onClose: () => void;
}

const ChooseCoverImageModal = <
  T extends { id: string; fileUrl?: string; videoThumbnail?: string },
>({
  albumId,
  selectNewCoverImage,
  title,
  mediaType,
  onClose,
  isOpen = false,
}: ChooseCoverImageModalProps<T>) => {
  const [mediaItems, setMediaItems] = useState<T[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const queryFunction =
    mediaType === 'photo'
      ? useGetPhotoAlbumByIdQuery
      : useGetVideoAlbumByIdQuery;
  const {
    data: albumData,
    isLoading,
    isFetching,
  } = queryFunction(
    {
      id: albumId ?? '',
      page,
      limit: LIMIT,
    },
    { skip: !albumId }
  );

  useEffect(() => {
    if (albumData) {
      const newMediaItems = (albumData.data || []) as T[];
      const total = albumData.total || 0;

      const count = total - mediaItems.length;

      if (count > 0) {
        const newItemsToAdd = newMediaItems.slice(-count);

        setMediaItems(prevItems =>
          page === 1 ? newMediaItems : [...prevItems, ...newItemsToAdd]
        );

        const hasNext = total !== 0 && page * LIMIT < total;
        setHasMore(hasNext);
      }
    }
  }, [albumData, page, mediaItems.length]);

  const handleNextPage = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleSelectNewCoverImg = (file: T) => () => {
    selectNewCoverImage(file);
    onClose();
  };

  return (
    <BaseModal
      header={{ text: title }}
      isOpen={isOpen}
      toggle={onClose}
      disableDefaultFooter
    >
      <Box sx={{ textAlign: 'center' }} mt="36px">
        {isLoading && <Loader color="primary" />}

        {!isLoading && (
          <Grid container spacing={2}>
            {mediaItems.map(file => (
              <Grid item xs={6} sm={4} lg={3} xl={2} key={file.id}>
                <Box
                  position="relative"
                  width="100%"
                  bgcolor="secondary2.main"
                  sx={{
                    cursor: 'pointer',
                    paddingBottom: '100%',
                  }}
                  onClick={handleSelectNewCoverImg(file)}
                >
                  <img
                    src={
                      mediaType === 'photo'
                        ? file.fileUrl || ''
                        : `data:image/png;base64,${file.videoThumbnail || ''}`
                    }
                    alt="Preview"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      maxWidth: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}

        {isFetching && <Loader boxSx={{ mt: 8 }} size={30} color="primary" />}

        {hasMore && (
          <Box>
            <CustomButton
              sx={{
                height: 56,
                width: '100%',
                mt: { xs: 3, md: 6 },
              }}
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={handleNextPage}
              disabled={isFetching}
            >
              show more
            </CustomButton>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default ChooseCoverImageModal;

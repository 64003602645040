import { Box } from '@mui/material';
import ModeSwitch from './ModeSwitch';
import { useThemeContext } from '../../theme/ThemeContextProvider';

const Logo = () => {
  const { mode } = useThemeContext();

  return (
    <Box display={'flex'} mr={'1rem'}>
      <svg
        width="202"
        height="27"
        viewBox="0 0 202 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M66.3198 5.46027H78.5535V8.4853H69.6607V13.4177H77.6424V16.3941H69.6607V23.6834H66.3076V5.46027H66.3198Z"
          fill="#142237"
        />
        <path
          d="M80.8491 7.7807H88.3327C91.5764 7.7807 93.6781 9.62731 93.6781 12.6766C93.6781 15.726 91.7951 17.5361 88.3327 17.5361H87.1421L90.5559 18.1679L94.5771 23.6712H90.6774L86.3281 17.5361H84.1657V23.6712H80.8369V7.7807H80.8491ZM88.199 14.5597C89.584 14.5597 90.3372 13.77 90.3372 12.6888C90.3372 11.6076 89.584 10.7936 88.199 10.7936H84.1778V14.5597H88.199Z"
          fill="#142237"
        />
        <path
          d="M96.6426 7.7807H107.771V10.7814H99.9835V14.1709H107.188V17.1352H99.9835V20.6584H107.819V23.6591H96.6547V7.7807H96.6426Z"
          fill="#142237"
        />
        <path
          d="M110.518 7.7807H121.646V10.7814H113.858V14.1709H121.063V17.1352H113.858V20.6584H121.694V23.6591H110.53V7.7807H110.518Z"
          fill="#142237"
        />
        <path
          d="M130.15 5.46027H133.722L140.914 23.6712H137.269L135.763 19.6014H128.06L126.53 23.6712H122.909L130.15 5.46027ZM134.633 16.6128L131.924 9.32356L129.19 16.6128H134.633Z"
          fill="#142237"
        />
        <path
          d="M140.901 15.6409C140.901 10.587 144.473 7.36761 149.235 7.36761C152.333 7.36761 155.213 8.87406 156.512 11.6561L153.791 13.4663C153.184 11.6561 151.471 10.3684 149.187 10.3684C146.174 10.3684 144.254 12.4701 144.254 15.568C144.254 18.8603 146.137 20.9863 149.041 20.9863C151.337 20.9863 152.807 19.7229 153.305 17.7669H147.826L150.147 14.8026H156.598V23.6712H153.33V21.9339C152.467 23.003 151.033 23.9992 148.701 23.9992C144.157 23.9992 140.938 20.5368 140.938 15.6409H140.901Z"
          fill="#142237"
        />
        <path
          d="M159.598 7.7807H170.726V10.7814H162.939V14.1709H170.143V17.1352H162.939V20.6584H170.775V23.6591H159.61V7.7807H159.598Z"
          fill="#142237"
        />
        <path
          d="M173.472 7.7807H178.1L183.47 19.5893V7.7807H186.69V23.6712H182.061L176.691 11.9599V23.6712H173.472V7.7807Z"
          fill="#142237"
        />
        <path
          d="M193.614 10.7814H188.742V7.7807H201.79V10.7814H196.943V23.6712H193.614V10.7814Z"
          fill="#142237"
        />
        <path
          d="M39.7628 13.7336V0.467163H23.1919L16.5587 7.10037L16.5709 8.7526H6.62106L0 15.3858V27H49.7126L56.3337 20.3668V13.7336H39.7628Z"
          fill="#B50D0D"
        />
        <path
          d="M33.1418 20.3667V7.10028H16.5587V15.3857H0V26.9999H16.5587H16.5709H33.1418H49.7126V20.3667H33.1418Z"
          fill="url(#paint0_linear_1413_3039)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1413_3039"
            x1="36.4584"
            y1="14.3045"
            x2="4.03338"
            y2="33.5481"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.15" stopColor="#E92726" />
            <stop offset="0.24" stopColor="#E22322" />
            <stop offset="0.73" stopColor="#C11312" />
            <stop offset="1" stopColor="#B50D0D" />
          </linearGradient>
        </defs>
      </svg>

      {/* TODO: Possibly add in future, currently only light theme works */}
      {/* <ModeSwitch /> */}
    </Box>
  );
};

export default Logo;

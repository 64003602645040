import { Divider, Typography } from '@mui/material';

const SecurityTab = () => {
  return (
    <>
      <Typography
        component="h2"
        variant="h2"
        mb={'24px'}
        color={'text.primary'}
      >
        Log in & Security
      </Typography>
      <Divider sx={{ bgcolor: 'background.darker' }} />
      🔐
    </>
  );
};

export default SecurityTab;

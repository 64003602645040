import { Box, Button, Typography } from '@mui/material';
import FiltersIcon from '../../../../components/Icons/FiltersIcon';
import SortIcon from '../../../../components/Icons/SortIcon';
import { colors } from '../../../../theme/theme';
import { SORT_OPTIONS } from '../../../../enums/sort-options-enum';

interface IPostsToolbarProps {
  postsCount: number;
  sort: SORT_OPTIONS;
  setSort: React.Dispatch<React.SetStateAction<SORT_OPTIONS>>;
}

const PostsToolbar = ({ sort, setSort, postsCount }: IPostsToolbarProps) => {
  const sortText: { [key in SORT_OPTIONS]: string } = {
    [SORT_OPTIONS.DESC]: 'Latest',
    [SORT_OPTIONS.ASC]: 'Oldest',
  };

  return (
    <Box
      className="profile-posts-box"
      display="flex"
      padding="12px 22px"
      alignItems="center"
      sx={{
        backgroundColor: '#FFFFFF',
        height: '50px',
        color: '#142237',
      }}
    >
      <Box className="profile-posts-title" flex="1">
        <Typography variant="h6">
          {postsCount} {postsCount >= 2 ? 'Posts' : 'Post'}
        </Typography>
      </Box>
      <Box className="profile-posts-actions" display="flex" gap="30px">
        <Button
          onClick={() =>
            setSort(
              sort === SORT_OPTIONS.ASC ? SORT_OPTIONS.DESC : SORT_OPTIONS.ASC
            )
          }
        >
          <Typography
            variant="subtitle2"
            display="flex"
            alignItems="center"
            columnGap="6px"
            textTransform="capitalize"
            color={colors.dark60}
          >
            <SortIcon />
            {sortText[sort]}
          </Typography>
        </Button>
        <Button>
          <Typography
            variant="subtitle2"
            display="flex"
            alignItems="center"
            columnGap="6px"
            textTransform="capitalize"
            color={colors.dark60}
          >
            <FiltersIcon />
            Filter
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default PostsToolbar;

import React from 'react';

function LeftArrowIcon({ color = '#142237' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="11"
      fill="none"
      viewBox="0 0 17 11"
    >
      <g stroke={color} strokeLinecap="square" strokeWidth="1.5">
        <path d="M16 5.485H2.364"></path>
        <path d="M5.909 9.485l-4-4"></path>
        <path d="M5.909 1.485l-4 4"></path>
      </g>
    </svg>
  );
}

export default LeftArrowIcon;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  IEducation,
  IEducationRequest,
  IEducationResponce,
} from '../../interfaces/education.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const userEducationApi = createApi({
  reducerPath: 'userEducationApi',
  baseQuery: baseQueryWithInterceptor(config.BASE_USER_MICROSERVICE_API_URL),
  tagTypes: ['userEducation'],

  endpoints: builder => ({
    getMyEducation: builder.query<IEducation[], void>({
      query: () => '/user-education',
      providesTags: ['userEducation'],
      transformResponse: (response: IEducationResponce[]) => {
        return response.map(
          ({ id, university, specialty, startYear, endYear }) => ({
            educationId: id,
            university,
            specialty,
            startYear,
            endYear,
          })
        );
      },
    }),
    createUpdateEducation: builder.mutation<
      IEducationResponce[],
      IEducationRequest
    >({
      query: (data: IEducationRequest) => {
        const tranformedData = data?.educations?.map(
          ({ id, university, specialty, startYear, endYear }) => ({
            id,
            university,
            specialty,
            startYear,
            endYear,
          })
        );

        return {
          url: `/user-education`,
          method: 'PUT',
          body: { educations: tranformedData },
        };
      },
      invalidatesTags: ['userEducation'],
    }),
    deleteEducation: builder.mutation<IEducationResponce, string>({
      query(id) {
        return {
          url: `/user-education/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['userEducation'],
    }),
  }),
});

export const {
  useGetMyEducationQuery,
  useCreateUpdateEducationMutation,
  useDeleteEducationMutation,
} = userEducationApi;

export default userEducationApi;

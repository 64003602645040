import { Box, Stack, Typography } from '@mui/material';
import Label from '../../MUIComponents/Label';
import { LabelTypeEnum } from '../../../enums/label-enum';
import { IJobPostApplySidebarProps } from './JobPostApplySidebar';
import { useGetIndustriesQuery } from '../../../store/apiSlice/jobpost/jobpostApi';
import CheckIcon from '@mui/icons-material/Check';

const JobPostApplyEmployerInfo: React.FC<IJobPostApplySidebarProps> = ({
  company,
}) => {
  const { data: sportsIndustries } = useGetIndustriesQuery();

  const renderCompanyIndustries = (industryIds: string[]) => {
    if (
      sportsIndustries &&
      sportsIndustries?.length > 0 &&
      industryIds?.length > 0
    ) {
      const companyIndustriesWithId = sportsIndustries.filter(industry =>
        industryIds.includes(industry.id)
      );

      return companyIndustriesWithId.map((industry, idx) => (
        <span key={industry.id}>
          {industry.industry}
          {idx !== companyIndustriesWithId?.length - 1 && ', '}
        </span>
      ));
    }
    return '';
  };
  return (
    <Box
      sx={{
        width: '100%',
        p: '22px 16px',
      }}
    >
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={'10px'}
        sx={{
          width: '100%',
          borderBottomWidth: '1px',
          borderBottomColor: 'background.dark',
          borderBottomStyle: 'solid',
          pb: '28px',
        }}
      >
        {company?.avatar?.fileUrl && (
          <img
            src={`${company?.avatar?.fileUrl}`}
            width={100}
            height={70}
            alt={'Company logo'}
          />
        )}

        <Typography textTransform={'uppercase'} variant="badge2">
          {company.companyInfo?.companyName}
        </Typography>
      </Stack>
      <Stack
        mt={'28px'}
        mb={'28px'}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant="h3" color={'text.primary'} textAlign={'left'}>
          About employer
        </Typography>
        <Label
          sx={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            fontSize: '8px',
          }}
          labelType={
            company.verified
              ? LabelTypeEnum.VERIFIED
              : LabelTypeEnum.UNDER_VERIFICATION
          }
        >
          {company.verified && <CheckIcon sx={{ width: 11, height: 11 }} />}
          {company.verified
            ? LabelTypeEnum.VERIFIED
            : LabelTypeEnum.UNDER_VERIFICATION.replace(/_/g, ' ')}
        </Label>
      </Stack>

      <Stack component={'ul'} spacing={'22px'}>
        <Box component={'li'}>
          <Typography component={'p'} fontSize={'12px'} fontWeight={700}>
            Location:
          </Typography>
          <Typography variant="body3">
            {company.companyInfo?.country}, {company.companyInfo?.city}
          </Typography>
        </Box>
        <Box component={'li'}>
          <Typography component={'p'} fontSize={'12px'} fontWeight={700}>
            Industry:
          </Typography>
          <Typography variant="body3">
            {renderCompanyIndustries(company.companyInfo?.industryIds)}
          </Typography>
        </Box>
        <Box component={'li'}>
          <Typography component={'p'} fontSize={'12px'} fontWeight={700}>
            1 job posted:
          </Typography>
          <Typography variant="body3">0% hire rate, 1 open job</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default JobPostApplyEmployerInfo;

const MessageLikeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5001 12.572L12.0001 20L4.50006 12.572C4.00536 12.0906 3.6157 11.512 3.3556 10.8726C3.09551 10.2333 2.97062 9.54694 2.98879 8.85693C3.00697 8.16691 3.16782 7.48813 3.46121 6.86333C3.75461 6.23853 4.17419 5.68125 4.69354 5.22657C5.21289 4.7719 5.82076 4.42968 6.47887 4.22147C7.13697 4.01327 7.83106 3.94358 8.51743 4.0168C9.20379 4.09001 9.86756 4.30455 10.4669 4.6469C11.0663 4.98925 11.5883 5.45199 12.0001 6.00599C12.4136 5.45602 12.9362 4.99731 13.5352 4.6586C14.1341 4.31988 14.7966 4.10844 15.481 4.03751C16.1654 3.96658 16.8571 4.03769 17.5128 4.24639C18.1685 4.45508 18.7741 4.79687 19.2916 5.25036C19.8091 5.70385 20.2275 6.25928 20.5205 6.88189C20.8135 7.50449 20.9748 8.18088 20.9944 8.8687C21.0139 9.55653 20.8913 10.241 20.6342 10.8792C20.3771 11.5175 19.991 12.0958 19.5001 12.578"
        stroke="#434E5F"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default MessageLikeIcon;

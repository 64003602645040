import { SvgIcon, SvgIconProps } from '@mui/material';

const TrashIcon = ({ sx, ...restProps }: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 24, height: 24, fill: 'none', ...sx }}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8208 4.73468C10.7545 4.73468 10.6909 4.76102 10.644 4.8079C10.5971 4.85479 10.5708 4.91838 10.5708 4.98468V7.23468H15.0708V4.98468C15.0708 4.91838 15.0445 4.85479 14.9976 4.8079C14.9507 4.76102 14.8871 4.73468 14.8208 4.73468H10.8208ZM16.5708 7.23468V4.98468C16.5708 4.52055 16.3864 4.07543 16.0582 3.74724C15.73 3.41905 15.2849 3.23468 14.8208 3.23468H10.8208C10.3567 3.23468 9.91155 3.41905 9.58336 3.74724C9.25518 4.07543 9.0708 4.52055 9.0708 4.98468V7.23468H5.82957C5.82429 7.23462 5.819 7.23462 5.81371 7.23468H4.8208C4.40659 7.23468 4.0708 7.57047 4.0708 7.98468C4.0708 8.39889 4.40659 8.73468 4.8208 8.73468H5.1307L6.07101 20.0183C6.07978 20.7355 6.36847 21.4214 6.87626 21.9292C7.39198 22.4449 8.09145 22.7347 8.8208 22.7347H16.8208C17.5501 22.7347 18.2496 22.4449 18.7653 21.9292C19.2731 21.4214 19.5618 20.7355 19.5706 20.0183L20.5109 8.73468H20.8208C21.235 8.73468 21.5708 8.39889 21.5708 7.98468C21.5708 7.57047 21.235 7.23468 20.8208 7.23468H19.8279C19.8226 7.23462 19.8173 7.23462 19.812 7.23468H16.5708ZM6.6359 8.73468L7.56821 19.9224C7.56994 19.9431 7.5708 19.9639 7.5708 19.9847C7.5708 20.3162 7.7025 20.6341 7.93692 20.8686C8.17134 21.103 8.48928 21.2347 8.8208 21.2347H16.8208C17.1523 21.2347 17.4703 21.103 17.7047 20.8686C17.9391 20.6341 18.0708 20.3162 18.0708 19.9847C18.0708 19.9639 18.0717 19.9431 18.0734 19.9224L19.0057 8.73468H6.6359ZM10.2905 13.515C9.99758 13.2221 9.99758 12.7472 10.2905 12.4544C10.5834 12.1615 11.0582 12.1615 11.3511 12.4544L12.8208 13.924L14.2905 12.4544C14.5834 12.1615 15.0582 12.1615 15.3511 12.4544C15.644 12.7472 15.644 13.2221 15.3511 13.515L13.8815 14.9847L15.3511 16.4543C15.644 16.7472 15.644 17.2221 15.3511 17.515C15.0582 17.8079 14.5834 17.8079 14.2905 17.515L12.8208 16.0453L11.3511 17.515C11.0582 17.8079 10.5834 17.8079 10.2905 17.515C9.99758 17.2221 9.99758 16.7472 10.2905 16.4543L11.7601 14.9847L10.2905 13.515Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default TrashIcon;

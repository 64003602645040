import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetAllMyBlockedFriendsQuery } from '../../../store/apiSlice/friendApi';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { IFriend } from '../../../interfaces/friend.interface';
import BlockedUsersList from './BlockedUsersList';

const LIMIT = 10;

const BlockedUsersTab = () => {
  const [page, setPage] = useState<number>(1);
  const [blockedUsers, setBlockedUsers] = useState<IFriend[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastConnectionElementRef = useCallback(
    (node: HTMLElement) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (
          entries[0].isIntersecting &&
          hasMore &&
          (!isLoading || !blockedUsersLoading)
        ) {
          setIsLoading(true);
          setPage(prevPage => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore]
  );

  const queryParams = {
    page,
    limit: LIMIT,
  };

  const {
    data: blockedUsersData,
    error: blockedUsersError,
    isLoading: blockedUsersLoading,
  } = useGetAllMyBlockedFriendsQuery(queryParams, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    let newBlockedUsers: IFriend[] = [];
    let totalPages = 0;

    newBlockedUsers = blockedUsersData?.blockedFriends || [];
    totalPages = blockedUsersData?.pagination.totalPages || 0;

    if (page === 1) {
      setBlockedUsers(newBlockedUsers);
    } else {
      setBlockedUsers(prevBlockedUsers => [
        ...prevBlockedUsers,
        ...newBlockedUsers,
      ]);
    }

    const hasNext = totalPages !== 0 && page !== totalPages;

    setIsLoading(false);
    setHasMore(hasNext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockedUsersData]);

  return (
    <>
      <Typography
        component="h2"
        variant="h2"
        mb={'24px'}
        color={'text.primary'}
      >
        Blocked users
      </Typography>
      <Divider sx={{ bgcolor: 'background.darker' }} />

      <BlockedUsersList
        blockedUsers={blockedUsers}
        setBlockedUsers={setBlockedUsers}
        setPage={setPage}
      />
      {blockedUsers.length > 0 &&
        hasMore &&
        (!isLoading || !blockedUsersLoading) && (
          <Box ref={lastConnectionElementRef}></Box>
        )}
      {(isLoading || blockedUsersLoading) && (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {blockedUsersError && (
        <Typography color="error">Failed to load connections.</Typography>
      )}
    </>
  );
};

export default BlockedUsersTab;

const PhotoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 3H7V17H21V3Z"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M16.2857 19.2857V21H3V8.14288H4.28571"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M17 7H17.01"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M7.28613 12.4286L10.6443 9.35602C10.7567 9.24354 10.8901 9.15431 11.037 9.09344C11.1838 9.03256 11.3413 9.00122 11.5003 9.00122C11.6593 9.00122 11.8167 9.03256 11.9636 9.09344C12.1105 9.15431 12.2439 9.24354 12.3563 9.35602L16.0003 13"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M15 12L16.644 10.356C16.7564 10.2435 16.8898 10.1543 17.0367 10.0934C17.1836 10.0326 17.341 10.0012 17.5 10.0012C17.659 10.0012 17.8164 10.0326 17.9633 10.0934C18.1102 10.1543 18.2436 10.2435 18.356 10.356L20.5714 12.4286"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default PhotoIcon;

import React, { FC } from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface MobileChatModalButtonProps extends ButtonProps {
  label: string;
}

const MobileChatModalButton: FC<MobileChatModalButtonProps> = ({
  label,
  ...props
}) => {
  const { theme } = useThemeContext();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: '16px',
        borderTop: `1px solid ${theme.palette.background.lightDark}`,
      }}
    >
      <Button
        sx={{
          minWidth: '100%',
          padding: '19px 16px',
          color: theme.palette.text.primary,
          fontSize: '12px',
          fontWeight: 800,
          letterSpacing: '0.48px',
          backgroundColor: 'transparent',
          borderRadius: '1px',
          border: `1px solid ${theme.palette.background.dark}`,
        }}
        {...props}
      >
        {label}
      </Button>
    </Box>
  );
};

export default MobileChatModalButton;

import { List, Typography } from '@mui/material';
import SportBackgroundListItem from './SportBackgroundListItem';
import { ISportBackground } from '../../../../../interfaces/sport.interface';

interface ISportBackgroundListProps {
  sportBackgrounds: ISportBackground[];
  isLoading: boolean;
  isError: boolean;
  error?: string;
}

const SportBackgroundList = (props: ISportBackgroundListProps) => {
  const {
    sportBackgrounds,
    isLoading,
    isError,
    error,
  } = props;

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return (
      <Typography>Error loading sport background: {error || 'Unknown error'}</Typography>
    );
  }

  if (sportBackgrounds.length === 0) {
    return <Typography>No sports available</Typography>;
  }

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '16px', md: '22px' },
      }}
    >
      {sportBackgrounds.map(sportBackground => (
        <SportBackgroundListItem
          key={sportBackground.id}
          sportBackground={sportBackground}
        />
      ))}
    </List>
  );
};

export default SportBackgroundList;

import { Box, Typography } from '@mui/material';
import useDeleteChat from '../../../../../hooks/chat/useDeleteChat';
import DeleteChatIcon from '../../../../../components/Icons/DeleteChatIcon';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';

interface IDeleteChatProps {
  onClose: () => void;
}

const DeleteChat = ({ onClose }: IDeleteChatProps) => {
  const { handleDeleteChat, isChatDeletting } = useDeleteChat();

  return (
    <>
      <DeleteChatIcon />
      <Typography typography={'h2'}>
        Are you sure you want to delete this chat?
      </Typography>
      <Typography typography={'body2'}>
        {
          'Are you sure you want to delete this chat? This action cannot be undone.'
        }
      </Typography>
      <Box display={'flex'} gap={'1rem'}>
        <CustomButton
          sx={{ color: 'text.primary' }}
          buttonWidth={'210px'}
          variantType={ButtonTypeEnum.TERTIARY}
          onClick={onClose}
          disabled={isChatDeletting}
        >
          cancel
        </CustomButton>
        <CustomButton
          buttonWidth={'210px'}
          variantType={ButtonTypeEnum.PRIMARY}
          onClick={() => {
            onClose();
            handleDeleteChat().catch(console.log);
          }}
          disabled={isChatDeletting}
        >
          delete
        </CustomButton>
      </Box>
    </>
  );
};

export default DeleteChat;

import { Stack } from '@mui/material';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';

// const LIMIT = 10;

interface IJobpostFilterActionButtonsProps {
  handleFilterReset: () => void;
  handleFilterApply: () => void;
}

const CandidatesFilterActionButtons: React.FC<
  IJobpostFilterActionButtonsProps
> = ({ handleFilterReset, handleFilterApply }) => {
  return (
    <Stack
      direction={'column'}
      spacing={1}
      sx={{
        width: '100%',
        p: '22px 16px',
        borderTopWidth: '1px',
        borderTopColor: 'background.dark',
        borderTopStyle: 'solid',
      }}
    >
      <CustomButton
        buttonWidth="100%"
        variant="contained"
        variantType={ButtonTypeEnum.PRIMARY}
        fullWidth
        onClick={handleFilterApply}
        type="button"
      >
        Apply filters
      </CustomButton>
      <CustomButton
        buttonWidth={'100%'}
        variantType={ButtonTypeEnum.SECONDARY}
        fullWidth
        onClick={handleFilterReset}
      >
        Reset filters
      </CustomButton>
    </Stack>
  );
};

export default CandidatesFilterActionButtons;

import { Line } from 'react-chartjs-2';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
  });
};

const AnalyticsChart = ({ chartData }: any) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const chartRef = useRef<ChartJSOrUndefined<'line'> | null>(null);

  const formattedChartData = {
    ...chartData,
    labels: chartData.labels.map((label: string) => formatDate(label)),
  };

  const processData = (dataset: any) => {
    const processedData = dataset.data.map(
      (value: number, index: number, arr: number[]) => {
        if (value === 0 && arr[index + 1] === 0) {
          return null;
        }
        return value;
      }
    );

    return { ...dataset, data: processedData };
  };

  const filteredData = {
    ...formattedChartData,
    datasets: formattedChartData.datasets
      .filter((dataset: any) =>
        dataset.data.some((value: number) => value !== 0)
      )
      .map((dataset: any, index: number) => {
        const processedDataset = processData(dataset);

        let backgroundColor: string | CanvasGradient = dataset.backgroundColor;
        if (index === 0 && dataset.label === 'Candidates') {
          const chart = chartRef.current;
          if (chart?.ctx) {
            const gradient = chart.ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, 'rgba(70, 130, 180, 0.2)');
            gradient.addColorStop(1, 'rgba(70, 130, 180, 0)');
            backgroundColor = gradient;
          }
          return {
            ...processedDataset,
            backgroundColor,
            borderColor: dataset.borderColor,
            pointBackgroundColor: dataset.borderColor,
            pointBorderColor: dataset.borderColor,
            fill: true,
            order: 1,
          };
        } else {
          return {
            ...processedDataset,
            backgroundColor: dataset.backgroundColor,
            borderColor: dataset.borderColor,
            pointBackgroundColor: dataset.borderColor,
            pointBorderColor: dataset.borderColor,
            fill: false,
            order: 2,
          };
        }
      }),
  };

  const maxYValue =
    filteredData.datasets.length &&
    filteredData.datasets.some((dataset: any) =>
      dataset.data.some((value: number | null) => value !== null)
    )
      ? Math.max(
          ...filteredData.datasets.flatMap((dataset: any) => dataset.data)
        ) + 10
      : 25;

  return (
    <div
      className="chart-container"
      style={{
        width: '100%',
        minHeight: mobileView ? '570px' : 'auto',
        height: '100%',
        padding: isSmallScreen ? '22px 16px' : '22px 36px',
      }}
    >
      <Line
        ref={chartRef}
        height={mobileView ? '570px' : 'auto'}
        data={filteredData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          spanGaps: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: '#142237',
              titleColor: '#FFFFFF',
              bodyColor: '#FFFFFF',
              borderColor: '#E0E0E0',
              borderWidth: 1,
              callbacks: {
                label: function (context) {
                  const value = context.raw;
                  if (value === 0) {
                    return '';
                  }
                  return context.dataset.label + ': ' + value;
                },
              },
              filter: function (tooltipItem) {
                return tooltipItem.raw !== 0;
              },
            },
          },
          elements: {
            point: {
              radius: 0,
              hoverRadius: 6,
            },
            line: {
              borderWidth: 1,
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
              },
              grid: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 5
              }
            },
            y: {
              display: true,
              grid: {
                display: true,
                color: '#E0E0E0',
                lineWidth: 0.5,
              },
              min: 0,
              max: maxYValue + 1,
              ticks: {
                maxTicksLimit: 6,
                stepSize: 5,
                callback: value => (value === 0 ? '' : value),
              },
              border: {
                display: false,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default AnalyticsChart;

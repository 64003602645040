import { Box, useMediaQuery } from '@mui/material';
import ProfileSidebarProgress from '../../../../components/Profile/ProfileSidebar/ProfileSidebarProgress';
import ConnectionsSidebarMenu from './ConnectionsSidebarMenu';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

const ConnectionsSidebar = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="22px"
      marginTop={!matches ? '-20px' : 0}
    >
      {matches && <ConnectionsSidebarMenu />}
      <ProfileSidebarProgress />
    </Box>
  );
};

export default ConnectionsSidebar;

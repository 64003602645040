import { SvgIcon, SvgIconProps } from '@mui/material';

const LocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 24, height: 24, fill: 'none' }}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M9 11C9 11.7956 9.31607 12.5587 9.87868 13.1213C10.4413 13.6839 11.2044 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8C11.2044 8 10.4413 8.31607 9.87868 8.87868C9.31607 9.44129 9 10.2044 9 11Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.4285L17.657 16.657C18.7758 15.5382 19.5377 14.1127 19.8463 12.5609C20.155 11.009 19.9965 9.40051 19.391 7.93873C18.7855 6.47694 17.7601 5.22754 16.4445 4.3485C15.1289 3.46947 13.5822 3.00029 12 3.00029C10.4178 3.00029 8.87107 3.46947 7.55549 4.3485C6.2399 5.22754 5.21452 6.47694 4.60901 7.93873C4.00349 9.40051 3.84504 11.009 4.15369 12.5609C4.46234 14.1127 5.22423 15.5382 6.343 16.657L12 21.4285Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default LocationIcon;

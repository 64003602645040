import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import TextField from '../../../../components/MUIComponents/TextField';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { CanceledReasonEnum } from '../../../../enums/cancel-reason-type-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { TReason } from '../../../../interfaces/jobpost.interface';
import { useCancelJobpostApplicationMutation } from '../../../../store/apiSlice/jobpost/jobUserApplyApi';
import createArrayFromEnum from '../../../../utils/createArrayFromEnum';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { CancelJobpostApplySchema } from '../../../../validation/cancel-jobpost-apply-validation';
import JobPostFilterSingleSelect from '../../../JobPostsPage/components/JobPostsSidebar/JobPostsFilters/JobPostFilterSingleSelect';

interface CancelJobpostApplyFormInputs {
  canceledNote?: string | undefined;
  canceledReason?: undefined | CanceledReasonEnum;
}
interface CancelNoteInput {
  canceledNote?: string | undefined;
}

interface CancelJobModalProps {
  isOpen?: boolean;
  onClose: () => void;
  jobpostId: string;
  handleCancelJobpostApplyFinished: () => void;
}

const CancelJobModal = ({
  onClose,
  isOpen = false,
  jobpostId,
  handleCancelJobpostApplyFinished,
}: CancelJobModalProps) => {
  const isScreenSizeBelow1141 = useMediaQuery('(max-width:1141px)');
  const reasonsArray: TReason[] = createArrayFromEnum(
    CanceledReasonEnum,
    'reason'
  );

  const [reasonState, setReasonState] = useState<string>('');

  const reasonRef = useRef<string>('');

  useEffect(() => {
    reasonRef.current = reasonState;
  }, [reasonState]);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CancelJobpostApplyFormInputs>({
    resolver: yupResolver(CancelJobpostApplySchema),
  });

  const [cancelJobpostApply, { isLoading: isCanceling }] =
    useCancelJobpostApplicationMutation();

  const handleCancelJobpostApply = async (
    data: CancelJobpostApplyFormInputs,
    jobpostId: string
  ) => {
    try {
      await cancelJobpostApply({ data, jobpostId }).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Cancel successful');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      onClose();
      handleCancelJobpostApplyFinished();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Cancel failed: ', error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = async (data: CancelNoteInput) => {
    try {
      if (!reasonState) {
        setSnackbarOpen(true);
        setSnackbarMessage('Please select a reason');
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        return;
      }

      const cancelData = {
        canceledNote: data?.canceledNote,
        canceledReason: reasonState as CanceledReasonEnum,
      };
      await handleCancelJobpostApply(cancelData, jobpostId);
    } catch (error) {
      console.error('Cancel apply failed:', error);
    }
  };

  const modalTitle = useMemo(() => {
    return (
      <Typography
        variant="h2"
        sx={{
          textAlign: isScreenSizeBelow1141 ? 'start' : 'center',
        }}
      >
        Cancel application
      </Typography>
    );
  }, []);

  const modalFooter = useMemo(() => {
    return (
      <Box mt={3}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={{ xs: '10px', lg: '0px' }}
          width={'100%'}
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isCanceling}
            >
              Confirm
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    );
  }, [handleSubmit, isCanceling, onClose, onSubmit]);

  return (
    <BaseModal
      modalSx={{ borderColor: 'transparent !important' }}
      header={{ component: modalTitle }}
      disableEscapeButton
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
    >
      <Stack spacing="36px">
        <Box className="add-media-container" position="relative">
          <JobPostFilterSingleSelect
            label={'reason'}
            selectItems={reasonsArray}
            placeholder={'Choose reason'}
            filterState={reasonState}
            setFilterState={setReasonState}
          />
        </Box>
        <Box>
          <Typography
            component={'p'}
            fontSize={'12px'}
            fontWeight={700}
            mb={'16px'}
          >
            Add notes:
          </Typography>
          <TextField
            placeholder="Tell us why you are cancelling your application"
            height={160}
            register={register('canceledNote')}
            error={!!errors.canceledNote}
            helperText={errors.canceledNote ? errors.canceledNote.message : ''}
          />
        </Box>
      </Stack>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default CancelJobModal;

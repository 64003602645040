import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LikeIcon from '../../../../components/Icons/LikeIcon';
import RepostIcon from '../../../../components/Icons/RepostIcon';
import SendIcon from '../../../../components/Icons/SendIcon';
import QuiltedImageList from '../../../../components/MUIComponents/QuiltedImageList';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { useModalManager } from '../../../../hooks/useModalManager';
import { ICommentResponse } from '../../../../interfaces/comment.interface';
import { IPost } from '../../../../interfaces/post.interface';
import {
  useCreateCommentMutation,
  useGetPostCommentsQuery,
} from '../../../../store/apiSlice/post/commentApi';
import {
  useDislikePostMutation,
  useLikePostMutation,
} from '../../../../store/apiSlice/post/postReactionApi';
import { useGetUserInfoQuery } from '../../../../store/apiSlice/userApi';
import { formatDate } from '../../../../utils/date-time-formats.ts/formatDate';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { removeHashtagWords } from '../../../../utils/helper/removeHashtagWords';
import RepostModal from '../modals/RepostModal';
import CreatePostModal from '../modals/CreatePostModal';
import PostCommentsModal from '../modals/PostCommentsModal';
import { PostType } from './NewPublicationTrigger';
import ProfilePostMoreMenu from './ProfilePostMoreMenu';
import RepostedPost from './RepostedPost';
import { selectAuthData } from '../../../../store/selectors/authSelector';

type PostButtonsProps = React.PropsWithChildren<ButtonProps>;

export const PostButtons = ({ children, ...props }: PostButtonsProps) => {
  return (
    <Button
      variant="text"
      fullWidth
      sx={{
        borderRadius: '4px',
        backgroundColor: '#F6F7F8',
        color: 'text.secondary',
        fontSize: '10px',
        columnGap: '12px',
        padding: '12px 16px',
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

interface IProfilePostProps {
  post: IPost;
  refetchPosts: () => void;
}

export enum MyPostModals {
  CREATE_POST_MODAL = 'CREATE_POST_MODAL',
  POST_WITH_COMMENTS_MODAL = 'POST_WITH_COMMENTS_MODAL',
  REPOST_MODAL = 'REPOST_MODAL',
  SHARE_MODAL = 'SHARE_MODAL',
}

const COMMENTS_LIMIT = 5;

const ProfilePost: React.FC<IProfilePostProps> = ({ post, refetchPosts }) => {
  const { currentModal, toggleModal } = useModalManager();
  const [selectedPostType, setSelectedPostType] = useState<PostType>(
    PostType.NONE
  );

  const { user: myData } = useSelector(selectAuthData);
  const { data: userData } = useGetUserInfoQuery(post.userId);

  const [postCommentsPage, setPostCommentsPage] = useState<number>(1);

  const commentsParams = {
    postId: post.id,
    page: postCommentsPage,
    limit: COMMENTS_LIMIT,
  };

  const { data: postCommentsData } = useGetPostCommentsQuery(commentsParams);

  useEffect(() => {
    if (postCommentsData?.total) setCommentsCount(postCommentsData.total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCommentsData?.total]);

  const [commentMsg, setCommentMsg] = useState<string>('');
  const [likesCount, setLikesCount] = useState<number>(post.likesCount);

  const [commentsCount, setCommentsCount] = useState<number>(0);

  const [isLiked, setIsLiked] = useState<boolean>(
    isPostLikedByUser(post.reactions, myData?.id as string) || false
  );

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  function isPostLikedByUser(postReactions: any[], userId: string) {
    return postReactions.some(
      reaction => reaction.userId === userId && reaction.type === 'LIKE'
    );
  }

  const [likePost, { isLoading: isLiking }] = useLikePostMutation();

  const handleLike = async (postId: string) => {
    try {
      await likePost(postId).unwrap();

      setIsLiked(prev => !prev);
      setLikesCount(prev => prev + 1);
      setSnackbarOpen(true);
      setSnackbarMessage('Post liked!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to like the post: ', error);
    }
  };
  const [dislikePost, { isLoading: isDisliking }] = useDislikePostMutation();

  const handleDislike = async (postId: string) => {
    try {
      await dislikePost(postId).unwrap();

      setIsLiked(prev => !prev);
      setLikesCount(prev => prev - 1);
      setSnackbarOpen(true);
      setSnackbarMessage('Post disliked!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Dislike failed: ', error);
    }
  };

  const [commentPost, { isLoading: isCommenting }] = useCreateCommentMutation();

  const handleComment = async (postId: string) => {
    if (commentMsg) {
      try {
        await commentPost({ postId, message: commentMsg }).unwrap();
        setCommentsCount(prev => prev + 1);
        setSnackbarOpen(true);
        setSnackbarMessage('Post commented!');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
        setCommentMsg('');
        setPostCommentsPage(1);
        // refetchComments();
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(errorHelper(error));
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        console.error('Comment failed: ', error);
      }
    }
  };

  const filesArray = post.postFiles.map((file, idx) => {
    const fileObj = { id: file.id, img: file.fileUrl, title: file.fileName };
    if (idx === 4 || idx === 5 || idx === 11) return { ...fileObj, cols: 2 };
    if (idx === 8 || idx === 5) return { ...fileObj, rows: 2, cols: 2 };
    return fileObj;
  });

  return (
    <>
      {currentModal === MyPostModals.CREATE_POST_MODAL && (
        <CreatePostModal
          refetchPosts={refetchPosts}
          isOpen
          onClose={() => {
            toggleModal(MyPostModals.CREATE_POST_MODAL);
          }}
          selectedPostType={selectedPostType}
        />
      )}
      {currentModal === MyPostModals.REPOST_MODAL && (
        <RepostModal
          isOpen
          onClose={() => {
            toggleModal(MyPostModals.REPOST_MODAL);
          }}
          post={post}
        />
      )}
      {currentModal === MyPostModals.SHARE_MODAL && (
        <RepostModal
          isOpen
          isShare
          onClose={() => {
            toggleModal(MyPostModals.SHARE_MODAL);
          }}
          post={post}
        />
      )}
      {currentModal === MyPostModals.POST_WITH_COMMENTS_MODAL &&
        post &&
        userData && (
          <PostCommentsModal
            isOpen
            onClose={() => {
              toggleModal(MyPostModals.POST_WITH_COMMENTS_MODAL);
            }}
            selectedPostType={selectedPostType}
            post={post}
            userData={userData}
            likesCount={likesCount}
            commentsCount={commentsCount}
            isLiked={isLiked}
            isLiking={isLiking}
            isDisliking={isDisliking}
            isCommenting={isCommenting}
            handleDislike={handleDislike}
            handleLike={handleLike}
            handleComment={handleComment}
            postCommentsPage={postCommentsPage}
            setPostCommentsPage={setPostCommentsPage}
            setSelectedPostType={setSelectedPostType}
            toggleModal={toggleModal}
            commentMsg={commentMsg}
            setCommentMsg={setCommentMsg}
            postCommentsData={postCommentsData as ICommentResponse}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
      <Container disableGutters>
        <Box component="header" mb={2} color="#142237">
          <Grid container columnSpacing={2}>
            <Grid item component={Link} to={`/${userData?.id}`}>
              <Avatar
                alt="Profile"
                src={
                  userData && userData?.optimizedUserAvatarBaseUrl
                    ? `data:image/jpeg;base64,${userData.optimizedUserAvatarBaseUrl}`
                    : ''
                }
                sx={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid
              item
              flex={1}
              component={Link}
              to={`/${userData?.id}`}
              color={'inherit'}
            >
              <Typography variant="body1">
                {`${userData?.firstName || ''} ${userData?.lastName || ''}`}
              </Typography>
              <Typography variant="caption" color={'text.secondary'}>
                {formatDate(new Date(post.createdAt))}
              </Typography>
            </Grid>
            <Grid item mr={'-12px'}>
              <ProfilePostMoreMenu
                post={post}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarSeverity={setSnackbarSeverity}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component="article"
          onClick={() => toggleModal(MyPostModals.POST_WITH_COMMENTS_MODAL)}
          sx={{ cursor: 'pointer' }}
        >
          <Box component="header" mb={2}>
            <Typography
              variant="body2"
              pt="16px"
              color="#142237"
              whiteSpace={'pre-wrap'}
              fontWeight={400}
              textAlign={'justify'}
            >
              {removeHashtagWords(post.content)}
            </Typography>
            {post.tags.length > 0 && (
              <Typography
                variant="body2"
                mt={2}
                color="primary"
                sx={{ fontWeight: 'bold' }}
              >
                {post.tags.map(tag => `#${tag} `)}
              </Typography>
            )}
          </Box>
          {!post.repostedPostId && (
            <Box component="figure">
              <QuiltedImageList
                itemData={filesArray}
                toggleModal={toggleModal}
                imageListProps={{
                  sx: { margin: '0 -22px' },
                }}
              />

              <Box component="figcaption" pt="16px" pb="16px">
                <Grid container justifyContent="space-between">
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    columnSpacing="6px"
                  >
                    <LikeIcon />
                    <Typography
                      variant="caption"
                      ml="6px"
                      color={'text.secondary'}
                      sx={{ cursor: 'pointer' }}
                    >
                      {likesCount} Likes
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      mr="12px"
                      variant="caption"
                      color={'text.secondary'}
                      sx={{ cursor: 'pointer' }}
                    >
                      {commentsCount} Comments
                    </Typography>
                    <Typography
                      variant="caption"
                      color={'text.secondary'}
                      sx={{ cursor: 'pointer' }}
                    >
                      {post.repostsCount} Reposts
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {post.repostedPostId && (
            <>
              <RepostedPost repostedPostId={post.repostedPostId} />
              <Box component="figcaption" pt="16px" pb="16px">
                <Grid container justifyContent="space-between">
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    columnSpacing="6px"
                  >
                    <LikeIcon />
                    <Typography
                      variant="caption"
                      ml="6px"
                      color={'text.secondary'}
                      sx={{ cursor: 'pointer' }}
                    >
                      {likesCount} Likes
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      mr="12px"
                      variant="caption"
                      color={'text.secondary'}
                      sx={{ cursor: 'pointer' }}
                    >
                      {commentsCount} Comments
                    </Typography>
                    <Typography
                      variant="caption"
                      color={'text.secondary'}
                      sx={{ cursor: 'pointer' }}
                    >
                      {post.repostsCount} Reposts
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
        <Box component="footer">
          <Grid
            container
            justifyContent="space-between"
            sx={{ gridColumnGap: '12px', padding: '22px 0' }}
          >
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  isLiked ? handleDislike(post.id) : handleLike(post.id);
                }}
                disabled={isLiking || isDisliking}
              >
                <LikeIcon fillNone={!isLiked} /> Like
              </PostButtons>
            </Grid>
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  setSelectedPostType(PostType.NONE);
                  toggleModal(MyPostModals.REPOST_MODAL);
                }}
              >
                <RepostIcon />
                Repost
              </PostButtons>
            </Grid>
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  setSelectedPostType(PostType.NONE);
                  toggleModal(MyPostModals.SHARE_MODAL);
                }}
              >
                <SendIcon />
                Share
              </PostButtons>
            </Grid>
          </Grid>
          <Grid container columnGap={2}>
            <Grid item height="fit-content">
              <Avatar
                alt="Profile"
                src={
                  myData && myData?.optimizedUserAvatarBaseUrl
                    ? `data:image/jpeg;base64,${myData.optimizedUserAvatarBaseUrl}`
                    : ''
                }
                sx={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item flex={1} component={'form'}>
              <TextField
                value={commentMsg}
                onChange={e => {
                  setCommentMsg(e.target.value);
                }}
                id="input-with-icon-textfield"
                fullWidth
                sx={{
                  minHeight: '2.5rem',
                  '& .MuiInputBase-root': {
                    backgroundColor: '#F6F7F8',
                    borderRadius: '4px',
                  },
                  '& .MuiInputBase-input': {
                    height: '2.5rem !important',
                    backgroundColor: '#F6F7F8 !important',
                    borderRadius: '4px',
                  },
                  '& .MuiSvgIcon-root': {
                    backgroundColor: 'unset',
                  },
                }}
                placeholder="Comment..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        aria-label="send comment button"
                        sx={{
                          p: '0px',
                          width: 25,
                          minWidth: '25px',
                        }}
                        disabled={isCommenting}
                        onClick={e => {
                          e.preventDefault();
                          handleComment(post.id);
                          toggleModal(MyPostModals.POST_WITH_COMMENTS_MODAL);
                        }}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <SnackbarCustom
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Container>
    </>
  );
};

export default ProfilePost;

import { Box } from '@mui/material';
import SidebarLayout from '../../layouts/SidebarLayout';
import SectionLayout from '../../layouts/SectionLayout';
import JobPostApplySidebar from '../../components/JobPostApply/JobPostApplySidebar/JobPostApplySidebar';
import JobPostApply from '../../components/JobPostApply/JobPostApply';
import { useParams } from 'react-router-dom';
import { useGetJobpostByIdQuery } from '../../store/apiSlice/jobpost/jobpostApi';
import { IJobpost } from '../../interfaces/jobpost.interface';
import { JobPostItem } from '../JobPostsPage/components/JobPostsList/JobPostsList';

const JobPostApplyPage = () => {
  const { id } = useParams();

  const { data: jobPostData, isLoading, error } = useGetJobpostByIdQuery(id);

  let mappedJobpost: JobPostItem | undefined = undefined;

  if (jobPostData && 'jobpost' in jobPostData) {
    mappedJobpost = { type: 'REGULAR', jobpost: jobPostData.jobpost };
  }

  return (
    <SidebarLayout
      sidebar={
        jobPostData && (
          <JobPostApplySidebar company={jobPostData?.jobpost.company} />
        )
      }
      flexDirection={{ xs: 'column', lg: 'row-reverse' }}
    >
      <Box display="flex" flexDirection="column" flexGrow="1">
        <SectionLayout
          height={'100%'}
          sx={{
            bgcolor: 'background.white',
            p: { xs: '36px 16px', md: '24px', lg: '16px 36px 48px 36px' },
          }}
        >
          {!isLoading && mappedJobpost && (
            <JobPostApply
              jobPost={mappedJobpost as { jobpost: IJobpost; type: 'REGULAR' }}
            />
          )}
        </SectionLayout>
      </Box>
    </SidebarLayout>
  );
};

export default JobPostApplyPage;

import { SvgIcon, SvgIconProps } from '@mui/material';

const BackArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 24, height: 24, fill: 'none' }}
      {...props}
    >
      <path
        d="M8.45459 14L4.45459 10L8.45459 6"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5 10H16C17.0609 10 18.0783 10.4214 18.8284 11.1716C19.5786 11.9217 20 12.9391 20 14C20 15.0609 19.5786 16.0783 18.8284 16.8284C18.0783 17.5786 17.0609 18 16 18H15"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default BackArrowIcon;

import { SvgIcon, SvgIconProps } from '@mui/material';

const ReportFileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 24, height: 24, fill: 'none' }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#727F93" strokeLinecap="square" strokeWidth="1.5">
        <path d="M14 3v5h5"></path>
        <path d="M5 21h14V7.722L14.455 3H5v18z"></path>
      </g>
    </SvgIcon>
  );
};

export default ReportFileIcon;

export enum ChatTypeEnum {
  PERSONAL = 'personal',
  GROUP = 'group',
}

export enum ChatStateEnum {
  CHAT = 'chat',
  NEW_PERSONAL = 'new_personal',
  NEW_GROUP = 'new_group',
  ALL_CHATS = 'all_chats',
  GROUP_INFO = 'group_info',
}

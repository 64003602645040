import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../../../theme/theme';

interface PasswordValidationMessageProps {
  message: string;
  isValid: boolean;
}
const PasswordValidationMessage: FC<PasswordValidationMessageProps> = ({
  message,
  isValid,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '13px',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isValid ? 'background.mint' : 'background.pink',
          width: '24px',
          height: '24px',
          borderRadius: '80px',
          p: '6px',
        }}
      >
        {isValid ? (
          <CheckIcon
            sx={{
              width: '14px',
              height: '14px',
              color: 'text.done',
            }}
          />
        ) : (
          <CloseIcon
            sx={{ width: '14px', height: '14px', color: 'error.main' }}
          />
        )}
      </Box>
      <Typography sx={{ color: colors.dark60 }} variant={'body4'}>
        {message}
      </Typography>
    </Box>
  );
};

export default PasswordValidationMessage;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function GreenCheckedIcon(svgProps?: SvgIconProps) {
  return (
    <SvgIcon {...svgProps}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
        <path d="M2.5 6.5L5 9L10 4" stroke="#19851D" strokeWidth="1.5" strokeLinecap="square"/>
      </svg>  
    </SvgIcon>
  );
}

import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAthletesDistributionBySportQuery } from '../../../store/apiSlice/analitics/analiticsApi';
import JobPostFilterSingleSelect from '../../../pages/JobPostsPage/components/JobPostsSidebar/JobPostsFilters/JobPostFilterSingleSelect';
import createArrayFromEnum from '../../../utils/createArrayFromEnum';
import { DemographicsInsightEnum } from '../../../enums/demographics-insight-enum';
import { TDemographicsInsight } from '../../../interfaces/jobpost.interface';
import AthleteDistributionBySport from './LineRangeAnalytics/AthleteDistributionBySport';
import Loader from '../../MUIComponents/Loader';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const DemographicInsight = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [statisticType, setStatisticType] = useState<string>(
    DemographicsInsightEnum.ATHLETE_DISTRIBUTION_BY_SPORT
  );

  const demographicsInsightArray: TDemographicsInsight[] = createArrayFromEnum(
    DemographicsInsightEnum,
    'demographicsInsight'
  );

  const {
    data: athletesDistributionBySportData,
    isLoading: isAthletesDistributionBySportLoading,
    refetch: refetchAthletesDistributionBySport,
  } = useAthletesDistributionBySportQuery(undefined, {
    skip:
      statisticType !== DemographicsInsightEnum.ATHLETE_DISTRIBUTION_BY_SPORT,
  });

  useEffect(() => {
    if (
      statisticType === DemographicsInsightEnum.ATHLETE_DISTRIBUTION_BY_SPORT
    ) {
      refetchAthletesDistributionBySport();
    }
  }, [statisticType, refetchAthletesDistributionBySport]);

  const filteredData = useMemo(() => {
    if (
      !athletesDistributionBySportData ||
      !athletesDistributionBySportData.data.sports
    )
      return [];

    const sportsData = Object.keys(
      athletesDistributionBySportData.data.sports
    ).map(sport => ({
      sport,
      candidates: athletesDistributionBySportData.data.sports[sport].count,
      percentage: athletesDistributionBySportData.data.sports[sport].percentage,
    }));

    return sportsData.sort((a, b) => b.candidates - a.candidates);
  }, [athletesDistributionBySportData]);

  const renderComponent = useMemo(() => {
    switch (statisticType) {
      case DemographicsInsightEnum.ATHLETE_DISTRIBUTION_BY_SPORT:
        return isAthletesDistributionBySportLoading ? (
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100px'}
          >
            <Loader size={50} color={'primary'} />
          </Box>
        ) : (
          <AthleteDistributionBySport
            data={filteredData}
            totalCandidates={
              athletesDistributionBySportData?.data?.candidates ?? 0
            }
          />
        );
      case DemographicsInsightEnum.SOURCE_OF_HIRE:
        return (
          <Typography variant={'caption'} padding="22px 36px">
            "Source of hire" will go here.
          </Typography>
        );
      case DemographicsInsightEnum.EDUCATION_LEVELS_OF_APPLICANTS:
        return (
          <Typography variant={'caption'} padding="22px 36px">
            "Education levels of applicants" will go here.
          </Typography>
        );
      default:
        return null;
    }
  }, [
    statisticType,
    filteredData,
    isAthletesDistributionBySportLoading,
    athletesDistributionBySportData,
  ]);

  return (
    <Box width="100%" height="fit-content" bgcolor="#fff">
      <Box
        borderBottom="1px solid #E8EBEF"
        padding={isSmallScreen ? '20px 16px' : '20.5px 36px'}
      >
        <Typography
          sx={{
            fontSize: '20px',
          }}
          variant={'h3'}
        >
          Demographic Insight
        </Typography>
      </Box>

      <Box
        width={mobileView ? '100%' : '50%'}
        maxWidth={mobileView ? '100%' : '400px'}
        padding={isSmallScreen ? '22px 16px 12px 16px' : '22px 36px'}
      >
        <JobPostFilterSingleSelect
          label={'demographicsInsight'}
          placeholder={'Choose type of statistics:'}
          selectItems={demographicsInsightArray}
          setFilterState={setStatisticType}
          filterState={statisticType}
        />
      </Box>
      <Box
        id="pdf-bottom-demographics"
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
      >
        {renderComponent}
      </Box>
    </Box>
  );
};

export default DemographicInsight;

import { IAppliedUser, IJobpost } from '../../interfaces/jobpost.interface';

export const jobStatusHelper = (
  jobpost: IJobpost | null
): { message: string; color: string } => {
  if (jobpost?.isClosed) {
    return { message: 'Closed', color: '#E92726' };
  }
  if (jobpost?.isArchived) {
    return { message: 'Archived', color: '#F5651D' };
  }
  if (jobpost?.isDraft) {
    return { message: 'Draft', color: '#727F93' };
  }
  if (jobpost?.isDeleted) {
    return { message: 'Deleted', color: '#E92726' };
  }
  return { message: 'Open', color: '#19851D' };
};

export const applicationStatusHelper = (
  appliedUser: IAppliedUser
): { message: string; color: string } => {
  if (appliedUser?.status) {
    return { message: 'application approved', color: '#19851D' };
  }
  if (appliedUser?.isRejected) {
    return { message: 'application rejected', color: '#E92726' };
  }
  return { message: 'application sent', color: '#727F93' };
};

export const applicationRequestStatusHelper = (
  application: IAppliedUser | null
): { message: string; color: string } => {
  const isApproved = application?.status;
  if (application?.isRejected) {
    return { message: 'Rejected', color: '#E92726' };
  }
  if (application?.isCanceled) {
    return { message: 'Canceled', color: '#727F93' };
  }
  if (isApproved) {
    return { message: 'Approved', color: '#19851D' };
  }
  if (!application?.isViewed) {
    return { message: 'New', color: '#19851D' };
  }

  return { message: '', color: 'none' };
};

import {
  Rating as MUIRating,
  RatingProps as MUIRatingProps,
  Typography,
} from '@mui/material';

interface RatingProps1 extends MUIRatingProps {
  error?: boolean;
  helperText?: string;
}

const Rating = ({ sx, onChange, error, helperText, ...rest }: RatingProps1) => {
  return (
    <>
      <MUIRating
        sx={{
          '& .MuiRating-iconFilled': {
            color: '#F5651D',
          },
          ...sx,
        }}
        onChange={onChange}
        {...rest}
      />
      {error && (
        <Typography sx={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default Rating;

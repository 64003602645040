import { SvgIcon, SvgIconProps } from '@mui/material';

const PhoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 24, height: 24, fill: 'none' }}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.25 5.25C11.6478 5.25 12.0294 5.40804 12.3107 5.68934C12.592 5.97064 12.75 6.35218 12.75 6.75"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 2.25C12.4435 2.25 13.5881 2.72411 14.432 3.56802C15.2759 4.41193 15.75 5.55653 15.75 6.75"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default PhoneIcon;

import { SvgIcon, SvgIconProps } from '@mui/material';

const RepostIcon = ({ stroke, ...svgProps }: SvgIconProps) => {
  return (
    <SvgIcon
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 21, height: 20, fill: 'none' }}
      viewBox="0 0 21 20"
    >
      <path
        d="M3.8335 10V7.5C3.8335 6.83696 4.09689 6.20107 4.56573 5.73223C5.03457 5.26339 5.67046 5 6.3335 5H17.1668M17.1668 5L14.6668 2.5M17.1668 5L14.6668 7.5"
        stroke={stroke ?? '#727F93'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M17.1668 10V12.5C17.1668 13.163 16.9034 13.7989 16.4346 14.2678C15.9658 14.7366 15.3299 15 14.6668 15H3.8335M3.8335 15L6.3335 17.5M3.8335 15L6.3335 12.5"
        stroke={stroke ?? '#727F93'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default RepostIcon;

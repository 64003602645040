import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonTypeEnum } from '../../enums/button-type-enum';
import { useModalManager } from '../../hooks/useModalManager';
import ApplyJobModal from '../../pages/JobPostApplyPage/components/modals/ApplyJobModal';
import formatEnumValue from '../../utils/formatEnumValue';
import ArrowLeftIcon from '../Icons/ArrowLeftIcon';
import JobCompanyIcon from '../Icons/JobCompanyIcon';
import JobLocationIcon from '../Icons/JobLocationIcon';
import JobPositionIcon from '../Icons/JobPositionIcon';
import CustomButton from '../MUIComponents/CustomButton';

import { useMemo } from 'react';
import { TIndustry } from '../../interfaces/jobpost.interface';
import CancelJobModal from '../../pages/home/shared-components/modals/CancelJobModal';
import { JobPostItem } from '../../pages/JobPostsPage/components/JobPostsList/JobPostsList';
import { applicationStatusHelper } from '../../utils/helper/status-helper';
import CollapsedContent from '../CollapsedContent/CollapsedContent';
import HTMLContent from '../HTMLcontent/HTMLcontent';
import FlagIcon from '../Icons/FlagIcon';
import BaseModal from '../MUIComponents/BaseModal';
import Button from '../MUIComponents/Button';

const jobApplySectionStyles = {
  paddingBottom: '28px',
  paddingTop: '36px',
};

interface IJobPostApplyProps {
  jobPost: JobPostItem;
  sectionStyles?: SxProps;
}

const JobPostApply: React.FC<IJobPostApplyProps> = ({
  jobPost,
  sectionStyles,
}) => {
  const navigate = useNavigate();
  const { currentModal, toggleModal } = useModalManager();

  const isAppliedUser = jobPost.type === 'APPLIED_USER';
  const isCompanyJobPost = jobPost.type === 'COMPANY_JOBPOST';

  const title = useMemo(
    () => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box m="24px 0 36px">
          <FlagIcon />
        </Box>
        <Typography variant="h2">Application Canceled</Typography>
      </Box>
    ),
    []
  );

  const footer = useMemo(
    () => (
      <Box
        display="flex"
        flexDirection={'row'}
        gap={'22px'}
        justifyContent="center"
        m="36px 0 36px"
      >
        <Button
          onClick={() => navigate('/?tab=6&page=1&query=', { replace: true })}
          variant="contained"
          width={220}
          height={50}
        >
          got it!
        </Button>
      </Box>
    ),
    [navigate]
  );

  const handleCancelJobpostApplyFinished = () => {
    toggleModal('COMPLETE_CANCEL_JOB_MODAL');
  };

  const completeCancelJobPost = () => {
    toggleModal('');
    navigate('/?tab=6&page=1&query=', { replace: true });
  };

  return (
    <Box
      color={'text.primary'}
      sx={theme => ({
        [theme.breakpoints.down('lg')]: {
          pb: '30px',
        },
      })}
    >
      {currentModal === 'APPLY_JOB_MODAL' && (
        <ApplyJobModal
          isOpen
          onClose={() => toggleModal('APPLY_JOB_MODAL')}
          jobpostId={
            isAppliedUser ? jobPost.appliedUser.id : jobPost.jobpost.id
          }
        />
      )}
      {currentModal === 'CANCEL_JOB_MODAL' && (
        <CancelJobModal
          isOpen
          onClose={() => toggleModal('CANCEL_JOB_MODAL')}
          jobpostId={
            isAppliedUser ? jobPost.appliedUser.jobpostId : jobPost.jobpost.id
          }
          handleCancelJobpostApplyFinished={handleCancelJobpostApplyFinished}
        />
      )}
      {currentModal === 'COMPLETE_CANCEL_JOB_MODAL' && (
        <BaseModal
          boxSx={{ width: '875px', maxHeight: 756, overflowY: 'scroll' }}
          header={{
            component: title,
          }}
          isOpen={true}
          toggle={() => completeCancelJobPost()}
          footer={{ component: footer }}
        >
          <Typography variant="body2" textAlign="center">
            Your application has been successfully canceled.
          </Typography>
        </BaseModal>
      )}
      <Stack
        gap={'28px'}
        sx={isCompanyJobPost ? sectionStyles : jobApplySectionStyles}
      >
        {isAppliedUser && (
          <>
            <Link
              to=""
              onClick={() => navigate(-1)}
              style={{ color: '#000', textDecoration: 'none' }}
            >
              <Typography variant="h3" display="flex" alignItems="center">
                <ArrowLeftIcon />
                &nbsp;&nbsp;Back to my job applications
              </Typography>
            </Link>
            <Divider sx={{ p: '0px -36px' }} />
          </>
        )}
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
        >
          <Box>
            {isAppliedUser && (
              <Box
                borderRadius="20px"
                border={`1px solid ${applicationStatusHelper(jobPost.appliedUser).color}`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="6px 12px"
                mb="16px"
                width="fit-content"
              >
                <Typography
                  variant="body4"
                  textTransform="uppercase"
                  color={applicationStatusHelper(jobPost.appliedUser).color}
                >
                  {applicationStatusHelper(jobPost.appliedUser).message}
                </Typography>
              </Box>
            )}
            <Typography variant={'h2'} mb={'16px'}>
              {isAppliedUser
                ? jobPost.appliedUser.jobpost.title
                : jobPost.jobpost.title}
            </Typography>

            <Stack direction={'row'} gap={'28px'}>
              {!isCompanyJobPost && (
                <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                  <JobCompanyIcon />
                  <Typography variant="body3">
                    {isAppliedUser
                      ? jobPost.appliedUser.jobpost.company.companyInfo
                          ?.companyName
                      : jobPost.jobpost.company.companyInfo?.companyName}
                  </Typography>
                </Box>
              )}
              <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                <JobPositionIcon />
                <Typography variant="body3">
                  {formatEnumValue(
                    isAppliedUser
                      ? jobPost.appliedUser.jobpost.position
                      : jobPost.jobpost.position
                  )}
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                <JobLocationIcon />
                <Typography variant="body3">
                  {formatEnumValue(
                    isAppliedUser
                      ? jobPost.appliedUser.jobpost.location
                      : jobPost.jobpost.location
                  )}
                </Typography>
              </Box>
            </Stack>
          </Box>
          {!isCompanyJobPost &&
            (isAppliedUser
              ? jobPost?.appliedUser?.jobpost?.company?.avatar?.fileUrl
              : jobPost?.jobpost?.company?.avatar?.fileUrl) && (
              <img
                src={`${
                  isAppliedUser
                    ? jobPost?.appliedUser?.jobpost?.company?.avatar?.fileUrl
                    : jobPost?.jobpost?.company?.avatar?.fileUrl
                }`}
                width={100}
                height={60}
                alt={'Company logo'}
              />
            )}
        </Stack>
        {isAppliedUser && (
          <CollapsedContent>
            <HTMLContent content={jobPost.appliedUser.jobpost.description} />
          </CollapsedContent>
        )}
        {!isAppliedUser && (
          <HTMLContent content={jobPost.jobpost.description} />
        )}
      </Stack>

      <Divider />

      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        gap={{ xs: '12px', lg: '5px' }}
        marginBottom={{
          xs: '20px',
          lg: '0',
        }}
        justifyContent={{
          lg: 'space-between',
        }}
        sx={isCompanyJobPost ? sectionStyles : jobApplySectionStyles}
      >
        <Box display={'flex'} flexDirection={'column'} flex={1} gap={'12px'}>
          <Typography variant="body3" fontSize={'12px'}>
            Industry:
          </Typography>
          {(isAppliedUser
            ? jobPost.appliedUser.jobpost.industries.length > 0
            : jobPost.jobpost.industries.length > 0) && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0 }}>
              {(isAppliedUser
                ? jobPost.appliedUser.jobpost.industries
                : jobPost.jobpost.industries
              ).map(({ industry, id }: TIndustry, index: number) => (
                <Typography
                  key={id}
                  component={'span'}
                  variant="body4"
                  fontSize={'14px'}
                  lineHeight={'normal'}
                >
                  {industry}
                  {index <
                    (isAppliedUser
                      ? jobPost.appliedUser.jobpost.industries
                      : jobPost.jobpost.industries
                    ).length -
                      1 && ' /\u00A0'}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
        <Box display={'flex'} flexDirection={'column'} flex={1} gap={'12px'}>
          <Typography variant="body3" fontSize={'12px'}>
            Salary:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0 }}>
            <Typography
              component={'span'}
              variant="body4"
              fontSize={'14px'}
              lineHeight={'normal'}
            >
              {`$${
                isAppliedUser
                  ? jobPost.appliedUser.jobpost.minSalary
                  : jobPost.jobpost.minSalary
              } - $${
                isAppliedUser
                  ? jobPost.appliedUser.jobpost.maxSalary
                  : jobPost.jobpost.maxSalary
              } ${formatEnumValue(
                isAppliedUser
                  ? jobPost.appliedUser.jobpost.salaryPeriod
                  : jobPost.jobpost.salaryPeriod,
                false
              )}, commensurate with experience.`}
            </Typography>
          </Box>
        </Box>
      </Stack>
      {!isCompanyJobPost && isAppliedUser && (
        <CustomButton
          variantType={ButtonTypeEnum.PRIMARY}
          variant="contained"
          sx={{ mt: '36px', width: { xs: '100%', lg: '220px' } }}
          onClick={() => toggleModal('CANCEL_JOB_MODAL')}
        >
          Cancel Application
        </CustomButton>
      )}
      {!isCompanyJobPost && !isAppliedUser && (
        <CustomButton
          variantType={ButtonTypeEnum.PRIMARY}
          variant="contained"
          sx={{ mt: '36px', width: { xs: '100%', lg: '220px' } }}
          onClick={() => toggleModal('APPLY_JOB_MODAL')}
        >
          Apply job
        </CustomButton>
      )}
    </Box>
  );
};

export default JobPostApply;

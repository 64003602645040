const DeleteChatIcon = () => {
  return (
    <svg
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3100_48654)">
        <path
          d="M60.5 120C93.6371 120 120.5 93.1371 120.5 60C120.5 26.8629 93.6371 0 60.5 0C27.3629 0 0.5 26.8629 0.5 60C0.5 93.1371 27.3629 120 60.5 120Z"
          fill="#FDECE8"
        />
        <path
          d="M40.4303 94.7002H80.6103C81.4203 94.7002 82.0903 94.0802 82.1603 93.2702L86.9803 33.7002H34.0703L38.8903 93.2702C38.9603 94.0802 39.6303 94.7002 40.4403 94.7002H40.4303Z"
          fill="#F4421D"
        />
        <path
          d="M34.63 26.8501H86.4C88.54 26.8501 90.29 28.5901 90.29 30.7401V33.7101H30.75V30.7401C30.75 28.6001 32.49 26.8501 34.64 26.8501H34.63Z"
          fill="#F4421D"
        />
        <mask
          id="mask0_3100_48654"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="33"
          y="32"
          width="55"
          height="4"
        >
          <path
            d="M87.0505 32.6501H33.9805L34.1905 35.3601H86.8405L87.0505 32.6501Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3100_48654)">
          <path
            d="M87.9308 34.2102H33.3008"
            stroke="white"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M69.7902 26.8501V24.7501C69.7902 22.9901 68.5502 21.5601 67.0202 21.5601H54.0102C52.4802 21.5601 51.2402 22.9901 51.2402 24.7501V26.8501"
          stroke="#142237"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M60.5098 43.1899V63.9699"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M74.3997 43.1899L73.2197 63.9799"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M47.8099 63.9702L46.6299 43.1802"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <mask
          id="mask1_3100_48654"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="36"
          y="63"
          width="49"
          height="32"
        >
          <path
            d="M40.4399 94.6999H80.5999C81.4099 94.6999 82.0799 94.0799 82.1499 93.2699L84.5799 63.1699H36.4199L38.8899 93.2699C38.9599 94.0799 39.6299 94.6999 40.4399 94.6999Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_3100_48654)">
          <path
            d="M82.8102 64.4699C83.1691 64.4699 83.4602 64.1789 83.4602 63.8199C83.4602 63.4609 83.1691 63.1699 82.8102 63.1699C82.4512 63.1699 82.1602 63.4609 82.1602 63.8199C82.1602 64.1789 82.4512 64.4699 82.8102 64.4699Z"
            fill="#142237"
          />
          <path
            d="M84.4107 66.06C84.7697 66.06 85.0607 65.769 85.0607 65.41C85.0607 65.051 84.7697 64.76 84.4107 64.76C84.0518 64.76 83.7607 65.051 83.7607 65.41C83.7607 65.769 84.0518 66.06 84.4107 66.06Z"
            fill="#142237"
          />
          <path
            d="M79.6305 64.4699C79.9894 64.4699 80.2805 64.1789 80.2805 63.8199C80.2805 63.4609 79.9894 63.1699 79.6305 63.1699C79.2715 63.1699 78.9805 63.4609 78.9805 63.8199C78.9805 64.1789 79.2715 64.4699 79.6305 64.4699Z"
            fill="#142237"
          />
          <path
            d="M81.2203 66.06C81.5793 66.06 81.8703 65.769 81.8703 65.41C81.8703 65.051 81.5793 64.76 81.2203 64.76C80.8613 64.76 80.5703 65.051 80.5703 65.41C80.5703 65.769 80.8613 66.06 81.2203 66.06Z"
            fill="#142237"
          />
          <path
            d="M82.8102 67.6499C83.1691 67.6499 83.4602 67.3588 83.4602 66.9999C83.4602 66.6409 83.1691 66.3499 82.8102 66.3499C82.4512 66.3499 82.1602 66.6409 82.1602 66.9999C82.1602 67.3588 82.4512 67.6499 82.8102 67.6499Z"
            fill="#142237"
          />
          <path
            d="M84.4107 69.2399C84.7697 69.2399 85.0607 68.9489 85.0607 68.5899C85.0607 68.231 84.7697 67.9399 84.4107 67.9399C84.0518 67.9399 83.7607 68.231 83.7607 68.5899C83.7607 68.9489 84.0518 69.2399 84.4107 69.2399Z"
            fill="#142237"
          />
          <path
            d="M76.4498 64.4699C76.8088 64.4699 77.0998 64.1789 77.0998 63.8199C77.0998 63.4609 76.8088 63.1699 76.4498 63.1699C76.0908 63.1699 75.7998 63.4609 75.7998 63.8199C75.7998 64.1789 76.0908 64.4699 76.4498 64.4699Z"
            fill="#142237"
          />
          <path
            d="M78.0406 66.06C78.3996 66.06 78.6906 65.769 78.6906 65.41C78.6906 65.051 78.3996 64.76 78.0406 64.76C77.6816 64.76 77.3906 65.051 77.3906 65.41C77.3906 65.769 77.6816 66.06 78.0406 66.06Z"
            fill="#142237"
          />
          <path
            d="M79.6305 67.6499C79.9894 67.6499 80.2805 67.3588 80.2805 66.9999C80.2805 66.6409 79.9894 66.3499 79.6305 66.3499C79.2715 66.3499 78.9805 66.6409 78.9805 66.9999C78.9805 67.3588 79.2715 67.6499 79.6305 67.6499Z"
            fill="#142237"
          />
          <path
            d="M81.2203 69.2399C81.5793 69.2399 81.8703 68.9489 81.8703 68.5899C81.8703 68.231 81.5793 67.9399 81.2203 67.9399C80.8613 67.9399 80.5703 68.231 80.5703 68.5899C80.5703 68.9489 80.8613 69.2399 81.2203 69.2399Z"
            fill="#142237"
          />
          <path
            d="M82.8102 70.83C83.1691 70.83 83.4602 70.539 83.4602 70.18C83.4602 69.821 83.1691 69.53 82.8102 69.53C82.4512 69.53 82.1602 69.821 82.1602 70.18C82.1602 70.539 82.4512 70.83 82.8102 70.83Z"
            fill="#142237"
          />
          <path
            d="M84.4107 72.4199C84.7697 72.4199 85.0607 72.1289 85.0607 71.7699C85.0607 71.4109 84.7697 71.1199 84.4107 71.1199C84.0518 71.1199 83.7607 71.4109 83.7607 71.7699C83.7607 72.1289 84.0518 72.4199 84.4107 72.4199Z"
            fill="#142237"
          />
          <path
            d="M73.2701 64.4699C73.6291 64.4699 73.9201 64.1789 73.9201 63.8199C73.9201 63.4609 73.6291 63.1699 73.2701 63.1699C72.9111 63.1699 72.6201 63.4609 72.6201 63.8199C72.6201 64.1789 72.9111 64.4699 73.2701 64.4699Z"
            fill="#142237"
          />
          <path
            d="M74.86 66.06C75.2189 66.06 75.51 65.769 75.51 65.41C75.51 65.051 75.2189 64.76 74.86 64.76C74.501 64.76 74.21 65.051 74.21 65.41C74.21 65.769 74.501 66.06 74.86 66.06Z"
            fill="#142237"
          />
          <path
            d="M76.4498 67.6499C76.8088 67.6499 77.0998 67.3588 77.0998 66.9999C77.0998 66.6409 76.8088 66.3499 76.4498 66.3499C76.0908 66.3499 75.7998 66.6409 75.7998 66.9999C75.7998 67.3588 76.0908 67.6499 76.4498 67.6499Z"
            fill="#142237"
          />
          <path
            d="M78.0406 69.2399C78.3996 69.2399 78.6906 68.9489 78.6906 68.5899C78.6906 68.231 78.3996 67.9399 78.0406 67.9399C77.6816 67.9399 77.3906 68.231 77.3906 68.5899C77.3906 68.9489 77.6816 69.2399 78.0406 69.2399Z"
            fill="#142237"
          />
          <path
            d="M79.6305 70.83C79.9894 70.83 80.2805 70.539 80.2805 70.18C80.2805 69.821 79.9894 69.53 79.6305 69.53C79.2715 69.53 78.9805 69.821 78.9805 70.18C78.9805 70.539 79.2715 70.83 79.6305 70.83Z"
            fill="#142237"
          />
          <path
            d="M81.2203 72.4199C81.5793 72.4199 81.8703 72.1289 81.8703 71.7699C81.8703 71.4109 81.5793 71.1199 81.2203 71.1199C80.8613 71.1199 80.5703 71.4109 80.5703 71.7699C80.5703 72.1289 80.8613 72.4199 81.2203 72.4199Z"
            fill="#142237"
          />
          <path
            d="M82.8102 74.01C83.1691 74.01 83.4602 73.7189 83.4602 73.36C83.4602 73.001 83.1691 72.71 82.8102 72.71C82.4512 72.71 82.1602 73.001 82.1602 73.36C82.1602 73.7189 82.4512 74.01 82.8102 74.01Z"
            fill="#142237"
          />
          <path
            d="M70.0904 64.4699C70.4494 64.4699 70.7404 64.1789 70.7404 63.8199C70.7404 63.4609 70.4494 63.1699 70.0904 63.1699C69.7314 63.1699 69.4404 63.4609 69.4404 63.8199C69.4404 64.1789 69.7314 64.4699 70.0904 64.4699Z"
            fill="#142237"
          />
          <path
            d="M71.6803 66.06C72.0393 66.06 72.3303 65.769 72.3303 65.41C72.3303 65.051 72.0393 64.76 71.6803 64.76C71.3213 64.76 71.0303 65.051 71.0303 65.41C71.0303 65.769 71.3213 66.06 71.6803 66.06Z"
            fill="#142237"
          />
          <path
            d="M73.2701 67.6499C73.6291 67.6499 73.9201 67.3588 73.9201 66.9999C73.9201 66.6409 73.6291 66.3499 73.2701 66.3499C72.9111 66.3499 72.6201 66.6409 72.6201 66.9999C72.6201 67.3588 72.9111 67.6499 73.2701 67.6499Z"
            fill="#142237"
          />
          <path
            d="M74.86 69.2399C75.2189 69.2399 75.51 68.9489 75.51 68.5899C75.51 68.231 75.2189 67.9399 74.86 67.9399C74.501 67.9399 74.21 68.231 74.21 68.5899C74.21 68.9489 74.501 69.2399 74.86 69.2399Z"
            fill="#142237"
          />
          <path
            d="M76.4498 70.83C76.8088 70.83 77.0998 70.539 77.0998 70.18C77.0998 69.821 76.8088 69.53 76.4498 69.53C76.0908 69.53 75.7998 69.821 75.7998 70.18C75.7998 70.539 76.0908 70.83 76.4498 70.83Z"
            fill="#142237"
          />
          <path
            d="M78.0406 72.4199C78.3996 72.4199 78.6906 72.1289 78.6906 71.7699C78.6906 71.4109 78.3996 71.1199 78.0406 71.1199C77.6816 71.1199 77.3906 71.4109 77.3906 71.7699C77.3906 72.1289 77.6816 72.4199 78.0406 72.4199Z"
            fill="#142237"
          />
          <path
            d="M79.6305 74.01C79.9894 74.01 80.2805 73.7189 80.2805 73.36C80.2805 73.001 79.9894 72.71 79.6305 72.71C79.2715 72.71 78.9805 73.001 78.9805 73.36C78.9805 73.7189 79.2715 74.01 79.6305 74.01Z"
            fill="#142237"
          />
          <path
            d="M81.2203 75.6098C81.5793 75.6098 81.8703 75.3188 81.8703 74.9598C81.8703 74.6008 81.5793 74.3098 81.2203 74.3098C80.8613 74.3098 80.5703 74.6008 80.5703 74.9598C80.5703 75.3188 80.8613 75.6098 81.2203 75.6098Z"
            fill="#142237"
          />
          <path
            d="M82.8102 77.1999C83.1691 77.1999 83.4602 76.9089 83.4602 76.5499C83.4602 76.1909 83.1691 75.8999 82.8102 75.8999C82.4512 75.8999 82.1602 76.1909 82.1602 76.5499C82.1602 76.9089 82.4512 77.1999 82.8102 77.1999Z"
            fill="#142237"
          />
          <path
            d="M66.9 64.4699C67.259 64.4699 67.55 64.1789 67.55 63.8199C67.55 63.4609 67.259 63.1699 66.9 63.1699C66.541 63.1699 66.25 63.4609 66.25 63.8199C66.25 64.1789 66.541 64.4699 66.9 64.4699Z"
            fill="#142237"
          />
          <path
            d="M68.5006 66.06C68.8596 66.06 69.1506 65.769 69.1506 65.41C69.1506 65.051 68.8596 64.76 68.5006 64.76C68.1416 64.76 67.8506 65.051 67.8506 65.41C67.8506 65.769 68.1416 66.06 68.5006 66.06Z"
            fill="#142237"
          />
          <path
            d="M70.0904 67.6499C70.4494 67.6499 70.7404 67.3588 70.7404 66.9999C70.7404 66.6409 70.4494 66.3499 70.0904 66.3499C69.7314 66.3499 69.4404 66.6409 69.4404 66.9999C69.4404 67.3588 69.7314 67.6499 70.0904 67.6499Z"
            fill="#142237"
          />
          <path
            d="M71.6803 69.2399C72.0393 69.2399 72.3303 68.9489 72.3303 68.5899C72.3303 68.231 72.0393 67.9399 71.6803 67.9399C71.3213 67.9399 71.0303 68.231 71.0303 68.5899C71.0303 68.9489 71.3213 69.2399 71.6803 69.2399Z"
            fill="#142237"
          />
          <path
            d="M73.2701 70.83C73.6291 70.83 73.9201 70.539 73.9201 70.18C73.9201 69.821 73.6291 69.53 73.2701 69.53C72.9111 69.53 72.6201 69.821 72.6201 70.18C72.6201 70.539 72.9111 70.83 73.2701 70.83Z"
            fill="#142237"
          />
          <path
            d="M74.86 72.4199C75.2189 72.4199 75.51 72.1289 75.51 71.7699C75.51 71.4109 75.2189 71.1199 74.86 71.1199C74.501 71.1199 74.21 71.4109 74.21 71.7699C74.21 72.1289 74.501 72.4199 74.86 72.4199Z"
            fill="#142237"
          />
          <path
            d="M76.4498 74.01C76.8088 74.01 77.0998 73.7189 77.0998 73.36C77.0998 73.001 76.8088 72.71 76.4498 72.71C76.0908 72.71 75.7998 73.001 75.7998 73.36C75.7998 73.7189 76.0908 74.01 76.4498 74.01Z"
            fill="#142237"
          />
          <path
            d="M78.0406 75.6098C78.3996 75.6098 78.6906 75.3188 78.6906 74.9598C78.6906 74.6008 78.3996 74.3098 78.0406 74.3098C77.6816 74.3098 77.3906 74.6008 77.3906 74.9598C77.3906 75.3188 77.6816 75.6098 78.0406 75.6098Z"
            fill="#142237"
          />
          <path
            d="M79.6305 77.1999C79.9894 77.1999 80.2805 76.9089 80.2805 76.5499C80.2805 76.1909 79.9894 75.8999 79.6305 75.8999C79.2715 75.8999 78.9805 76.1909 78.9805 76.5499C78.9805 76.9089 79.2715 77.1999 79.6305 77.1999Z"
            fill="#142237"
          />
          <path
            d="M81.2203 78.79C81.5793 78.79 81.8703 78.499 81.8703 78.14C81.8703 77.781 81.5793 77.49 81.2203 77.49C80.8613 77.49 80.5703 77.781 80.5703 78.14C80.5703 78.499 80.8613 78.79 81.2203 78.79Z"
            fill="#142237"
          />
          <path
            d="M82.8102 80.3798C83.1691 80.3798 83.4602 80.0888 83.4602 79.7298C83.4602 79.3709 83.1691 79.0798 82.8102 79.0798C82.4512 79.0798 82.1602 79.3709 82.1602 79.7298C82.1602 80.0888 82.4512 80.3798 82.8102 80.3798Z"
            fill="#142237"
          />
          <path
            d="M63.7203 64.4699C64.0793 64.4699 64.3703 64.1789 64.3703 63.8199C64.3703 63.4609 64.0793 63.1699 63.7203 63.1699C63.3613 63.1699 63.0703 63.4609 63.0703 63.8199C63.0703 64.1789 63.3613 64.4699 63.7203 64.4699Z"
            fill="#142237"
          />
          <path
            d="M65.3102 66.06C65.6691 66.06 65.9602 65.769 65.9602 65.41C65.9602 65.051 65.6691 64.76 65.3102 64.76C64.9512 64.76 64.6602 65.051 64.6602 65.41C64.6602 65.769 64.9512 66.06 65.3102 66.06Z"
            fill="#142237"
          />
          <path
            d="M66.9 67.6499C67.259 67.6499 67.55 67.3588 67.55 66.9999C67.55 66.6409 67.259 66.3499 66.9 66.3499C66.541 66.3499 66.25 66.6409 66.25 66.9999C66.25 67.3588 66.541 67.6499 66.9 67.6499Z"
            fill="#142237"
          />
          <path
            d="M68.5006 69.2399C68.8596 69.2399 69.1506 68.9489 69.1506 68.5899C69.1506 68.231 68.8596 67.9399 68.5006 67.9399C68.1416 67.9399 67.8506 68.231 67.8506 68.5899C67.8506 68.9489 68.1416 69.2399 68.5006 69.2399Z"
            fill="#142237"
          />
          <path
            d="M70.0904 70.83C70.4494 70.83 70.7404 70.539 70.7404 70.18C70.7404 69.821 70.4494 69.53 70.0904 69.53C69.7314 69.53 69.4404 69.821 69.4404 70.18C69.4404 70.539 69.7314 70.83 70.0904 70.83Z"
            fill="#142237"
          />
          <path
            d="M71.6803 72.4199C72.0393 72.4199 72.3303 72.1289 72.3303 71.7699C72.3303 71.4109 72.0393 71.1199 71.6803 71.1199C71.3213 71.1199 71.0303 71.4109 71.0303 71.7699C71.0303 72.1289 71.3213 72.4199 71.6803 72.4199Z"
            fill="#142237"
          />
          <path
            d="M73.2701 74.01C73.6291 74.01 73.9201 73.7189 73.9201 73.36C73.9201 73.001 73.6291 72.71 73.2701 72.71C72.9111 72.71 72.6201 73.001 72.6201 73.36C72.6201 73.7189 72.9111 74.01 73.2701 74.01Z"
            fill="#142237"
          />
          <path
            d="M74.86 75.6098C75.2189 75.6098 75.51 75.3188 75.51 74.9598C75.51 74.6008 75.2189 74.3098 74.86 74.3098C74.501 74.3098 74.21 74.6008 74.21 74.9598C74.21 75.3188 74.501 75.6098 74.86 75.6098Z"
            fill="#142237"
          />
          <path
            d="M76.4498 77.1999C76.8088 77.1999 77.0998 76.9089 77.0998 76.5499C77.0998 76.1909 76.8088 75.8999 76.4498 75.8999C76.0908 75.8999 75.7998 76.1909 75.7998 76.5499C75.7998 76.9089 76.0908 77.1999 76.4498 77.1999Z"
            fill="#142237"
          />
          <path
            d="M78.0406 78.79C78.3996 78.79 78.6906 78.499 78.6906 78.14C78.6906 77.781 78.3996 77.49 78.0406 77.49C77.6816 77.49 77.3906 77.781 77.3906 78.14C77.3906 78.499 77.6816 78.79 78.0406 78.79Z"
            fill="#142237"
          />
          <path
            d="M79.6305 80.3798C79.9894 80.3798 80.2805 80.0888 80.2805 79.7298C80.2805 79.3709 79.9894 79.0798 79.6305 79.0798C79.2715 79.0798 78.9805 79.3709 78.9805 79.7298C78.9805 80.0888 79.2715 80.3798 79.6305 80.3798Z"
            fill="#142237"
          />
          <path
            d="M81.2203 81.9699C81.5793 81.9699 81.8703 81.6789 81.8703 81.3199C81.8703 80.9609 81.5793 80.6699 81.2203 80.6699C80.8613 80.6699 80.5703 80.9609 80.5703 81.3199C80.5703 81.6789 80.8613 81.9699 81.2203 81.9699Z"
            fill="#142237"
          />
          <path
            d="M82.8102 83.56C83.1691 83.56 83.4602 83.269 83.4602 82.91C83.4602 82.551 83.1691 82.26 82.8102 82.26C82.4512 82.26 82.1602 82.551 82.1602 82.91C82.1602 83.269 82.4512 83.56 82.8102 83.56Z"
            fill="#142237"
          />
          <path
            d="M60.5406 64.4699C60.8996 64.4699 61.1906 64.1789 61.1906 63.8199C61.1906 63.4609 60.8996 63.1699 60.5406 63.1699C60.1816 63.1699 59.8906 63.4609 59.8906 63.8199C59.8906 64.1789 60.1816 64.4699 60.5406 64.4699Z"
            fill="#142237"
          />
          <path
            d="M62.1305 66.06C62.4895 66.06 62.7805 65.769 62.7805 65.41C62.7805 65.051 62.4895 64.76 62.1305 64.76C61.7715 64.76 61.4805 65.051 61.4805 65.41C61.4805 65.769 61.7715 66.06 62.1305 66.06Z"
            fill="#142237"
          />
          <path
            d="M63.7203 67.6499C64.0793 67.6499 64.3703 67.3588 64.3703 66.9999C64.3703 66.6409 64.0793 66.3499 63.7203 66.3499C63.3613 66.3499 63.0703 66.6409 63.0703 66.9999C63.0703 67.3588 63.3613 67.6499 63.7203 67.6499Z"
            fill="#142237"
          />
          <path
            d="M65.3102 69.2399C65.6691 69.2399 65.9602 68.9489 65.9602 68.5899C65.9602 68.231 65.6691 67.9399 65.3102 67.9399C64.9512 67.9399 64.6602 68.231 64.6602 68.5899C64.6602 68.9489 64.9512 69.2399 65.3102 69.2399Z"
            fill="#142237"
          />
          <path
            d="M66.9 70.83C67.259 70.83 67.55 70.539 67.55 70.18C67.55 69.821 67.259 69.53 66.9 69.53C66.541 69.53 66.25 69.821 66.25 70.18C66.25 70.539 66.541 70.83 66.9 70.83Z"
            fill="#142237"
          />
          <path
            d="M68.5006 72.4199C68.8596 72.4199 69.1506 72.1289 69.1506 71.7699C69.1506 71.4109 68.8596 71.1199 68.5006 71.1199C68.1416 71.1199 67.8506 71.4109 67.8506 71.7699C67.8506 72.1289 68.1416 72.4199 68.5006 72.4199Z"
            fill="#142237"
          />
          <path
            d="M70.0904 74.01C70.4494 74.01 70.7404 73.7189 70.7404 73.36C70.7404 73.001 70.4494 72.71 70.0904 72.71C69.7314 72.71 69.4404 73.001 69.4404 73.36C69.4404 73.7189 69.7314 74.01 70.0904 74.01Z"
            fill="#142237"
          />
          <path
            d="M71.6803 75.6098C72.0393 75.6098 72.3303 75.3188 72.3303 74.9598C72.3303 74.6008 72.0393 74.3098 71.6803 74.3098C71.3213 74.3098 71.0303 74.6008 71.0303 74.9598C71.0303 75.3188 71.3213 75.6098 71.6803 75.6098Z"
            fill="#142237"
          />
          <path
            d="M73.2701 77.1999C73.6291 77.1999 73.9201 76.9089 73.9201 76.5499C73.9201 76.1909 73.6291 75.8999 73.2701 75.8999C72.9111 75.8999 72.6201 76.1909 72.6201 76.5499C72.6201 76.9089 72.9111 77.1999 73.2701 77.1999Z"
            fill="#142237"
          />
          <path
            d="M74.86 78.79C75.2189 78.79 75.51 78.499 75.51 78.14C75.51 77.781 75.2189 77.49 74.86 77.49C74.501 77.49 74.21 77.781 74.21 78.14C74.21 78.499 74.501 78.79 74.86 78.79Z"
            fill="#142237"
          />
          <path
            d="M76.4498 80.3798C76.8088 80.3798 77.0998 80.0888 77.0998 79.7298C77.0998 79.3709 76.8088 79.0798 76.4498 79.0798C76.0908 79.0798 75.7998 79.3709 75.7998 79.7298C75.7998 80.0888 76.0908 80.3798 76.4498 80.3798Z"
            fill="#142237"
          />
          <path
            d="M78.0406 81.9699C78.3996 81.9699 78.6906 81.6789 78.6906 81.3199C78.6906 80.9609 78.3996 80.6699 78.0406 80.6699C77.6816 80.6699 77.3906 80.9609 77.3906 81.3199C77.3906 81.6789 77.6816 81.9699 78.0406 81.9699Z"
            fill="#142237"
          />
          <path
            d="M79.6305 83.56C79.9894 83.56 80.2805 83.269 80.2805 82.91C80.2805 82.551 79.9894 82.26 79.6305 82.26C79.2715 82.26 78.9805 82.551 78.9805 82.91C78.9805 83.269 79.2715 83.56 79.6305 83.56Z"
            fill="#142237"
          />
          <path
            d="M81.2203 85.1499C81.5793 85.1499 81.8703 84.8588 81.8703 84.4999C81.8703 84.1409 81.5793 83.8499 81.2203 83.8499C80.8613 83.8499 80.5703 84.1409 80.5703 84.4999C80.5703 84.8588 80.8613 85.1499 81.2203 85.1499Z"
            fill="#142237"
          />
          <path
            d="M82.8102 86.7399C83.1691 86.7399 83.4602 86.4489 83.4602 86.0899C83.4602 85.731 83.1691 85.4399 82.8102 85.4399C82.4512 85.4399 82.1602 85.731 82.1602 86.0899C82.1602 86.4489 82.4512 86.7399 82.8102 86.7399Z"
            fill="#142237"
          />
          <path
            d="M57.36 64.4699C57.7189 64.4699 58.01 64.1789 58.01 63.8199C58.01 63.4609 57.7189 63.1699 57.36 63.1699C57.001 63.1699 56.71 63.4609 56.71 63.8199C56.71 64.1789 57.001 64.4699 57.36 64.4699Z"
            fill="#142237"
          />
          <path
            d="M58.9498 66.06C59.3088 66.06 59.5998 65.769 59.5998 65.41C59.5998 65.051 59.3088 64.76 58.9498 64.76C58.5908 64.76 58.2998 65.051 58.2998 65.41C58.2998 65.769 58.5908 66.06 58.9498 66.06Z"
            fill="#142237"
          />
          <path
            d="M60.5406 67.6499C60.8996 67.6499 61.1906 67.3588 61.1906 66.9999C61.1906 66.6409 60.8996 66.3499 60.5406 66.3499C60.1816 66.3499 59.8906 66.6409 59.8906 66.9999C59.8906 67.3588 60.1816 67.6499 60.5406 67.6499Z"
            fill="#142237"
          />
          <path
            d="M62.1305 69.2399C62.4895 69.2399 62.7805 68.9489 62.7805 68.5899C62.7805 68.231 62.4895 67.9399 62.1305 67.9399C61.7715 67.9399 61.4805 68.231 61.4805 68.5899C61.4805 68.9489 61.7715 69.2399 62.1305 69.2399Z"
            fill="#142237"
          />
          <path
            d="M63.7203 70.83C64.0793 70.83 64.3703 70.539 64.3703 70.18C64.3703 69.821 64.0793 69.53 63.7203 69.53C63.3613 69.53 63.0703 69.821 63.0703 70.18C63.0703 70.539 63.3613 70.83 63.7203 70.83Z"
            fill="#142237"
          />
          <path
            d="M65.3102 72.4199C65.6691 72.4199 65.9602 72.1289 65.9602 71.7699C65.9602 71.4109 65.6691 71.1199 65.3102 71.1199C64.9512 71.1199 64.6602 71.4109 64.6602 71.7699C64.6602 72.1289 64.9512 72.4199 65.3102 72.4199Z"
            fill="#142237"
          />
          <path
            d="M66.9 74.01C67.259 74.01 67.55 73.7189 67.55 73.36C67.55 73.001 67.259 72.71 66.9 72.71C66.541 72.71 66.25 73.001 66.25 73.36C66.25 73.7189 66.541 74.01 66.9 74.01Z"
            fill="#142237"
          />
          <path
            d="M68.5006 75.6098C68.8596 75.6098 69.1506 75.3188 69.1506 74.9598C69.1506 74.6008 68.8596 74.3098 68.5006 74.3098C68.1416 74.3098 67.8506 74.6008 67.8506 74.9598C67.8506 75.3188 68.1416 75.6098 68.5006 75.6098Z"
            fill="#142237"
          />
          <path
            d="M70.0904 77.1999C70.4494 77.1999 70.7404 76.9089 70.7404 76.5499C70.7404 76.1909 70.4494 75.8999 70.0904 75.8999C69.7314 75.8999 69.4404 76.1909 69.4404 76.5499C69.4404 76.9089 69.7314 77.1999 70.0904 77.1999Z"
            fill="#142237"
          />
          <path
            d="M71.6803 78.79C72.0393 78.79 72.3303 78.499 72.3303 78.14C72.3303 77.781 72.0393 77.49 71.6803 77.49C71.3213 77.49 71.0303 77.781 71.0303 78.14C71.0303 78.499 71.3213 78.79 71.6803 78.79Z"
            fill="#142237"
          />
          <path
            d="M73.2701 80.3798C73.6291 80.3798 73.9201 80.0888 73.9201 79.7298C73.9201 79.3709 73.6291 79.0798 73.2701 79.0798C72.9111 79.0798 72.6201 79.3709 72.6201 79.7298C72.6201 80.0888 72.9111 80.3798 73.2701 80.3798Z"
            fill="#142237"
          />
          <path
            d="M74.86 81.9699C75.2189 81.9699 75.51 81.6789 75.51 81.3199C75.51 80.9609 75.2189 80.6699 74.86 80.6699C74.501 80.6699 74.21 80.9609 74.21 81.3199C74.21 81.6789 74.501 81.9699 74.86 81.9699Z"
            fill="#142237"
          />
          <path
            d="M76.4498 83.56C76.8088 83.56 77.0998 83.269 77.0998 82.91C77.0998 82.551 76.8088 82.26 76.4498 82.26C76.0908 82.26 75.7998 82.551 75.7998 82.91C75.7998 83.269 76.0908 83.56 76.4498 83.56Z"
            fill="#142237"
          />
          <path
            d="M78.0406 85.1499C78.3996 85.1499 78.6906 84.8588 78.6906 84.4999C78.6906 84.1409 78.3996 83.8499 78.0406 83.8499C77.6816 83.8499 77.3906 84.1409 77.3906 84.4999C77.3906 84.8588 77.6816 85.1499 78.0406 85.1499Z"
            fill="#142237"
          />
          <path
            d="M79.6305 86.7399C79.9894 86.7399 80.2805 86.4489 80.2805 86.0899C80.2805 85.731 79.9894 85.4399 79.6305 85.4399C79.2715 85.4399 78.9805 85.731 78.9805 86.0899C78.9805 86.4489 79.2715 86.7399 79.6305 86.7399Z"
            fill="#142237"
          />
          <path
            d="M81.2203 88.33C81.5793 88.33 81.8703 88.039 81.8703 87.68C81.8703 87.321 81.5793 87.03 81.2203 87.03C80.8613 87.03 80.5703 87.321 80.5703 87.68C80.5703 88.039 80.8613 88.33 81.2203 88.33Z"
            fill="#142237"
          />
          <path
            d="M82.8102 89.9199C83.1691 89.9199 83.4602 89.6289 83.4602 89.2699C83.4602 88.9109 83.1691 88.6199 82.8102 88.6199C82.4512 88.6199 82.1602 88.9109 82.1602 89.2699C82.1602 89.6289 82.4512 89.9199 82.8102 89.9199Z"
            fill="#142237"
          />
          <path
            d="M54.1803 64.4699C54.5393 64.4699 54.8303 64.1789 54.8303 63.8199C54.8303 63.4609 54.5393 63.1699 54.1803 63.1699C53.8213 63.1699 53.5303 63.4609 53.5303 63.8199C53.5303 64.1789 53.8213 64.4699 54.1803 64.4699Z"
            fill="#142237"
          />
          <path
            d="M55.7701 66.06C56.1291 66.06 56.4201 65.769 56.4201 65.41C56.4201 65.051 56.1291 64.76 55.7701 64.76C55.4111 64.76 55.1201 65.051 55.1201 65.41C55.1201 65.769 55.4111 66.06 55.7701 66.06Z"
            fill="#142237"
          />
          <path
            d="M57.36 67.6499C57.7189 67.6499 58.01 67.3588 58.01 66.9999C58.01 66.6409 57.7189 66.3499 57.36 66.3499C57.001 66.3499 56.71 66.6409 56.71 66.9999C56.71 67.3588 57.001 67.6499 57.36 67.6499Z"
            fill="#142237"
          />
          <path
            d="M58.9498 69.2399C59.3088 69.2399 59.5998 68.9489 59.5998 68.5899C59.5998 68.231 59.3088 67.9399 58.9498 67.9399C58.5908 67.9399 58.2998 68.231 58.2998 68.5899C58.2998 68.9489 58.5908 69.2399 58.9498 69.2399Z"
            fill="#142237"
          />
          <path
            d="M60.5406 70.83C60.8996 70.83 61.1906 70.539 61.1906 70.18C61.1906 69.821 60.8996 69.53 60.5406 69.53C60.1816 69.53 59.8906 69.821 59.8906 70.18C59.8906 70.539 60.1816 70.83 60.5406 70.83Z"
            fill="#142237"
          />
          <path
            d="M62.1305 72.4199C62.4895 72.4199 62.7805 72.1289 62.7805 71.7699C62.7805 71.4109 62.4895 71.1199 62.1305 71.1199C61.7715 71.1199 61.4805 71.4109 61.4805 71.7699C61.4805 72.1289 61.7715 72.4199 62.1305 72.4199Z"
            fill="#142237"
          />
          <path
            d="M63.7203 74.01C64.0793 74.01 64.3703 73.7189 64.3703 73.36C64.3703 73.001 64.0793 72.71 63.7203 72.71C63.3613 72.71 63.0703 73.001 63.0703 73.36C63.0703 73.7189 63.3613 74.01 63.7203 74.01Z"
            fill="#142237"
          />
          <path
            d="M65.3102 75.6098C65.6691 75.6098 65.9602 75.3188 65.9602 74.9598C65.9602 74.6008 65.6691 74.3098 65.3102 74.3098C64.9512 74.3098 64.6602 74.6008 64.6602 74.9598C64.6602 75.3188 64.9512 75.6098 65.3102 75.6098Z"
            fill="#142237"
          />
          <path
            d="M66.9 77.1999C67.259 77.1999 67.55 76.9089 67.55 76.5499C67.55 76.1909 67.259 75.8999 66.9 75.8999C66.541 75.8999 66.25 76.1909 66.25 76.5499C66.25 76.9089 66.541 77.1999 66.9 77.1999Z"
            fill="#142237"
          />
          <path
            d="M68.5006 78.79C68.8596 78.79 69.1506 78.499 69.1506 78.14C69.1506 77.781 68.8596 77.49 68.5006 77.49C68.1416 77.49 67.8506 77.781 67.8506 78.14C67.8506 78.499 68.1416 78.79 68.5006 78.79Z"
            fill="#142237"
          />
          <path
            d="M70.0904 80.3798C70.4494 80.3798 70.7404 80.0888 70.7404 79.7298C70.7404 79.3709 70.4494 79.0798 70.0904 79.0798C69.7314 79.0798 69.4404 79.3709 69.4404 79.7298C69.4404 80.0888 69.7314 80.3798 70.0904 80.3798Z"
            fill="#142237"
          />
          <path
            d="M71.6803 81.9699C72.0393 81.9699 72.3303 81.6789 72.3303 81.3199C72.3303 80.9609 72.0393 80.6699 71.6803 80.6699C71.3213 80.6699 71.0303 80.9609 71.0303 81.3199C71.0303 81.6789 71.3213 81.9699 71.6803 81.9699Z"
            fill="#142237"
          />
          <path
            d="M73.2701 83.56C73.6291 83.56 73.9201 83.269 73.9201 82.91C73.9201 82.551 73.6291 82.26 73.2701 82.26C72.9111 82.26 72.6201 82.551 72.6201 82.91C72.6201 83.269 72.9111 83.56 73.2701 83.56Z"
            fill="#142237"
          />
          <path
            d="M74.86 85.1499C75.2189 85.1499 75.51 84.8588 75.51 84.4999C75.51 84.1409 75.2189 83.8499 74.86 83.8499C74.501 83.8499 74.21 84.1409 74.21 84.4999C74.21 84.8588 74.501 85.1499 74.86 85.1499Z"
            fill="#142237"
          />
          <path
            d="M76.4498 86.7399C76.8088 86.7399 77.0998 86.4489 77.0998 86.0899C77.0998 85.731 76.8088 85.4399 76.4498 85.4399C76.0908 85.4399 75.7998 85.731 75.7998 86.0899C75.7998 86.4489 76.0908 86.7399 76.4498 86.7399Z"
            fill="#142237"
          />
          <path
            d="M78.0406 88.33C78.3996 88.33 78.6906 88.039 78.6906 87.68C78.6906 87.321 78.3996 87.03 78.0406 87.03C77.6816 87.03 77.3906 87.321 77.3906 87.68C77.3906 88.039 77.6816 88.33 78.0406 88.33Z"
            fill="#142237"
          />
          <path
            d="M79.6305 89.9199C79.9894 89.9199 80.2805 89.6289 80.2805 89.2699C80.2805 88.9109 79.9894 88.6199 79.6305 88.6199C79.2715 88.6199 78.9805 88.9109 78.9805 89.2699C78.9805 89.6289 79.2715 89.9199 79.6305 89.9199Z"
            fill="#142237"
          />
          <path
            d="M81.2203 91.52C81.5793 91.52 81.8703 91.229 81.8703 90.87C81.8703 90.511 81.5793 90.22 81.2203 90.22C80.8613 90.22 80.5703 90.511 80.5703 90.87C80.5703 91.229 80.8613 91.52 81.2203 91.52Z"
            fill="#142237"
          />
          <path
            d="M82.8102 93.1098C83.1691 93.1098 83.4602 92.8188 83.4602 92.4598C83.4602 92.1008 83.1691 91.8098 82.8102 91.8098C82.4512 91.8098 82.1602 92.1008 82.1602 92.4598C82.1602 92.8188 82.4512 93.1098 82.8102 93.1098Z"
            fill="#142237"
          />
          <path
            d="M50.9898 64.4699C51.3488 64.4699 51.6398 64.1789 51.6398 63.8199C51.6398 63.4609 51.3488 63.1699 50.9898 63.1699C50.6309 63.1699 50.3398 63.4609 50.3398 63.8199C50.3398 64.1789 50.6309 64.4699 50.9898 64.4699Z"
            fill="#142237"
          />
          <path
            d="M52.5904 66.06C52.9494 66.06 53.2404 65.769 53.2404 65.41C53.2404 65.051 52.9494 64.76 52.5904 64.76C52.2314 64.76 51.9404 65.051 51.9404 65.41C51.9404 65.769 52.2314 66.06 52.5904 66.06Z"
            fill="#142237"
          />
          <path
            d="M54.1803 67.6499C54.5393 67.6499 54.8303 67.3588 54.8303 66.9999C54.8303 66.6409 54.5393 66.3499 54.1803 66.3499C53.8213 66.3499 53.5303 66.6409 53.5303 66.9999C53.5303 67.3588 53.8213 67.6499 54.1803 67.6499Z"
            fill="#142237"
          />
          <path
            d="M55.7701 69.2399C56.1291 69.2399 56.4201 68.9489 56.4201 68.5899C56.4201 68.231 56.1291 67.9399 55.7701 67.9399C55.4111 67.9399 55.1201 68.231 55.1201 68.5899C55.1201 68.9489 55.4111 69.2399 55.7701 69.2399Z"
            fill="#142237"
          />
          <path
            d="M57.36 70.83C57.7189 70.83 58.01 70.539 58.01 70.18C58.01 69.821 57.7189 69.53 57.36 69.53C57.001 69.53 56.71 69.821 56.71 70.18C56.71 70.539 57.001 70.83 57.36 70.83Z"
            fill="#142237"
          />
          <path
            d="M58.9498 72.4199C59.3088 72.4199 59.5998 72.1289 59.5998 71.7699C59.5998 71.4109 59.3088 71.1199 58.9498 71.1199C58.5908 71.1199 58.2998 71.4109 58.2998 71.7699C58.2998 72.1289 58.5908 72.4199 58.9498 72.4199Z"
            fill="#142237"
          />
          <path
            d="M60.5406 74.01C60.8996 74.01 61.1906 73.7189 61.1906 73.36C61.1906 73.001 60.8996 72.71 60.5406 72.71C60.1816 72.71 59.8906 73.001 59.8906 73.36C59.8906 73.7189 60.1816 74.01 60.5406 74.01Z"
            fill="#142237"
          />
          <path
            d="M62.1305 75.6098C62.4895 75.6098 62.7805 75.3188 62.7805 74.9598C62.7805 74.6008 62.4895 74.3098 62.1305 74.3098C61.7715 74.3098 61.4805 74.6008 61.4805 74.9598C61.4805 75.3188 61.7715 75.6098 62.1305 75.6098Z"
            fill="#142237"
          />
          <path
            d="M63.7203 77.1999C64.0793 77.1999 64.3703 76.9089 64.3703 76.5499C64.3703 76.1909 64.0793 75.8999 63.7203 75.8999C63.3613 75.8999 63.0703 76.1909 63.0703 76.5499C63.0703 76.9089 63.3613 77.1999 63.7203 77.1999Z"
            fill="#142237"
          />
          <path
            d="M65.3102 78.79C65.6691 78.79 65.9602 78.499 65.9602 78.14C65.9602 77.781 65.6691 77.49 65.3102 77.49C64.9512 77.49 64.6602 77.781 64.6602 78.14C64.6602 78.499 64.9512 78.79 65.3102 78.79Z"
            fill="#142237"
          />
          <path
            d="M66.9 80.3798C67.259 80.3798 67.55 80.0888 67.55 79.7298C67.55 79.3709 67.259 79.0798 66.9 79.0798C66.541 79.0798 66.25 79.3709 66.25 79.7298C66.25 80.0888 66.541 80.3798 66.9 80.3798Z"
            fill="#142237"
          />
          <path
            d="M68.5006 81.9699C68.8596 81.9699 69.1506 81.6789 69.1506 81.3199C69.1506 80.9609 68.8596 80.6699 68.5006 80.6699C68.1416 80.6699 67.8506 80.9609 67.8506 81.3199C67.8506 81.6789 68.1416 81.9699 68.5006 81.9699Z"
            fill="#142237"
          />
          <path
            d="M70.0904 83.56C70.4494 83.56 70.7404 83.269 70.7404 82.91C70.7404 82.551 70.4494 82.26 70.0904 82.26C69.7314 82.26 69.4404 82.551 69.4404 82.91C69.4404 83.269 69.7314 83.56 70.0904 83.56Z"
            fill="#142237"
          />
          <path
            d="M71.6803 85.1499C72.0393 85.1499 72.3303 84.8588 72.3303 84.4999C72.3303 84.1409 72.0393 83.8499 71.6803 83.8499C71.3213 83.8499 71.0303 84.1409 71.0303 84.4999C71.0303 84.8588 71.3213 85.1499 71.6803 85.1499Z"
            fill="#142237"
          />
          <path
            d="M73.2701 86.7399C73.6291 86.7399 73.9201 86.4489 73.9201 86.0899C73.9201 85.731 73.6291 85.4399 73.2701 85.4399C72.9111 85.4399 72.6201 85.731 72.6201 86.0899C72.6201 86.4489 72.9111 86.7399 73.2701 86.7399Z"
            fill="#142237"
          />
          <path
            d="M74.86 88.33C75.2189 88.33 75.51 88.039 75.51 87.68C75.51 87.321 75.2189 87.03 74.86 87.03C74.501 87.03 74.21 87.321 74.21 87.68C74.21 88.039 74.501 88.33 74.86 88.33Z"
            fill="#142237"
          />
          <path
            d="M76.4498 89.9199C76.8088 89.9199 77.0998 89.6289 77.0998 89.2699C77.0998 88.9109 76.8088 88.6199 76.4498 88.6199C76.0908 88.6199 75.7998 88.9109 75.7998 89.2699C75.7998 89.6289 76.0908 89.9199 76.4498 89.9199Z"
            fill="#142237"
          />
          <path
            d="M78.0406 91.52C78.3996 91.52 78.6906 91.229 78.6906 90.87C78.6906 90.511 78.3996 90.22 78.0406 90.22C77.6816 90.22 77.3906 90.511 77.3906 90.87C77.3906 91.229 77.6816 91.52 78.0406 91.52Z"
            fill="#142237"
          />
          <path
            d="M79.6305 93.1098C79.9894 93.1098 80.2805 92.8188 80.2805 92.4598C80.2805 92.1008 79.9894 91.8098 79.6305 91.8098C79.2715 91.8098 78.9805 92.1008 78.9805 92.4598C78.9805 92.8188 79.2715 93.1098 79.6305 93.1098Z"
            fill="#142237"
          />
          <path
            d="M81.2201 94.6899C81.5735 94.6899 81.8601 94.4034 81.8601 94.0499C81.8601 93.6964 81.5735 93.4099 81.2201 93.4099C80.8666 93.4099 80.5801 93.6964 80.5801 94.0499C80.5801 94.4034 80.8666 94.6899 81.2201 94.6899Z"
            fill="#142237"
          />
          <path
            d="M47.8102 64.4699C48.1691 64.4699 48.4602 64.1789 48.4602 63.8199C48.4602 63.4609 48.1691 63.1699 47.8102 63.1699C47.4512 63.1699 47.1602 63.4609 47.1602 63.8199C47.1602 64.1789 47.4512 64.4699 47.8102 64.4699Z"
            fill="#142237"
          />
          <path
            d="M49.4 66.06C49.759 66.06 50.05 65.769 50.05 65.41C50.05 65.051 49.759 64.76 49.4 64.76C49.041 64.76 48.75 65.051 48.75 65.41C48.75 65.769 49.041 66.06 49.4 66.06Z"
            fill="#142237"
          />
          <path
            d="M50.9898 67.6499C51.3488 67.6499 51.6398 67.3588 51.6398 66.9999C51.6398 66.6409 51.3488 66.3499 50.9898 66.3499C50.6309 66.3499 50.3398 66.6409 50.3398 66.9999C50.3398 67.3588 50.6309 67.6499 50.9898 67.6499Z"
            fill="#142237"
          />
          <path
            d="M52.5904 69.2399C52.9494 69.2399 53.2404 68.9489 53.2404 68.5899C53.2404 68.231 52.9494 67.9399 52.5904 67.9399C52.2314 67.9399 51.9404 68.231 51.9404 68.5899C51.9404 68.9489 52.2314 69.2399 52.5904 69.2399Z"
            fill="#142237"
          />
          <path
            d="M54.1803 70.83C54.5393 70.83 54.8303 70.539 54.8303 70.18C54.8303 69.821 54.5393 69.53 54.1803 69.53C53.8213 69.53 53.5303 69.821 53.5303 70.18C53.5303 70.539 53.8213 70.83 54.1803 70.83Z"
            fill="#142237"
          />
          <path
            d="M55.7701 72.4199C56.1291 72.4199 56.4201 72.1289 56.4201 71.7699C56.4201 71.4109 56.1291 71.1199 55.7701 71.1199C55.4111 71.1199 55.1201 71.4109 55.1201 71.7699C55.1201 72.1289 55.4111 72.4199 55.7701 72.4199Z"
            fill="#142237"
          />
          <path
            d="M57.36 74.01C57.7189 74.01 58.01 73.7189 58.01 73.36C58.01 73.001 57.7189 72.71 57.36 72.71C57.001 72.71 56.71 73.001 56.71 73.36C56.71 73.7189 57.001 74.01 57.36 74.01Z"
            fill="#142237"
          />
          <path
            d="M58.9498 75.6098C59.3088 75.6098 59.5998 75.3188 59.5998 74.9598C59.5998 74.6008 59.3088 74.3098 58.9498 74.3098C58.5908 74.3098 58.2998 74.6008 58.2998 74.9598C58.2998 75.3188 58.5908 75.6098 58.9498 75.6098Z"
            fill="#142237"
          />
          <path
            d="M60.5406 77.1999C60.8996 77.1999 61.1906 76.9089 61.1906 76.5499C61.1906 76.1909 60.8996 75.8999 60.5406 75.8999C60.1816 75.8999 59.8906 76.1909 59.8906 76.5499C59.8906 76.9089 60.1816 77.1999 60.5406 77.1999Z"
            fill="#142237"
          />
          <path
            d="M62.1305 78.79C62.4895 78.79 62.7805 78.499 62.7805 78.14C62.7805 77.781 62.4895 77.49 62.1305 77.49C61.7715 77.49 61.4805 77.781 61.4805 78.14C61.4805 78.499 61.7715 78.79 62.1305 78.79Z"
            fill="#142237"
          />
          <path
            d="M63.7203 80.3798C64.0793 80.3798 64.3703 80.0888 64.3703 79.7298C64.3703 79.3709 64.0793 79.0798 63.7203 79.0798C63.3613 79.0798 63.0703 79.3709 63.0703 79.7298C63.0703 80.0888 63.3613 80.3798 63.7203 80.3798Z"
            fill="#142237"
          />
          <path
            d="M65.3102 81.9699C65.6691 81.9699 65.9602 81.6789 65.9602 81.3199C65.9602 80.9609 65.6691 80.6699 65.3102 80.6699C64.9512 80.6699 64.6602 80.9609 64.6602 81.3199C64.6602 81.6789 64.9512 81.9699 65.3102 81.9699Z"
            fill="#142237"
          />
          <path
            d="M66.9 83.56C67.259 83.56 67.55 83.269 67.55 82.91C67.55 82.551 67.259 82.26 66.9 82.26C66.541 82.26 66.25 82.551 66.25 82.91C66.25 83.269 66.541 83.56 66.9 83.56Z"
            fill="#142237"
          />
          <path
            d="M68.5006 85.1499C68.8596 85.1499 69.1506 84.8588 69.1506 84.4999C69.1506 84.1409 68.8596 83.8499 68.5006 83.8499C68.1416 83.8499 67.8506 84.1409 67.8506 84.4999C67.8506 84.8588 68.1416 85.1499 68.5006 85.1499Z"
            fill="#142237"
          />
          <path
            d="M70.0904 86.7399C70.4494 86.7399 70.7404 86.4489 70.7404 86.0899C70.7404 85.731 70.4494 85.4399 70.0904 85.4399C69.7314 85.4399 69.4404 85.731 69.4404 86.0899C69.4404 86.4489 69.7314 86.7399 70.0904 86.7399Z"
            fill="#142237"
          />
          <path
            d="M71.6803 88.33C72.0393 88.33 72.3303 88.039 72.3303 87.68C72.3303 87.321 72.0393 87.03 71.6803 87.03C71.3213 87.03 71.0303 87.321 71.0303 87.68C71.0303 88.039 71.3213 88.33 71.6803 88.33Z"
            fill="#142237"
          />
          <path
            d="M73.2701 89.9199C73.6291 89.9199 73.9201 89.6289 73.9201 89.2699C73.9201 88.9109 73.6291 88.6199 73.2701 88.6199C72.9111 88.6199 72.6201 88.9109 72.6201 89.2699C72.6201 89.6289 72.9111 89.9199 73.2701 89.9199Z"
            fill="#142237"
          />
          <path
            d="M74.86 91.52C75.2189 91.52 75.51 91.229 75.51 90.87C75.51 90.511 75.2189 90.22 74.86 90.22C74.501 90.22 74.21 90.511 74.21 90.87C74.21 91.229 74.501 91.52 74.86 91.52Z"
            fill="#142237"
          />
          <path
            d="M76.4498 93.1098C76.8088 93.1098 77.0998 92.8188 77.0998 92.4598C77.0998 92.1008 76.8088 91.8098 76.4498 91.8098C76.0908 91.8098 75.7998 92.1008 75.7998 92.4598C75.7998 92.8188 76.0908 93.1098 76.4498 93.1098Z"
            fill="#142237"
          />
          <path
            d="M78.0404 94.6899C78.3939 94.6899 78.6804 94.4034 78.6804 94.0499C78.6804 93.6964 78.3939 93.4099 78.0404 93.4099C77.6869 93.4099 77.4004 93.6964 77.4004 94.0499C77.4004 94.4034 77.6869 94.6899 78.0404 94.6899Z"
            fill="#142237"
          />
          <path
            d="M44.6305 64.4699C44.9895 64.4699 45.2805 64.1789 45.2805 63.8199C45.2805 63.4609 44.9895 63.1699 44.6305 63.1699C44.2715 63.1699 43.9805 63.4609 43.9805 63.8199C43.9805 64.1789 44.2715 64.4699 44.6305 64.4699Z"
            fill="#142237"
          />
          <path
            d="M46.2203 66.06C46.5793 66.06 46.8703 65.769 46.8703 65.41C46.8703 65.051 46.5793 64.76 46.2203 64.76C45.8613 64.76 45.5703 65.051 45.5703 65.41C45.5703 65.769 45.8613 66.06 46.2203 66.06Z"
            fill="#142237"
          />
          <path
            d="M47.8102 67.6499C48.1691 67.6499 48.4602 67.3588 48.4602 66.9999C48.4602 66.6409 48.1691 66.3499 47.8102 66.3499C47.4512 66.3499 47.1602 66.6409 47.1602 66.9999C47.1602 67.3588 47.4512 67.6499 47.8102 67.6499Z"
            fill="#142237"
          />
          <path
            d="M49.4 69.2399C49.759 69.2399 50.05 68.9489 50.05 68.5899C50.05 68.231 49.759 67.9399 49.4 67.9399C49.041 67.9399 48.75 68.231 48.75 68.5899C48.75 68.9489 49.041 69.2399 49.4 69.2399Z"
            fill="#142237"
          />
          <path
            d="M50.9898 70.83C51.3488 70.83 51.6398 70.539 51.6398 70.18C51.6398 69.821 51.3488 69.53 50.9898 69.53C50.6309 69.53 50.3398 69.821 50.3398 70.18C50.3398 70.539 50.6309 70.83 50.9898 70.83Z"
            fill="#142237"
          />
          <path
            d="M52.5904 72.4199C52.9494 72.4199 53.2404 72.1289 53.2404 71.7699C53.2404 71.4109 52.9494 71.1199 52.5904 71.1199C52.2314 71.1199 51.9404 71.4109 51.9404 71.7699C51.9404 72.1289 52.2314 72.4199 52.5904 72.4199Z"
            fill="#142237"
          />
          <path
            d="M54.1803 74.01C54.5393 74.01 54.8303 73.7189 54.8303 73.36C54.8303 73.001 54.5393 72.71 54.1803 72.71C53.8213 72.71 53.5303 73.001 53.5303 73.36C53.5303 73.7189 53.8213 74.01 54.1803 74.01Z"
            fill="#142237"
          />
          <path
            d="M55.7701 75.6098C56.1291 75.6098 56.4201 75.3188 56.4201 74.9598C56.4201 74.6008 56.1291 74.3098 55.7701 74.3098C55.4111 74.3098 55.1201 74.6008 55.1201 74.9598C55.1201 75.3188 55.4111 75.6098 55.7701 75.6098Z"
            fill="#142237"
          />
          <path
            d="M57.36 77.1999C57.7189 77.1999 58.01 76.9089 58.01 76.5499C58.01 76.1909 57.7189 75.8999 57.36 75.8999C57.001 75.8999 56.71 76.1909 56.71 76.5499C56.71 76.9089 57.001 77.1999 57.36 77.1999Z"
            fill="#142237"
          />
          <path
            d="M58.9498 78.79C59.3088 78.79 59.5998 78.499 59.5998 78.14C59.5998 77.781 59.3088 77.49 58.9498 77.49C58.5908 77.49 58.2998 77.781 58.2998 78.14C58.2998 78.499 58.5908 78.79 58.9498 78.79Z"
            fill="#142237"
          />
          <path
            d="M60.5406 80.3798C60.8996 80.3798 61.1906 80.0888 61.1906 79.7298C61.1906 79.3709 60.8996 79.0798 60.5406 79.0798C60.1816 79.0798 59.8906 79.3709 59.8906 79.7298C59.8906 80.0888 60.1816 80.3798 60.5406 80.3798Z"
            fill="#142237"
          />
          <path
            d="M62.1305 81.9699C62.4895 81.9699 62.7805 81.6789 62.7805 81.3199C62.7805 80.9609 62.4895 80.6699 62.1305 80.6699C61.7715 80.6699 61.4805 80.9609 61.4805 81.3199C61.4805 81.6789 61.7715 81.9699 62.1305 81.9699Z"
            fill="#142237"
          />
          <path
            d="M63.7203 83.56C64.0793 83.56 64.3703 83.269 64.3703 82.91C64.3703 82.551 64.0793 82.26 63.7203 82.26C63.3613 82.26 63.0703 82.551 63.0703 82.91C63.0703 83.269 63.3613 83.56 63.7203 83.56Z"
            fill="#142237"
          />
          <path
            d="M65.3102 85.1499C65.6691 85.1499 65.9602 84.8588 65.9602 84.4999C65.9602 84.1409 65.6691 83.8499 65.3102 83.8499C64.9512 83.8499 64.6602 84.1409 64.6602 84.4999C64.6602 84.8588 64.9512 85.1499 65.3102 85.1499Z"
            fill="#142237"
          />
          <path
            d="M66.9 86.7399C67.259 86.7399 67.55 86.4489 67.55 86.0899C67.55 85.731 67.259 85.4399 66.9 85.4399C66.541 85.4399 66.25 85.731 66.25 86.0899C66.25 86.4489 66.541 86.7399 66.9 86.7399Z"
            fill="#142237"
          />
          <path
            d="M68.5006 88.33C68.8596 88.33 69.1506 88.039 69.1506 87.68C69.1506 87.321 68.8596 87.03 68.5006 87.03C68.1416 87.03 67.8506 87.321 67.8506 87.68C67.8506 88.039 68.1416 88.33 68.5006 88.33Z"
            fill="#142237"
          />
          <path
            d="M70.0904 89.9199C70.4494 89.9199 70.7404 89.6289 70.7404 89.2699C70.7404 88.9109 70.4494 88.6199 70.0904 88.6199C69.7314 88.6199 69.4404 88.9109 69.4404 89.2699C69.4404 89.6289 69.7314 89.9199 70.0904 89.9199Z"
            fill="#142237"
          />
          <path
            d="M71.6803 91.52C72.0393 91.52 72.3303 91.229 72.3303 90.87C72.3303 90.511 72.0393 90.22 71.6803 90.22C71.3213 90.22 71.0303 90.511 71.0303 90.87C71.0303 91.229 71.3213 91.52 71.6803 91.52Z"
            fill="#142237"
          />
          <path
            d="M73.2701 93.1098C73.6291 93.1098 73.9201 92.8188 73.9201 92.4598C73.9201 92.1008 73.6291 91.8098 73.2701 91.8098C72.9111 91.8098 72.6201 92.1008 72.6201 92.4598C72.6201 92.8188 72.9111 93.1098 73.2701 93.1098Z"
            fill="#142237"
          />
          <path
            d="M74.8607 94.6899C75.2142 94.6899 75.5007 94.4034 75.5007 94.0499C75.5007 93.6964 75.2142 93.4099 74.8607 93.4099C74.5072 93.4099 74.2207 93.6964 74.2207 94.0499C74.2207 94.4034 74.5072 94.6899 74.8607 94.6899Z"
            fill="#142237"
          />
          <path
            d="M41.4498 64.4699C41.8088 64.4699 42.0998 64.1789 42.0998 63.8199C42.0998 63.4609 41.8088 63.1699 41.4498 63.1699C41.0908 63.1699 40.7998 63.4609 40.7998 63.8199C40.7998 64.1789 41.0908 64.4699 41.4498 64.4699Z"
            fill="#142237"
          />
          <path
            d="M43.0406 66.06C43.3996 66.06 43.6906 65.769 43.6906 65.41C43.6906 65.051 43.3996 64.76 43.0406 64.76C42.6816 64.76 42.3906 65.051 42.3906 65.41C42.3906 65.769 42.6816 66.06 43.0406 66.06Z"
            fill="#142237"
          />
          <path
            d="M44.6305 67.6499C44.9895 67.6499 45.2805 67.3588 45.2805 66.9999C45.2805 66.6409 44.9895 66.3499 44.6305 66.3499C44.2715 66.3499 43.9805 66.6409 43.9805 66.9999C43.9805 67.3588 44.2715 67.6499 44.6305 67.6499Z"
            fill="#142237"
          />
          <path
            d="M46.2203 69.2399C46.5793 69.2399 46.8703 68.9489 46.8703 68.5899C46.8703 68.231 46.5793 67.9399 46.2203 67.9399C45.8613 67.9399 45.5703 68.231 45.5703 68.5899C45.5703 68.9489 45.8613 69.2399 46.2203 69.2399Z"
            fill="#142237"
          />
          <path
            d="M47.8102 70.83C48.1691 70.83 48.4602 70.539 48.4602 70.18C48.4602 69.821 48.1691 69.53 47.8102 69.53C47.4512 69.53 47.1602 69.821 47.1602 70.18C47.1602 70.539 47.4512 70.83 47.8102 70.83Z"
            fill="#142237"
          />
          <path
            d="M49.4 72.4199C49.759 72.4199 50.05 72.1289 50.05 71.7699C50.05 71.4109 49.759 71.1199 49.4 71.1199C49.041 71.1199 48.75 71.4109 48.75 71.7699C48.75 72.1289 49.041 72.4199 49.4 72.4199Z"
            fill="#142237"
          />
          <path
            d="M50.9898 74.01C51.3488 74.01 51.6398 73.7189 51.6398 73.36C51.6398 73.001 51.3488 72.71 50.9898 72.71C50.6309 72.71 50.3398 73.001 50.3398 73.36C50.3398 73.7189 50.6309 74.01 50.9898 74.01Z"
            fill="#142237"
          />
          <path
            d="M52.5904 75.6098C52.9494 75.6098 53.2404 75.3188 53.2404 74.9598C53.2404 74.6008 52.9494 74.3098 52.5904 74.3098C52.2314 74.3098 51.9404 74.6008 51.9404 74.9598C51.9404 75.3188 52.2314 75.6098 52.5904 75.6098Z"
            fill="#142237"
          />
          <path
            d="M54.1803 77.1999C54.5393 77.1999 54.8303 76.9089 54.8303 76.5499C54.8303 76.1909 54.5393 75.8999 54.1803 75.8999C53.8213 75.8999 53.5303 76.1909 53.5303 76.5499C53.5303 76.9089 53.8213 77.1999 54.1803 77.1999Z"
            fill="#142237"
          />
          <path
            d="M55.7701 78.79C56.1291 78.79 56.4201 78.499 56.4201 78.14C56.4201 77.781 56.1291 77.49 55.7701 77.49C55.4111 77.49 55.1201 77.781 55.1201 78.14C55.1201 78.499 55.4111 78.79 55.7701 78.79Z"
            fill="#142237"
          />
          <path
            d="M57.36 80.3798C57.7189 80.3798 58.01 80.0888 58.01 79.7298C58.01 79.3709 57.7189 79.0798 57.36 79.0798C57.001 79.0798 56.71 79.3709 56.71 79.7298C56.71 80.0888 57.001 80.3798 57.36 80.3798Z"
            fill="#142237"
          />
          <path
            d="M58.9498 81.9699C59.3088 81.9699 59.5998 81.6789 59.5998 81.3199C59.5998 80.9609 59.3088 80.6699 58.9498 80.6699C58.5908 80.6699 58.2998 80.9609 58.2998 81.3199C58.2998 81.6789 58.5908 81.9699 58.9498 81.9699Z"
            fill="#142237"
          />
          <path
            d="M60.5406 83.56C60.8996 83.56 61.1906 83.269 61.1906 82.91C61.1906 82.551 60.8996 82.26 60.5406 82.26C60.1816 82.26 59.8906 82.551 59.8906 82.91C59.8906 83.269 60.1816 83.56 60.5406 83.56Z"
            fill="#142237"
          />
          <path
            d="M62.1305 85.1499C62.4895 85.1499 62.7805 84.8588 62.7805 84.4999C62.7805 84.1409 62.4895 83.8499 62.1305 83.8499C61.7715 83.8499 61.4805 84.1409 61.4805 84.4999C61.4805 84.8588 61.7715 85.1499 62.1305 85.1499Z"
            fill="#142237"
          />
          <path
            d="M63.7203 86.7399C64.0793 86.7399 64.3703 86.4489 64.3703 86.0899C64.3703 85.731 64.0793 85.4399 63.7203 85.4399C63.3613 85.4399 63.0703 85.731 63.0703 86.0899C63.0703 86.4489 63.3613 86.7399 63.7203 86.7399Z"
            fill="#142237"
          />
          <path
            d="M65.3102 88.33C65.6691 88.33 65.9602 88.039 65.9602 87.68C65.9602 87.321 65.6691 87.03 65.3102 87.03C64.9512 87.03 64.6602 87.321 64.6602 87.68C64.6602 88.039 64.9512 88.33 65.3102 88.33Z"
            fill="#142237"
          />
          <path
            d="M66.9 89.9199C67.259 89.9199 67.55 89.6289 67.55 89.2699C67.55 88.9109 67.259 88.6199 66.9 88.6199C66.541 88.6199 66.25 88.9109 66.25 89.2699C66.25 89.6289 66.541 89.9199 66.9 89.9199Z"
            fill="#142237"
          />
          <path
            d="M68.5006 91.52C68.8596 91.52 69.1506 91.229 69.1506 90.87C69.1506 90.511 68.8596 90.22 68.5006 90.22C68.1416 90.22 67.8506 90.511 67.8506 90.87C67.8506 91.229 68.1416 91.52 68.5006 91.52Z"
            fill="#142237"
          />
          <path
            d="M70.0904 93.1098C70.4494 93.1098 70.7404 92.8188 70.7404 92.4598C70.7404 92.1008 70.4494 91.8098 70.0904 91.8098C69.7314 91.8098 69.4404 92.1008 69.4404 92.4598C69.4404 92.8188 69.7314 93.1098 70.0904 93.1098Z"
            fill="#142237"
          />
          <path
            d="M71.68 94.6899C72.0335 94.6899 72.32 94.4034 72.32 94.0499C72.32 93.6964 72.0335 93.4099 71.68 93.4099C71.3266 93.4099 71.04 93.6964 71.04 94.0499C71.04 94.4034 71.3266 94.6899 71.68 94.6899Z"
            fill="#142237"
          />
          <path
            d="M38.2701 64.4699C38.6291 64.4699 38.9201 64.1789 38.9201 63.8199C38.9201 63.4609 38.6291 63.1699 38.2701 63.1699C37.9111 63.1699 37.6201 63.4609 37.6201 63.8199C37.6201 64.1789 37.9111 64.4699 38.2701 64.4699Z"
            fill="#142237"
          />
          <path
            d="M39.86 66.06C40.2189 66.06 40.51 65.769 40.51 65.41C40.51 65.051 40.2189 64.76 39.86 64.76C39.501 64.76 39.21 65.051 39.21 65.41C39.21 65.769 39.501 66.06 39.86 66.06Z"
            fill="#142237"
          />
          <path
            d="M41.4498 67.6499C41.8088 67.6499 42.0998 67.3588 42.0998 66.9999C42.0998 66.6409 41.8088 66.3499 41.4498 66.3499C41.0908 66.3499 40.7998 66.6409 40.7998 66.9999C40.7998 67.3588 41.0908 67.6499 41.4498 67.6499Z"
            fill="#142237"
          />
          <path
            d="M43.0406 69.2399C43.3996 69.2399 43.6906 68.9489 43.6906 68.5899C43.6906 68.231 43.3996 67.9399 43.0406 67.9399C42.6816 67.9399 42.3906 68.231 42.3906 68.5899C42.3906 68.9489 42.6816 69.2399 43.0406 69.2399Z"
            fill="#142237"
          />
          <path
            d="M44.6305 70.83C44.9895 70.83 45.2805 70.539 45.2805 70.18C45.2805 69.821 44.9895 69.53 44.6305 69.53C44.2715 69.53 43.9805 69.821 43.9805 70.18C43.9805 70.539 44.2715 70.83 44.6305 70.83Z"
            fill="#142237"
          />
          <path
            d="M46.2203 72.4199C46.5793 72.4199 46.8703 72.1289 46.8703 71.7699C46.8703 71.4109 46.5793 71.1199 46.2203 71.1199C45.8613 71.1199 45.5703 71.4109 45.5703 71.7699C45.5703 72.1289 45.8613 72.4199 46.2203 72.4199Z"
            fill="#142237"
          />
          <path
            d="M47.8102 74.01C48.1691 74.01 48.4602 73.7189 48.4602 73.36C48.4602 73.001 48.1691 72.71 47.8102 72.71C47.4512 72.71 47.1602 73.001 47.1602 73.36C47.1602 73.7189 47.4512 74.01 47.8102 74.01Z"
            fill="#142237"
          />
          <path
            d="M49.4 75.6098C49.759 75.6098 50.05 75.3188 50.05 74.9598C50.05 74.6008 49.759 74.3098 49.4 74.3098C49.041 74.3098 48.75 74.6008 48.75 74.9598C48.75 75.3188 49.041 75.6098 49.4 75.6098Z"
            fill="#142237"
          />
          <path
            d="M50.9898 77.1999C51.3488 77.1999 51.6398 76.9089 51.6398 76.5499C51.6398 76.1909 51.3488 75.8999 50.9898 75.8999C50.6309 75.8999 50.3398 76.1909 50.3398 76.5499C50.3398 76.9089 50.6309 77.1999 50.9898 77.1999Z"
            fill="#142237"
          />
          <path
            d="M52.5904 78.79C52.9494 78.79 53.2404 78.499 53.2404 78.14C53.2404 77.781 52.9494 77.49 52.5904 77.49C52.2314 77.49 51.9404 77.781 51.9404 78.14C51.9404 78.499 52.2314 78.79 52.5904 78.79Z"
            fill="#142237"
          />
          <path
            d="M54.1803 80.3798C54.5393 80.3798 54.8303 80.0888 54.8303 79.7298C54.8303 79.3709 54.5393 79.0798 54.1803 79.0798C53.8213 79.0798 53.5303 79.3709 53.5303 79.7298C53.5303 80.0888 53.8213 80.3798 54.1803 80.3798Z"
            fill="#142237"
          />
          <path
            d="M55.7701 81.9699C56.1291 81.9699 56.4201 81.6789 56.4201 81.3199C56.4201 80.9609 56.1291 80.6699 55.7701 80.6699C55.4111 80.6699 55.1201 80.9609 55.1201 81.3199C55.1201 81.6789 55.4111 81.9699 55.7701 81.9699Z"
            fill="#142237"
          />
          <path
            d="M57.36 83.56C57.7189 83.56 58.01 83.269 58.01 82.91C58.01 82.551 57.7189 82.26 57.36 82.26C57.001 82.26 56.71 82.551 56.71 82.91C56.71 83.269 57.001 83.56 57.36 83.56Z"
            fill="#142237"
          />
          <path
            d="M58.9498 85.1499C59.3088 85.1499 59.5998 84.8588 59.5998 84.4999C59.5998 84.1409 59.3088 83.8499 58.9498 83.8499C58.5908 83.8499 58.2998 84.1409 58.2998 84.4999C58.2998 84.8588 58.5908 85.1499 58.9498 85.1499Z"
            fill="#142237"
          />
          <path
            d="M60.5406 86.7399C60.8996 86.7399 61.1906 86.4489 61.1906 86.0899C61.1906 85.731 60.8996 85.4399 60.5406 85.4399C60.1816 85.4399 59.8906 85.731 59.8906 86.0899C59.8906 86.4489 60.1816 86.7399 60.5406 86.7399Z"
            fill="#142237"
          />
          <path
            d="M62.1305 88.33C62.4895 88.33 62.7805 88.039 62.7805 87.68C62.7805 87.321 62.4895 87.03 62.1305 87.03C61.7715 87.03 61.4805 87.321 61.4805 87.68C61.4805 88.039 61.7715 88.33 62.1305 88.33Z"
            fill="#142237"
          />
          <path
            d="M63.7203 89.9199C64.0793 89.9199 64.3703 89.6289 64.3703 89.2699C64.3703 88.9109 64.0793 88.6199 63.7203 88.6199C63.3613 88.6199 63.0703 88.9109 63.0703 89.2699C63.0703 89.6289 63.3613 89.9199 63.7203 89.9199Z"
            fill="#142237"
          />
          <path
            d="M65.3102 91.52C65.6691 91.52 65.9602 91.229 65.9602 90.87C65.9602 90.511 65.6691 90.22 65.3102 90.22C64.9512 90.22 64.6602 90.511 64.6602 90.87C64.6602 91.229 64.9512 91.52 65.3102 91.52Z"
            fill="#142237"
          />
          <path
            d="M66.9 93.1098C67.259 93.1098 67.55 92.8188 67.55 92.4598C67.55 92.1008 67.259 91.8098 66.9 91.8098C66.541 91.8098 66.25 92.1008 66.25 92.4598C66.25 92.8188 66.541 93.1098 66.9 93.1098Z"
            fill="#142237"
          />
          <path
            d="M68.5004 94.6899C68.8538 94.6899 69.1404 94.4034 69.1404 94.0499C69.1404 93.6964 68.8538 93.4099 68.5004 93.4099C68.1469 93.4099 67.8604 93.6964 67.8604 94.0499C67.8604 94.4034 68.1469 94.6899 68.5004 94.6899Z"
            fill="#142237"
          />
          <path
            d="M36.6803 66.06C37.0393 66.06 37.3303 65.769 37.3303 65.41C37.3303 65.051 37.0393 64.76 36.6803 64.76C36.3213 64.76 36.0303 65.051 36.0303 65.41C36.0303 65.769 36.3213 66.06 36.6803 66.06Z"
            fill="#142237"
          />
          <path
            d="M38.2701 67.6499C38.6291 67.6499 38.9201 67.3588 38.9201 66.9999C38.9201 66.6409 38.6291 66.3499 38.2701 66.3499C37.9111 66.3499 37.6201 66.6409 37.6201 66.9999C37.6201 67.3588 37.9111 67.6499 38.2701 67.6499Z"
            fill="#142237"
          />
          <path
            d="M39.86 69.2399C40.2189 69.2399 40.51 68.9489 40.51 68.5899C40.51 68.231 40.2189 67.9399 39.86 67.9399C39.501 67.9399 39.21 68.231 39.21 68.5899C39.21 68.9489 39.501 69.2399 39.86 69.2399Z"
            fill="#142237"
          />
          <path
            d="M41.4498 70.83C41.8088 70.83 42.0998 70.539 42.0998 70.18C42.0998 69.821 41.8088 69.53 41.4498 69.53C41.0908 69.53 40.7998 69.821 40.7998 70.18C40.7998 70.539 41.0908 70.83 41.4498 70.83Z"
            fill="#142237"
          />
          <path
            d="M43.0406 72.4199C43.3996 72.4199 43.6906 72.1289 43.6906 71.7699C43.6906 71.4109 43.3996 71.1199 43.0406 71.1199C42.6816 71.1199 42.3906 71.4109 42.3906 71.7699C42.3906 72.1289 42.6816 72.4199 43.0406 72.4199Z"
            fill="#142237"
          />
          <path
            d="M44.6305 74.01C44.9895 74.01 45.2805 73.7189 45.2805 73.36C45.2805 73.001 44.9895 72.71 44.6305 72.71C44.2715 72.71 43.9805 73.001 43.9805 73.36C43.9805 73.7189 44.2715 74.01 44.6305 74.01Z"
            fill="#142237"
          />
          <path
            d="M46.2203 75.6098C46.5793 75.6098 46.8703 75.3188 46.8703 74.9598C46.8703 74.6008 46.5793 74.3098 46.2203 74.3098C45.8613 74.3098 45.5703 74.6008 45.5703 74.9598C45.5703 75.3188 45.8613 75.6098 46.2203 75.6098Z"
            fill="#142237"
          />
          <path
            d="M47.8102 77.1999C48.1691 77.1999 48.4602 76.9089 48.4602 76.5499C48.4602 76.1909 48.1691 75.8999 47.8102 75.8999C47.4512 75.8999 47.1602 76.1909 47.1602 76.5499C47.1602 76.9089 47.4512 77.1999 47.8102 77.1999Z"
            fill="#142237"
          />
          <path
            d="M49.4 78.79C49.759 78.79 50.05 78.499 50.05 78.14C50.05 77.781 49.759 77.49 49.4 77.49C49.041 77.49 48.75 77.781 48.75 78.14C48.75 78.499 49.041 78.79 49.4 78.79Z"
            fill="#142237"
          />
          <path
            d="M50.9898 80.3798C51.3488 80.3798 51.6398 80.0888 51.6398 79.7298C51.6398 79.3709 51.3488 79.0798 50.9898 79.0798C50.6309 79.0798 50.3398 79.3709 50.3398 79.7298C50.3398 80.0888 50.6309 80.3798 50.9898 80.3798Z"
            fill="#142237"
          />
          <path
            d="M52.5904 81.9699C52.9494 81.9699 53.2404 81.6789 53.2404 81.3199C53.2404 80.9609 52.9494 80.6699 52.5904 80.6699C52.2314 80.6699 51.9404 80.9609 51.9404 81.3199C51.9404 81.6789 52.2314 81.9699 52.5904 81.9699Z"
            fill="#142237"
          />
          <path
            d="M54.1803 83.56C54.5393 83.56 54.8303 83.269 54.8303 82.91C54.8303 82.551 54.5393 82.26 54.1803 82.26C53.8213 82.26 53.5303 82.551 53.5303 82.91C53.5303 83.269 53.8213 83.56 54.1803 83.56Z"
            fill="#142237"
          />
          <path
            d="M55.7701 85.1499C56.1291 85.1499 56.4201 84.8588 56.4201 84.4999C56.4201 84.1409 56.1291 83.8499 55.7701 83.8499C55.4111 83.8499 55.1201 84.1409 55.1201 84.4999C55.1201 84.8588 55.4111 85.1499 55.7701 85.1499Z"
            fill="#142237"
          />
          <path
            d="M57.36 86.7399C57.7189 86.7399 58.01 86.4489 58.01 86.0899C58.01 85.731 57.7189 85.4399 57.36 85.4399C57.001 85.4399 56.71 85.731 56.71 86.0899C56.71 86.4489 57.001 86.7399 57.36 86.7399Z"
            fill="#142237"
          />
          <path
            d="M58.9498 88.33C59.3088 88.33 59.5998 88.039 59.5998 87.68C59.5998 87.321 59.3088 87.03 58.9498 87.03C58.5908 87.03 58.2998 87.321 58.2998 87.68C58.2998 88.039 58.5908 88.33 58.9498 88.33Z"
            fill="#142237"
          />
          <path
            d="M60.5406 89.9199C60.8996 89.9199 61.1906 89.6289 61.1906 89.2699C61.1906 88.9109 60.8996 88.6199 60.5406 88.6199C60.1816 88.6199 59.8906 88.9109 59.8906 89.2699C59.8906 89.6289 60.1816 89.9199 60.5406 89.9199Z"
            fill="#142237"
          />
          <path
            d="M62.1305 91.52C62.4895 91.52 62.7805 91.229 62.7805 90.87C62.7805 90.511 62.4895 90.22 62.1305 90.22C61.7715 90.22 61.4805 90.511 61.4805 90.87C61.4805 91.229 61.7715 91.52 62.1305 91.52Z"
            fill="#142237"
          />
          <path
            d="M63.7203 93.1098C64.0793 93.1098 64.3703 92.8188 64.3703 92.4598C64.3703 92.1008 64.0793 91.8098 63.7203 91.8098C63.3613 91.8098 63.0703 92.1008 63.0703 92.4598C63.0703 92.8188 63.3613 93.1098 63.7203 93.1098Z"
            fill="#142237"
          />
          <path
            d="M65.3099 94.6899C65.6634 94.6899 65.9499 94.4034 65.9499 94.0499C65.9499 93.6964 65.6634 93.4099 65.3099 93.4099C64.9565 93.4099 64.6699 93.6964 64.6699 94.0499C64.6699 94.4034 64.9565 94.6899 65.3099 94.6899Z"
            fill="#142237"
          />
          <path
            d="M36.6803 69.2399C37.0393 69.2399 37.3303 68.9489 37.3303 68.5899C37.3303 68.231 37.0393 67.9399 36.6803 67.9399C36.3213 67.9399 36.0303 68.231 36.0303 68.5899C36.0303 68.9489 36.3213 69.2399 36.6803 69.2399Z"
            fill="#142237"
          />
          <path
            d="M38.2701 70.83C38.6291 70.83 38.9201 70.539 38.9201 70.18C38.9201 69.821 38.6291 69.53 38.2701 69.53C37.9111 69.53 37.6201 69.821 37.6201 70.18C37.6201 70.539 37.9111 70.83 38.2701 70.83Z"
            fill="#142237"
          />
          <path
            d="M39.86 72.4199C40.2189 72.4199 40.51 72.1289 40.51 71.7699C40.51 71.4109 40.2189 71.1199 39.86 71.1199C39.501 71.1199 39.21 71.4109 39.21 71.7699C39.21 72.1289 39.501 72.4199 39.86 72.4199Z"
            fill="#142237"
          />
          <path
            d="M41.4498 74.01C41.8088 74.01 42.0998 73.7189 42.0998 73.36C42.0998 73.001 41.8088 72.71 41.4498 72.71C41.0908 72.71 40.7998 73.001 40.7998 73.36C40.7998 73.7189 41.0908 74.01 41.4498 74.01Z"
            fill="#142237"
          />
          <path
            d="M43.0406 75.6098C43.3996 75.6098 43.6906 75.3188 43.6906 74.9598C43.6906 74.6008 43.3996 74.3098 43.0406 74.3098C42.6816 74.3098 42.3906 74.6008 42.3906 74.9598C42.3906 75.3188 42.6816 75.6098 43.0406 75.6098Z"
            fill="#142237"
          />
          <path
            d="M44.6305 77.1999C44.9895 77.1999 45.2805 76.9089 45.2805 76.5499C45.2805 76.1909 44.9895 75.8999 44.6305 75.8999C44.2715 75.8999 43.9805 76.1909 43.9805 76.5499C43.9805 76.9089 44.2715 77.1999 44.6305 77.1999Z"
            fill="#142237"
          />
          <path
            d="M46.2203 78.79C46.5793 78.79 46.8703 78.499 46.8703 78.14C46.8703 77.781 46.5793 77.49 46.2203 77.49C45.8613 77.49 45.5703 77.781 45.5703 78.14C45.5703 78.499 45.8613 78.79 46.2203 78.79Z"
            fill="#142237"
          />
          <path
            d="M47.8102 80.3798C48.1691 80.3798 48.4602 80.0888 48.4602 79.7298C48.4602 79.3709 48.1691 79.0798 47.8102 79.0798C47.4512 79.0798 47.1602 79.3709 47.1602 79.7298C47.1602 80.0888 47.4512 80.3798 47.8102 80.3798Z"
            fill="#142237"
          />
          <path
            d="M49.4 81.9699C49.759 81.9699 50.05 81.6789 50.05 81.3199C50.05 80.9609 49.759 80.6699 49.4 80.6699C49.041 80.6699 48.75 80.9609 48.75 81.3199C48.75 81.6789 49.041 81.9699 49.4 81.9699Z"
            fill="#142237"
          />
          <path
            d="M50.9898 83.56C51.3488 83.56 51.6398 83.269 51.6398 82.91C51.6398 82.551 51.3488 82.26 50.9898 82.26C50.6309 82.26 50.3398 82.551 50.3398 82.91C50.3398 83.269 50.6309 83.56 50.9898 83.56Z"
            fill="#142237"
          />
          <path
            d="M52.5904 85.1499C52.9494 85.1499 53.2404 84.8588 53.2404 84.4999C53.2404 84.1409 52.9494 83.8499 52.5904 83.8499C52.2314 83.8499 51.9404 84.1409 51.9404 84.4999C51.9404 84.8588 52.2314 85.1499 52.5904 85.1499Z"
            fill="#142237"
          />
          <path
            d="M54.1803 86.7399C54.5393 86.7399 54.8303 86.4489 54.8303 86.0899C54.8303 85.731 54.5393 85.4399 54.1803 85.4399C53.8213 85.4399 53.5303 85.731 53.5303 86.0899C53.5303 86.4489 53.8213 86.7399 54.1803 86.7399Z"
            fill="#142237"
          />
          <path
            d="M55.7701 88.33C56.1291 88.33 56.4201 88.039 56.4201 87.68C56.4201 87.321 56.1291 87.03 55.7701 87.03C55.4111 87.03 55.1201 87.321 55.1201 87.68C55.1201 88.039 55.4111 88.33 55.7701 88.33Z"
            fill="#142237"
          />
          <path
            d="M57.36 89.9199C57.7189 89.9199 58.01 89.6289 58.01 89.2699C58.01 88.9109 57.7189 88.6199 57.36 88.6199C57.001 88.6199 56.71 88.9109 56.71 89.2699C56.71 89.6289 57.001 89.9199 57.36 89.9199Z"
            fill="#142237"
          />
          <path
            d="M58.9498 91.52C59.3088 91.52 59.5998 91.229 59.5998 90.87C59.5998 90.511 59.3088 90.22 58.9498 90.22C58.5908 90.22 58.2998 90.511 58.2998 90.87C58.2998 91.229 58.5908 91.52 58.9498 91.52Z"
            fill="#142237"
          />
          <path
            d="M60.5406 93.1098C60.8996 93.1098 61.1906 92.8188 61.1906 92.4598C61.1906 92.1008 60.8996 91.8098 60.5406 91.8098C60.1816 91.8098 59.8906 92.1008 59.8906 92.4598C59.8906 92.8188 60.1816 93.1098 60.5406 93.1098Z"
            fill="#142237"
          />
          <path
            d="M62.1302 94.6899C62.4837 94.6899 62.7702 94.4034 62.7702 94.0499C62.7702 93.6964 62.4837 93.4099 62.1302 93.4099C61.7768 93.4099 61.4902 93.6964 61.4902 94.0499C61.4902 94.4034 61.7768 94.6899 62.1302 94.6899Z"
            fill="#142237"
          />
          <path
            d="M36.6803 72.4199C37.0393 72.4199 37.3303 72.1289 37.3303 71.7699C37.3303 71.4109 37.0393 71.1199 36.6803 71.1199C36.3213 71.1199 36.0303 71.4109 36.0303 71.7699C36.0303 72.1289 36.3213 72.4199 36.6803 72.4199Z"
            fill="#142237"
          />
          <path
            d="M38.2701 74.01C38.6291 74.01 38.9201 73.7189 38.9201 73.36C38.9201 73.001 38.6291 72.71 38.2701 72.71C37.9111 72.71 37.6201 73.001 37.6201 73.36C37.6201 73.7189 37.9111 74.01 38.2701 74.01Z"
            fill="#142237"
          />
          <path
            d="M39.86 75.6098C40.2189 75.6098 40.51 75.3188 40.51 74.9598C40.51 74.6008 40.2189 74.3098 39.86 74.3098C39.501 74.3098 39.21 74.6008 39.21 74.9598C39.21 75.3188 39.501 75.6098 39.86 75.6098Z"
            fill="#142237"
          />
          <path
            d="M41.4498 77.1999C41.8088 77.1999 42.0998 76.9089 42.0998 76.5499C42.0998 76.1909 41.8088 75.8999 41.4498 75.8999C41.0908 75.8999 40.7998 76.1909 40.7998 76.5499C40.7998 76.9089 41.0908 77.1999 41.4498 77.1999Z"
            fill="#142237"
          />
          <path
            d="M43.0406 78.79C43.3996 78.79 43.6906 78.499 43.6906 78.14C43.6906 77.781 43.3996 77.49 43.0406 77.49C42.6816 77.49 42.3906 77.781 42.3906 78.14C42.3906 78.499 42.6816 78.79 43.0406 78.79Z"
            fill="#142237"
          />
          <path
            d="M44.6305 80.3798C44.9895 80.3798 45.2805 80.0888 45.2805 79.7298C45.2805 79.3709 44.9895 79.0798 44.6305 79.0798C44.2715 79.0798 43.9805 79.3709 43.9805 79.7298C43.9805 80.0888 44.2715 80.3798 44.6305 80.3798Z"
            fill="#142237"
          />
          <path
            d="M46.2203 81.9699C46.5793 81.9699 46.8703 81.6789 46.8703 81.3199C46.8703 80.9609 46.5793 80.6699 46.2203 80.6699C45.8613 80.6699 45.5703 80.9609 45.5703 81.3199C45.5703 81.6789 45.8613 81.9699 46.2203 81.9699Z"
            fill="#142237"
          />
          <path
            d="M47.8102 83.56C48.1691 83.56 48.4602 83.269 48.4602 82.91C48.4602 82.551 48.1691 82.26 47.8102 82.26C47.4512 82.26 47.1602 82.551 47.1602 82.91C47.1602 83.269 47.4512 83.56 47.8102 83.56Z"
            fill="#142237"
          />
          <path
            d="M49.4 85.1499C49.759 85.1499 50.05 84.8588 50.05 84.4999C50.05 84.1409 49.759 83.8499 49.4 83.8499C49.041 83.8499 48.75 84.1409 48.75 84.4999C48.75 84.8588 49.041 85.1499 49.4 85.1499Z"
            fill="#142237"
          />
          <path
            d="M50.9898 86.7399C51.3488 86.7399 51.6398 86.4489 51.6398 86.0899C51.6398 85.731 51.3488 85.4399 50.9898 85.4399C50.6309 85.4399 50.3398 85.731 50.3398 86.0899C50.3398 86.4489 50.6309 86.7399 50.9898 86.7399Z"
            fill="#142237"
          />
          <path
            d="M52.5904 88.33C52.9494 88.33 53.2404 88.039 53.2404 87.68C53.2404 87.321 52.9494 87.03 52.5904 87.03C52.2314 87.03 51.9404 87.321 51.9404 87.68C51.9404 88.039 52.2314 88.33 52.5904 88.33Z"
            fill="#142237"
          />
          <path
            d="M54.1803 89.9199C54.5393 89.9199 54.8303 89.6289 54.8303 89.2699C54.8303 88.9109 54.5393 88.6199 54.1803 88.6199C53.8213 88.6199 53.5303 88.9109 53.5303 89.2699C53.5303 89.6289 53.8213 89.9199 54.1803 89.9199Z"
            fill="#142237"
          />
          <path
            d="M55.7701 91.52C56.1291 91.52 56.4201 91.229 56.4201 90.87C56.4201 90.511 56.1291 90.22 55.7701 90.22C55.4111 90.22 55.1201 90.511 55.1201 90.87C55.1201 91.229 55.4111 91.52 55.7701 91.52Z"
            fill="#142237"
          />
          <path
            d="M57.36 93.1098C57.7189 93.1098 58.01 92.8188 58.01 92.4598C58.01 92.1008 57.7189 91.8098 57.36 91.8098C57.001 91.8098 56.71 92.1008 56.71 92.4598C56.71 92.8188 57.001 93.1098 57.36 93.1098Z"
            fill="#142237"
          />
          <path
            d="M58.9505 94.6899C59.304 94.6899 59.5905 94.4034 59.5905 94.0499C59.5905 93.6964 59.304 93.4099 58.9505 93.4099C58.5971 93.4099 58.3105 93.6964 58.3105 94.0499C58.3105 94.4034 58.5971 94.6899 58.9505 94.6899Z"
            fill="#142237"
          />
          <path
            d="M38.2701 77.1999C38.6291 77.1999 38.9201 76.9089 38.9201 76.5499C38.9201 76.1909 38.6291 75.8999 38.2701 75.8999C37.9111 75.8999 37.6201 76.1909 37.6201 76.5499C37.6201 76.9089 37.9111 77.1999 38.2701 77.1999Z"
            fill="#142237"
          />
          <path
            d="M39.86 78.79C40.2189 78.79 40.51 78.499 40.51 78.14C40.51 77.781 40.2189 77.49 39.86 77.49C39.501 77.49 39.21 77.781 39.21 78.14C39.21 78.499 39.501 78.79 39.86 78.79Z"
            fill="#142237"
          />
          <path
            d="M41.4498 80.3798C41.8088 80.3798 42.0998 80.0888 42.0998 79.7298C42.0998 79.3709 41.8088 79.0798 41.4498 79.0798C41.0908 79.0798 40.7998 79.3709 40.7998 79.7298C40.7998 80.0888 41.0908 80.3798 41.4498 80.3798Z"
            fill="#142237"
          />
          <path
            d="M43.0406 81.9699C43.3996 81.9699 43.6906 81.6789 43.6906 81.3199C43.6906 80.9609 43.3996 80.6699 43.0406 80.6699C42.6816 80.6699 42.3906 80.9609 42.3906 81.3199C42.3906 81.6789 42.6816 81.9699 43.0406 81.9699Z"
            fill="#142237"
          />
          <path
            d="M44.6305 83.56C44.9895 83.56 45.2805 83.269 45.2805 82.91C45.2805 82.551 44.9895 82.26 44.6305 82.26C44.2715 82.26 43.9805 82.551 43.9805 82.91C43.9805 83.269 44.2715 83.56 44.6305 83.56Z"
            fill="#142237"
          />
          <path
            d="M46.2203 85.1499C46.5793 85.1499 46.8703 84.8588 46.8703 84.4999C46.8703 84.1409 46.5793 83.8499 46.2203 83.8499C45.8613 83.8499 45.5703 84.1409 45.5703 84.4999C45.5703 84.8588 45.8613 85.1499 46.2203 85.1499Z"
            fill="#142237"
          />
          <path
            d="M47.8102 86.7399C48.1691 86.7399 48.4602 86.4489 48.4602 86.0899C48.4602 85.731 48.1691 85.4399 47.8102 85.4399C47.4512 85.4399 47.1602 85.731 47.1602 86.0899C47.1602 86.4489 47.4512 86.7399 47.8102 86.7399Z"
            fill="#142237"
          />
          <path
            d="M49.4 88.33C49.759 88.33 50.05 88.039 50.05 87.68C50.05 87.321 49.759 87.03 49.4 87.03C49.041 87.03 48.75 87.321 48.75 87.68C48.75 88.039 49.041 88.33 49.4 88.33Z"
            fill="#142237"
          />
          <path
            d="M50.9898 89.9199C51.3488 89.9199 51.6398 89.6289 51.6398 89.2699C51.6398 88.9109 51.3488 88.6199 50.9898 88.6199C50.6309 88.6199 50.3398 88.9109 50.3398 89.2699C50.3398 89.6289 50.6309 89.9199 50.9898 89.9199Z"
            fill="#142237"
          />
          <path
            d="M52.5904 91.52C52.9494 91.52 53.2404 91.229 53.2404 90.87C53.2404 90.511 52.9494 90.22 52.5904 90.22C52.2314 90.22 51.9404 90.511 51.9404 90.87C51.9404 91.229 52.2314 91.52 52.5904 91.52Z"
            fill="#142237"
          />
          <path
            d="M54.1803 93.1098C54.5393 93.1098 54.8303 92.8188 54.8303 92.4598C54.8303 92.1008 54.5393 91.8098 54.1803 91.8098C53.8213 91.8098 53.5303 92.1008 53.5303 92.4598C53.5303 92.8188 53.8213 93.1098 54.1803 93.1098Z"
            fill="#142237"
          />
          <path
            d="M55.7699 94.6899C56.1233 94.6899 56.4099 94.4034 56.4099 94.0499C56.4099 93.6964 56.1233 93.4099 55.7699 93.4099C55.4164 93.4099 55.1299 93.6964 55.1299 94.0499C55.1299 94.4034 55.4164 94.6899 55.7699 94.6899Z"
            fill="#142237"
          />
          <path
            d="M38.2701 80.3798C38.6291 80.3798 38.9201 80.0888 38.9201 79.7298C38.9201 79.3709 38.6291 79.0798 38.2701 79.0798C37.9111 79.0798 37.6201 79.3709 37.6201 79.7298C37.6201 80.0888 37.9111 80.3798 38.2701 80.3798Z"
            fill="#142237"
          />
          <path
            d="M39.86 81.9699C40.2189 81.9699 40.51 81.6789 40.51 81.3199C40.51 80.9609 40.2189 80.6699 39.86 80.6699C39.501 80.6699 39.21 80.9609 39.21 81.3199C39.21 81.6789 39.501 81.9699 39.86 81.9699Z"
            fill="#142237"
          />
          <path
            d="M41.4498 83.56C41.8088 83.56 42.0998 83.269 42.0998 82.91C42.0998 82.551 41.8088 82.26 41.4498 82.26C41.0908 82.26 40.7998 82.551 40.7998 82.91C40.7998 83.269 41.0908 83.56 41.4498 83.56Z"
            fill="#142237"
          />
          <path
            d="M43.0406 85.1499C43.3996 85.1499 43.6906 84.8588 43.6906 84.4999C43.6906 84.1409 43.3996 83.8499 43.0406 83.8499C42.6816 83.8499 42.3906 84.1409 42.3906 84.4999C42.3906 84.8588 42.6816 85.1499 43.0406 85.1499Z"
            fill="#142237"
          />
          <path
            d="M44.6305 86.7399C44.9895 86.7399 45.2805 86.4489 45.2805 86.0899C45.2805 85.731 44.9895 85.4399 44.6305 85.4399C44.2715 85.4399 43.9805 85.731 43.9805 86.0899C43.9805 86.4489 44.2715 86.7399 44.6305 86.7399Z"
            fill="#142237"
          />
          <path
            d="M46.2203 88.33C46.5793 88.33 46.8703 88.039 46.8703 87.68C46.8703 87.321 46.5793 87.03 46.2203 87.03C45.8613 87.03 45.5703 87.321 45.5703 87.68C45.5703 88.039 45.8613 88.33 46.2203 88.33Z"
            fill="#142237"
          />
          <path
            d="M47.8102 89.9199C48.1691 89.9199 48.4602 89.6289 48.4602 89.2699C48.4602 88.9109 48.1691 88.6199 47.8102 88.6199C47.4512 88.6199 47.1602 88.9109 47.1602 89.2699C47.1602 89.6289 47.4512 89.9199 47.8102 89.9199Z"
            fill="#142237"
          />
          <path
            d="M49.4 91.52C49.759 91.52 50.05 91.229 50.05 90.87C50.05 90.511 49.759 90.22 49.4 90.22C49.041 90.22 48.75 90.511 48.75 90.87C48.75 91.229 49.041 91.52 49.4 91.52Z"
            fill="#142237"
          />
          <path
            d="M50.9898 93.1098C51.3488 93.1098 51.6398 92.8188 51.6398 92.4598C51.6398 92.1008 51.3488 91.8098 50.9898 91.8098C50.6309 91.8098 50.3398 92.1008 50.3398 92.4598C50.3398 92.8188 50.6309 93.1098 50.9898 93.1098Z"
            fill="#142237"
          />
          <path
            d="M52.5902 94.6899C52.9437 94.6899 53.2302 94.4034 53.2302 94.0499C53.2302 93.6964 52.9437 93.4099 52.5902 93.4099C52.2367 93.4099 51.9502 93.6964 51.9502 94.0499C51.9502 94.4034 52.2367 94.6899 52.5902 94.6899Z"
            fill="#142237"
          />
          <path
            d="M38.2701 83.56C38.6291 83.56 38.9201 83.269 38.9201 82.91C38.9201 82.551 38.6291 82.26 38.2701 82.26C37.9111 82.26 37.6201 82.551 37.6201 82.91C37.6201 83.269 37.9111 83.56 38.2701 83.56Z"
            fill="#142237"
          />
          <path
            d="M39.86 85.1499C40.2189 85.1499 40.51 84.8588 40.51 84.4999C40.51 84.1409 40.2189 83.8499 39.86 83.8499C39.501 83.8499 39.21 84.1409 39.21 84.4999C39.21 84.8588 39.501 85.1499 39.86 85.1499Z"
            fill="#142237"
          />
          <path
            d="M41.4498 86.7399C41.8088 86.7399 42.0998 86.4489 42.0998 86.0899C42.0998 85.731 41.8088 85.4399 41.4498 85.4399C41.0908 85.4399 40.7998 85.731 40.7998 86.0899C40.7998 86.4489 41.0908 86.7399 41.4498 86.7399Z"
            fill="#142237"
          />
          <path
            d="M43.0406 88.33C43.3996 88.33 43.6906 88.039 43.6906 87.68C43.6906 87.321 43.3996 87.03 43.0406 87.03C42.6816 87.03 42.3906 87.321 42.3906 87.68C42.3906 88.039 42.6816 88.33 43.0406 88.33Z"
            fill="#142237"
          />
          <path
            d="M44.6305 89.9199C44.9895 89.9199 45.2805 89.6289 45.2805 89.2699C45.2805 88.9109 44.9895 88.6199 44.6305 88.6199C44.2715 88.6199 43.9805 88.9109 43.9805 89.2699C43.9805 89.6289 44.2715 89.9199 44.6305 89.9199Z"
            fill="#142237"
          />
          <path
            d="M46.2203 91.52C46.5793 91.52 46.8703 91.229 46.8703 90.87C46.8703 90.511 46.5793 90.22 46.2203 90.22C45.8613 90.22 45.5703 90.511 45.5703 90.87C45.5703 91.229 45.8613 91.52 46.2203 91.52Z"
            fill="#142237"
          />
          <path
            d="M47.8102 93.1098C48.1691 93.1098 48.4602 92.8188 48.4602 92.4598C48.4602 92.1008 48.1691 91.8098 47.8102 91.8098C47.4512 91.8098 47.1602 92.1008 47.1602 92.4598C47.1602 92.8188 47.4512 93.1098 47.8102 93.1098Z"
            fill="#142237"
          />
          <path
            d="M49.4007 94.6899C49.7542 94.6899 50.0407 94.4034 50.0407 94.0499C50.0407 93.6964 49.7542 93.4099 49.4007 93.4099C49.0473 93.4099 48.7607 93.6964 48.7607 94.0499C48.7607 94.4034 49.0473 94.6899 49.4007 94.6899Z"
            fill="#142237"
          />
          <path
            d="M38.2701 86.7399C38.6291 86.7399 38.9201 86.4489 38.9201 86.0899C38.9201 85.731 38.6291 85.4399 38.2701 85.4399C37.9111 85.4399 37.6201 85.731 37.6201 86.0899C37.6201 86.4489 37.9111 86.7399 38.2701 86.7399Z"
            fill="#142237"
          />
          <path
            d="M39.86 88.33C40.2189 88.33 40.51 88.039 40.51 87.68C40.51 87.321 40.2189 87.03 39.86 87.03C39.501 87.03 39.21 87.321 39.21 87.68C39.21 88.039 39.501 88.33 39.86 88.33Z"
            fill="#142237"
          />
          <path
            d="M41.4498 89.9199C41.8088 89.9199 42.0998 89.6289 42.0998 89.2699C42.0998 88.9109 41.8088 88.6199 41.4498 88.6199C41.0908 88.6199 40.7998 88.9109 40.7998 89.2699C40.7998 89.6289 41.0908 89.9199 41.4498 89.9199Z"
            fill="#142237"
          />
          <path
            d="M43.0406 91.52C43.3996 91.52 43.6906 91.229 43.6906 90.87C43.6906 90.511 43.3996 90.22 43.0406 90.22C42.6816 90.22 42.3906 90.511 42.3906 90.87C42.3906 91.229 42.6816 91.52 43.0406 91.52Z"
            fill="#142237"
          />
          <path
            d="M44.6305 93.1098C44.9895 93.1098 45.2805 92.8188 45.2805 92.4598C45.2805 92.1008 44.9895 91.8098 44.6305 91.8098C44.2715 91.8098 43.9805 92.1008 43.9805 92.4598C43.9805 92.8188 44.2715 93.1098 44.6305 93.1098Z"
            fill="#142237"
          />
          <path
            d="M46.2201 94.6899C46.5735 94.6899 46.8601 94.4034 46.8601 94.0499C46.8601 93.6964 46.5735 93.4099 46.2201 93.4099C45.8666 93.4099 45.5801 93.6964 45.5801 94.0499C45.5801 94.4034 45.8666 94.6899 46.2201 94.6899Z"
            fill="#142237"
          />
          <path
            d="M38.2701 89.9199C38.6291 89.9199 38.9201 89.6289 38.9201 89.2699C38.9201 88.9109 38.6291 88.6199 38.2701 88.6199C37.9111 88.6199 37.6201 88.9109 37.6201 89.2699C37.6201 89.6289 37.9111 89.9199 38.2701 89.9199Z"
            fill="#142237"
          />
          <path
            d="M39.86 91.52C40.2189 91.52 40.51 91.229 40.51 90.87C40.51 90.511 40.2189 90.22 39.86 90.22C39.501 90.22 39.21 90.511 39.21 90.87C39.21 91.229 39.501 91.52 39.86 91.52Z"
            fill="#142237"
          />
          <path
            d="M41.4498 93.1098C41.8088 93.1098 42.0998 92.8188 42.0998 92.4598C42.0998 92.1008 41.8088 91.8098 41.4498 91.8098C41.0908 91.8098 40.7998 92.1008 40.7998 92.4598C40.7998 92.8188 41.0908 93.1098 41.4498 93.1098Z"
            fill="#142237"
          />
          <path
            d="M43.0404 94.6899C43.3939 94.6899 43.6804 94.4034 43.6804 94.0499C43.6804 93.6964 43.3939 93.4099 43.0404 93.4099C42.6869 93.4099 42.4004 93.6964 42.4004 94.0499C42.4004 94.4034 42.6869 94.6899 43.0404 94.6899Z"
            fill="#142237"
          />
          <path
            d="M38.2701 93.1098C38.6291 93.1098 38.9201 92.8188 38.9201 92.4598C38.9201 92.1008 38.6291 91.8098 38.2701 91.8098C37.9111 91.8098 37.6201 92.1008 37.6201 92.4598C37.6201 92.8188 37.9111 93.1098 38.2701 93.1098Z"
            fill="#142237"
          />
          <path
            d="M39.8607 94.6899C40.2142 94.6899 40.5007 94.4034 40.5007 94.0499C40.5007 93.6964 40.2142 93.4099 39.8607 93.4099C39.5072 93.4099 39.2207 93.6964 39.2207 94.0499C39.2207 94.4034 39.5072 94.6899 39.8607 94.6899Z"
            fill="#142237"
          />
          <path
            d="M60.5107 62.75V86.72"
            stroke="#142237"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M73.2902 62.75L71.9502 86.71"
            stroke="#142237"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M49.0802 86.72L47.7402 62.76"
            stroke="#142237"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3100_48654">
          <rect
            width="120"
            height="120"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteChatIcon;

import React from 'react';
import { colors } from '../../../../theme/theme';
import { Box, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import UploadDocumentIcon from '../../../Icons/UploadDocumentIcon';
import BallIcon from '../../../Icons/BallIcon';
import CupIcon from '../../../Icons/CupIcon';
import NoteBookIcon from '../../../Icons/NoteBookIcon';
import SmallRightIcon from '../../../Icons/SmallRightIcon';
import CustomButton from '../../../MUIComponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import {
  ButtonIconPositionEnum,
  ButtonTypeEnum,
} from '../../../../enums/button-type-enum';
import RightArrowIcon from '../../../Icons/RightArrowIcon';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

const GetStarted = () => {
  const navigate = useNavigate();

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const handleSubmit = () => {
    navigate('/profile', { replace: true });
  };

  return (
    <Box
      alignSelf={mobileView ? '' : 'center'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={mobileView ? '2rem' : '48px'}
    >
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        bgcolor={'background.white'}
        padding={mobileView ? '2.25rem 1rem' : '48px'}
        gap={'48px'}
        maxWidth={'1096px'}
      >
        <Typography variant={'h2'}>Welcome to Free Agent</Typography>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={'36px'}
        >
          <Typography variant={'h3'}>Get started:</Typography>

          <List
            sx={{
              display: mobileView ? 'flex' : 'grid',
              flexDirection: 'column',
              gap: '1.375rem',
              padding: 0,
              listStyle: 'none',
              gridTemplateColumns: mobileView ? '' : 'repeat(4, 1fr)',
            }}
          >
            <ListItem
              onClick={() => navigate('/profile', { replace: true })}
              sx={{
                padding: '22px',
                gap: mobileView ? '1rem' : '50px',
                bgcolor: 'background.light',
                borderRadius: '1px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                transition: 'background 0.3s, color 0.3s',
                cursor: 'pointer',
                '& svg': {
                  stroke: colors.bgDarkOrange,
                  transition: 'background 0.3s, color 0.3s',
                },
                '&:hover': {
                  background: colors.bgDarkOrange,
                  color: colors.white,
                  span: {
                    color: colors.white,
                    transition: 'color 0.3s',
                  },
                  '& svg': {
                    stroke: colors.white,
                  },
                },
              }}
            >
              <Box display={'flex'} maxWidth={'28px'} maxHeight={'28px'}>
                <UploadDocumentIcon />
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'22px'}>
                <Typography variant={'body1'}>
                  Upload a profile picture:
                </Typography>
                <Typography variant={'body3'}>
                  Personalize your profile with a professional photo.
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              onClick={() => navigate('/profile', { replace: true })}
              sx={{
                padding: '22px',
                gap: mobileView ? '1rem' : '50px',
                bgcolor: 'background.light',
                borderRadius: '1px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                transition: 'background 0.3s, color 0.3s',
                cursor: 'pointer',
                '& svg': {
                  stroke: colors.bgDarkOrange,
                  transition: 'background 0.3s, color 0.3s',
                },
                '&:hover': {
                  background: colors.bgDarkOrange,
                  color: colors.white,
                  span: {
                    color: colors.white,
                    transition: 'color 0.3s',
                  },
                  svg: { stroke: colors.white },
                },
              }}
            >
              <Box display={'flex'} maxWidth={'28px'} maxHeight={'28px'}>
                <BallIcon />
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'22px'}>
                <Typography variant={'body1'}>
                  Add your sports and positions:
                </Typography>
                <Typography component={'span'} variant={'body3'}>
                  Let us know your primary sports and positions to tailor your
                  experience.
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              onClick={() => navigate('/profile', { replace: true })}
              sx={{
                padding: '22px',
                gap: mobileView ? '1rem' : '50px',
                bgcolor: 'background.light',
                borderRadius: '1px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                cursor: 'pointer',
                transition: 'background 0.3s, color 0.3s',
                '& svg': {
                  stroke: colors.bgDarkOrange,
                  transition: 'background 0.3s, color 0.3s',
                },
                '&:hover': {
                  background: colors.bgDarkOrange,
                  color: colors.white,
                  span: {
                    color: colors.white,
                    transition: 'color 0.3s',
                  },
                  '& svg': {
                    stroke: colors.white,
                  },
                },
              }}
            >
              <Box display={'flex'} maxWidth={'28px'} maxHeight={'28px'}>
                <CupIcon />
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'22px'}>
                <Typography variant={'body1'}>
                  Share Your Achievements and Goals:
                </Typography>
                <Typography variant={'body3'}>
                  Inspire others with your journey and track your progress over
                  time.
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              onClick={() => navigate('/profile', { replace: true })}
              sx={{
                padding: '22px',
                gap: mobileView ? '1rem' : '50px',
                bgcolor: 'background.light',
                borderRadius: '1px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                cursor: 'pointer',
                transition: 'background 0.3s, color 0.3s',
                '& svg': {
                  stroke: colors.bgDarkOrange,
                  transition: 'background 0.3s, color 0.3s',
                },
                '&:hover': {
                  background: colors.bgDarkOrange,
                  color: colors.white,
                  span: {
                    color: colors.white,
                    transition: 'color 0.3s',
                  },
                  '& svg': {
                    stroke: colors.white,
                  },
                },
              }}
            >
              <Box display={'flex'} maxWidth={'28px'} maxHeight={'28px'}>
                <NoteBookIcon />
              </Box>
              <Typography variant={'body1'}>
                Ready to showcase your talent?
              </Typography>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={'8px'}
                marginTop={'auto'}
                sx={{
                  '& svg': {
                    transition: 'background 0.3s, color 0.3s',
                  },
                }}
              >
                <Box maxWidth={'18px'} maxHeight={'18px'}>
                  <SmallRightIcon />
                </Box>
                <Typography
                  variant={'body4'}
                  fontWeight={700}
                  color={'text.secondary'}
                >
                  Complete Your Profile Now
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Box>
      </Box>
      <CustomButton
        icon={<RightArrowIcon sx={{ stroke: '#DDE1E8' }} />}
        variantType={ButtonTypeEnum.SECONDARY}
        buttonWidth={mobileView ? '90%' : '220px'}
        onClick={handleSubmit}
        iconPosition={ButtonIconPositionEnum.RIGHT}
      >
        complete later
      </CustomButton>
    </Box>
  );
};

export default GetStarted;

import React, { FC } from 'react';
import { Button, ButtonProps, SxProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface AddMediaMobileButtonProps extends ButtonProps {}

const AddMediaMobileButton: FC<AddMediaMobileButtonProps> = ({ ...props }) => {
  return (
    <Button
      variant={'outlined'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 40,
        width: 40,
        height: 40,
        border: '1px solid #E8EBEf',
        borderRadius: '999px',
      }}
      {...props}
    >
      <AddIcon
        sx={{
          color: `#727F93`,
          width: 24,
          height: 24,
        }}
      />
    </Button>
  );
};

export default AddMediaMobileButton;

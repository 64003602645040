const SmallTrashIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.25H15"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M3.85742 5.78577L4.8216 15.7501H13.1787L14.1431 5.78577"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M6.75 5.25V2.25H11.25V5.25"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M7.5 9L10.5 12M10.5 9L7.5 12"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default SmallTrashIcon;

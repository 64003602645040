import React from 'react';
import { Box, Avatar, Typography, IconButton } from '@mui/material';
import SendMessageIcon from '../../../../../components/Icons/SendMessageIcon';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import CloseX from '../../../../../components/Icons/CloseX';
import { IFriend } from '../../../../../interfaces/friend.interface';
import {
  useCreateChatMutation,
  useGetAllMyChatsQuery,
} from '../../../../../store/apiSlice/chat/chatApi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChatStateEnum } from '../../../../../enums/chat-enum';
import {
  setChatState,
  setSelectedChat,
} from '../../../../../store/apiSlice/chat/chatSlice';

interface NotificationBoxProps {
  notificationData: IFriend;
  snackbarMessage: string;
  matches: boolean;
  setNotificationData: React.Dispatch<React.SetStateAction<{}>>;
}

const NotificationBox: React.FC<NotificationBoxProps> = ({
  notificationData,
  snackbarMessage,
  matches,
  setNotificationData,
}) => {
  const { data: myChats } = useGetAllMyChatsQuery();
  const [createChat, { isLoading: isCreatingChat }] = useCreateChatMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMessage = (friendUserId: string) => {
    dispatch(setChatState(ChatStateEnum.CHAT));

    if (myChats)
      for (const chat of myChats)
        if (chat.withUserId === friendUserId) {
          dispatch(setSelectedChat(chat));
          return navigate('/chat');
        }

    createChat(friendUserId)
      .unwrap()
      .then(chat => {
        dispatch(setSelectedChat(chat));
        navigate('/chat');
      })
      .catch(console.log);
  };
  return (
    <Box
      mt={'22px'}
      bgcolor={'background.light'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      p={'16px'}
    >
      <Box display={'flex'} alignItems={'center'} gap={'16px'}>
        <Avatar
          alt={`${notificationData.firstName || ''} ${notificationData.lastName || ''}`}
          src={notificationData.avatarUrl || ''}
          sx={{ width: '48px', height: '48px' }}
        />
        <Typography fontSize={'14px'} color={'text.primary'} fontWeight={700}>
          {`${notificationData.firstName || ''} ${notificationData.lastName || ''}`}{' '}
          <Typography
            component={'span'}
            fontSize={'14px'}
            color={'text.secondary'}
            fontWeight={400}
          >
            {snackbarMessage}
          </Typography>
        </Typography>
      </Box>
      {snackbarMessage ===
      'is now in your contacts list. Send him a message.' ? (
        <>
          {!matches && (
            <IconButton
              sx={{ border: '1px solid darkgray' }}
              onClick={() => handleMessage(notificationData.userId)}
              disabled={isCreatingChat}
            >
              <SendMessageIcon />
            </IconButton>
          )}
          {matches && (
            <Box minWidth={'145px'}>
              <CustomButton
                disabled={isCreatingChat}
                sx={{ p: '12px 16px' }}
                buttonWidth="100%"
                variant="contained"
                variantType={ButtonTypeEnum.PRIMARY}
                fullWidth
                onClick={() => handleMessage(notificationData.userId)}
              >
                Send Message
              </CustomButton>
            </Box>
          )}
        </>
      ) : (
        <IconButton
          onClick={() => {
            setNotificationData({});
          }}
        >
          <CloseX />
        </IconButton>
      )}
    </Box>
  );
};

export default NotificationBox;

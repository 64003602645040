import dayjs, { Dayjs } from "dayjs";
import * as yup from 'yup';

export const dayjsSchema = yup
  .mixed<Dayjs>()
  .test('is-dayjs', 'Invalid date format', (value): value is Dayjs => dayjs.isDayjs(value))
  .transform((value, originalValue, ctx) => {
    if (ctx.isType(value)) return value;
    const date = dayjs(originalValue);
    return date.isValid() ? date : null;
  });

import { Box, SxProps } from '@mui/material';
import { LabelTypeEnum } from '../../enums/label-enum';
import { FC, ReactNode } from 'react';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface LabelProps {
  labelType: LabelTypeEnum;
  children: ReactNode;
  sx?: SxProps;
}

const Label: FC<LabelProps> = ({ labelType, children, sx }) => {
  const { theme } = useThemeContext();

  const getBackgroundColor = (labelType: LabelTypeEnum) => {
    switch (labelType) {
      case LabelTypeEnum.VERIFIED:
        return theme.palette.success.main;
      case LabelTypeEnum.UNDER_VERIFICATION:
        return theme.palette.warning.main;
      case LabelTypeEnum.OPEN:
        return theme.palette.success.main;
      case LabelTypeEnum.CLOSED:
        return theme.palette.error.main;
      case LabelTypeEnum.DRAFT:
        return theme.palette.grey[500];
      case LabelTypeEnum.ARCHIVE:
        return theme.palette.grey[700];
      default:
        return theme.palette.text.accent;
    }
  };

  const bgcolor = getBackgroundColor(labelType);

  return (
    <Box
      typography={'badge3'}
      sx={{
        p: '0.375rem 0.75rem',
        textTransform: 'uppercase',
        borderRadius: '20px',
        width: 'fit-content',
        height: 'fit-content',
        color: theme.palette.button.primaryText,
        bgcolor,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Label;

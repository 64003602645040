import Box from '@mui/material/Box';
import React, { useState } from 'react';
import CustomButton from '../../../components/MUIComponents/CustomButton';
import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

interface ISubscriptionPaymentManualForm {
  onSubmitManual: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const SubscriptionPaymentManualForm = (
  props: ISubscriptionPaymentManualForm
) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const { onSubmitManual } = props;
  const [paymentMethod, setPaymentMethod] = useState<string>('');

  const isCard = paymentMethod === 'card';

  const handlePaymentMethodChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setPaymentMethod(event.value.type || '');
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
      }}
      component="form"
      onSubmit={e => onSubmitManual(e)}
    >
      <Typography variant="h3" color={theme.palette.text.primary}>
        Credit Card
      </Typography>

      <PaymentElement onChange={handlePaymentMethodChange} />

      {isCard && (
        <>
          <Divider />
          <Typography variant="h3" color={theme.palette.text.primary}>
            Billing address
          </Typography>
          <AddressElement options={{ mode: 'billing' }} />
        </>
      )}

      <CustomButton
        sx={{ width: mobileView ? '100%' : '220px' }}
        variant="contained"
        type="submit"
      >
        Confirm payment
      </CustomButton>
    </Box>
  );
};

export default SubscriptionPaymentManualForm;

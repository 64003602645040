import { Box, BoxProps, Typography, useMediaQuery } from '@mui/material';
import SectionLayout from '../../../layouts/SectionLayout';
import CustomButton from '../../MUIComponents/CustomButton';
import { ReactNode } from 'react';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { useCurrentPageUserId } from '../../../hooks/useCurrentPageUserId';

interface IMediaLayoutProps extends BoxProps {
  title: string;
  isVideos?: boolean;
  isAllMedia: boolean;
  addButton: ReactNode;
  onAllMediaClick: () => void;
  onYourAlbumsClick: () => void;
  children: ReactNode;
}

const MediaLayout = ({
  title,
  isVideos = false,
  isAllMedia,
  addButton,
  onAllMediaClick,
  onYourAlbumsClick,
  children,
  ...props
}: IMediaLayoutProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { isOwnPage } = useCurrentPageUserId();

  return (
    <SectionLayout
      display="flex"
      flexDirection="column"
      gap={mobileView ? '28px' : '36px'}
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
          marginBottom: '12px',
        }}
      >
        <Typography component="h2" variant="h2">
          {title}
        </Typography>

        {mobileView && addButton}
      </Box>

      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: '12px', md: '0' }}
        justifyContent="space-between"
      >
        <Box
          bgcolor={'background.light'}
          display="flex"
          p="2px"
          gap={'1px'}
          width={mobileView ? '100%' : 'auto'}
        >
          <CustomButton
            sx={{
              height: 42,
              width: { xs: '100%', md: 140 },
              border: 'none',
              bgcolor: isAllMedia ? 'button.primary' : 'background.light',
            }}
            variantType={
              isAllMedia ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.SECONDARY
            }
            onClick={onAllMediaClick}
          >
            {`all ${title}`}
          </CustomButton>
          <CustomButton
            sx={{
              height: 42,
              width: { xs: '100%', md: 140 },
              border: 'none',
              bgcolor: isAllMedia ? 'background.light' : 'button.primary',
            }}
            variantType={
              isAllMedia ? ButtonTypeEnum.SECONDARY : ButtonTypeEnum.PRIMARY
            }
            onClick={onYourAlbumsClick}
          >
            {isVideos ? 'playlists' : 'all albums'}
          </CustomButton>
        </Box>
        {isOwnPage && !mobileView && addButton}
      </Box>
      {children}
    </SectionLayout>
  );
};

export default MediaLayout;

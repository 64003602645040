import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 13, height: 10, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 10"
      {...props}
    >
      <path
        d="M12.25 5H2.02273"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.68164 8L1.68164 5"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.68164 2L1.68164 5"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default ArrowLeftIcon;

import React, { useState } from 'react';
import { Box, Collapse, Slider, TextField, Typography } from '@mui/material';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';

interface ICandidateExperienceFilterProps {
  from: number;
  to: number;
  setFrom: (value: number) => void;
  setTo: (value: number) => void;
}

const CandidateExperienceFilter = ({
  from,
  to,
  setFrom,
  setTo,
}: ICandidateExperienceFilterProps) => {
  const [isScoreExpanded, setIsScoreExpanded] = useState(false);

  const toggleExpand = () => {
    setIsScoreExpanded(prevState => !prevState);
  };

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10);
    if (value < 0) value = 0;
    setFrom(value);
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10);
    if (value < 0) value = 0;
    if (value > 50) value = 50;
    setTo(value);
  };

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    const [newFrom, newTo] = newValue as number[];
    setFrom(newFrom);
    setTo(newTo);
  };

  return (
    <Box
      sx={{
        minWidth: 227,
        width: '100%',
        borderRadius: '1px',
        bgcolor: 'background.white',
        p: '11px 16px',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          gap: '28px',
          padding: '0 2px',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          color={'text.primary'}
          textAlign={'left'}
          display={'flex'}
          alignItems={'center'}
        >
          Experience
        </Typography>
        <Box display="flex" gap="12px">
          <ArrowDownIcon
            sx={{
              transform: isScoreExpanded ? 'rotate(180deg)' : '',
            }}
            onClick={toggleExpand}
          />
        </Box>
      </Box>
      <Collapse in={isScoreExpanded} sx={{ marginTop: '22px' }}>
        <Box display="flex" flexDirection="column" gap="22px">
          <Slider
            value={[from, to]}
            onChange={handleRangeChange}
            min={0}
            max={50}
            valueLabelDisplay="auto"
            sx={{ mb: 2 }}
          />
          <Box display="flex" flexDirection="column" gap={'12px'}>
            <Typography
              variant="caption"
              sx={{
                padding: '0 2px',
                color: 'text.primary',
              }}
            >
              Time range:
            </Typography>
            <Box display="flex" flexDirection="row" gap={'16px'}>
              <TextField
                placeholder={'From'}
                type="number"
                value={from || ''}
                onChange={handleFromChange}
                variant="outlined"
                fullWidth
                inputProps={{ min: 0 }}
              />
              <TextField
                placeholder={'To'}
                type="number"
                value={to || ''}
                onChange={handleToChange}
                variant="outlined"
                fullWidth
                inputProps={{ min: 0, max: 50 }}
              />
            </Box>{' '}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CandidateExperienceFilter;

import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CareerAdviceSupportEnum,
  CareerArrearsEnum,
  CurrentLevelEnum,
  PrimaryCareersGoalEnum,
  TypeOfSupportEnum,
} from '../../enums/questionnaires-enum';

interface FreeAgentPersonalCareerFormProps {
  answers:
    | PrimaryCareersGoalEnum[]
    | CurrentLevelEnum[]
    | TypeOfSupportEnum[]
    | CareerArrearsEnum[]
    | CareerAdviceSupportEnum[];
  selectedAnswer:
    | PrimaryCareersGoalEnum
    | CurrentLevelEnum
    | TypeOfSupportEnum
    | CareerArrearsEnum
    | CareerAdviceSupportEnum
    | null;
  onAnswerChange: (
    answer:
      | PrimaryCareersGoalEnum
      | CurrentLevelEnum
      | TypeOfSupportEnum
      | CareerArrearsEnum
      | CareerAdviceSupportEnum
  ) => void;
}

const FreeAgentPersonalCareerForm: React.FC<
  FreeAgentPersonalCareerFormProps
> = ({ answers, selectedAnswer, onAnswerChange }) => {
  const theme = useTheme();

  const getTitleFromEnum = (answer: string) => {
    switch (answer) {
      case PrimaryCareersGoalEnum.SECURE_POSITION_IN_PROFESSIONAL_TEAM:
        return 'Secure a position in a professional team';
      case PrimaryCareersGoalEnum.TRANSITION_TO_COACHING:
        return 'Transition into a coaching role';
      case PrimaryCareersGoalEnum.ENHANCE_SKILLS_TRAINING_EDUCATION:
        return 'Enhance skills through further training\n' + 'and education';
      case PrimaryCareersGoalEnum.EXPLORE_SPORTS_MANAGEMENT_ADMINISTRATION:
        return (
          'Explore opportunities in sports management\n' + 'or administration'
        );
      case PrimaryCareersGoalEnum.OTHER:
        return 'Other';
      case CurrentLevelEnum.EXCELLENT:
        return 'Excellent';
      case CurrentLevelEnum.GOOD:
        return 'Good';
      case CurrentLevelEnum.POOR:
        return 'Poor';
      case CurrentLevelEnum.FAIR:
        return 'Fair';
      case TypeOfSupportEnum.MENTORSHIP:
        return 'Mentorship and guidance from experienced\n' + 'professionals';
      case TypeOfSupportEnum.ACCESS_TO_EXCLUSIVE:
        return 'Access to exclusive job listings and\n' + 'opportunities';
      case TypeOfSupportEnum.PROFESSIONAL_TRAINER:
        return 'Professional training and certification\n' + 'programs';
      case TypeOfSupportEnum.ASSISTANCE_WITH:
        return (
          'Assistance with creating and optimizing\n' +
          'a professional resume and profile'
        );
      case CareerArrearsEnum.TECHNICAL_SKILLS:
        return 'Technical skills and performance';
      case CareerArrearsEnum.COMMUNICATION_SKILLS:
        return 'Communication and leadership skills';
      case CareerArrearsEnum.KNOWLEDGE_OF_SPORTS:
        return 'Knowledge of the sports industry and market\n' + 'trends';
      case CareerArrearsEnum.PERSONAL_BRANDING:
        return 'Personal branding and online presence';
      case CareerAdviceSupportEnum.ONE_ON_ONE:
        return 'One-on-one sessions with a career coach\n' + 'or mentor';
      case CareerAdviceSupportEnum.COURSES:
        return 'Online courses and webinars';
      case CareerAdviceSupportEnum.GROUP_WORKSHOPS:
        return 'Group workshops and training sessions';
      case CareerAdviceSupportEnum.RESOURCES:
        return 'Written resources and guides\n' + '(e.g., articles, e-books)';
      default:
        return 'Unknown';
    }
  };

  const getDescriptionFromEnum = (answer: string): string => {
    switch (answer) {
      case CurrentLevelEnum.EXCELLENT:
        return (
          'I have strong connections and regularly engage with\n' +
          'industry professionals'
        );
      case CurrentLevelEnum.GOOD:
        return 'I have some connections and engage\n' + 'occasionally';
      case CurrentLevelEnum.POOR:
        return 'I have minimal connections and rarely network';
      case CurrentLevelEnum.FAIR:
        return 'I have a few connections but need to\n' + 'network more';
      default:
        return '';
    }
  };

  return (
    <FormGroup sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '16px',
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
            gap: '8px',
          },
        }}
      >
        {answers.map(answer => (
          <Box
            key={answer}
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedAnswer === answer}
                  onChange={() => onAnswerChange(answer)}
                  sx={{
                    padding: 0,
                    margin: 0,
                  }}
                />
              }
              label={
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
                >
                  <Typography
                    variant="h4"
                    color={'text.primary'}
                    sx={{ width: '100%', fontWeight: 400 }}
                  >
                    {getTitleFromEnum(answer)}
                  </Typography>
                  {getDescriptionFromEnum(answer).length > 0 && (
                    <Typography
                      variant="body4"
                      color={'text.secondary'}
                      sx={{ width: '100%', fontWeight: 500 }}
                    >
                      {getDescriptionFromEnum(answer)}
                    </Typography>
                  )}
                </Box>
              }
              sx={{
                border: '1px solid',
                borderColor: 'background.darker',
                borderRadius: '4px',
                padding: '22px 16px',
                margin: 0,
                gap: '8px',
                width: '100%',
              }}
            />
          </Box>
        ))}
      </Box>
    </FormGroup>
  );
};

export default FreeAgentPersonalCareerForm;

import { Box, Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useModal } from '../../../../../hooks/useModal';
import { Photo } from '../../../../../interfaces/media.interface';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import ButtonsOverlay from '../../../Layouts/ButtonsOverlay';
import MediaPreviewModal from '../../../Modals/MediaPreviewModal';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';

interface PhotoGridProps {
  files: Photo[];
  handleFileRemove: ((id: string) => Promise<void>) | ((id: string) => void);
}

const PhotoGrid = ({ files, handleFileRemove }: PhotoGridProps) => {
  const { isOpen, toggleModal } = useModal();
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('md'));
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { isOwnPage } = useCurrentPageUserId();

  const handleOpen = (index: number) => {
    setCurrentIndex(index);
    toggleModal();
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex < files.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : files.length - 1
    );
  };

  const handleDelete = () => {
    toggleModal();
    handleFileRemove(files[currentIndex].id);
  };

  return (
    <Box sx={{ textAlign: 'center' }} mt="36px">
      <Grid container spacing={2}>
        {files?.map((file, index) => (
          <Grid item xs={6} sm={4} lg={3} xl={2} key={file.id}>
            <Box
              position="relative"
              width="100%"
              bgcolor="secondary2.main"
              sx={{
                paddingBottom: '100%',
                '&:hover .photo-buttons-overlay': { opacity: 1 },
              }}
              onClick={!tabletAndBigger ? () => handleOpen(index) : undefined}
            >
              <img
                src={file.fileUrl}
                alt="Preview"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  maxWidth: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
              <ButtonsOverlay
                nameOfClass="photo-buttons-overlay"
                openHandler={() => handleOpen(index)}
                removeHandler={() => handleFileRemove(file.id)}
                hasRemove={isOwnPage}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <MediaPreviewModal
        files={files}
        currentIndex={currentIndex}
        isOpen={isOpen}
        onClose={() => toggleModal()}
        clickNext={handleNext}
        clickPrev={handlePrev}
        deleteFile={handleDelete}
      />
    </Box>
  );
};

export default PhotoGrid;

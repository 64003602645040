import {
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { LabelTypeEnum } from '../../../../enums/label-enum';
import { ISportBackground } from '../../../../interfaces/sport.interface';
import { IUserSportBackgroundResponse } from '../../../../interfaces/user-sport-background.interface';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { foramtCareerDate } from '../../../../utils/profile-page/format-career-date';
import EditIcon from '../../../Icons/EditIcon';
import Label from '../../../MUIComponents/Label';
import SportBackgroundList from './SportBackgroundList/SportBackgroundList';

interface SportBackgroundFormProps {
  sportBg: IUserSportBackgroundResponse;
  openSportBackgroundEdit: () => void;
}

const SportBackground = (
  { sportBg, openSportBackgroundEdit }: SportBackgroundFormProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const sportBgList: ISportBackground[] = useMemo(() => {
    if (!sportBg) {
      return [];
    }

    return sportBg.userSportBackgroundFiles.map(file => ({
      id: file.id,
      title: file.description,
      icon: file.fileUrl,
      links: file.userSportBackgroundFileLinks.map(link => ({
        url: link.link,
        label: link.link,
      })),
    }));
  }, [sportBg]);

  return (
    <Grid
      ref={ref}
      container
      className="sport-bg"
      display="flex"
      flexDirection="column"
      rowGap={mobileView ? '28px' : '36px'}
    >
      <Grid item>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="sport-bg__name" component="h3" variant="h3">
            {sportBg.sport.name}
          </Typography>
          <Label
            labelType={
              sportBg?.verified
                ? LabelTypeEnum.VERIFIED
                : LabelTypeEnum.UNDER_VERIFICATION
            }
          >
            {sportBg?.verified
              ? LabelTypeEnum.VERIFIED
              : LabelTypeEnum.UNDER_VERIFICATION}
          </Label>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography component="p" typography={'body3'}>
            {foramtCareerDate(sportBg.startCareerDate)} -{' '}
            {foramtCareerDate(sportBg.endCareerDate)}
          </Typography>
          <IconButton onClick={openSportBackgroundEdit}>
            <EditIcon />
          </IconButton>
        </Stack>
      </Grid>

      <Grid
        item
        className="sport-bg__teams-container"
        display="flex"
        flexDirection="column"
        rowGap="36px"
      >
        {sportBg.careers.map(career => (
          <Stack rowGap="36px" key={career.id}>
            <Stack rowGap={2}>
              <Typography className="team" variant="h3">
                {career.leagueName} - {career.teamName}
              </Typography>

              <Typography component="p" typography={'body3'}>
                {foramtCareerDate(career.startDate)} -{' '}
                {foramtCareerDate(career.endDate)}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Grid>

      <Grid item>
        <Typography
          component="p"
          typography={'body3'}
          sx={{ maxWidth: '630px' }}
        >
          {sportBg.description}
        </Typography>
      </Grid>

      <SportBackgroundList
        sportBackgrounds={sportBgList}
        isLoading={false}
        isError={false}
      />
    </Grid>
  );
};

export default forwardRef(SportBackground);

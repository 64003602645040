import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { ICountry, IState } from 'country-state-city';
import {
  CityOption,
  getCitiesOfState,
  getCountries,
  getStatesOfCountry,
} from '../../../../utils/coutry-city-utils';

import { useGetIndustriesQuery } from '../../../../store/apiSlice/jobpost/jobpostApi';
import Input from '../../../../components/MUIComponents/Input';
import JobPostFilterMultiSelect from '../../../JobPostsPage/components/JobPostsSidebar/JobPostsFilters/JobPostFilterMultiSelect';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';

export interface RegistrationFormInputs {
  companyName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  phone: string;
  contactEmail: string;
  website: string;
}

interface CompanyDetailsProps {
  setIndustryFilterState: any;
  setValue: any;
  watch: any;
  register: any;
  industryFilterState: any;
  errors: any;
  control: any;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({
  setIndustryFilterState,
  setValue,
  watch,
  register,
  errors,
  industryFilterState,
  control,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedCountryCode, setSelectedCountryCode] = useState<
    string | undefined
  >();
  const [selectedStateCode, setSelectedStateCode] = useState<
    string | undefined
  >();
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<CityOption[]>([]);
  const [city, setCity] = useState<string>('');

  const { data: sportsIndustries } = useGetIndustriesQuery();

  const handleFilterChange = (industriesArray: string[]) => {
    setIndustryFilterState(industriesArray.join(','));
  };

  const findCountryByName = (
    name: string | null | undefined
  ): ICountry | null => {
    const countries = getCountries();
    return countries.find(country => country.name === name) || null;
  };

  const findStateByName = (name: string | null | undefined): IState | null => {
    return states.find(state => state.name === name) || null;
  };

  const previousCountryRef = useRef<string | null>(null);

  useEffect(() => {
    const subscription = watch(
      (value: Record<'country' | 'state' | 'city', string>) => {
        const countryName = value.country || null;
        if (previousCountryRef.current !== countryName) {
          previousCountryRef.current = countryName;

          const country = findCountryByName(countryName);
          const countryCode = country?.isoCode;

          if (countryCode) {
            setSelectedCountryCode(countryCode);
            const statesOfCountry = getStatesOfCountry(countryCode);
            setStates(statesOfCountry || []);
            setValue('state', value.state || '');
            const citiesOfState = getCitiesOfState(
              selectedCountryCode,
              selectedStateCode
            );
            setCities(citiesOfState || []);
            let preState = statesOfCountry?.find(st => st.name === value.state);
            setSelectedStateCode(preState?.isoCode || undefined);
            setCity(value.city);
          } else {
            setSelectedCountryCode(undefined);
            setStates([]);
            setCities([]);
            setValue('state', '');
            setValue('city', '');
          }
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useEffect(() => {
    if (selectedCountryCode && selectedStateCode) {
      const citiesOfState = getCitiesOfState(
        selectedCountryCode,
        selectedStateCode
      );
      setCities(citiesOfState || []);
      setValue('city', city || '');
    }
  }, [selectedCountryCode, selectedStateCode, setValue]);
  return (
    <Box display="flex" flexDirection="column" alignSelf="center" width="100%">
      <Box display="flex" flexDirection="column" gap="36px">
        <Input
          containerFlex="1"
          caption="Company name:"
          variant="outlined"
          fullWidth
          placeholder="Enter Company name"
          register={register('companyName')}
          error={!!errors.companyName}
          helperText={errors.companyName ? errors.companyName.message : ''}
        />
        <Box flex={1}>
          <JobPostFilterMultiSelect
            label={'industry'}
            placeholder={'Choose one'}
            selectItems={sportsIndustries || []}
            handleFilterChange={handleFilterChange}
            filterState={industryFilterState}
          />
        </Box>
      </Box>
      <Divider sx={{ m: '48px 0' }} />
      <Box display="flex" flexDirection="column" gap="36px">
        <Typography component="h3" variant="h3">
          Company contacts
        </Typography>
        <Input
          containerFlex="1"
          caption="Address:"
          variant="outlined"
          fullWidth
          placeholder="Enter Address"
          register={register('address')}
          error={!!errors.address}
          helperText={errors.address ? errors.address.message : ''}
        />

        <Box
          display="flex"
          flexDirection={mobileView ? 'column' : 'row'}
          gap={mobileView ? '28px' : '22px'}
        >
          <Controller
            name="country"
            control={control}
            render={({ field }) => {
              const countryValue =
                typeof field.value === 'string'
                  ? findCountryByName(field.value)
                  : field.value;

              return (
                <FormControl
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  variant="outlined"
                  fullWidth
                  error={!!errors.country}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      padding: '0 2px',
                      mb: '12px',
                      color: field.disabled ? 'text.disabled' : 'text.primary',
                    }}
                  >
                    Countries:
                  </Typography>
                  <Autocomplete
                    {...field}
                    options={getCountries()}
                    getOptionLabel={option => option.name || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value?.name
                    }
                    value={countryValue || null}
                    onChange={(event, newValue) => {
                      setSelectedCountryCode(newValue?.isoCode);
                      field.onChange(newValue ? newValue.name : null);
                      if (!newValue) {
                        setSelectedStateCode(undefined);
                        setValue('state', '');
                        setValue('city', '');
                        setStates([]);
                        setCities([]);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Select a country"
                        error={!!errors.country}
                        helperText={
                          errors.country ? errors.country.message : ''
                        }
                      />
                    )}
                    sx={{
                      '.MuiInputBase-root': {
                        height: '50px',
                      },
                      '.MuiSvgIcon-root': {
                        bgcolor: 'transparent !important',
                      },
                    }}
                    popupIcon={<ArrowDownIcon />}
                  />
                </FormControl>
              );
            }}
          />

          <Controller
            name="state"
            control={control}
            render={({ field }) => {
              const stateValue =
                typeof field.value === 'string'
                  ? findStateByName(field.value)
                  : field.value;

              return (
                <FormControl
                  fullWidth
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  variant="outlined"
                  error={!!errors.state}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      padding: '0 2px',
                      mb: '12px',
                      color: selectedCountryCode
                        ? 'text.primary'
                        : 'text.disabled',
                    }}
                  >
                    State / Province:
                  </Typography>
                  <Autocomplete
                    {...field}
                    options={states}
                    getOptionLabel={option => option.name || ''}
                    value={stateValue || null}
                    onChange={(event, newValue) => {
                      setSelectedStateCode(newValue?.isoCode);
                      field.onChange(newValue ? newValue.name : null);
                      if (!newValue) {
                        setValue('city', '');
                        setCities([]);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Select a state"
                        error={!!errors.state}
                        helperText={errors.state ? errors.state.message : ''}
                        disabled={!selectedCountryCode}
                      />
                    )}
                    disabled={!selectedCountryCode}
                    sx={{
                      '.MuiInputBase-root': {
                        height: '50px',
                      },
                      '.MuiSvgIcon-root': {
                        bgcolor: 'transparent !important',
                      },
                    }}
                    popupIcon={<ArrowDownIcon />}
                  />
                </FormControl>
              );
            }}
          />
        </Box>

        <Box
          display="flex"
          width={'100%'}
          flexDirection={mobileView ? 'column' : 'row'}
          gap={mobileView ? '28px' : '22px'}
        >
          <Controller
            name="city"
            control={control}
            render={({ field }) => {
              const cityValue =
                typeof field.value === 'string' && field.value !== ''
                  ? { name: field.value, id: '' }
                  : field.value;
              return (
                <FormControl
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  variant="outlined"
                  fullWidth
                  error={!!errors.city}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      padding: '0 2px',
                      mb: '12px',
                      color: selectedStateCode
                        ? 'text.primary'
                        : 'text.disabled',
                    }}
                  >
                    City:
                  </Typography>
                  <Autocomplete
                    {...field}
                    options={selectedStateCode ? cities : []}
                    getOptionLabel={option => option?.name || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value?.name
                    }
                    value={cityValue || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue ? newValue.name : null);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Select a city"
                        error={!!errors.city}
                        helperText={errors.city ? errors.city.message : ''}
                        disabled={!selectedStateCode}
                      />
                    )}
                    disabled={!selectedStateCode}
                    sx={{
                      '.MuiInputBase-root': {
                        height: '50px',
                      },
                      '.MuiSvgIcon-root': {
                        bgcolor: 'transparent !important',
                      },
                    }}
                    popupIcon={<ArrowDownIcon />}
                  />
                </FormControl>
              );
            }}
          />

          <Input
            containerFlex="1"
            caption="ZIP code:"
            variant="outlined"
            fullWidth
            placeholder="Enter ZIP code"
            register={register('zipCode')}
            error={!!errors.zipCode}
            helperText={errors.zipCode ? errors.zipCode.message : ''}
          />
        </Box>

        <Box
          display="flex"
          width={'100%'}
          flexDirection={mobileView ? 'column' : 'row'}
          gap={mobileView ? '28px' : '22px'}
        >
          <Input
            width={'100%'}
            containerFlex="1"
            caption="Phone:"
            variant="outlined"
            fullWidth
            placeholder="Enter Phone"
            register={register('phone')}
            error={!!errors.phone}
            helperText={errors.phone ? errors.phone.message : ''}
          />
          <Input
            width={'100%'}
            containerFlex="1"
            caption="Email:"
            variant="outlined"
            type={'email'}
            fullWidth
            placeholder="Enter Email"
            register={register('contactEmail')}
            error={!!errors.contactEmail}
            helperText={errors.contactEmail ? errors.contactEmail.message : ''}
          />
        </Box>
        <Box display="flex">
          <Box flex={mobileView ? '1' : '0 1 calc(50% - 11px)'}>
            <Input
              containerFlex="1"
              caption="Website:"
              variant="outlined"
              fullWidth
              placeholder="Enter Website"
              register={register('website')}
              error={!!errors.website}
              helperText={errors.website ? errors.website.message : ''}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyDetails;

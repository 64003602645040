const RefreshIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12V9C4 8.20435 4.31607 7.44129 4.87868 6.87868C5.44129 6.31607 6.20435 6 7 6H20M20 6L17 3M20 6L17 9"
        stroke={color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M20 12V15C20 15.7956 19.6839 16.5587 19.1213 17.1213C18.5587 17.6839 17.7957 18 17 18H4M4 18L7 21M4 18L7 15"
        stroke={color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default RefreshIcon;

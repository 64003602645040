import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CameraIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
        fill: props.fill || 'transparent',
      }}
    >
      <path
        d="M9.33333 8.16666H3.5L3.5 23.3333H24.5V8.16666H18.6667V4.66666H9.33333V8.16666Z"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.5 15.1667C10.5 16.0949 10.8687 16.9852 11.5251 17.6415C12.1815 18.2979 13.0717 18.6667 14 18.6667C14.9283 18.6667 15.8185 18.2979 16.4749 17.6415C17.1313 16.9852 17.5 16.0949 17.5 15.1667C17.5 14.2384 17.1313 13.3482 16.4749 12.6918C15.8185 12.0354 14.9283 11.6667 14 11.6667C13.0717 11.6667 12.1815 12.0354 11.5251 12.6918C10.8687 13.3482 10.5 14.2384 10.5 15.1667Z"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default CameraIcon;

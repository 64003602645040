interface ErrorType {
  data?: { message?: string };
  message?: string;
}

export const errorHelper = (error: unknown): string => {
  let errorMessage = 'Failed to fetch data';
  if (error && typeof error === 'object') {
    const err = error as ErrorType;
    if (err.data && err.data.message) {
      errorMessage = err.data.message;
    } else if (err.message) {
      errorMessage = err.message;
    }
  }
  return errorMessage;
};

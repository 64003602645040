import ReactPlayer from 'react-player';
import { useState, useRef, useEffect, useMemo } from 'react';
import { OnProgressProps } from 'react-player/base';
import Control from './components/Control';
import { Box, Container } from '@mui/material';
import { formatTime } from '../../utils/video-player-time-format';

// to do: wrap all svg icons in IconButton
// make controls disappear on mouse move off
// save controls state in local storage or cookie to load player with saved e.g volume mark
// add events: doulbe mouse click on player, F-button, space-button, etc.
// code refactor

let count = 0;
interface VideoPlayerProps {
  height: number | string;
  width: number | string;
  setRef: (node: HTMLVideoElement) => void;
  url: string;
}
const VideoPlayer = ({ height, width, setRef, url }: VideoPlayerProps) => {
  const videoPlayerRef = useRef<ReactPlayer | null>(null);
  const videoPlayerContainerRef = useRef<HTMLDivElement | null>(null);
  const controlRef = useRef<HTMLDivElement>(null);

  const [videoState, setVideoState] = useState({
    playing: true,
    muted: false,
    volume: 0.5,
    volumeMark: 0.3,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });

  //Destructuring the properties from the videoState
  // eslint-disable-next-line
  const { playing, muted, volume, playbackRate, played, seeking, buffer } =
    videoState;
  // to do: add loading icon to the Control component using 'buffer' e.g. buffer && <Loader>
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const currentTime = videoPlayerRef.current
    ? videoPlayerRef.current.getCurrentTime()
    : '00:00';
  const duration = videoPlayerRef.current
    ? videoPlayerRef.current.getDuration()
    : '00:00';

  const { formatCurrentTime, formatDuration } = useMemo(
    () => ({
      formatCurrentTime: formatTime(currentTime),
      formatDuration: formatTime(duration),
    }),
    [currentTime, duration]
  );

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = () => {
    //Rewinds the video player reducing 5
    videoPlayerRef.current?.seekTo(videoPlayerRef.current.getCurrentTime() - 5);
  };

  const handleFastFoward = () => {
    //FastFowards the video player by adding 10
    videoPlayerRef.current?.seekTo(
      videoPlayerRef.current.getCurrentTime() + 10
    );
  };

  const progressHandler = (state: OnProgressProps) => {
    if (count > 10 && controlRef.current) {
      controlRef.current.style.visibility = 'hidden'; // toggling player control container
    } else if (controlRef.current?.style.visibility === 'visible') {
      count += 1;
    }

    if (!seeking) {
      setVideoState({
        ...videoState,
        ...state,
        playing: state.played === 1 ? false : videoState.playing,
      });
    }
  };

  const seekHandler = (e: Event, value: number) => {
    setVideoState({ ...videoState, played: parseFloat(String(value / 1000)) });
    videoPlayerRef.current?.seekTo(parseFloat(String(value / 1000)));
  };

  const seekMouseUpHandler = (e: Event, value: number) => {
    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current?.seekTo(value / 1000);
  };

  const volumeChangeHandler = (e: Event, value: number) => {
    const newVolume = parseFloat(String(value)) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
    });
  };

  const volumeSeekUpHandler = (e: Event, value: number) => {
    const newVolume = parseFloat(String(value)) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false,
    });
  };

  const muteHandler = () => {
    //Mutes the video player
    setVideoState(prev => ({
      ...videoState,
      muted: !videoState.muted,
      volume: !videoState.muted ? 0 : prev.volumeMark,
      volumeMark: !videoState.muted ? prev.volume : prev.volumeMark,
    }));
  };

  const onSeekMouseDownHandler = (e: React.MouseEvent) => {
    setVideoState({ ...videoState, seeking: true });
  };

  const mouseMoveHandler = () => {
    if (controlRef.current?.style.visibility) {
      controlRef.current.style.visibility = 'visible';
      count = 0;
    }
  };

  const onMouseLeaveHandler = () => {
    if (controlRef.current?.style.visibility) {
      controlRef.current.style.visibility = 'hidden';
    }
  };

  const bufferStartHandler = () => {
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    setVideoState({ ...videoState, buffer: false });
  };

  // Fullscreen toggle
  const handleToggleFullscreen = () => {
    const doc = document as any;
    // const playerElement = videoPlayerContainerRef.current?.querySelector(".player") as any;
    const playerElement = videoPlayerContainerRef.current as any;

    if (playerElement) {
      if (isFullscreen) {
        // Exit fullscreen only if document is in fullscreen mode
        if (
          doc.fullscreenElement ||
          doc.webkitFullscreenElement ||
          doc.msFullscreenElement
        ) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (doc.webkitExitFullscreen) {
            /* Safari */
            doc.webkitExitFullscreen();
          } else if (doc.msExitFullscreen) {
            /* IE11 */
            doc.msExitFullscreen();
          }

          setIsFullscreen(false);
        }
      } else {
        // Enter fullscreen
        if (playerElement.requestFullscreen) {
          playerElement.requestFullscreen();
        } else if (playerElement.webkitRequestFullscreen) {
          /* Safari */
          playerElement.webkitRequestFullscreen();
        } else if (playerElement.msRequestFullscreen) {
          /* IE11 */
          playerElement.msRequestFullscreen();
        }

        setIsFullscreen(true);
      }
    }
  };

  // Listen for 'fullscreenchange' event to update fullscreen state when user presses escape
  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreenActive =
        !!document.fullscreenElement ||
        !!(document as any).webkitFullscreenElement ||
        !!(document as any).msFullscreenElement;

      setIsFullscreen(isFullscreenActive);
    };

    // Add event listeners for fullscreen change
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // Safari
    document.addEventListener('msfullscreenchange', handleFullscreenChange); // IE11

    return () => {
      // Cleanup event listeners
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      );
      document.removeEventListener(
        'msfullscreenchange',
        handleFullscreenChange
      );
    };
  }, []);

  return (
    <Container
      className="video_container"
      disableGutters
      sx={{ maxWidth: 'unset !important' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          className="player__wrapper"
          component="div"
          position="relative"
          onMouseMove={mouseMoveHandler}
          onMouseLeave={onMouseLeaveHandler}
          width={width}
          height={height}
          minWidth={{ md: 500 }}
          ref={videoPlayerContainerRef}
        >
          <ReactPlayer
            ref={node => {
              videoPlayerRef.current = node;

              if (node) {
                setRef(node.getInternalPlayer() as HTMLVideoElement);
              }
            }}
            className="player"
            url={url}
            width="100%"
            height="100%"
            playing={playing}
            volume={volume}
            muted={muted}
            onProgress={progressHandler}
            progressInterval={100}
            onBuffer={bufferStartHandler}
            onBufferEnd={bufferEndHandler}
          />

          <Control
            controlRef={controlRef}
            isFullscreen={isFullscreen}
            onPlayPause={playPauseHandler}
            playing={playing}
            onRewind={rewindHandler}
            onForward={handleFastFoward}
            played={played}
            onSeek={seekHandler}
            onSeekMouseUp={seekMouseUpHandler}
            volume={volume}
            onVolumeChangeHandler={volumeChangeHandler}
            onVolumeSeekUp={volumeSeekUpHandler}
            mute={muted}
            onMute={muteHandler}
            // playRate={playbackRate}
            duration={formatDuration}
            currentTime={formatCurrentTime}
            onMouseSeekDown={onSeekMouseDownHandler}
            handleToggleFullscreen={handleToggleFullscreen}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default VideoPlayer;

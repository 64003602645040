import React from 'react';
import { Photo } from '../../../interfaces/media.interface';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import TrashRedIcon from '../../Icons/TrashRedIcon';

interface CompanyAboutFileProps {
  files: Photo[];
  handleFileRemove: (id: string) => void;
}

const CompanyAboutPdfFile = ({
  files,
  handleFileRemove,
}: CompanyAboutFileProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {files.map(file => (
        <Card
          key={file.id}
          elevation={3}
          sx={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}
        >
          <CardContent>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={'12px'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mb={'12px'}
            >
              {' '}
              <Box display="flex" alignItems="center" gap="12px">
                <PictureAsPdfIcon color="primary" fontSize="large" />
                <Typography variant="h6" component="div">
                  {file.fileName || 'PDF File'}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'12px'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <IconButton onClick={() => handleFileRemove(file.id)}>
                  <TrashRedIcon />
                </IconButton>
              </Box>
            </Box>

            <Box
              sx={{
                height: {
                  xs: '200px',
                  sm: '300px',
                  md: '400px',
                  lg: '500px',
                },
                '@media (max-width: 320px)': {
                  height: '150px',
                },
              }}
            >
              <embed
                src={file.fileUrl}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default CompanyAboutPdfFile;

import { useEffect } from 'react';
import { useSendMessageMutation } from '../../store/apiSlice/chat/chatApi';
import { useSendGroupMessageMutation } from '../../store/apiSlice/chat/groupApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatType,
  setMessageSending,
} from '../../store/apiSlice/chat/chatSlice';
import { ChatTypeEnum } from '../../enums/chat-enum';

const useSendMessage = () => {
  const chatType = useSelector(getChatType);
  const dispatch = useDispatch();

  const [sendPersonalMesssage, { isLoading: isPersonalMessageSending }] =
    useSendMessageMutation();
  const [sendGroupMessage, { isLoading: isGroupMessageSending }] =
    useSendGroupMessageMutation();

  useEffect(() => {
    if (chatType === ChatTypeEnum.PERSONAL) {
      dispatch(setMessageSending(isPersonalMessageSending));
    } else if (chatType === ChatTypeEnum.GROUP) {
      dispatch(setMessageSending(isGroupMessageSending));
    }
  }, [dispatch, chatType, isGroupMessageSending, isPersonalMessageSending]);

  return chatType === ChatTypeEnum.PERSONAL
    ? sendPersonalMesssage
    : sendGroupMessage;
};

export default useSendMessage;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddGreyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 24, height: 24, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="#727F93"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 5v14M5 12h14"
      />
    </SvgIcon>
  );
};

export default AddGreyIcon;

import { Box } from '@mui/material';

interface IHTMLContentProps {
  content: string;
}

const HTMLContent = ({ content }: IHTMLContentProps) => {
  return (
    <Box
      lineHeight={'20.3px'}
      sx={{
        textAlign: 'justify',
        width: { xs: '100%', lg: '85%' },
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        lineHeight: '19.6px',
        color: 'text.secondary',
        '& h1 & h2 & h3 & h4 & h5 & h6': {
          fontWeight: 600,
          lineHeight: '20px',
        },
        '& p': {
          fontWeight: 400,
          color: 'text.secondary',
        },
        '& ul': {
          listStyleType: 'disc',
          listStyle: 'inside',
          '& li::marker': { color: 'text.primary' },
        },
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    ></Box>
  );
};

export default HTMLContent;

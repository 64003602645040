import BaseModal, {
  ModalCloseReason,
} from '../../../../components/MUIComponents/BaseModal';
import { Box, Input, Typography, useMediaQuery } from '@mui/material';
import IconWithBackground from '../../../../components/MUIComponents/IconWithBackground';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import RefreshIcon from '../../../../components/Icons/RefreshIcon';
import TrashBoxIcon from '../../../../components/Icons/TrashBoxIcon';
import WritingField from './WritingField';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useModal } from '../../../../hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMessageSending,
  getReplyingTo,
  getSelectedChat,
  resetForwardingFrom,
  resetPage,
  resetReplyingTo,
} from '../../../../store/apiSlice/chat/chatSlice';
import { AcceptedFileTypeEnum } from './UploadFileMenu';
import FileIcon from '../../../../components/Icons/FileIcon';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { transformFileSize } from '../../../../utils/helper/transformFileSize';
import { IMessageAttributes } from '../Message/Message';
import useSendMessage from '../../../../hooks/chat/useSendMessage';
import UploadedVideoBlock from './UploadedVideoBlock';

interface IUploadFileModalProps {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  acceptedFileType: AcceptedFileTypeEnum;
  fileInputRef: MutableRefObject<HTMLInputElement | undefined>;
  onClose: () => void;
  messageInput: string;
  clearMessageInput?: () => void;
}

const UploadFileModal = ({
  files,
  setFiles,
  acceptedFileType,
  fileInputRef,
  onClose,
  clearMessageInput,
  messageInput,
}: IUploadFileModalProps) => {
  const { theme } = useThemeContext();
  const [description, setDescription] = useState<string>(messageInput);
  const [updatingFileIndex, setUpdatingFileIndex] = useState<number | null>(
    null
  );

  const updateFileInputRef = useRef<HTMLInputElement>();
  const videoPreviewRef = useRef<HTMLVideoElement>(null);

  const { isOpen, toggleModal } = useModal();
  const selectedChat = useSelector(getSelectedChat)!;
  const isMessageSending = useSelector(getMessageSending);
  const replyingTo = useSelector(getReplyingTo);
  const sendMessage = useSendMessage();
  const dispatch = useDispatch();

  const handleCloseModal = (_?: unknown, reason?: ModalCloseReason) => {
    if (reason !== 'backdropClick') {
      toggleModal();
    }
  };

  const handleFileChange = () => {
    const files = Array.from(fileInputRef.current?.files || []) as File[];

    if (files) {
      setFiles(prev => [...prev, ...files]);
      toggleModal();
    }
  };

  const handleDeleteFile = (index: number) => () => {
    if (files.length === 1) toggleModal();
    setFiles(prev => prev.slice(0, index).concat(prev.slice(index + 1)));
  };

  const handleUpdateFile = (index: number) => () => {
    setUpdatingFileIndex(index);

    if (updateFileInputRef.current) {
      updateFileInputRef.current.accept = acceptedFileType;
      updateFileInputRef.current.click();
    }
  };

  const handleUpdateFileChange = () => {
    const file = updateFileInputRef.current?.files?.item(0);

    if (file && updatingFileIndex !== null) {
      if (file.type.startsWith('video') && videoPreviewRef.current) {
        videoPreviewRef.current.load();
      }

      setFiles(prev => [
        ...prev.slice(0, updatingFileIndex),
        file,
        ...prev.slice(updatingFileIndex + 1),
      ]);

      setUpdatingFileIndex(null);
    }
  };

  const handleSendMessage = useCallback(async () => {
    if (!isMessageSending)
      try {
        const fd = new FormData();
        const attributes: IMessageAttributes = {
          withFile: true,
          fileType: acceptedFileType,
          replyTo: replyingTo || undefined,
        };

        fd.append('attributes', JSON.stringify(attributes));
        fd.append('from', selectedChat.userId);
        fd.append('body', description.trim());
        fd.append('mediaFile', files[0]);

        clearMessageInput && clearMessageInput();
        setDescription('');

        await sendMessage({
          chatId: selectedChat.id,
          formData: fd,
        })
          .unwrap()
          .then(() => {
            dispatch(resetPage());
            dispatch(resetReplyingTo());
            dispatch(resetForwardingFrom());
            toggleModal();
            onClose();
          });
      } catch (error) {
        console.log(error);
      }
  }, [
    acceptedFileType,
    description,
    dispatch,
    files,
    isMessageSending,
    onClose,
    replyingTo,
    selectedChat,
    sendMessage,
    toggleModal,
  ]);

  return (
    <>
      <Input
        inputRef={fileInputRef}
        type="file"
        sx={{ display: 'none' }}
        onInput={handleFileChange}
        inputProps={{ multiple: true }}
      />
      <Input
        inputRef={updateFileInputRef}
        type="file"
        sx={{ display: 'none' }}
        onInput={handleUpdateFileChange}
      />
      <BaseModal
        boxSx={{
          border: 'none',
          borderRadius: '1px',
          p: 0,
          m: 0,
          width: 'content-fit',
        }}
        disableDefaultFooter
        disableEscapeButton
        disableContentMargin
        isOpen={isOpen}
        toggle={handleCloseModal}
      >
        <Box
          p={'1.375rem 1rem'}
          display={'flex'}
          flexDirection={'column'}
          gap={'3rem'}
        >
          <Box
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            gap={'1.375rem'}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="body1">
                Send{' '}
                {acceptedFileType === AcceptedFileTypeEnum.DOCUMENT
                  ? 'document'
                  : acceptedFileType === AcceptedFileTypeEnum.IMAGE
                    ? 'image'
                    : 'video'}
              </Typography>
              <Box
                sx={{ '&:hover': { cursor: 'pointer' } }}
                onClick={() => handleCloseModal()}
              >
                <IconWithBackground size="2.5rem" icon={<CloseIcon />} />
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              width={'100%'}
              alignItems={'center'}
              gap={
                acceptedFileType === AcceptedFileTypeEnum.DOCUMENT
                  ? '0'
                  : '1.375rem'
              }
              sx={{
                overflowY: 'scroll',
                maxHeight: '25rem',
                scrollbarWidth: 'none',
              }}
            >
              {files.length > 0 &&
                files.map((file, index) => (
                  <Box
                    width={theme.breakpoints.down('md') ? '100%' : '21.25rem'}
                    height={
                      acceptedFileType === AcceptedFileTypeEnum.DOCUMENT
                        ? 'auto'
                        : '21.25rem'
                    }
                    position={'relative'}
                    key={index}
                    display={'flex'}
                    justifyContent={'space-between'}
                    sx={{
                      '&:not(&:hover) > #helper-buttons': {
                        visibility:
                          acceptedFileType === AcceptedFileTypeEnum.DOCUMENT
                            ? ''
                            : 'hidden',
                      },
                    }}
                  >
                    {acceptedFileType === AcceptedFileTypeEnum.IMAGE && (
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        src={URL.createObjectURL(file)}
                        alt=""
                      />
                    )}
                    {acceptedFileType === AcceptedFileTypeEnum.VIDEO && (
                      <UploadedVideoBlock
                        file={file}
                        acceptedFileType={acceptedFileType}
                      />
                    )}
                    {acceptedFileType === AcceptedFileTypeEnum.DOCUMENT && (
                      <Box
                        display={'flex'}
                        borderRadius={'1px'}
                        p={'0.375rem 0'}
                        gap={'0.75rem'}
                        alignItems={'center'}
                      >
                        <IconWithBackground
                          bgcolor={'text.secondary'}
                          size={'2.5rem'}
                          icon={
                            <FileIcon color={theme.palette.background.darker} />
                          }
                        />
                        <Box display={'flex'} flexDirection={'column'}>
                          <Typography
                            color={'text.primary'}
                            variant={'body3'}
                            fontWeight={700}
                            maxWidth={'20ch'}
                            noWrap
                          >
                            {file.name}
                          </Typography>
                          <Typography
                            color={'text.secondary'}
                            variant="body4"
                            fontWeight={400}
                          >
                            {transformFileSize(file.size)}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box
                      id={'helper-buttons'}
                      zIndex={1}
                      top={0}
                      right={0}
                      p={'0.75rem'}
                      position={
                        acceptedFileType === AcceptedFileTypeEnum.DOCUMENT
                          ? 'static'
                          : 'absolute'
                      }
                      display={'flex'}
                      gap={'0.375rem'}
                    >
                      <Box
                        onClick={handleUpdateFile(index)}
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <IconWithBackground
                          size="2.5rem"
                          bgcolor={
                            acceptedFileType !== AcceptedFileTypeEnum.DOCUMENT
                              ? 'text.primary'
                              : 'background.light'
                          }
                          icon={
                            <RefreshIcon
                              color={
                                acceptedFileType ===
                                AcceptedFileTypeEnum.DOCUMENT
                                  ? theme.palette.text.secondary
                                  : undefined
                              }
                            />
                          }
                        />
                      </Box>
                      <Box
                        onClick={handleDeleteFile(index)}
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <IconWithBackground
                          size="2.5rem"
                          bgcolor={
                            acceptedFileType !== AcceptedFileTypeEnum.DOCUMENT
                              ? 'text.primary'
                              : 'background.light'
                          }
                          icon={
                            <TrashBoxIcon
                              color={
                                acceptedFileType ===
                                AcceptedFileTypeEnum.DOCUMENT
                                  ? theme.palette.text.secondary
                                  : undefined
                              }
                            />
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
            <WritingField
              disableReplyView
              onSend={handleSendMessage}
              disableFileIcon
              disableSpacing
              onChange={e => setDescription(e.target.value)}
              defaultMessageInput={messageInput}
            />
          </Box>
        </Box>
      </BaseModal>
    </>
  );
};

export default UploadFileModal;

import { SvgIcon, SvgIconProps } from '@mui/material';

const ApprovedProfileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 28, height: 28, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        d="M9.33301 8.16667C9.33301 9.40434 9.82467 10.5913 10.6998 11.4665C11.575 12.3417 12.762 12.8333 13.9997 12.8333C15.2374 12.8333 16.4243 12.3417 17.2995 11.4665C18.1747 10.5913 18.6663 9.40434 18.6663 8.16667C18.6663 6.92899 18.1747 5.742 17.2995 4.86683C16.4243 3.99167 15.2374 3.5 13.9997 3.5C12.762 3.5 11.575 3.99167 10.6998 4.86683C9.82467 5.742 9.33301 6.92899 9.33301 8.16667Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 24.5V22.1667C7 20.929 7.49167 19.742 8.36683 18.8668C9.242 17.9917 10.429 17.5 11.6667 17.5H16.3333"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M17.5 22.1667L19.8333 24.5L24.5 19.8333"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default ApprovedProfileIcon;

import {
  Avatar,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import React, { useMemo, useState } from 'react';
import {
  useAcceptJobApplyMutation,
  useGetAppliedUserByApplicationIdQuery,
} from '../../../../store/apiSlice/jobpost/jobUserApplyApi';
import { IUser } from '../../../../interfaces/user.interface';
import { Link } from 'react-router-dom';
import Loader from '../../../../components/MUIComponents/Loader';
import { IAppliedUser } from '../../../../interfaces/jobpost.interface';
import { CanceledReasonEnum } from '../../../../enums/cancel-reason-type-enum';
import EmptyFileIcon from '../../../../components/Icons/EmptyFileIcon';

interface ApplicationInfoModalProps {
  isOpen?: boolean;
  onClose: () => void;
  applicationId: string;
  userData: IUser;
  onReject: () => void;
  application: IAppliedUser;
}

const ApplicationInfoModal = ({
  onClose,
  isOpen = false,
  applicationId,
  userData,
  onReject,
  application,
}: ApplicationInfoModalProps) => {
  const { data: appliedUserData } =
    useGetAppliedUserByApplicationIdQuery(applicationId);
  const [acceptJobApply, { isLoading }] = useAcceptJobApplyMutation();
  const [documentPreviewModal, setDocumentPreviewModal] = useState(false);
  const [documentPreview, setDocumentPreview] = useState<any>(null);

  function getCanceledReasonText(reason: CanceledReasonEnum | null): string {
    switch (reason) {
      case CanceledReasonEnum.NO_LONGER_INTERESTED:
        return 'No longer interested';
      case CanceledReasonEnum.ACCEPTED_ANOTHER_JOB_OFFER:
        return 'Accepted another job offer';
      case CanceledReasonEnum.DECIDED_THE_ROLE_ISNT_A_GOOD_FIT:
        return 'Decided the role isn’t a good fit';
      case CanceledReasonEnum.REALIZED_I_DONT_MEET_THE_JOB_REQUIREMENTS:
        return 'Realized I don’t meet the job requirements';
      case CanceledReasonEnum.FOUND_ERRORS_IN_MY_APPLICATION:
        return 'Found errors in my application';
      case CanceledReasonEnum.OTHER:
        return 'Other';
      default:
        return 'Unknown reason';
    }
  }

  const modalTitle = useMemo(() => {
    return (
      <>
        <Typography variant="h2" textAlign="left" mb={'22px'}>
          {application?.isCanceled
            ? 'Application canceled'
            : 'Application details'}
        </Typography>
        <Divider sx={{ m: { xs: '0 -12px', lg: '0 -36px' } }} />
      </>
    );
  }, []);

  const handleReject = () => {
    onClose();
    onReject();
  };

  const handleAccept = async () => {
    try {
      if (applicationId) await acceptJobApply(applicationId);
    } catch (e) {}
    onClose();
  };

  const modalFooter = useMemo(() => {
    return (
      <Box mt={3}>
        <Grid
          container
          justifyContent="start"
          flexDirection={{ xs: 'column', lg: 'row' }}
          gap={'16px'}
          width={'100%'}
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={handleAccept}
            >
              ACCEPT
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={handleReject}
            >
              Reject
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const isPdfFile = (fileName: string) =>
    fileName ? fileName?.toLowerCase()?.endsWith('.pdf') : false;

  return (
    <>
      <BaseModal
        modalSx={{ borderColor: 'transparent !important' }}
        isOpen={documentPreviewModal}
        toggle={() => setDocumentPreviewModal(false)}
        disableDefaultFooter={true}
      >
        <Box
          sx={{
            height: {
              xs: '200px',
              sm: '300px',
              md: '400px',
              lg: '500px',
            },
            '@media (max-width: 320px)': {
              height: '150px',
            },
          }}
        >
          {isPdfFile(documentPreview?.fileUrl) ? (
            <embed
              src={documentPreview?.fileUrl}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          ) : (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              <img
                src={documentPreview?.fileUrl}
                alt={documentPreview?.fileName}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
          )}
        </Box>
      </BaseModal>
      <BaseModal
        modalSx={{ borderColor: 'transparent !important' }}
        header={{ component: modalTitle }}
        isOpen={isOpen}
        toggle={onClose}
        footer={{ component: application?.isCanceled ? <></> : modalFooter }}
        styleEscapeBtnStylesBelow={{ top: 26 }}
      >
        {isLoading ? (
          <Loader color="primary" />
        ) : (
          <Stack gap={'36px'}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              flexWrap={'wrap'}
              p={'16px'}
              border={'1px solid #D1D6DD'}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                flexDirection={'row'}
                gap={'16px'}
                maxWidth={'100%'}
              >
                <ListItemAvatar
                  sx={{
                    width: '64px',
                    height: '64px',
                    minWidth: '64px',
                    minHeight: '64px',
                  }}
                >
                  <Avatar
                    alt={`${userData?.firstName || ''}  ${userData?.lastName || ''}`}
                    src={
                      userData && userData?.optimizedUserAvatarBaseUrl
                        ? `data:image/jpeg;base64,${userData.optimizedUserAvatarBaseUrl}`
                        : ''
                    }
                    sx={{ width: '100%', height: '100%' }}
                  />
                </ListItemAvatar>
                <ListItemText
                  key={userData.id}
                  sx={{
                    color: 'text.primary',
                    maxWidth: '350px',
                  }}
                  primary={`${userData?.firstName || ''}  ${userData?.lastName || ''}`}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: {
                        xs: '14px',
                        md: '14px',
                        lg: '18px',
                      },
                    },
                  }}
                  secondaryTypographyProps={{
                    sx: {
                      textAlign: 'left',
                      lineHeight: '15px',
                      fontSize: '14px',
                    },
                  }}
                  secondary={
                    userData &&
                    userData.sports &&
                    userData.sports.length > 0 &&
                    userData.sports.map((sport, idx, arr) => (
                      <React.Fragment key={sport.id}>
                        <span>{sport.name}</span>
                        {idx < arr.length - 1 && ', '}
                      </React.Fragment>
                    ))
                  }
                />
              </Box>
              <Link to={`/${userData.id}`}>
                <CustomButton
                  variant="outlined"
                  variantType={ButtonTypeEnum.SECONDARY}
                  sx={{ p: '15px 16px' }}
                >
                  view profile
                </CustomButton>
              </Link>
            </Stack>

            {application?.isCanceled ? (
              <>
                <Box>
                  <Typography component={'h4'} variant="badge2" mb={'22px'}>
                    CANCELED REASON
                  </Typography>
                  <Typography variant="body3" textAlign={'justify'}>
                    {getCanceledReasonText(
                      application?.canceledReason as CanceledReasonEnum
                    )}
                  </Typography>
                </Box>
                {application?.canceledNote && (
                  <>
                    <Divider />
                    <Box>
                      <Typography component={'h4'} variant="badge2" mb={'22px'}>
                        NOTES
                      </Typography>
                      <Typography variant="body3" textAlign={'justify'}>
                        {application?.canceledNote}
                      </Typography>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <>
                {' '}
                <Box>
                  <Typography component={'h4'} variant="badge2" mb={'22px'}>
                    INTRODUCTION
                  </Typography>
                  <Typography variant="body3" textAlign={'justify'}>
                    {appliedUserData?.appliedUser.introduction}
                  </Typography>
                </Box>
                <Divider />
                {appliedUserData &&
                  appliedUserData.appliedUser.appliedUserFiles.length > 0 && (
                    <Box>
                      <Typography component={'h4'} variant="badge2" mb={'22px'}>
                        DOCUMENTS
                      </Typography>
                      <List
                        sx={{
                          p: 0,
                          display: 'flex',
                          flexWrap: 'wrap',
                          flexDirection: 'row',
                          gap: '16px',
                        }}
                      >
                        {appliedUserData.appliedUser.appliedUserFiles.map(
                          file => {
                            const isPdf = isPdfFile(file.fileName);

                            const handleClick = () => {
                              if (!isPdf) {
                                setDocumentPreview(file);
                                setDocumentPreviewModal(true);
                              } else {
                                window.open(file.fileUrl, '_blank');
                              }
                            };

                            return (
                              <ListItem
                                key={file.id}
                                onClick={handleClick}
                                sx={{
                                  p: 0,
                                  flexDirection: 'column',
                                  alignItems: 'start',
                                  width: '128px',
                                  cursor: 'pointer',
                                }}
                              >
                                <Box
                                  width={'128px'}
                                  height={'128px'}
                                  bgcolor={'#DDE1E8'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  overflow="hidden"
                                >
                                  {!isPdf ? (
                                    <CardMedia
                                      component="img"
                                      height="128"
                                      image={file.fileUrl}
                                      alt={file.fileName}
                                      sx={{
                                        width: '128px',
                                        height: '128px',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                    >
                                      <EmptyFileIcon
                                        sx={{
                                          stroke: '#727F93',
                                        }}
                                        viewBox={'0 0 28 28'}
                                        width={'28px'}
                                        height={'28px'}
                                      />
                                    </Box>
                                  )}
                                </Box>
                                <Typography
                                  variant="body2"
                                  fontWeight={700}
                                  sx={{
                                    paddingTop: '7px',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    overflow: 'auto',
                                  }}
                                >
                                  {decodeURIComponent(file?.fileName)}
                                </Typography>
                              </ListItem>
                            );
                          }
                        )}
                      </List>
                    </Box>
                  )}
              </>
            )}
            <Divider />
          </Stack>
        )}
      </BaseModal>
    </>
  );
};

export default ApplicationInfoModal;

import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../../theme/theme';
import LocationIcon from '../../../../components/Icons/LocationIcon';
import PersonalInfoIcon from '../../../../components/Icons/PersonalInfoIcon';
import PhotosIcon from '../../../../components/Icons/PhotosIcon';
import VideosIcon from '../../../../components/Icons/VideosIcon';

interface IProfileScoreCard {
  scoreKey: string;
}

const ProfileScoreCard = (props: IProfileScoreCard) => {
  const { scoreKey } = props;
  const navigate = useNavigate();

  const getCardData = (key: string) => {
    switch (key) {
      case 'accountVerified':
        return {
          title: 'Verify your account',
          description: `
            Verifying your account increases trust from potential employers and clients.
            It shows that your profile information is accurate, which may help you stand out in searches.
            Account verification is an important step toward building a credible online presence.
          `,
          icon: <LocationIcon />,
          points: '20 points',
          navigateTo: '/verify-account',
          buttonText: 'verify now',
        };
      case 'addedAvatar':
        return {
          title: 'Add an avatar',
          description: `
            Your profile picture is one of the first things employers and colleagues see.
            Having a professional, clear avatar makes your profile more approachable and recognizable.
            Make sure your avatar reflects the professional image you want to present.
          `,
          icon: <PersonalInfoIcon />,
          points: '20 points',
          navigateTo: '/profile#personal-info',
          buttonText: 'add avatar',
        };
      case 'completePersonalInfo':
        return {
          title: 'Complete personal information',
          description: `
            Keeping your personal information up-to-date ensures that employers can easily contact you.
            This includes adding your phone number, email, and relevant social profiles.
            A complete profile increases your chances of being noticed for the right opportunities.
          `,
          icon: <PersonalInfoIcon />,
          points: '20 points',
          navigateTo: '/profile#personal-info',
          buttonText: 'update info',
        };
      case 'uploadedPhotos':
        return {
          title: 'Upload photos',
          description: `
            High-quality photos can enhance your profile's appeal, especially if they highlight your work or projects.
            Images speak louder than words – showcase your skills, experiences, or any professional achievements visually.
            Photos help employers understand your background better and make your profile stand out.
          `,
          icon: <PhotosIcon />,
          points: '20 points',
          navigateTo: '/profile#photos',
          buttonText: 'upload photos',
        };
      case 'uploadedVideos':
        return {
          title: 'Upload videos',
          description: `
            Videos offer a dynamic way to present your skills, experiences, and projects.
            Whether it's a project demonstration or a short introduction, videos bring a personal touch to your profile.
            Adding videos can significantly increase your profile's attractiveness and help you connect with employers.
          `,
          icon: <VideosIcon />,
          points: '20 points',
          navigateTo: '/profile#videos',
          buttonText: 'upload videos',
        };
      default:
        return {
          title: 'Profile Score Item',
          description: `
            This section of your profile needs improvement. Completing this part will help you achieve a higher score,
            making your profile more visible and appealing to employers and clients.
          `,
          icon: <LocationIcon />,
          points: '0 points',
          navigateTo: '/profile',
          buttonText: 'edit profile',
        };
    }
  };

  const cardData = getCardData(scoreKey);

  const onEditProfileClick = useCallback(
    () => navigate(cardData.navigateTo),
    [navigate, cardData.navigateTo]
  );

  return (
    <Container
      disableGutters
      sx={{
        p: '22px 16px',
        backgroundColor: '#F6F7F8',
        minWidth: { xs: 300, lg: 'unset' },
      }}
    >
      <Box component="header" mb="12px">
        <Grid container height="50px" justifyContent="space-between">
          <Grid item width={50} height={50}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                fontSize: '30px',
                color: '#3ACC40',
                borderRadius: '50%',
                backgroundColor: '#E8EBEF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {cardData.icon}
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                border: '1px solid #3ACC40',
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 79,
                height: 24,
              }}
            >
              <Typography fontSize="10px" color="#3ACC40">
                {cardData.points}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box component="article">
        <Typography component="header" variant="h6" color="#142237" mb="16px">
          {cardData.title}
        </Typography>
        <Typography variant="caption" color={colors.dark60} mb="16px">
          {cardData.description.trim()}
        </Typography>
      </Box>
      <Box component="footer" mt="28px">
        <Button
          onClick={onEditProfileClick}
          variant="outlined"
          sx={{
            borderRadius: 0,
            color: '#142237',
            borderColor: '#D1D6DD',
            fontSize: 10,
            padding: '12px 16px',
          }}
        >
          {cardData.buttonText}
        </Button>
      </Box>
    </Container>
  );
};

export default ProfileScoreCard;

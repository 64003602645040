import { Box } from '@mui/material';
import { Videos } from '../../../../components/Media';

const VideosTab = () => {
  return (
    <Box id="videos">
      <Videos />
    </Box>
  );
};

export default VideosTab;

import React from 'react';
import CompanyDetailsForm from './CompanyDetailsForm';
import UploadDocument, {
  FileUploadFormInputs,
} from '../../../../components/shared/UploadDocumentForm/UploadDocument';
import { CompanyDetailsFormInputs } from './CompanyRegistration';
import { SubmitHandler } from 'react-hook-form';
import { IUserFileDB } from '../../../../interfaces/file.interface';

interface ICompanyStepsWithAuthProps {
  step: number;
  onSubmitCompanyDetails: SubmitHandler<CompanyDetailsFormInputs>;
  formData: Partial<CompanyDetailsFormInputs>;
  setStep: React.Dispatch<React.SetStateAction<number>>;

  onSubmitFiles: SubmitHandler<FileUploadFormInputs>;
  formDataFiles: FileUploadFormInputs;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedFiles: IUserFileDB[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<IUserFileDB[]>>;
  idConfirmationFilesIsLoading: boolean;
  idConfirmationFilesIsError: boolean;
  idConfirmationFilesError: any;
  idConfirmationFilesResponseData: any;
  isCompanyInfoExists: boolean;
}
const CompanyStepsNeededAuth = (props: ICompanyStepsWithAuthProps) => {
  const {
    step,
    onSubmitCompanyDetails,
    formData,
    setStep,

    onSubmitFiles,
    formDataFiles,
    files,
    setFiles,
    uploadedFiles,
    setUploadedFiles,
    idConfirmationFilesIsLoading,
    idConfirmationFilesIsError,
    idConfirmationFilesError,
    idConfirmationFilesResponseData,
    isCompanyInfoExists,
  } = props;

  return (
    <>
      {step === 2 && (
        <CompanyDetailsForm
          onSubmitCompanyDetails={onSubmitCompanyDetails}
          formData={formData as CompanyDetailsFormInputs}
          setStep={setStep}
          step={step}
        />
      )}
      {step === 3 && (
        <UploadDocument
          title={'Upload documents'}
          contentText={
            <>
              To complete your registration, please upload a valid DUNS Number
              and proof of address for verification.
              <br />
              This ensures the authenticity of your profile.
            </>
          }
          dragAndDropContentText={
            <>
              Drag and drop your DUNS Number and proof of address here to
              complete your <br />
              registration. Ensure the document is clear and legible for
              verification purposes.
            </>
          }
          onSubmitFiles={onSubmitFiles}
          formData={formDataFiles as FileUploadFormInputs}
          setStep={setStep}
          step={step}
          files={files}
          setFiles={setFiles}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          isLoading={idConfirmationFilesIsLoading}
          isError={idConfirmationFilesIsError}
          error={idConfirmationFilesError}
          responseData={idConfirmationFilesResponseData}
          maxFiles={5}
          needBackButton={!isCompanyInfoExists}
        />
      )}
    </>
  );
};

export default CompanyStepsNeededAuth;

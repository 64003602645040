import axios from 'axios';

const axiosService = axios.create({
  baseURL: '',
});

export const reportService = {
  setReportToS3OneTimeLink: async (
    link: string,
    fileBlob: Blob,
    type: string
  ): Promise<void> => {
    const response = await axiosService.put(link, fileBlob, {
      headers: {
        'Content-Type': type,
      },
    });
    return response.data;
  },
};

import { Box, IconButton } from '@mui/material';
import { useCurrentPageUserId } from '../../../hooks/useCurrentPageUserId';
import {
  useGetBannerByIdQuery,
  useDeleteMyBannerMutation,
} from '../../../store/apiSlice/userApi';
import TrashIcon from '../../../components/Icons/TrashIcon';
import EditIcon from '../../../components/Icons/EditIcon';
import IconRoundBackground from '../../../components/MUIComponents/IconRoundBackground';
import Tooltip from '@mui/material/Tooltip';
import PlusIcon from '../../../components/Icons/PlusIcon';
import CropBackgroundModal from './modals/CropBackgroundModal';
import { useModal } from '../../../hooks/useModal';

const ProfileBackground = () => {
  const { isOwnPage, userId } = useCurrentPageUserId();
  const { isOpen, openModal, closeModal } = useModal();

  const { data: banner } = useGetBannerByIdQuery(userId as string, {
    skip: !userId,
  });

  const [deleteBanner] = useDeleteMyBannerMutation();

  const handleBannerRemove = async () => {
    await deleteBanner();
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      sx={{
        width: '100%',
        height: '280px',
        backgroundImage: `url(${banner?.fileUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'primary.main',
        position: 'relative',
        '&:hover .action-buttons': {
          visibility: 'visible',
        },
      }}
    >
      {isOwnPage && (
        <>
          <Box
            display="flex"
            gap={1}
            m="16px"
            className="action-buttons"
            sx={{
              visibility: { lg: 'hidden' },
            }}
          >
            <Tooltip title={banner ? 'Change banner' : 'Add banner'}>
              <IconButton
                onClick={openModal}
                sx={{
                  width: 'fit-content',
                  height: 'fit-content',
                  p: 0,
                }}
              >
                <IconRoundBackground backgroundColor="secondary.main">
                  {banner ? <EditIcon iconColor="white" /> : <PlusIcon />}
                </IconRoundBackground>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete banner">
              <IconButton
                onClick={handleBannerRemove}
                sx={{
                  width: 'fit-content',
                  height: 'fit-content',
                  p: 0,
                }}
              >
                <IconRoundBackground backgroundColor="secondary.main">
                  <TrashIcon />
                </IconRoundBackground>
              </IconButton>
            </Tooltip>
          </Box>
          <CropBackgroundModal isOpen={isOpen} onClose={closeModal} />
        </>
      )}
    </Box>
  );
};

export default ProfileBackground;

import {
  Box,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Typography,
  useMediaQuery,
} from '@mui/material';
import VideoIcon from '../../../../components/Icons/VideoIcon';
import PhotoIcon from '../../../../components/Icons/PhotoIcon';
import FileIcon from '../../../../components/Icons/FileIcon';
import { useRef, useState } from 'react';
import UploadFileModal from './UploadFileModal';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import MobileChatModalButton from '../MobileChatModalButton';

interface IUploadFileMenu {
  onClose: () => void;
  messageInput: string;
  clearMessageInput?: () => void;
}

export const enum AcceptedFileTypeEnum {
  IMAGE = 'image/*',
  VIDEO = 'video/*',
  DOCUMENT = '.csv, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf, .txt, .html, .htm',
}

const menuItems = [
  {
    icon: <PhotoIcon />,
    text: 'Photo',
    fileType: AcceptedFileTypeEnum.IMAGE,
  },
  {
    icon: <VideoIcon />,
    text: 'Video',
    fileType: AcceptedFileTypeEnum.VIDEO,
  },
  {
    icon: <FileIcon />,
    text: 'Document',
    fileType: AcceptedFileTypeEnum.DOCUMENT,
  },
];

const UploadFileMenu = ({
  onClose,
  messageInput,
  clearMessageInput,
}: IUploadFileMenu) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const fileInputRef = useRef<HTMLInputElement>();
  const [files, setFiles] = useState<File[]>([]);
  const [acceptedFileType, setAcceptedFileType] =
    useState<AcceptedFileTypeEnum>(AcceptedFileTypeEnum.IMAGE);

  const handleOpenFileInput = (accept: AcceptedFileTypeEnum) => () => {
    if (fileInputRef.current) {
      setFiles([]);
      setAcceptedFileType(accept);
      fileInputRef.current.value = '';
      fileInputRef.current.accept = accept;
      fileInputRef.current.click();
    }
  };

  const itemStyle = {
    minWidth: '160px',
    padding: mobileView ? '16px' : '12px',
    gap: '6px',
    '&:hover': {
      '*': {
        color: theme.palette.button.primary,
        stroke: theme.palette.button.primary,
      },
      bgcolor: 'background.white',
    },
  };

  const mobileBox = mobileView
    ? {
        position: 'fixed',
        minWidth: '100%',
        bottom: -16,
        left: 0,
      }
    : {};

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box
        sx={{
          bgcolor: 'background.white',
          boxShadow: '0px 6px 30px 0px #111D3733',
          ...mobileBox,
        }}
      >
        <UploadFileModal
          acceptedFileType={acceptedFileType}
          fileInputRef={fileInputRef}
          files={files}
          setFiles={setFiles}
          onClose={onClose}
          clearMessageInput={clearMessageInput}
          messageInput={messageInput}
        />
        <MenuList autoFocusItem={false} sx={{ p: 0 }}>
          {menuItems.map(({ icon, text, fileType }, index) => (
            <MenuItem
              key={text}
              onClick={handleOpenFileInput(fileType)}
              divider={index < menuItems.length - 1}
              sx={{
                ...itemStyle,
              }}
            >
              {icon}
              <Typography
                variant="body3"
                fontWeight={700}
                color={'text.primary'}
              >
                {text}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>

        {mobileView && (
          <MobileChatModalButton
            variant={'outlined'}
            onClick={onClose}
            label={'cancel'}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default UploadFileMenu;

import { IMessage } from '../../store/apiSlice/chat/chatApi';

export const groupMessagesByDate = (messages: IMessage[]) => {
  const groupedMessages: { [index: string]: IMessage[] } = {};

  messages.forEach(msg => {
    const identifier = new Date(msg.createdAt).toLocaleDateString('en-US', {
      month: 'long',
      day: '2-digit',
    });

    if (groupedMessages[identifier]) {
      groupedMessages[identifier].push(msg);
    } else {
      groupedMessages[identifier] = [msg];
    }
  });

  return groupedMessages;
};

const MessageForwardIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9999 4V8C6.42495 9.028 3.97994 14.788 2.99994 20C2.96294 20.206 8.38395 14.038 12.9999 14V18L20.9999 11L12.9999 4Z"
        stroke="#434E5F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MessageForwardIcon;

import { List, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { IFriend } from '../../../../../interfaces/friend.interface';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';

import SnackbarCustom from '../../../../../components/MUIComponents/SnackbarCustom';
import { FriendRequest } from '../../../../../interfaces/friend-invitation.interface';

import NotificationBox from './NotificationBox';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import ConnectionItem from './ConnectionItem';
import { UserDataFiledEnum } from '../../../../../enums/user-data-field-enum';

interface ConnectionsListProps {
  connections: IFriend[] | FriendRequest[];
  activeTab: number;
  setConnections: React.Dispatch<
    React.SetStateAction<IFriend[] | FriendRequest[]>
  >;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const ConnectionsList: React.FC<ConnectionsListProps> = ({
  connections,
  activeTab,
  setConnections,
  setPage,
}) => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [notificationData, setNotificationData] = useState<IFriend | {}>({});

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const isOutgoingTab = activeTab === 1;
  const userDataField = isOutgoingTab
    ? UserDataFiledEnum.FRIEND
    : UserDataFiledEnum.USER;

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {'userId' in notificationData && (
        <NotificationBox
          notificationData={notificationData}
          snackbarMessage={snackbarMessage}
          matches={matches}
          setNotificationData={setNotificationData}
        />
      )}
      {connections && connections.length > 0 && (
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: '12px', lg: '16px' },
            paddingTop: '20px',
          }}
        >
          {(activeTab === 0 || activeTab === 3) &&
            (connections as IFriend[]).map((connection: IFriend) => (
              <ConnectionItem
                key={connection.userId}
                activeTab={activeTab}
                userDataField={userDataField}
                connection={connection}
                matches={matches}
                setConnections={setConnections}
                setPage={setPage}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarSeverity={setSnackbarSeverity}
                setNotificationData={setNotificationData}
              />
            ))}

          {(activeTab === 1 || activeTab === 2) &&
            (connections as FriendRequest[]).map(
              (connection: FriendRequest) => (
                <ConnectionItem
                  key={connection[userDataField].userId}
                  activeTab={activeTab}
                  userDataField={userDataField}
                  connection={connection}
                  matches={matches}
                  setConnections={setConnections}
                  setPage={setPage}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarSeverity={setSnackbarSeverity}
                  setNotificationData={setNotificationData}
                />
              )
            )}

          <SnackbarCustom
            open={snackbarOpen}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </List>
      )}
    </>
  );
};

export default ConnectionsList;

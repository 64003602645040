import { SvgIcon, SvgIconProps } from '@mui/material';

const JobCompanyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      sx={{ width: 18, height: 18, fill: 'none' }}
      {...props}
    >
      <path
        d="M2.25 15.75H15.75"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 15.75V5.25L9.75 2.25V15.75"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 15.75V8.25L9.75 5.25"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 6.75V6.7575"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 9V9.0075"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 11.25V11.2575"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 13.5V13.5075"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default JobCompanyIcon;

import { SvgIcon, SvgIconProps } from '@mui/material';

const FlagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 121 120"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 121, height: 120, fill: 'none' }}
      {...props}
    >
      <g clipPath="url(#clip0_554_10385)">
        <path
          d="M60.5 120C93.6371 120 120.5 93.1371 120.5 60C120.5 26.8629 93.6371 0 60.5 0C27.3629 0 0.5 26.8629 0.5 60C0.5 93.1371 27.3629 120 60.5 120Z"
          fill="#FEEDE9"
        />
        <path
          d="M16.2998 78.6L32.4798 61.96L22.2398 47.26H44.0598L46.9198 32.26H85.0399L78.4998 66.74H65.6798L63.4398 78.6H16.2998Z"
          fill="#F5421D"
        />
        <path
          d="M65.6798 66.74L63.4398 78.6L40.3398 66.94L40.3798 66.74H65.6798Z"
          fill="#142237"
        />
        <path
          d="M85.5401 33.42C88.2794 33.42 90.5001 31.1993 90.5001 28.46C90.5001 25.7207 88.2794 23.5 85.5401 23.5C82.8008 23.5 80.5801 25.7207 80.5801 28.46C80.5801 31.1993 82.8008 33.42 85.5401 33.42Z"
          fill="#142237"
        />
        <mask
          id="mask0_554_10385"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="66"
          y="32"
          width="25"
          height="67"
        >
          <path
            d="M66.6196 98.4H77.2996L90.0797 32.26H79.8596L66.6196 98.4Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_554_10385)">
          <path
            d="M85.0398 32.26L71.7798 102.22"
            stroke="#142237"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask1_554_10385"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="16"
          y="47"
          width="50"
          height="32"
        >
          <path
            d="M44.0598 47.26H22.2398L32.4798 61.96L16.2998 78.6H63.4398L65.6798 66.74H40.3798L44.0598 47.26Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_554_10385)">
          <path
            d="M39.4396 52.78C40.1576 52.78 40.7396 52.198 40.7396 51.48C40.7396 50.762 40.1576 50.18 39.4396 50.18C38.7217 50.18 38.1396 50.762 38.1396 51.48C38.1396 52.198 38.7217 52.78 39.4396 52.78Z"
            fill="#142237"
          />
          <path
            d="M42.6193 55.96C43.3373 55.96 43.9193 55.378 43.9193 54.66C43.9193 53.942 43.3373 53.36 42.6193 53.36C41.9014 53.36 41.3193 53.942 41.3193 54.66C41.3193 55.378 41.9014 55.96 42.6193 55.96Z"
            fill="#142237"
          />
          <path
            d="M33.0798 52.78C33.7977 52.78 34.3798 52.198 34.3798 51.48C34.3798 50.762 33.7977 50.18 33.0798 50.18C32.3618 50.18 31.7798 50.762 31.7798 51.48C31.7798 52.198 32.3618 52.78 33.0798 52.78Z"
            fill="#142237"
          />
          <path
            d="M36.2595 55.96C36.9774 55.96 37.5594 55.378 37.5594 54.66C37.5594 53.942 36.9774 53.36 36.2595 53.36C35.5415 53.36 34.9595 53.942 34.9595 54.66C34.9595 55.378 35.5415 55.96 36.2595 55.96Z"
            fill="#142237"
          />
          <path
            d="M39.4397 59.1C40.1356 59.1 40.6997 58.5359 40.6997 57.84C40.6997 57.1441 40.1356 56.58 39.4397 56.58C38.7438 56.58 38.1797 57.1441 38.1797 57.84C38.1797 58.5359 38.7438 59.1 39.4397 59.1Z"
            fill="#142237"
          />
          <path
            d="M26.7195 52.76C27.4264 52.76 27.9995 52.1869 27.9995 51.48C27.9995 50.7731 27.4264 50.2 26.7195 50.2C26.0126 50.2 25.4395 50.7731 25.4395 51.48C25.4395 52.1869 26.0126 52.76 26.7195 52.76Z"
            fill="#142237"
          />
          <path
            d="M36.2595 49.6C36.9774 49.6 37.5594 49.018 37.5594 48.3C37.5594 47.582 36.9774 47 36.2595 47C35.5415 47 34.9595 47.582 34.9595 48.3C34.9595 49.018 35.5415 49.6 36.2595 49.6Z"
            fill="#142237"
          />
          <path
            d="M42.6193 49.6C43.3373 49.6 43.9193 49.018 43.9193 48.3C43.9193 47.582 43.3373 47 42.6193 47C41.9014 47 41.3193 47.582 41.3193 48.3C41.3193 49.018 41.9014 49.6 42.6193 49.6Z"
            fill="#142237"
          />
          <path
            d="M29.8996 49.6C30.6176 49.6 31.1996 49.018 31.1996 48.3C31.1996 47.582 30.6176 47 29.8996 47C29.1816 47 28.5996 47.582 28.5996 48.3C28.5996 49.018 29.1816 49.6 29.8996 49.6Z"
            fill="#142237"
          />
          <path
            d="M23.5398 49.58C24.2467 49.58 24.8198 49.0069 24.8198 48.3C24.8198 47.5931 24.2467 47.02 23.5398 47.02C22.8329 47.02 22.2598 47.5931 22.2598 48.3C22.2598 49.0069 22.8329 49.58 23.5398 49.58Z"
            fill="#142237"
          />
          <path
            d="M29.8996 55.96C30.6176 55.96 31.1996 55.378 31.1996 54.66C31.1996 53.942 30.6176 53.36 29.8996 53.36C29.1816 53.36 28.5996 53.942 28.5996 54.66C28.5996 55.378 29.1816 55.96 29.8996 55.96Z"
            fill="#142237"
          />
          <path
            d="M33.0798 59.14C33.7977 59.14 34.3798 58.558 34.3798 57.84C34.3798 57.122 33.7977 56.54 33.0798 56.54C32.3618 56.54 31.7798 57.122 31.7798 57.84C31.7798 58.558 32.3618 59.14 33.0798 59.14Z"
            fill="#142237"
          />
          <path
            d="M36.2595 62.32C36.9774 62.32 37.5594 61.738 37.5594 61.02C37.5594 60.302 36.9774 59.72 36.2595 59.72C35.5415 59.72 34.9595 60.302 34.9595 61.02C34.9595 61.738 35.5415 62.32 36.2595 62.32Z"
            fill="#142237"
          />
          <path
            d="M39.4396 65.5C40.1576 65.5 40.7396 64.918 40.7396 64.2C40.7396 63.482 40.1576 62.9 39.4396 62.9C38.7217 62.9 38.1396 63.482 38.1396 64.2C38.1396 64.918 38.7217 65.5 39.4396 65.5Z"
            fill="#142237"
          />
          <path
            d="M52.1794 78.22C52.8863 78.22 53.4594 77.6469 53.4594 76.94C53.4594 76.2331 52.8863 75.66 52.1794 75.66C51.4725 75.66 50.8994 76.2331 50.8994 76.94C50.8994 77.6469 51.4725 78.22 52.1794 78.22Z"
            fill="#142237"
          />
          <path
            d="M33.0794 65.42C33.7532 65.42 34.2994 64.8738 34.2994 64.2C34.2994 63.5262 33.7532 62.98 33.0794 62.98C32.4056 62.98 31.8594 63.5262 31.8594 64.2C31.8594 64.8738 32.4056 65.42 33.0794 65.42Z"
            fill="#142237"
          />
          <path
            d="M36.2595 68.68C36.9774 68.68 37.5594 68.098 37.5594 67.38C37.5594 66.662 36.9774 66.08 36.2595 66.08C35.5415 66.08 34.9595 66.662 34.9595 67.38C34.9595 68.098 35.5415 68.68 36.2595 68.68Z"
            fill="#142237"
          />
          <path
            d="M39.4396 71.84C40.1466 71.84 40.7197 71.2669 40.7197 70.56C40.7197 69.8531 40.1466 69.28 39.4396 69.28C38.7327 69.28 38.1597 69.8531 38.1597 70.56C38.1597 71.2669 38.7327 71.84 39.4396 71.84Z"
            fill="#142237"
          />
          <path
            d="M42.6193 75.04C43.3373 75.04 43.9193 74.458 43.9193 73.74C43.9193 73.022 43.3373 72.44 42.6193 72.44C41.9014 72.44 41.3193 73.022 41.3193 73.74C41.3193 74.458 41.9014 75.04 42.6193 75.04Z"
            fill="#142237"
          />
          <path
            d="M48.9997 75.04C49.7177 75.04 50.2997 74.458 50.2997 73.74C50.2997 73.022 49.7177 72.44 48.9997 72.44C48.2817 72.44 47.6997 73.022 47.6997 73.74C47.6997 74.458 48.2817 75.04 48.9997 75.04Z"
            fill="#142237"
          />
          <path
            d="M45.7995 78.24C46.5175 78.24 47.0995 77.658 47.0995 76.94C47.0995 76.222 46.5175 75.64 45.7995 75.64C45.0815 75.64 44.4995 76.222 44.4995 76.94C44.4995 77.658 45.0815 78.24 45.7995 78.24Z"
            fill="#142237"
          />
          <path
            d="M33.0798 71.86C33.7977 71.86 34.3798 71.278 34.3798 70.56C34.3798 69.842 33.7977 69.26 33.0798 69.26C32.3618 69.26 31.7798 69.842 31.7798 70.56C31.7798 71.278 32.3618 71.86 33.0798 71.86Z"
            fill="#142237"
          />
          <path
            d="M36.2595 75.04C36.9774 75.04 37.5594 74.458 37.5594 73.74C37.5594 73.022 36.9774 72.44 36.2595 72.44C35.5415 72.44 34.9595 73.022 34.9595 73.74C34.9595 74.458 35.5415 75.04 36.2595 75.04Z"
            fill="#142237"
          />
          <path
            d="M39.4396 78.24C40.1576 78.24 40.7396 77.658 40.7396 76.94C40.7396 76.222 40.1576 75.64 39.4396 75.64C38.7217 75.64 38.1396 76.222 38.1396 76.94C38.1396 77.658 38.7217 78.24 39.4396 78.24Z"
            fill="#142237"
          />
          <path
            d="M45.7995 71.86C46.5175 71.86 47.0995 71.278 47.0995 70.56C47.0995 69.842 46.5175 69.26 45.7995 69.26C45.0815 69.26 44.4995 69.842 44.4995 70.56C44.4995 71.278 45.0815 71.86 45.7995 71.86Z"
            fill="#142237"
          />
          <path
            d="M29.8996 75.04C30.6176 75.04 31.1996 74.458 31.1996 73.74C31.1996 73.022 30.6176 72.44 29.8996 72.44C29.1816 72.44 28.5996 73.022 28.5996 73.74C28.5996 74.458 29.1816 75.04 29.8996 75.04Z"
            fill="#142237"
          />
          <path
            d="M33.0798 78.24C33.7977 78.24 34.3798 77.658 34.3798 76.94C34.3798 76.222 33.7977 75.64 33.0798 75.64C32.3618 75.64 31.7798 76.222 31.7798 76.94C31.7798 77.658 32.3618 78.24 33.0798 78.24Z"
            fill="#142237"
          />
          <path
            d="M26.7195 78.24C27.4375 78.24 28.0195 77.658 28.0195 76.94C28.0195 76.222 27.4375 75.64 26.7195 75.64C26.0015 75.64 25.4194 76.222 25.4194 76.94C25.4194 77.658 26.0015 78.24 26.7195 78.24Z"
            fill="#142237"
          />
          <path
            d="M29.8996 68.68C30.6176 68.68 31.1996 68.098 31.1996 67.38C31.1996 66.662 30.6176 66.08 29.8996 66.08C29.1816 66.08 28.5996 66.662 28.5996 67.38C28.5996 68.098 29.1816 68.68 29.8996 68.68Z"
            fill="#142237"
          />
          <path
            d="M26.7195 71.86C27.4375 71.86 28.0195 71.278 28.0195 70.56C28.0195 69.842 27.4375 69.26 26.7195 69.26C26.0015 69.26 25.4194 69.842 25.4194 70.56C25.4194 71.278 26.0015 71.86 26.7195 71.86Z"
            fill="#142237"
          />
          <path
            d="M23.5398 75.04C24.2578 75.04 24.8398 74.458 24.8398 73.74C24.8398 73.022 24.2578 72.44 23.5398 72.44C22.8218 72.44 22.2397 73.022 22.2397 73.74C22.2397 74.458 22.8218 75.04 23.5398 75.04Z"
            fill="#142237"
          />
          <path
            d="M20.3596 78.24C21.0775 78.24 21.6596 77.658 21.6596 76.94C21.6596 76.222 21.0775 75.64 20.3596 75.64C19.6416 75.64 19.0596 76.222 19.0596 76.94C19.0596 77.658 19.6416 78.24 20.3596 78.24Z"
            fill="#142237"
          />
        </g>
        <path
          d="M64.5 38L66.12 45.9L74.5 46.54L66.86 52.04L67.22 60.34L60.88 55.84L52.72 60.34L56.42 52.04L51.02 46.54L59.66 45.9L64.5 38Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_554_10385">
          <rect
            width="120"
            height="120"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default FlagIcon;

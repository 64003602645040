import { Box, Typography } from '@mui/material';

interface CardStatusProps {
  textColor?: string;
  bgColor?: string;
  children: string;
}
const CardStatus = ({ textColor = 'unset', bgColor, children }: CardStatusProps) => (
  <Box
    sx={theme => ({
      borderRadius: '20px',
      background: bgColor ?? theme.palette.success.main,
      color: textColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '6px 12px',
      height: 'fit-content',
    })}
  >
    <Typography variant="badge3" textTransform="uppercase" color="inherit">
      {children}
    </Typography>
  </Box>
);

export default CardStatus;

import { SvgIcon, SvgIconProps } from '@mui/material';

const CloseX = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 24, height: 24, fill: 'none' }}
      viewBox="0 0 24 24"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke={props.stroke ? props.stroke : '#142237'}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke={props.stroke ? props.stroke : '#142237'}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CloseX;

import { Box, BoxProps, useMediaQuery } from '@mui/material';
import About from '../../../components/Company/About';
import SidebarLayout from '../../../layouts/SidebarLayout';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import CompanySidebar from '../../../components/Company/CompanySidebar/CompanySidebar';
import CompanyInfo from '../../company/CompanySettings/CompanyInfo';
import { Photos, Videos } from '../../../components/Media';

const CompanyPage = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const mobileProps: BoxProps = mobileView
    ? {
        flexDirection: 'column',
        marginTop: { xs: '-20px', md: '-24px' },
      }
    : {};

  return (
    <SidebarLayout sidebar={<CompanySidebar />} {...mobileProps}>
      <Box display="flex" flexDirection="column" gap="22px" flexGrow="1">
        <Box id="company-info">
          <CompanyInfo />
        </Box>
        <Box id="about">
          <About />
        </Box>
        <Box id="photos">
          <Photos />
        </Box>
        <Box id="videos">
          <Videos />
        </Box>
      </Box>
    </SidebarLayout>
  );
};

export default CompanyPage;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import Autocomplete from '@mui/material/Autocomplete';
import {
  getCitiesOfCountry,
  getCountries,
} from '../../../../../utils/coutry-city-utils';
import { ICountry } from 'country-state-city';

interface ICandidateLocationCollapseProps {
  setCountryFilterState: (value: string) => void;
  setCityFilterState: (value: string) => void;
  countryFilterState: string;
  cityFilterState: string;
}

const CandidateLocationFilter = (props: ICandidateLocationCollapseProps) => {
  const {
    setCountryFilterState,
    setCityFilterState,
    countryFilterState,
    cityFilterState,
  } = props;
  const [isScoreExpanded, setIsScoreExpanded] = useState(false);

  const toggleExpand = () => {
    setIsScoreExpanded(prevState => !prevState);
  };

  const [selectedCountryCode, setSelectedCountryCode] = useState<
    string | undefined
  >();

  const findCountryByName = (
    name: string | null | undefined
  ): ICountry | null => {
    const countries = getCountries();
    return countries.find(country => country.name === name) || null;
  };

  useEffect(() => {
    const country = findCountryByName(countryFilterState);
    setSelectedCountryCode(country?.isoCode || undefined);
  }, [countryFilterState]);

  return (
    <Box
      sx={{
        minWidth: 227,
        width: '100%',
        borderRadius: '1px',
        bgcolor: 'background.white',
        p: '11px 16px',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          gap: '28px',
          padding: '0 2px',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          color={'text.primary'}
          textAlign={'left'}
          display={'flex'}
          alignItems={'center'}
        >
          Location
        </Typography>
        <Box display="flex" gap="12px">
          <ArrowDownIcon
            sx={{
              transform: isScoreExpanded ? 'rotate(180deg)' : '',
            }}
            onClick={toggleExpand}
          />
        </Box>
      </Box>
      <Collapse in={isScoreExpanded} sx={{ marginTop: '22px' }}>
        <Box display="flex" flexDirection={'column'} gap={'22px'}>
          <FormControl
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
            variant="outlined"
            fullWidth
          >
            <Typography
              variant="caption"
              sx={{
                padding: '0 2px',
                mb: '12px',
                color: 'text.primary',
              }}
            >
              Countries:
            </Typography>
            <Autocomplete
              options={getCountries()}
              getOptionLabel={option => option.name || ''}
              isOptionEqualToValue={(option, value) =>
                option.name === value?.name
              }
              value={findCountryByName(countryFilterState) || null}
              onChange={(event, newValue) => {
                setSelectedCountryCode(newValue?.isoCode);
                setCountryFilterState(newValue ? newValue.name : '');
                setCityFilterState(''); // Очищаємо поле міста при зміні країни
              }}
              renderInput={params => (
                <TextField {...params} placeholder="Select a country" />
              )}
              sx={{
                '.MuiInputBase-root': {
                  height: '50px',
                },
                '.MuiSvgIcon-root': {
                  bgcolor: 'transparent !important',
                },
              }}
              popupIcon={<ArrowDownIcon />}
            />
          </FormControl>

          <FormControl
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
            variant="outlined"
            fullWidth
          >
            <Typography
              variant="caption"
              sx={{
                padding: '0 2px',
                mb: '12px',
                color: selectedCountryCode ? 'text.primary' : 'text.disabled',
              }}
            >
              City:
            </Typography>
            <Autocomplete
              options={
                selectedCountryCode
                  ? getCitiesOfCountry(selectedCountryCode) || []
                  : []
              }
              getOptionLabel={option => option?.name || ''}
              isOptionEqualToValue={(option, value) =>
                option.name === value?.name
              }
              value={cityFilterState ? { name: cityFilterState, id: '' } : null}
              onChange={(event, newValue) => {
                setCityFilterState(newValue ? newValue.name : '');
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Select a city"
                  disabled={!selectedCountryCode}
                />
              )}
              disabled={!selectedCountryCode}
              sx={{
                '.MuiInputBase-root': {
                  height: '50px',
                },
                '.MuiSvgIcon-root': {
                  bgcolor: 'transparent !important',
                },
              }}
              popupIcon={<ArrowDownIcon />}
            />
          </FormControl>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CandidateLocationFilter;

import * as yup from 'yup';

export const leaveReviewSchema = yup.object().shape({
  rating: yup
    .number()
    .required('please leave your rating')
    .min(1, 'please leave your rating'),
  text: yup
    .string()
    .required('Review is required')
    .min(10, 'Review must be at least 10 characters')
    .max(300, 'Review cannot be more than 300 characters'),
});

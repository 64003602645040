import {
  Box,
  BoxProps,
  ButtonGroup,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ListSort, { IListSortPops } from './ListSort';
import ListSearch from './ListSearch';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';
import CustomButton from '../../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../../enums/button-type-enum';
import { colors } from '../../../../../../theme/theme';
import { useNavigate } from 'react-router-dom';
import PlusIcon from '../../../../../../components/Icons/PlusIcon';
import MagnifyingGlassIcon from '../../../../../../components/Icons/MagnifyingGlassIcon';
import { Fragment, useState } from 'react';
import StatusFilter from './StatusFilter';

interface IListToolBarProps extends IListSortPops, BoxProps {
  subTitle: string;
  isSortShown?: boolean;
  isSearchShown?: boolean;
}

const NEW_JOB_POST = 'NEW_JOB_POST';

const ListToolBar: React.FC<IListToolBarProps> = ({
  subTitle,
  isSortShown = true,
  isSearchShown = true,
  sortMenuItems,
  sortMenuLabel,
  ...props
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const [isShowSearch, setShowSearch] = useState<boolean>(false);

  return (
    <Box display="flex" flexDirection="column">
      <Stack
        gap="28px"
        direction="column"
        justifyContent={{ xs: 'space-between' }}
        mb="24px"
      >
        {mobileView && (
          <Box display={'flex'} justifyContent={{ xs: 'space-between' }}>
            <Typography
              variant="h3"
              color={colors.fontDark}
              justifyContent="start"
              alignItems="center"
              display="flex"
            >
              Job posts
            </Typography>
            <IconButton
              sx={{
                height: 40,
                width: 40,
                minHeight: 40,
                minWidth: 40,
                bgcolor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              onClick={() => navigate('/manage-job-post/new')}
            >
              <PlusIcon />
            </IconButton>
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <StatusFilter />
          {!mobileView && (
            <CustomButton
              sx={{
                minWidth: {
                  lg: '160px',
                },
                whiteSpace: 'nowrap',
                padding: '22px 15px',
              }}
              variantType={ButtonTypeEnum.PRIMARY}
              onClick={() => navigate('/manage-job-post/new')}
            >
              NEW JOB POST
            </CustomButton>
          )}
        </Box>
      </Stack>
      {mobileView && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          borderTop={`1px solid ${theme.palette.background.lightDark}`}
        >
          {!isShowSearch ? (
            <>
              <ListSort
                sortMenuItems={sortMenuItems}
                sortMenuLabel={sortMenuLabel}
              />
              <IconButton onClick={() => setShowSearch(true)}>
                <MagnifyingGlassIcon />
              </IconButton>
            </>
          ) : (
            <ListSearch closeSearch={() => setShowSearch(false)} />
          )}
        </Box>
      )}
      {!mobileView && (
        <Box
          display={'flex'}
          flexDirection={!mobileView ? 'row' : 'column'}
          alignItems={!mobileView ? 'center' : 'start'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          gap={!!mobileView ? '22px' : 0}
          borderTop={`1px solid ${theme.palette.background.lightDark}`}
          borderBottom={`1px solid ${theme.palette.background.lightDark}`}
          p="16px 0"
          {...props}
        >
          <Box>{isSearchShown && <ListSearch />}</Box>
          {isSortShown && (
            <ListSort
              sortMenuItems={sortMenuItems}
              sortMenuLabel={sortMenuLabel}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ListToolBar;

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ElementIdEnum } from '../../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { ISportBackgroundFileData } from '../../../../../interfaces/user-sport-background.interface';
import { SportBackgroundContext } from '../../../../../pages/auth/ProfilePage/sport-background/EditSportBackground';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { sportBackgroundSchema } from '../../../../../validation/sport-validation';
import UploadIcon from '../../../../Icons/UploadIcon';
import DragAndDropBox from '../../../../MUIComponents/DragAndDropBox/DragAndDropBox';
import SnackbarCustom from '../../../../MUIComponents/SnackbarCustom';
import SportBackgroundFileList from '../SportBackgroundFileList/SportBackgroundFileList';
import SportBackgroundFormBlock from '../SportBackgroundFormBlock/SportBackgroundFormBlock';
import { ISportBackgroundWithPosition } from '../SportVerification';

interface IProps {
  isEdit?: boolean;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave: () => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  triggerFileInput: () => void;
  sportBackgroundElementPosition: number;
  setSportBackGroundWithPosition: React.Dispatch<
    React.SetStateAction<ISportBackgroundWithPosition[]>
  >;
  sportBackGroundWithPosition: ISportBackgroundWithPosition[];
  selectedSportId: string | null;
  removeFileFromBackground: (
    sportBackgroundElementPosition: number,
    file: File
  ) => void;
}

export interface TeamFormInputs {
  id?: string;
  leagueId: string;
  teamId: string;
  startDateInTeam: Date;
  endDateInTeam: Date;
}

export interface SportBackgroundFormInputs {
  id?: string;
  startCareerDate: Date;
  endCareerDate: Date;
  description?: string | null;

  teams: TeamFormInputs[];
}

const SportBackgroundItem: React.FC<IProps> = ({
  isEdit,
  handleDragOver,
  handleDragLeave,
  handleDrop,
  handleFileChange,
  triggerFileInput,
  setSportBackGroundWithPosition,
  sportBackgroundElementPosition,
  selectedSportId,
  sportBackGroundWithPosition,
  removeFileFromBackground,
}) => {
  const sportBg = useContext(SportBackgroundContext);
  const {
    register,
    formState: { errors },
    control,
  } = useForm<SportBackgroundFormInputs>({
    resolver: yupResolver(sportBackgroundSchema),
    defaultValues: sportBg ? sportBackgroundSchema.cast(sportBg) : undefined,
    mode: 'onChange',
  });

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [formState, setFormState] = useState<SportBackgroundFormInputs>({
    id: sportBg?.id,
    startCareerDate: sportBg?.startCareerDate || new Date(),
    endCareerDate: sportBg?.endCareerDate || new Date(),
    description: sportBg?.description,

    teams: sportBg?.teams || [],
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const handleChange = (field: keyof SportBackgroundFormInputs, value: any) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    const background = sportBackGroundWithPosition.find(
      item => item.position === sportBackgroundElementPosition
    );
    const fileData = background?.sportBackground?.fileData || [];

    updateSportBackgroundWithPosition(formState, fileData);
  }, [formState]);

  const updateSportBackgroundWithPosition = (
    {
      startCareerDate,
      endCareerDate,
      description,
      teams,
    }: Partial<SportBackgroundFormInputs>,
    fileData: ISportBackgroundFileData[]
  ) => {
    setSportBackGroundWithPosition(prevState => {
      const existingIndex = prevState.findIndex(
        item => item.position === sportBackgroundElementPosition
      );

      const newBackground: ISportBackgroundWithPosition = {
        position: sportBackgroundElementPosition,
        sportBackground: {
          id: sportBg?.id || undefined,
          sportId: selectedSportId || '',
          startCareerDate: startCareerDate || undefined,
          endCareerDate: endCareerDate || undefined,
          description: description || undefined,
          fileData,
          teams: teams || [],
        },
      };

      if (existingIndex !== -1) {
        return prevState.map((item, index) =>
          index === existingIndex ? newBackground : item
        );
      } else {
        return [...prevState, newBackground];
      }
    });
  };

  const handleFileDataChange = (
    index: number,
    data: Partial<ISportBackgroundFileData>
  ) => {
    const background = sportBackGroundWithPosition.find(
      item => item.position === sportBackgroundElementPosition
    );

    if (background) {
      const updatedFileData = [
        ...(background?.sportBackground?.fileData || []),
      ];
      updatedFileData[index] = { ...updatedFileData[index], ...data };

      updateSportBackgroundWithPosition(formState, updatedFileData);
    }
  };

  const handleFileChangeWithCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!selectedSportId) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }
    handleFileChange(event);
  };

  const handleDropWithCheck = (event: React.DragEvent<HTMLDivElement>) => {
    if (!selectedSportId) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }
    handleDrop(event);
  };

  const triggerFileInputWithCheck = () => {
    if (!selectedSportId) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }
    triggerFileInput();
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'2.25rem'}>
      <SportBackgroundFormBlock
        sportBg={sportBg}
        control={control}
        register={register}
        errors={errors}
        onFieldChange={handleChange}
        disabled={!selectedSportId}
        selectedSportId={selectedSportId}
      />
      {!isEdit && (
        <>
          <Divider />
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={mobileView ? '1.75rem' : '2.25rem'}
          >
            <Typography variant={'h3'}>
              Confirm Your Sport Experience
            </Typography>
            <Typography variant={'body3'}>
              Upload photos, videos or documents of your awards, certificates,
              etc.
            </Typography>
            {sportBackGroundWithPosition.length > 0 && (
              <SportBackgroundFileList
                sportBackGroundWithPosition={sportBackGroundWithPosition}
                sportBackgroundElementPosition={sportBackgroundElementPosition}
                removeFileFromBackground={removeFileFromBackground}
                handleFileDataChange={handleFileDataChange}
              />
            )}
            <DragAndDropBox
              handleDragOver={handleDragOver}
              handleDragLeave={handleDragLeave}
              handleDrop={handleDrop}
              handleFileChange={handleFileChangeWithCheck}
              triggerFileInput={triggerFileInputWithCheck}
              fileInputId={`${ElementIdEnum.SPORT_BACKGROUND_FILE_INPUT}-${sportBackgroundElementPosition}`}
              icon={
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                  <UploadIcon />
                </Box>
              }
            />
          </Box>
        </>
      )}
      <SnackbarCustom
        open={snackbarOpen}
        message="Please select a sport before adding files."
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default SportBackgroundItem;

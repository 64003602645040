import { SvgIcon, SvgIconProps } from '@mui/material';

const FiltersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{ width: 24, height: 24, fill: 'none' }}
      {...props}
    >
      <path
        d="M4.28613 6H13.7147"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M17.1426 6L19.714 6"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M13.7139 5.99993C13.7139 6.45459 13.8945 6.89062 14.216 7.21211C14.5375 7.5336 14.9735 7.71422 15.4282 7.71422C15.8828 7.71422 16.3188 7.5336 16.6403 7.21211C16.9618 6.89062 17.1424 6.45459 17.1424 5.99993C17.1424 5.54527 16.9618 5.10924 16.6403 4.78775C16.3188 4.46626 15.8828 4.28564 15.4282 4.28564C14.9735 4.28564 14.5375 4.46626 14.216 4.78775C13.8945 5.10924 13.7139 5.54527 13.7139 5.99993Z"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2861 12H19.7147"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.28613 12L6.85756 12"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M6.85742 11.9999C6.85742 12.4546 7.03803 12.8906 7.35952 13.2121C7.68102 13.5336 8.11705 13.7142 8.57171 13.7142C9.02636 13.7142 9.4624 13.5336 9.78389 13.2121C10.1054 12.8906 10.286 12.4546 10.286 11.9999C10.286 11.5453 10.1054 11.1092 9.78389 10.7877C9.4624 10.4663 9.02636 10.2856 8.57171 10.2856C8.11705 10.2856 7.68102 10.4663 7.35952 10.7877C7.03803 11.1092 6.85742 11.5453 6.85742 11.9999Z"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.28613 18H13.7147"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M17.1426 18L19.714 18"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M13.7139 17.9999C13.7139 18.4546 13.8945 18.8906 14.216 19.2121C14.5375 19.5336 14.9735 19.7142 15.4282 19.7142C15.8828 19.7142 16.3188 19.5336 16.6403 19.2121C16.9618 18.8906 17.1424 18.4546 17.1424 17.9999C17.1424 17.5453 16.9618 17.1092 16.6403 16.7877C16.3188 16.4663 15.8828 16.2856 15.4282 16.2856C14.9735 16.2856 14.5375 16.4663 14.216 16.7877C13.8945 17.1092 13.7139 17.5453 13.7139 17.9999Z"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default FiltersIcon;

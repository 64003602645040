import { useDispatch, useSelector } from 'react-redux';
import { useDeleteChatMutation } from '../../store/apiSlice/chat/chatApi';
import { useDeleteGroupChatMutation } from '../../store/apiSlice/chat/groupApi';
import {
  getChatsLoaded,
  getChatType,
  getSelectedChat,
  resetPage,
  resetToken,
  setChatState,
  setSelectedChat,
} from '../../store/apiSlice/chat/chatSlice';
import { ChatStateEnum, ChatTypeEnum } from '../../enums/chat-enum';
import useChatToken from './useChatToken';
import useGetMyChats from './useGetMyChats';
import { useCallback } from 'react';
import { getCurrentUserId } from '../../utils/helper/getCurrentUserId';

const useDeleteChat = () => {
  const { deleteToken } = useChatToken();
  const { myChats } = useGetMyChats({});

  const chatType = useSelector(getChatType);
  const selectedChat = useSelector(getSelectedChat);
  const chatsLoaded = useSelector(getChatsLoaded);
  const dispatch = useDispatch();

  const [deletePersonalChat, { isLoading: personalChatDeletting }] =
    useDeleteChatMutation();
  const [deleteGroupChat, { isLoading: groupChatDeletting }] =
    useDeleteGroupChatMutation();

  const handleDeleteChat = useCallback(async () => {
    if (selectedChat && myChats && myChats.length > 0 && chatsLoaded) {
      const delettingChatId = selectedChat.id;

      await deleteToken({
        chatId: delettingChatId,
        userId: getCurrentUserId(),
      }).unwrap();

      dispatch(resetPage());
      dispatch(resetToken());

      if (myChats.length === 1) {
        dispatch(
          setChatState(
            chatType === ChatTypeEnum.PERSONAL
              ? ChatStateEnum.NEW_PERSONAL
              : ChatStateEnum.NEW_GROUP
          )
        );
        dispatch(setSelectedChat(undefined));
      } else if (myChats[0].id !== delettingChatId) {
        dispatch(setSelectedChat(myChats[0]));
      } else {
        dispatch(setSelectedChat(myChats[1]));
      }

      if (chatType === ChatTypeEnum.GROUP)
        await deleteGroupChat(delettingChatId).unwrap();
      else await deletePersonalChat(delettingChatId).unwrap();
    }
  }, [
    selectedChat,
    dispatch,
    myChats,
    chatsLoaded,
    deleteToken,
    chatType,
    deleteGroupChat,
    deletePersonalChat,
  ]);

  return {
    handleDeleteChat,
    isChatDeletting:
      chatType === ChatTypeEnum.PERSONAL
        ? personalChatDeletting
        : groupChatDeletting,
  };
};

export default useDeleteChat;

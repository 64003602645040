import { Box, Typography } from '@mui/material';
import { MessageTypeEnum } from '../../../../enums/message-type-enum';

const MessagePhotoPreview = ({
  src,
  filename,
  filesize,
  type,
}: {
  src: string;
  filename: string;
  filesize: string;
  type: MessageTypeEnum;
}) => {
  return (
    <Box display={'flex'} gap={'1rem'} flexDirection={'column'}>
      <img style={{ maxWidth: '100%' }} src={src} loading="lazy" alt="" />
      <Box display={'flex'} flexDirection={'column'} gap="0.375rem">
        <Typography
          variant="body3"
          fontWeight={700}
          sx={{
            color:
              type === MessageTypeEnum.OWN
                ? 'background.white'
                : 'text.primary',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            textOverflow: 'ellipsis',
          }}
        >
          {filename}
        </Typography>
        <Typography variant="body4" fontWeight={500} sx={{ color: '#A1ABBA' }}>
          {filesize}
        </Typography>
      </Box>
    </Box>
  );
};

export default MessagePhotoPreview;

import WelcomeToPlatform from '../../../components/Auth/VerifyAccount/WelcomeToPlatform/WelcomeToPlatform';
import { Box, Modal } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../components/Logo/Logo';

interface IWelcomeToPlatformPageProps {
  step: 1 | 2;
}

const WelcomeToPlatformPage = ({ step }: IWelcomeToPlatformPageProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email } = state || '';

  return (
    <Box
      pt={'1rem'}
      display={'flex'}
      justifyContent={'center'}
      width={'100%'}
      height={'100dvh'}
    >
      <Logo />
      <Modal
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            alignItems: 'flex-end',
          },
        })}
        open={true}
      >
        {step === 1 ? (
          <WelcomeToPlatform
            title="Welcome to Free Agent!"
            text="Welcome to our sports network, your ultimate destination for live games, exclusive interviews, and in-depth analysis. To enjoy the full experience and access all our premium features, please verify your account."
            textLastLine="Join our community and never miss a moment of the action!"
            buttonText="Verify account"
            onProceed={() =>
              navigate('/verify-account', {
                replace: true,
                state: { email: email || '' },
              })
            }
            onClose={() => navigate('/', { replace: true })}
          />
        ) : (
          <WelcomeToPlatform
            title="Welcome!"
            text="Your verification form has been successfully submitted to the Network for Athletes. We are thrilled to have you as part of our community. Our team will review your submission, and you will be notified of the next steps shortly."
            textLastLine="Thank you for your participation and dedication.  "
            buttonText="Explore the platform"
            onProceed={() =>
              navigate('/get-started', {
                replace: true,
                state: { email: email || '' },
              })
            }
            onClose={() => navigate('/', { replace: true })}
          />
        )}
      </Modal>
    </Box>
  );
};

export default WelcomeToPlatformPage;

import React, { useEffect, useState } from 'react';
import { Badge, IconButton } from '@mui/material';
import BellIcon from '../../Icons/BellIcon';
import {
  useGetAllMyNotificationsQuery,
  useGetMyUnreadNotificationsCountQuery,
} from '../../../store/apiSlice/notificationApi';
import NotificationList from './NotificationList';
import { INotification } from '../../../interfaces/notification.interface';

const LIMIT = 5;
const POLLING_INTERVAL = 20000;

const NotificationsMenu = () => {
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [newNotificationsCount, setNewNotificationsCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setCurrentPage(1);
    refetch();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveNotification = (notificationId: string) => {
    if (notifications.find(el => el.id === notificationId && !el.isRead)) {
      setNewNotificationsCount(prev => prev - 1);
    }
    setCurrentPage(1);
  };

  const handleMarkAsRead = () => {
    if (newNotificationsCount <= 0) return;
    setNewNotificationsCount(prev => prev - 1);
    setCurrentPage(1);
  };

  const queryParams = {
    page: currentPage,
    limit: LIMIT,
  };

  const { data: allNotifications, refetch } = useGetAllMyNotificationsQuery(
    queryParams,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const { data: unreadNotificationsCount } =
    useGetMyUnreadNotificationsCountQuery(undefined, {
      pollingInterval: POLLING_INTERVAL,
    });

  useEffect(() => {
    if (unreadNotificationsCount?.data)
      setNewNotificationsCount(unreadNotificationsCount.data);
  }, [unreadNotificationsCount]);

  useEffect(() => {
    if (allNotifications && allNotifications?.data) {
      if (currentPage === 1) setNotifications(allNotifications.data);
      else setNotifications(prev => [...prev, ...allNotifications.data]);

      const hasNext =
        allNotifications.limit * (allNotifications.page - 1) +
          allNotifications.limit <
        allNotifications.total;

      setIsLoadMore(hasNext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifications]);

  return (
    <>
      <Badge
        component={IconButton}
        onClick={handleClick}
        badgeContent={newNotificationsCount > 0 ? newNotificationsCount : null}
        color="error"
        sx={{
          m: 0,
          p: 0,
          minWidth: 0,
          width: 'auto',
          height: 'auto',
          minHeight: 'auto',
        }}
      >
        <BellIcon />
      </Badge>
      <NotificationList
        notifications={notifications}
        isLoadMore={isLoadMore}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onLoadMore={() => setCurrentPage(prev => prev + 1)}
        onDelete={handleRemoveNotification}
        onMarkAsRead={handleMarkAsRead}
      />
    </>
  );
};

export default NotificationsMenu;

import { Avatar, Typography } from '@mui/material';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { useSelector } from 'react-redux';
import { getSelectedChat } from '../../../../../store/apiSlice/chat/chatSlice';
import { IGroupChat } from '../../../../../store/apiSlice/chat/groupApi';

interface IInfoProps {
  onClose: () => void;
}

const Info = ({ onClose }: IInfoProps) => {
  const selectedChat = useSelector(getSelectedChat)! as IGroupChat;

  return (
    <>
      <Avatar
        sx={{ width: '7.5rem', height: '7.5rem' }}
        src={
          selectedChat.thumb
            ? `data:image/jpeg;base64, ${selectedChat.thumb}`
            : ''
        }
      />
      <Typography typography={'h2'}>{selectedChat.name}</Typography>
      <Typography typography={'body2'}>{selectedChat.description}</Typography>
      <CustomButton buttonWidth={'210px'} onClick={onClose}>
        close
      </CustomButton>
    </>
  );
};

export default Info;

import {
  Box,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EyeIcon from '../../../../components/Icons/EyeIcon';
import { colors } from '../../../../theme/theme';

interface IProfileScoreHeader {
  percentage: number;
}

const ProfileScoreHeader = (props: IProfileScoreHeader) => {
  const { percentage } = props;
  return (
    <Grid
      container
      className="profile-score-header-container"
      direction="column"
      color={colors.bgDark}
      p="22px 16px"
    >
      <Grid container item pb="16px">
        <Grid
          container
          flex={1}
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="subtitle1">Your Profile Score</Typography>
          <Grid
            container
            columnGap="6px"
            sx={{
              alignItems: 'center',
            }}
          >
            <Grid item display="flex" alignItems="flex-end">
              <EyeIcon />
            </Grid>
            <Grid item>
              <Typography variant="caption" color={colors.dark60}>
                Only visible to you
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ minWidth: 60, minHeight: 60 }}>
          <Box
            sx={{
              width: '100%',
              aspectRatio: '1 / 1',
              fontSize: '30px',
              p: '10px',
              color: '#3ACC40',
              borderRadius: '50%',
              backgroundColor: '#DAF0DB',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {percentage}
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <BorderLinearProgress variant="determinate" value={percentage} />
      </Grid>
    </Grid>
  );
};

export default ProfileScoreHeader;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    //   backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    backgroundColor: '#3ACC40',
  },
}));

import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ElementIdEnum } from '../../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import {
  ISportBackgroundFileData,
  IUserSportBackgroundResponse,
} from '../../../../../interfaces/user-sport-background.interface';
import { useGetSportsQuery } from '../../../../../store/apiSlice/sportApi';
import { isSupportedSportBackgroundFileHelper } from '../../../../../utils/helper/file-validation-helper';
import DeleteFileRedIcon from '../../../../Icons/DeleteFileRedIcon';
import SnackbarCustom from '../../../../MUIComponents/SnackbarCustom';
import SportBackgroundItem from '../SportBackgroundItem/SportBackgroundItem';
import SportList from '../SportList/SportList';
import { ISportBackgroundWithPosition } from '../SportVerification';

interface SportVerificationItemProps {
  isEdit?: boolean;
  sportBackground?: IUserSportBackgroundResponse;
  sportBackgroundElementPosition: number;
  setSportBackGroundWithPosition: React.Dispatch<
    React.SetStateAction<ISportBackgroundWithPosition[]>
  >;
  sportBackGroundWithPosition: ISportBackgroundWithPosition[];
  deleteSportBackground: (value: number) => void;
}

const SportVerificationItem: React.FC<SportVerificationItemProps> = ({
  isEdit,
  sportBackground,
  sportBackgroundElementPosition,
  setSportBackGroundWithPosition,
  sportBackGroundWithPosition,
  deleteSportBackground,
}) => {
  const [selectedSportId, setSelectedSportId] = useState<string | null>(
    sportBackground?.sport.id || null
  );
  const [dragOver, setDragOver] = useState(false);
  const { data: sports = [], isLoading, isError } = useGetSportsQuery();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  useEffect(() => {
    if (isError) {
      setSnackbarMessage('Failed to load sports.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  }, [isError]);

  const handleSportToggle = (sportId: string) => {
    if (isEdit) {
      return;
    }
    setSportBackGroundWithPosition(prevState => {
      const existingIndex = prevState.findIndex(
        item => item.position === sportBackgroundElementPosition
      );

      if (existingIndex !== -1) {
        return prevState.map((item, index) =>
          index === existingIndex
            ? {
                ...item,
                sportBackground: {
                  ...item.sportBackground,
                  sportId,
                },
              }
            : item
        );
      } else {
        return [
          ...prevState,
          {
            position: sportBackgroundElementPosition,
            sportBackground: {
              sportId,
            },
          },
        ];
      }
    });
    setSelectedSportId(sportId);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const validFiles = Array.from(event.target.files).filter(file =>
        isSupportedSportBackgroundFileHelper(file)
      );
      addFilesToBackground(sportBackgroundElementPosition, validFiles);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      const newFiles = Array.from(event.dataTransfer.files);
      const validFiles = newFiles.filter(file =>
        isSupportedSportBackgroundFileHelper(file)
      );
      addFilesToBackground(sportBackgroundElementPosition, validFiles);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById(
      `${ElementIdEnum.SPORT_BACKGROUND_FILE_INPUT}-${sportBackgroundElementPosition}`
    ) as HTMLInputElement | null;

    if (fileInput) {
      fileInput.click();
    }
  };

  const addFilesToBackground = (
    sportBackgroundElementPosition: number,
    newFiles: File[]
  ) => {
    setSportBackGroundWithPosition(prevState => {
      const existingIndex = prevState.findIndex(
        item => item.position === sportBackgroundElementPosition
      );

      const newFileData: ISportBackgroundFileData[] = newFiles.map(file => ({
        file,
      }));

      if (existingIndex !== -1) {
        const existingSportBackground =
          prevState[existingIndex].sportBackground;

        const updatedSportBackground = {
          ...existingSportBackground,
          fileData: [
            ...(existingSportBackground?.fileData || []),
            ...newFileData,
          ],
          sportId: existingSportBackground?.sportId || '',
        };

        return prevState.map((item, index) =>
          index === existingIndex
            ? {
                ...item,
                sportBackground: updatedSportBackground,
              }
            : item
        );
      } else {
        return [
          ...prevState,
          {
            position: sportBackgroundElementPosition,
            sportBackground: {
              sportId: selectedSportId || '',
              fileData: newFileData,
            },
          },
        ];
      }
    });
  };

  const removeFileFromBackground = (
    sportBackgroundElementPosition: number,
    fileToRemove: File
  ) => {
    setSportBackGroundWithPosition(prevState => {
      const existingIndex = prevState.findIndex(
        item => item.position === sportBackgroundElementPosition
      );

      if (existingIndex !== -1) {
        const existingSportBackground =
          prevState[existingIndex].sportBackground;

        if (existingSportBackground) {
          const updatedFileData = existingSportBackground.fileData?.filter(
            fileData => fileData.file !== fileToRemove
          );

          return prevState.map((item, index) =>
            index === existingIndex
              ? {
                  ...item,
                  sportBackground: {
                    ...existingSportBackground,
                    fileData: updatedFileData,
                  },
                }
              : item
          );
        }
      }

      return prevState;
    });
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'2.25rem'}
      sx={{
        backgroundColor: isHovered ? '#2a19191e' : 'transparent',
        transition: 'all 200ms ease',
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'36px'}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant={'h3'}>What did you play?</Typography>

          {!isEdit && sportBackGroundWithPosition.length > 1 && (
            <IconButton
              onClick={() =>
                deleteSportBackground(sportBackgroundElementPosition)
              }
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <DeleteFileRedIcon />
            </IconButton>
          )}
        </Box>
        <SportList
          sports={sports}
          selectedSportId={selectedSportId}
          handleSportToggle={handleSportToggle}
        />
      </Box>

      <SportBackgroundItem
        isEdit={isEdit}
        handleDragOver={handleDragOver}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        handleFileChange={handleFileChange}
        triggerFileInput={triggerFileInput}
        removeFileFromBackground={removeFileFromBackground}
        sportBackgroundElementPosition={sportBackgroundElementPosition}
        sportBackGroundWithPosition={sportBackGroundWithPosition}
        setSportBackGroundWithPosition={setSportBackGroundWithPosition}
        selectedSportId={selectedSportId}
      />
      <SnackbarCustom
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default SportVerificationItem;

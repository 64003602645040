import React from 'react';
import { Box, Typography } from '@mui/material';
import SportBackgroundImageFileItem from '../SportBackgroundImageFileItem/SportBackgroundImageFileItem';
import { ISportBackgroundFileData } from '../../../../../interfaces/user-sport-background.interface';
import { ISportBackgroundWithPosition } from '../SportVerification';
import { DocumentScannerOutlined } from '@mui/icons-material';

interface IProps {
  sportBackGroundWithPosition: ISportBackgroundWithPosition[];
  sportBackgroundElementPosition: number;
  removeFileFromBackground: (
    sportBackgroundElementPosition: number,
    file: File
  ) => void;
  handleFileDataChange: (
    index: number,
    data: Partial<ISportBackgroundFileData>
  ) => void;
}

const SportBackgroundFileList: React.FC<IProps> = ({
  sportBackGroundWithPosition,
  sportBackgroundElementPosition,
  removeFileFromBackground,
  handleFileDataChange,
}) => {
  const background = sportBackGroundWithPosition.find(
    item => item.position === sportBackgroundElementPosition
  );
  const fileData = background?.sportBackground?.fileData || [];

  const renderFilePreview = (
    fileData: ISportBackgroundFileData,
    index: number
  ) => {
    const file = fileData.file;
    const fileType = file.type;

    if (fileType.startsWith('image/')) {
      return (
        <SportBackgroundImageFileItem
          fileData={fileData}
          index={index}
          removeFileFromBackground={() =>
            removeFileFromBackground(sportBackgroundElementPosition, file)
          }
          handleFileDataChange={handleFileDataChange}
        />
      );
    } else if (fileType === 'application/pdf') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <DocumentScannerOutlined
            sx={{
              color: 'text.secondary',
              minWidth: '24px',
              minHeight: '24px',
            }}
          />
          <Typography
            variant={'h4'}
            sx={{
              color: 'text.secondary',
              fontWeight: 400,
              lineHeight: '140%',
            }}
          >
            {file.name}
          </Typography>
        </Box>
      );
    } else {
      return <Typography>{file.name}</Typography>;
    }
  };

  return (
    <Box display="flex" flexDirection={'column'} gap="22px">
      {fileData.map((fileData, index) => (
        <Box
          key={index}
          width="100%"
          padding={'22px'}
          border={'1px solid'}
          borderColor={'background.darker'}
          borderRadius={'1px'}
        >
          {renderFilePreview(fileData, index)}
        </Box>
      ))}
    </Box>
  );
};

export default SportBackgroundFileList;

import React from 'react';
import StepForm from '../../Auth/VerifyAccount/StepForm';
import { Box, Typography } from '@mui/material';
import Input from '../../MUIComponents/Input';
import PasswordValidation from '../../Auth/Elements/PasswordValidation';

export const CompanyRegistrationForm: React.FC<any> = ({
  registerRegistration,
  registrationErrors,
  registrationFormSubmit,
  handleRegistrationSubmit,
  isRegistrationValid,
  step,
  setStep,
  inviteEmail,
  setIsShowLogin,
  dirtyFields = {},
}) => (
  <StepForm
    title="Create company account"
    onNext={handleRegistrationSubmit(registrationFormSubmit)}
    nextDisabled={!isRegistrationValid}
    step={step}
    setStep={setStep}
  >
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="center"
      gap="14px"
      sx={{ maxWidth: 392, width: '100%' }}
    >
      <Box display="flex" flexDirection="column" gap="36px">
        <Box display="flex">
          <Input
            containerFlex="1"
            caption="Email:"
            type="email"
            variant="outlined"
            defaultValue={inviteEmail ?? ''}
            fullWidth
            placeholder="Enter email"
            register={registerRegistration('email')}
            error={!!registrationErrors.email}
            helperText={
              registrationErrors.email ? registrationErrors.email.message : ''
            }
          />
        </Box>
        <Box display="flex" flexDirection={'column'} gap="14px">
          <Input
            caption="Password:"
            containerFlex="1"
            variant="outlined"
            type="password"
            fullWidth
            placeholder="Enter password"
            register={registerRegistration('password')}
            error={!!registrationErrors.password}
          />
          <PasswordValidation
            isDirty={Object.keys(dirtyFields).length > 0}
            errorMessages={
              Object.values(registrationErrors?.password?.types ?? {})
                .filter(Boolean)
                .flat() as string[]
            }
          />
        </Box>
        <Box display="flex">
          <Input
            caption="Confirm password:"
            containerFlex="1"
            variant="outlined"
            type="password"
            fullWidth
            placeholder="Confirm password"
            register={registerRegistration('confirmPassword')}
            error={!!registrationErrors.confirmPassword}
            helperText={
              registrationErrors.confirmPassword
                ? registrationErrors.confirmPassword.message
                : ''
            }
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'right'} marginTop={'16px'}>
        <Box display={'flex'} flexDirection={'column-reverse'} gap={'8px'}>
          <Typography
            onClick={() => setIsShowLogin(true)}
            variant="body4"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              transition: 'color 0.3s',
              ':hover': { color: 'button.primary' },
            }}
          >
            Already have an account?{' '}
          </Typography>
        </Box>
      </Box>
    </Box>
  </StepForm>
);

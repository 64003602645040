import { Box, SxProps } from '@mui/material';
import React from 'react';

interface IconRoundButtonProps {
  sx?: SxProps;
  icon?: React.ReactNode;
  width?: string | number;
  height?: string | number;
  fontSize?: number | string;
  color?: string;
  backgroundColor?: string;
  children?: React.ReactNode;
}

const IconRoundBackground = ({
  sx,
  icon,
  width,
  height,
  fontSize,
  color,
  backgroundColor,
  children,
}: IconRoundButtonProps) => {
  return (
    <Box
      sx={{
        width: width || '50px',
        height: height || '50px',
        fontSize: fontSize || '30px',
        color: color || '#727F93',
        borderRadius: '50%',
        backgroundColor: backgroundColor || '#F6F7F8',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',

        ...sx,
      }}
    >
      {icon || children}
    </Box>
  );
};

export default IconRoundBackground;

import { Box, useMediaQuery } from '@mui/material';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import StepHeader from './StepHeader';
import CustomButton from '../../MUIComponents/CustomButton';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import { FC, PropsWithChildren } from 'react';
import LeftArrowIcon from '../../Icons/LeftArrowIcon';
import {
  ButtonIconPositionEnum,
  ButtonTypeEnum,
} from '../../../enums/button-type-enum';

interface IStepFormProps {
  onNext: () => void;
  setStep: (_: number) => void;
  nextDisabled?: boolean;
  backDisabled?: boolean;
  step: number;
  title: string | React.ReactNode;
  needBackButton?: boolean;
}

const StepForm: FC<PropsWithChildren<IStepFormProps>> = ({
  onNext,
  setStep,
  nextDisabled = false,
  backDisabled = false,
  step,
  children,
  title,
  needBackButton = true,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      gap={mobileView ? '2.25rem' : '3rem'}
    >
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        padding={mobileView ? '2.25rem 1rem' : '2.25rem 2.25rem 3rem 2.25rem'}
        gap={'2.25rem'}
        bgcolor={'background.white'}
      >
        <StepHeader step={`STEP 0${step}`} title={title} />
        {children}
      </Box>
      <Box
        sx={theme => ({
          gap: '8px',
          justifyContent:
            step === 1 || !needBackButton ? 'right' : 'space-between',
          [theme.breakpoints.down('md')]: {
            p: '0 1rem',
          },
        })}
        display={'flex'}
      >
        {step > 1 && needBackButton && (
          <CustomButton
            icon={<LeftArrowIcon />}
            variantType={ButtonTypeEnum.SECONDARY}
            buttonWidth={mobileView ? '45%' : '220px'}
            onClick={() => setStep(step - 1)}
            iconPosition={ButtonIconPositionEnum.LEFT}
            disabled={backDisabled}
          >
            Back
          </CustomButton>
        )}
        <CustomButton
          type="submit"
          icon={<RightArrowIcon />}
          buttonWidth={mobileView ? '45%' : '220px'}
          onClick={onNext}
          disabled={nextDisabled}
        >
          {step === 3 ? 'sumbit' : 'next'}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default StepForm;

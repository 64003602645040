import { SvgIcon, SvgIconProps } from '@mui/material';

const PauseIcon = ({ width, height, sx, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: width || 28,
        height: height || 28,
        fill: 'none',
        ...sx,
      }}
      {...rest}
    >
      <path
        d="M11.6667 5.83337H7V22.1667H11.6667V5.83337Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.0007 5.83337H16.334V22.1667H21.0007V5.83337Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default PauseIcon;

import { useState } from 'react';
import AccountSettingsSidebar from '../../components/AccountSettings/AccontSettingsSidebar/AccontSettingsSidebar';
import SidebarLayout from '../../layouts/SidebarLayout';
import { Box } from '@mui/material';
import SectionLayout from '../../layouts/SectionLayout';
import BlockedUsersTab from '../../components/AccountSettings/BlockedUsersTab/BlockedUsersTab';
import MobileAccountSettingsMenu from '../../components/AccountSettings/AccontSettingsSidebar/MobileAccountSettingsMenu';
import SecurityTab from '../../components/AccountSettings/SecurityTab/SecurityTab';
import NotificationsTab from '../../components/AccountSettings/NotificationsTab/NotificationsTab';
import OtherTab from '../../components/AccountSettings/OtherTab/OtherTab';
import ManageSubscriptionsTab from '../../components/AccountSettings/ManageSubscriptionsTab/ManageSubscriptionsTab';
import { SettingMenuItemType } from '../../enums/setting-menu-type-enum';

const AccountSettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState<SettingMenuItemType>(
    SettingMenuItemType.LOG_IN_AND_SECURITY
  );

  const handleMenuItemClick = (selectedTab: SettingMenuItemType) => {
    setSelectedTab(selectedTab);
  };

  return (
    <SidebarLayout
      sidebar={
        <AccountSettingsSidebar
          handleMenuItemClick={handleMenuItemClick}
          selectedTab={selectedTab}
        />
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow="1"
        width="100%"
        maxWidth={
          selectedTab === SettingMenuItemType.MANAGE_SUBSCRIPTIONS
            ? '648px'
            : 'none'
        }
      >
        <MobileAccountSettingsMenu
          handleMenuItemClick={handleMenuItemClick}
          selectedTab={selectedTab}
        />
        <SectionLayout>
          {selectedTab === SettingMenuItemType.LOG_IN_AND_SECURITY && (
            <SecurityTab />
          )}
          {selectedTab === SettingMenuItemType.NOTIFICATIONS && (
            <NotificationsTab />
          )}
          {selectedTab === SettingMenuItemType.BLOCKED_USERS && (
            <BlockedUsersTab />
          )}
          {selectedTab === SettingMenuItemType.MANAGE_SUBSCRIPTIONS && (
            <ManageSubscriptionsTab />
          )}
          {selectedTab === SettingMenuItemType.OTHER && <OtherTab />}
        </SectionLayout>
      </Box>
    </SidebarLayout>
  );
};

export default AccountSettingsPage;

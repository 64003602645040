import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ChatStateEnum, ChatTypeEnum } from '../../../enums/chat-enum';
import { IChat, IMessage, IUser } from './chatApi';
import { IGroupChat } from './groupApi';

export interface ForwardingFrom {
  user: IUser;
  message: IMessage;
}

interface IChatState {
  chatState: ChatStateEnum;
  chatType: ChatTypeEnum;
  selectedChat?: IChat | IGroupChat;
  chatsLoaded: boolean;
  isTokenLoading: boolean;
  isMessageSending: boolean;
  token?: string;
  page: string;
  replyingTo?: IMessage;
  forwardingFrom?: ForwardingFrom;
}

const initialState = {
  chatState: ChatStateEnum.NEW_PERSONAL,
  chatType: ChatTypeEnum.PERSONAL,
  chatsLoaded: false,
  page: '',
  isTokenLoading: false,
  isMessageSending: false,
} satisfies IChatState as IChatState;

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: create => ({
    setSelectedChat: create.reducer<IChat | IGroupChat | undefined>(
      (state, action) => {
        state.selectedChat = action.payload;
        state.page = '';
      }
    ),
    setChatState: create.reducer<ChatStateEnum>((state, action) => {
      state.chatState = action.payload;
    }),
    setPage: create.reducer<string>((state, action) => {
      state.page = action.payload;
    }),
    resetPage: create.reducer(state => {
      state.page = '';
    }),
    setToken: create.reducer<string>((state, action) => {
      state.token = action.payload;
    }),
    resetToken: create.reducer(state => {
      state.token = undefined;
    }),
    setChatType: create.reducer<ChatTypeEnum>((state, action) => {
      state.chatType = action.payload;
      state.page = '';
      state.token = undefined;
      state.chatsLoaded = false;
    }),
    setChatsLoaded: create.reducer(state => {
      state.chatsLoaded = true;
    }),
    resetChatsLoaded: create.reducer(state => {
      state.chatsLoaded = false;
    }),
    setTokenLoading: create.reducer<boolean>((state, action) => {
      state.isTokenLoading = action.payload;
    }),
    setMessageSending: create.reducer<boolean>((state, action) => {
      state.isMessageSending = action.payload;
    }),
    setReplyingTo: create.reducer<IMessage>((state, action) => {
      state.replyingTo = action.payload;
    }),
    resetReplyingTo: create.reducer(state => {
      state.replyingTo = undefined;
    }),
    setForwardingFrom: create.reducer<ForwardingFrom>((state, action) => {
      state.forwardingFrom = action.payload;
    }),
    resetForwardingFrom: create.reducer(state => {
      state.forwardingFrom = undefined;
    }),
  }),
  selectors: {
    getChatState: state => state.chatState,
    getChatType: state => state.chatType,
    getSelectedChat: state => state.selectedChat,
    getPage: state => state.page,
    getChatsLoaded: state => state.chatsLoaded,
    getMessageSending: state => state.isMessageSending,
    getToken: state => state.token,
    getTokenLoading: state => state.isTokenLoading,
    getReplyingTo: state => state.replyingTo,
    getForwardingFrom: state => state.forwardingFrom,
  },
});

export const getToken = createSelector(
  chatSlice.selectors.getToken,
  chatSlice.selectors.getTokenLoading,
  (token, isTokenLoading) => ({ token, isTokenLoading })
);

export default chatSlice.reducer;
export const {
  getChatState,
  getSelectedChat,
  getPage,
  getChatType,
  getChatsLoaded,
  getMessageSending,
  getReplyingTo,
  getForwardingFrom,
} = chatSlice.selectors;
export const {
  setChatState,
  setSelectedChat,
  setPage,
  resetPage,
  setToken,
  resetToken,
  setChatType,
  setChatsLoaded,
  resetChatsLoaded,
  setTokenLoading,
  setMessageSending,
  setReplyingTo,
  resetReplyingTo,
  setForwardingFrom,
  resetForwardingFrom,
} = chatSlice.actions;

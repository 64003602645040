import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { verifyCodeSchema } from '../../../validation/auth-validation';
import {
  useResendVerifyCodeMutation,
  useVerifyCodeMutation,
} from '../../../store/apiSlice/auth/authApi';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CustomButton from '../../MUIComponents/CustomButton';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import Input from '../../MUIComponents/Input';
import { errorHelper } from '../../../utils/helper/error-helper';
import { CookieEnum } from '../../../enums/cookie-enum';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import Cookies from 'js-cookie';
import { setAuthData } from '../../../store/apiSlice/auth/authSlice';
import { useDispatch } from 'react-redux';

interface VerifyCodeFormInputs {
  email: string;
  verificationCode: string;
  password: string;
}

const VerifyCode = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email, password } = state || '';

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarError, setSnackbarError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<VerifyCodeFormInputs>({
    resolver: yupResolver(verifyCodeSchema),
  });

  const [verifyCode, { isLoading, isError, error, data: responseData }] =
    useVerifyCodeMutation();

  const onSubmit = async (data: VerifyCodeFormInputs) => {
    try {
      const result = await verifyCode(data).unwrap();

      const userId = result.user.id;
      const email = result.user.email;
      const role = result.user.role || '';

      Cookies.set(CookieEnum.ACCESS_TOKEN, result.accessToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.REFRESH_TOKEN, result.refreshToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.USER, JSON.stringify({ id: userId, email }), {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.ROLE, role, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });

      dispatch(setAuthData({ user: result.user }));

      setSnackbarMessage('Verification successful');
      setSnackbarError(false);
      navigate('/welcome');
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
      console.error('Verification failed:', error);
    }
  };

  const [resendVerificationCode] = useResendVerifyCodeMutation();

  const resendVerificationCodeSubmit = async (email: string): Promise<void> => {
    try {
      const emailFromForm = getValues('email');
      await resendVerificationCode({
        email: email ? email : emailFromForm ? emailFromForm : 'null',
      }).unwrap();
      setSnackbarMessage(
        `Verification code resent, please check your email: ${email ?? emailFromForm}`
      );
      setSnackbarError(false);
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="3rem"
      width={'min(26.5625rem,100%)'}
    >
      <Typography variant="h2">Verify Code</Typography>
      <Box display="flex" flexDirection="column" gap="1.375rem">
        <Input
          caption="Email:"
          type="email"
          variant="outlined"
          fullWidth
          placeholder="Enter email address"
          defaultValue={email || ''}
          register={register('email')}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
        />
        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Input
            caption="Verification Code:"
            variant="outlined"
            fullWidth
            placeholder="Enter verification code"
            register={register('verificationCode')}
            error={!!errors.verificationCode}
            helperText={
              errors.verificationCode ? errors.verificationCode.message : ''
            }
          />
          <Typography variant="body4">
            Verification code has been sent to {email}
          </Typography>
        </Box>
        <Input
          caption="Password:"
          type="password"
          variant="outlined"
          fullWidth
          placeholder="Enter password"
          defaultValue={password || ''}
          register={register('password')}
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ''}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={mobileView ? 'column' : 'row'}
        justifyContent={'space-between'}
        gap={'36px'}
        alignItems={'center'}
      >
        <CustomButton
          buttonWidth={mobileView ? '100%' : '50%'}
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          icon={<RightArrowIcon />}
        >
          Verify
        </CustomButton>
        <Typography
          onClick={() => resendVerificationCodeSubmit(email)}
          variant="body4"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            transition: 'color 0.3s',
            ':hover': { color: 'button.primary' },
          }}
        >
          Resend verification code?{' '}
        </Typography>
      </Box>
      <SnackbarCustom
        open={!!snackbarMessage}
        onClose={handleCloseSnackbar}
        message={snackbarMessage || ''}
        severity={
          snackbarError
            ? SnackbarSeverityEnum.ERROR
            : SnackbarSeverityEnum.SUCCESS
        }
      />
    </Box>
  );
};

export default VerifyCode;

import {
  Avatar,
  Box,
  Button,
  Grid,
  GridProps,
  Skeleton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LocationIcon from '../../../../../components/Icons/LocationIcon';
import MailIcon from '../../../../../components/Icons/MailIcon';
import PhoneIcon from '../../../../../components/Icons/PhoneIcon';
import WebsiteIcon from '../../../../../components/Icons/WebsiteIcon';
import { useGetMyCompanyQuery } from '../../../../../store/apiSlice/userCompanyInfoApi';
import { selectAuthData } from '../../../../../store/selectors/authSelector';
import { colors } from '../../../../../theme/theme';

const CompanyCard = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  // eslint-disable-next-line
  const { data: myData, error, isLoading } = useGetMyCompanyQuery();
  const authData = useSelector(selectAuthData);

  const onEditProfileClick = useCallback(
    () => navigate('/profile'),
    [navigate]
  );

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="rounded"
          sx={{
            width: '100%',
            minHeight: {
              xs: '310px',
              lg: '420px',
            },
            marginTop: {
              xs: '40px',
              lg: '0px',
            },
          }}
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: { xs: '0 16px 16px', lg: 0 },
          }}
        >
          <Box
            display="flex"
            flexDirection={mobileView ? 'row' : 'column'}
            alignItems="center"
            sx={
              mobileView
                ? {
                    columnGap: '20px',
                    width: '100%',
                    position: 'absolute',
                    top: '-25px',
                    left: '0',
                    padding: '0 22px',
                  }
                : {}
            }
          >
            <Avatar
              alt="Profile"
              src={authData?.user?.avatar?.fileUrl || ''}
              sx={{
                [theme.breakpoints.up('xs')]: { width: 100, height: 100 },
                [theme.breakpoints.up('lg')]: { width: 140, height: 140 },
                border: `2px solid ${theme.palette.background.white}`,
              }}
            />
            <Box
              className="profile-name-location-container"
              width="100%"
              m={mobileView ? '35px 0 0' : '28px 0'}
            >
              <Typography
                variant="h3"
                color={colors.fontDark}
                justifyContent="center"
                alignItems="center"
                textAlign={{ xs: 'left', lg: 'center' }}
                sx={{ overflowWrap: 'break-word' }}
              >
                {myData?.companyName || ''}
              </Typography>
              <Typography
                mt="6px"
                mb="22px"
                variant="caption"
                color="#727F93"
                display="flex"
                justifyContent={{ lg: 'center' }}
                alignItems="start"
                textAlign="center"
                gap="12px"
                sx={{ overflowWrap: 'break-word' }}
              >
                {myData?.industries?.[0]?.industry}
              </Typography>
            </Box>
          </Box>
          <StyledGrid
            container
            direction="column"
            wrap="nowrap"
            width="100%"
            gap="10px"
            mb="36px"
            sx={
              mobileView
                ? {
                    marginTop: '100px',
                  }
                : {}
            }
          >
            <Stack direction="row" mt="0 !important">
              <LocationIcon />
              <Typography variant="caption">
                {`${myData?.address || ''}, ${myData?.city || ''}, ${myData?.state || ''}, ${myData?.country || ''}, ${myData?.zipCode || ''}`}
              </Typography>
            </Stack>

            <Link to={myData?.phone ? 'tel:' + myData?.phone : '#'}>
              <Stack direction="row">
                <PhoneIcon />
                <Typography variant="caption">{myData?.phone || ''}</Typography>
              </Stack>
            </Link>

            <Link
              to={myData?.contactEmail ? 'mailto:' + myData?.contactEmail : '#'}
            >
              <Stack direction="row">
                <MailIcon />
                <Typography variant="caption">
                  {myData?.contactEmail || ''}
                </Typography>
              </Stack>
            </Link>

            <Link to={myData?.website ? myData?.website : '#'}>
              <Stack direction="row">
                <WebsiteIcon />
                <Typography variant="caption">
                  {myData?.website || ''}
                </Typography>
              </Stack>
            </Link>
          </StyledGrid>
          <Box className="profile-actions" width="100%">
            <Button
              onClick={onEditProfileClick}
              variant="outlined"
              fullWidth
              sx={{
                height: 50,
                color: '#142237',
                fontSize: 12,
                borderRadius: '4px',
                borderColor: 'background.dark',
              }}
            >
              Edit Profile
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CompanyCard;

const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  '& .MuiStack-root': {
    marginTop: '6px',
    gap: '12px',
    alignItems: 'flex-start',
  },
  '& .MuiSvgIcon-root': {
    width: 16,
    height: 16,
    marginTop: '2px',
    fill: 'none',
  },
  '& .MuiTypography-root': {
    color: '#142237',
    fontWeight: 400,
    fontSize: 14,
    display: 'inline-block',
    maxWidth: '100%',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
}));

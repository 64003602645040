import { SvgIcon, SvgIconProps } from '@mui/material';

function RightArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 24, height: 24, fill: 'none' }}
      viewBox="0 0 24 24"
      {...props}
      stroke={props?.stroke ?? '#FFFFFF'}
    >
      <path d="M4.90918 12H18.5455" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M15 16L19 12" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M15 8L19 12" strokeWidth="1.5" strokeLinecap="square" />
    </SvgIcon>
  );
}

export default RightArrowIcon;

import { SvgIcon, SvgIconProps } from '@mui/material';

const TrashRedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 24, height: 24, fill: 'none' }}
      {...props}
    >
      <path
        d="M4 7H20"
        stroke="#F5431D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.14307 7.71436L6.42864 21.0001H17.5715L18.8574 7.71436"
        stroke="#F5431D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M9 7V3H15V7"
        stroke="#F5431D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M10 12L14 16M14 12L10 16"
        stroke="#F5431D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default TrashRedIcon;

// This enum is used for get element by id in the DOM
export enum ElementIdEnum {
  ID_CONFIRMATION_FILE_INPUT = 'id-confirmation-file-input',
  SPORT_BACKGROUND_FILE_INPUT = 'sport-background-file-input',
  CREATE_POST_FILE_INPUT = 'create-post-file-input',
  ADD_POST_PHOTOS = 'add-post-photos',
  ADD_POST_VIDEOS = 'add-post-videos',
  ADD_PHOTOS_DRUG_AND_DROP = 'add-photos-drug-and-drop',
  ADD_VIDEOS_DRUG_AND_DROP = 'add-videos-drug-and-drop',
  ADD_PHOTOS_BUTTON = 'add-photos-button',
  ADD_VIDEOS_BUTTON = 'add-videos-button',
  ADD_ALBUM_BUTTON = 'add-album-button',
  ADD_COMPANY_IMAGE_ABOUT_BUTTON = 'add-company-image-about-button',
  ADD_PHOTOS_SIDEBAR_BUTTON = 'add-photos-sidebar-button',
  ADD_VIDEOS_SIDEBAR_BUTTON = 'add-videos-sidebar-button',
  ADD_BANNER = 'add-banner',
  ADD_PHOTOS_MODAL = 'add-photos-modal',
  ADD_VIDEOS_MODAL = 'add-videos-modal',
}

import React from 'react';

const UploadFileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0007 7L8.50068 13.5C8.10286 13.8978 7.87936 14.4374 7.87936 15C7.87936 15.5626 8.10286 16.1022 8.50068 16.5C8.8985 16.8978 9.43807 17.1213 10.0007 17.1213C10.5633 17.1213 11.1029 16.8978 11.5007 16.5L18.0007 10C18.7963 9.20435 19.2433 8.12522 19.2433 7C19.2433 5.87478 18.7963 4.79565 18.0007 4C17.205 3.20435 16.1259 2.75736 15.0007 2.75736C13.8755 2.75736 12.7963 3.20435 12.0007 4L5.50068 10.5C4.30721 11.6935 3.63672 13.3122 3.63672 15C3.63672 16.6878 4.30721 18.3065 5.50068 19.5C6.69415 20.6935 8.31285 21.364 10.0007 21.364C11.6885 21.364 13.3072 20.6935 14.5007 19.5L21.0007 13"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadFileIcon;

import { useState, useCallback } from 'react';

// Custom hook for modal management
export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to open the modal
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  // Function to close the modal
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  // Function to toggle the modal's open/close state
  const toggleModal = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  return {
    isOpen,
    openModal,
    closeModal,
    toggleModal,
  };
};

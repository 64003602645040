import React, { FC } from 'react';
import { ISportBackground } from '../../../../../interfaces/sport.interface';
import { Box, Link, ListItem, Typography } from '@mui/material';
import LinkIcon from '../../../../Icons/LinkIcon';
import { DocumentScannerOutlined } from '@mui/icons-material';

interface ISportBackgroundListItemProps {
  sportBackground: ISportBackground;
}

const SportBackgroundListItem: FC<ISportBackgroundListItemProps> = ({
  sportBackground,
}) => {
  function isPdfFile(filename: string): boolean {
    return filename.toLowerCase().endsWith('.pdf');
  }

  const isPdf = isPdfFile(sportBackground.icon);

  return (
    <ListItem
      sx={{
        p: '22px',
        border: '1px solid',
        borderColor: 'background.dark',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'flex-start',
        gap: { xs: '12px', md: '22px' },
      }}
    >
      {!isPdf ? (
        <Box
          component="img"
          sx={{
            height: 64,
            width: 64,
            objectFit: 'cover',
          }}
          src={sportBackground.icon}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: '#DDE1E8',
            width: 64,
            height: 64,
          }}
        >
          <DocumentScannerOutlined
            sx={{
              color: 'text.secondary',
              minWidth: '24px',
              minHeight: '24px',
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="body3"
          component="h4"
          color={'text.primary'}
          mb="6px"
          fontWeight={700}
        >
          {isPdf ? 'PDF document' : sportBackground.title}
        </Typography>

        {sportBackground.links.map((link, index) => (
          <Link
            key={`${link.url}${index}`}
            href={link.url}
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '6px',
              wordBreak: 'break-all',
              whiteSpace: 'normal',
              flexWrap: 'no-wrap',
            }}
          >
            <LinkIcon sx={{ width: '18px', height: '18px', mr: 1 }} />
            {link.label}
          </Link>
        ))}
      </Box>
    </ListItem>
  );
};

export default SportBackgroundListItem;

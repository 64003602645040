import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import {
  ICheckMyCompanySubscriptionExistResponse,
  ICompanyProductResponse,
  ICompanyProductsResponse,
  ICompanySubscriptionsResponse,
  ICreateCompanySubscriptionRequest,
  ICreatedCompanySubscriptionResponse,
  IDeleteSubscriptionResponse,
  IMyCompanySubscriptionResponse,
  IUserProductResponse,
  IUserProductsResponse,
} from '../../../interfaces/subscription.interface';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_COMPANY_MICROSERVICE_API_URL}/subscription`
  ),
  tagTypes: ['subscription'],

  endpoints: builder => ({
    getCompanyProducts: builder.query<ICompanyProductsResponse, void>({
      query: () => '/company-products',
      providesTags: ['subscription'],
    }),

    getCompanyProductById: builder.query<ICompanyProductResponse, string>({
      query: id => `/company-products/${id}`,
      providesTags: ['subscription'],
    }),

    getUserProducts: builder.query<IUserProductsResponse, void>({
      query: () => '/user-products',
      providesTags: ['subscription'],
    }),

    getUserProductById: builder.query<IUserProductResponse, string>({
      query: id => `/user-products/${id}`,
      providesTags: ['subscription'],
    }),

    createCompanySubscription: builder.mutation<
      ICreatedCompanySubscriptionResponse,
      ICreateCompanySubscriptionRequest
    >({
      query: createSubscriptionDto => ({
        url: '/create-company-subscription',
        method: 'POST',
        body: createSubscriptionDto,
      }),
      invalidatesTags: ['subscription'],
    }),

    getMyCompanySubscription: builder.query<
      IMyCompanySubscriptionResponse,
      void
    >({
      query: () => ({
        url: '/my-company-subscription',
        method: 'GET',
      }),
    }),

    checkMyCompanySubscriptionExist: builder.query<
      ICheckMyCompanySubscriptionExistResponse,
      void
    >({
      query: () => ({
        url: '/my-company-subscription-exist',
        method: 'GET',
      }),
    }),

    deleteSubscription: builder.mutation<IDeleteSubscriptionResponse, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['subscription'],
    }),

    getMyCompanySubscriptions: builder.query<
      ICompanySubscriptionsResponse,
      void
    >({
      query: () => '/my-company-subscriptions',
      providesTags: ['subscription'],
    }),
  }),
});

export const {
  useGetCompanyProductsQuery,
  useGetCompanyProductByIdQuery,
  useGetUserProductsQuery,
  useGetUserProductByIdQuery,
  useDeleteSubscriptionMutation,
  useGetMyCompanySubscriptionsQuery,
  useCreateCompanySubscriptionMutation,
  useGetMyCompanySubscriptionQuery,
  useCheckMyCompanySubscriptionExistQuery,
} = subscriptionApi;

export default subscriptionApi;

import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { formatTime } from '../../../utils/video-player-time-format';

interface VideoDurationOverlayProps {
  duration?: number;
  nameOfClass: string;
}

const VideoDurationOverlay: FC<VideoDurationOverlayProps> = ({
  duration,
  nameOfClass,
}) => {
  return (
    <Box
      className={nameOfClass}
      sx={{
        height: 'fit-content',
        position: 'absolute',
        top: 6,
        right: 6,
        display: { xs: 'none', md: 'flex' },
        flexDirection: { xs: 'inherit', md: 'column' },
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
      }}
    >
      {duration && (
        <Box
          sx={theme => ({
            p: '6px 12px',
            borderRadius: '20px',
            alignSelf: 'flex-end',
            backgroundColor: theme.palette.secondary.main,
          })}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: '800',
              lineHeight: '10px',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
            }}
          >
            {formatTime(duration)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default VideoDurationOverlay;

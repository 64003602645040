import React, { FC } from 'react';
import { Box, Grid, Link, SxProps, Theme, Typography } from '@mui/material';
import { DocumentScannerOutlined } from '@mui/icons-material';
import { IUserSportBackgroundFile } from '../../../../../interfaces/file.interface';

interface AchievementEntryProps {
  achievementFile: IUserSportBackgroundFile;
}

const AchievementFileEntry: FC<AchievementEntryProps> = ({
  achievementFile,
}) => {
  const isPdf = isPdfFile(achievementFile.uniqueKey ?? '');

  function isPdfFile(filename: string): boolean {
    return filename.toLowerCase().endsWith('.pdf');
  }

  return (
    <Grid container rowGap="28px" sx={gridContainerStyles}>
      <Grid item display="flex" flexDirection="row">
        {isPdf ? (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={achievementFile.fileUrl}
          >
            <DocumentScannerOutlined
              sx={{
                color: 'text.secondary',
                minWidth: '100px',
                minHeight: '100px',
              }}
            />
          </Link>
        ) : (
          <Box
            component="img"
            width="100px"
            height="100px"
            src={achievementFile.fileUrl}
            alt="achievement img"
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        )}
      </Grid>
      <Grid item display="flex" flexDirection="row">
        <Typography className="label" variant="caption">
          Achievement name:
        </Typography>
        <Typography className="plain-text" variant="body3">
          {`${achievementFile.fileName ?? ''}`}
        </Typography>
      </Grid>
      {!isPdf && (
        <Grid item display="flex" flexDirection="row">
          <Typography className="label" variant="caption">
            Description:
          </Typography>
          <Typography className="plain-text" variant="body3">
            {`${achievementFile.description ?? ''}`}
          </Typography>
        </Grid>
      )}
      <Grid item display="flex" flexDirection="row">
        <Typography className="label" variant="caption">
          Link:
        </Typography>
        <Link
          href={`${achievementFile.fileUrl ?? ''}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography className="plain-text" variant="body3">
            {`${achievementFile.fileUrl ?? ''}`}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

const gridContainerStyles: SxProps<Theme> = theme => ({
  '& .label': {
    width: 165,
    flexShrink: 0,
  },
  '& .plain-text': {
    fontWeight: 400,
    lineHeight: '140%',
    color: theme.palette.text.primary,

    textAlign: 'left',
    flexGrow: '1',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    minWidth: 0,
  },
  '& .MuiGrid-item': {
    width: '100%',
  },
});

export default AchievementFileEntry;

import {
  Box,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import SortIcon from '../Icons/SortIcon';

interface ISortMenuItem {
  label: string;
  value: string;
}

export interface IListSortPops {
  sortMenuItems: ISortMenuItem[];
  sortMenuLabel: string;
  queryParam?: string;
  showAtMobile?: boolean;
  listSortStyle?: SxProps;
}

const ListSort: React.FC<IListSortPops> = ({
  sortMenuItems,
  sortMenuLabel,
  queryParam = 'sortBy',
  showAtMobile = false,
  listSortStyle,
}) => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [searchParams, setSearchParams] = useSearchParams();
  const initialQuery = searchParams.get(queryParam) || sortMenuItems[0].value;
  const [queryValue, setQueryValue] = useState<string>(initialQuery);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setQueryValue(initialQuery);
  }, [initialQuery]);

  const handleMenuChange = (event: SelectChangeEvent<string>) => {
    const query = event.target.value as string;
    setQueryValue(query);

    setSearchParams(prevParams => {
      prevParams.set(queryParam, query);
      return prevParams;
    });
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (showAtMobile || matches)
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl
          fullWidth
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: theme.palette.text.primary,
            }}
            component={'label'}
            id={sortMenuLabel}
          >
            {sortMenuLabel}
          </Typography>
          <Box>
            <Select
              IconComponent={props => <ArrowDownIcon {...props} />}
              MenuProps={{
                sx: {
                  '& .MuiMenu-list': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  },
                },
              }}
              labelId={sortMenuLabel}
              value={queryValue}
              onChange={handleMenuChange}
              displayEmpty
              inputProps={{ 'aria-label': sortMenuLabel }}
              sx={{
                fontSize: '14px',
                color: theme.palette.text.primary,
                border: 'none',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                textDecoration: 'underline',
                textUnderlinePosition: 'under',
                '& .MuiSelect-select': {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                  background: 'transparent',
                  paddingLeft: '1px',
                  paddingRight: '0px',
                  paddingTop: '0px',
                  paddingBottom: '2px',
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiSelect-select:focus': {
                  outline: 'none',
                },
                '& .MuiSelect-select:focusWithin': {
                  outline: 'none',
                },
                '& .MuiSelect-icon': {
                  color: theme.palette.text.primary,
                  backgroundColor: 'transparent',
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    borderBottomLeftRadius: '0px',
                    borderBottomRightRadius: '0px',
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                    borderBottomLeftRadius: '0px',
                    borderBottomRightRadius: '0px',
                  },
                ...listSortStyle,
              }}
            >
              {sortMenuItems.length > 0 &&
                sortMenuItems.map(({ value, label }) => (
                  <MenuItem
                    key={value}
                    value={value}
                    sx={{ fontSize: '14px', fontWeight: 400 }}
                  >
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </FormControl>
      </Box>
    );
  else
    return (
      <>
        <IconButton
          sx={{
            padding: 0,
            height: '28px',
            width: '28px',
            minWidth: '28px',
            minHeight: '28px',
          }}
          onClick={handleMenuOpen}
        >
          <SortIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': sortMenuLabel,
          }}
        >
          {sortMenuItems.length > 0 &&
            sortMenuItems.map(({ value, label }) => (
              <MenuItem
                key={value}
                onClick={() => {
                  setQueryValue(value);
                  setSearchParams(prevParams => {
                    prevParams.set(queryParam, value);
                    return prevParams;
                  });
                  handleMenuClose();
                }}
                sx={{ fontSize: '14px', fontWeight: 400 }}
              >
                {label}
              </MenuItem>
            ))}
        </Menu>
      </>
    );
};

export default ListSort;

export const isSupportedCompanyAboutFileHelper = (file: File): boolean => {
  return (
    file.type.startsWith('image/jpeg') ||
    file.type.startsWith('image/png') ||
    file.type.startsWith('image/jpg') ||
    file.type.startsWith('image/webp') ||
    file.type === 'application/pdf'
  );
};
export const isSupportedIdConfirmationFileHelper = (file: File): boolean => {
  return (
    file.type.startsWith('image/jpeg') ||
    file.type.startsWith('image/png') ||
    file.type.startsWith('image/jpg') ||
    file.type.startsWith('image/webp')
  );
};

export const isSupportedIdConfirmationVideoFileHelper = (
  file: File
): boolean => {
  return file.type.startsWith('video/');
};

export const isSupportedSportBackgroundFileHelper = (file: File): boolean => {
  return (
    file.type.startsWith('image/jpeg') ||
    file.type.startsWith('image/png') ||
    file.type.startsWith('image/jpg') ||
    file.type.startsWith('image/webp') ||
    file.type === 'application/pdf' ||
    file.type.startsWith('video/mp4') ||
    file.type.startsWith('video/avi')
  );
};

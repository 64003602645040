import { List } from '@mui/material';
import CandidateItem from './CandidateItem';
import { IUser } from '../../../../interfaces/user.interface';

interface ICandidatesListProps {
  candidates: IUser[];
}

const CandidatesList: React.FC<ICandidatesListProps> = ({ candidates }) => {
  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '12px', lg: '16px' },
        paddingTop: '20px',
      }}
    >
      {candidates.map(candidate => {
        return <CandidateItem key={candidate.id} user={candidate} />;
      })}
    </List>
  );
};

export default CandidatesList;

import React from 'react';
import { Box, Divider, IconButton, Menu, MenuItem } from '@mui/material';

import { Add, DeleteOutline } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import {
  ButtonTypeEnum,
  ConnectionPrimaryButtonLabelEnum,
  ConnectionSecondaryButtonLabelEnum,
} from '../../../../../enums/button-type-enum';
import CloseX from '../../../../../components/Icons/CloseX';
import RemoveConnectionIcon from '../../../../../components/Icons/RemoveConnectionIcon';
import BlockUserIcon from '../../../../../components/Icons/BlockUserIcon';
import SendConnectionMessageIcon from '../../../../../components/Icons/SendConnectionMessageIcon';
import DoneIcon from '../../../../../components/Icons/DoneIcon';

interface ConnectionActionsProps {
  matches: boolean;
  isLoading: boolean;
  onPrimaryAction: () => void;
  onSecondaryAction?: () => void;
  onMoreRemove?: () => void;
  onMoreBlock?: () => void;
  primaryLabel: string;
  secondaryLabel?: string;
  showSecondary: boolean;
  isMyConnectionsTab?: boolean;
}

const ConnectionActions: React.FC<ConnectionActionsProps> = ({
  matches,
  isLoading,
  onPrimaryAction,
  onSecondaryAction,
  onMoreRemove,
  onMoreBlock,
  primaryLabel,
  secondaryLabel,
  showSecondary,
  isMyConnectionsTab = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display={'flex'}
      gap={matches ? '16px' : '6px'}
      justifyContent={'flex-end'}
      maxWidth={showSecondary && matches ? '330px' : '180px'}
      width={showSecondary && matches ? '100%' : 'fit-content'}
    >
      {!matches ? (
        <IconButton
          disabled={isLoading}
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'background.lightDark',
            bgcolor:
              primaryLabel === ConnectionPrimaryButtonLabelEnum.ACCEPT ||
              primaryLabel === ConnectionPrimaryButtonLabelEnum.SEND_REQUEST
                ? 'primary.main'
                : 'transparent',
            height: '40px',
            width: '40px',
            minHeight: '40px',
            minWidth: '40px',
            color:
              primaryLabel === ConnectionPrimaryButtonLabelEnum.SEND_REQUEST
                ? 'white'
                : 'inherit',
          }}
          onClick={onPrimaryAction}
        >
          {primaryLabel === ConnectionPrimaryButtonLabelEnum.ACCEPT && (
            <DoneIcon />
          )}
          {primaryLabel === ConnectionPrimaryButtonLabelEnum.SEND_MESSAGE && (
            <SendConnectionMessageIcon />
          )}
          {primaryLabel === ConnectionPrimaryButtonLabelEnum.CANCEL_REQUEST && (
            <CloseX stroke={'#727F93'} />
          )}
          {primaryLabel === ConnectionPrimaryButtonLabelEnum.SEND_REQUEST && (
            <Add />
          )}
        </IconButton>
      ) : (
        <CustomButton
          disabled={isLoading}
          onClick={onPrimaryAction}
          sx={{ p: '12px 16px' }}
          buttonWidth="100%"
          variant="contained"
          variantType={ButtonTypeEnum.PRIMARY}
          fullWidth
        >
          {primaryLabel}
        </CustomButton>
      )}
      {showSecondary &&
        secondaryLabel &&
        (!matches ? (
          <IconButton
            disabled={isLoading}
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'background.lightDark',
              bgcolor:
                secondaryLabel === ConnectionSecondaryButtonLabelEnum.DECLINE ||
                secondaryLabel === ConnectionSecondaryButtonLabelEnum.REMOVE
                  ? 'primary.main'
                  : 'transparent',
              height: '40px',
              width: '40px',
              minHeight: '40px',
              minWidth: '40px',
              color:
                secondaryLabel === ConnectionSecondaryButtonLabelEnum.REMOVE
                  ? '#fff'
                  : 'ingerit',
            }}
            onClick={onSecondaryAction}
          >
            {secondaryLabel === ConnectionSecondaryButtonLabelEnum.DECLINE && (
              <CloseX stroke="#fff" />
            )}
            {secondaryLabel === ConnectionSecondaryButtonLabelEnum.REMOVE && (
              <DeleteOutline />
            )}
          </IconButton>
        ) : (
          <CustomButton
            buttonWidth={'100%'}
            disabled={isLoading}
            variantType={ButtonTypeEnum.SECONDARY}
            variant="outlined"
            fullWidth
            sx={{ p: '12px 16px' }}
            onClick={onSecondaryAction}
          >
            {secondaryLabel}
          </CustomButton>
        ))}
      {isMyConnectionsTab && (
        <>
          <IconButton
            aria-label="More"
            sx={{
              p: 0,
              width: 'auto',
              height: 'auto',
              minWidth: 'auto',
              minHeight: 'auto',
              '&:hover': { background: 'transparent' },
            }}
            id="show-more-button"
            aria-controls={open ? 'show-more' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ color: 'text.secondary' }} />
          </IconButton>
          <Menu
            sx={{
              pt: '0px',
              pb: '0px',
            }}
            id="show-more"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'show-more-button',
              disablePadding: true,
            }}
          >
            <MenuItem
              onClick={() => {
                if (onMoreRemove) {
                  onMoreRemove();
                }
                handleClose();
              }}
              sx={{ display: 'flex', gap: '6px', fontSize: '14px', p: '15px' }}
            >
              <RemoveConnectionIcon /> Remove connection
            </MenuItem>
            <Divider
              component={'li'}
              sx={{
                '&.MuiDivider-root': { margin: 0 },
              }}
            />
            <MenuItem
              onClick={() => {
                if (onMoreBlock) {
                  onMoreBlock();
                }
                handleClose();
              }}
              sx={{ display: 'flex', gap: '6px', fontSize: '14px', p: '15px' }}
            >
              <BlockUserIcon stroke="#142237" /> Block user
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

export default ConnectionActions;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  IUserAchievementsResponse,
  IUserSportBackgroundResponse,
} from '../../interfaces/user-sport-background.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const userSportBackgroundApi = createApi({
  reducerPath: 'userSportBackgroundApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/user-sport-background`
  ),
  tagTypes: ['userSportBackground'],

  endpoints: builder => ({
    getUserSportBackgroundById: builder.query<
      IUserSportBackgroundResponse,
      string
    >({
      query: id => `/${id}`,
      providesTags: ['userSportBackground'],
    }),
    getUserSportBackgroundsByUserId: builder.query<
      IUserSportBackgroundResponse[],
      string
    >({
      query: userId => `/userId/${userId}`,
      providesTags: ['userSportBackground'],
    }),
    getMyUserSportBackgrounds: builder.query<
      IUserSportBackgroundResponse[],
      void
    >({
      query: () => '/my',
      providesTags: ['userSportBackground'],
    }),
    getUserAchievementsByUserId: builder.query<
      IUserAchievementsResponse[],
      string
    >({
      query: userId => `/achievements/userId/${userId}`,
      providesTags: ['userSportBackground'],
    }),
    getUserSportBackgroundsBySportId: builder.query<
      IUserSportBackgroundResponse[],
      string
    >({
      query: sportId => `/sportId/${sportId}`,
      providesTags: ['userSportBackground'],
    }),
    createUserSportBackground: builder.mutation<
      IUserSportBackgroundResponse,
      FormData
    >({
      query: formData => ({
        url: '',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['userSportBackground'],
    }),
    updateUserSportBackground: builder.mutation<
      IUserSportBackgroundResponse,
      { id: string; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: ['userSportBackground'],
    }),
    deleteUserSportBackground: builder.mutation<
      IUserSportBackgroundResponse,
      string
    >({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['userSportBackground'],
    }),
  }),
});

export const {
  useGetUserSportBackgroundByIdQuery,
  useGetUserSportBackgroundsByUserIdQuery,
  useGetMyUserSportBackgroundsQuery,
  useGetUserAchievementsByUserIdQuery,
  useGetUserSportBackgroundsBySportIdQuery,
  useCreateUserSportBackgroundMutation,
  useUpdateUserSportBackgroundMutation,
  useDeleteUserSportBackgroundMutation,
} = userSportBackgroundApi;

export default userSportBackgroundApi;

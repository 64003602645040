import {
  Box,
  BoxProps,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useGetIndividualStageForUserByAgentIdQuery } from '../../../../../store/apiSlice/individualStageApi';
import CareerDevelopmentCard from './CareerDevelopmentCard';
import LiveAgentCard from './LiveAgentCard';

interface CareerDevelopmentMainContentProps extends BoxProps {}

const CareerDevelopmentMainContent = ({
  ...rest
}: CareerDevelopmentMainContentProps) => {
  const queryParams = useMemo(
    () => ({
      // mocked agentId
      id: '7b78e3f2-1b57-4ef7-a637-5619cf95fb9f',
      page: 1,
      limit: 10,
      search: '',
    }),
    []
  );

  const { data: individualStages, isLoading } =
    useGetIndividualStageForUserByAgentIdQuery(queryParams);

  const individualStagesList = useMemo(() => {
    if (!individualStages) {
      return [];
    }

    return individualStages.data;
  }, [individualStages]);

  return (
    <Box {...rest} justifyContent="center">
      <Typography component="header" variant="h2" pb="48px">
        Career development
      </Typography>

      <Box
        width={1}
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading && <CircularProgress sx={{ alignSelf: 'center' }} />}
        {!isLoading && (
          <Grid container direction="row" columnGap="22px">
            <Grid item flex={1}>
              <Box component="article">
                <Typography variant="body1" pb="16px">
                  Your personalized career milestones are ready!
                </Typography>
                <Typography variant="body2" pb="36px">
                  Your dedicated agent has created a set of tailored milestones
                  to help you advance your career. Let’s start achieving your
                  goals together!
                </Typography>

                <Stack rowGap="12px">
                  {!!individualStagesList.length &&
                    individualStagesList.map((stage: any) => (
                      <CareerDevelopmentCard
                        key={stage.id}
                        component="section"
                        cardData={stage}
                      />
                    ))}
                </Stack>
              </Box>
            </Grid>
            <Grid item width={{ lg: 277 }}>
              <LiveAgentCard />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default CareerDevelopmentMainContent;

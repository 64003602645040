import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  MenuItem,
  MenuList,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ChatStateEnum } from '../../../../enums/chat-enum';
import { useRef, useState } from 'react';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import PersonalChatIcon from '../../../../components/Icons/PersonalChatIcon';
import GroupChatIcon from '../../../../components/Icons/GroupChatIcon';
import { useDispatch } from 'react-redux';
import { setChatState } from '../../../../store/apiSlice/chat/chatSlice';
import AddIcon from '@mui/icons-material/Add';
import MobileChatModalButton from '../MobileChatModalButton';

const chatMenuItems = [
  {
    icon: <PersonalChatIcon />,
    text: 'New personal chat',
    onClick: (dispatch: any) => {
      dispatch(setChatState(ChatStateEnum.NEW_PERSONAL));
    },
  },
  {
    icon: <GroupChatIcon />,
    text: 'New group chat',
    onClick: (dispatch: any) => {
      dispatch(setChatState(ChatStateEnum.NEW_GROUP));
    },
  },
];

const NewChatButton = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: {
          xs: '0px',
          lg: '0px 1rem',
        },
      }}
    >
      {mobileView ? (
        <IconButton
          onClick={() => setMenuOpen(true)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 'unset',
            minWidth: 'unset',
            p: 0,
          }}
        >
          <AddIcon
            sx={{
              minWidth: '28px',
              minHeight: '28px',
              color: 'text.secondary',
            }}
          />
        </IconButton>
      ) : (
        <CustomButton
          buttonWidth={'100%'}
          variantType={ButtonTypeEnum.SECONDARY}
          variant="outlined"
          btnRef={btnRef}
          onClick={() => setMenuOpen(true)}
        >
          new chat
        </CustomButton>
      )}

      <Popper
        open={menuOpen}
        anchorEl={btnRef.current}
        placement={mobileView ? 'bottom' : 'top-start'}
        disablePortal
        sx={{
          position: mobileView ? 'fixed' : 'absolute',
          top: 'unset !important',
          bottom: mobileView ? '0' : 'auto',
          left: 0,
          width: {
            xs: '100%',
            lg: btnRef.current ? btnRef.current.offsetWidth : 'auto',
          },
          zIndex: 20,
        }}
      >
        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.white',
              boxShadow: '0px 6px 30px 0px #111D3733',
            }}
          >
            <MenuList autoFocusItem={false} sx={{ p: 0 }}>
              {chatMenuItems.map(({ icon, text, onClick }, index) => (
                <MenuItem
                  key={text}
                  onClick={() => {
                    setMenuOpen(false);
                    onClick(dispatch);
                  }}
                  divider={index === 0}
                  sx={{
                    alignItems: 'center',
                    p: {
                      xs: '16px',
                      lg: '0.75rem 0.75rem 0.75rem 0.375rem',
                    },
                    gap: '0.375rem',
                  }}
                >
                  {icon}
                  <Typography
                    variant={'body3'}
                    sx={{
                      color: 'text.primary',
                      fontWeight: {
                        xs: 500,
                        lg: 700,
                      },
                    }}
                  >
                    {text}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>

            {mobileView && (
              <MobileChatModalButton
                variant={'outlined'}
                onClick={() => setMenuOpen(false)}
                label={'cancel'}
              />
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
      {mobileView && (
        <Fade in={menuOpen} timeout={100}>
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 10,
              transition: 'opacity 100ms ease-in-out',
            }}
          />
        </Fade>
      )}
    </Box>
  );
};

export default NewChatButton;

import { Box, Typography } from '@mui/material';

const StatLabel = ({ text, color }: Record<'text' | 'color', string>) => {
  return (
    <Box display="flex" flexDirection="row" gap="6px" alignItems="center">
      <Box
        sx={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: color,
        }}
      />
      <Typography
        variant={'caption'}
        sx={{ fontWeight: 400, fontSize: '14px' }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default StatLabel;

import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import MagnifyingGlassIcon from '../../../../components/Icons/MagnifyingGlassIcon';
import NewChatButton from './NewChatButton';

interface ISearchBarProps {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar = ({ onSearch }: ISearchBarProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchBarVisible, setSearchBarVisible] = useState<boolean>(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
        p: '0 1em',
      }}
    >
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {searchBarVisible ? (
          <ClickAwayListener onClickAway={() => setSearchBarVisible(false)}>
            <TextField
              onChange={onSearch}
              variant="outlined"
              fullWidth
              placeholder="Search... "
              sx={{
                bgcolor: 'background.light',
                border: `1px solid ${theme.palette.text.secondary}`,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MagnifyingGlassIcon />
                  </InputAdornment>
                ),
                autoFocus: true,
              }}
            />
          </ClickAwayListener>
        ) : (
          <>
            <Typography variant={mobileView ? 'h2' : 'body1'}>
              Messages
            </Typography>
            <IconButton
              onClick={() => setSearchBarVisible(true)}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 0,
              }}
            >
              <MagnifyingGlassIcon />
            </IconButton>
          </>
        )}
      </Box>
      {mobileView && <NewChatButton />}
    </Box>
  );
};

export default SearchBar;

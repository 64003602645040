import React from 'react';
import { List, ListItem } from '@mui/material';
import { IUserScore } from '../../../../interfaces/user.interface';
import ProfileScoreCard from './ProfileScoreCard';

interface IProfileScoreList {
  profileScore: IUserScore;
}
const ProfileScoreList = (props: IProfileScoreList) => {
  const { profileScore } = props;

  return (
    <List sx={{ columnGap: '16px' }}>
      {Object.entries(profileScore)
        .filter(([_, value]) => !value)
        .map(([key, value]) => (
          <ListItem key={key}>
            <ProfileScoreCard scoreKey={key} />
          </ListItem>
        ))}
    </List>
  );
};

export default ProfileScoreList;

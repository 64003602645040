import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import JobPostApply from '../../../components/JobPostApply/JobPostApply';
import SectionLayout from '../../../layouts/SectionLayout';
import { useGetMyAppliedByIdQuery } from '../../../store/apiSlice/jobpost/jobUserApplyApi';
import { IAppliedUser } from '../../../interfaces/jobpost.interface';
import { JobPostItem } from '../../JobPostsPage/components/JobPostsList/JobPostsList';

const MyJobApplicationDetailsPage = () => {
  const { applicationId } = useParams();

  const {
    data: jobPostData,
    isLoading,
    error,
  } = useGetMyAppliedByIdQuery(applicationId);

  let mappedJobpost: JobPostItem | undefined = undefined;

  if (jobPostData && 'appliedUser' in jobPostData) {
    mappedJobpost = {
      type: 'APPLIED_USER',
      appliedUser: jobPostData.appliedUser,
    };
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow="1" pt="22px">
      <SectionLayout
        height={'100%'}
        sx={{
          bgcolor: 'background.white',
          p: { xs: '36px 16px', md: '24px', lg: '16px 36px 48px 36px' },
        }}
      >
        {jobPostData && (
          <JobPostApply
            jobPost={
              mappedJobpost as {
                appliedUser: IAppliedUser;
                type: 'APPLIED_USER';
              }
            }
          />
        )}
      </SectionLayout>
    </Box>
  );
};

export default MyJobApplicationDetailsPage;

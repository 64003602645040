export const plainDataFormat = (date: Date | string) => {
  try {
    if (date instanceof Date) {
      if (isNaN(date as unknown as number))
        throw new Error('Invalid Date object');
    } else if (typeof date === 'string') {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate as unknown as number)) {
        throw new Error('Invalid date string');
      }
      date = parsedDate;
    } else {
      throw new Error('Input must be a Date object or a valid date string');
    }

    // Format the date
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}.${day}.${year}`;
  } catch (e) {
    console.log(e);

    return 'No information';
  }
};

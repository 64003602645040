import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}
function CupIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      className={cn({ [className!]: className !== undefined })}
    >
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M9.333 24.5h9.334M14 19.833V24.5M8.167 4.667h11.666M19.833 4.667V14a5.833 5.833 0 01-11.666 0V4.667"
      ></path>
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M3.5 10.5a2.333 2.333 0 104.667 0 2.333 2.333 0 00-4.667 0zM19.833 10.5a2.333 2.333 0 104.667 0 2.333 2.333 0 00-4.667 0z"
      ></path>
    </svg>
  );
}

export default CupIcon;

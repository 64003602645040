import { ISportResponse } from '../../../../../interfaces/sport.interface';
import { Box, Typography } from '@mui/material';
import SportListItem from './SportListItem';
import { colors } from '../../../../../theme/theme';

interface IProps {
  sports: ISportResponse[];
  selectedSportId: string | null;
  handleSportToggle: (sportId: string) => void;
}

const SportList = (props: IProps) => {
  const { sports, selectedSportId, handleSportToggle } = props;

  if (sports.length === 0) {
    return (
      <Typography style={{ color: colors.white }}>
        No sports available
      </Typography>
    );
  }

  return (
    <Box
      display="grid"
      sx={theme => ({
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1.375rem',

        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '1rem',
        },
      })}
    >
      {sports.map(sport => (
        <SportListItem
          key={sport.id}
          sport={sport}
          selectedSportId={selectedSportId}
          handleSportToggle={handleSportToggle}
        />
      ))}
    </Box>
  );
};

export default SportList;

import { SvgIcon, SvgIconProps } from '@mui/material';

const MailIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            sx={{ width: 24, height: 24, fill: 'none' }}
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M15.75 3.75H2.25V14.25H15.75V3.75Z" stroke="#727F93" stroke-width="1.5" stroke-linecap="round" />
            <path d="M2.25 5.25L9 9.75L15.75 5.25" stroke="#727F93" stroke-width="1.5" stroke-linecap="round" />
        </SvgIcon>
    );
};

export default MailIcon;

import {
  Box,
  BoxProps,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { SnackbarSeverityEnum } from '../../enums/snackbar-severity-enum';
import SnackbarCustom from '../MUIComponents/SnackbarCustom';

interface CareerDevelopmentProfileProps extends BoxProps {
  cardData: JSX.Element;
}
const CareerDevelopmentProfile = ({
  cardData,
  ...rest
}: CareerDevelopmentProfileProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // eslint-disable-next-line
  const [snackbarMessage, setSnackbarMessage] = useState('');
  // eslint-disable-next-line
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  return (
    <Box position="relative" width="100%" {...rest}>
      <Box
        sx={
          !mobileView
            ? {
                color: theme.palette.text.primary,
                width: { lg: 236, xl: 314 },
                height: 'fit-content',
                padding: { xl: '36px 0 22px' },
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
              }
            : {}
        }
      >
        <Grid
          container
          display="flex"
          flexDirection="column"
          rowGap={{ lg: '16px', xl: '22px' }}
          sx={{
            backgroundColor: '#F6F7F8',
            '& .MuiGrid-item': {
              width: '100%',
            },
          }}
        >
          <Grid
            p={{ lg: '28px 16px 16px', xl: '16px 22px' }}
            item
            className="home-edit-box"
            sx={{ backgroundColor: '#FFFFFF' }}
          >
            {cardData}
          </Grid>
        </Grid>
      </Box>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default CareerDevelopmentProfile;

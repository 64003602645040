import { Box, Typography } from '@mui/material';

interface IStepHeaderProps {
  step: string;
  title: string | React.ReactNode;
}

const StepHeader = ({ step, title }: IStepHeaderProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection={'column'}
      alignItems="center"
      gap={'16px'}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="20px"
        padding="0.375rem 0.75rem"
        width={'max-content'}
        border={'1px solid'}
        borderColor={'button.primary'}
      >
        <Typography typography="badge3">{step}</Typography>
      </Box>
      <Typography variant="h2" align="center">
        {title}
      </Typography>
    </Box>
  );
};

export default StepHeader;

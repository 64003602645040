// Note: if you use it somewhere else -> rename fn to make it more abstract and move to the 'shared' folder
export const foramtCareerDate = (date: string | Date): string => {
  let tempDate: Date;

  if (typeof date === 'string') {
    tempDate = new Date(date);
  } else {
    tempDate = date;
  }

  const options = {
    year: 'numeric',
    month: 'short',
  } as Intl.DateTimeFormatOptions;

  return new Intl.DateTimeFormat('en-US', options).format(tempDate);
};

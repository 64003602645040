import { Box, Slider } from '@mui/material';
import { useCallback, useState } from 'react';
import VolumeOffIcon from '../../Icons/VolumeOffIcon';
import VolumeOnIcon from '../../Icons/VolumeOnIcon';
import { StyledIconButton } from './StyledIconButton';

interface VolumeEditorProps {
  volume: number;
  mute: boolean;
  onMute: () => void;
  onVolumeChangeHandler: ((event: Event, value: number) => void) | undefined;
  onVolumeSeekUp: ((event: Event, value: number) => void) | undefined;
}

const VolumeEditor = ({
  volume,
  mute,
  onMute,
  onVolumeChangeHandler,
  onVolumeSeekUp,
}: VolumeEditorProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box
      className="icon__btn"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      sx={{
        display: 'inline-block',
        position: 'relative',
        height: '28px', // height and width required in order to the box with 'absolute' was centered correctly
        width: '28px',
      }}
    >
      {/* absolute box should stretch beyond parent top border to be able interact with slider and not trigger onMouseLeave event */}
      <Box
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          position: 'absolute',
          height: '50px',
          left: '50%',
          bottom: '50%',
          transform: 'translate(-50%, 50%)',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledIconButton
          onClick={onMute}
          sx={{
            padding: '0',
            minHeight: '25px',
            minWidth: '25px',
            height: 'fit-content',
            width: 'fit-content',
          }}
        >
          {mute ? (
            <VolumeOffIcon fontSize="medium" />
          ) : (
            <VolumeOnIcon fontSize="medium" />
          )}
        </StyledIconButton>

        {/* Volume Slider (mimicking popover behavior) */}
        {anchorEl && (
          <Box
            sx={theme => ({
              height: '120px',
              padding: '14px 6px',
              position: 'absolute',
              bottom: '50px', // Adjust based on your needs
              left: -10,
              bgcolor: theme.palette.secondary.main,
              boxShadow: 3,
              zIndex: 10,
            })}
          >
            <Slider
              orientation="vertical"
              value={volume * 100}
              min={0}
              max={100}
              shiftStep={1}
              step={1}
              size='medium'
              onChange={onVolumeChangeHandler as any}
              onChangeCommitted={onVolumeSeekUp as any}

              sx={theme => ({
                height: '100%',
                color: theme.palette.primary.main,

                '& .MuiSlider-thumb': {
                  height: 20,
                  width: 20,
                }
              })}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VolumeEditor;


import { TIndustry } from '../../../interfaces/jobpost.interface';
import JobPositionIcon from '../../../components/Icons/JobPositionIcon';
import formatEnumValue from '../../../utils/formatEnumValue';
import JobLocationIcon from '../../../components/Icons/JobLocationIcon';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { IMyJobPostSidebarProps } from './MyJobPostSidebar';

const JobPostShortInfo: React.FC<IMyJobPostSidebarProps> = ({ jobPost }) => {
  return (
    <>
      <Divider />

      <Stack p={'21px 16px'} gap={'16px'}>
        <Typography variant={'h2'}>{jobPost.title}</Typography>

        <Stack direction={'row'} gap={'28px'}>
          <Box display={'flex'} alignItems={'center'} gap={'6px'}>
            <JobPositionIcon />
            <Typography variant="body3">
              {formatEnumValue(jobPost.position)}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={'6px'}>
            <JobLocationIcon />
            <Typography variant="body3">
              {formatEnumValue(jobPost.location)}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Divider />

      <Stack direction={'column'} gap={'22px'} p={'21px 16px'}>
        <Box display={'flex'} flexDirection={'column'} flex={1} gap={'12px'}>
          <Typography variant="body3" fontSize={'12px'}>
            Industry:
          </Typography>
          {jobPost.industries.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0 }}>
              {jobPost.industries.map(
                ({ industry, id }: TIndustry, index: number) => (
                  <Typography
                    key={id}
                    component={'span'}
                    variant="body4"
                    fontSize={'14px'}
                    lineHeight={'normal'}
                  >
                    {industry}
                    {index < jobPost.industries.length - 1 && ' /\u00A0'}
                  </Typography>
                )
              )}
            </Box>
          )}
        </Box>
        <Box display={'flex'} flexDirection={'column'} flex={1} gap={'12px'}>
          <Typography variant="body3" fontSize={'12px'}>
            Salary:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0 }}>
            <Typography
              component={'span'}
              variant="body4"
              fontSize={'14px'}
              lineHeight={'normal'}
            >
              {`$${jobPost.minSalary} - $${jobPost.maxSalary} ${formatEnumValue(
                jobPost.salaryPeriod,
                false
              )}, commensurate with experience.`}
            </Typography>
          </Box>
        </Box>
      </Stack>

      <Divider />
    </>
  );
};

export default JobPostShortInfo;

import { useState } from "react";

// for managing multiple modals in one component
export const useModalManager = () => {
    const [currentModal, setCurrentModal] = useState<string | null>(null);
  
    const openModal = (modalName: string) => setCurrentModal(modalName);
    const closeModal = () => setCurrentModal(null);
  
    const toggleModal = (modalName: string) => {
      if (currentModal === modalName) {
        closeModal();
      } else {
        openModal(modalName);
      }
    };
  
    return { openModal, closeModal, toggleModal, currentModal };
  };
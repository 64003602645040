import { Button, ClickAwayListener, Stack, Tooltip } from '@mui/material';
import CustomButton from '../../MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { useState } from 'react';
import { useModalManager } from '../../../hooks/useModalManager';
import ApplyJobModal from '../../../pages/JobPostApplyPage/components/modals/ApplyJobModal';
import { useParams } from 'react-router-dom';

const JobPostApplyActionButtons = () => {
  const { id } = useParams();

  const { currentModal, toggleModal } = useModalManager();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleCopyJobLink = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        handleTooltipOpen();
        setTimeout(() => {
          handleTooltipClose();
        }, 3000);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <>
      {currentModal === 'APPLY_JOB_MODAL' && (
        <ApplyJobModal
          jobpostId={id as string}
          isOpen
          onClose={() => toggleModal('APPLY_JOB_MODAL')}
        />
      )}
      <Stack
        direction={'column'}
        spacing={1}
        sx={{
          width: '100%',
          p: '22px 16px',
          borderBottomWidth: '1px',
          borderBottomColor: 'background.dark',
          borderBottomStyle: 'solid',
        }}
      >
        <CustomButton
          buttonWidth="100%"
          variant="contained"
          variantType={ButtonTypeEnum.PRIMARY}
          fullWidth
          onClick={() => toggleModal('APPLY_JOB_MODAL')}
        >
          Apply job
        </CustomButton>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Link copied to clipboard!"
            >
              <Button
                onClick={handleCopyJobLink}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '1px',
                  padding: '0',
                  height: '50px',
                  width: '100%',
                  backgroundColor: 'transparent',
                  color: 'text.primary',
                  border: '1px solid',
                  borderColor: 'background.darker',
                  '&.Mui-disabled': {},
                }}
              >
                Copy job link
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </Stack>
    </>
  );
};

export default JobPostApplyActionButtons;

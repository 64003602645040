import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

interface IArticleLayoutProps extends BoxProps {
  children: ReactNode;
}

const ArticleLayout = ({ children, ...props }: IArticleLayoutProps) => {
  return (
    <Box
      component="article"
      sx={{
        bgcolor: 'background.white',
        p: { xs: '36px 16px', md: '24px', lg: '36px' },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ArticleLayout;

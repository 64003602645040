import { Client, Conversation } from '@twilio/conversations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatType,
  getSelectedChat,
  getToken,
  resetPage,
} from '../../store/apiSlice/chat/chatSlice';
import chatApi from '../../store/apiSlice/chat/chatApi';
import { ChatTypeEnum } from '../../enums/chat-enum';
import groupChatApi from '../../store/apiSlice/chat/groupApi';

const useTwilioClient = () => {
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const dispatch = useDispatch();
  const { token } = useSelector(getToken);
  const { id: chatId, externalId } = useSelector(getSelectedChat)!;
  const chatType = useSelector(getChatType);

  useEffect(() => {
    if (token) {
      const client = new Client(token);
      setConversation(null);

      client.on('initialized', async () => {
        try {
          const convo = await client.getConversationBySid(externalId);
          setConversation(convo);
        } catch (error) {
          console.log(error);
        }
      });
    }
  }, [token, externalId]);

  useEffect(() => {
    if (conversation) {
      conversation.on('messageAdded', () => {
        dispatch(resetPage());

        if (chatType === ChatTypeEnum.PERSONAL)
          dispatch(
            chatApi.util.invalidateTags([
              { type: 'messages', id: chatId },
              { type: 'chats', id: chatId },
            ])
          );
        else if (chatType === ChatTypeEnum.GROUP)
          dispatch(
            groupChatApi.util.invalidateTags([
              { type: 'messages', id: chatId },
              { type: 'chats', id: chatId },
            ])
          );
      });
    }
  }, [conversation, dispatch, chatId, chatType]);
};

export default useTwilioClient;

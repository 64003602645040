import { Box, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import CommentItem from '../../../../components/Comment/CommentItem';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import {
  IComment,
  ICommentResponse,
} from '../../../../interfaces/comment.interface';

interface PostCommentsProps {
  postCommentsPage: number;
  setPostCommentsPage: React.Dispatch<React.SetStateAction<number>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >;
  postCommentsData: ICommentResponse;
}

const ProfilePostComments: React.FC<PostCommentsProps> = ({
  postCommentsPage,
  setPostCommentsPage,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarSeverity,
  postCommentsData,
}) => {
  const [postCommentsArray, setPostCommentsArray] = useState<IComment[]>([]);
  const [hasMoreComments, setHasMoreComments] = useState<boolean>(true);

  const handleLoadMoreComments = () => setPostCommentsPage(prev => prev + 1);

  useEffect(() => {
    let newPostComments: IComment[] = [];

    newPostComments = postCommentsData?.data || [];

    if (postCommentsPage === 1) {
      setPostCommentsArray(newPostComments);
    } else {
      setPostCommentsArray(prevPostComments => [
        ...prevPostComments,
        ...newPostComments,
      ]);
    }

    if (postCommentsData) {
      const hasNext =
        postCommentsData.limit * (postCommentsData.page - 1) +
          postCommentsData.limit <
        postCommentsData.total;
      setHasMoreComments(hasNext);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCommentsData]);

  if (postCommentsArray.length > 0)
    return (
      <Box>
        <Stack component={'ul'} spacing={1}>
          {postCommentsArray.map(comment => (
            <CommentItem
              key={comment.id}
              comment={comment}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarSeverity={setSnackbarSeverity}
              setPostCommentsPage={setPostCommentsPage}
            />
          ))}
        </Stack>
        {hasMoreComments && (
          <IconButton
            onClick={handleLoadMoreComments}
            sx={{
              height: 17,
              minHeight: 17,
              minWidth: 200,
              fontSize: 14,
              fontWeight: 700,
              borderRadius: 12,
              p: 0,
              '&:hover': { bgcolor: 'transparent' },
              color: 'text.primary',
            }}
          >
            Show more
          </IconButton>
        )}
      </Box>
    );
  else return null;
};

export default ProfilePostComments;

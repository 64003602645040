import { Box } from '@mui/material';
import JobPostApplyActionButtons from './JobPostApplyActionButtons';
import JobPostApplyEmployerInfo from './JobPostApplyEmployerInfo';
import { ICompany } from '../../../interfaces/jobpost.interface';

export interface IJobPostApplySidebarProps {
  company: ICompany;
}

const JobPostApplySidebar: React.FC<IJobPostApplySidebarProps> = ({
  company,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="22px">
      <Box
        sx={{
          minWidth: 227,
          width: { xs: '100%', lg: 314 },
          borderRadius: '1px',
          bgcolor: 'background.white',
        }}
      >
        <JobPostApplyActionButtons />
        <JobPostApplyEmployerInfo company={company} />
      </Box>
    </Box>
  );
};

export default JobPostApplySidebar;

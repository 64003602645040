import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface PhotosIconProps extends SvgIconProps {
  iconColor?: string;
  active?: boolean;
}

const PhotosIcon = ({ iconColor, active, ...svgProps }: PhotosIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: svgProps.width ?? 28,
        height: svgProps.height ?? 28,
        fill: 'none',
        '& > path': {
          stroke: `${iconColor} !important`,
        },
      }}
      {...svgProps}
    >
      <path
        d="M24.4998 3.5L8.1665 3.5V19.8333H24.4998V3.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M19 22.5V24.5H3.5L3.5 9.5H5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M19.8335 8.16663H19.8452"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M8.5 14.4999L12.4178 10.9153C12.5489 10.7841 12.7046 10.68 12.876 10.6089C13.0473 10.5379 13.231 10.5013 13.4165 10.5013C13.602 10.5013 13.7857 10.5379 13.957 10.6089C14.1284 10.68 14.2841 10.7841 14.4152 10.9153L18.6665 15.1666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M17.5 14L19.418 12.082C19.5491 11.9508 19.7048 11.8467 19.8761 11.7757C20.0475 11.7046 20.2312 11.6681 20.4167 11.6681C20.6022 11.6681 20.7858 11.7046 20.9572 11.7757C21.1286 11.8467 21.2842 11.9508 21.4153 12.082L24 14.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default PhotosIcon;

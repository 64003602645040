import { Box, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import JobPostsFilters from './JobPostsFilters/JobPostsFilter';

const JobPostsSidebar = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="22px"
      marginTop={!matches ? '-20px' : 0}
      minWidth={{ lg: 236, xl: 314 }}
    >
      <JobPostsFilters />
    </Box>
  );
};

export default JobPostsSidebar;

import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}
function BallIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      fill="none"
      viewBox="0 0 29 28"
      className={cn({ [className!]: className !== undefined })}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4 14a10.5 10.5 0 1021 0 10.5 10.5 0 00-21 0zM7.092 6.592l14.816 14.816M7.092 21.408L21.908 6.592M14.5 3.5A10.5 10.5 0 0025 14M4 14a10.5 10.5 0 0110.5 10.5"
      ></path>
    </svg>
  );
}

export default BallIcon;

import { Avatar, Box, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../../enums/cookie-enum';
import { IChat } from '../../../../store/apiSlice/chat/chatApi';
import { IGroupChat } from '../../../../store/apiSlice/chat/groupApi';
import { useMemo } from 'react';

export function isGroupChat(chat: IChat | IGroupChat): chat is IGroupChat {
  return (chat as IGroupChat)?.members !== undefined;
}

interface IChatProps {
  chat: IChat | IGroupChat;
  isShowLastMessage?: boolean;
}

const Chat = ({ chat, isShowLastMessage = true }: IChatProps) => {
  const src = useMemo(() => {
    if (isGroupChat(chat))
      return chat.thumb ? `data:image/png;base64, ${chat.thumb}` : '';
    else
      return chat.withUser.thumbnail
        ? `data:image/png;base64, ${chat.withUser.thumbnail}`
        : '';
  }, [chat]);

  return (
    <Box
      width={'100%'}
      justifyContent={'flex-start'}
      sx={{ '&:hover': { bgcolor: 'background.light', cursor: 'pointer' } }}
      display={'flex'}
      gap="1rem"
      p={'0.375rem'}
      borderRadius={'1px'}
    >
      <Box position={'relative'}>
        <Avatar
          sx={{ width: '3.125rem', height: '3.125rem' }}
          src={src.endsWith('undefined') ? '' : src}
        />
        <Box
          position={'absolute'}
          bottom={'0'}
          right={'0'}
          bgcolor={'#3ACC40'}
          width={'0.75rem'}
          height={'0.75rem'}
          borderRadius={'50%'}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'0.375rem'}
        justifyContent={'center'}
      >
        <Typography
          typography={'body3'}
          fontWeight={700}
          sx={{ color: 'text.primary' }}
        >
          {isGroupChat(chat)
            ? chat.name
            : `${chat.withUser.firstName} ${chat.withUser.lastName}`}
        </Typography>

        {isShowLastMessage && (
          <Box display={'flex'} gap={'0.125rem'}>
            {chat.lastMessageFrom && (
              <Typography
                maxWidth={'27ch'}
                variant="body4"
                noWrap
                sx={{ color: 'text.secondary' }}
                fontWeight={500}
              >
                <Typography variant="body4" fontWeight={700}>
                  {chat.lastMessageFrom &&
                    (chat.lastMessageFrom ===
                    JSON.parse(Cookies.get(CookieEnum.USER) || '').id
                      ? 'You: '
                      : isGroupChat(chat)
                        ? 'Group:'
                        : `${chat.withUser.firstName} ${chat.withUser.lastName}: `)}
                </Typography>
                {chat.lastMessageText || 'file'}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Chat;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import {
  IAppliedUser,
  ICancelJobpostApplicationRequest,
  IJobpost,
  IJobPostApplyRejectRequest,
  IPaginatedIMyJobApplicationResponse,
} from '../../../interfaces/jobpost.interface';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const jobUserApplyApi = createApi({
  reducerPath: 'jobUserApplyApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_COMPANY_MICROSERVICE_API_URL}/job-user-applied`
  ),
  tagTypes: ['job-user-applied'],

  endpoints: builder => ({
    getMyJobpostsApplied: builder.query<
      IPaginatedIMyJobApplicationResponse,
      {
        page?: number;
        limit?: number;
        search?: string;
        sort?: string;
        industries?: string;
        position?: string;
        location?: string;
      } | void
    >({
      query: (
        args = {
          page: 1,
          limit: 10,
          search: '',
          sort: 'desc',
          industries: '',
          position: '',
          location: '',
        }
      ) => {
        const {
          page = 1,
          limit = 10,
          search = '',
          sort = 'desc',
          industries = '',
          position = '',
          location = '',
        } = args || {};
        return `/my?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}&industries=${industries}&position=${position}&location=${location}`;
      },
      providesTags: ['job-user-applied'],
    }),

    checkIsIAppliedToJobpost: builder.query<IJobpost, string | undefined>({
      query: jobpostId => `/check-is-applied/${jobpostId}`,
      providesTags: ['job-user-applied'],
    }),

    getMyAppliedById: builder.query<
      { appliedUser: IAppliedUser },
      string | undefined
    >({
      query: jobpostId => `/applied-my/${jobpostId}`,
      providesTags: ['job-user-applied'],
    }),

    getAllAppliedToJob: builder.query<IJobpost, string | undefined>({
      query: jobpostId => `/job/${jobpostId}`,
      providesTags: ['job-user-applied'],
    }),

    applyJobpost: builder.mutation<IJobpost, FormData>({
      query: (data: FormData) => ({
        url: `/apply`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['job-user-applied'],
    }),

    cancelJobpostApplication: builder.mutation<
      IJobpost,
      { data: ICancelJobpostApplicationRequest; jobpostId: string }
    >({
      query: ({ data, jobpostId }) => ({
        url: `/cancel-job-apply/${jobpostId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['job-user-applied'],
    }),

    getAppliedUsersByJobpostId: builder.query<
      IPaginatedIMyJobApplicationResponse,
      {
        jobpostId: string;
        page?: number;
        limit?: number;
        search?: string;
        sort?: string;
        status?: string;
      } | void
    >({
      query: (
        args = {
          jobpostId: '',
          page: 1,
          limit: 10,
          search: '',
          sort: 'desc',
          status: '',
        }
      ) => {
        const {
          jobpostId,
          page = 1,
          limit = 10,
          search = '',
          sort = 'desc',
          status = '',
        } = args || {};
        return `/applied-user/jobpostId/${jobpostId}?search=${encodeURIComponent(search)}&page=${page}&limit=${limit}&sort=${sort}&status=${status}`;
      },
      providesTags: ['job-user-applied'],
    }),

    rejectJobApply: builder.mutation<
      void,
      { applicationId: string; data: IJobPostApplyRejectRequest }
    >({
      query: ({ applicationId, data }) => ({
        url: `reject-job-apply/userAppliedId/${applicationId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['job-user-applied'],
    }),
    acceptJobApply: builder.mutation<void, any>({
      query: userAppliedId => ({
        url: `accept-job-apply/userAppliedId/${userAppliedId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['job-user-applied'],
    }),

    getAppliedUserByApplicationId: builder.query<
      { appliedUser: IAppliedUser },
      string
    >({
      query: applicationId => `/applied-user/userAppliedId/${applicationId}`,
      providesTags: ['job-user-applied'],
    }),
  }),
});

export const {
  useGetMyJobpostsAppliedQuery,
  useCheckIsIAppliedToJobpostQuery,
  useGetAllAppliedToJobQuery,
  useGetMyAppliedByIdQuery,
  useApplyJobpostMutation,
  useCancelJobpostApplicationMutation,
  useGetAppliedUsersByJobpostIdQuery,
  useGetAppliedUserByApplicationIdQuery,
  useAcceptJobApplyMutation,
  useRejectJobApplyMutation,
} = jobUserApplyApi;

export default jobUserApplyApi;

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import BlockUserIcon from '../../../../components/Icons/BlockUserIcon';
import RemoveConnectionIcon from '../../../../components/Icons/RemoveConnectionIcon';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { IComment } from '../../../../interfaces/comment.interface';
import { useDeleteCommentByIdMutation } from '../../../../store/apiSlice/post/commentApi';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { selectAuthData } from '../../../../store/selectors/authSelector';

interface IProfileCommentMoreMenuProps {
  comment: IComment;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >;
  setPostCommentsPage: React.Dispatch<React.SetStateAction<number>>;
}

const ProfileCommentMoreMenu: React.FC<IProfileCommentMoreMenuProps> = ({
  comment,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  setPostCommentsPage,
}) => {
  const { user: myData } = useSelector(selectAuthData);

  const isMyComment = myData?.id === comment.userId;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // eslint-disable-next-line
  const [deleteComment, { isLoading: isDeleting }] =
    useDeleteCommentByIdMutation();

  const handleDeleteComment = async (commentId: string) => {
    try {
      await deleteComment(commentId).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Comment deleted!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setPostCommentsPage(1);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to delete the comment: ', error);
    }
  };

  const myCommentMenuItems = [
    {
      label: 'Remove comment',
      icon: <RemoveConnectionIcon />,
      action: () => handleDeleteComment(comment.id),
    },
  ];

  const notMyCommentMenuItems = [
    {
      label: 'Complain',
      icon: <BlockUserIcon />,
      action: () => {},
    },
  ];

  return (
    <>
      <IconButton
        aria-label="More"
        sx={{
          p: 0,
          width: 'auto',
          height: 'auto',
          minWidth: 'auto',
          minHeight: 'auto',
          '&:hover': { background: 'transparent' },
        }}
        id="show-more-button"
        aria-controls={open ? 'show-more' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: 'text.secondary' }} />
      </IconButton>
      <Menu
        sx={{
          pt: '0px',
          pb: '0px',
        }}
        id="show-more"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'show-more-button',
          disablePadding: true,
        }}
      >
        {isMyComment
          ? myCommentMenuItems.length > 0 &&
            myCommentMenuItems.map((item, idx) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  item.action();
                  handleClose();
                }}
                sx={{
                  display: 'flex',
                  gap: '6px',
                  fontSize: '14px',
                  p: '15px',
                }}
              >
                {item.icon} {item.label}
              </MenuItem>
            ))
          : notMyCommentMenuItems.length > 0 &&
            notMyCommentMenuItems.map((item, idx) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  item.action();
                  handleClose();
                }}
                sx={{
                  display: 'flex',
                  gap: '6px',
                  fontSize: '14px',
                  p: '15px',
                }}
              >
                {item.icon} {item.label}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default ProfileCommentMoreMenu;

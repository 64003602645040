import { SvgIcon, SvgIconProps } from '@mui/material';

const ClockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 32, height: 32, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <g stroke="#434E5F" strokeLinecap="square" strokeWidth="1.5">
        <path
          strokeLinejoin="round"
          d="M28.589 17.361a12 12 0 10-12.543 10.623"
        ></path>
        <path strokeLinejoin="round" d="M16.666 9.333V16l4 4"></path>
        <path d="M20.666 25.333L23.334 28l5.334-5.333"></path>
      </g>
    </SvgIcon>
  );
};

export default ClockIcon;

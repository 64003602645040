import React from 'react';
import { Control, FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';
import { Box, useMediaQuery } from '@mui/material';
import Input from '../../../MUIComponents/Input';
import { ProfilelInfoFormInputs } from '../../../../interfaces/profile.interface';
import DateInput from '../../../MUIComponents/DateInput';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface ProfileEducationFormProps {
  control: Control<ProfilelInfoFormInputs, any>;
  register: UseFormRegister<ProfilelInfoFormInputs>;
  errors: FieldErrors<ProfilelInfoFormInputs>;
  index: number;
}

type EducationErrors = {
  university?: FieldError;
  specialty?: FieldError;
  startYear?: FieldError;
  endYear?: FieldError;
};

const ProfileEducationForm: React.FC<ProfileEducationFormProps> = ({
  control,
  register,
  errors,
  index,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const educationErrors = errors?.educations?.[index] as EducationErrors | undefined;

  return (
    <Box
      mt='48px'
      display="flex"
      flexDirection="column"
      gap={mobileView ? '28px' : '36px'}
    >
      <Box 
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        gap={mobileView ? '28px' : '22px'}
      >
        <Input 
          caption='University:'
          containerFlex='1'
          variant="outlined"
          fullWidth
          placeholder="Choose university"
          register={register(`educations.${index}.university` as const)}
          error={!!educationErrors?.university}
          helperText={educationErrors?.university?.message || ''}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Input 
          caption='Speciality:'
          containerFlex='1'
          variant="outlined"
          fullWidth
          placeholder="Choose speciality"
          register={register(`educations.${index}.specialty` as const)}
          error={!!educationErrors?.specialty}
          helperText={educationErrors?.specialty?.message || ''}
        />
      </Box>
      <Box 
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        gap={mobileView ? '28px' : '22px'}
      >
        <Box flex='1'>
          <DateInput<ProfilelInfoFormInputs>
            control={control}
            name={`educations.${index}.startYear`}
            label='Start of education:'
            error={educationErrors?.startYear}
            helperText={educationErrors?.startYear?.message || ''}
          />
        </Box>
        <Box flex='1'>
          <DateInput<ProfilelInfoFormInputs>
            control={control}
            name={`educations.${index}.endYear`}
            label='End of education:'
            error={educationErrors?.endYear}
            helperText={educationErrors?.endYear?.message || ''}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileEducationForm;
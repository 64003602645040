import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import BaseModal from '../../../components/MUIComponents/BaseModal';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface SubscriptionInformationProps {
  isOpen?: boolean;
  onClose: () => void;
}

const SubscriptionModal = ({
  onClose,
  isOpen = false,
}: SubscriptionInformationProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <BaseModal
      modalSx={{
        borderColor: 'none !important',
        marginTop: '0',
        boxShadow: 'none !important',
      }}
      isOpen={isOpen}
      toggle={onClose}
      header={{
        component: (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            height={'50px'}
          >
            <Typography variant={'h3'} textAlign={'center'}>
              Interest / Payment plan breakdown
            </Typography>
          </Box>
        ),
      }}
      disableDefaultFooter={true}
      disableContentMargin={true}
    >
      <Divider sx={{ margin: '22px 0 36px 0' }} />
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'36px 22px'}
        gap={'28px'}
        sx={{
          backgroundColor: 'background.light',
        }}
      >
        <Typography variant={'caption'} textTransform={'uppercase'}>
          Payment Plan Options
        </Typography>
        <Box display={'flex'} flexDirection={'column'} gap={'22px'}>
          <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
            <Typography variant={'h4'} color={'text.primary'}>
              1. Annual Upfront Payment
            </Typography>
            <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
              <Typography variant={'h4'} color={'#434E5F'} fontWeight={'400'}>
                - Full amount paid at the start of the subscription
              </Typography>{' '}
              <Typography variant={'h4'} color={'#434E5F'} fontWeight={'400'}>
                - No additional fees or interest
              </Typography>
            </Box>
          </Box>{' '}
          <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
            <Typography variant={'h4'} color={'text.primary'}>
              2. Semi-Annual Payment Plan
            </Typography>
            <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
              <Typography variant={'h4'} color={'#434E5F'} fontWeight={'400'}>
                - Two equal payments: at start and after 6 months
              </Typography>{' '}
              <Typography variant={'h4'} color={'#434E5F'} fontWeight={'400'}>
                - Interest rate: 5% of total annual subscription
              </Typography>
            </Box>
          </Box>{' '}
          <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
            <Typography variant={'h4'} color={'text.primary'}>
              3. Quarterly Payment Plan
            </Typography>
            <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
              <Typography variant={'h4'} color={'#434E5F'} fontWeight={'400'}>
                - Four equal payments: at start and every 3 months
              </Typography>{' '}
              <Typography variant={'h4'} color={'#434E5F'} fontWeight={'400'}>
                - Interest rate: 8% of total annual subscription
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </BaseModal>
  );
};

export default SubscriptionModal;

const LeaveChatIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 6V3H2.25V15H10.5V12"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M6.75 9H15.75L13.5 6.75"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 11.25L15.75 9"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeaveChatIcon;

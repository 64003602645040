import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface Props {
  externalUse?: boolean;
}

export const Loading = ({ externalUse }: Props): React.JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(!externalUse && { height: '100vh', width: '100vw' }),
        ...(externalUse && {
          height: 'calc(100vh - 300px)',
          width: 'calc(100vw - 300px)',
        }),
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '-20px',
          left: '-20px',
          right: '-20px',
          bottom: '-20px',
          backgroundColor: 'transparent',
          color: 'primary.main',
          filter: 'blur(5px)',
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 3,
        }}
      >
        <CircularProgress
          sx={{
            color: 'primary.main',
          }}
        />
        <Typography variant="h4" sx={{ color: 'primary.main' }}>
          Loading...
        </Typography>
      </Box>
    </Box>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const postReactionApi = createApi({
  reducerPath: 'postReactionApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_POST_MICROSERVICE_API_URL}/reaction`
  ),
  tagTypes: ['reaction'],

  endpoints: builder => ({
    likePost: builder.mutation<void, any>({
      query: postId => ({
        url: `/add/like/${postId}`,
        method: 'POST',
      }),
      invalidatesTags: ['reaction'],
    }),
    dislikePost: builder.mutation<void, any>({
      query: postId => ({
        url: `/add/dislike/${postId}`,
        method: 'POST',
      }),
      invalidatesTags: ['reaction'],
    }),
  }),
});

export const { useLikePostMutation, useDislikePostMutation } = postReactionApi;

export default postReactionApi;

import { SvgIcon, SvgIconProps } from '@mui/material';

const ExitFullScreenIcon = ({ width, height, sx, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: width || 28,
        height: height || 28,
        fill: 'none',
        ...sx,
      }}
      {...rest}
    >
      <path
        d="M17.5 22.1667V17.5H22.1667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M17.5 5.83325V10.4999H22.1667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.83398 17.5H10.5007V22.1667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.83398 10.4999H10.5007V5.83325"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default ExitFullScreenIcon;

import {
  Box,
  Button,
  IconButton,
  Modal,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../theme/theme';
import CloseIcon from '../Icons/CloseIcon';

interface HeaderProps {
  text?: string;
  component?: React.ReactNode;
  styles?: SxProps;
}

interface FooterProps {
  isOkButtonDisabled?: boolean;
  okText?: string;
  declineText?: string;
  component?: React.ReactNode;
  okButtonStyles?: React.CSSProperties;
  declineButtonStyles?: React.CSSProperties;
  styles?: SxProps;
}

export type ModalCloseReason = 'backdropClick' | 'escapeKeyDown' | undefined;

interface BaseModalProps {
  isOpen: boolean;
  toggle: (_?: unknown, reason?: ModalCloseReason) => void;
  boxSx?: SxProps;
  modalSx?: SxProps;
  header?: HeaderProps;
  footer?: FooterProps;
  content?: React.ReactNode;
  children?: React.ReactNode;
  disableDefaultFooter?: boolean;
  disableEscapeButton?: boolean;
  disableContentMargin?: boolean;
  onAccept?: () => void;
  onDecline?: () => void;
  styleEscapeBtnStylesAbove?: SxProps;
  styleEscapeBtnStylesBelow?: SxProps;
}

const defaultFooter: FooterProps = {
  isOkButtonDisabled: false,
  okText: 'OK',
  declineText: 'Decline',
};

const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  boxSx,
  modalSx,
  header,
  footer = defaultFooter,
  content,
  children,
  disableDefaultFooter = false,
  disableEscapeButton = false,
  disableContentMargin = false,
  toggle,
  onAccept,
  onDecline,
  styleEscapeBtnStylesAbove,
  styleEscapeBtnStylesBelow,
}) => {
  const theme = useTheme();
  const isScreenSizeBelow1141 = useMediaQuery('(max-width:1141px)');
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      if (onAccept) await onAccept();
    } finally {
      setLoading(false);
    }
  };

  const boxStyles = !isScreenSizeBelow1141
    ? aboveScreenSize1141BoxStyles
    : belowScreenSize1141BoxStyles;

  const escapeBtnStyles = isScreenSizeBelow1141
    ? aboveScreenSize1141EscapeButtonStyles(styleEscapeBtnStylesAbove)
    : belowScreenSize1141EscapeButtonStyles(styleEscapeBtnStylesBelow);

  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      sx={{
        overflowY: 'auto',
        ...modalSx,
      }}
    >
      <Box
        sx={{
          [theme.breakpoints.down(1141)]: {
            width: '100vw',
          },

          // width: '875px',
          // maxHeight: '90dvh !important',
          // overflowY: 'auto',

          // '::-webkit-scrollbar': {
          //   width: '4px',
          // },
          // '::-webkit-scrollbar-thumb': {
          //   backgroundColor: 'rgba(0, 0, 0, 0.3)',
          //   borderRadius: '10px',
          // },
          // '::-webkit-scrollbar-track': {
          //   backgroundColor: 'transparent',
          // },

          overflowY: 'auto',
          ...boxStyles,
          ...boxSx,
        }}
      >
        {!disableEscapeButton && (
          <IconButton
            onClick={toggle}
            disableFocusRipple
            centerRipple
            size="small"
            sx={escapeBtnStyles}
          >
            <Box
              sx={{
                width: '50px',
                height: '50px',
                fontSize: '30px',
                color: '#727F93',
                borderRadius: '50%',
                backgroundColor: '#F6F7F8',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <CloseIcon />
            </Box>
          </IconButton>
        )}

        {/* HEADER */}
        {header?.component ||
          (header?.text && (
            <Typography
              variant="h2"
              height={50}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={header?.styles}
            >
              {header.text}
            </Typography>
          ))}

        {/* CONTENT */}
        {(content || children) && (
          <Box
            className="content"
            sx={{
              my: disableContentMargin ? 0 : 2,
              mt: disableContentMargin ? 0 : '36px',
            }}
          >
            {content || children || null}
          </Box>
        )}

        {/* FOOTER */}
        {footer?.component}
        {!disableDefaultFooter && !footer?.component && (
          <Box
            sx={{
              ...{ display: 'flex', justifyContent: 'space-between' },
              ...(isScreenSizeBelow1141 ? footerStylesBelowSS1141 : {}),
              ...footer.styles,
            }}
          >
            <Button
              variant="outlined"
              onClick={onDecline || toggle}
              sx={{
                ...{
                  height: 50,
                  [theme.breakpoints.up('lg')]: {
                    minWidth: 220,
                  },
                  color: '#142237',
                  fontSize: 12,
                  borderRadius: '4px',
                  borderColor: '#434E5F',
                },
                ...footer.declineButtonStyles,
              }}
            >
              {footer.declineText}
            </Button>
            <Button
              disabled={footer.isOkButtonDisabled || loading}
              variant="contained"
              onClick={handleClick}
              sx={{
                ...{
                  height: 50,
                  [theme.breakpoints.up('lg')]: {
                    minWidth: 220,
                  },
                  color: 'white',
                  fontSize: 12,
                  backgroundColor: '#F5431D',
                  borderRadius: '4px',
                },
                ...footer.okButtonStyles,
              }}
            >
              {loading ? (
                <Typography
                  variant="badge2"
                  sx={{
                    color: theme.palette.background.white,
                  }}
                >
                  {'Loading...'}
                </Typography>
              ) : (
                <>{footer.okText}</>
              )}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default BaseModal;

const baseBoxStyles = {
  width: { xs: '100%', lg: '875px' },
  boxShadow: 24,
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
};

const aboveScreenSize1141BoxStyles = {
  ...baseBoxStyles,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(calc(-1 * min(50%, 22rem)))',
  bgcolor: 'background.paper',
  // borderColor: 'transparent',
  // borderRadius: '1px',
  // boxShadow: 24,
  p: '36px 36px 48px',
};

const belowScreenSize1141BoxStyles = {
  ...baseBoxStyles,
  position: 'fixed' as 'fixed',
  maxHeight: '90dvh',
  bottom: 0,
  left: 0,
  width: '100vw',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 4,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  p: '20px 12px 12px',
};

const aboveScreenSize1141EscapeButtonStyles = (
  customStyles: SxProps = {}
): SxProps<Theme> => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: 0,

  '& .MuiBox-root': {
    backgroundColor: 'unset',
  },
  ...customStyles,
});

const belowScreenSize1141EscapeButtonStyles = (
  customStyles: SxProps = {}
): SxProps<Theme> => ({
  width: 'fit-content',
  height: 'fit-content',
  position: 'fixed',
  top: '34px',
  right: '34px',
  p: '2px',
  ...customStyles,
});

const footerStylesBelowSS1141 = {
  gap: '15px',
  '& button': {
    flex: 1,
    borderColor: colors.dark20,
  },
};

import { SvgIcon, SvgIconProps } from '@mui/material';

const AddVideosIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{ width: 24, height: 24, fill: 'none' }}
      {...props}
    >
      <path
        fill="none"
        d="M20 4H4V20H20V4Z"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M8 4V20"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M16 4V20"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4 8H8"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4 16H8"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4 12H20"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M16 8H20"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M16 16H20"
        stroke="#A1ABBA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default AddVideosIcon;

import { SvgIcon, SvgIconProps } from '@mui/material';

const EyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 16, height: 16, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M6.66675 7.99999C6.66675 8.35361 6.80722 8.69275 7.05727 8.9428C7.30732 9.19285 7.64646 9.33332 8.00008 9.33332C8.3537 9.33332 8.69284 9.19285 8.94289 8.9428C9.19294 8.69275 9.33342 8.35361 9.33342 7.99999C9.33342 7.64637 9.19294 7.30723 8.94289 7.05718C8.69284 6.80713 8.3537 6.66666 8.00008 6.66666C7.64646 6.66666 7.30732 6.80713 7.05727 7.05718C6.80722 7.30723 6.66675 7.64637 6.66675 7.99999Z"
        stroke="#727F93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8C12.4 10.6667 10.4 12 8 12C5.6 12 3.6 10.6667 2 8C3.6 5.33333 5.6 4 8 4C10.4 4 12.4 5.33333 14 8Z"
        stroke="#727F93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default EyeIcon;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LinkIcon(svgProps?: SvgIconProps) {
  return (
    <SvgIcon {...svgProps}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M6.75 11.25L11.25 6.75" stroke="#F5431D" strokeLinecap="square" strokeLinejoin="round"/>
        <path d="M8.25 4.5L8.59725 4.098C9.3006 3.39475 10.2545 2.9997 11.2491 2.99977C12.2438 2.99984 13.1976 3.39502 13.9009 4.09838C14.6041 4.80173 14.9992 5.75564 14.9991 6.75027C14.999 7.74489 14.6039 8.69875 13.9005 9.402L13.5 9.75" stroke="#F5431D" strokeLinecap="square" strokeLinejoin="round"/>
        <path d="M9.75011 13.5L9.45236 13.9005C8.74079 14.6041 7.78045 14.9988 6.77973 14.9988C5.77902 14.9988 4.81867 14.6041 4.10711 13.9005C3.75637 13.5537 3.47793 13.1408 3.28789 12.6856C3.09785 12.2304 3 11.7421 3 11.2489C3 10.7556 3.09785 10.2673 3.28789 9.81214C3.47793 9.35698 3.75637 8.94405 4.10711 8.59725L4.50011 8.25" stroke="#F5431D" strokeLinecap="square" strokeLinejoin="round"/>
      </svg>
    </SvgIcon>
  );
}

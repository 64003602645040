import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import SportVerificationItem from './SportVerificationItem/SportVerificationItem';
import {
  ISportBackground,
  ISportBackgroundFormData,
  ITeam,
} from '../../../../interfaces/user-sport-background.interface';
import { SubmitHandler } from 'react-hook-form';
import StepForm from '../StepForm';

interface SportBackgroundFormProps {
  onSubmitSport: SubmitHandler<ISportBackgroundFormData>;
  formData: Partial<ISportBackgroundFormData>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  isLoading: boolean;
}

export interface ISportBackgroundWithPosition {
  position: number;
  sportBackground: ISportBackground | null;
}

export interface ITeamWithPosition {
  position: number;
  team: ITeam | null;
}

const SportVerification: React.FC<SportBackgroundFormProps> = ({
  onSubmitSport,
  formData,
  setStep,
  step,
  isLoading,
}) => {
  const [sportBackGroundWithPosition, setSportBackGroundWithPosition] =
    useState<ISportBackgroundWithPosition[]>([
      { position: 1, sportBackground: null },
    ]);
  const [sportBackGroundsElements, setSportBackGroundsElements] = useState<
    number[]
  >([1]);

  useEffect(() => {
    if (sportBackGroundsElements?.length <= 0) {
      setSportBackGroundsElements([1]);
    }
  }, [sportBackGroundsElements]);

  const addSportBackground = () => {
    setSportBackGroundsElements(prevState => {
      const maxElement = Math.max(...prevState);

      return [...prevState, maxElement + 1];
    });
  };

  const deleteSportBackground = (value: number) => {
    setSportBackGroundsElements(prev =>
      prev.filter(backgroundValue => backgroundValue !== value)
    );
    setSportBackGroundWithPosition(prev =>
      prev.filter(sportBackGround => sportBackGround.position !== value)
    );
  };

  return (
    <StepForm
      title="Sport background"
      onNext={() =>
        onSubmitSport({
          sportBackgrounds: !sportBackGroundWithPosition?.length
            ? []
            : [
                ...sportBackGroundWithPosition.map(
                  item => (item.sportBackground as ISportBackground) ?? []
                ),
              ],
        })
      }
      nextDisabled={isLoading}
      backDisabled={isLoading}
      step={step}
      setStep={setStep}
    >
      {sportBackGroundsElements.map(value => {
        return (
          <Box key={value}>
            <SportVerificationItem
              sportBackgroundElementPosition={value}
              sportBackGroundWithPosition={sportBackGroundWithPosition}
              setSportBackGroundWithPosition={setSportBackGroundWithPosition}
              deleteSportBackground={deleteSportBackground}
            />
          </Box>
        );
      })}
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        border={'1px solid'}
        borderColor={'background.dark'}
        borderRadius={'4px'}
        padding={'1.375rem 0'}
        onClick={addSportBackground}
        style={{ cursor: 'pointer' }}
      >
        <Typography typography={'badge2'}>ADD SPORT BACKGROUND</Typography>
      </Box>
    </StepForm>
  );
};

export default SportVerification;

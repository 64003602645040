import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import Rating from '../../../../../components/MUIComponents/Rating';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { ChatStateEnum } from '../../../../../enums/chat-enum';
import { useModalManager } from '../../../../../hooks/useModalManager';
import {
  useCreateChatMutation,
  useGetAllMyChatsQuery,
} from '../../../../../store/apiSlice/chat/chatApi';
import {
  setChatState,
  setSelectedChat,
} from '../../../../../store/apiSlice/chat/chatSlice';
import { useGetAgentReviewsCountByAgentIdQuery } from '../../../../../store/apiSlice/reviewApi';
import { useGetUserAgentInfoByIdQuery } from '../../../../../store/apiSlice/userApi';
import LeaveReviewModal from '../modals/LeaveReviewModal';
import ReviewModal from '../modals/ReviewModal';

enum LiveAgentCardModals {
  REVIEW_MODAL = 'REVIEW_MODAL',
  LEAVE_REVIEW_MODAL = 'LEAVE_REVIEW_MODAL',
}

const LiveAgentCard = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const { data: myChats } = useGetAllMyChatsQuery();
  const [createChat, { isLoading: isCreatingChat }] = useCreateChatMutation();
  const dispatch = useDispatch();

  const { currentModal, toggleModal } = useModalManager();

  // mocked user-agent id
  // in the future mb take agentId from url e.g. http://localhost:3000/career-development/:agentId
  // or from location state
  const agentId = '7b78e3f2-1b57-4ef7-a637-5619cf95fb9f';
  const { data: agentData, isLoading: isAgentDataLoading } =
    useGetUserAgentInfoByIdQuery(agentId || '');
  // eslint-disable-next-line
  const { data: agentReviews, isLoading: isAgentReviewsLoading } =
    useGetAgentReviewsCountByAgentIdQuery(agentId || '');

  const openReviewModal = () => toggleModal(LiveAgentCardModals.REVIEW_MODAL);

  const openLeaveReviewModal = () =>
    toggleModal(LiveAgentCardModals.LEAVE_REVIEW_MODAL);

  const handleMessage = useCallback(
    (toUserId: string | undefined) => {
      if (!toUserId) {
        return;
      }

      return () => {
        dispatch(setChatState(ChatStateEnum.CHAT));

        if (myChats)
          for (const chat of myChats)
            if (chat.withUserId === toUserId) {
              dispatch(setSelectedChat(chat));
              return navigate('/chat');
            }

        createChat(toUserId)
          .unwrap()
          .then(chat => {
            dispatch(setSelectedChat(chat));
            navigate('/chat');
          })
          .catch(console.log);
      };
    },
    [myChats, createChat, dispatch, navigate]
  );

  return (
    <>
      <ReviewModal
        isOpen={currentModal === LiveAgentCardModals.REVIEW_MODAL}
        agentId={agentId}
        onClose={() => {
          toggleModal(LiveAgentCardModals.REVIEW_MODAL);
        }}
      />
      <LeaveReviewModal
        agentId={agentId}
        isOpen={currentModal === LiveAgentCardModals.LEAVE_REVIEW_MODAL}
        onClose={() => {
          toggleModal(LiveAgentCardModals.LEAVE_REVIEW_MODAL);
        }}
      />
      <Box
        className="main-content__live-agent-card"
        rowGap="28px"
        sx={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: { xs: '0 16px 16px', lg: '36px 16px 16px' },
          border: `solid 1px ${theme.palette.background.lightDark}`,
        }}
      >
        {isAgentDataLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={mobileView ? 'row' : 'column'}
              alignItems="center"
              rowGap="28px"
            >
              <Typography variant="badge2" textTransform="uppercase">
                your live agent
              </Typography>

              <Avatar
                alt="Profile"
                src={agentData?.avatar?.fileUrl || ''}
                sx={{
                  [theme.breakpoints.up('xs')]: { width: 100, height: 100 },
                  [theme.breakpoints.up('lg')]: { width: 140, height: 140 },
                  border: `2px solid ${theme.palette.background.white}`,
                }}
              />

              <Box
                className="profile-name-review-container"
                // m={mobileView ? '35px 0 0' : '28px 0'}
                display="flex"
                flexDirection="column"
                alignItems="center"
                rowGap="12px"
              >
                <Typography
                  variant="h3"
                  color={theme.palette.text.primary}
                  textAlign={{ xs: 'left', lg: 'center' }}
                >
                  {`${agentData?.firstName || ''} ${agentData?.lastName || ''}`}
                </Typography>
                <Rating
                  readOnly
                  value={agentData?.rating || 0}
                  sx={{ fontSize: 20 }}
                />
                <Link to="" onClick={openReviewModal}>
                  <Typography variant="body3">
                    {agentReviews?.data ?? 0} reviews
                  </Typography>
                </Link>
              </Box>
            </Box>

            <Box
              className="profile-actions"
              width="100%"
              display="flex"
              flexDirection="column"
              rowGap="12px"
            >
              <CustomButton
                variantType={ButtonTypeEnum.PRIMARY}
                fullWidth
                disabled={isCreatingChat}
                onClick={handleMessage(agentId)}
              >
                send message
              </CustomButton>
              <CustomButton
                variantType={ButtonTypeEnum.SECONDARY}
                fullWidth
                onClick={openLeaveReviewModal}
              >
                leave review
              </CustomButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default LiveAgentCard;

import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, SxProps, useMediaQuery } from '@mui/material';
import { Video } from '../../../../../interfaces/media.interface';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import IconRoundBackground from '../../../../../components/MUIComponents/IconRoundBackground';
import CloseIcon from '../../../../../components/Icons/CloseIcon';
import BaseModal from '../../../../../components/MUIComponents/BaseModal';
import VideoPlayer from '../../../../../components/VideoPlayer/VideoPlayer';

interface MediaPreviewModalProps {
  file: Video;
  isOpen?: boolean;
  onClose: () => void;
}

const PostVideoModal = ({
  file,
  onClose,
  isOpen = false,
}: MediaPreviewModalProps) => {
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('md'));
  const mediaRef = useRef<HTMLVideoElement | null>(null);
  const [currentMedia, setCurrentMedia] = useState<HTMLVideoElement | null>(
    null
  );
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
    arrowsOffset: 100,
  });

  useEffect(() => {
    const updateImageDimensions = () => {
      const vid = currentMedia;

      if (!vid) {
        return;
      }

      const containerWidth = Math.min(window.innerWidth * 0.7, 1300);
      const widthRatio = containerWidth / vid.videoWidth;
      const containerHeight = Math.min(window.innerHeight * 0.8, 1200);
      const heightRatio = containerHeight / vid.videoHeight;
      const width = vid.videoWidth * Math.min(widthRatio, heightRatio);
      const height = vid.videoHeight * Math.min(widthRatio, heightRatio);
      const arrowsOffset = (containerWidth + 200 - width) / 2;
      setImageDimensions({
        width,
        height,
        arrowsOffset,
      });
    };

    if (currentMedia) {
      currentMedia.onloadedmetadata = updateImageDimensions;
      updateImageDimensions();
    }

    window.addEventListener('resize', updateImageDimensions);

    return () => {
      if (currentMedia) {
        currentMedia.onloadedmetadata = null;
      }
      window.removeEventListener('resize', updateImageDimensions);
    };
  }, [file, currentMedia]);

  const handleModalClose = () => {
    if (mediaRef.current) {
      mediaRef.current.pause();
      mediaRef.current.currentTime = 0;
    }
    onClose();
  };

  const boxSx: SxProps = tabletAndBigger
    ? {
        p: 0,
        border: 'none',
        overflow: 'visible',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto !important',
        height: 'fit-content',
        borderRadius: 0,
        boxShadow: 'unset',
        bgcolor: 'transparent',
      }
    : { borderRadius: 0, overflow: 'auto' };

  const header = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      mb={3}
      sx={{
        position: { md: 'absolute' },
        zIndex: { md: 1000 },
        right: { md: 12 },
        top: { md: 12 },
      }}
    >
      <IconButton sx={{ p: 0 }} onClick={handleModalClose}>
        <IconRoundBackground
          width={50}
          height={50}
          backgroundColor={
            tabletAndBigger ? 'secondary.main' : 'secondary2.main'
          }
        >
          <CloseIcon sx={{ path: { md: { stroke: 'white' } } }} />
        </IconRoundBackground>
      </IconButton>
    </Box>
  );

  return (
    <BaseModal
      boxSx={{ ...boxSx }}
      disableEscapeButton
      disableContentMargin
      isOpen={isOpen}
      toggle={handleModalClose}
      disableDefaultFooter
      header={{ component: header }}
    >
      <VideoPlayer
        height={tabletAndBigger ? imageDimensions.height : '100%'}
        width={tabletAndBigger ? imageDimensions.width : '100%'}
        setRef={(node: HTMLVideoElement) => {
          mediaRef.current = node;
          setCurrentMedia(node);
        }}
        url={file.fileUrl}
      />
    </BaseModal>
  );
};

export default PostVideoModal;

import { SvgIcon, SvgIconProps } from '@mui/material';

const PlusIcon = ({ width, height, sx, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: width || 24, height: height || 24, fill: 'none', ...sx }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        d="M12 5V19"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M5 12H19"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default PlusIcon;

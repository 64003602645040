import {
  Avatar,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LikeIcon from '../../../../components/Icons/LikeIcon';
import RepostIcon from '../../../../components/Icons/RepostIcon';
import SendIcon from '../../../../components/Icons/SendIcon';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import QuiltedImageList from '../../../../components/MUIComponents/QuiltedImageList';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { ICommentResponse } from '../../../../interfaces/comment.interface';
import { IPost } from '../../../../interfaces/post.interface';
import { IUser } from '../../../../interfaces/user.interface';
import { formatDate } from '../../../../utils/date-time-formats.ts/formatDate';
import { removeHashtagWords } from '../../../../utils/helper/removeHashtagWords';
import { PostType } from '../posts/NewPublicationTrigger';
import { MyPostModals, PostButtons } from '../posts/ProfilePost';
import ProfilePostComments from '../posts/ProfilePostComments';
import RepostedPost from '../posts/RepostedPost';
import { selectAuthData } from '../../../../store/selectors/authSelector';

interface PostCommentsModalProps {
  selectedPostType: PostType;
  isOpen?: boolean;
  onClose: () => void;
  post: IPost;
  userData: IUser;
  isLiking: boolean;
  isLiked: boolean;
  likesCount: number;
  isDisliking: boolean;
  isCommenting: boolean;
  handleLike: (postId: string) => Promise<void>;
  handleDislike: (postId: string) => Promise<void>;
  handleComment: (postId: string) => Promise<void>;
  commentsCount: number;
  postCommentsPage: number;
  setPostCommentsPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedPostType: React.Dispatch<React.SetStateAction<PostType>>;
  toggleModal: (modalName: string) => void;
  commentMsg: string;
  setCommentMsg: React.Dispatch<React.SetStateAction<string>>;
  postCommentsData: ICommentResponse;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >;
}

const PostCommentsModal = ({
  selectedPostType,
  onClose,
  isOpen = false,
  post,
  userData,
  likesCount,
  commentsCount,
  isLiking,
  isLiked,
  isDisliking,
  isCommenting,
  handleLike,
  handleDislike,
  handleComment,
  setSelectedPostType,
  toggleModal,
  commentMsg,
  setCommentMsg,
  postCommentsPage,
  setPostCommentsPage,
  postCommentsData,
}: PostCommentsModalProps) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const { user: myData } = useSelector(selectAuthData);

  const filesArray = post.postFiles.map((file, idx) => {
    const fileObj = { id: file.id, img: file.fileUrl, title: file.fileName };
    if (idx === 4 || idx === 5 || idx === 11) return { ...fileObj, cols: 2 };
    if (idx === 8 || idx === 5) return { ...fileObj, rows: 2, cols: 2 };
    return fileObj;
  });
  return (
    <BaseModal isOpen={isOpen} toggle={onClose} disableDefaultFooter>
      <>
        <Container disableGutters>
          <Box component="header" mb={2} color="#142237">
            <Grid container columnSpacing={2}>
              <Grid item component={Link} to={`/${userData?.id}`}>
                <Avatar
                  alt="Profile"
                  src={
                    userData && userData?.optimizedUserAvatarBaseUrl
                      ? `data:image/jpeg;base64,${userData.optimizedUserAvatarBaseUrl}`
                      : ''
                  }
                  sx={{ width: 40, height: 40 }}
                />
              </Grid>
              <Grid
                item
                flex={1}
                component={Link}
                to={`/${userData?.id}`}
                color={'inherit'}
              >
                <Typography variant="body1">
                  {`${userData?.firstName || ''} ${userData?.lastName || ''}`}
                </Typography>
                <Typography variant="caption" color={'text.secondary'}>
                  {formatDate(new Date(post.createdAt))}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box component="article">
            <Box component="header" mb={2}>
              <Typography
                variant="body2"
                pt="16px"
                color="#142237"
                fontWeight={400}
                textAlign={'justify'}
              >
                {removeHashtagWords(post.content)}
              </Typography>
              {post.tags.length > 0 && (
                <Typography
                  variant="body2"
                  mt={2}
                  color="primary"
                  sx={{ fontWeight: 'bold' }}
                >
                  {post.tags.map(tag => `#${tag} `)}
                </Typography>
              )}
            </Box>
            {!post.repostedPostId && (
              <Box component="figure">
                <QuiltedImageList
                  itemData={filesArray}
                  imageListProps={{ sx: { maxHeight: 480, margin: '0 -22px' } }}
                />
                <Box component="figcaption" pt="16px" pb="16px">
                  <Grid container justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                      columnSpacing="6px"
                    >
                      <LikeIcon />
                      <Typography
                        variant="caption"
                        ml="6px"
                        color={'text.secondary'}
                      >
                        {likesCount} Likes
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        mr="12px"
                        variant="caption"
                        color={'text.secondary'}
                      >
                        {commentsCount} Comments
                      </Typography>
                      <Typography variant="caption" color={'text.secondary'}>
                        {post.repostsCount} Reposts
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            {post.repostedPostId && (
              <>
                <RepostedPost repostedPostId={post.repostedPostId} />
                <Box component="figcaption" pt="16px" pb="16px">
                  <Grid container justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                      columnSpacing="6px"
                    >
                      <LikeIcon />
                      <Typography
                        variant="caption"
                        ml="6px"
                        color={'text.secondary'}
                      >
                        {likesCount} Likes
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        mr="12px"
                        variant="caption"
                        color={'text.secondary'}
                      >
                        {commentsCount} Comments
                      </Typography>
                      <Typography variant="caption" color={'text.secondary'}>
                        {post.repostsCount} Reposts
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
          <Box component="footer">
            <Grid
              container
              justifyContent="space-between"
              sx={{ gridColumnGap: '12px', padding: '22px 0' }}
            >
              <Grid item flex={1}>
                <PostButtons
                  onClick={() => {
                    isLiked ? handleDislike(post.id) : handleLike(post.id);
                  }}
                  disabled={isLiking || isDisliking}
                >
                  <LikeIcon fillNone={!isLiked} /> Like
                </PostButtons>
              </Grid>
              <Grid item flex={1}>
                <PostButtons
                  onClick={() => {
                    setSelectedPostType(PostType.NONE);
                    toggleModal(MyPostModals.REPOST_MODAL);
                  }}
                >
                  <RepostIcon />
                  Repost
                </PostButtons>
              </Grid>
              <Grid item flex={1}>
                <PostButtons>
                  <SendIcon />
                  Send
                </PostButtons>
              </Grid>
            </Grid>
            <ProfilePostComments
              postCommentsPage={postCommentsPage}
              setPostCommentsPage={setPostCommentsPage}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarSeverity={setSnackbarSeverity}
              postCommentsData={postCommentsData}
            />
            <Divider sx={{ width: '100%', mt: '16px', mb: '16px' }} />
            <Grid container columnGap={2} mt={'22px'}>
              <Grid
                container
                columnGap={2}
                width={'100%'}
                ml={'auto'}
                alignItems={'center'}
              >
                <Grid item height="fit-content">
                  <Avatar
                    alt="Profile"
                    src={
                      myData && myData?.optimizedUserAvatarBaseUrl
                        ? `data:image/jpeg;base64,${myData.optimizedUserAvatarBaseUrl}`
                        : ''
                    }
                    sx={{ width: 40, height: 40 }}
                  />
                </Grid>
                <Grid item flex={1} component={'form'}>
                  <TextField
                    multiline={false}
                    style={{ padding: '0px 0px' }}
                    value={commentMsg}
                    onChange={e => {
                      setCommentMsg(e.target.value);
                    }}
                    id="input-with-icon-textfield"
                    fullWidth
                    sx={{
                      padding: 0,
                      '& .MuiInputBase-root': {
                        backgroundColor: 'background.light',
                        borderRadius: '4px',
                        padding: '0px',
                      },
                      '& .MuiInputBase-input': {
                        height: '2.5rem !important',
                        backgroundColor: 'background.light',
                        borderRadius: '4px',
                      },
                      '& .MuiSvgIcon-root': {
                        backgroundColor: 'unset',
                      },
                      '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiInputBase-inputAdornedEnd':
                        {
                          padding: '0px !important',
                        },
                    }}
                    placeholder="Comment..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            type="submit"
                            aria-label="send reply button"
                            sx={{
                              p: '0px',
                              width: 25,
                              minWidth: '25px',
                            }}
                            disabled={isCommenting}
                            onClick={e => {
                              e.preventDefault();
                              handleComment(post.id);
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <SnackbarCustom
            open={snackbarOpen}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </Container>
      </>
    </BaseModal>
  );
};

export default PostCommentsModal;

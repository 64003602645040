import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '../../../validation/auth-validation';
import { ILoginRequest } from '../../../interfaces/auth.interface';
import { useLoginMutation } from '../../../store/apiSlice/auth/authApi';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../enums/cookie-enum';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../MUIComponents/CustomButton';
import Input from '../../MUIComponents/Input';
import { errorHelper } from '../../../utils/helper/error-helper';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { setAuthData } from '../../../store/apiSlice/auth/authSlice';
import { useDispatch } from 'react-redux';

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login = () => {
  const { state } = useLocation();
  const { email } = state || '';
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarError, setSnackbarError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema),
  });

  const [login, { isLoading }] = useLoginMutation();

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      const loginData: ILoginRequest = {
        email: data.email,
        password: data.password,
      };
      const result = await login(loginData).unwrap();
      const userId = result.user.id;
      const email = result.user.email || '';
      const role = result.user.role || '';

      Cookies.set(CookieEnum.ACCESS_TOKEN, result.accessToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.REFRESH_TOKEN, result.refreshToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.USER, JSON.stringify({ id: userId, email }), {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set(CookieEnum.ROLE, role, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });

      dispatch(setAuthData({ user: result.user }));

      setSnackbarMessage('Login successful');
      setSnackbarError(false);
      navigate('/profile', { replace: true });
    } catch (error: any) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
      console.error('Login failed:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  return (
    <Box
      component={'form'}
      display="flex"
      flexDirection="column"
      width={'100%'}
      gap="3rem"
    >
      <Typography variant="h2">Log in</Typography>
      <Box display="flex" flexDirection="column" gap="1.375rem">
        <Input
          variant="outlined"
          caption="Email:"
          type="email"
          fullWidth
          defaultValue={email || ''}
          placeholder="Enter email address"
          register={register('email')}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
        />
        <Input
          variant="outlined"
          caption="Password:"
          type="password"
          fullWidth
          placeholder="Enter password"
          register={register('password')}
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ''}
        />
      </Box>

      <Box
        sx={theme => ({
          display: 'grid',
          gap: '16px',
          gridTemplateColumns: '1fr 1fr',
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr',
          },
        })}
      >
        <CustomButton
          type={'submit'}
          buttonWidth="100%"
          variant="contained"
          color="primary"
          disableElevation={true}
          fullWidth
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          Log in
        </CustomButton>
        <Box display="flex" justifyContent="center" alignItems="center">
          <CustomButton
            buttonWidth="100%"
            variantType={ButtonTypeEnum.TERTIARY}
            color="primary"
            fullWidth
            onClick={() => navigate('/forgot-password')}
            disabled={isLoading}
            sx={{ border: 'none' }}
          >
            Forgot password?
          </CustomButton>
        </Box>
      </Box>

      <SnackbarCustom
        open={!!snackbarMessage}
        onClose={handleCloseSnackbar}
        message={snackbarMessage || ''}
        severity={
          snackbarError
            ? SnackbarSeverityEnum.ERROR
            : SnackbarSeverityEnum.SUCCESS
        }
      />
    </Box>
  );
};

export default Login;

import { Box, Input, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChat,
  setSelectedChat,
} from '../../../../../store/apiSlice/chat/chatSlice';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { groupChatSchema } from '../../../../../validation/group-chat-validation';
import { IGroupChatFormTypes } from '../../NewGroupChat/ChatInfoForm';
import { default as CustomInput } from '../../../../../components/MUIComponents/Input';
import {
  IGroupChat,
  useUpdateGroupChatMutation,
} from '../../../../../store/apiSlice/chat/groupApi';
import IconWithBackground from '../../../../../components/MUIComponents/IconWithBackground';
import RefreshIcon from '../../../../../components/Icons/RefreshIcon';
import useSendMessage from '../../../../../hooks/chat/useSendMessage';
import { IMessageAttributes } from '../../Message/Message';

interface IEditProps {
  onClose: () => void;
}

const Edit = ({ onClose }: IEditProps) => {
  const selectedChat = useSelector(getSelectedChat)! as IGroupChat;
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [updateGroupChat, { isLoading }] = useUpdateGroupChatMutation();
  const dispatch = useDispatch();
  const sendMessage = useSendMessage();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, defaultValues, isDirty },
  } = useForm<IGroupChatFormTypes>({
    resolver: yupResolver(groupChatSchema),
    defaultValues: {
      name: selectedChat.name,
      description: selectedChat.description,
    },
    mode: 'onChange',
  });

  const onChangeSubmit: SubmitHandler<IGroupChatFormTypes> = async data => {
    try {
      const fd = new FormData();
      if (data.name && data.name !== defaultValues?.name)
        fd.append('name', data.name);
      if (data.description && data.description !== defaultValues?.description)
        fd.append('description', data.description);
      if (file) fd.append('thumbnail', file);

      const updatedChat = await updateGroupChat({
        chatId: selectedChat.id,
        formData: fd,
      }).unwrap();
      dispatch(setSelectedChat(updatedChat));

      if (fd.has('name')) {
        const sendMessageFd = new FormData();

        const attributes: IMessageAttributes = {
          isGroupNameChangedNotification: true,
        };

        sendMessageFd.append('attributes', JSON.stringify(attributes));
        sendMessageFd.append('from', selectedChat.userId);
        sendMessageFd.append('body', fd.get('name')?.toString() || '');

        sendMessage({ chatId: selectedChat.id, formData: sendMessageFd });
      }

      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = () => {
    if (fileInputRef.current && fileInputRef.current.files) {
      setFile(fileInputRef.current.files.item(0));
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'1.75rem'}
        width={'100%'}
      >
        <Typography textAlign={'center'} typography={'h2'}>
          Edit group chat
        </Typography>

        <Box
          width={'10rem'}
          height={'10rem'}
          alignSelf={'left'}
          position={'relative'}
          sx={{
            '&:not(&:hover) > #change-image-button': {
              visibility: 'hidden',
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Input
            inputRef={fileInputRef}
            type={'file'}
            inputProps={{
              display: 'none',
              accept: 'image/jpeg, image/png, image/jpg',
            }}
            onChange={handleFileChange}
          />
          <Box
            id={'change-image-button'}
            position={'absolute'}
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={handleOpenFileInput}
          >
            <IconWithBackground
              size="2.5rem"
              bgcolor={'text.primary'}
              icon={<RefreshIcon />}
            />
          </Box>
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src={
              file
                ? URL.createObjectURL(file)
                : selectedChat.thumb
                  ? `data:image/jpeg;base64, ${selectedChat.thumb}`
                  : ''
            }
            alt=""
          />
        </Box>

        <CustomInput
          register={register('name')}
          variant={'outlined'}
          caption={'Group name:'}
          defaultValue={defaultValues?.name}
          fullWidth
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ''}
        />
        <CustomInput
          multiline
          rows={5}
          inputProps={{
            style: { overflowY: 'scroll', scrollbarWidth: 'none' },
          }}
          register={register('description')}
          variant={'outlined'}
          caption={'Information:'}
          defaultValue={defaultValues?.description}
          placeholder="Write some information here"
        />
      </Box>
      <Box display={'flex'} gap={'1.375rem'}>
        <CustomButton
          buttonWidth={'210px'}
          variantType={ButtonTypeEnum.TERTIARY}
          sx={{ color: 'text.primary' }}
          onClick={onClose}
          disabled={isLoading}
        >
          cancel
        </CustomButton>
        <CustomButton
          buttonWidth={'210px'}
          onClick={handleSubmit(onChangeSubmit)}
          disabled={!isValid || !(isDirty || file) || isLoading}
        >
          save
        </CustomButton>
      </Box>
    </>
  );
};

export default Edit;

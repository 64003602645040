import { useState } from 'react';
import { List } from '@mui/material';
import { IFriend } from '../../../interfaces/friend.interface';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import BlockedUsersItem from './BlockedUsersItem';

interface BlockedUsersListProps {
  blockedUsers: IFriend[];
  setBlockedUsers: React.Dispatch<React.SetStateAction<IFriend[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const BlockedUsersList: React.FC<BlockedUsersListProps> = ({
  blockedUsers,
  setBlockedUsers,
  setPage,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleRemoveLocally = (friendUserId: string) => {
    setBlockedUsers(prev =>
      prev.filter(blockedFriend => blockedFriend.userId !== friendUserId)
    );
    setPage(1);
  };

  const handleUnblockSuccess = (friendUserId: string) => {
    handleRemoveLocally(friendUserId);
    setSnackbarOpen(true);
    setSnackbarMessage(`Successfully unblocked!`);
    setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
  };

  const handleUnblockError = (error: any) => {
    setSnackbarOpen(true);
    setSnackbarMessage(error);
    setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
  };

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: '12px', lg: '16px' },
          paddingTop: '20px',
        }}
      >
        {blockedUsers.map(blockedFriend => (
          <BlockedUsersItem
            key={blockedFriend.userId}
            blockedFriend={blockedFriend}
            onUnblockSuccess={handleUnblockSuccess}
            onUnblockError={handleUnblockError}
          />
        ))}
      </List>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default BlockedUsersList;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  ISportPaginationResponse,
  ISportResponse,
} from '../../interfaces/sport.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const sportApi = createApi({
  reducerPath: 'sportApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/sport`
  ),
  tagTypes: ['sport'],

  endpoints: builder => ({
    getSportById: builder.query<ISportResponse, string>({
      query: id => `/${id}`,
      providesTags: ['sport'],
    }),
    getSportByName: builder.query<ISportResponse, string>({
      query: name => `/name/${name}`,
      providesTags: ['sport'],
    }),
    getSports: builder.query<ISportResponse[], void>({
      query: () => '',
      providesTags: ['sport'],
    }),
    getPaginatedSports: builder.query<
      ISportPaginationResponse,
      {
        page: number;
        limit: number;
        search: string;
        sort: string;
      }
    >({
      query: ({ page = 1, limit = 10, search = '', sort = 'desc' }) =>
        `paginated/sports?page=${page}&limit=${limit}&search=${search}&sort=${sort}`,
      providesTags: ['sport'],
    }),
  }),
});

export const {
  useGetSportByIdQuery,
  useGetSportByNameQuery,
  useGetSportsQuery,
  useGetPaginatedSportsQuery,
} = sportApi;

export default sportApi;

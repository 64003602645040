import { useRef, useState, useEffect } from 'react';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';
import setCanvasPreview from '../../../utils/setCanvasPreview';
import { Stack } from '@mui/material';
import 'react-image-crop/dist/ReactCrop.css';
import useDebounce from '../../../hooks/useDebounce';

const ASPECT_RATIO = 2.3 / 1;

interface CropperProps {
  updateBackground: (dataUrl: string) => void;
  imgSrc: string;
}

const Cropper: React.FC<CropperProps> = ({ updateBackground, imgSrc }) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [crop, setCrop] = useState<Crop | undefined>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>();

  const debouncedCompletedCrop = useDebounce(completedCrop, 100);

  useEffect(() => {
    if (
      debouncedCompletedCrop?.width &&
      debouncedCompletedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      setCanvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        debouncedCompletedCrop
      );
      const dataUrl = previewCanvasRef.current.toDataURL('image/png');
      updateBackground(dataUrl);
    }
  }, [debouncedCompletedCrop, updateBackground]);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  return (
    <Stack>
      {imgSrc && (
        <Stack display="flex" flexDirection="column" alignItems="center">
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={c => setCompletedCrop(c)}
            aspect={ASPECT_RATIO}
            minWidth={275}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{
                maxHeight: '100vh',
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Stack>
      )}

      {crop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            marginTop: '16px',
          }}
        />
      )}
    </Stack>
  );
};

export default Cropper;

import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckMarkIcon from '../../../components/Icons/CheckMarkIcon';
import Button from '../../../components/MUIComponents/Button';
import { CompanyProductDefaultNameEnum } from '../../../enums/subscription-enum';
import SubscriptionInfoIcon from '../../../components/Icons/SubscriptionInfoIcon';
import { Link } from 'react-router-dom';
import ArrowLeftIcon from '../../../components/Icons/ArrowLeftIcon';
import ChevronUpIcon from '@mui/icons-material/ExpandLess';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';

interface SubscriptionPlanCardProps {
  sx?: SxProps;
  price: string;
  period: string;
  planName: string;
  isSelected?: boolean;
  nextPayment?: string;
  isMostPopular?: boolean;
  isFooterEnabled?: boolean;
  includedFeatures: string[];
  pricePlanAudience: string;
  isPricePlanAudienceEnabled?: boolean;
  onClick?: () => void;
  handleChoosePlan?: () => void;
  showInfoIcon?: boolean;
  maxCardWidth?: string;
  iconClick?: () => void;
  showBorder?: boolean;
  showBack?: boolean;
  asDropdown?: boolean;
}

const SubscriptionPlanCard = ({
  sx,
  price,
  period,
  planName,
  isSelected = false,
  includedFeatures,
  pricePlanAudience,
  nextPayment,
  isFooterEnabled = true,
  isMostPopular = false,
  isPricePlanAudienceEnabled = true,
  onClick,
  handleChoosePlan,
  showInfoIcon = false,
  maxCardWidth,
  iconClick,
  showBorder = true,
  showBack = false,
  asDropdown = false,
}: SubscriptionPlanCardProps) => {
  const [isContentShown, setIsContentShown] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const planNameHelper = () => {
    switch (planName) {
      case CompanyProductDefaultNameEnum.BASIC_COMPANY_PLAN:
        return 'Basic';
      case CompanyProductDefaultNameEnum.PRO_COMPANY_PLAN:
        return 'Pro';
      case CompanyProductDefaultNameEnum.ADVANCED_COMPANY_PLAN:
        return 'Advanced';
      default:
        return planName;
    }
  };

  return (
    <Box
      className="subscription-plan-card"
      maxWidth={
        mobileView ? 'auto' : maxCardWidth?.length ? maxCardWidth : '369px'
      }
      minWidth={
        mobileView ? '100%' : maxCardWidth?.length ? maxCardWidth : '369px'
      }
      border={
        showBorder
          ? !isSelected
            ? `1px solid ${theme.palette.background.dark}`
            : `1px solid ${theme.palette.background.white}`
          : 'none'
      }
      bgcolor={
        isSelected
          ? theme.palette.background.light
          : theme.palette.background.white
      }
      sx={sx}
      onClick={onClick}
    >
      <Grid
        container
        component="article"
        p="28px 16px"
        height="100%"
        sx={{ '& > .MuiGrid-root': { width: '100%' } }}
      >
        <Grid item component="header">
          {showBack && (
            <>
              <Link
                to={`/subscription-plans`}
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                <Typography
                  variant="h4"
                  display="flex"
                  alignItems="center"
                  color={'text.primary'}
                >
                  <ArrowLeftIcon />
                  &nbsp;&nbsp;Go back
                </Typography>
              </Link>
              <Divider sx={{ m: '28px 0' }} />
            </>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="caption"
              minHeight={22}
              display="inline-flex"
              alignItems="center"
              textTransform="uppercase"
              color={theme.palette.primary.main}
            >
              {planNameHelper()}
            </Typography>

            {isMostPopular && (
              <Box
                bgcolor="#28BA2E"
                borderRadius={6}
                display="flex"
                alignItems={asDropdown ? 'center' : 'flex-end'}
                justifyContent="center"
                p="6px 12px"
              >
                <Typography
                  variant="badge3"
                  color={theme.palette.background.white}
                  textTransform="uppercase"
                >
                  most popular
                </Typography>
              </Box>
            )}
          </Stack>
          <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'flex-end'}
              gap={'8px'}
              mt="16px"
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'flex-end'}
                gap={'4px'}
              >
                <Typography variant="h2" fontSize={'34px'}>
                  $
                </Typography>
                <Typography variant="h2" fontSize={'34px'}>
                  {Number(price).toLocaleString()}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'flex-end'}
                gap={'2px'}
              >
                <Typography variant="h3">{period}</Typography>
                {showInfoIcon && (
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <SubscriptionInfoIcon onClick={iconClick} />
                  </Box>
                )}
              </Box>
              {mobileView && asDropdown && (
                <Box marginLeft={'auto'}>
                  <IconButton
                    onClick={() => {
                      setIsContentShown(prevState => !prevState);
                    }}
                  >
                    {isContentShown ? (
                      <ChevronUpIcon sx={{ width: 40, height: 40 }} />
                    ) : (
                      <ChevronDownIcon sx={{ width: 40, height: 40 }} />
                    )}
                  </IconButton>
                </Box>
              )}
            </Box>
            {isPricePlanAudienceEnabled && (
              <Typography
                variant="body3"
                color={isSelected ? 'text.primary' : 'text.secondary'}
              >
                {pricePlanAudience}
              </Typography>
            )}
          </Box>
        </Grid>
        {(!mobileView || !asDropdown || isContentShown) && (
          <Grid
            item
            container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            rowGap="22px"
            mb="28px"
            component="section"
          >
            <Divider sx={{ m: '28px 0', width: '100%' }} />
            {includedFeatures.map((item, index) => (
              <Stack key={index} direction="row" alignItems="center">
                <CheckMarkIcon />
                <Typography
                  variant="body3"
                  textTransform="capitalize"
                  ml="6px"
                  color={theme.palette.text.primary}
                  sx={{
                    fontSize: 12,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            ))}
          </Grid>
        )}

        {nextPayment && (
          <Box
            sx={{
              width: '100%',
              '& .MuiTypography-root': {
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
              },
            }}
          >
            <Divider sx={{ m: '28px 0' }} />
            <Stack direction="row" gap="6px">
              <Typography variant="body3">Next payment:</Typography>
              <Typography variant="body3" color={theme.palette.text.primary}>
                {nextPayment}
              </Typography>
            </Stack>
          </Box>
        )}

        {isFooterEnabled && (
          <Grid item component="footer" mt="auto">
            <Button
              fullWidth
              height={'50px'}
              variant="contained"
              onClick={handleChoosePlan}
            >
              choose plan
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SubscriptionPlanCard;

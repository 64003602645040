import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { groupChatSchema } from '../../../../validation/group-chat-validation';
import { Box, Input, useMediaQuery } from '@mui/material';
import IconWithBackground from '../../../../components/MUIComponents/IconWithBackground';
import CameraIcon from '../../../../components/Icons/CameraIcon';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { default as CustomInput } from '../../../../components/MUIComponents/Input';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { ICreateGroupChat } from './NewGroupChat';
import {
  setChatsLoaded,
  setChatState,
} from '../../../../store/apiSlice/chat/chatSlice';
import { ChatStateEnum } from '../../../../enums/chat-enum';
import { useDispatch } from 'react-redux';

export interface IGroupChatFormTypes {
  name: string;
  description?: string;
  thumbnail?: File;
}

interface IChatInfoFormProps {
  formData: Partial<ICreateGroupChat>;
  setFormData: Dispatch<SetStateAction<Partial<ICreateGroupChat>>>;
  setStep: Dispatch<SetStateAction<number>>;
}

const ChatInfoForm = ({
  formData,
  setFormData,
  setStep,
}: IChatInfoFormProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [groupPhoto, setGroupPhoto] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IGroupChatFormTypes>({
    resolver: yupResolver(groupChatSchema),
    defaultValues: formData,
    mode: 'onChange',
  });

  const handleOpenFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const handleFileChange = () => {
    const file = fileInputRef.current?.files?.item(0);

    if (file) {
      setGroupPhoto(file);
      setValue('thumbnail', file);
    }
  };

  const handleSubmitForm: SubmitHandler<IGroupChatFormTypes> = data => {
    setFormData(prev => ({ ...prev, ...data }));
    setStep(prev => prev + 1);
  };

  function handleCancel() {
    dispatch(
      setChatState(mobileView ? ChatStateEnum.ALL_CHATS : ChatStateEnum.CHAT)
    );
    dispatch(setChatsLoaded());
  }

  return (
    <>
      <Box
        alignItems={'center'}
        justifyContent={'center'}
        display={'flex'}
        flexDirection={'column'}
        gap={'3rem'}
        flexGrow={1}
        p={'0 1rem'}
      >
        <Box
          onClick={handleOpenFileInput}
          sx={{
            '&:hover': { cursor: 'pointer' },
            borderRadius: '50%',
            width: '8.75rem',
            height: '8.75rem',
          }}
        >
          <Input
            inputRef={fileInputRef}
            type="file"
            inputProps={{ accept: 'image/png, image/jpeg, image/jpg' }}
            sx={{ display: 'none' }}
            onInput={handleFileChange}
          />
          {groupPhoto ? (
            <img
              style={{
                borderRadius: '50%',
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
              src={URL.createObjectURL(groupPhoto)}
              alt=""
            />
          ) : (
            <IconWithBackground
              bgcolor={'background.default'}
              size={'100%'}
              icon={
                <CameraIcon
                  stroke={theme.palette.text.secondary}
                  height={'2.5rem'}
                  width={'2.5rem'}
                />
              }
            />
          )}
        </Box>
        <CustomInput
          register={register('name')}
          autoComplete="off"
          variant="outlined"
          placeholder="Select name for group chat"
          caption="Chat title:"
          width={mobileView ? '100%' : '70%'}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ''}
        />
        <CustomInput
          register={register('description')}
          autoComplete="off"
          variant="outlined"
          placeholder="Enter description"
          caption="Description:"
          width={mobileView ? '100%' : '70%'}
          error={!!errors.description}
          helperText={errors.description ? errors.description.message : ''}
        />
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        p={'1rem'}
        borderTop={`1px solid ${theme.palette.background.dark}`}
      >
        <CustomButton
          type="button"
          variantType={ButtonTypeEnum.SECONDARY}
          buttonWidth={'220px'}
          onClick={() => handleCancel()}
        >
          Cancel
        </CustomButton>
        <CustomButton
          disabled={!isValid}
          onClick={handleSubmit(handleSubmitForm)}
          buttonWidth={'220px'}
        >
          Invite people
        </CustomButton>
      </Box>
    </>
  );
};

export default ChatInfoForm;

import React from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { ISport } from '../../../../../interfaces/sport.interface';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';

interface ICandidateSportFilterProps {
  sports: ISport[] | undefined;
  isLoading: boolean;
  isError: boolean;
  sportsFilterState: string;
  setSportsFilterState: (value: string) => void;
  label: string;
  error: FetchBaseQueryError | SerializedError | undefined;
  onShowMore: () => void;
  totalSportsCount: number;
}

const CandidateSportFilter = (props: ICandidateSportFilterProps) => {
  const {
    sports,
    isLoading,
    isError,
    sportsFilterState,
    setSportsFilterState,
    error,
    onShowMore,
    totalSportsCount,
  } = props;

  const handleSportChange = (sport: string) => {
    const formattedSport = sport.replace(/\s/g, '_');
    const updatedState = sportsFilterState.includes(formattedSport)
      ? sportsFilterState
          .split(',')
          .filter(s => s !== formattedSport)
          .join(',')
      : [...sportsFilterState.split(','), formattedSport].join(',');

    setSportsFilterState(updatedState);
  };

  const [isScoreExpanded, setIsScoreExpanded] = React.useState(false);

  const toggleExpand = () => {
    setIsScoreExpanded(prevState => !prevState);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          p: '22px 16px 11px 16px',
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography
        sx={{
          p: '22px 16px 11px 16px',
        }}
        color="error"
        variant="h4"
      >
        {errorHelper(error)}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        minWidth: 227,
        width: '100%',
        borderRadius: '1px',
        bgcolor: 'background.white',
        p: '22px 16px 11px 16px',
        gap: '22px',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          gap: '28px',
          padding: '0 2px',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          color={'text.primary'}
          textAlign={'left'}
          display={'flex'}
          alignItems={'center'}
        >
          Sports
        </Typography>
        <Box display="flex" gap="12px">
          <ArrowDownIcon
            sx={{
              transform: isScoreExpanded ? 'rotate(180deg)' : '',
            }}
            onClick={toggleExpand}
          />
        </Box>
      </Box>
      <Collapse in={isScoreExpanded} sx={{ marginTop: '10px' }}>
        <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
          <FormGroup>
            {sports?.map(sport => (
              <FormControlLabel
                key={sport.id}
                control={
                  <Checkbox
                    checked={sportsFilterState.includes(
                      sport.name.replace(/\s/g, '_')
                    )}
                    onChange={() => handleSportChange(sport.name)}
                  />
                }
                label={
                  <Typography
                    variant="h4"
                    component="h4"
                    color={'text.primary'}
                  >
                    {sport.name}
                  </Typography>
                }
              />
            ))}
          </FormGroup>
          {sports && sports.length < totalSportsCount && (
            <Typography
              onClick={onShowMore}
              variant="h4"
              component="h4"
              color={'text.accent'}
              sx={{ marginTop: '10px', cursor: 'pointer' }}
            >
              + Show more
            </Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CandidateSportFilter;

import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import SectionLayout from '../../../../../layouts/SectionLayout';
import JobPosts from './JobPosts';
import { IJobpost } from '../../../../../interfaces/jobpost.interface';
import { useGetMyJobpostsQuery } from '../../../../../store/apiSlice/jobpost/jobpostApi';
import { useSearchParams } from 'react-router-dom';

const sortMenuItems = [
  { label: 'Recently Added', value: 'desc' },
  { label: 'Oldest', value: 'asc' },
];

const LIMIT = 10;

const MyJobsPostsTab = () => {
  const [jobPostsArray, setJobPostsArray] = useState<{ jobpost: IJobpost }[]>(
    []
  );
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [totalPosts, setTotalPosts] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    const query = searchParams.get('query') ?? '';
    const page = parseInt(searchParams.get('page') || '1', 10);
    const sort = searchParams.get('sortBy') ?? 'desc';
    const industry = searchParams.get('industry') ?? '';
    const position = searchParams.get('position') ?? '';
    const location = searchParams.get('location') ?? '';
    const status = searchParams.get('status') ?? 'all';

    return {
      page,
      search: query,
      limit: LIMIT,
      sort,
      industries: industry,
      position,
      location,
      status,
    };
  }, [searchParams]);

  const {
    data: jobPostsData,
    error,
    isLoading,
  } = useGetMyJobpostsQuery(queryParams, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (jobPostsData && jobPostsData?.data) {
      if (queryParams.page === 1) setJobPostsArray(jobPostsData.data);
      else setJobPostsArray(prev => [...prev, ...jobPostsData.data]);

      setTotalPosts(jobPostsData.total);

      const hasNext =
        jobPostsData.limit * (jobPostsData.page - 1) + jobPostsData.limit <
        jobPostsData.total;

      setIsLoadMore(hasNext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPostsData]);

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('page', '1');
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.search, queryParams.sort]);

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <SectionLayout
        height={'100%'}
        sx={{
          bgcolor: 'background.white',
          p: { xs: '36px 16px', md: '24px', lg: '16px 36px 48px 36px' },
        }}
      >
        <JobPosts
          listToolBarSubtitle={
            totalPosts === 0
              ? 'No job posts found'
              : `${totalPosts} job post${totalPosts === 1 ? '' : 's'}`
          }
          sortMenuItems={sortMenuItems}
          jobPosts={jobPostsArray}
          jobPostTitleClickPath="job-post-apply"
          isLoadMore={isLoadMore}
          isLoading={isLoading}
          showStatus={true}
          showNewAppliedUser={true}
        />
      </SectionLayout>
    </Box>
  );
};

export default MyJobsPostsTab;

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import {
  Control,
  Controller,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import CustomButton from '../../../MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { IAnalyticReportValues } from '../../../../interfaces/analytic.interface';
import Input from '../../../MUIComponents/Input';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import DateInputISO from '../../../MUIComponents/DateInputISO';

interface ApplyJobModalProps {
  isOpen?: boolean;
  onClose: () => void;
  handleReportSubmit: (data: IAnalyticReportValues) => void;
  handleSubmit: UseFormHandleSubmit<IAnalyticReportValues>;
  control: Control<IAnalyticReportValues>;
  register: UseFormRegister<IAnalyticReportValues>;
  errors: {
    [key: string]: any;
  };
  loading: boolean;
  createReportLoading: boolean;
  isValid: boolean;
}

const ReportModal = ({
  onClose,
  isOpen = false,
  handleReportSubmit,
  handleSubmit,
  control,
  register,
  errors,
  loading,
  isValid,
  createReportLoading,
}: ApplyJobModalProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <BaseModal
      modalSx={{
        borderColor: 'none !important',
        marginTop: '0',
        boxShadow: 'none !important',
      }}
      isOpen={isOpen}
      toggle={onClose}
      disableEscapeButton={!mobileView}
      header={mobileView ? { text: 'Generate report' } : {}}
      footer={{
        component: (
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={'16px'}
            marginTop={'28px'}
          >
            <CustomButton
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={onClose}
              buttonWidth={'220px'}
              disabled={loading || createReportLoading}
            >
              {loading || createReportLoading ? 'Loading...' : 'Cancel'}
            </CustomButton>
            <CustomButton
              variantType={ButtonTypeEnum.PRIMARY}
              buttonWidth={'220px'}
              onClick={handleSubmit(handleReportSubmit)}
              disabled={!isValid || loading || createReportLoading}
            >
              {loading || createReportLoading
                ? 'Generating...'
                : 'Generate report'}
            </CustomButton>
          </Box>
        ),
      }}
    >
      <form onSubmit={handleSubmit(handleReportSubmit)}>
        {mobileView ? (
          <></>
        ) : (
          <Typography
            variant={'h2'}
            textAlign={'center'}
            paddingBottom={'48px'}
          >
            Generate report
          </Typography>
        )}

        <Box display={'flex'} flexDirection={'column'} gap={'28px'}>
          <Box
            display={'flex'}
            flexDirection={isMediumScreen ? 'column' : 'row'}
            gap={'16px'}
          >
            <Box width={isMediumScreen ? '100%' : '50%'}>
              <Input
                caption="Report name"
                variant="outlined"
                fullWidth
                placeholder="Write reports name"
                register={register('reportName')}
                error={!!errors.reportName}
                helperText={errors.reportName ? errors.reportName.message : ''}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={'16px'}
              width={isMediumScreen ? '100%' : '50%'}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                flexDirection={'row'}
                gap={'12px'}
                width={'100%'}
              >
                <Box display={'flex'}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DateInputISO
                        control={control}
                        label="Time range"
                        placeholder="Start Date"
                        error={errors.startDate}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ mt: '15px' }} display={'flex'}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DateInputISO
                        control={control}
                        label=""
                        placeholder="End Date"
                        error={errors.endDate}
                        {...field}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box display={'flex'} gap={'16px'} width={'100%'}>
                {errors.startDate && (
                  <Typography
                    variant="caption"
                    color="error"
                    width={isMediumScreen ? '100%' : '50%'}
                  >
                    {errors.startDate.message ? errors.startDate.message : ''}
                  </Typography>
                )}
                {errors.endDate && (
                  <Typography
                    variant="caption"
                    color="error"
                    width={isMediumScreen ? '100%' : '50%'}
                  >
                    {errors.endDate.message ? errors.endDate.message : ''}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'column'}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  padding={'0 4px'}
                  color={'text.primary'}
                >
                  Stats
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="numberOfCandidates"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={'caption'}
                          sx={{ fontWeight: 400, fontSize: '14px' }}
                        >
                          Number of candidates
                        </Typography>
                      }
                      sx={{
                        padding: 0,
                        margin: 0,
                        gap: '8px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Divider sx={{ margin: '22px 0' }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="numberOfAcceptedCandidates"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={'caption'}
                          sx={{ fontWeight: 400, fontSize: '14px' }}
                        >
                          Number of accepted candidates
                        </Typography>
                      }
                      sx={{
                        padding: 0,
                        margin: 0,
                        gap: '8px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="numberOfJobPosting"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={'caption'}
                          sx={{ fontWeight: 400, fontSize: '14px' }}
                        >
                          Number of job postings
                        </Typography>
                      }
                      sx={{
                        padding: 0,
                        margin: 0,
                        gap: '8px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="numberOfRejectedCandidates"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={'caption'}
                          sx={{ fontWeight: 400, fontSize: '14px' }}
                        >
                          Number of rejected candidates
                        </Typography>
                      }
                      sx={{
                        padding: 0,
                        margin: 0,
                        gap: '8px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>
    </BaseModal>
  );
};

export default ReportModal;

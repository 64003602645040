import { Avatar, Box, Typography } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { config } from '../../../../config/config';
import { ChatTypeEnum } from '../../../../enums/chat-enum';
import { MessageTypeEnum } from '../../../../enums/message-type-enum';
import { IMessage, IUser } from '../../../../store/apiSlice/chat/chatApi';
import {
  getChatType,
  getSelectedChat,
  getToken,
} from '../../../../store/apiSlice/chat/chatSlice';
import { formatTime } from '../../../../utils/helper/formatTime';
import { transformFileSize } from '../../../../utils/helper/transformFileSize';
import { isGroupChat } from '../ChatNavigation/Chat';
import { AcceptedFileTypeEnum } from '../WritingField/UploadFileMenu';
import GroupNameChangedNotification from './GroupNameChangedNotification';
import MessageActions from './MessageActions';
import MessageDocumentPreview from './MessageDocumentPreview';
import MessageLikes from './MessageLikes';
import MessagePhotoPreview from './MessagePhotoPreview';
import MessageVideoPreview from './MessageVideoPreview';
import RepostedPost from '../../../home/shared-components/posts/RepostedPost';

interface IMessageProps {
  type: MessageTypeEnum;
  message: IMessage;
}

interface ForwardFrom {
  user: Omit<IUser, 'thumbnail'>;
  message: IMessage;
}

export interface IMessageAttributes {
  withFile?: boolean;
  fileType?: AcceptedFileTypeEnum;
  isIntroduction?: boolean;
  filename?: string;
  forwardFrom?: ForwardFrom;
  replyTo?: IMessage;
  type?: string;
  postId?: string;
  likedBy?: string[];
  isGroupNameChangedNotification?: boolean;
}

const Message = ({ type, message }: IMessageProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const messageRef = useRef<HTMLDivElement>(null);

  const selectedChat = useSelector(getSelectedChat)!;
  const chatType = useSelector(getChatType);
  const { token } = useSelector(getToken);

  const attributes: IMessageAttributes = useMemo(
    () =>
      typeof message.attributes === 'string'
        ? JSON.parse(message.attributes as string)
        : message.attributes,
    [message]
  );

  const replyOrForward = useMemo(() => {
    if (!attributes.replyTo && !attributes.forwardFrom) {
      return null;
    }

    let message = {
      header: '',
      body: '',
    };

    if (attributes.replyTo) {
      message.header = `Replied to ${isGroupChat(selectedChat) ? selectedChat.members.find(member => member.id === attributes.replyTo?.from)?.firstName : selectedChat.withUser.firstName}`;
      message.body = attributes.replyTo.body;

      return message;
    }

    if (attributes.forwardFrom) {
      message.header = `Forwarded from ${attributes.forwardFrom.user.firstName} ${attributes.forwardFrom.user.lastName}`;
      message.body = attributes.forwardFrom.message.body;
    }

    return message;
  }, [selectedChat, attributes.replyTo, attributes.forwardFrom]);

  const mediaSrc = useMemo(() => {
    return token && attributes.withFile
      ? `${chatType === ChatTypeEnum.PERSONAL ? config.BASE_CHAT_MICROSERVICE_API_URL : config.BASE_GROUP_CHAT_MICROSERVICE_API_URL}/${selectedChat.id}/messages/${message.id}/media?token=${token}`
      : '';
  }, [token, message, selectedChat, chatType, attributes]);

  const { username, thumbnail } = useMemo(() => {
    const member = isGroupChat(selectedChat)
      ? selectedChat.members[selectedChat.memberIds.indexOf(message.from)]
      : selectedChat.withUser;

    return {
      username: `${member?.firstName} ${member?.lastName}`,
      thumbnail: member?.thumbnail
        ? member.thumbnail.startsWith('https')
          ? member.thumbnail
          : `data:image/png;base64, ${member?.thumbnail}`
        : '',
    };
  }, [message, selectedChat]);

  const {
    forwardedMsgMedia,
    forwardedMsgWithFile,
    forwardedMsgFileType,
    forwardedMsgFileName,
  } = useMemo(() => {
    let media = {
      forwardedMsgMedia: [],
      forwardedMsgWithFile: false,
      forwardedMsgFileType: null,
      forwardedMsgFileName: '',
    };
    if (!attributes.forwardFrom?.message.attributes) {
      return media;
    }
    const attr = JSON.parse(
      attributes.forwardFrom.message.attributes as string
    ) as IMessageAttributes;

    if (!attr.withFile) {
      return media;
    }

    return {
      forwardedMsgMedia: attributes.forwardFrom.message.media,
      forwardedMsgWithFile: !!attr.withFile,
      forwardedMsgFileType: attr.fileType ?? null,
      forwardedMsgFileName: attr.filename ?? '',
    };
  }, [
    attributes.forwardFrom?.message.media,
    attributes.forwardFrom?.message.attributes,
  ]);

  const forwardedMediaSrc = useMemo(() => {
    if (!attributes.forwardFrom?.message.id) {
      return '';
    }

    return token && forwardedMsgWithFile
      ? `${chatType === ChatTypeEnum.PERSONAL ? config.BASE_CHAT_MICROSERVICE_API_URL : config.BASE_GROUP_CHAT_MICROSERVICE_API_URL}/${selectedChat.id}/messages/${attributes.forwardFrom.message.id}/media?token=${token}`
      : '';
  }, [
    token,
    selectedChat,
    chatType,
    forwardedMsgWithFile,
    attributes.forwardFrom?.message.id,
  ]);

  return attributes.isGroupNameChangedNotification ? (
    <GroupNameChangedNotification name={message.body} />
  ) : (
    <Box
      maxWidth={'60%'}
      display={'flex'}
      alignSelf={type === MessageTypeEnum.OWN ? 'flex-end' : 'flex-start'}
      gap={'0.375rem'}
    >
      {chatType === ChatTypeEnum.GROUP && type === MessageTypeEnum.INCOMING && (
        <Box display={'flex'} alignItems={'flex-end'}>
          <Avatar src={thumbnail} sx={{ width: '2rem', height: '2rem' }} />
        </Box>
      )}
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'0.5rem'}
        alignSelf={'inherit'}
      >
        <Box
          borderRadius={
            type === MessageTypeEnum.INCOMING
              ? '0.75rem 0.75rem 0.75rem 0.25rem'
              : '0.75rem 0.75rem 0.25rem 0.75rem'
          }
          bgcolor={type === MessageTypeEnum.INCOMING ? '#F0F1F3' : '#142237'}
          p={'1rem'}
          gap={'1rem'}
          flexDirection={'column'}
          alignSelf={'inherit'}
          display={'flex'}
          onMouseOver={() => setMenuOpen(true)}
          onMouseLeave={() => setMenuOpen(false)}
          ref={messageRef}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={message.body || attributes.replyTo ? '0.75rem' : '0'}
            sx={{
              span: {
                color: type === MessageTypeEnum.INCOMING ? '#0D0810' : 'white',
                wordBreak: 'break-word',
              },
            }}
          >
            {replyOrForward && (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'0.375rem'}
                p={'0.375rem'}
                bgcolor={
                  type === MessageTypeEnum.OWN ? '#434E5F' : 'background.darker'
                }
                borderRadius={'1px'}
              >
                <Typography variant="body3" fontWeight={700}>
                  {replyOrForward.header}
                </Typography>
                <Typography
                  variant="body3"
                  fontWeight={400}
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {replyOrForward.body}
                </Typography>
                {forwardedMsgWithFile && token && (
                  <>
                    {forwardedMsgFileType === AcceptedFileTypeEnum.IMAGE && (
                      <MessagePhotoPreview
                        type={type}
                        src={forwardedMediaSrc}
                        filename={forwardedMsgFileName || 'File'}
                        filesize={transformFileSize(
                          forwardedMsgMedia[0].size || 0
                        )}
                      />
                    )}
                    {forwardedMsgFileType === AcceptedFileTypeEnum.VIDEO && (
                      <MessageVideoPreview
                        type={type}
                        src={forwardedMediaSrc}
                        filename={forwardedMsgFileName || 'File'}
                        filesize={transformFileSize(
                          forwardedMsgMedia[0].size || 0
                        )}
                      />
                    )}
                    {forwardedMsgFileType === AcceptedFileTypeEnum.DOCUMENT && (
                      <MessageDocumentPreview
                        type={type}
                        src={forwardedMediaSrc}
                        filename={forwardedMsgFileName || 'File'}
                        filesize={transformFileSize(
                          forwardedMsgMedia[0].size || 0
                        )}
                      />
                    )}
                  </>
                )}
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.75rem',
              }}
            >
              <Typography variant="body3">{message.body}</Typography>
              {attributes.type === 'post' && attributes.postId && (
                <RepostedPost repostedPostId={attributes.postId} />
              )}
            </Box>

            {attributes.withFile && token && (
              <>
                {attributes.fileType === AcceptedFileTypeEnum.IMAGE && (
                  <MessagePhotoPreview
                    type={type}
                    src={mediaSrc}
                    filename={attributes.filename || 'File'}
                    filesize={transformFileSize(message.media[0].size || 0)}
                  />
                )}
                {attributes.fileType === AcceptedFileTypeEnum.VIDEO && (
                  <MessageVideoPreview
                    type={type}
                    src={mediaSrc}
                    filename={attributes.filename || 'File'}
                    filesize={transformFileSize(message.media[0].size || 0)}
                  />
                )}
                {attributes.fileType === AcceptedFileTypeEnum.DOCUMENT && (
                  <MessageDocumentPreview
                    type={type}
                    src={mediaSrc}
                    filename={attributes.filename || 'File'}
                    filesize={transformFileSize(message.media[0].size || 0)}
                  />
                )}
              </>
            )}
          </Box>

          {/* {showProfile && (
        <Box
          borderRadius={'4px'}
          bgcolor={'white'}
          display={'flex'}
          flexDirection={'column'}
          gap={'1rem'}
          p={'1rem'}
        >
          <SenderInfo />
          <Box display={'flex'} gap={'0.75rem'}>
            <CustomButton
              variantType={ButtonTypeEnum.PRIMARY}
              sx={{
                height: '2.25rem',
              }}
              buttonWidth={'50%'}
            >
              connect
            </CustomButton>
            <CustomButton
              variantType={ButtonTypeEnum.TERTIARY}
              sx={{
                height: '2.25rem',
                color: '#142237',
                border: '1px solid #D1D6DD',
              }}
              buttonWidth={'50%'}
            >
              view profile
            </CustomButton>
          </Box>
        </Box>
      )} */}
        </Box>

        <Box display={'flex'} gap={'0.25rem'} alignSelf={'inherit'}>
          <Typography variant="body4">
            {type === MessageTypeEnum.OWN ? 'You' : username}
          </Typography>
          <Typography
            variant="body4"
            fontWeight={500}
            sx={{ color: 'text.secondary' }}
          >
            {formatTime(new Date(message.createdAt))}
          </Typography>
        </Box>
      </Box>

      <MessageActions
        open={menuOpen}
        setMenuOpen={setMenuOpen}
        messageType={type}
        messageRef={messageRef}
        message={message}
        attributes={attributes}
      />

      {messageRef.current &&
        !!attributes.likedBy &&
        attributes.likedBy.length > 0 && (
          <MessageLikes
            open
            message={message}
            attributes={attributes}
            messageType={type}
            messageRef={messageRef}
          />
        )}
    </Box>
  );
};

export default Message;

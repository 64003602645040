import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import { INotificationResponse } from '../../interfaces/notification.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_NOTIFICATION_MICROSERVICE_API_URL}/notification`
  ),
  tagTypes: ['notification'],

  endpoints: builder => ({
    getAllMyNotifications: builder.query<
      INotificationResponse,
      { page?: number; limit?: number; search?: string; sort?: string } | void
    >({
      query: (args = { page: 1, limit: 10, search: '', sort: 'desc' }) => {
        const { page = 1, limit = 10, search = '', sort = 'desc' } = args || {};
        return `/my-notifications?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}`;
      },
      providesTags: ['notification'],
    }),

    getMyUnreadNotificationsCount: builder.query<{ data: number }, void>({
      query: () => '/count-my-unread-notifications',
      providesTags: ['notification'],
    }),

    markMyNotificationAsRead: builder.mutation<void, string>({
      query: notificationId => ({
        url: `mark-my-notifications-as-read/${notificationId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['notification'],
    }),

    deleteMyNotification: builder.mutation<void, string>({
      query: notificationId => ({
        url: `delete-my-notification/${notificationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['notification'],
    }),
  }),
});

export const {
  useGetAllMyNotificationsQuery,
  useGetMyUnreadNotificationsCountQuery,
  useMarkMyNotificationAsReadMutation,
  useDeleteMyNotificationMutation,
} = notificationApi;

export default notificationApi;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MoveToDraftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 18, height: 18, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M10.5 2.25V6H14.25"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M3.75 15.75H14.25V5.79119L10.841 2.25H3.75V15.75Z"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default MoveToDraftIcon;

import { Box, Typography, useMediaQuery } from '@mui/material';
import SidebarLayout from '../../layouts/SidebarLayout';
import ConnectionsSidebar from './components/ConnectionsSidebar/ConnectionsSidebar';
import SectionLayout from '../../layouts/SectionLayout';
import ConnectionsTabs from './components/ConnectionsTabs/ConnectionsTabs';
import { useThemeContext } from '../../theme/ThemeContextProvider';

const ConnectionsPage = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <SidebarLayout
      sidebar={<ConnectionsSidebar />}
      flexDirection={matches ? 'row' : 'column'}
    >
      <Box display="flex" flexDirection="column" flexGrow="1">
        <SectionLayout
          sx={{
            bgcolor: 'background.white',
            p: { xs: '36px 9px', md: '24px', lg: '36px' },
          }}
        >
          <Typography
            component="h2"
            variant="h2"
            mb={'24px'}
            color={'text.primary'}
          >
            Connections
          </Typography>
          <ConnectionsTabs />
        </SectionLayout>
      </Box>
    </SidebarLayout>
  );
};

export default ConnectionsPage;

import { Box } from '@mui/material';
import AnalyticsChartSection from './AnalyticsChartSection';
import { useModalManager } from '../../../hooks/useModalManager';
import ReportModal from './modals/ReportModal';
import {
  IAnalyticFilterValues,
  IAnalyticReportValues,
} from '../../../interfaces/analytic.interface';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  filterSchema,
  reportSchema,
} from '../../../validation/analytic-validation';
import { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { useGetUserCompanyInfoQuery } from '../../../store/apiSlice/userApi';
import { useCreateReportMutation } from '../../../store/apiSlice/analitics/companyReportApi';
import { errorHelper } from '../../../utils/helper/error-helper';
import { IReportRequest } from '../../../interfaces/report.interface';
import { reportService } from '../../../service/report-service';
import AnalyticsCards from './AnalyticsCards';
import DemographicInsight from './DemographicInsight';
import Reports from './Reports';
import {
  endDateFormatted,
  startDateFormatted,
} from '../../../utils/date-time-formats.ts/formatDate';

const Analytics = () => {
  const { currentModal, toggleModal } = useModalManager();

  const [filters, setFilters] = useState<IAnalyticFilterValues | null>(null);
  const [loadingAnalyticFilterData, setLoadingAnalyticFilterData] =
    useState(false);
  const [reportGeneratingLoading, setReportGeneratingLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const { data: companyData, error: companyError } =
    useGetUserCompanyInfoQuery();

  const [createReport, { error, isError }] = useCreateReportMutation();

  const {
    control: controlReport,
    handleSubmit: handleReportSubmit,
    reset: resetReportForm,
    formState: { errors: reportErrors, isValid: isValidReport },
    register: registerReport,
    watch: watchReport,
  } = useForm<IAnalyticReportValues>({
    resolver: yupResolver(reportSchema),
    defaultValues: {
      reportName: '',
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      numberOfCandidates: false,
      numberOfAcceptedCandidates: false,
      numberOfJobPosting: false,
      numberOfRejectedCandidates: false,
    },
    mode: 'onChange',
  });

  const {
    control: analyticsControl,
    handleSubmit: handleAnalyticsFilterSubmit,
    reset: resetAnalyticForm,
    watch: watchAnalytics,
    formState: { errors: errorsAnalytic, isValid: isValidAnalytic },
    setValue: setAnalyticsValue,
  } = useForm<IAnalyticFilterValues>({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      numberOfCandidates: true,
      numberOfAcceptedCandidates: false,
      numberOfJobPosting: false,
      numberOfRejectedCandidates: false,
    },
    mode: 'onChange',
  });

  const startDate = watchReport('startDate');
  const endDate = watchReport('endDate');
  const numberOfCandidates = watchReport('numberOfCandidates');
  const numberOfAcceptedCandidates = watchReport('numberOfAcceptedCandidates');
  const numberOfJobPosting = watchReport('numberOfJobPosting');
  const numberOfRejectedCandidates = watchReport('numberOfRejectedCandidates');

  useEffect(() => {
    const reportValues = {
      startDate,
      endDate,
      numberOfCandidates,
      numberOfAcceptedCandidates,
      numberOfJobPosting,
      numberOfRejectedCandidates,
    };

    setAnalyticsValue('startDate', reportValues.startDate);
    setAnalyticsValue('endDate', reportValues.endDate);
    setAnalyticsValue('numberOfCandidates', reportValues.numberOfCandidates);
    setAnalyticsValue(
      'numberOfAcceptedCandidates',
      reportValues.numberOfAcceptedCandidates
    );
    setAnalyticsValue('numberOfJobPosting', reportValues.numberOfJobPosting);
    setAnalyticsValue(
      'numberOfRejectedCandidates',
      reportValues.numberOfRejectedCandidates
    );

    setFilters(prevFilters => ({
      ...prevFilters,
      startDate: reportValues.startDate,
      endDate: reportValues.endDate,
      numberOfCandidates: reportValues.numberOfCandidates,
      numberOfAcceptedCandidates: reportValues.numberOfAcceptedCandidates,
      numberOfJobPosting: reportValues.numberOfJobPosting,
      numberOfRejectedCandidates: reportValues.numberOfRejectedCandidates,
    }));
  }, [
    startDate,
    endDate,
    numberOfCandidates,
    numberOfAcceptedCandidates,
    numberOfJobPosting,
    numberOfRejectedCandidates,
  ]);

  const handleCancelAndClose = () => {
    resetReportForm();
    resetAnalyticForm();
    setFilters(null);
    toggleModal('');
  };

  const openReportModal = () => {
    resetReportForm();
    resetAnalyticForm();
    setFilters(null);
    toggleModal('GENERATE_REPORT_MODAL');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleGenerateReport = async (data: IAnalyticReportValues) => {
    setReportGeneratingLoading(true);
    try {
      const topElement = document.getElementById('pdf-top-statisctics');
      const graphsElement = document.getElementById('pdf-graphs');
      const bottomElement = document.getElementById('pdf-bottom-demographics');

      if (!topElement || !graphsElement || !bottomElement) {
        throw new Error('Failed to find required report elements');
      }

      const [topCanvas, graphsCanvas, bottomCanvas] = await Promise.all([
        html2canvas(topElement, { scale: 2, useCORS: true }),
        html2canvas(graphsElement, { scale: 2, useCORS: true }),
        html2canvas(bottomElement, { scale: 2, useCORS: true }),
      ]);

      const topImgData = topCanvas.toDataURL('image/png');
      const graphsImgData = graphsCanvas.toDataURL('image/png');
      const bottomImgData = bottomCanvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const margin = 10;
      const pageHeight = pdf.internal.pageSize.height;
      let currentY = 10;

      const topImgHeight = (topCanvas.height * imgWidth) / topCanvas.width;
      const graphsImgHeight =
        (graphsCanvas.height * imgWidth) / graphsCanvas.width;
      const bottomImgHeight =
        (bottomCanvas.height * imgWidth) / bottomCanvas.width;

      const addFooter = () => {
        const date = new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        pdf.setFontSize(10);
        pdf.text(`Generated on: ${date}`, imgWidth / 2, pageHeight - 10, {
          align: 'center',
        });
      };

      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);

      if (companyData?.companyInfo?.companyName) {
        pdf.text(companyData.companyInfo.companyName, margin, 15);
      }

      if (companyData?.optimizedUserAvatarBaseUrl) {
        const avatarSize = 12;
        pdf.addImage(
          companyData.optimizedUserAvatarBaseUrl,
          'PNG',
          imgWidth - margin - avatarSize,
          15 - avatarSize / 2,
          avatarSize,
          avatarSize,
          undefined,
          'FAST'
        );
      }

      currentY += 30;

      pdf.setFontSize(14);
      pdf.text('Report', imgWidth / 2, currentY, { align: 'center' });
      currentY += 15;

      pdf.setFontSize(10);
      pdf.text('Analytics', margin, currentY);
      currentY += 10;

      pdf.addImage(topImgData, 'PNG', 0, currentY, imgWidth, topImgHeight);
      currentY += topImgHeight + 10;

      if (currentY + graphsImgHeight > pageHeight - 30) {
        addFooter();
        pdf.addPage();
        currentY = 10;
      }

      pdf.text('Graphs', margin, currentY);
      currentY += 10;

      pdf.addImage(
        graphsImgData,
        'PNG',
        0,
        currentY,
        imgWidth,
        graphsImgHeight
      );
      currentY += graphsImgHeight + 10;

      const legendItems = [
        { color: '#1866DB', label: 'Number of candidates' },
        { color: '#F5431D', label: 'Number of rejected candidates' },
        { color: '#28BA2E', label: 'Number of accepted candidates' },
        { color: '#FFC620', label: 'Number of job postings' },
      ];

      const columnSpacing = 80;
      const rowSpacing = 6;
      let legendX = margin;
      pdf.setFontSize(10);

      legendItems.forEach((item, index) => {
        if (index % 2 === 0 && index !== 0) {
          currentY += rowSpacing;
          legendX = margin;
        }

        pdf.setFillColor(item.color);
        pdf.circle(legendX, currentY + 2, 1.5, 'F');
        pdf.text(item.label, legendX + 5, currentY + 3);
        legendX += columnSpacing;
      });

      currentY += rowSpacing * 2;
      addFooter();

      pdf.addPage();
      currentY = 10;

      pdf.text('Demographic Insight', margin, currentY);
      currentY += 10;

      pdf.addImage(
        bottomImgData,
        'PNG',
        0,
        currentY,
        imgWidth,
        bottomImgHeight
      );
      currentY += bottomImgHeight + 15;

      addFooter();

      const filterType = [
        numberOfCandidates ? 'Number of candidates' : '',
        numberOfAcceptedCandidates ? 'Number of accepted candidates' : '',
        numberOfRejectedCandidates ? 'Number of rejected candidates' : '',
        numberOfJobPosting ? 'Number of job postings' : '',
      ]
        .filter(Boolean)
        .join('/');

      const pdfBlob = pdf.output('blob');

      const reportData: IReportRequest = {
        reportName: data.reportName,
        type: filterType || 'analytics',
        periodStartDate: new Date(data.startDate).toISOString(),
        periodEndDate: new Date(data.endDate).toISOString(),
      };

      const response = await createReport(reportData).unwrap();

      const { oneTimeLink } = response.data;

      await reportService.setReportToS3OneTimeLink(
        oneTimeLink,
        pdfBlob,
        'application/pdf'
      );

      setSnackbarMessage('Report generated successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
      resetReportForm();
      pdf.save(`${data.reportName}.pdf`);
      setReportGeneratingLoading(false);
      toggleModal('');
    } catch (error) {
      setReportGeneratingLoading(false);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: {
          lg: 'calc(1440px - 32px)',
          xl: 'calc(1440px - 120px)',
        },
        marginX: {
          lg: 'auto',
        },
      }}
      display={'flex'}
      width={'100%'}
      height={'100%'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'22px'}
    >
      {currentModal === 'GENERATE_REPORT_MODAL' && (
        <ReportModal
          isOpen
          onClose={handleCancelAndClose}
          handleReportSubmit={handleGenerateReport}
          handleSubmit={handleReportSubmit}
          control={controlReport}
          register={registerReport}
          errors={reportErrors}
          loading={loadingAnalyticFilterData}
          createReportLoading={reportGeneratingLoading}
          isValid={isValidReport}
        />
      )}
      <AnalyticsCards />
      <AnalyticsChartSection
        handleSubmit={handleAnalyticsFilterSubmit}
        control={analyticsControl}
        reset={resetAnalyticForm}
        errors={errorsAnalytic}
        isValid={isValidAnalytic}
        watch={watchAnalytics}
        loading={loadingAnalyticFilterData}
        setLoading={setLoadingAnalyticFilterData}
        setFilters={setFilters}
        filters={filters}
      />

      <DemographicInsight />

      <Reports openReportModal={openReportModal} />

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default Analytics;

import React from 'react';
import StepForm from '../../Auth/VerifyAccount/StepForm';
import { Box, Typography } from '@mui/material';
import Input from '../../MUIComponents/Input';

interface CompanyLoginFormProps {
  registerLogin: any;
  loginErrors: any;
  loginFormSubmit: (data: any) => void;
  handleLoginSubmit: any;
  isLoginValid: boolean;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setIsShowRegistration: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  inviteEmail?: string | null;
}

const CompanyLoginForm: React.FC<CompanyLoginFormProps> = ({
  registerLogin,
  loginErrors,
  loginFormSubmit,
  handleLoginSubmit,
  isLoginValid,
  step,
  setStep,
  setIsShowRegistration,
  setIsShowLogin,
  inviteEmail,
}) => {
  return (
    <StepForm
      title="Login to company account"
      onNext={handleLoginSubmit(loginFormSubmit)}
      nextDisabled={!isLoginValid}
      step={step}
      setStep={setStep}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="center"
        gap="14px"
        sx={{ maxWidth: 392, width: '100%' }}
      >
        <Box display="flex" flexDirection="column" gap="36px">
          <Box display="flex">
            <Input
              containerFlex="1"
              caption="Email:"
              defaultValue={inviteEmail ?? ''}
              type="email"
              variant="outlined"
              fullWidth
              placeholder="Enter email"
              register={registerLogin('email')}
              error={!!loginErrors.email}
              helperText={loginErrors.email ? loginErrors.email.message : ''}
            />
          </Box>
          <Box display="flex">
            <Input
              caption="Password:"
              containerFlex="1"
              variant="outlined"
              type="password"
              fullWidth
              placeholder="Enter password"
              register={registerLogin('password')}
              error={!!loginErrors.password}
              helperText={
                loginErrors.password ? loginErrors.password.message : ''
              }
            />
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'right'} marginTop={'16px'}>
          <Box display={'flex'} flexDirection={'column-reverse'} gap={'8px'}>
            <Typography
              onClick={() => {
                setIsShowRegistration(true);
                setIsShowLogin(false);
              }}
              variant="body4"
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                transition: 'color 0.3s',
                ':hover': { color: 'button.primary' },
              }}
            >
              Don't have an account?
            </Typography>
          </Box>
        </Box>
      </Box>
    </StepForm>
  );
};

export default CompanyLoginForm;

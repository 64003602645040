import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowDownIcon = ({
  width = 20,
  height = 20,
  sx,
  ...rest
}: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      sx={{ width, height, fill: 'none', ...sx }}
      {...rest}
    >
      <path
        d="M4.5 6.75L9 11.25L13.5 6.75"
        stroke="#727F93"
        strokeWidth="1.2"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default ArrowDownIcon;

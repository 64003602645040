import { SvgIconProps, SvgIcon } from '@mui/material';

const ConfirmIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 20, height: 21, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      {...props}
    >
      <path
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M4.167 10.485l4.166 4.166 8.334-8.333"
      ></path>
    </SvgIcon>
  );
};

export default ConfirmIcon;

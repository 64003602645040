import VerifyAccount from '../../../components/Auth/VerifyAccount/VerifyAccount';
import Header from '../../../components/Header/Header';
import { HeaderTypeEnum } from '../../../enums/header-type';

const VerifyAccountPage = () => {
  return (
    <>
      <Header type={HeaderTypeEnum.VERIFY_ACCOUNT} />
      <VerifyAccount />
    </>
  );
};

export default VerifyAccountPage;

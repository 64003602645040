import React, { useMemo } from 'react';
import { FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userSchema } from '../../../../validation/user-validation';
import { Box, useMediaQuery } from '@mui/material';
import Input from '../../../MUIComponents/Input';
import { CookieEnum } from '../../../../enums/cookie-enum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import dayjs, { Dayjs } from 'dayjs';
import DateInput from '../../../MUIComponents/DateInput';
import StepForm from '../StepForm';
import Cookies from 'js-cookie';

interface RegistrationFormInputs {
  firstName: string;
  lastName: string;
  birthdate: Dayjs;
}

interface PersonalInformationFormProps {
  onSubmitUser: SubmitHandler<RegistrationFormInputs>;
  formData: Partial<RegistrationFormInputs>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
}

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
  onSubmitUser,
  formData,
  setStep,
  step,
}) => {
  const email = JSON.parse(Cookies.get(CookieEnum.USER) || '')?.email;

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  // user must be 16 years old
  const sixteenYearsAgo = useMemo(() => dayjs().subtract(16, 'year'), []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RegistrationFormInputs>({
    resolver: yupResolver(userSchema),
    defaultValues: formData,
    mode: 'onChange',
  });

  return (
    <StepForm
      title="Personal information"
      onNext={handleSubmit(onSubmitUser)}
      nextDisabled={!isValid}
      step={step}
      setStep={setStep}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitUser)}
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" flexDirection="column" gap="14px">
          <Box display="flex" flexDirection="column" gap="36px">
            <Box display="flex">
              <Input
                containerFlex={mobileView ? '0 1 100%' : '0 1 calc(50% - 8px)'}
                caption="Email:"
                type="email"
                variant="outlined"
                fullWidth
                defaultValue={email}
                disabled={true}
              />
            </Box>
            <Box
              sx={theme => ({
                display: 'flex',
                gap: '16px',
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  gap: '36px',
                },
              })}
            >
              <Input
                caption="First name:"
                containerFlex="1"
                variant="outlined"
                fullWidth
                placeholder="Enter your first name"
                register={register('firstName')}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ''}
              />
              <Input
                caption="Last name:"
                containerFlex="1"
                variant="outlined"
                fullWidth
                placeholder="Enter your last name"
                register={register('lastName')}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : ''}
              />
            </Box>
            <Box
              display="flex"
              sx={theme => ({
                width: `calc(50% - 8px)`,
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}
            >
              <DateInput<RegistrationFormInputs>
                control={control}
                label="Birthday:"
                name="birthdate"
                error={errors.birthdate as FieldError}
                helperText={errors.birthdate?.message}
                placeholder="DD.MM.YYYY"
                datePickerProps={{
                  maxDate: sixteenYearsAgo,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </StepForm>
  );
};

export default PersonalInformationForm;

import { createTheme, PaletteMode, Theme } from '@mui/material';
import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useColorTheme } from './useColorTheme';

interface IThemeContext {
  mode: PaletteMode;
  toggleMode: () => void;
  theme: Theme;
}

export const ThemeContext = createContext<IThemeContext>({
  mode: 'light',
  toggleMode: () => {},
  theme: createTheme(),
});

export const ThemeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useColorTheme();

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};

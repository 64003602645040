import { Box, useMediaQuery, useTheme } from '@mui/material';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ChatStateEnum, ChatTypeEnum } from '../../../../enums/chat-enum';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatType,
  getSelectedChat,
  getToken,
  resetForwardingFrom,
  resetPage,
  resetReplyingTo,
  resetToken,
  setChatState,
  setChatType,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import { useGetAllMyChatsQuery } from '../../../../store/apiSlice/chat/chatApi';
import { useGetAllMyGroupChatsQuery } from '../../../../store/apiSlice/chat/groupApi';
import useChatToken from '../../../../hooks/chat/useChatToken';
import { useCallback } from 'react';

const FilterButtons = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const chatType = useSelector(getChatType);
  const selectedChat = useSelector(getSelectedChat);
  const { token } = useSelector(getToken);

  const { data: myPersonalChats } = useGetAllMyChatsQuery();
  const { data: myGroupChats } = useGetAllMyGroupChatsQuery();
  const { deleteToken } = useChatToken();

  const handleSelectPersonalChats = useCallback(async () => {
    if (chatType !== ChatTypeEnum.PERSONAL) {
      if (token && selectedChat) {
        try {
          await deleteToken({
            chatId: selectedChat.id,
            userId: selectedChat.userId,
          }).unwrap();
          dispatch(resetToken());
        } catch (error) {
          console.log(error);
        }
      }

      if (myPersonalChats && myPersonalChats.length > 0) {
        dispatch(setSelectedChat(myPersonalChats[0]));
        !mobileView && dispatch(setChatState(ChatStateEnum.CHAT));
      } else {
        dispatch(setSelectedChat(undefined));
        !mobileView && dispatch(setChatState(ChatStateEnum.NEW_PERSONAL));
      }

      dispatch(resetToken());
      dispatch(resetReplyingTo());
      dispatch(resetForwardingFrom());
      dispatch(setChatType(ChatTypeEnum.PERSONAL));
    }
  }, [
    dispatch,
    deleteToken,
    chatType,
    myPersonalChats,
    selectedChat,
    token,
    mobileView,
  ]);

  const handleSelectGroupChats = useCallback(async () => {
    if (chatType !== ChatTypeEnum.GROUP) {
      if (token && selectedChat) {
        try {
          await deleteToken({
            chatId: selectedChat.id,
            userId: selectedChat.userId,
          }).unwrap();
          dispatch(resetToken());
        } catch (error) {
          console.log(error);
        }
      }

      if (myGroupChats && myGroupChats.length > 0) {
        dispatch(setSelectedChat(myGroupChats[0]));
        !mobileView && dispatch(setChatState(ChatStateEnum.CHAT));
      } else {
        dispatch(resetPage());
        dispatch(resetToken());
        dispatch(setSelectedChat(undefined));
        !mobileView && dispatch(setChatState(ChatStateEnum.NEW_GROUP));
      }

      dispatch(resetToken());
      dispatch(resetReplyingTo());
      dispatch(resetForwardingFrom());
      dispatch(setChatType(ChatTypeEnum.GROUP));
    }
  }, [
    dispatch,
    deleteToken,
    chatType,
    myGroupChats,
    selectedChat,
    token,
    mobileView,
  ]);

  return (
    <Box display={'flex'} p={'0 1rem'}>
      <CustomButton
        variantType={
          chatType === ChatTypeEnum.PERSONAL
            ? ButtonTypeEnum.PRIMARY
            : ButtonTypeEnum.SECONDARY
        }
        onClick={handleSelectPersonalChats}
        buttonWidth={'50%'}
        sx={{
          ...(chatType === ChatTypeEnum.GROUP
            ? {
                bgcolor: 'background.light',
                color: 'text.primary',
                border: 'none',
              }
            : {}),
        }}
      >
        personal
      </CustomButton>
      <CustomButton
        variantType={
          chatType === ChatTypeEnum.GROUP
            ? ButtonTypeEnum.PRIMARY
            : ButtonTypeEnum.SECONDARY
        }
        buttonWidth={'50%'}
        onClick={handleSelectGroupChats}
        sx={{
          ...(chatType === ChatTypeEnum.PERSONAL
            ? {
                bgcolor: 'background.light',
                color: 'text.primary',
                border: 'none',
              }
            : {}),
        }}
      >
        group chat
      </CustomButton>
    </Box>
  );
};

export default FilterButtons;

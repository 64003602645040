import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSendMessage from '../../../hooks/chat/useSendMessage';
import {
  ForwardingFrom,
  getForwardingFrom,
  getMessageSending,
  getReplyingTo,
  getSelectedChat,
  resetForwardingFrom,
  resetPage,
  resetReplyingTo,
} from '../../../store/apiSlice/chat/chatSlice';
import { getCurrentUserId } from '../../../utils/helper/getCurrentUserId';
import { IMessageAttributes } from './Message/Message';
import Messages from './Messages';
import WritingField from './WritingField/WritingField';

const ChatBox = () => {
  const [messageInput, setMessageInput] = useState<string>('');
  const selectedChat = useSelector(getSelectedChat)!;
  const isMessageSending = useSelector(getMessageSending);
  const replyingTo = useSelector(getReplyingTo);
  const forwardingFrom = useSelector(getForwardingFrom);
  const dispatch = useDispatch();

  const sendMessage = useSendMessage();

  const handleSendMessage = useCallback(() => {
    if ((messageInput.trim().length > 0 || forwardingFrom) && !isMessageSending)
      try {
        const getForwardFrom = (
          forwardingFrom: ForwardingFrom | undefined
        ): IMessageAttributes['forwardFrom'] | undefined => {
          if (!forwardingFrom) {
            return undefined;
          }
          const { thumbnail, ...rest } = forwardingFrom.user;
          return {
            user: rest,
            message: forwardingFrom.message,
          };
        };
        const fd = new FormData();
        const attributes: IMessageAttributes = {
          replyTo: replyingTo || undefined,
          forwardFrom: getForwardFrom(forwardingFrom),
        };

        fd.append('attributes', JSON.stringify(attributes));
        fd.append('from', getCurrentUserId());
        fd.append('body', messageInput.trim());

        setMessageInput('');

        sendMessage({
          chatId: selectedChat.id,
          formData: fd,
        })
          .unwrap()
          .then(() => {
            dispatch(resetPage());
            dispatch(resetReplyingTo());
            dispatch(resetForwardingFrom());
          });
      } catch (error) {
        console.log(error);
      }
  }, [
    dispatch,
    sendMessage,
    messageInput,
    isMessageSending,
    replyingTo,
    forwardingFrom,
  ]);

  return (
    <>
      <Messages />
      <WritingField
        setMessageInput={() => setMessageInput('')}
        messageInput={messageInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setMessageInput(e.target.value)
        }
        onSend={handleSendMessage}
      />
    </>
  );
};

export default ChatBox;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import {
  ICreatedReportResponse,
  IDownloadReportResponse,
  IReport,
  IReportRequest,
} from '../../../interfaces/report.interface';
import { IPaginationResponse } from '../../../interfaces/common.interface';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_COMPANY_MICROSERVICE_API_URL}/report`
  ),
  tagTypes: ['report'],

  endpoints: builder => ({
    getReportById: builder.query<IReport, string>({
      query: id => `/report/${id}`,
      providesTags: ['report'],
    }),
    getMyReports: builder.query<
      IPaginationResponse<IReport>,
      { page: string; limit: string; sort: string; filter: string }
    >({
      query: ({ page, limit, sort, filter }) => ({
        url: `/my/reports`,
        params: { page, limit, sort, filter },
      }),
      providesTags: ['report'],
    }),
    createReport: builder.mutation<ICreatedReportResponse, IReportRequest>({
      query: reportData => ({
        url: `/`,
        method: 'POST',
        body: reportData,
      }),
      invalidatesTags: ['report'],
    }),
    downloadReport: builder.query<IDownloadReportResponse, string>({
      query: reportId => ({
        url: `download/${reportId}`,
      }),
    }),
    deleteReport: builder.mutation<IReport, string>({
      query: id => ({
        url: `/report/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['report'],
    }),
  }),
});

export const {
  useGetReportByIdQuery,
  useGetMyReportsQuery,
  useCreateReportMutation,
  useDeleteReportMutation,
  useLazyDownloadReportQuery,
} = reportApi;

export default reportApi;

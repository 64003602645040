import React from 'react';

const MagnifyingGlassIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.42773 10.2857C3.42773 12.1044 4.15018 13.8485 5.43615 15.1345C6.72211 16.4204 8.46625 17.1429 10.2849 17.1429C12.1035 17.1429 13.8476 16.4204 15.1336 15.1345C16.4196 13.8485 17.142 12.1044 17.142 10.2857C17.142 8.4671 16.4196 6.72296 15.1336 5.437C13.8476 4.15104 12.1035 3.42859 10.2849 3.42859C8.46625 3.42859 6.72211 4.15104 5.43615 5.437C4.15018 6.72296 3.42773 8.4671 3.42773 10.2857Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M15.4277 15.4286L19.7134 19.7143"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default MagnifyingGlassIcon;

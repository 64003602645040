import { Box } from '@mui/material';
import Photos from '../../../../components/Media/Photos/Photos';

const PhotosTab = () => {
  return (
    <Box id="photos">
      <Photos />
    </Box>
  );
};

export default PhotosTab;

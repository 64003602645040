import { SvgIcon, SvgIconProps } from '@mui/material';

const PortfolioIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 32, height: 32, fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <g stroke="#434E5F" strokeWidth="1.5">
        <path
          strokeLinecap="round"
          d="M28.334 16V9.333h-24V16m24 0v10.667h-24V16m24 0h-24"
        ></path>
        <path strokeLinecap="square" d="M11 9.333V4h10.667v5.333"></path>
      </g>
    </SvgIcon>
  );
};

export default PortfolioIcon;

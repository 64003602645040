import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Input as MUIInput, SxProps, useMediaQuery } from '@mui/material';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { ElementIdEnum } from '../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { Photo, Video } from '../../../interfaces/media.interface';
import {
  useAddUserPhotoMutation,
  useAddUserVideoMutation,
  useCreatePhotoAlbumMutation,
  useCreateVideoAlbumMutation,
} from '../../../store/apiSlice/profileMediaApi';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { isSupportedIdConfirmationFileHelper } from '../../../utils/helper/file-validation-helper';
import { triggerFileInput } from '../../../utils/helper/triggerInputHelper';
import { mediaSchema } from '../../../validation/media-validation';
import UploadedFileIconElement from '../../Auth/Elements/UploadedFileIconElement';
import PhotosIcon from '../../Icons/PhotosIcon';
import VideosIcon from '../../Icons/VideosIcon';
import BaseModal from '../../MUIComponents/BaseModal';
import CustomButton from '../../MUIComponents/CustomButton';
import DragAndDropBox from '../../MUIComponents/DragAndDropBox/DragAndDropBox';
import Loader from '../../MUIComponents/Loader';
import PhotoGrid from '../Photos/components/photos-tab/PhotoGrid';
import Input from '../../MUIComponents/Input';

interface AddAlbumModalProps {
  isVideo?: boolean;
  isOpen?: boolean;
  onClose: () => void;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setSnackbarSeverity: Dispatch<SetStateAction<SnackbarSeverityEnum>>;
}

interface FileWithId {
  id: string;
  file: File;
}

const AddAlbumModal = ({
  isVideo = false,
  onClose,
  isOpen = false,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}: AddAlbumModalProps) => {
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('md'));
  // eslint-disable-next-line
  const [dragOver, setDragOver] = useState(false);
  const [media, setMedias] = useState<(Photo | Video)[]>([]);
  const [files, setFiles] = useState<FileWithId[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ albumName: string }>({
    resolver: yupResolver(mediaSchema),
    defaultValues: {
      albumName: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const [addUserPhoto, { isLoading: isUpdatingPhotos }] =
    useAddUserPhotoMutation();

  const [addUserVideo, { isLoading: isUpdatingVideos }] =
    useAddUserVideoMutation();

  const [createPhotoAlbum, { isLoading: isCreatingPhotoAlbum }] =
    useCreatePhotoAlbumMutation();

  const [createVideoAlbum, { isLoading: isCreatingVideoAlbum }] =
    useCreateVideoAlbumMutation();

  const { addUserMedia, isUpdatingMedia, createAlbum, isCreatingAlbum } =
    useMemo(() => {
      if (!isVideo) {
        return {
          addUserMedia: addUserPhoto,
          isUpdatingMedia: isUpdatingPhotos,
          createAlbum: createPhotoAlbum,
          isCreatingAlbum: isCreatingPhotoAlbum,
        };
      }

      return {
        addUserMedia: addUserVideo,
        isUpdatingMedia: isUpdatingVideos,
        createAlbum: createVideoAlbum,
        isCreatingAlbum: isCreatingVideoAlbum,
      };
    }, [
      isVideo,
      addUserPhoto,
      isUpdatingPhotos,
      createPhotoAlbum,
      isCreatingPhotoAlbum,
      addUserVideo,
      isUpdatingVideos,
      createVideoAlbum,
      isCreatingVideoAlbum,
    ]);

  const addFile = async (files: FileList) => {
    const newFiles = Array.from(files);
    const invalidFiles = newFiles.filter(
      file => !isSupportedIdConfirmationFileHelper(file)
    );

    if (invalidFiles.length > 0) {
      setSnackbarMessage('Invalid file type. Only images (JPEG, PNG, WebP)');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    const mapedFiles: Photo[] = [];
    const filesWithIds: FileWithId[] = [];

    newFiles.forEach(file => {
      const id = uuidv4();
      mapedFiles.push({
        id,
        fileUrl: URL.createObjectURL(file),
      });
      filesWithIds.push({
        id,
        file,
      });
    });

    setFiles(filesWithIds);
    setMedias(mapedFiles);
  };

  const onSubmit = async ({ albumName }: { albumName: string }) => {
    if (!files.length) {
      setSnackbarMessage('Add at least one file');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    try {
      const createAlbumFormData = new FormData();
      createAlbumFormData.append('name', albumName);
      createAlbumFormData.append('file', files[0].file);

      await createAlbum(createAlbumFormData).unwrap();

      setSnackbarMessage('All files uploaded successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(`Error uploading files: ${error}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      console.error('Error uploading files:', error);
    }
    onClose();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFile(event.dataTransfer.files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleFileRemove = (id: string) => {
    setFiles(prevFiles => prevFiles.filter(fileWithId => fileWithId.id !== id));
    setMedias(prevMedias => prevMedias.filter(media => media.id !== id));
  };

  const boxSx: SxProps = tabletAndBigger
    ? {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '1020px',
        maxHeight: '90vh',
        overflow: 'auto',
        bottom: 'unset',
        borderRadius: 0,
        border: 0,
        width: '90%',
        p: '36px',
      }
    : { borderRadius: 0, overflow: 'auto' };

  const mainContent = (
    <>
      {!media.length && (
        <DragAndDropBox
          handleDragOver={handleDragOver}
          handleDragLeave={handleDragLeave}
          handleDrop={handleDrop}
          handleFileChange={handleFileChange}
          triggerFileInput={() =>
            triggerFileInput(ElementIdEnum.ADD_ALBUM_BUTTON)
          }
          fileInputId={ElementIdEnum.ADD_ALBUM_BUTTON}
          text={`Drag and drop your cover image here. Only images (JPEG, PNG, WebP) are accepted.`}
          icon={
            <UploadedFileIconElement>
              {isVideo ? <VideosIcon active /> : <PhotosIcon active />}
            </UploadedFileIconElement>
          }
        />
      )}

      {!!media.length && (
        <PhotoGrid files={media} handleFileRemove={handleFileRemove} />
      )}
    </>
  );

  return (
    <BaseModal
      boxSx={{ ...boxSx }}
      disableEscapeButton
      disableContentMargin
      isOpen={isOpen}
      toggle={onClose}
      footer={{
        okText: 'publish',
        declineText: 'cancel',
        styles: {
          gap: 'unset',
        },
        declineButtonStyles: {
          flex: 'unset !important',
          borderColor: theme.palette.background.darker,
          minWidth: tabletAndBigger ? '220px' : 'unset',
        },
        okButtonStyles: {
          flex: 'unset !important',
          minWidth: tabletAndBigger ? '220px' : 'unset',
        },
      }}
      header={{ text: 'Create album' }}
      onAccept={handleSubmit(onSubmit)}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="48px"
        sx={{
          m: '48px 0 36px',
        }}
      >
        <Box
          display="flex"
          alignItems="flex-end"
          gap={3}
          flexDirection={tabletAndBigger ? 'row' : 'column'}
        >
          <Input
            caption="Album name:"
            variant="outlined"
            width={!tabletAndBigger ? '100%' : ''}
            containerFlex={1}
            placeholder="Album name"
            register={register('albumName')}
            error={!!errors.albumName}
            helperText={errors.albumName ? errors.albumName.message : ''}
          />
          <CustomButton
            sx={{
              height: 50,
              width: { xs: '100%', md: 124 },
            }}
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={() => triggerFileInput(ElementIdEnum.ADD_ALBUM_BUTTON)}
            disabled={isUpdatingMedia || isCreatingAlbum}
          >
            {'add cover image'}
          </CustomButton>
          <MUIInput
            id={ElementIdEnum.ADD_ALBUM_BUTTON}
            type="file"
            sx={{ display: 'none' }}
            inputProps={{
              multiple: true,
              accept: 'image/*',
            }}
            onChange={handleFileChange}
          />
        </Box>
        {isUpdatingMedia || isCreatingAlbum ? (
          <Loader color="primary" />
        ) : (
          mainContent
        )}
      </Box>
    </BaseModal>
  );
};

export default AddAlbumModal;

import {
  Stack,
  StackProps,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomButton from '../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import JobPostSidebarMoreMenu from './JobPostSidebarMoreMenu';
import { useNavigate } from 'react-router-dom';

interface IJobPostSidebarActionsProps {
  jobpostId: string;
  refetchJobpostData: () => void;
}

const JobPostSidebarActions = ({
  jobpostId,
  refetchJobpostData,
}: IJobPostSidebarActionsProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const navigate = useNavigate();
  return (
    <StyledStack
      direction={{ xs: 'row', lg: 'column' }}
      gap={'12px'}
      p={'21px 16px'}
    >
      <CustomButton
        variantType={ButtonTypeEnum.PRIMARY}
        variant="contained"
        sx={{ width: '100%' }}
        onClick={() =>
          console.log(`view applications jobpost:${jobpostId} click`)
        }
      >
        view applications
      </CustomButton>
      {!mobileView && (
        <Stack direction={'row'} gap={'12px'}>
          <CustomButton
            variant="outlined"
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={() => navigate(`/manage-job-post/${jobpostId}`)}
            sx={{ width: '100%' }}
          >
            edit job post
          </CustomButton>
          <JobPostSidebarMoreMenu
            jobpostId={jobpostId}
            refetchJobpostData={refetchJobpostData}
          />
        </Stack>
      )}
      {mobileView && (
        <JobPostSidebarMoreMenu
          jobpostId={jobpostId}
          refetchJobpostData={refetchJobpostData}
        />
      )}
    </StyledStack>
  );
};

export default JobPostSidebarActions;

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    width: '100%',
    bottom: '0',
    backgroundColor: theme.palette.background.white,
    zIndex: '1',
  },
}));

import Login from '../../../components/Auth/Login/Login';
import { Box, useMediaQuery } from '@mui/material';
import Logo from '../../../components/Logo/Logo';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const LoginPage = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box
      className="login-page-container"
      bgcolor={'background.white'}
      height={'100dvh'}
      display="flex"
      justifyContent="center"
    >
      <Box
        className="login-page-container__decorator"
        display="flex"
        flexDirection={'column'}
        maxWidth="1440px"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          pt={'1rem'}
          alignSelf={'center'}
          position={mobileView ? 'static' : 'absolute'}
          top={'0'}
          pb={'3rem'}
        >
          <Logo />
        </Box>
        <Box
          width="100%"
          bgcolor={'background.white'}
          sx={theme => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            gap: '3rem',

            [theme.breakpoints.down('lg')]: {
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            },
          })}
        >
          <Box
            sx={theme => ({
              pl: '10.75rem',
              pt: '1rem',
              [theme.breakpoints.down('lg')]: {
                p: '1rem',
                alignItems: 'center',
              },
            })}
            display={'flex'}
            flexDirection={'column'}
          >
            <Box
              sx={theme => ({
                marginY: 'auto',
                width: 'min(26.5625rem,100%)',
                [theme.breakpoints.only('md')]: {
                  width: '75%',
                },
              })}
            >
              <Login />
            </Box>
          </Box>
          <Box
            display={'flex'}
            height={mobileView ? '100%' : '100dvh'}
            bgcolor={'background.white'}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: mobileView ? 'bottom' : 'right',
              }}
              src={
                mobileView
                  ? '/images/mobile-login-page-img.png'
                  : '/images/login-page-img.png'
              }
              alt="Login page"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;

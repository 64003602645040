import {
  Avatar,
  Box,
  IconButton,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../../interfaces/user.interface';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import PersonalChatIcon from '../../../../components/Icons/PersonalChatIcon';

interface ConnectionAvatarWithTextProps {
  user: IUser;
}

const CandidateItem: React.FC<ConnectionAvatarWithTextProps> = ({ user }) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const onViewProfileButtonClick = () => {
    navigate(`/${user.id}`);
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignContent={'center'}
      gap={'16px'}
      maxWidth={'100%'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexDirection={'row'}
        gap={'16px'}
        maxWidth={'100%'}
      >
        <ListItemAvatar
          sx={{
            width: '50px',
            height: '50px',
            minWidth: '50px',
            minHeight: '50px',
          }}
        >
          <Avatar
            alt={`${user.firstName || ''}  ${user.lastName || ''}`}
            src={user?.avatar?.fileUrl || ''}
            sx={{ width: '100%', height: '100%' }}
          />
        </ListItemAvatar>
        <ListItemText
          key={user.id}
          sx={{
            color: 'text.primary',
            maxWidth: '350px',
          }}
          primary={
            user.firstName && user.lastName
              ? `${user.firstName || ''}  ${user.lastName || ''}`
              : user.email
          }
          primaryTypographyProps={{
            sx: {
              fontSize: {
                xs: '14px',
                md: '14px',
                lg: '18px',
              },
            },
          }}
          secondaryTypographyProps={{
            sx: {
              textAlign: 'left',
              lineHeight: '15px',
              fontSize: '14px',
            },
          }}
          secondary={
            user.sports &&
            user.sports.length > 0 &&
            user.sports.map((sport, idx, arr) => (
              <React.Fragment key={sport.id}>
                <span>{sport.name}</span>
                {idx < arr.length - 1 && ', '}
              </React.Fragment>
            ))
          }
        />
      </Box>

      {mobileView ? (
        <IconButton
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'background.dark',
            height: '40px',
            width: '40px',
            minHeight: '40px',
            minWidth: '40px',
            color: 'white',
          }}
          type="submit"
          onClick={onViewProfileButtonClick}
        >
          <PersonalChatIcon />
        </IconButton>
      ) : (
        <CustomButton
          onClick={onViewProfileButtonClick}
          buttonWidth={mobileView ? '100%' : '220px'}
          variantType={ButtonTypeEnum.SECONDARY}
          sx={{ borderColor: 'background.dark' }}
        >
          View Profile
        </CustomButton>
      )}
    </Box>
  );
};

export default CandidateItem;

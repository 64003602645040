import React from 'react';

function UploadIcon() {
  return (
    <svg
      width="76"
      height="74"
      viewBox="0 0 76 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 13.9998C15.5 13.4476 15.9477 12.9998 16.5 12.9998H74.5C75.0523 12.9998 75.5 13.4476 75.5 13.9998V71.9998C75.5 72.5521 75.0523 72.9998 74.5 72.9998H16.5C15.9477 72.9998 15.5 72.5521 15.5 71.9998V13.9998Z"
        fill="#DDE1E8"
      />
      <path
        d="M0.758819 16.4951C0.615877 15.9616 0.93246 15.4133 1.46593 15.2703L57.4896 0.258821C58.0231 0.115879 58.5714 0.432461 58.7144 0.965927L73.7259 56.9896C73.8688 57.5231 73.5522 58.0714 73.0188 58.2144L16.9951 73.2259C16.4616 73.3688 15.9133 73.0522 15.7703 72.5188L0.758819 16.4951Z"
        fill="#FEE3DD"
      />
      <g clipPath="url(#clip0_746_10028)">
        <path
          d="M36.6826 26.1534L38.1924 31.7879L43.827 30.2782"
          stroke="#F5431D"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path
          d="M31.9757 49.1554L47.7525 44.928L43.743 29.9644L37.1951 26.0161L26.5405 28.871L31.9757 49.1554Z"
          stroke="#F5431D"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path
          d="M36.8442 35.7726L38.656 42.5341"
          stroke="#F5431D"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path
          d="M34.6172 38.7301L36.6796 35.1579L40.2518 37.2203"
          stroke="#F5431D"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_746_10028">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(20 27) rotate(-15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UploadIcon;

import { Box, Typography } from '@mui/material';

interface StepIndicatorProps {
  value: number;
  width?: string | number;
  height?: string | number;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
}

const StepIndicator = ({
  width = 40,
  height = 40,
  value,
  bgColor = '#DAF0DB',
  textColor = 'unset',
  borderColor = 'unset',
}: StepIndicatorProps) => {
  return (
    <Box
      sx={{
        width,
        height,
        fontSize: '30px',
        color: textColor,
        borderRadius: '50%',
        backgroundColor: bgColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        border: `solid ${borderColor} 5px`,
      }}
    >
      <Typography variant="caption" color="inherit">
        {value < 10 ? '0' + value: value}
      </Typography>
    </Box>
  );
};

export default StepIndicator;

const EditChatIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 5.25H3L3 15L12.75 15L12.75 12.75"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M15.2888 4.93871C15.5841 4.64332 15.7501 4.2427 15.7501 3.82496C15.7501 3.40722 15.5841 3.00659 15.2888 2.71121C14.9934 2.41582 14.5927 2.24988 14.175 2.24988C13.7573 2.24988 13.3566 2.41582 13.0613 2.71121L6.75 8.99996V11.25H9L15.2888 4.93871Z"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12 3.75L14.25 6"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default EditChatIcon;

import React, { useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import CustomButton from '../MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../enums/button-type-enum';
import StepHeader from '../Auth/VerifyAccount/StepHeader';
import {
  AdditionalServicesEnum,
  CandidateExperiencePriorityEnum,
  NeededJobPerMonthEnum,
  OrganizationSizeEnum,
  PositionPriorityEnum,
} from '../../enums/questionnaires-enum';
import SnackbarCustom from '../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../enums/snackbar-severity-enum';
import QuestionnairesForm from './QuestionnairesForm';
import { useCreateOrUpdateCompanyStructureMutation } from '../../store/apiSlice/userCompanyStructure';
import { errorHelper } from '../../utils/helper/error-helper';
import { useNavigate } from 'react-router-dom';
import BaseModal from '../MUIComponents/BaseModal';
import { useModalManager } from '../../hooks/useModalManager';
import FlagIcon from '../Icons/FlagIcon';
import Button from '../MUIComponents/Button';

type AnswerKeys =
  | 'positionPriority'
  | 'neededJobPerMonth'
  | 'candidateExperiencePriority'
  | 'additionalServices'
  | 'organizationSize';

const Questionnaires = () => {
  const { currentModal, toggleModal } = useModalManager();
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const navigate = useNavigate();

  const [selectedAnswers, setSelectedAnswers] = useState<
    Record<AnswerKeys, any>
  >({
    positionPriority: null,
    neededJobPerMonth: null,
    candidateExperiencePriority: null,
    additionalServices: null,
    organizationSize: null,
  });

  const [createOrUpdateCompanyStructure, { isLoading }] =
    useCreateOrUpdateCompanyStructureMutation();

  const questions = [
    {
      key: 'positionPriority' as AnswerKeys,
      question: 'What type of position are you looking to fill?',
      answers: [
        PositionPriorityEnum.FULL_TIME,
        PositionPriorityEnum.PART_TIME,
        PositionPriorityEnum.INTERNSHIP,
        PositionPriorityEnum.SEASONAL,
      ],
    },
    {
      key: 'neededJobPerMonth' as AnswerKeys,
      question: 'How many job postings do you anticipate needing per month?',
      answers: [
        NeededJobPerMonthEnum.ONE_TO_FIVE,
        NeededJobPerMonthEnum.FIVE_TO_TEN,
        NeededJobPerMonthEnum.ELEVEN_TO_TWENTY,
        NeededJobPerMonthEnum.TWENTY_PLUS,
      ],
    },
    {
      key: 'candidateExperiencePriority' as AnswerKeys,
      question:
        'What level of experience are you primarily seeking in candidates?',
      answers: [
        CandidateExperiencePriorityEnum.ENTRY_LEVEL,
        CandidateExperiencePriorityEnum.MID_LEVEL,
        CandidateExperiencePriorityEnum.SENIOR_LEVEL,
        CandidateExperiencePriorityEnum.ALL_LEVELS,
      ],
    },
    {
      key: 'additionalServices' as AnswerKeys,
      question:
        'Do you require additional services such as candidate screening or premium job posting visibility?',
      answers: [
        AdditionalServicesEnum.YES_CANDIDATE_SCREENING,
        AdditionalServicesEnum.YES_PREMIUM_JOB_POSTING_VISIBILITY,
        AdditionalServicesEnum.YES_BOTH,
        AdditionalServicesEnum.NO_STANDARD_POSTING,
      ],
    },
    {
      key: 'organizationSize' as AnswerKeys,
      question: 'What is the size of your organization?',
      answers: [
        OrganizationSizeEnum.ONE_TO_TEN_EMPLOYEES,
        OrganizationSizeEnum.ELEVEN_TO_FIFTY_EMPLOYEES,
        OrganizationSizeEnum.FIFTY_ONE_TO_TWO_HUNDRED_EMPLOYEES,
        OrganizationSizeEnum.TWO_HUNDRED_PLUS_EMPLOYEES,
      ],
    },
  ];

  const handleNextButtonClick = async () => {
    const currentKey = questions[currentQuestion].key;

    if (!selectedAnswers[currentKey]) {
      setSnackbarMessage('Please select an option before proceeding.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (currentQuestion === questions.length - 1) {
      try {
        await createOrUpdateCompanyStructure(selectedAnswers).unwrap();
        setSnackbarMessage('Company structure saved successfully!');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
        setSnackbarOpen(true);

        setSelectedAnswers({
          positionPriority: null,
          neededJobPerMonth: null,
          candidateExperiencePriority: null,
          additionalServices: null,
          organizationSize: null,
        });
        setCurrentQuestion(0);
        toggleModal('WELCOME_MODAL');
      } catch (error) {
        console.error('Error:', error);
        setSnackbarMessage(errorHelper(error));
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
      }
      return;
    }

    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrevButtonClick = () => {
    if (currentQuestion === 0) {
      toggleModal('WELCOME_MODAL');
      return;
    }

    setCurrentQuestion(currentQuestion - 1);
  };

  const handleAnswerChange = (
    answer:
      | PositionPriorityEnum
      | NeededJobPerMonthEnum
      | CandidateExperiencePriorityEnum
      | AdditionalServicesEnum
      | OrganizationSizeEnum
  ) => {
    const currentKey = questions[currentQuestion].key;
    setSelectedAnswers(prevSelected => ({
      ...prevSelected,
      [currentKey]: answer,
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const title = useMemo(
    () => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box m="24px 0 36px">
          <FlagIcon />
        </Box>
        <Typography variant="h2">Welcome to Free Agent!</Typography>
      </Box>
    ),
    []
  );

  const footer = useMemo(
    () => (
      <Box display="flex" justifyContent="center" m="36px 0 36px">
        <Button
          onClick={() => navigate('/subscription-plans', { replace: true })}
          variant="contained"
          width={220}
          height={50}
        >
          explore the platform
        </Button>
      </Box>
    ),
    [navigate]
  );

  return (
    <>
      {currentModal === 'WELCOME_MODAL' && (
        <BaseModal
          boxSx={{ width: '875px', maxHeight: 756, overflowY: 'scroll' }}
          header={{
            component: title,
          }}
          isOpen={true}
          toggle={() => toggleModal('')}
          footer={{ component: footer }}
        >
          <Typography variant="body2" textAlign="center">
            Your verification form has been successfully submitted to the
            Network for Athletes.
            <br />
            We are thrilled to have you as part of our community. Our team will
            review your
            <br />
            submission, and you will be notified of the next steps shortly.
            <br />
            <br />
            Thank you for your participation and dedication.
          </Typography>
        </BaseModal>
      )}
      <Box
        display={'flex'}
        maxWidth={'875px'}
        width={'100%'}
        height={'756px'}
        margin={'0 auto'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        bgcolor={'background.white'}
        padding={'36px 36px 48px 36px'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          gap={'2.25rem'}
        >
          <StepHeader
            step={`company questionnaire`.toUpperCase()}
            title={questions[currentQuestion].question}
          />
        </Box>

        <QuestionnairesForm
          answers={questions[currentQuestion].answers}
          selectedAnswer={selectedAnswers[questions[currentQuestion].key]}
          onAnswerChange={handleAnswerChange}
        />

        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={'36px'}
        >
          <Divider
            sx={{ bgcolor: 'background.white', height: '1px', width: '100%' }}
          />
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={'16px'}
          >
            <CustomButton
              buttonWidth={'100%'}
              variantType={ButtonTypeEnum.SECONDARY}
              variant="outlined"
              fullWidth
              sx={{ p: '12px 16px', maxWidth: '220px' }}
              onClick={handlePrevButtonClick}
            >
              {currentQuestion === 0 ? 'Cancel' : 'Go Back'}
            </CustomButton>

            <Typography variant="body3">
              QUESTION 0{currentQuestion + 1} / 05{' '}
            </Typography>

            <CustomButton
              sx={{ p: '12px 16px', maxWidth: '220px' }}
              buttonWidth="100%"
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              onClick={handleNextButtonClick}
              fullWidth
              disabled={isLoading}
            >
              {currentQuestion === questions.length - 1
                ? 'Submit'
                : 'Next Question'}
            </CustomButton>
          </Box>
        </Box>

        <SnackbarCustom
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Box>
    </>
  );
};

export default Questionnaires;

import { useCallback } from 'react';
import useChatToken from './useChatToken';
import useGetMyChats from './useGetMyChats';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatsLoaded,
  getSelectedChat,
  resetPage,
  resetToken,
  setChatState,
  setSelectedChat,
} from '../../store/apiSlice/chat/chatSlice';
import {
  IGroupChat,
  useRemoveGroupChatMemberMutation,
} from '../../store/apiSlice/chat/groupApi';
import { getCurrentUserId } from '../../utils/helper/getCurrentUserId';
import { ChatStateEnum } from '../../enums/chat-enum';

const useLeaveFromChat = () => {
  const { deleteToken } = useChatToken();
  const { myChats } = useGetMyChats({});

  const selectedChat = useSelector(getSelectedChat) as IGroupChat | undefined;
  const chatsLoaded = useSelector(getChatsLoaded);
  const dispatch = useDispatch();

  const [removeUserFromGroupChat, { isLoading }] =
    useRemoveGroupChatMemberMutation();

  const handleLeaveFromChat = useCallback(async () => {
    if (selectedChat && myChats && myChats.length > 0 && chatsLoaded) {
      const currentChatId = selectedChat.id;
      const currentUserId = getCurrentUserId();

      await deleteToken({
        chatId: currentChatId,
        userId: currentUserId,
      }).unwrap();

      dispatch(resetPage());
      dispatch(resetToken());

      if (myChats.length === 1) {
        dispatch(setChatState(ChatStateEnum.NEW_GROUP));
        dispatch(setSelectedChat(undefined));
      } else if (myChats[0].id !== currentChatId) {
        dispatch(setSelectedChat(myChats[0]));
      } else {
        dispatch(setSelectedChat(myChats[1]));
      }

      await removeUserFromGroupChat({
        chatId: currentChatId,
        userId: currentUserId,
      }).unwrap();
    }
  }, [
    selectedChat,
    dispatch,
    myChats,
    chatsLoaded,
    deleteToken,
    removeUserFromGroupChat,
  ]);

  return { handleLeaveFromChat, isLeavingFromChat: isLoading };
};

export default useLeaveFromChat;

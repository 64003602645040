import { Box, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { ISportBackgroundFileData } from '../../../../../interfaces/user-sport-background.interface';
import AddGreyIcon from '../../../../Icons/AddGreyIcon';
import DeleteFileRedIcon from '../../../../Icons/DeleteFileRedIcon';
import Input from '../../../../MUIComponents/Input';
import SnackbarCustom from '../../../../MUIComponents/SnackbarCustom';

interface FileFormInputs {
  description: string;
  links: { value: string }[];
}

interface IProps {
  fileData: ISportBackgroundFileData;
  index: number;
  removeFileFromBackground: () => void;
  handleFileDataChange: (
    index: number,
    data: Partial<ISportBackgroundFileData>
  ) => void;
}

const SportBackgroundImageFileItem: React.FC<IProps> = ({
  fileData,
  index,
  removeFileFromBackground,
  handleFileDataChange,
}) => {
  const { file, description, links } = fileData;

  const formatFileSize = (size: number): string => {
    if (size < 1024) return `${size} bytes`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    if (size < 1024 * 1024 * 1024)
      return `${(size / 1024 / 1024).toFixed(2)} MB`;
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
  };

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm<FileFormInputs>({
    defaultValues: {
      description: description || '',
      links: links ? links.map(link => ({ value: link })) : [{ value: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'links',
  });

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    React.useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const handleAddLink = async () => {
    const values = getValues('links');
    const lastLink = values[values.length - 1].value;
    const isLastLinkValid = await trigger(`links.${values.length - 1}.value`);

    const isDuplicate = values.some(
      (link, index) => link.value === lastLink && index !== values.length - 1
    );

    if (lastLink.trim() === '' || !isLastLinkValid || isDuplicate) {
      const errorMessage = isDuplicate
        ? 'This link is already added. Please enter a unique link.'
        : 'Please fill in the link correctly.';
      setError(`links.${values.length - 1}.value`, {
        type: 'manual',
        message: errorMessage,
      });
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    } else {
      clearErrors(`links.${values.length - 1}.value`);
      append({ value: '' });
    }
  };

  const handleChangeLink = async (fieldIndex: number) => {
    const values = getValues('links');
    const currentLink = values[fieldIndex].value;

    const isDuplicate = values.some(
      (link, index) => link.value === currentLink && index !== fieldIndex
    );

    if (isDuplicate) {
      const errorMessage =
        'This link is already added. Please enter a unique link.';
      setError(`links.${fieldIndex}.value`, {
        type: 'manual',
        message: errorMessage,
      });
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    } else {
      clearErrors(`links.${fieldIndex}.value`);
    }
  };

  const handleRemoveLink = (fieldIndex: number) => {
    const values = getValues('links');
    remove(fieldIndex);
    handleFileDataChange(index, {
      links: values.filter((_, i) => i !== fieldIndex).map(link => link.value),
    });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'36px'}>
      <Box display={'flex'} gap={'16px'}>
        <Box
          display="flex"
          position="relative"
          flexDirection="column"
          borderRadius="1px"
          width={'64px'}
          minWidth={'64px'}
          height={'64px'}
          minHeight={'64px'}
          overflow="hidden"
        >
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          gap={'12px'}
          width={'100%'}
        >
          <Typography variant={'body3'} fontWeight={700} color={'text.primary'}>
            {file.name.length > 80 ? `${file.name.slice(0, 80)}...` : file.name}
          </Typography>
          <Typography variant={'body3'}>{formatFileSize(file.size)}</Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row-reverse'}
          gap={'12px'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width={'3.125rem'}
            height={'3.125rem'}
            bgcolor={'background.light'}
            borderRadius={'50%'}
          >
            <IconButton onClick={removeFileFromBackground} className="delete">
              <DeleteFileRedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Input
        caption="Description:"
        variant="outlined"
        fullWidth
        placeholder="Enter description"
        register={register('description', {
          onChange: e =>
            handleFileDataChange(index, { description: e.target.value }),
        })}
        error={!!errors.description}
        helperText={errors.description?.message || ''}
      />

      <Box display="flex" flexDirection="column" gap="12px">
        <Typography variant="body4" padding={'0 2px'}>
          Links:
        </Typography>
        {fields.map((field, fieldIndex) => (
          <Box key={field.id} display="flex" alignItems="flex-start" gap="8px">
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter link"
              {...register(`links.${fieldIndex}.value`, {
                onBlur: () => handleChangeLink(fieldIndex),
              })}
              onChange={e => {
                const linksCopy = [...fields];
                linksCopy[fieldIndex] = {
                  ...linksCopy[fieldIndex],
                  value: e.target.value,
                };
                handleFileDataChange(index, {
                  links: linksCopy.map(link => link.value),
                });
              }}
              error={!!errors.links?.[fieldIndex]?.value}
              helperText={errors.links?.[fieldIndex]?.value?.message || ''}
            />
            <Box
              width={'3.125rem'}
              height={'3.125rem'}
              bgcolor={'background.light'}
              borderRadius={'50%'}
            >
              <IconButton
                onClick={() => {
                  if (fieldIndex === fields.length - 1) {
                    handleAddLink();
                  } else {
                    handleRemoveLink(fieldIndex);
                  }
                }}
                className={fieldIndex === fields.length - 1 ? 'add' : 'delete'}
                disableRipple={fieldIndex === fields.length - 1}
              >
                {fieldIndex === fields.length - 1 ? (
                  <AddGreyIcon />
                ) : (
                  <DeleteFileRedIcon />
                )}
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
      <SnackbarCustom
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default SportBackgroundImageFileItem;

import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';

import { cloneElement, Fragment } from 'react';
import SecurityIcon from '../../Icons/SecurityIcon';
import BellIcon from '../../Icons/BellIcon';
import BlockUserIcon from '../../Icons/BlockUserIcon';
import DotIcon from '../../Icons/DotIcon';
import DotsHorizontalIcon from '../../Icons/DotsHorizontalIcon';
import SubscriptionIcon from '../../Icons/SubscriptionIcon';
import { SettingMenuItemType } from '../../../enums/setting-menu-type-enum';
import { useFindIsCompany } from '../../../hooks/useFindIsCompany';

const commonMenuItems = [
  {
    title: 'Log in & Security',
    icon: <SecurityIcon />,
    type: SettingMenuItemType.LOG_IN_AND_SECURITY,
  },
  {
    title: 'Notifications',
    icon: <BellIcon />,
    type: SettingMenuItemType.NOTIFICATIONS,
  },
  {
    title: 'Other',
    icon: <DotsHorizontalIcon />,
    type: SettingMenuItemType.OTHER,
  },
];

const companyMenuItems = [
  {
    title: 'Manage subscriptions',
    icon: <SubscriptionIcon />,
    type: SettingMenuItemType.MANAGE_SUBSCRIPTIONS,
  },
];

const userMenuItems = [
  {
    title: 'Blocked users',
    icon: <BlockUserIcon />,
    type: SettingMenuItemType.BLOCKED_USERS,
  },
];

const MobileAccountSettingsMenu = ({
  selectedTab,
  handleMenuItemClick,
}: {
  selectedTab: SettingMenuItemType;
  handleMenuItemClick: (selectedTab: SettingMenuItemType) => void;
}) => {
  const isCompany = useFindIsCompany();

  const menuItems = [
    ...commonMenuItems.slice(0, -1),
    ...(isCompany ? companyMenuItems : userMenuItems),
    commonMenuItems[commonMenuItems.length - 1],
  ];

  return (
    <Box
      component={'ul'}
      display={{ xs: 'grid', lg: 'none' }}
      gridTemplateRows={'repeat(1fr, 4)'}
      gap={'1px'}
    >
      {menuItems.map(({ title, icon, type }, index) => (
        <Fragment key={title}>
          <ListItem
            onClick={() => handleMenuItemClick(type)}
            sx={{ bgcolor: 'background.white', borderRadius: '1px', p: 0 }}
          >
            <ListItemButton sx={{ p: '12px' }}>
              <ListItemIcon
                sx={{
                  minWidth: 'unset',
                  mr: '12px',
                  alignItems: 'center',
                }}
              >
                {selectedTab === type && (
                  <DotIcon sx={{ width: 8, height: 8, mr: '12px' }} />
                )}
                {cloneElement(icon, {
                  active: selectedTab === type,
                })}
              </ListItemIcon>
              <Typography
                variant="body4"
                fontWeight={700}
                color={
                  selectedTab === type ? 'button.primary' : 'text.secondary'
                }
              >
                {title}
              </Typography>
            </ListItemButton>
          </ListItem>
        </Fragment>
      ))}
    </Box>
  );
};

export default MobileAccountSettingsMenu;

import * as yup from 'yup';
import { CanceledReasonEnum } from '../enums/cancel-reason-type-enum';
import { RejectReasonReasonEnum } from '../enums/reject-reason-type-enum';

export const CancelJobpostApplySchema = yup.object().shape({
  canceledNote: yup
    .string()
    .optional()
    .max(500, 'Note cannot exceed 500 characters'),
  canceledReason: yup
    .mixed<CanceledReasonEnum>()
    .oneOf(Object.values(CanceledReasonEnum), 'Invalid reason')
    .optional(),
});

export const RejectJobpostApplySchema = yup.object().shape({
  rejectedNote: yup
    .string()
    .optional()
    .max(500, 'Note cannot exceed 500 characters'),
  rejectedReason: yup
    .mixed<RejectReasonReasonEnum>()
    .oneOf(Object.values(RejectReasonReasonEnum), 'Invalid reason')
    .optional(),
});

import { Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { IUserFileDB } from '../../../interfaces/file.interface';
import { ISportBackgroundFormData } from '../../../interfaces/user-sport-background.interface';
import {
  IVerifyUserProfileFormData,
  IVerifyUserProfileRequest,
} from '../../../interfaces/user.interface';
import { useCreateCareerMutation } from '../../../store/apiSlice/careerApi';
import { useCreateUserSportBackgroundMutation } from '../../../store/apiSlice/sportBackgroundApi';
import { useVerifyUserProfileMutation } from '../../../store/apiSlice/userApi';
import { useUploadIdConfirmationFilesMutation } from '../../../store/apiSlice/userFilesApi';
import { errorHelper } from '../../../utils/helper/error-helper';
import { teamSchema } from '../../../validation/sport-validation';
import CustomStepper from '../../MUIComponents/CustomStepper';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import UploadDocument from '../../shared/UploadDocumentForm/UploadDocument';
import PersonalInformationForm from './PersonalInformationForm/PersonalInformationForm';
import SportVerification from './SportVerification/SportVerification';

interface RegistrationFormInputs {
  firstName: string;
  lastName: string;
  birthdate: Dayjs;
}

interface FileUploadFormInputs {
  documents: File[];
}

const VerifyAccount = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<Partial<IVerifyUserProfileRequest>>(
    {}
  );
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<IUserFileDB[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [verifyUserProfile, { isLoading, isError, error }] =
    useVerifyUserProfileMutation();

  const [
    uploadIdConfirmationFiles,
    {
      isLoading: idConfirmationFilesIsLoading,
      isError: idConfirmationFilesIsError,
      error: idConfirmationFilesError,
      data: idConfirmationFilesResponseData,
    },
  ] = useUploadIdConfirmationFilesMutation();

  const [createSportBackground] = useCreateUserSportBackgroundMutation();
  const [createCareer] = useCreateCareerMutation();

  const onSubmitUser: SubmitHandler<RegistrationFormInputs> = data => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };
  const onSubmitFiles: SubmitHandler<FileUploadFormInputs> = data => {
    const finalData = { ...formData, ...data };
    setFormData(finalData);
    setStep(step + 1);
  };

  const onSubmitSport: SubmitHandler<ISportBackgroundFormData> = async data => {
    const finalData = { ...formData, ...data } as IVerifyUserProfileFormData;

    // Validate teams form
    try {
      for (const sportBackground of finalData.sportBackgrounds) {
        for (const team of sportBackground.teams || []) {
          try {
            // Validate the form data against the schema
            await teamSchema.validate(team, { abortEarly: false });

            // If valid, proceed with submission
          } catch (validationErrors: any) {
            const errorMessages: any[] = validationErrors.inner.map(
              (error: any) => ({
                path: error.path, // The field with the error
                message: error.message, // The error message
              })
            );

            const errorToString = errorMessages.reduce<string>(
              (acc, cur) => acc + `${cur.message}\n`,
              ''
            );

            throw new Error(errorToString); // Throw the error to propagate it to the outer catch block
          }
        }
      }
      // Continue with the next code if all validations pass
    } catch (error) {
      // Handle the error from any team validation
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);

      return;
    }

    const sportIds = [] as string[];

    const uniqueSportIds = new Set();

    finalData.sportBackgrounds.forEach(sportBackground => {
      if (!uniqueSportIds.has(sportBackground.sportId)) {
        uniqueSportIds.add(sportBackground.sportId);
        if (sportBackground.sportId.length > 0) {
          sportIds.push(sportBackground.sportId);
        }
      }
    });

    const userVerificationData = {
      firstName: finalData.firstName,
      lastName: finalData.lastName,
      birthdate: finalData.birthdate?.toISOString(),
      sportIds: sportIds,
    };

    if (sportIds.length === 0) {
      setSnackbarMessage('Please select at least one sport.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    try {
      await verifyUserProfile(userVerificationData).unwrap();
      // TODO upload files will be changed at the future then will be create one time link
      if (files.length > 0) {
        const formData = new FormData();
        files.forEach(file => {
          formData.append('documents', file);
        });
        await uploadIdConfirmationFiles(formData).unwrap();
      }
      await Promise.all(
        finalData.sportBackgrounds.map(async sportBackground => {
          const sportBackgroundData = new FormData();
          sportBackgroundData.append('sportId', sportBackground.sportId);
          if (sportBackground.description) {
            sportBackgroundData.append(
              'description',
              sportBackground.description
            );
          }
          if (sportBackground.startCareerDate) {
            sportBackgroundData.append(
              'startCareerDate',
              new Date(sportBackground.startCareerDate).toISOString()
            );
          }
          if (sportBackground.endCareerDate) {
            sportBackgroundData.append(
              'endCareerDate',
              new Date(sportBackground.endCareerDate).toISOString()
            );
          }
          if (sportBackground.fileData && sportBackground.fileData.length > 0) {
            sportBackground.fileData.forEach((fileData, index) => {
              sportBackgroundData.append('documents', fileData.file);
              if (fileData.description) {
                sportBackgroundData.append(
                  `documents[${index}].description`,
                  fileData.description
                );
              }
              if (fileData.links && fileData.links.length > 0) {
                fileData.links.forEach(link => {
                  sportBackgroundData.append(
                    `documents[${index}].links[]`,
                    link
                  );
                });
              }
            });
          }
          const createdSporgBg =
            await createSportBackground(sportBackgroundData).unwrap();

          if (sportBackground.teams) {
            await Promise.all(
              sportBackground.teams.map(async team => {
                return createCareer({
                  sportId: sportBackground.sportId,
                  userSportBackgroundId: createdSporgBg.id,
                  idLeague: team.leagueId,
                  idTeam: team.teamId,
                  startDate: new Date(team.startDateInTeam).toISOString(),
                  endDate: new Date(team.endDateInTeam).toISOString(),
                });
              })
            );
          }
        })
      )
        .then(() => {
          setSnackbarMessage('Profile verification successful.');
          setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
          navigate('/welcome-to-platform', { replace: true });
        })
        .catch(error => {
          const errorMessage = errorHelper(error);
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
          setSnackbarOpen(true);
        });
      setSnackbarMessage('Profile verification successful.');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error: any) {
      const errorMessage = errorHelper(error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const steps = ['Personal Information', 'ID Confirmation', 'Sport Background'];

  return (
    <Box
      display={'flex'}
      maxWidth={'875px'}
      margin={'0 auto'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      marginBottom={'200px'}
    >
      <CustomStepper steps={steps} step={step} />

      {step === 1 && (
        <PersonalInformationForm
          onSubmitUser={onSubmitUser}
          formData={formData as RegistrationFormInputs}
          setStep={setStep}
          step={step}
        />
      )}
      {step === 2 && (
        <UploadDocument
          title={'ID Confirmation'}
          contentText={
            <>
              To complete your registration, please upload a valid ID document
              or
              <br />
              driver’s license for verification.
              <br />
              This ensures the authenticity of your profile.
            </>
          }
          dragAndDropContentText={
            <>
              Drag and drop your ID document or driver’s license here to
              complete your registration.
              <br />
              Ensure the document is clear and legible for verification
              purposes.
              <br />
              You can upload up to 5 files. Only images (JPEG, PNG, JPG, WebP)
              are accepted.
            </>
          }
          onSubmitFiles={onSubmitFiles}
          formData={formData as FileUploadFormInputs}
          setStep={setStep}
          step={step}
          files={files}
          setFiles={setFiles}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          isLoading={idConfirmationFilesIsLoading}
          isError={idConfirmationFilesIsError}
          error={idConfirmationFilesError}
          responseData={idConfirmationFilesResponseData}
        />
      )}
      {step === 3 && (
        <SportVerification
          onSubmitSport={onSubmitSport}
          formData={formData as ISportBackgroundFormData}
          setStep={setStep}
          step={step}
          isLoading={isLoading}
        />
      )}

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default VerifyAccount;

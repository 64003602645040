import { Avatar, Box, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import { IFriend } from '../../../../../interfaces/friend.interface';
import { Link } from 'react-router-dom';

interface ConnectionAvatarWithTextProps {
  connection: IFriend;
}

const ConnectionAvatarWithText: React.FC<ConnectionAvatarWithTextProps> = ({
  connection,
}) => {
  return (
    <Box
      component={Link}
      to={`/${connection.userId}`}
      display={'flex'}
      alignItems={'center'}
      flexDirection={'row'}
      gap={'16px'}
      maxWidth={'100%'}
    >
      <ListItemAvatar
        sx={{
          width: '50px',
          height: '50px',
          minWidth: '50px',
          minHeight: '50px',
        }}
      >
        <Avatar
          alt={`${connection.firstName || ''}  ${connection.lastName || ''}`}
          src={connection.avatarUrl || ''}
          sx={{ width: '100%', height: '100%' }}
        />
      </ListItemAvatar>
      <ListItemText
        key={connection.userId}
        sx={{
          color: 'text.primary',
          maxWidth: '350px',
        }}
        primary={`${connection.firstName || ''}  ${connection.lastName || ''}`}
        primaryTypographyProps={{
          sx: {
            fontSize: {
              xs: '14px',
              md: '14px',
              lg: '18px',
            },
          },
        }}
        secondaryTypographyProps={{
          sx: {
            textAlign: 'left',
            lineHeight: '15px',
            fontSize: '14px',
          },
        }}
        secondary={
          connection.sports &&
          connection.sports.length > 0 &&
          connection.sports.map((sport, idx, arr) => (
            <React.Fragment key={sport.id}>
              <span>{sport.name}</span>
              {idx < arr.length - 1 && ', '}
            </React.Fragment>
          ))
        }
      />
    </Box>
  );
};

export default ConnectionAvatarWithText;

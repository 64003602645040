import { SvgIcon, SvgIconProps } from '@mui/material';

const LikeNotificationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      sx={{ width: 14, height: 14, fill: 'none' }}
      {...props}
    >
      <path
        d="M11.3748 7.33365L6.99977 11.6666L2.62477 7.33365C2.3362 7.05284 2.10889 6.71532 1.95717 6.34235C1.80545 5.96938 1.7326 5.56904 1.7432 5.16653C1.7538 4.76402 1.84763 4.36806 2.01878 4.00359C2.18992 3.63913 2.43468 3.31405 2.73764 3.04882C3.04059 2.78359 3.39518 2.58397 3.77908 2.46251C4.16297 2.34106 4.56786 2.30041 4.96823 2.34312C5.36861 2.38583 5.75581 2.51097 6.10545 2.71068C6.45508 2.91038 6.75958 3.18031 6.99977 3.50348C7.241 3.18266 7.54585 2.91508 7.89524 2.7175C8.24464 2.51992 8.63106 2.39658 9.03031 2.3552C9.42957 2.31383 9.83307 2.3553 10.2156 2.47704C10.598 2.59878 10.9513 2.79816 11.2532 3.06269C11.5551 3.32723 11.7991 3.65123 11.97 4.01442C12.1409 4.37761 12.235 4.77216 12.2464 5.17339C12.2579 5.57463 12.1863 5.97389 12.0363 6.34621C11.8864 6.71853 11.6611 7.05588 11.3748 7.33715"
        stroke="white"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default LikeNotificationIcon;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import { IUserFilesResponse } from '../../interfaces/user-files.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const userFilesApi = createApi({
  reducerPath: 'userFilesApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/user-files`
  ),
  tagTypes: ['userFiles'],

  endpoints: builder => ({
    uploadIdConfirmationFiles: builder.mutation<IUserFilesResponse, FormData>({
      query: (data: FormData) => ({
        url: ``,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['userFiles'],
    }),
    deleteIdConfirmationFileById: builder.mutation<IUserFilesResponse, string>({
      query: (id: string) => ({
        url: `/delete-id-confirmation-document/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['userFiles'],
    }),
  }),
});

export const {
  useUploadIdConfirmationFilesMutation,
  useDeleteIdConfirmationFileByIdMutation,
  usePrefetch,
} = userFilesApi;

export default userFilesApi;

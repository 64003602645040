const HeartIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5003 14.072L12.0003 21.5L4.5003 14.072C4.00561 13.5906 3.61594 13.012 3.35585 12.3726C3.09575 11.7333 2.97086 11.0469 2.98904 10.3569C3.00721 9.66691 3.16806 8.98813 3.46146 8.36333C3.75485 7.73853 4.17444 7.18125 4.69379 6.72657C5.21314 6.2719 5.82101 5.92968 6.47911 5.72147C7.13722 5.51327 7.83131 5.44358 8.51767 5.5168C9.20403 5.59001 9.8678 5.80455 10.4672 6.1469C11.0666 6.48925 11.5885 6.95199 12.0003 7.50599C12.4138 6.95602 12.9364 6.49731 13.5354 6.1586C14.1344 5.81988 14.7968 5.60844 15.4812 5.53751C16.1657 5.46658 16.8574 5.53769 17.5131 5.74639C18.1688 5.95508 18.7743 6.29687 19.2919 6.75036C19.8094 7.20385 20.2277 7.75928 20.5207 8.38189C20.8137 9.00449 20.975 9.68088 20.9946 10.3687C21.0142 11.0565 20.8916 11.741 20.6344 12.3792C20.3773 13.0175 19.9912 13.5958 19.5003 14.078"
        fill="#F5431D"
      />
    </svg>
  );
};

export default HeartIcon;

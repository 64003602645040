import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import EditIcon from '../../../../components/Icons/EditIcon';
import SquareIconButton from '../../../../components/MUIComponents/SquareIconButton';
import { useCurrentPageUserId } from '../../../../hooks/useCurrentPageUserId';
import ArticleLayout from '../../../../layouts/ArticleLayout';
import { useGetUserSportBackgroundsByUserIdQuery } from '../../../../store/apiSlice/sportBackgroundApi';
import { foramtCareerDate } from '../../../../utils/profile-page/format-career-date';

const ExperienceTab = () => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  const { data: sportBgs, isLoading } = useGetUserSportBackgroundsByUserIdQuery(
    userId ?? '',
    {
      skip: !userId,
    }
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={{ xs: '16px', xl: '22px' }}
    >
      {isLoading && (
        <Skeleton
          variant="rounded"
          sx={{
            minHeight: '530px',
          }}
        />
      )}

      {!!sportBgs?.length &&
        !isLoading &&
        sportBgs.map(sportBg => (
          <ArticleLayout
            className="about-article"
            key={sportBg.id}
            rowGap={8}
            display="flex"
            flexDirection="column"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" textTransform="capitalize">
                experience
              </Typography>
              {/*{isOwnPage && (*/}
              {/*  <SquareIconButton>*/}
              {/*    <EditIcon />*/}
              {/*  </SquareIconButton>*/}
              {/*)}*/}
            </Stack>

            <Grid container rowGap="28px" sx={gridContainerStyles}>
              <Grid item display="flex" flexDirection="row">
                <Typography className="label" variant="caption">
                  Sport:
                </Typography>
                <Typography className="plain-text" variant="body3">
                  {`${sportBg?.sport.name ?? ''}`}
                </Typography>
              </Grid>
              <Grid item display="flex" flexDirection="row">
                <Typography className="label" variant="caption">
                  Career timeline:
                </Typography>
                <Typography className="plain-text" variant="body3">
                  {foramtCareerDate(sportBg.startCareerDate)} -{' '}
                  {foramtCareerDate(sportBg.endCareerDate)}
                </Typography>
              </Grid>
              <Grid item display="flex" flexDirection="row">
                <Typography className="label" variant="caption">
                  Description:
                </Typography>
                <Typography className="plain-text" variant="body3">
                  {sportBg.description ?? ''}
                </Typography>
              </Grid>
            </Grid>

            {!!sportBg.careers.length && (
              <>
                <Divider sx={{ m: '-32px 0' }} />
                <Box
                  className="teams"
                  display="flex"
                  flexDirection="column"
                  rowGap={4}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h3" textTransform="capitalize">
                      teams
                    </Typography>
                    {/*{isOwnPage && (*/}
                    {/*  <SquareIconButton>*/}
                    {/*    <EditIcon />*/}
                    {/*  </SquareIconButton>*/}
                    {/*)}*/}
                  </Stack>
                  {sportBg.careers.map((career, index) => (
                    <Box key={career.id}>
                      <Grid container rowGap="28px" sx={gridContainerStyles}>
                        <Grid item display="flex" flexDirection="row">
                          <Typography className="label" variant="caption">
                            League:
                          </Typography>
                          <Typography className="plain-text" variant="body3">
                            {career.leagueName}
                          </Typography>
                        </Grid>
                        <Grid item display="flex" flexDirection="row">
                          <Typography className="label" variant="caption">
                            Team:
                          </Typography>
                          <Typography className="plain-text" variant="body3">
                            {career.teamName}
                          </Typography>
                        </Grid>
                        <Grid item display="flex" flexDirection="row">
                          <Typography className="label" variant="caption">
                            Timeline:
                          </Typography>
                          <Typography className="plain-text" variant="body3">
                            {foramtCareerDate(career.startDate)} -{' '}
                            {foramtCareerDate(career.endDate)}
                          </Typography>
                        </Grid>
                      </Grid>

                      {index !== sportBg.careers.length - 1 && (
                        <Divider sx={{ mt: '32px' }} />
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </ArticleLayout>
        ))}
    </Box>
  );
};

export default ExperienceTab;

const gridContainerStyles: SxProps<Theme> = theme => ({
  '& .label': {
    width: 165,
    flexShrink: 0,
  },
  '& .plain-text': {
    fontWeight: 400,
    lineHeight: '140%',
    color: theme.palette.text.primary,

    textAlign: 'left',
    flexGrow: '1',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    minWidth: 0,
  },
  '& .MuiGrid-item': {
    width: '100%',
  },
});

export enum ButtonTypeEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  MENU = 'menu',
}

export enum ButtonIconPositionEnum {
  LEFT = 'left',
  RIGHT = 'right',
  NONE = 'none',
}

export enum ConnectionPrimaryButtonLabelEnum {
  ACCEPT = 'Accept',
  SEND_MESSAGE = 'Send Message',
  CANCEL_REQUEST = 'Cancel Request',
  SEND_REQUEST = 'Send Request',
}

export enum ConnectionSecondaryButtonLabelEnum {
  DECLINE = 'Decline',
  REMOVE = 'Remove',
}

import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface IIconWithBackgroundProps {
  icon: ReactNode;
  size?: string;
  bgcolor?: string;
}

const IconWithBackground = ({
  size = '3.125rem',
  bgcolor = 'background.light',
  icon,
}: IIconWithBackgroundProps) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      width={size}
      height={size}
      flexShrink={0}
      bgcolor={bgcolor}
      borderRadius={'50%'}
    >
      {icon}
    </Box>
  );
};

export default IconWithBackground;

import { SxProps, useMediaQuery } from '@mui/material';
import { Video } from '../../../interfaces/media.interface';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import BaseModal from '../../MUIComponents/BaseModal';
import { ElementIdEnum } from '../../../enums/element-id-enum';
import { Dispatch, SetStateAction, useState } from 'react';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import UploadedFileIconElement from '../../Auth/Elements/UploadedFileIconElement';
import DragAndDropBox from '../../MUIComponents/DragAndDropBox/DragAndDropBox';
import { triggerFileInput } from '../../../utils/helper/triggerInputHelper';
import { isSupportedIdConfirmationVideoFileHelper } from '../../../utils/helper/file-validation-helper';
import { useAddUserVideoMutation } from '../../../store/apiSlice/profileMediaApi';
import VideoGrid from '../Videos/components/videos-tab/VideoGrid';
import VideosIcon from '../../Icons/VideosIcon';
import Loader from '../../MUIComponents/Loader';
import { errorHelper } from '../../../utils/helper/error-helper';

interface AddVideosModalProps {
  albumId?: string | null;
  onClose: () => void;
  isOpen: boolean;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setSnackbarSeverity: Dispatch<SetStateAction<SnackbarSeverityEnum>>;
}

const AddVideosModal = ({
  albumId = null,
  onClose,
  isOpen = false,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}: AddVideosModalProps) => {
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('lg'));
  const [videos, setVideos] = useState<Video[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  // eslint-disable-next-line
  const [dragOver, setDragOver] = useState(false);

  const [addUserVideo, { isLoading: isUpdatingFiles }] =
    useAddUserVideoMutation();

  const handleFileRemove = async (id: string) => {
    setVideos(prevVideos => prevVideos.filter(video => video.id !== id));
    setSnackbarMessage('Video deleted successfully');
    setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    setSnackbarOpen(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const addFiles = async (files: FileList) => {
    const newFiles = Array.from(files);
    if (newFiles.length + videos.length > 5) {
      setSnackbarMessage('You can upload up to 5 files at once');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    setFiles(newFiles);

    const newVideos: Video[] = Array.from(files).map(file => {
      const url = URL.createObjectURL(file);
      return {
        id: crypto.randomUUID(),
        fileUrl: url,
      };
    });

    setVideos(prevVideo => [...prevVideo, ...newVideos]);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFiles(event.target.files);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFiles(event.dataTransfer.files);
    }
  };

  const boxSx: SxProps = {
    width: { xs: '100%', lg: '875px !important' },
    maxHeight: { xs: '80vh', lg: '90dvh' },
    p: { xs: '20px 12px 12px', lg: '36px 36px 48px' },
    position: { xs: 'fixed', lg: 'absolute' },
    top: { lg: '50%' },
    left: { lg: '50%' },
    bottom: { xs: 0, lg: 'unset' },
    borderRadius: { lg: '0 !important' },
    transform: { lg: 'translate(-50%, -50%)' },
    '.content': { m: '48px 0 36px' },
  };

  const closeModal = () => {
    setVideos([]);
    setFiles([]);
    onClose();
  };

  const publishChanges = async () => {
    if (!files?.length) {
      return;
    }

    const validFiles = files.filter(file =>
      isSupportedIdConfirmationVideoFileHelper(file)
    );
    const invalidFiles = files.filter(
      file => !isSupportedIdConfirmationVideoFileHelper(file)
    );

    if (validFiles.length > 5) {
      setSnackbarMessage('You can upload up to 5 files at once');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (invalidFiles.length > 0) {
      setSnackbarMessage('Invalid file type. Only videos (mp4, ...)');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }
    try {
      await Promise.all(
        validFiles.map(async file => {
          const formData = new FormData();
          formData.append('file', file);
          albumId && formData.append('videoAlbumId', albumId);
          await addUserVideo(formData).unwrap();
        })
      );
      setSnackbarMessage('All files uploaded successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(`Error uploading files: ${errorHelper(error)}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      console.error('Error uploading files:', error);
    }

    closeModal();
  };

  const mainContent = !videos.length ? (
    <DragAndDropBox
      handleDragOver={handleDragOver}
      handleDragLeave={handleDragLeave}
      handleDrop={handleDrop}
      handleFileChange={handleFileChange}
      triggerFileInput={() => triggerFileInput(ElementIdEnum.ADD_VIDEOS_MODAL)}
      fileInputId={ElementIdEnum.ADD_VIDEOS_MODAL}
      text="Drag and drop your files here. You can upload up to 5 videos at once"
      icon={
        <UploadedFileIconElement>
          <VideosIcon active />
        </UploadedFileIconElement>
      }
      isVideoInput
    />
  ) : (
    <VideoGrid
      files={videos}
      handleFileRemove={handleFileRemove}
      hideViews={true}
    />
  );

  return (
    <BaseModal
      boxSx={{ ...boxSx }}
      disableEscapeButton
      disableContentMargin
      isOpen={isOpen}
      toggle={closeModal}
      onAccept={publishChanges}
      footer={{
        okText: 'publish',
        declineText: 'cancel',
        styles: {
          gap: 'unset',
        },
        declineButtonStyles: {
          flex: 'unset !important',
          borderColor: theme.palette.background.darker,
          minWidth: tabletAndBigger ? '220px' : 'unset',
          borderRadius: 0,
        },
        okButtonStyles: {
          flex: 'unset !important',
          minWidth: tabletAndBigger ? '220px' : 'unset',
          borderRadius: 0,
        },
        isOkButtonDisabled:
          videos?.length === 0 || isUpdatingFiles ? true : false,
      }}
      header={{ text: 'Upload Video' }}
    >
      {isUpdatingFiles ? <Loader color="primary" /> : mainContent}
    </BaseModal>
  );
};

export default AddVideosModal;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, Typography } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import SnackbarCustom from '../../../components/MUIComponents/SnackbarCustom';
import SubscriptionPaymentManualForm from './SubscriptionPaymentManualForm';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../utils/helper/error-helper';

interface ISubscriptionPayment {
  clientSecret: string;
  planName: string;
  price: number;
}

const SubscriptionPayment = (props: ISubscriptionPayment) => {
  const { clientSecret, price, planName } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!clientSecret || !stripe || !elements) {
    return <CircularProgress />;
  }

  const onSubmitManual = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    if (!stripe || !elements || !clientSecret) return;

    try {
      const { error: submitError } = await elements.submit();

      if (submitError) {
        setSnackbarMessage(`Error: ${errorHelper(submitError)}`);
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: clientSecret,
        confirmParams: {
          return_url:
            window.location.origin + '/subscription-successfully-paid',
        },
      });

      if (error) {
        setSnackbarMessage(`Error: ${errorHelper(error)}`);
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Payment successful');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(`Error: ${errorHelper(error)}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '36px',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="h2">Subscription payment</Typography>
      </Box>

      <SubscriptionPaymentManualForm onSubmitManual={onSubmitManual} />

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default SubscriptionPayment;

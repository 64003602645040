import {
  Box,
  Grid,
  IconButton,
  SlideProps,
  Slider,
  SliderProps,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import FullScreenIcon from '../../Icons/FullScreenIcon';
import PauseIcon from '../../Icons/PauseIcon';
import PlayIcon from '../../Icons/PlayIcon';
import VolumeEditor from './VolumeEditor';
import { StyledIconButton } from './StyledIconButton';
import ExitFullScreenIcon from '../../Icons/ExitFullScreenIcon';

const PrettoSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  '& .MuiSlider-rail': {
    height: '5px',
    borderRadius: 4,
  },
  '& .MuiSlider-track': {
    height: '5px',
    borderRadius: 4,
    width: '100%',
  },
}));

interface ControlProps {
  isFullscreen: boolean;
  onPlayPause: React.MouseEventHandler<HTMLDivElement> | undefined;
  playing: boolean;
  onRewind: React.MouseEventHandler<HTMLDivElement> | undefined;
  onForward: React.MouseEventHandler<SVGSVGElement> | undefined;
  played: number;
  onSeek: ((event: Event, value: number) => void) | undefined;
  onSeekMouseUp: ((event: Event, value: number) => void) | undefined;
  onVolumeChangeHandler: ((event: Event, value: number) => void) | undefined;
  onVolumeSeekUp: ((event: Event, value: number) => void) | undefined;
  volume: number;
  mute: boolean;
  onMute: () => void;
  duration: string;
  currentTime: string;
  onMouseSeekDown: (e: React.MouseEvent) => void;
  handleToggleFullscreen: () => void;
  controlRef: React.RefObject<HTMLDivElement>;
}

const Control = ({
  isFullscreen,
  onPlayPause,
  playing,
  onRewind,
  onForward,
  played,
  onSeek,
  onSeekMouseUp,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  mute,
  onMute,
  duration,
  currentTime,
  onMouseSeekDown,
  handleToggleFullscreen,
  controlRef,
}: ControlProps) => {
  return (
    <Grid
      className="control_Container"
      container
      style={{
        visibility: 'visible',
        transition: 'visibility 1.5s, opacity 1.5s linear',
      }}
      ref={controlRef}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        flexDirection: 'column',
        zIndex: '1',
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiSvgIcon-root': {
          cursor: 'pointer',
        },
      }}
    >
      <Grid item flex={1}></Grid>
      <Grid
        className="mid__container"
        item
        container
        flex={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <Grid item className="icon__btn" onDoubleClick={onRewind}>
          <FastRewind fontSize="medium" />
        </Grid> */}

        <Grid item className="icon__btn" onClick={onPlayPause}>
          {(playing && played !== 1) ? (
            <PauseIcon width={50} height={50} fontSize="large" />
          ) : (
            <PlayIcon height={50} width={50} fontSize="large" />
          )}
        </Grid>

        {/* <Grid item className="icon__btn">
          <FastForward fontSize="medium" onDoubleClick={onForward} />
        </Grid> */}
      </Grid>
      <Grid
        className="bottom__container"
        item
        container
        alignSelf="center"
        alignItems="flex-end"
        justifyContent="center"
        flex={1}
      >
        <Grid
          container
          flexDirection="row"
          height="fit-content"
          maxWidth={826}
          width="90%"
          p="6px 12px"
          mb="22px"
          sx={theme => ({ backgroundColor: theme.palette.secondary.main })}
        >
          <Grid item className="control__box" width="100%">
            <Stack
              className="inner__controls"
              direction="row"
              sx={theme => ({
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                  justifyContent: 'space-between',
                },
                color: theme.palette.text.accent,
              })}
            >
              <Stack
                direction="row"
                width="100%"
                justifyContent="center"
                alignItems="center"
                columnGap="28px"
                sx={theme => ({ color: theme.palette.background.white })}
              >
                <Stack direction="row" columnGap="12px">
                  <Box className="icon__btn" onClick={onPlayPause}>
                    {playing ? (
                      <PauseIcon fontSize="medium" />
                    ) : (
                      <PlayIcon fontSize="medium" />
                    )}
                  </Box>

                  {/* <Box className="icon__btn">
                  <SkipNext fontSize="medium" />
                </Box> */}

                  <VolumeEditor
                    mute={mute}
                    volume={volume}
                    onMute={onMute}
                    onVolumeSeekUp={onVolumeSeekUp}
                    onVolumeChangeHandler={onVolumeChangeHandler}
                  />
                </Stack>

                <Stack
                  className="progress__container"
                  direction="row"
                  columnGap="12px"
                  alignItems="center"
                  width="100%"
                  sx={theme => ({ color: theme.palette.text.accent })}
                >
                  <Typography variant="badge2">{currentTime}</Typography>
                  <Box
                    className="slider__container"
                    width="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <PrettoSlider
                      size="small"
                      min={0}
                      max={1000}
                      shiftStep={1000}
                      step={1}
                      value={played * 1000}
                      onChange={onSeek as any}
                      onChangeCommitted={onSeekMouseUp as any}
                      onMouseDown={onMouseSeekDown}
                      sx={{
                        '& .MuiSlider-thumb': {
                          display: 'none',
                        },
                      }}
                    />
                  </Box>
                  <Typography variant="badge2">{duration}</Typography>
                </Stack>
                <StyledIconButton onClick={handleToggleFullscreen}>
                  {!isFullscreen ? (
                    <FullScreenIcon fontSize="medium" />
                  ) : (
                    <ExitFullScreenIcon fontSize="medium" />
                  )}
                </StyledIconButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Control;

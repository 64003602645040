import { Box, Typography } from '@mui/material';
import DeleteChatIcon from '../../../../../components/Icons/DeleteChatIcon';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import useLeaveFromChat from '../../../../../hooks/chat/useLeaveFromChat';

const LeaveChat = ({ onClose }: { onClose: () => void }) => {
  const { handleLeaveFromChat, isLeavingFromChat } = useLeaveFromChat();

  return (
    <>
      <DeleteChatIcon />
      <Typography typography={'h2'}>
        Are you sure you want to leave this group chat?
      </Typography>
      <Typography typography={'body2'}>
        {
          'Are you sure you want to leave this group chat? This action cannot be undone.'
        }
      </Typography>
      <Box display={'flex'} gap={'1rem'}>
        <CustomButton
          sx={{ color: 'text.primary' }}
          buttonWidth={'210px'}
          variantType={ButtonTypeEnum.TERTIARY}
          onClick={onClose}
          disabled={isLeavingFromChat}
        >
          cancel
        </CustomButton>
        <CustomButton
          buttonWidth={'210px'}
          variantType={ButtonTypeEnum.PRIMARY}
          onClick={() => {
            onClose();
            handleLeaveFromChat();
          }}
          disabled={isLeavingFromChat}
        >
          leave
        </CustomButton>
      </Box>
    </>
  );
};

export default LeaveChat;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CommentIcon(svgProps?: SvgIconProps) {
  return (
    <SvgIcon {...svgProps}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M17.5 15V3.33337H2.5V15H6.66667V17.5L10.8333 15H17.5Z" stroke="#727F93" strokeWidth="1.2" strokeLinecap="square"/>
      </svg>
    </SvgIcon>
  );
}

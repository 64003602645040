import { TextField, InputAdornment } from '@mui/material';
import { ChangeEvent } from 'react';
import MagnifyingGlassIcon from '../../../../components/Icons/MagnifyingGlassIcon';

interface ForwardToSearchBarProps {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}
const ForwardToSearchBar = ({ onSearch }: ForwardToSearchBarProps) => {
  return (
    <TextField
      onChange={onSearch}
      variant="outlined"
      fullWidth
      placeholder="Search... "
      sx={{
        bgcolor: 'background.light',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlassIcon />
          </InputAdornment>
        ),
        autoFocus: true,
      }}
    />
  );
};

export default ForwardToSearchBar;

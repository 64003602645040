import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { CookieEnum } from '../../enums/cookie-enum';

const SubscriptionPaymentProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = Cookies.get(CookieEnum.ACCESS_TOKEN);

    if (!token && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [location.pathname, navigate]);

  if (!Cookies.get(CookieEnum.ACCESS_TOKEN)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default SubscriptionPaymentProtectedRoute;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { config } from '../../config/config';
import { CookieEnum } from '../../enums/cookie-enum';
import { IPaginationResponse } from '../../interfaces/common.interface';
import {
  AgentReviewsCountRes,
  CreateReviewReq,
  CreateReviewRes,
  GetAgentReviewReq,
  Review,
} from '../../interfaces/review.interface';

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.BASE_USER_MICROSERVICE_API_URL}/review`,
  prepareHeaders: headers => {
    const token = Cookies.get(CookieEnum.ACCESS_TOKEN);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithInterceptor = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  const response = await baseQuery(args, api, extraOptions);

  if ((response as any)?.error?.status === 401) {
    Cookies.remove(CookieEnum.ACCESS_TOKEN);
    window.location.reload();
  }

  return response;
};

const reviewApi = createApi({
  reducerPath: 'reviewApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['review'],

  endpoints: builder => ({
    getAllAgentReviewsByAgentId: builder.query<
      IPaginationResponse<Review>,
      GetAgentReviewReq
    >({
      query: ({
        id, // required
        page = 1,
        limit = 10,
        search = '',
        sort = 'desc',
      }) => {
        const params = new URLSearchParams({
          page: page.toString(),
          limit: limit.toString(),
          search,
          sort,
        });

        return `/agent-reviews/agentId/${id}?${params.toString()}`;
      },
      providesTags: ['review'],
    }),
    getAgentReviewsCountByAgentId: builder.query<AgentReviewsCountRes, string>({
      query: id => `/count-reviewers/agentId/${id}`,
      providesTags: ['review'],
    }),
    createReview: builder.mutation<CreateReviewRes, CreateReviewReq>({
      query: data => ({
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['review'],
    }),
  }),
});

export const {
  useGetAllAgentReviewsByAgentIdQuery,
  useGetAgentReviewsCountByAgentIdQuery,
  useCreateReviewMutation,
} = reviewApi;

export default reviewApi;

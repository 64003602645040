import React, { FC } from 'react';
import { passwordValidationMessages } from '../../../validation/auth-validation';
import PasswordValidationMessage from './PasswordValidationMessage';
import { Box } from '@mui/material';

interface PasswordValidationProps {
  isDirty: boolean;
  errorMessages: string[];
}
const PasswordValidation: FC<PasswordValidationProps> = ({
  isDirty,
  errorMessages,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
      {Object.values(passwordValidationMessages).map(message => (
        <PasswordValidationMessage
          key={message}
          isValid={isDirty && !errorMessages.includes(message)}
          message={message}
        />
      ))}
    </Box>
  );
};

export default PasswordValidation;

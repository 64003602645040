import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface UploadedFileIconElementProps {
  children: ReactNode;
}

const UploadedFileIconElement = ({
  children,
}: UploadedFileIconElementProps) => {
  const { theme } = useThemeContext();
  
  return (
    <Box display={'flex'} justifyContent={'center'} width={'100%'}>
      <Box
        display={'flex'}
        position={'relative'}
        sx={{
          width: '60px',
          height: '60px',
          borderRadius: '1px',
        }}
        bgcolor={theme.palette.background.dark}
      >
        <Box
          position={'absolute'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            width: '60px',
            height: '60px',
            borderRadius: '1px',
            background: theme.palette.button.light,
            top: '-5px',
            left: '-10px',
            transform: 'rotate(-15deg)',
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadedFileIconElement;

import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { SnackbarSeverityEnum } from '../../enums/snackbar-severity-enum';

interface SnackbarCustomProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: SnackbarSeverityEnum;
}

const SnackbarCustom: React.FC<SnackbarCustomProps> = ({
  open,
  onClose,
  message,
  severity,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarCustom;

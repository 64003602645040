import React, { useEffect, useState } from 'react';
import { ButtonTypeEnum } from '../../enums/button-type-enum';
import { SnackbarSeverityEnum } from '../../enums/snackbar-severity-enum';
import CustomButton from '../MUIComponents/CustomButton';
import SnackbarCustom from '../MUIComponents/SnackbarCustom';
import CkEditorComponent from '../CkEditorComponent/CkEditorComponent';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import SectionLayout from '../../layouts/SectionLayout';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import {
  useCreateMyCompanyAboutMutation,
  useDeleteMyCompanyAboutMutation,
  useGetMyCompanyAboutQuery,
  useUpdateMyCompanyAboutMutation,
} from '../../store/apiSlice/userCompanyInfoApi';
import DragAndDropBox from '../MUIComponents/DragAndDropBox/DragAndDropBox';
import { triggerFileInput } from '../../utils/helper/triggerInputHelper';
import { ElementIdEnum } from '../../enums/element-id-enum';
import UploadedFileIconElement from '../Auth/Elements/UploadedFileIconElement';
import { Photo, Video } from '../../interfaces/media.interface';
import { v4 as uuidv4 } from 'uuid';
import { errorHelper } from '../../utils/helper/error-helper';
import Loader from '../MUIComponents/Loader';
import CompanyAboutFilesIcon from '../Icons/CompanyAboutFilesIcon';
import CompanyAboutPdfFile from './CompanyAboutPdfFile/CompanyAboutPdfFile';
import CompanyAboutPhotoFile from './CompanyAboutPhotoFile/CompanyAboutPhotoFile';
import { isSupportedCompanyAboutFileHelper } from '../../utils/helper/file-validation-helper';
import TrashRedIcon from '../Icons/TrashRedIcon';

interface FileWithId {
  id: string;
  file: File;
}

const About = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [about, setAbout] = useState<string>('');
  const [dragOver, setDragOver] = useState(false);
  const [media, setMedias] = useState<(Photo | Video)[]>([]);
  const [files, setFiles] = useState<FileWithId[]>([]);

  const {
    data: getMyCompanyAboutInfoResponseData,
    error,
    isLoading: getMyCompanyAboutInfoIsLoading,
    refetch,
  } = useGetMyCompanyAboutQuery();

  const [
    createCompanyAboutInfo,
    { isLoading: createCompanyAboutInfoIsLoading },
  ] = useCreateMyCompanyAboutMutation();

  const [
    updateCompanyAboutInfo,
    { isLoading: updateCompanyAboutInfoIsLoading },
  ] = useUpdateMyCompanyAboutMutation();

  const [
    deleteCompanyAboutInfo,
    { isLoading: deleteCompanyAboutInfoIsLoading },
  ] = useDeleteMyCompanyAboutMutation();

  useEffect(() => {
    if (getMyCompanyAboutInfoResponseData) {
      setAbout(getMyCompanyAboutInfoResponseData.about || '');
      setMedias([
        {
          id: getMyCompanyAboutInfoResponseData.id,
          fileName: getMyCompanyAboutInfoResponseData.fileName,
          fileUrl: getMyCompanyAboutInfoResponseData.fileUrl,
          uniqueKey: getMyCompanyAboutInfoResponseData.uniqueKey,
        },
      ]);
    }
  }, [getMyCompanyAboutInfoResponseData]);

  const onDeleteCompanyAboutInfoSubmit = async (id: string) => {
    try {
      await deleteCompanyAboutInfo({ id }).unwrap();
      refetch();
      setAbout('');
      setMedias([]);
      setFiles([]);
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarMessage('Company info deleted successfully');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarMessage(errorHelper(error));
      setSnackbarOpen(true);
    }
  };

  const onSubmit = async () => {
    const companyAboutInfoRequest = new FormData();
    companyAboutInfoRequest.append('about', about);

    try {
      if (getMyCompanyAboutInfoResponseData) {
        if (!media.length) {
          setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
          setSnackbarMessage('Please add a file');
          setSnackbarOpen(true);
          return;
        }

        if (files.length === 1) {
          companyAboutInfoRequest.append('file', files[0].file);
        }

        await updateCompanyAboutInfo({
          id: getMyCompanyAboutInfoResponseData.id,
          data: companyAboutInfoRequest,
        }).unwrap();
      } else {
        if (!files.length || !media.length) {
          setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
          setSnackbarMessage('Please add a file');
          setSnackbarOpen(true);
          return;
        }

        if (files.length === 1) {
          companyAboutInfoRequest.append('file', files[0].file);
        }

        await createCompanyAboutInfo(companyAboutInfoRequest).unwrap();
        refetch();
      }
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarMessage('Company info updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarMessage(errorHelper(error));
      setSnackbarOpen(true);
    }
  };

  const addFile = async (files: FileList) => {
    const newFiles = Array.from(files);
    const invalidFiles = newFiles.filter(
      file => !isSupportedCompanyAboutFileHelper(file)
    );

    if (invalidFiles.length > 0) {
      setSnackbarMessage(
        'Invalid file type. Only images (JPEG, PNG, WebP) or PDF'
      );
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (newFiles.length > 1) {
      setSnackbarMessage('Only one file allowed');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    const mapedFiles: Photo[] = [];
    const filesWithIds: FileWithId[] = [];

    newFiles.forEach(file => {
      const id = uuidv4();
      mapedFiles.push({
        id,
        fileUrl: URL.createObjectURL(file),
      });
      filesWithIds.push({
        id,
        file,
      });
    });

    setFiles(filesWithIds);
    setMedias(mapedFiles);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFile(event.dataTransfer.files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleFileRemove = (id: string) => {
    setFiles(prevFiles => prevFiles.filter(fileWithId => fileWithId.id !== id));
    setMedias(prevMedias => prevMedias.filter(media => media.id !== id));
  };

  const isPdfFile = (fileName: string) =>
    fileName.toLowerCase().endsWith('.pdf') ||
    (files.length && files[0].file.type === 'application/pdf');

  return (
    <SectionLayout
      display="flex"
      flexDirection="column"
      gap={mobileView ? '28px' : '36px'}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={'12px'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography component="h2" variant="h2" mb="12px">
          About company
        </Typography>

        {getMyCompanyAboutInfoResponseData && (
          <IconButton
            onClick={() =>
              onDeleteCompanyAboutInfoSubmit(
                getMyCompanyAboutInfoResponseData.id
              )
            }
          >
            <TrashRedIcon />
          </IconButton>
        )}
      </Box>

      <div>
        {getMyCompanyAboutInfoIsLoading ? (
          <Loader size={30} color="primary" />
        ) : (
          <Box display={'flex'} flexDirection={'column'}>
            {!media.length && (
              <DragAndDropBox
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleDrop={handleDrop}
                handleFileChange={handleFileChange}
                triggerFileInput={() =>
                  triggerFileInput(ElementIdEnum.ADD_COMPANY_IMAGE_ABOUT_BUTTON)
                }
                fileInputId={ElementIdEnum.ADD_COMPANY_IMAGE_ABOUT_BUTTON}
                text={
                  <>
                    Drag and drop your file here
                    <br />
                    Only one file allowed. Only images (JPEG, PNG, WebP) or PDF
                    are accepted.
                  </>
                }
                icon={
                  <UploadedFileIconElement>
                    <CompanyAboutFilesIcon active />
                  </UploadedFileIconElement>
                }
              />
            )}
            {!!media.length &&
              media.map(file =>
                isPdfFile(file.fileUrl) ? (
                  <CompanyAboutPdfFile
                    key={file.id}
                    files={[file]}
                    handleFileRemove={handleFileRemove}
                  />
                ) : (
                  <CompanyAboutPhotoFile
                    key={file.id}
                    files={[file]}
                    handleFileRemove={handleFileRemove}
                  />
                )
              )}
          </Box>
        )}
      </div>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <CkEditorComponent
        label=""
        value={about}
        onChange={data => setAbout(data)}
      />
      <Box
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        mt="36px"
        gap="22px"
      >
        <CustomButton
          buttonWidth={mobileView ? '100%' : '220px'}
          variantType={ButtonTypeEnum.PRIMARY}
          onClick={() => onSubmit()}
          disabled={
            getMyCompanyAboutInfoIsLoading ||
            createCompanyAboutInfoIsLoading ||
            updateCompanyAboutInfoIsLoading
          }
        >
          {getMyCompanyAboutInfoIsLoading ||
          createCompanyAboutInfoIsLoading ||
          updateCompanyAboutInfoIsLoading
            ? 'saving...'
            : getMyCompanyAboutInfoResponseData
              ? 'change'
              : 'save'}
        </CustomButton>
      </Box>
    </SectionLayout>
  );
};

export default About;

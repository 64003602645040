import { SvgIcon, SvgIconProps } from '@mui/material';

const FullScreenIcon = ({ width, height, sx, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: width || 28,
        height: height || 28,
        fill: 'none',
        ...sx,
      }}
      {...rest}
    >
      <path
        d="M4.66602 9.33329V4.66663H9.33268"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.66602 18.6666V23.3333H9.33268"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M18.666 4.66663H23.3327V9.33329"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M18.666 23.3333H23.3327V18.6666"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default FullScreenIcon;

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const commentReactionApi = createApi({
  reducerPath: 'commentReaction',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_COMMENT_MICROSERVICE_API_URL}/reaction`
  ),
  tagTypes: ['reaction'],

  endpoints: builder => ({
    likeComment: builder.mutation<void, any>({
      query: commentId => ({
        url: `/add/like/${commentId}`,
        method: 'POST',
      }),
      invalidatesTags: ['reaction'],
    }),
    dislikeComment: builder.mutation<void, any>({
      query: commentId => ({
        url: `/add/dislike/${commentId}`,
        method: 'POST',
      }),
      invalidatesTags: ['reaction'],
    }),
  }),
});

export const { useLikeCommentMutation, useDislikeCommentMutation } =
  commentReactionApi;

export default commentReactionApi;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface VideosIconProps extends SvgIconProps {
  iconColor?: string;
  active?: boolean;
}

const VideosIcon = ({ iconColor, active, ...svgProps }: VideosIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: svgProps.width ?? 28,
        height: svgProps.height ?? 28,
        fill: 'none',
        '& > path': {
          stroke: `${iconColor} !important`,
        },
      }}
      {...svgProps}
    >
      <path
        d="M23.3332 4.66663L4.6665 4.66663L4.6665 23.3333H23.3332V4.66663Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M9.3335 4.66663V23.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M18.6665 4.66663V23.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.6665 9.33337H9.33317"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.6665 18.6666H9.33317"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.6665 14H23.3332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M18.6665 9.33337H23.3332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M18.6665 18.6666H23.3332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default VideosIcon;

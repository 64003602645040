import { Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useMemo, useState } from 'react';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import TextField from '../../../../components/MUIComponents/TextField';
import { ElementIdEnum } from '../../../../enums/element-id-enum';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useApplyJobpostMutation } from '../../../../store/apiSlice/jobpost/jobUserApplyApi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApplyJobpostSchema } from '../../../../validation/apply-jobpost-validation';
import { useNavigate } from 'react-router-dom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { errorHelper } from '../../../../utils/helper/error-helper';
import DragAndDropBox from '../../../../components/MUIComponents/DragAndDropBox/DragAndDropBox';
import { triggerFileInput } from '../../../../utils/helper/triggerInputHelper';
import UploadedFileIconElement from '../../../../components/Auth/Elements/UploadedFileIconElement';
import CompanyAboutFilesIcon from '../../../../components/Icons/CompanyAboutFilesIcon';
import CompanyAboutPdfFile from '../../../../components/Company/CompanyAboutPdfFile/CompanyAboutPdfFile';
import CompanyAboutPhotoFile from '../../../../components/Company/CompanyAboutPhotoFile/CompanyAboutPhotoFile';
import { isSupportedCompanyAboutFileHelper } from '../../../../utils/helper/file-validation-helper';
import { Photo, Video } from '../../../../interfaces/media.interface';
import { v4 as uuidv4 } from 'uuid';

interface FileWithId {
  id: string;
  file: File;
}
interface ApplyJobpostFormInputs {
  jobpostId: string;
  introduction: string;
}

interface ApplyJobModalProps {
  isOpen?: boolean;
  onClose: () => void;
  jobpostId: string;
}

const ApplyJobModal = ({
  onClose,
  isOpen = false,
  jobpostId,
}: ApplyJobModalProps) => {
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [dragOver, setDragOver] = useState(false);
  const [media, setMedias] = useState<(Photo | Video)[]>([]);
  const [files, setFiles] = useState<FileWithId[]>([]);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const addFile = async (files: FileList) => {
    const newFiles = Array.from(files);
    const invalidFiles = newFiles.filter(
      file => !isSupportedCompanyAboutFileHelper(file)
    );

    if (invalidFiles.length > 0) {
      setSnackbarMessage(
        'Invalid file type. Only images (JPEG, PNG, WebP) or PDF'
      );
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (media.length + newFiles.length > 5) {
      setSnackbarMessage('Only 5 files allowed');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    const mapedFiles: Photo[] = [];
    const filesWithIds: FileWithId[] = [];

    newFiles.forEach(file => {
      const id = uuidv4();
      mapedFiles.push({
        id,
        fileUrl: URL.createObjectURL(file),
      });
      filesWithIds.push({
        id,
        file,
      });
    });

    setFiles(filesWithIds);
    setMedias(mapedFiles);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFile(event.dataTransfer.files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleFileRemove = (id: string) => {
    setFiles(prevFiles => prevFiles.filter(fileWithId => fileWithId.id !== id));
    setMedias(prevMedias => prevMedias.filter(media => media.id !== id));
  };

  const isPdfFile = (mediaFile: Photo | Video) => {
    const fileWithId = files.find(file => file.id === mediaFile.id);

    return (
      mediaFile?.fileName?.toLowerCase()?.endsWith('.pdf') ||
      fileWithId?.file?.type === 'application/pdf'
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplyJobpostFormInputs>({
    resolver: yupResolver(ApplyJobpostSchema),
    defaultValues: {
      jobpostId,
    },
  });

  const [applyJobpost, { isLoading: isApplying }] = useApplyJobpostMutation();

  const handleApplyJobpost = async (data: FormData) => {
    try {
      await applyJobpost(data).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Job was applied successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setTimeout(() => {
        onClose();
        navigate('/job-posts');
      }, 2000);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Apply failed: ', error);
    }
  };

  const onSubmit = async (data: ApplyJobpostFormInputs) => {
    try {
      const formData = new FormData();
      data.jobpostId = jobpostId;

      formData.append('introduction', data.introduction);
      formData.append('jobpostId', data.jobpostId);

      if (files.length > 0) {
        files.forEach(fileWithId => {
          const utf8FileName = new Blob([fileWithId.file], {
            type: fileWithId.file.type,
          });
          formData.append(
            'documents',
            utf8FileName,
            encodeURIComponent(fileWithId.file.name)
          );
        });
      }

      await handleApplyJobpost(formData);
    } catch (error) {
      console.error('Apply failed:', error);
    }
  };

  const modalTitle = useMemo(() => {
    return (
      <Typography
        variant="h2"
        textAlign="center"
        fontSize={'26px'}
        fontWeight={600}
      >
        Apply
      </Typography>
    );
  }, []);

  const modalFooter = useMemo(() => {
    return (
      <Box mt={3}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={{ xs: '10px', lg: '0px' }}
          width={'100%'}
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isApplying}
            >
              Send
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    );
  }, [handleSubmit, isApplying, onClose, onSubmit]);

  return (
    <BaseModal
      modalSx={{ borderColor: 'transparent !important' }}
      header={{ component: modalTitle }}
      disableEscapeButton
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
    >
      <Stack spacing="36px">
        <Box>
          <Typography
            component={'p'}
            fontSize={'12px'}
            fontWeight={700}
            mb={'16px'}
          >
            Cover letter/Introduction:
          </Typography>
          <TextField
            placeholder="Highlight your relevant skills and experiences"
            height={160}
            register={register('introduction')}
            error={!!errors.introduction}
            helperText={errors.introduction ? errors.introduction.message : ''}
          />
        </Box>
        {
          <Box
            display={'flex'}
            flexDirection={'column'}
            className="add-media-container"
            position="relative"
            gap={'28px'}
          >
            {!media.length && (
              <DragAndDropBox
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleDrop={handleDrop}
                handleFileChange={handleFileChange}
                triggerFileInput={() =>
                  triggerFileInput(ElementIdEnum.ADD_COMPANY_IMAGE_ABOUT_BUTTON)
                }
                fileInputId={ElementIdEnum.ADD_COMPANY_IMAGE_ABOUT_BUTTON}
                text={
                  <>
                    Drag and drop your file here
                    <br />
                    Only one file allowed. Only images (JPEG, PNG, WebP) or PDF
                    are accepted.
                  </>
                }
                icon={
                  <UploadedFileIconElement>
                    <CompanyAboutFilesIcon active />
                  </UploadedFileIconElement>
                }
              />
            )}
            {!!media.length &&
              media.map(file =>
                isPdfFile(file) ? (
                  <CompanyAboutPdfFile
                    key={file.id}
                    files={[file]}
                    handleFileRemove={handleFileRemove}
                  />
                ) : (
                  <CompanyAboutPhotoFile
                    key={file.id}
                    files={[file]}
                    handleFileRemove={handleFileRemove}
                  />
                )
              )}
          </Box>
        }
      </Stack>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default ApplyJobModal;

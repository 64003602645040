import { Box, BoxProps, Typography, useMediaQuery } from '@mui/material';
import ListSort, { IListSortPops } from './ListSort';
import ListSearch from './ListSearch';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface IListToolBarProps extends IListSortPops, BoxProps {
  subTitle: string;
  isSortShown?: boolean;
  isSearchShown?: boolean;
}

const ListToolBar: React.FC<IListToolBarProps> = ({
  subTitle,
  isSortShown = true,
  isSearchShown = true,
  sortMenuItems,
  sortMenuLabel,
  ...props
}) => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Box
      display={'flex'}
      flexDirection={matches ? 'row' : 'column'}
      alignItems={matches ? 'center' : 'start'}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      gap={!matches ? '22px' : 0}
      {...props}
    >
      <Typography
        variant="subtitle1"
        color={'text.primary'}
        sx={{ fontSize: '20px', fontWeight: 600 }}
        mr={'10px'}
      >
        {subTitle}
      </Typography>
      <Box
        display={'flex'}
        sx={{ gap: { xs: '8px', md: '16px', lg: '36px' }, width: '100%' }}
        alignItems={'center'}
        flexWrap={'wrap'}
        flexDirection={matches ? 'row' : 'row-reverse'}
        maxWidth={matches ? '530px' : '100%'}
      >
        {isSortShown && (
          <ListSort
            sortMenuItems={sortMenuItems}
            sortMenuLabel={sortMenuLabel}
          />
        )}
        {isSearchShown && <ListSearch />}
      </Box>
    </Box>
  );
};

export default ListToolBar;

import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import LeftArrowIcon from '../../Icons/LeftArrowIcon';
import { colors } from '../../../theme/theme';
import CustomButton from '../../MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  ICreateJobpostRequest,
  IJopPostInputs,
  TLocation,
  TPosition,
  TSalaryPeriod,
} from '../../../interfaces/jobpost.interface';
import Input from '../../MUIComponents/Input';
import { Link, useParams } from 'react-router-dom';
import createArrayFromEnum from '../../../utils/createArrayFromEnum';
import {
  LocationEnum,
  PositionEnum,
  SalaryPeriodEnum,
} from '../../../enums/job-post-enum';
import CkEditorComponent from '../../CkEditorComponent/CkEditorComponent';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../utils/helper/error-helper';
import JobPostFilterMultiSelect from '../../../pages/JobPostsPage/components/JobPostsSidebar/JobPostsFilters/JobPostFilterMultiSelect';
import JobPostFilterSingleSelect from '../../../pages/JobPostsPage/components/JobPostsSidebar/JobPostsFilters/JobPostFilterSingleSelect';
import {
  useCreateJobpostMutation,
  useGetIndustriesQuery,
  useGetJobpostByIdQuery,
  useMoveToDraftMyJobpostByIdMutation,
  useUpdateJobpostMutation,
} from '../../../store/apiSlice/jobpost/jobpostApi';
import { useNavigate } from 'react-router-dom';

const AddNewJobPostForm = () => {
  const { id } = useParams();
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const {
    data: jobPostData,
    isLoading: getJobPostLoading,
    error,
  } = useGetJobpostByIdQuery(id);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
  } = useForm<IJopPostInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (id !== 'new') {
      if (jobPostData && 'jobpost' in jobPostData) {
        const jobPost = jobPostData.jobpost;
        setValue('title', jobPost.title);
        setValue('description', jobPost.description);
        setValue('minSalary', jobPost.minSalary);
        setValue('maxSalary', jobPost.maxSalary);
        setPositionFilterState(jobPost.position);
        setLocationFilterState(jobPost.location);
        setSalaryPeriodFilterState(jobPost.salaryPeriod);
        setIndustryFilterState(
          jobPost.industries.map((ind: any) => ind.id).join(',')
        );
      }
    }
  }, [id, jobPostData, setValue]);

  const [createJobpost, { isLoading }] = useCreateJobpostMutation();
  const [updateJobpost, { isLoading: isLoadingUpdate }] =
    useUpdateJobpostMutation();
  const [moveJobpostToDraft, { isLoading: isMovingToDraft }] =
    useMoveToDraftMyJobpostByIdMutation();
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const postJob: SubmitHandler<IJopPostInputs> = async data => {
    if (!isFormValid()) return;

    const jobPost: ICreateJobpostRequest = {
      ...data,
      maxSalary: Number(data.maxSalary),
      minSalary: Number(data.minSalary),
      position: positionFilterState,
      location: locationFilterState,
      salaryPeriod: salaryPeriodFilterState,
      industryIds: industryFilterState.split(','),
      isDraft: false,
    };

    try {
      if (id === 'new') {
        await createJobpost(jobPost).unwrap();
      } else {
        await moveJobpostToDraft(id as string).unwrap();
        await updateJobpost({
          jobpostId: id,
          data: { ...jobPost, isDraft: false },
        });
      }
      setSnackbarMessage('Job posted successfully!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
      reset();
      handleFilterReset();
      navigate('/?tab=1');
    } catch (error) {
      setSnackbarMessage(`Error: ${errorHelper(error)}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const saveDraft: SubmitHandler<IJopPostInputs> = async data => {
    if (!isFormValid()) return;
    const jobPostDraft: ICreateJobpostRequest = {
      ...data,
      maxSalary: Number(data.maxSalary),
      minSalary: Number(data.minSalary),
      position: positionFilterState,
      location: locationFilterState,
      salaryPeriod: salaryPeriodFilterState,
      industryIds: industryFilterState.split(','),
      isDraft: true,
    };
    try {
      if (id === 'new') {
        await createJobpost(jobPostDraft).unwrap();
      } else {
        await moveJobpostToDraft(id as string).unwrap();
        await updateJobpost({
          jobpostId: id,
          data: { ...jobPostDraft, isDraft: true },
        });
      }
      setSnackbarMessage('Draft saved successfully!');
      setSnackbarSeverity(SnackbarSeverityEnum.INFO);
      setSnackbarOpen(true);
      reset();
      handleFilterReset();
      navigate('/?tab=1');
    } catch (error) {
      setSnackbarMessage(`Error: ${errorHelper(error)}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const [industryFilterState, setIndustryFilterState] = useState<string>('');
  const [positionFilterState, setPositionFilterState] = useState<string>('');
  const [locationFilterState, setLocationFilterState] = useState<string>('');
  const [salaryPeriodFilterState, setSalaryPeriodFilterState] =
    useState<string>(SalaryPeriodEnum.PER_MONTH);

  const { data: sportsIndustries } = useGetIndustriesQuery();

  const locationsArray: TLocation[] = createArrayFromEnum(
    LocationEnum,
    'location'
  );

  const salaryPeriodsArray: TSalaryPeriod[] = createArrayFromEnum(
    SalaryPeriodEnum,
    'salaryPeriod'
  );

  const positionsArray: TPosition[] = createArrayFromEnum(
    PositionEnum,
    'position'
  );

  const handleFilterChange = (industriesArray: string[]) => {
    if (industriesArray.length > 0)
      setIndustryFilterState(industriesArray.join(','));
  };

  const handleFilterReset = () => {
    setIndustryFilterState('');
    setPositionFilterState('');
    setLocationFilterState('');
    setSalaryPeriodFilterState(SalaryPeriodEnum.PER_MONTH);
  };

  const isFormValid = () => {
    const minSalary = Number(watch('minSalary'));
    const maxSalary = Number(watch('maxSalary'));
    const description = watch('description');

    if (minSalary > maxSalary) {
      setSnackbarMessage('Min salary cannot be greater than max salary.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return false;
    }

    if (minSalary < 0 || maxSalary < 0) {
      setSnackbarMessage('Salaries must be greater than or equal to 0.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return false;
    }

    if (!description) {
      setSnackbarMessage('Description is required.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return false;
    }

    if (
      !industryFilterState ||
      !positionFilterState ||
      !locationFilterState ||
      !salaryPeriodFilterState ||
      errors.title ||
      errors.minSalary ||
      errors.maxSalary
    ) {
      setSnackbarMessage(
        'Please fill all required fields: industry, position, location, salary period, and ensure all input fields are valid.'
      );
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return false;
    }

    return true;
  };

  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    const minSalary = Number(watch('minSalary'));
    const maxSalary = Number(watch('maxSalary'));
    const description = watch('description');

    setSubmitDisabled(
      !isValid ||
        industryFilterState === '' ||
        positionFilterState === '' ||
        locationFilterState === '' ||
        salaryPeriodFilterState === '' ||
        minSalary < 0 ||
        maxSalary < 0 ||
        !description ||
        isLoading ||
        isLoadingUpdate
    );
  }, [
    isValid,
    industryFilterState,
    positionFilterState,
    locationFilterState,
    salaryPeriodFilterState,
    watch('minSalary'),
    watch('maxSalary'),
    watch('description'),
    isLoading || isLoadingUpdate,
  ]);

  return (
    <Box
      display={'flex'}
      maxWidth={'875px'}
      width={'100%'}
      margin={'0 auto'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      marginBottom={'200px'}
    >
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          bgcolor={'background.white'}
        >
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            borderBottom={'1px solid'}
            borderColor={'background.darker'}
            padding={{
              xs: '22px 16px',
              lg: '36px 22px',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={'16px'}
              alignItems={'center'}
            >
              <IconButton
                aria-label="More"
                onClick={() => navigate('/')}
                sx={{
                  p: 0,
                  width: '24px',
                  height: '24px',
                  minWidth: '24px',
                  minHeight: '24px',
                  '&:hover': { background: 'transparent' },
                }}
                aria-haspopup="true"
              >
                <LeftArrowIcon color={colors.lightGreyBg} />
              </IconButton>
              <Typography variant="h3" component="h3">
                Go back
              </Typography>
            </Box>
          </Box>

          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Box
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              padding={{
                xs: '28px 16px',
                lg: '36px',
              }}
              gap={'36px'}
            >
              <Typography component="h2" variant="h2">
                New job post{' '}
              </Typography>

              <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                gap={mobileView ? '2.25rem' : '3rem'}
              >
                <Box display="flex">
                  <Input
                    caption="Job Title:"
                    containerFlex={
                      mobileView ? '0 1 100%' : '0 1 calc(50% - 8px)'
                    }
                    variant="outlined"
                    fullWidth
                    placeholder="Enter job title"
                    register={register('title', {
                      required: 'Job title is required',
                      minLength: {
                        value: 3,
                        message: 'Job title must be at least 3 characters',
                      },
                    })}
                    error={!!errors.title}
                    helperText={errors.title ? errors.title.message : ''}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={theme => ({
                    width: `calc(50% - 8px)`,
                    [theme.breakpoints.down('md')]: {
                      width: '100%',
                    },
                  })}
                >
                  <Box width={'100%'}>
                    <JobPostFilterMultiSelect
                      label={'industry'}
                      placeholder={'Choose one'}
                      selectItems={sportsIndustries || []}
                      handleFilterChange={handleFilterChange}
                      filterState={industryFilterState}
                    />
                  </Box>
                </Box>
                <Box
                  sx={theme => ({
                    display: 'flex',
                    gap: '16px',
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                      gap: '36px',
                    },
                  })}
                >
                  {' '}
                  <Box width={'100%'}>
                    <JobPostFilterSingleSelect
                      label={'position'}
                      placeholder={'Choose one'}
                      selectItems={positionsArray}
                      setFilterState={setPositionFilterState}
                      filterState={positionFilterState}
                    />{' '}
                  </Box>{' '}
                  <Box width={'100%'}>
                    <JobPostFilterSingleSelect
                      label={'location'}
                      placeholder={'Choose one'}
                      selectItems={locationsArray}
                      setFilterState={setLocationFilterState}
                      filterState={locationFilterState}
                    />{' '}
                  </Box>
                </Box>

                <Controller
                  name="description"
                  control={control}
                  rules={{ required: 'Description is required' }}
                  render={({ field }) => (
                    <CkEditorComponent
                      label="Description:"
                      value={field.value || ''}
                      onChange={field.onChange}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  )}
                />

                <Box display="flex" gap="16px">
                  <Input
                    caption="Min Salary:"
                    containerFlex="1"
                    variant="outlined"
                    fullWidth
                    type="number"
                    placeholder="Min Salary"
                    register={register('minSalary', {
                      required: 'Minimum salary is required',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Minimum salary must be a valid number',
                      },
                      max: {
                        value: 9000000,
                        message: 'Maximum salary cannot exceed 9,000,000',
                      },
                    })}
                    error={!!errors.minSalary}
                    helperText={
                      errors.minSalary ? errors.minSalary.message : ''
                    }
                  />
                  <Input
                    caption="Max Salary:"
                    containerFlex="1"
                    variant="outlined"
                    fullWidth
                    type="number"
                    placeholder="Max Salary"
                    register={register('maxSalary', {
                      required: 'Maximum salary is required',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Maximum salary must be a valid number',
                      },
                      max: {
                        value: 9000000,
                        message: 'Maximum salary cannot exceed 9,000,000',
                      },
                    })}
                    error={!!errors.maxSalary}
                    helperText={
                      errors.maxSalary ? errors.maxSalary.message : ''
                    }
                  />
                </Box>

                <JobPostFilterSingleSelect
                  label={'salaryPeriod'}
                  placeholder={'Choose salary period'}
                  selectItems={salaryPeriodsArray}
                  setFilterState={setSalaryPeriodFilterState}
                  filterState={salaryPeriodFilterState}
                />
              </Box>
            </Box>

            <Box
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              borderTop={'1px solid'}
              borderColor={'background.darker'}
              padding={{
                xs: '16px',
                lg: '36px',
              }}
            >
              <Box display="flex" flexDirection={'row'} gap="16px">
                <CustomButton
                  buttonWidth={mobileView ? '100%' : '220px'}
                  variantType={ButtonTypeEnum.PRIMARY}
                  onClick={handleSubmit(postJob)}
                  disabled={isSubmitDisabled}
                >
                  {isLoading || getJobPostLoading || isLoadingUpdate
                    ? 'Posting...'
                    : 'Post job'}
                </CustomButton>
                <CustomButton
                  buttonWidth={mobileView ? '100%' : '220px'}
                  variantType={ButtonTypeEnum.SECONDARY}
                  sx={{ borderColor: 'background.dark' }}
                  onClick={handleSubmit(saveDraft)}
                  disabled={isSubmitDisabled}
                >
                  {isLoading || isLoadingUpdate ? 'Saving...' : 'Save draft'}
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default AddNewJobPostForm;

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';
import RemoveConnectionIcon from '../../../components/Icons/RemoveConnectionIcon';

import ArchiveIcon from '../../../components/Icons/ArchiveIcon';
import EditIcon from '../../../components/Icons/EditIcon';
import MoveToDraftIcon from '../../../components/Icons/MoveToDraftIcon';
import { useModalManager } from '../../../hooks/useModalManager';
import ManageJobPostsModal from '../components/modals/ManageJobPostsModal';
import MyCompanyJobPostActionConfirmationModal from '../components/modals/MyCompanyJobPostActionConfirmationModal';

export interface IMenuItem {
  label: string;
  icon: JSX.Element;
  action: () => void;
  isDivider: boolean;
}

interface IJobPostSidebarMoreMenuProps {
  jobpostId: string;
  refetchJobpostData: () => void;
}

const JobPostSidebarMoreMenu: React.FC<IJobPostSidebarMoreMenuProps> = ({
  jobpostId,
  refetchJobpostData,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const { currentModal, toggleModal } = useModalManager();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (mobileView) {
      return toggleModal('JOB-POST-ACTIONS-MODAL');
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const jobpostMenuItems = useMemo((): IMenuItem[] => {
    const items = [
      {
        label: 'Move to drafts',
        icon: <MoveToDraftIcon />,
        action: () => toggleModal('DRAFT'),
        isDivider: true,
      },
      {
        label: 'Archive job post',
        icon: <ArchiveIcon />,
        action: () => toggleModal('ARCHIVE'),
        isDivider: true,
      },
      {
        label: 'Close job post',
        icon: <RemoveConnectionIcon />,
        action: () => toggleModal('CLOSE'),
        isDivider: true,
      },
      {
        label: 'Delete job post',
        icon: <RemoveConnectionIcon />,
        action: () => toggleModal('DELETE'),
        isDivider: false,
      },
    ];

    if (!mobileView) {
      return items;
    }
    items.unshift({
      label: 'Edit job posts',
      icon: (
        <EditIcon
          width={18}
          height={18}
          iconColor={theme.palette.secondary.main}
        />
      ),
      action: () => toggleModal('EDIT'),
      isDivider: true,
    });

    return items;
  }, [toggleModal, mobileView, theme]);

  return (
    <>
      {currentModal === 'EDIT' &&
        (() => console.log('Add edit jobpost modal here'))()}
      {currentModal === 'CLOSE' && (
        <MyCompanyJobPostActionConfirmationModal
          isOpen
          onClose={() => {
            toggleModal('CLOSE');
            refetchJobpostData();
          }}
          jobpostId={jobpostId as string}
          type={'CLOSE'}
        />
      )}
      {currentModal === 'DELETE' && (
        <MyCompanyJobPostActionConfirmationModal
          isOpen
          onClose={() => {
            toggleModal('DELETE');
            refetchJobpostData();
          }}
          jobpostId={jobpostId as string}
          type={'DELETE'}
        />
      )}
      {currentModal === 'DRAFT' && (
        <MyCompanyJobPostActionConfirmationModal
          isOpen
          onClose={() => {
            toggleModal('DRAFT');
            refetchJobpostData();
          }}
          jobpostId={jobpostId as string}
          type={'DRAFT'}
        />
      )}
      {currentModal === 'ARCHIVE' && (
        <MyCompanyJobPostActionConfirmationModal
          isOpen
          onClose={() => {
            toggleModal('ARCHIVE');
            refetchJobpostData();
          }}
          jobpostId={jobpostId as string}
          type={'ARCHIVE'}
        />
      )}
      <IconButton
        aria-label="More"
        sx={{
          p: 0,
          width: '50px',
          height: '50px',
          minWidth: '50px',
          minHeight: '50px',
          bgcolor: 'secondary2.main',
        }}
        id="show-more-button"
        aria-controls={open ? 'show-more' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: 'text.secondary' }} />
      </IconButton>

      {!mobileView && (
        <Menu
          sx={{
            pt: '0px',
            pb: '0px',
          }}
          id="show-more"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'show-more-button',
            disablePadding: true,
          }}
        >
          {jobpostMenuItems.length > 0 &&
            jobpostMenuItems.map((item, idx) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  item.action();
                  handleClose();
                }}
                sx={{
                  display: 'flex',
                  width: '100%',
                  gap: '6px',
                  fontSize: '12px',
                  p: '15px',
                  borderBottom: item.isDivider ? '1px solid #E8EBEF' : 'none',
                }}
              >
                {item.icon} {item.label}
              </MenuItem>
            ))}
        </Menu>
      )}
      {mobileView && (
        <ManageJobPostsModal
          isOpen={currentModal === 'JOB-POST-ACTIONS-MODAL'}
          onClose={() => toggleModal('JOB-POST-ACTIONS-MODAL')}
          jobpostMenuItems={jobpostMenuItems}
        />
      )}
    </>
  );
};

export default JobPostSidebarMoreMenu;

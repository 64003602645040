import { useMediaQuery, SxProps, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import UploadedFileIconElement from '../../../../components/Auth/Elements/UploadedFileIconElement';
import PhotosIcon from '../../../../components/Icons/PhotosIcon';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import DragAndDropBox from '../../../../components/MUIComponents/DragAndDropBox/DragAndDropBox';
import Loader from '../../../../components/MUIComponents/Loader';
import { ElementIdEnum } from '../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { triggerFileInput } from '../../../../utils/helper/triggerInputHelper';
import Cropper from '../Cropper';
import { useUpdateBannerMutation } from '../../../../store/apiSlice/userApi';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';

const MIN_WIDTH = 1500;

interface CropBackgroundModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const CropBackgroundModal = ({
  onClose,
  isOpen = false,
}: CropBackgroundModalProps) => {
  const imageSizeHint = `Image width should be at least ${MIN_WIDTH} pixels`;
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('lg'));
  const [imgSrc, setImgSrc] = useState('');
  const [finalCroppedDataUrl, setFinalCroppedDataUrl] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  // eslint-disable-next-line
  const [dragOver, setDragOver] = useState(false);

  const [updateBanner, { isLoading: isUpdatingBanner }] =
    useUpdateBannerMutation();

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const addFile = async (file: File) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || '';
      imageElement.src = imageUrl;

      imageElement.addEventListener('load', e => {
        const { naturalWidth } = e.currentTarget as HTMLImageElement;
        if (naturalWidth < MIN_WIDTH) {
          setSnackbarOpen(true);
          setSnackbarMessage(imageSizeHint);
          setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
          return setImgSrc('');
        }
      });
      setImgSrc(reader.result?.toString() || '');
    });
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files[0]);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFile(event.dataTransfer.files[0]);
    }
  };

  const boxSx: SxProps = {
    width: { xs: '100%', lg: '875px !important' },
    maxHeight: { xs: '80vh', lg: '90dvh' },
    p: { xs: '20px 12px 12px', lg: '36px 36px 48px' },
    position: { xs: 'fixed', lg: 'absolute' },
    top: { lg: '50%' },
    left: { lg: '50%' },
    bottom: { xs: 0, lg: 'unset' },
    borderRadius: { lg: '0 !important' },
    transform: { lg: 'translate(-50%, -50%)' },
    '.content': { m: '48px 0 36px' },
  };

  const updateBackground = (dataUrl: string) => {
    setFinalCroppedDataUrl(dataUrl);
  };

  const closeModal = () => {
    setImgSrc('');
    onClose();
  };

  const onAccept = async () => {
    try {
      const response = await fetch(finalCroppedDataUrl);
      const blob = await response.blob();

      const file = new File([blob], 'croppedImage.png', { type: 'image/png' });
      const formData = new FormData();
      formData.append('file', file);
      await updateBanner(formData).unwrap();
      closeModal();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(`Error uploading cropped image: ${error}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Error uploading cropped image:', error);
    }
  };

  const mainContent = !imgSrc ? (
    <DragAndDropBox
      handleDragOver={handleDragOver}
      handleDragLeave={handleDragLeave}
      handleDrop={handleDrop}
      handleFileChange={handleFileChange}
      triggerFileInput={() => triggerFileInput(ElementIdEnum.ADD_PHOTOS_MODAL)}
      fileInputId={ElementIdEnum.ADD_PHOTOS_MODAL}
      text={
        <Fragment>
          <Typography
            marginBottom={'2px'}
            variant="body4"
            color={'text.primary'}
          >
            {imageSizeHint}
            <br />
          </Typography>
          Drag and drop your photos here
        </Fragment>
      }
      icon={
        <UploadedFileIconElement>
          <PhotosIcon active />
        </UploadedFileIconElement>
      }
      multipleInput={false}
    />
  ) : (
    <Cropper updateBackground={updateBackground} imgSrc={imgSrc} />
  );

  return (
    <BaseModal
      boxSx={{ ...boxSx }}
      disableEscapeButton
      disableContentMargin
      isOpen={isOpen}
      toggle={closeModal}
      onAccept={onAccept}
      footer={{
        okText: 'publish',
        declineText: 'cancel',
        styles: {
          gap: 'unset',
        },
        declineButtonStyles: {
          flex: 'unset !important',
          borderColor: theme.palette.background.darker,
          minWidth: tabletAndBigger ? '220px' : 'unset',
          borderRadius: 0,
        },
        okButtonStyles: {
          flex: 'unset !important',
          minWidth: tabletAndBigger ? '220px' : 'unset',
          borderRadius: 0,
        },
        isOkButtonDisabled: !imgSrc || isUpdatingBanner ? true : false,
      }}
      header={{ text: 'Crop Background' }}
    >
      {isUpdatingBanner ? <Loader color="primary" /> : mainContent}
      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default CropBackgroundModal;

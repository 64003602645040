import { Divider, Typography } from '@mui/material';

const NotificationsTab = () => {
  return (
    <>
      <Typography
        component="h2"
        variant="h2"
        mb={'24px'}
        color={'text.primary'}
      >
        Notifications
      </Typography>
      <Divider sx={{ bgcolor: 'background.darker' }} />
      🔔
    </>
  );
};

export default NotificationsTab;

import { Avatar, Box, Skeleton, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useGetMyProfileQuery } from '../../../../store/apiSlice/userApi';

const ConnectionsSidebarMenu = () => {
  const { data: profileInfo, isLoading: isProfileInfoLoading } =
    useGetMyProfileQuery();

  const userNameField =
    profileInfo?.firstName || profileInfo?.lastName
      ? `${profileInfo?.firstName || ''}  ${profileInfo?.lastName || ''}`
      : profileInfo?.email;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      bgcolor={'background.white'}
      sx={{
        minWidth: 227,
        width: 290,
        borderRadius: '1px',
        p: '36px 22px',
        gap: '28px',
      }}
    >
      {isProfileInfoLoading ? (
        skeletonSidebarAvatar
      ) : (
        <Avatar
          alt={userNameField}
          src={profileInfo?.avatar?.fileUrl || ''}
          sx={{ width: 140, height: 140 }}
        />
      )}

      {isProfileInfoLoading ? (
        skeletonSidebarTitle
      ) : (
        <Typography component="h3" variant="h3" color={'text.primary'}>
          {userNameField}
        </Typography>
      )}

      <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
        <Box component={Link} to="/" sx={{ width: '100%' }}>
          <CustomButton
            buttonWidth="100%"
            variant="contained"
            variantType={ButtonTypeEnum.PRIMARY}
            fullWidth
            disableElevation
          >
            View Profile
          </CustomButton>
        </Box>
        <Box component={Link} to="/profile" sx={{ width: '100%' }}>
          <CustomButton
            buttonWidth={'100%'}
            variantType={ButtonTypeEnum.SECONDARY}
            fullWidth
            variant="outlined"
          >
            Edit Profile
          </CustomButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default ConnectionsSidebarMenu;

const skeletonSidebarTitle = (
  <Skeleton
    sx={{
      width: '90%',
      height: '2.5rem',
      borderRadius: '0.375rem',
    }}
  />
);

const skeletonSidebarAvatar = (
  <Skeleton
    variant="circular"
    sx={{
      width: 140,
      height: 140,
    }}
  />
);

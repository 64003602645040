import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  IUserCompanyStructureRequest,
  IUserCompanyStructureResponse,
} from '../../interfaces/user-company-structure.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const userCompanyStructureApi = createApi({
  reducerPath: 'userCompanyStructureApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/user-company-structure`
  ),
  tagTypes: ['userCompanyStructure'],

  endpoints: builder => ({
    createOrUpdateCompanyStructure: builder.mutation<
      IUserCompanyStructureResponse,
      IUserCompanyStructureRequest
    >({
      query: (data: IUserCompanyStructureRequest) => ({
        url: `/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['userCompanyStructure'],
    }),
  }),
});

export const { useCreateOrUpdateCompanyStructureMutation } =
  userCompanyStructureApi;

export default userCompanyStructureApi;

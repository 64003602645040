import {
  Avatar,
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApprovedProfileIcon from '../../../../../components/Icons/ApprovedProfileIcon';
import LocationIcon from '../../../../../components/Icons/LocationIcon';
import { ChatStateEnum } from '../../../../../enums/chat-enum';
import { CookieEnum } from '../../../../../enums/cookie-enum';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import { useModalManager } from '../../../../../hooks/useModalManager';
import { IUserSportBackgroundWithSportAndExperience } from '../../../../../interfaces/user-sport-background.interface';
import {
  useCreateChatMutation,
  useGetAllMyChatsQuery,
} from '../../../../../store/apiSlice/chat/chatApi';
import {
  setChatState,
  setSelectedChat,
} from '../../../../../store/apiSlice/chat/chatSlice';
import { useGetUserInformationQuery } from '../../../../../store/apiSlice/userApi';
import { selectAuthData } from '../../../../../store/selectors/authSelector';
import { colors } from '../../../../../theme/theme';
import { RequestIntroductionModal } from '../modals';

const ProfileCard = () => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  // to do: create custom hook for this
  const authData = useSelector(selectAuthData);
  const isCompany = useMemo(
    () =>
      authData?.user?.role === 'COMPANY' ||
      Cookies.get(CookieEnum.ROLE) === 'COMPANY',
    [authData?.user?.role]
  );
  // ************
  const { currentModal, toggleModal } = useModalManager();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: myData, isLoading } = useGetUserInformationQuery(userId || '');
  const { data: myChats } = useGetAllMyChatsQuery();
  const [createChat, { isLoading: isCreatingChat }] = useCreateChatMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.introductionId) {
      toggleModal('REQUEST_INTODUCTION_MODAL');
    }
  }, [state, toggleModal]);

  const onEditProfileClick = useCallback(
    () => navigate('/profile'),
    [navigate]
  );

  const handleMessage = useCallback(
    (friendUserId: string | undefined) => {
      if (!friendUserId) {
        return;
      }

      return () => {
        dispatch(setChatState(ChatStateEnum.CHAT));

        if (myChats)
          for (const chat of myChats)
            if (chat.withUserId === friendUserId) {
              dispatch(setSelectedChat(chat));
              return navigate('/chat');
            }

        createChat(friendUserId)
          .unwrap()
          .then(chat => {
            dispatch(setSelectedChat(chat));
            navigate('/chat');
          })
          .catch(console.log);
      };
    },
    [myChats, createChat, dispatch, navigate]
  );

  const formatExperienceYears = useCallback(
    (experienceYears: number): string => {
      if (!experienceYears || experienceYears <= 0) {
        return '1';
      }

      return experienceYears >= 1 ? `${experienceYears.toFixed(0)}` : '1';
    },
    []
  );

  const aggregateSportExperience = useCallback(
    (sportBackgrounds: IUserSportBackgroundWithSportAndExperience[]) => {
      const sportMap: Record<string, any> = {};

      sportBackgrounds.forEach(background => {
        const { sportId, experienceYears } = background;

        if (sportMap[sportId]) {
          sportMap[sportId].experienceYears += experienceYears;
        } else {
          sportMap[sportId] = { ...background };
        }
      });

      return Object.values(sportMap);
    },
    []
  );

  const aggregatedSports = useMemo(() => {
    return aggregateSportExperience(myData?.userSportBackgrounds || []);
  }, [myData?.userSportBackgrounds, aggregateSportExperience]);

  return (
    <>
      {currentModal === 'REQUEST_INTODUCTION_MODAL' && (
        <RequestIntroductionModal
          isOpen
          onClose={() => toggleModal('REQUEST_INTODUCTION_MODAL')}
          introductionId={state?.introductionId}
          senderFirstName={state?.senderFirstName}
          senderLastName={state?.senderLastName}
        />
      )}
      <Box
        sx={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: { xs: '0 16px 16px', lg: 0 },
        }}
      >
        {isLoading ? (
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              minHeight: {
                xs: '310px',
                lg: '420px',
              },
              marginTop: {
                xs: '40px',
                lg: '0px',
              },
            }}
          />
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={mobileView ? 'row' : 'column'}
              alignItems="center"
              sx={
                mobileView
                  ? {
                      columnGap: '20px',
                      width: '100%',
                      position: 'absolute',
                      top: '-25px',
                      left: '0',
                      padding: '0 22px',
                    }
                  : {}
              }
            >
              <Avatar
                alt="Profile"
                src={myData?.avatar?.fileUrl || ''}
                sx={{
                  [theme.breakpoints.up('xs')]: { width: 100, height: 100 },
                  [theme.breakpoints.up('lg')]: { width: 140, height: 140 },
                  border: `2px solid ${theme.palette.background.white}`,
                }}
              />
              <Box
                className="profile-name-location-container"
                m={mobileView ? '35px 0 0' : '28px 0'}
              >
                <Typography
                  variant="h3"
                  color={colors.fontDark}
                  textAlign={{ xs: 'left', lg: 'center' }}
                >
                  {`${myData?.firstName || ''} ${myData?.lastName || ''}`}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    marginTop: '0.375rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '0.375rem',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {myData?.country || myData?.city ? (
                    <LocationIcon
                      width={'16px'}
                      height={'16px'}
                      viewBox="0 0 24 24"
                      sx={{
                        width: '16px',
                        height: '16px',
                        viewBox: '0 0 16px 16px',
                        fill: 'none',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {myData?.country ? `${myData?.country},` : ''}{' '}
                  {myData?.city ? myData?.city : ''}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              width={'100%'}
              gap={'12px'}
              mb="36px"
              sx={
                mobileView
                  ? {
                      marginTop: '100px',
                    }
                  : {}
              }
            >
              {aggregatedSports.map(background => (
                <Box
                  key={background.id}
                  display="flex"
                  flexDirection="row"
                  gap={'12px'}
                  width={'100%'}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'12px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    padding={'16px'}
                    sx={{ bgcolor: 'background.light' }}
                    width={{ xs: '100%', lg: '96px', xl: '100%' }}
                    textAlign={'center'}
                  >
                    <Avatar
                      alt={background.sport.name}
                      src={background.sport.fileUrl || ''}
                      sx={{ width: 50, height: 50 }}
                    />
                    <Typography
                      variant={'h4'}
                      sx={{ fontWeight: 500, color: 'text.primary' }}
                    >
                      {background.sport.name}
                    </Typography>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'12px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    padding={'16px'}
                    sx={{ bgcolor: 'background.light' }}
                    width={{ xs: '100%', lg: '96px', xl: '100%' }}
                  >
                    <Box
                      sx={{
                        width: '50px',
                        height: '50px',
                        fontSize: '30px',
                        color: 'text.primary',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography fontSize={26} color={'text.primary'}>
                        {formatExperienceYears(background.experienceYears)}
                      </Typography>
                    </Box>
                    <Typography
                      variant={'h4'}
                      sx={{ fontWeight: 500, color: 'text.primary' }}
                    >
                      Experience
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box className="profile-actions" width="100%">
              {isOwnPage && (
                <>
                  <Button
                    onClick={onEditProfileClick}
                    variant="outlined"
                    fullWidth
                    sx={{
                      height: '50px',
                      color: 'text.primary',
                      fontSize: '12px',
                      borderRadius: '4px',
                      borderColor: '#434E5F',
                    }}
                  >
                    Edit Profile
                  </Button>
                </>
              )}
              {!isOwnPage && (
                <>
                  <Stack direction="row" spacing="12px" mb="12px">
                    <Box
                      className="approved-profile-btn-box"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '50px',
                        backgroundColor: colors.dark05,
                      }}
                    >
                      <ApprovedProfileIcon width={'50px'} height={'50px'} />
                    </Box>
                    <Button
                      disabled={isCreatingChat}
                      onClick={handleMessage(userId)}
                      variant="contained"
                      sx={{
                        flex: 1,
                        height: 50,
                        color: 'white',
                        fontSize: '12px',
                        marginBottom: '12px',
                        backgroundColor: '#F5431D',
                        borderRadius: '4px',
                      }}
                    >
                      send message
                    </Button>
                  </Stack>
                  {!isCompany && (
                    <Button
                      onClick={() => toggleModal('REQUEST_INTODUCTION_MODAL')}
                      variant="outlined"
                      fullWidth
                      sx={{
                        height: '50px',
                        color: 'text.primary',
                        fontSize: '12px',
                        borderRadius: '4px',
                        borderColor: '#434E5F',
                      }}
                    >
                      request introduction
                    </Button>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ProfileCard;

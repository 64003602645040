import { Box, IconButton, Typography } from '@mui/material';
import FlagIcon from '../../../Icons/FlagIcon';
import CustomButton from '../../../MUIComponents/CustomButton';
import CloseX from '../../../Icons/CloseX';

interface IWelcomeToPlatformProps {
  onClose: () => void;
  onProceed: () => void;
  text: string;
  textLastLine: string;
  title: string;
  buttonText: string;
}

const WelcomeToPlatform = ({
  onClose,
  text,
  title,
  buttonText,
  onProceed,
  textLastLine,
}: IWelcomeToPlatformProps) => {
  return (
    <Box
      width={'100%'}
      maxWidth={'875px'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      textAlign={'center'}
      alignItems={'center'}
      bgcolor={'background.white'}
      padding={'48px'}
      position={'relative'}
    >
      <IconButton
        onClick={onClose}
        sx={{
          p: '0.75rem',
          borderRadius: '50%',
          position: 'absolute',
          bgcolor: '#F6F7F8',
          top: '1rem',
          right: '1rem',
        }}
      >
        <CloseX />
      </IconButton>
      <Box
        display={'flex'}
        maxWidth={'120px'}
        maxHeight={'120px'}
        borderRadius={'50%'}
      >
        <FlagIcon />
      </Box>
      <Box
        display={'flex'}
        marginTop={'36px'}
        gap={'36px'}
        flexDirection={'column'}
        maxWidth={'40.8125rem'}
      >
        <Typography variant={'h2'}>{title}</Typography>
        <Typography variant={'body2'}>
          {text}
          <br />
          <br />
          {textLastLine}
        </Typography>
      </Box>
      <Box display={'flex'} marginTop={'48px'}>
        <CustomButton
          buttonWidth="100%"
          variant="contained"
          color="primary"
          style={{ minWidth: '13.75rem' }}
          fullWidth
          onClick={onProceed}
        >
          {buttonText}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default WelcomeToPlatform;

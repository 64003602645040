import { Box, Collapse, Typography } from '@mui/material';
import { ReactNode, useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

interface ICollapsedContentProps {
  children: ReactNode;
  isExpandable?: boolean;
  collapsedSize?: number;
}

const CollapsedContent = ({
  children,
  isExpandable = true,
  collapsedSize = 100,
}: ICollapsedContentProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [adjustedCollapsedSize, setAdjustedCollapsedSize] =
    useState<number>(collapsedSize);
  const contentRef = useRef<HTMLDivElement>(null);

  const checkOverflow = useCallback(() => {
    if (contentRef.current) {
      const { scrollHeight } = contentRef.current;

      if (scrollHeight > collapsedSize) {
        setIsOverflowing(true);
        setAdjustedCollapsedSize(collapsedSize);
      } else {
        setIsOverflowing(false);
        setAdjustedCollapsedSize(scrollHeight);
      }
    }
  }, [collapsedSize]);

  useEffect(() => {
    const images = contentRef.current?.querySelectorAll('img');

    const handleImageLoad = () => {
      checkOverflow();
    };

    images?.forEach(img => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
      }
    });

    window.addEventListener('resize', checkOverflow);

    checkOverflow();

    return () => {
      images?.forEach(img => {
        img.removeEventListener('load', handleImageLoad);
      });
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow]);

  return (
    <>
      <Collapse
        in={showDetails}
        collapsedSize={adjustedCollapsedSize}
        sx={{
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '40px',
            background:
              showDetails || !isOverflowing
                ? 'none'
                : 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.9))',
            pointerEvents: 'none',
          },
        }}
      >
        <Box ref={contentRef} sx={{ padding: '10px 0' }}>
          {children}
        </Box>
      </Collapse>
      {isExpandable && isOverflowing && (
        <Box pb={'28px'}>
          <Link to="#" onClick={() => setShowDetails(prev => !prev)}>
            <Box display="flex" alignItems="center">
              <ArrowDownIcon
                width={18}
                height={18}
                sx={{
                  transform: showDetails ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
              />
              <Typography variant="caption">
                {showDetails ? 'hide details' : 'show details'}
              </Typography>
            </Box>
          </Link>
        </Box>
      )}
    </>
  );
};

export default CollapsedContent;

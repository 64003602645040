import React from 'react';
import cn from 'classnames';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EmptyFileIcon = (svgProps?: SvgIconProps) => {
  return (
    <SvgIcon {...svgProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        fill="none"
        viewBox="0 0 24 25"
      >
        <g strokeLinecap="square" strokeWidth="1.5">
          <path d="M14 3.485v5h5"></path>
          <path d="M5 21.485h14V8.206l-4.545-4.721H5v18z"></path>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default EmptyFileIcon;

import { Box, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import SenderInfo from '../ChatHeader/SenderInfo';
import SearchContacts from './SearchContacts';
import { ChatStateEnum, ChatTypeEnum } from '../../../../enums/chat-enum';
import { useGetFriendsByUserIdQuery } from '../../../../store/apiSlice/friendApi';
import {
  useCreateChatMutation,
  useGetAllMyChatsQuery,
} from '../../../../store/apiSlice/chat/chatApi';
import { useDispatch } from 'react-redux';
import {
  setChatsLoaded,
  setChatState,
  setChatType,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';

const NewPersonalChat = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { data: myFriends } = useGetFriendsByUserIdQuery();
  const { data: myChats } = useGetAllMyChatsQuery();
  const [createChat] = useCreateChatMutation();
  const dispatch = useDispatch();

  const filteredFriends = useMemo(
    () =>
      myFriends?.friends.filter(friend => {
        const firstName = friend.firstName?.toLocaleLowerCase();
        const lastName = friend.lastName?.toLocaleLowerCase();

        return [firstName, lastName].some(item =>
          item?.includes(searchValue.toLocaleLowerCase())
        );
      }),
    [myFriends, searchValue]
  );

  const handleCreateChat = useCallback(
    (withUserId: string) => async () => {
      try {
        const existingChat = myChats?.find(
          chat => chat.withUserId === withUserId
        );
        if (existingChat) {
          dispatch(setSelectedChat(existingChat));
        } else {
          const chat = await createChat(withUserId).unwrap();
          dispatch(setSelectedChat(chat));
        }
        setSearchValue('');
        dispatch(setChatType(ChatTypeEnum.PERSONAL));
        dispatch(setChatState(ChatStateEnum.CHAT));
        dispatch(setChatsLoaded());
      } catch (error) {}
    },
    [myChats, dispatch, createChat]
  );

  return (
    <>
      <SearchContacts
        onSearch={(e: ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        borderBottom={'1px solid'}
        borderColor={'background.dark'}
        flexGrow={1}
        sx={{ overflow: 'scroll', scrollbarWidth: 'none' }}
      >
        <Box
          p={'1.75rem 1rem'}
          gap={'1.75rem'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Typography variant="body3" fontWeight={700} color={'text.primary'}>
            Suggestions for you
          </Typography>
          <Box gap={'0.625rem'} display={'flex'} flexDirection={'column'}>
            {filteredFriends?.map(friend => (
              <Box
                onClick={handleCreateChat(friend.userId)}
                key={friend.userId}
                sx={{
                  '&:hover': { bgcolor: 'background.light', cursor: 'pointer' },
                }}
                p={'0.375rem'}
              >
                <SenderInfo
                  firstname={friend.firstName!}
                  lastname={friend.lastName!}
                  avatar={friend.avatarUrl!}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewPersonalChat;

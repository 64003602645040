import React from 'react';
import { Box, Input, Typography } from '@mui/material';

interface IProps {
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave: () => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  triggerFileInput: () => void;
  fileInputId: string;
  text?: string | React.ReactNode;
  icon: React.ReactNode;
  isVideoInput?: boolean;
  multipleInput?: boolean;
}
const DragAndDropBox = (props: IProps) => {
  const {
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleFileChange,
    triggerFileInput,
    fileInputId,
    text,
    icon,
    isVideoInput,
    multipleInput = true,
  } = props;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      padding={'60px 0'}
      bgcolor={'background.light'}
      border={`1px dashed`}
      borderColor={'background.dark'}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'36px'}>
        <Box display={'flex'} width={'100%'} justifyContent={'center'}>
          {icon}
        </Box>
        <Box
          display="flex"
          flexDirection="column-reverse"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          maxWidth="530px"
          margin="0 auto"
          gap={'22px'}
        >
          <Typography variant="body4" color={'text.secondary'} fontWeight={500}>
            {text}
          </Typography>
          <Typography variant="body4" textAlign="center" component="div">
            Drag & Drop files here or{' '}
            <Box
              component={'span'}
              typography={'body4'}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              color={'text.accent'}
              onClick={triggerFileInput}
            >
              choose files
            </Box>{' '}
            to upload
          </Typography>
          <Input
            type="file"
            id={fileInputId}
            sx={{ display: 'none' }}
            onChange={handleFileChange}
            inputProps={{
              multiple: multipleInput,
              accept: isVideoInput ? 'video/*' : 'image/*,application/pdf',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DragAndDropBox;

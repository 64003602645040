import {
  Box,
  Button,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ChatStateEnum } from '../../../../enums/chat-enum';
import SenderInfo from './SenderInfo';
import DotsVerticalIcon from '../../../../components/Icons/DotsVerticalIcon';
import LeftArrowIcon from '../../../../components/Icons/LeftArrowIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatsLoaded,
  getChatState,
  getSelectedChat,
  setChatState,
} from '../../../../store/apiSlice/chat/chatSlice';
import { useRef, useState } from 'react';
import ChatActionsMenu from './ChatActions/ChatActionsMenu';
import { isGroupChat } from '../ChatNavigation/Chat';

const ChatHeader = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const threeDotsRef = useRef<HTMLDivElement>(null);

  const selectedChat = useSelector(getSelectedChat)!;
  const chatState = useSelector(getChatState);
  const chatsLoaded = useSelector(getChatsLoaded);
  const dispatch = useDispatch();

  return (
    <Box
      display={'flex'}
      p={mobileView ? '15px 1rem' : '1rem'}
      borderBottom={'1px solid'}
      borderColor={'background.dark'}
    >
      {mobileView && (
        <Button
          onClick={() => {
            dispatch(setChatState(ChatStateEnum.ALL_CHATS));
          }}
          sx={{
            minWidth: 'unset',
            padding: '1rem 0.75rem',
          }}
        >
          <LeftArrowIcon />
        </Button>
      )}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexGrow={'1'}
      >
        {chatState === ChatStateEnum.CHAT ? (
          chatsLoaded &&
          selectedChat && (
            <>
              <SenderInfo
                firstname={
                  isGroupChat(selectedChat)
                    ? selectedChat.name
                    : selectedChat.withUser?.firstName
                }
                lastname={
                  isGroupChat(selectedChat)
                    ? ''
                    : selectedChat.withUser?.lastName
                }
                avatar={
                  isGroupChat(selectedChat)
                    ? selectedChat.thumb || undefined
                    : selectedChat.withUser?.thumbnail
                }
              />
              <Box
                onClick={() => setMenuOpen(true)}
                sx={{ '&:hover': { cursor: 'pointer' } }}
                ref={threeDotsRef}
              >
                <DotsVerticalIcon />
              </Box>
            </>
          )
        ) : (
          <Box
            p={'0.575rem 0'}
            display={'flex'}
            gap={'1.375rem'}
            alignItems={'center'}
          >
            {!mobileView && selectedChat && (
              <Box
                onClick={() => dispatch(setChatState(ChatStateEnum.CHAT))}
                sx={{ '&:hover': { cursor: 'pointer' } }}
              >
                <LeftArrowIcon />
              </Box>
            )}
            <Typography variant="body1">
              {chatState === ChatStateEnum.NEW_PERSONAL
                ? 'New message'
                : 'New group chat'}
            </Typography>
          </Box>
        )}
      </Box>

      <Popper
        open={menuOpen}
        anchorEl={threeDotsRef.current}
        placement="bottom-end"
        disablePortal
        sx={{ zIndex: 1 }}
      >
        <ChatActionsMenu onClose={() => setMenuOpen(false)} />
      </Popper>
    </Box>
  );
};

export default ChatHeader;

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useMemo } from 'react';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import { useGetMyAppliedByIdQuery } from '../../../../store/apiSlice/jobpost/jobUserApplyApi';
import Loader from '../../../../components/MUIComponents/Loader';
import { RejectReasonReasonEnum } from '../../../../enums/reject-reason-type-enum';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { Add } from '@mui/icons-material';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface ApplyJobModalProps {
  isOpen?: boolean;
  onClose: () => void;
  id?: string;
}

const RejectReasonJobApplyModal = ({
  onClose,
  isOpen = false,
  id,
}: ApplyJobModalProps) => {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const {
    data: jobPostApplyData,
    isLoading,
    error,
  } = useGetMyAppliedByIdQuery(id);

  function getRejectReasonText(
    reason: RejectReasonReasonEnum | null | undefined
  ): string {
    if (reason == null) return 'Unknown reason';

    switch (reason) {
      case RejectReasonReasonEnum.NOT_QUALIFIED:
        return 'Not qualified';
      case RejectReasonReasonEnum.OVERQUALIFIED:
        return 'Overqualified';
      case RejectReasonReasonEnum.POOR_FIT:
        return 'Poor fit for the role';
      case RejectReasonReasonEnum.BUDGET_CONSTRAINTS:
        return 'Budget constraints';
      case RejectReasonReasonEnum.POSITION_FILLED:
        return 'Position already filled';
      case RejectReasonReasonEnum.OTHER:
        return 'Other';
      default:
        return 'Unknown reason';
    }
  }

  const modalTitle = useMemo(
    () => (
      <Typography
        variant="h2"
        textAlign="center"
        fontSize={'26px'}
        fontWeight={600}
      >
        Reject Job Application
      </Typography>
    ),
    []
  );

  const viewJobApplication = () => {
    navigate(`/me/job-application/${id}`);
    onClose();
  };

  return (
    <BaseModal
      modalSx={{ borderColor: 'transparent !important' }}
      header={{ component: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      disableDefaultFooter
    >
      {isLoading ? (
        <Loader color="primary" />
      ) : error ? (
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          <Typography variant="body2" color={'#E92726'}>
            {`Error: ${errorHelper(error) || 'Failed to load job application data'}`}
          </Typography>
        </Box>
      ) : (
        <Stack gap={'36px'}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            flexWrap={'wrap'}
            p={'16px'}
            border={'1px solid #D1D6DD'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'row'}
              gap={'16px'}
              maxWidth={'100%'}
            >
              <ListItemAvatar
                sx={{
                  width: '64px',
                  height: '64px',
                  minWidth: '64px',
                  minHeight: '64px',
                }}
              >
                <Avatar
                  alt={`${jobPostApplyData?.appliedUser?.jobpost?.company?.companyInfo?.companyName ?? jobPostApplyData?.appliedUser?.jobpost?.company?.email} `}
                  src={
                    jobPostApplyData?.appliedUser?.jobpost?.company
                      ?.optimizedUserAvatarBaseUrl
                      ? `data:image/jpeg;base64,${jobPostApplyData?.appliedUser?.jobpost?.company?.optimizedUserAvatarBaseUrl}`
                      : ''
                  }
                  sx={{ width: '100%', height: '100%' }}
                />
              </ListItemAvatar>
              <ListItemText
                key={jobPostApplyData?.appliedUser?.jobpost?.company?.id}
                sx={{
                  color: 'text.primary',
                  maxWidth: '350px',
                }}
                primary={`${jobPostApplyData?.appliedUser?.jobpost?.company?.companyInfo?.companyName ?? jobPostApplyData?.appliedUser?.jobpost?.company?.email} `}
                primaryTypographyProps={{
                  sx: {
                    fontSize: {
                      xs: '14px',
                      md: '14px',
                      lg: '18px',
                    },
                  },
                }}
                secondaryTypographyProps={{
                  sx: {
                    textAlign: 'left',
                    lineHeight: '15px',
                    fontSize: '14px',
                  },
                }}
              />
            </Box>
            {mobileView ? (
              <IconButton
                disabled={isLoading}
                sx={{
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: 'background.lightDark',
                  height: '40px',
                  width: '40px',
                  minHeight: '40px',
                  minWidth: '40px',
                }}
                type="submit"
                onClick={viewJobApplication}
              >
                <Add />
              </IconButton>
            ) : (
              <CustomButton
                variant="outlined"
                variantType={ButtonTypeEnum.SECONDARY}
                sx={{ p: '15px 16px' }}
                onClick={viewJobApplication}
              >
                View Job Application
              </CustomButton>
            )}
          </Stack>

          {jobPostApplyData?.appliedUser?.jobpost?.title && (
            <Box>
              <Typography
                component={'h4'}
                variant="badge2"
                mb={'22px'}
                textTransform={'uppercase'}
              >
                Job Title
              </Typography>
              <Typography variant="body3" textAlign={'justify'}>
                {jobPostApplyData?.appliedUser?.jobpost?.title}
              </Typography>
            </Box>
          )}

          <Box>
            <Typography
              component={'h4'}
              variant="badge2"
              mb={'22px'}
              textTransform={'uppercase'}
            >
              Rejection Reason
            </Typography>
            <Typography variant="body3" textAlign={'justify'}>
              {getRejectReasonText(
                jobPostApplyData?.appliedUser?.rejectedReason
              )}
            </Typography>
          </Box>

          {jobPostApplyData?.appliedUser?.rejectedNote && (
            <>
              <Divider />
              <Box>
                <Typography
                  component={'h4'}
                  variant="badge2"
                  mb={'22px'}
                  textTransform={'uppercase'}
                >
                  Notes
                </Typography>
                <Typography variant="body3" textAlign={'justify'}>
                  {jobPostApplyData?.appliedUser?.rejectedNote}
                </Typography>
              </Box>
            </>
          )}
        </Stack>
      )}
    </BaseModal>
  );
};

export default RejectReasonJobApplyModal;

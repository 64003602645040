import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import SubscriptionPlanCard from '../../../pages/subscription/components/SubscriptionPlanCard';
import CustomButton from '../../MUIComponents/CustomButton';
import {
  useDeleteSubscriptionMutation,
  useGetMyCompanySubscriptionQuery,
} from '../../../store/apiSlice/subscription/subscriptionApi';
import { useNavigate } from 'react-router-dom';
import { getIncludedFeaturesHelper } from '../../../utils/helper/subscription-helper';
import { useState } from 'react';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const MyCompanySubscriptionSettingContainer = () => {
  const { theme } = useThemeContext();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    data,
    isLoading: isFetching,
    isError,
  } = useGetMyCompanySubscriptionQuery();
  const [deleteSubscription, { isLoading: isDeletingLoading }] =
    useDeleteSubscriptionMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const navigate = useNavigate();
  const [isChangingPlan, setIsChangingPlan] = useState(false);

  if (
    !data ||
    !data.hasOwnProperty('subscription') ||
    !data.hasOwnProperty('products') ||
    !data.products.length
  ) {
    return null;
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangeButtonClick = () => {
    setIsChangingPlan(true);
    navigate('/subscription-plans');
    setIsChangingPlan(false);
  };

  const handleDeleteButtonClick = async () => {
    try {
      await deleteSubscription(data.subscription.id).unwrap();
      setSnackbarMessage('Subscription successfully cancelled');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
      window.location.reload();
    } catch (error) {
      setSnackbarMessage('Failed to cancel subscription.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const formatDateForUS = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  if (isError) {
    return <Typography color="error">Failed to load subscription.</Typography>;
  }

  if (isFetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <>
      <SubscriptionPlanCard
        sx={{
          width: '100%',
          maxWidth: 'unset',
          '& > article': { p: '28px 22px' },
        }}
        key={data.products[0].product.id}
        planName={data.products[0].product.name}
        price={`${data.products[0].product.price}`}
        pricePlanAudience={data.products[0].product.description}
        isMostPopular={data.products[0].product.isMostPopular}
        period={`${
          data.products[0].product.interval === 'year'
            ? 'annual'
            : data.products[0].product.interval === 'month' &&
                data.products[0].product.intervalCount === 6
              ? 'semi-annual'
              : 'quarterly'
        }`}
        isSelected
        nextPayment={formatDateForUS(data.subscription.currentPeriodEnd)}
        includedFeatures={getIncludedFeaturesHelper(
          data.products[0].product.name
        )}
        isFooterEnabled={false}
      />
      <Stack
        direction={isMediumScreen ? 'column' : 'row'}
        gap={'16px'}
        justifyContent="space-between"
        mt="36px"
      >
        <CustomButton
          buttonWidth={isMediumScreen ? '100%' : '220px'}
          color="primary"
          onClick={handleChangeButtonClick}
          disabled={isDeletingLoading || isChangingPlan}
        >
          {isChangingPlan ? 'Changing...' : 'Change Plan'}
        </CustomButton>
        <CustomButton
          buttonWidth={isMediumScreen ? '100%' : '220px'}
          variantType={ButtonTypeEnum.SECONDARY}
          color="primary"
          onClick={handleDeleteButtonClick}
          disabled={isDeletingLoading || isChangingPlan}
        >
          {isDeletingLoading ? 'Deleting...' : 'Cancel Subscription'}
        </CustomButton>
      </Stack>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

const ManageSubscriptionsTab = () => {
  return (
    <>
      <Typography
        component="h2"
        variant="h2"
        mb={'24px'}
        color={'text.primary'}
      >
        Your subscription
      </Typography>

      <MyCompanySubscriptionSettingContainer />
    </>
  );
};

export default ManageSubscriptionsTab;

import { Avatar, Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import QuiltedImageList from '../../../../components/MUIComponents/QuiltedImageList';
import { useGetPostByIdQuery } from '../../../../store/apiSlice/post/postApi';
import { useGetUserInfoQuery } from '../../../../store/apiSlice/userApi';
import { formatDate } from '../../../../utils/date-time-formats.ts/formatDate';
import { removeHashtagWords } from '../../../../utils/helper/removeHashtagWords';

interface IRepostedPostProps {
  repostedPostId: string;
}
const RepostedPost = (props: IRepostedPostProps) => {
  const { repostedPostId } = props;
  const { data: post } = useGetPostByIdQuery(repostedPostId);

  const { data: userData } = useGetUserInfoQuery(post?.userId as string);

  const filesArray = post?.postFiles.map((file, idx) => {
    const fileObj = { id: file.id, img: file.fileUrl, title: file.fileName };
    if (idx === 4 || idx === 5 || idx === 11) return { ...fileObj, cols: 2 };
    if (idx === 8 || idx === 5) return { ...fileObj, rows: 2, cols: 2 };
    return fileObj;
  });

  if (post)
    return (
      <Container
        disableGutters
        sx={{ padding: '16px', bgcolor: 'background.light' }}
      >
        <Box component="header" mb={2} color="#142237">
          <Grid container columnSpacing={2}>
            <Grid
              item
              component={Link}
              to={`/${userData?.id}`}
              color={'inherit'}
            >
              <Avatar
                alt="Profile"
                src={
                  userData && userData?.optimizedUserAvatarBaseUrl
                    ? `data:image/jpeg;base64,${userData.optimizedUserAvatarBaseUrl}`
                    : ''
                }
                sx={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid
              item
              flex={1}
              component={Link}
              to={`/${userData?.id}`}
              color={'inherit'}
            >
              <Typography variant="body1">
                {`${userData?.firstName || ''} ${userData?.lastName || ''}`}
              </Typography>
              <Typography variant="caption" color={'text.secondary'}>
                {formatDate(new Date(post.createdAt))}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box component="article">
          <Box component="header" mb={2}>
            <Typography
              variant="body2"
              p="16px 0"
              color="#142237"
              fontWeight={400}
              textAlign={'justify'}
            >
              {removeHashtagWords(post.content)}
            </Typography>
            <Typography
              variant="body2"
              mt={2}
              color="primary"
              sx={{ fontWeight: 'bold' }}
            >
              {post.tags.length > 0 && post.tags.map(tag => `#${tag} `)}
            </Typography>
          </Box>
          <Box component="figure" width={'100%'}>
            <QuiltedImageList itemData={filesArray || []} />
          </Box>
        </Box>
      </Container>
    );
  else return null;
};

export default RepostedPost;

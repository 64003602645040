import { CompanyProductDefaultNameEnum } from '../../enums/subscription-enum';

export const getIncludedFeaturesHelper = (planName: string) => {
  switch (planName) {
    case CompanyProductDefaultNameEnum.BASIC_COMPANY_PLAN:
      return [
        'Access athlete profiles and resumes',
        'Ability to post job openings on the platform',
        'Limited number of candidate introductions per month',
      ];
    case CompanyProductDefaultNameEnum.PRO_COMPANY_PLAN:
      return [
        'All Basic package features',
        'Dedicated talent advisor to help identify and screen suitable candidates',
        'Ability to sponsor educational content and courses',
        'Enhanced candidate matching and recommendation algorithms',
        'Increased number of candidate introductions per month',
      ];
    case CompanyProductDefaultNameEnum.ADVANCED_COMPANY_PLAN:
      return [
        'All Advanced package features',
        'Exclusive access to top-tier athlete talent',
        'Customized recruitment and onboarding support',
        'Detailed performance analytics and reporting',
        'Ability to co-develop specialized training programs',
        'Unlimited candidate introductions',
      ];
    default:
      return [];
  }
};
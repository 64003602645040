import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Divider,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';

import ConnectionsList from './ConnectionList/ConnectionsList';
import {
  useGetAllMySuggestionsQuery,
  useGetFriendsByUserIdQuery,
} from '../../../../store/apiSlice/friendApi';
import {
  useGetAllInvitationsForMeQuery,
  useGetAllMyInvitationsQuery,
} from '../../../../store/apiSlice/friendInvitationApi';
import { IFriend } from '../../../../interfaces/friend.interface';
import { FriendRequest } from '../../../../interfaces/friend-invitation.interface';
import { useSearchParams } from 'react-router-dom';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import ListToolBar from '../../../../components/ListToolbar/ListToolBar';
import SkeletonList from '../../../../components/MUIComponents/SkeletonList';

const connectionsTabsLabels = [
  'My connections',
  'Outgoing requests',
  'Incoming requests',
  'Suggestions',
];

const sortMenuItems = [
  { label: 'Recently Added', value: 'desc' },
  { label: 'Oldest', value: 'asc' },
];

const sortMenuLabel = 'Sort by:';

const LIMIT = 10;

const ConnectionsTabs = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState<number>(
    parseInt(searchParams.get('tab') || '0', 10)
  );
  const [query, setQuery] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('desc');
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalConnectionsCount, setTotalConnectionsCount] = useState<number>(0);
  const [connections, setConnections] = useState<IFriend[] | FriendRequest[]>(
    []
  );

  useEffect(() => {
    setQuery(searchParams.get('query') || '');
    setSortBy(searchParams.get('sortBy') || 'desc');
  }, [searchParams]);

  useEffect(() => {
    setConnections([]);
    setPage(1);
  }, [query, sortBy, activeTab]);

  useEffect(() => {
    setSearchParams({
      query: '',
      sortBy: '',
      tab: activeTab.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastConnectionElementRef = useCallback(
    (node: HTMLElement) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (
          entries[0].isIntersecting &&
          hasMore &&
          (!isLoading ||
            !friendsLoading ||
            !outgoingLoading ||
            !incomingLoading ||
            !suggestionsLoading)
        ) {
          setPage(prevPage => prevPage + 1);
          setIsLoading(true);
        }
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore]
  );

  const queryParams = {
    page,
    limit: LIMIT,
    search: query,
    sort: sortBy,
  };

  const {
    data: friendsData,
    error: friendsError,
    isLoading: friendsLoading,
  } = useGetFriendsByUserIdQuery(queryParams, {
    skip: activeTab !== 0,
    refetchOnFocus: activeTab === 0,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: outgoingData,
    error: outgoingError,
    isLoading: outgoingLoading,
  } = useGetAllMyInvitationsQuery(queryParams, {
    skip: activeTab !== 1,
    refetchOnFocus: activeTab === 1,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: incomingData,
    error: incomingError,
    isLoading: incomingLoading,
  } = useGetAllInvitationsForMeQuery(queryParams, {
    skip: activeTab !== 2,
    refetchOnFocus: activeTab === 2,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: suggestionsData,
    error: suggestionsError,
    isLoading: suggestionsLoading,
  } = useGetAllMySuggestionsQuery(queryParams, {
    skip: activeTab !== 3,
    refetchOnFocus: activeTab === 3,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    let newConnections: any[] = [];
    let totalCount = 0;
    let totalPages = 0;

    switch (activeTab) {
      case 0:
        newConnections = friendsData?.friends || [];
        totalCount = friendsData?.pagination.totalCount || 0;
        totalPages = friendsData?.pagination.totalPages || 0;

        break;
      case 1:
        newConnections = outgoingData?.data || [];
        totalCount = outgoingData?.pagination.total || 0;
        totalPages = outgoingData?.pagination.totalPages || 0;

        break;
      case 2:
        newConnections = incomingData?.data || [];
        totalCount = incomingData?.pagination.total || 0;
        totalPages = incomingData?.pagination.totalPages || 0;

        break;
      case 3:
        newConnections = suggestionsData?.suggestions || [];
        totalCount = suggestionsData?.pagination.totalCount || 0;
        totalPages = suggestionsData?.pagination.totalPages || 0;

        break;
    }

    if (page === 1) {
      setConnections(newConnections);
    } else {
      setConnections(prevConnections => [
        ...prevConnections,
        ...newConnections,
      ]);
    }

    const hasNext = totalPages !== 0 && page !== totalPages;

    setTotalConnectionsCount(totalCount);
    setIsLoading(false);
    setHasMore(hasNext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendsData, outgoingData, incomingData, suggestionsData, activeTab]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setConnections([]);
    setSearchParams({
      query: '',
      sortBy: '',
      page: '1',
      tab: newValue.toString(),
    });

    setActiveTab(newValue);
  };

  const totalCountWithTextContent = (activeTab: number) => {
    if (activeTab === 0)
      return `${totalConnectionsCount > 0 ? totalConnectionsCount : 'No'} connection${
        totalConnectionsCount === 1 ? '' : 's'
      }`;
    if (activeTab === 1)
      return `${totalConnectionsCount > 0 ? totalConnectionsCount : 'No'} outgoing request${
        totalConnectionsCount === 1 ? '' : 's'
      }`;
    if (activeTab === 2)
      return `${totalConnectionsCount > 0 ? totalConnectionsCount : 'No'} incoming request${
        totalConnectionsCount === 1 ? '' : 's'
      }`;
    if (activeTab === 3) return 'People you may know';
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs"
        sx={{
          bgcolor: 'background.light',
          width: `${matches ? '100%' : '94vw'}`,
          maxWidth: 'fit-content',
          borderRadius: '1px',
        }}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        {connectionsTabsLabels.map((label, index) => (
          <Tab
            key={index}
            label={label}
            sx={{
              color: 'text.primary',
              fontSize: '10px',
              fontWeight: 800,
              padding: '16px 22px',
              '&.Mui-selected': {
                bgcolor: 'primary.main',
                color: 'background.white',
              },
            }}
          />
        ))}
      </Tabs>
      <Box p={'50px 0'}>
        <ListToolBar
          subTitle={totalCountWithTextContent(activeTab) as string}
          isSortShown={totalConnectionsCount > 0 && activeTab === 0}
          isSearchShown={totalConnectionsCount > 0 && activeTab !== 3}
          sortMenuLabel={sortMenuLabel}
          sortMenuItems={sortMenuItems}
          sx={{ mb: 3 }}
        />

        <Divider sx={{ bgcolor: 'background.darker' }} />

        <ConnectionsList
          key={activeTab}
          connections={connections}
          activeTab={activeTab}
          setConnections={setConnections}
          setPage={setPage}
        />
        {connections.length > 0 &&
          hasMore &&
          (!isLoading ||
            !friendsLoading ||
            !outgoingLoading ||
            !incomingLoading ||
            !suggestionsLoading) && <Box ref={lastConnectionElementRef}></Box>}
        {(isLoading ||
          friendsLoading ||
          outgoingLoading ||
          incomingLoading ||
          suggestionsLoading) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <SkeletonList
              count={3}
              sx={{
                minHeight: '60px',
              }}
            />
          </Box>
        )}
        {(friendsError ||
          outgoingError ||
          incomingError ||
          suggestionsError) && (
          <Typography color="error">Failed to load connections.</Typography>
        )}
      </Box>
    </>
  );
};

export default ConnectionsTabs;

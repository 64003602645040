import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}
function DateRangeIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#727F93"
      viewBox="0 0 24 24"
      className={cn({ [className!]: className !== undefined })}
    >
      <g strokeWidth="1.5">
        <path strokeLinecap="square" d="M20 5H4v16h16V5z"></path>
        <path strokeLinecap="square" strokeLinejoin="round" d="M16 3v4"></path>
        <path strokeLinecap="square" strokeLinejoin="round" d="M8 3v4"></path>
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 11h16"></path>
      </g>
    </svg>
  );
}

export default DateRangeIcon;

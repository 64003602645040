import { SvgIcon, SvgIconProps } from '@mui/material';

const SendConnectionMessageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 24, height: 24, fill: 'none' }}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M21 18V4H3V18H8V21L13 18H21Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default SendConnectionMessageIcon;

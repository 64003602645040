import { Box, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';
import WelcomeToPlatform from '../../components/Auth/VerifyAccount/WelcomeToPlatform/WelcomeToPlatform';

const PersonalizedCareerModalPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      pt={'1rem'}
      display={'flex'}
      justifyContent={'center'}
      width={'100%'}
      height={'100dvh'}
    >
      <Logo />
      <Modal
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            alignItems: 'flex-end',
          },
        })}
        open={true}
      >
        <WelcomeToPlatform
          title="Welcome to your personalized career development journey!"
          text="Answer a few questions to connect with a dedicated agent who will help you achieve your career goals."
          textLastLine=""
          buttonText="start questionnaire"
          onProceed={() =>
            navigate('/career-questionnaire', {
              replace: true,
            })
          }
          onClose={() => navigate('/', { replace: true })}
        />
      </Modal>
    </Box>
  );
};

export default PersonalizedCareerModalPage;

import { SvgIcon, SvgIconProps } from '@mui/material';

const RemoveConnectionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      sx={{ width: 18, height: 18, fill: 'none' }}
      {...props}
    >
      <path
        d="M3 5.25H15"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M3.85693 5.78571L4.82112 15.75H13.1783L14.1426 5.78571"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M6.75 5.25V2.25H11.25V5.25"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M7.5 9L10.5 12M10.5 9L7.5 12"
        stroke="#142237"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default RemoveConnectionIcon;

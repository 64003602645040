import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { IAppliedUser } from '../../../interfaces/jobpost.interface';
import React from 'react';
import { useGetUserInfoQuery } from '../../../store/apiSlice/userApi';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { useModalManager } from '../../../hooks/useModalManager';
import ApplicationInfoModal from './modals/ApplicationInfoModal';
import RejectionInfoModal from './modals/RejectApplicationInfoModal';
import { applicationRequestStatusHelper } from '../../../utils/helper/status-helper';

interface IApplicationItemProps {
  application: IAppliedUser;
  sectionStyles: SxProps;
  refetchJobpostData: () => void;
  refetchApplications: () => void;
}

const ApplicationItem = ({
  application,
  sectionStyles,
  refetchJobpostData,
  refetchApplications,
}: IApplicationItemProps) => {
  const { data: userData } = useGetUserInfoQuery(application.userId);

  const { currentModal, toggleModal } = useModalManager();

  return (
    <>
      {currentModal === 'APPLICATION_INFO' && userData && (
        <ApplicationInfoModal
          isOpen
          onClose={() => {
            toggleModal('APPLICATION_INFO');
            refetchJobpostData();
            refetchApplications();
          }}
          onReject={() => toggleModal('REJECTION_INFO')}
          applicationId={application.id as string}
          userData={userData}
          application={application}
        />
      )}
      {currentModal === 'REJECTION_INFO' && userData && (
        <RejectionInfoModal
          isOpen={true}
          onClose={() => {
            toggleModal('REJECTION_INFO');
            refetchJobpostData();
            refetchApplications();
          }}
          applicationId={application.id as string}
          userData={userData}
        />
      )}
      <ListItem
        sx={{
          ...sectionStyles,
          bgcolor: application.isViewed ? 'inherit' : 'background.light',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '22px',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          flexWrap={'wrap'}
        >
          <Box
            component={Link}
            to={`/${application.userId}`}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'row'}
            gap={'16px'}
            flex={1}
            maxWidth={'100%'}
          >
            <ListItemAvatar
              sx={{
                width: '64px',
                height: '64px',
                minWidth: '64px',
                minHeight: '64px',
              }}
            >
              <Avatar
                alt={`${userData?.firstName || ''}  ${userData?.lastName || ''}`}
                src={
                  userData && userData?.optimizedUserAvatarBaseUrl
                    ? `data:image/jpeg;base64,${userData.optimizedUserAvatarBaseUrl}`
                    : ''
                }
                sx={{ width: '100%', height: '100%' }}
              />
            </ListItemAvatar>
            <ListItemText
              key={application.userId}
              sx={{
                color: 'text.primary',
                maxWidth: '350px',
              }}
              primary={`${userData?.firstName || ''}  ${userData?.lastName || ''}`}
              primaryTypographyProps={{
                sx: {
                  fontSize: {
                    xs: '14px',
                    md: '14px',
                    lg: '18px',
                  },
                },
              }}
              secondaryTypographyProps={{
                sx: {
                  textAlign: 'left',
                  lineHeight: '15px',
                  fontSize: '14px',
                },
              }}
              secondary={
                userData &&
                userData.sports &&
                userData.sports.length > 0 &&
                userData.sports.map((sport, idx, arr) => (
                  <React.Fragment key={sport.id}>
                    <span>{sport.name}</span>
                    {idx < arr.length - 1 && ', '}
                  </React.Fragment>
                ))
              }
            />
          </Box>
          <Box
            borderRadius="20px"
            border={`1px solid ${applicationRequestStatusHelper(application).color}`}
            color={applicationRequestStatusHelper(application).color}
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="6px 12px"
            marginRight={'16px'}
            width="fit-content"
          >
            <Typography variant="body4" textTransform="uppercase">
              {applicationRequestStatusHelper(application).message}
            </Typography>
          </Box>
          <CustomButton
            variant="outlined"
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={() => toggleModal('APPLICATION_INFO')}
            sx={{ p: '15px 16px' }}
          >
            view more
          </CustomButton>
        </Stack>
        <Typography
          variant="body3"
          component={'p'}
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textAlign: 'justify',
            width: { xs: '100%', lg: '85%' },
            lineHeight: '20.3px',
          }}
        >
          {application.introduction}
        </Typography>
      </ListItem>
      <Divider component={'li'} />
    </>
  );
};

export default ApplicationItem;

import { SxProps } from '@mui/material';
import HomeLeftBar from '../../../shared-components/leftbar/HomeLeftBar';
import CompanyCard from './CompanyCard';
import { useNavigate } from 'react-router-dom';

interface CompanyLeftBarProps {
  styles?: SxProps;
}

const CompanyLeftBar = ({ styles }: CompanyLeftBarProps) => {
  const navigate = useNavigate();

  return (
    <HomeLeftBar
      styles={styles}
      cardData={<CompanyCard />}
      navigatePhotos={() => navigate(`?tab=${3}`)}
      navigateVideos={() => navigate(`?tab=${4}`)}
    />
  );
};

export default CompanyLeftBar;

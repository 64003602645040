import { Avatar, Box, Popper, Typography } from '@mui/material';
import React, { RefObject, useCallback } from 'react';
import { MessageTypeEnum } from '../../../../enums/message-type-enum';
import { IMessage, IUser } from '../../../../store/apiSlice/chat/chatApi';
import { IMessageAttributes } from './Message';
import HeartIcon from '../../../../components/Icons/HeartIcon';
import { isGroupChat } from '../ChatNavigation/Chat';
import { useSelector } from 'react-redux';
import { getSelectedChat } from '../../../../store/apiSlice/chat/chatSlice';

interface IMessageLikesProps {
  messageRef: RefObject<HTMLDivElement>;
  message: IMessage;
  messageType: MessageTypeEnum;
  open: boolean;
  attributes: IMessageAttributes;
}

const MessageLikes = ({
  messageRef,
  message,
  attributes,
  open,
  messageType,
}: IMessageLikesProps) => {
  const selectedChat = useSelector(getSelectedChat)!;

  const getLikerAvatarSrc = useCallback(
    (likerId: string) => {
      let liker: IUser;

      if (isGroupChat(selectedChat)) {
        liker = selectedChat.members.find(member => member.id === likerId)!;
      } else {
        liker =
          selectedChat.withUserId === likerId
            ? selectedChat.withUser
            : selectedChat.user;
      }

      return liker.thumbnail ? `data:image/png;base64, ${liker.thumbnail}` : '';
    },
    [selectedChat]
  );

  return (
    <Popper
      id={message.id}
      open={open}
      anchorEl={messageRef.current}
      placement={
        messageType === MessageTypeEnum.OWN ? 'bottom-start' : 'bottom-end'
      }
      disablePortal
    >
      <Box
        borderRadius={'3.75rem'}
        display={'flex'}
        alignItems={'center'}
        mt={'2px'}
        p={'2px 5px 2px 2px'}
        gap={'2px'}
        bgcolor={
          messageType === MessageTypeEnum.OWN
            ? 'text.primary'
            : 'background.default'
        }
      >
        <HeartIcon />
        {attributes.likedBy?.length === 1 ? (
          <Box width={'1.5rem'} height={'1.5rem'}>
            <Avatar
              style={{ width: '100%', height: '100%' }}
              src={getLikerAvatarSrc(attributes.likedBy[0])}
              alt=""
            />
          </Box>
        ) : (
          <Typography
            variant="badge3"
            sx={{
              color:
                messageType === MessageTypeEnum.INCOMING
                  ? 'text.primary'
                  : 'background.white',
            }}
          >
            {attributes.likedBy?.length}
          </Typography>
        )}
      </Box>
    </Popper>
  );
};

export default MessageLikes;

import { SvgIcon, SvgIconProps } from '@mui/material';

const FriendNotificationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 14, height: 14, fill: 'none' }}
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M7 2.91669V11.0834"
        stroke="white"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2.91699 7H11.0837"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default FriendNotificationIcon;

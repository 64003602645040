import { SvgIcon, SvgIconProps } from '@mui/material';

const ReplyIcon = ({ ...svgProps }: SvgIconProps) => {
  return (
    <SvgIcon
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 18, height: 18, fill: 'none' }}
      viewBox="0 0 18 18"
    >
      <path
        d="M6.34082 10.5L3.34082 7.5L6.34082 4.5"
        stroke="#727F93"
        strokeLinecap="square"
      />
      <path
        d="M3.75 7.5H12C12.7956 7.5 13.5587 7.81607 14.1213 8.37868C14.6839 8.94129 15 9.70435 15 10.5C15 11.2957 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5H11.25"
        stroke="#727F93"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ReplyIcon;

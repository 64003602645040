import { Box, BoxProps, useMediaQuery } from '@mui/material';
import PersonalInfo from '../../../components/Profile/PersonalInfo';
import ProfileSidebar from '../../../components/Profile/ProfileSidebar/ProfileSidebar';
import SidebarLayout from '../../../layouts/SidebarLayout';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { Photos, Videos } from '../../../components/Media';
import SportBackgroundsSection from '../../../components/Profile/SportBackgroundsSection';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const ProfilePage = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();

  const photosRef = useRef<HTMLDivElement | null>(null);
  const videosRef = useRef<HTMLDivElement | null>(null);

  const mobileProps: BoxProps = mobileView
    ? {
        flexDirection: 'column',
        marginTop: { xs: '-20px', md: '-24px' },
      }
    : {};

  useEffect(() => {
    const scrollToSection = () => {
      if (location.hash === '#photos' && photosRef.current) {
        photosRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (location.hash === '#videos' && videosRef.current) {
        videosRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToSection();
  }, [location]);

  return (
    <SidebarLayout sidebar={<ProfileSidebar />} {...mobileProps}>
      <Box display="flex" flexDirection="column" gap="22px" flexGrow="1">
        <Box id="personal-info">
          <PersonalInfo />
        </Box>
        <Box id="sport-background">
          <SportBackgroundsSection />
        </Box>
        <Box id="photos" ref={photosRef}>
          <Photos />
        </Box>
        <Box id="videos" ref={videosRef}>
          <Videos />
        </Box>
      </Box>
    </SidebarLayout>
  );
};

export default ProfilePage;

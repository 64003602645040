import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}
function DeleteFileRedIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={cn({ [className!]: className !== undefined })}
    >
      <g stroke="#F5431D" strokeLinecap="square" strokeWidth="1.5">
        <path d="M4 7h16"></path>
        <path d="M5.143 7.714L6.428 21h11.143l1.286-13.286"></path>
        <path d="M9 7V3h6v4"></path>
        <path d="M10 12l4 4m0-4l-4 4"></path>
      </g>
    </svg>
  );
}

export default DeleteFileRedIcon;

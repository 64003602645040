import {
  Box,
  CircularProgress,
  CircularProgressProps,
  SxProps,
} from '@mui/material';
import { FC } from 'react';

interface LoaderProps extends CircularProgressProps {
  boxSx?: SxProps;
}

const Loader: FC<LoaderProps> = ({ boxSx, ...props }) => (
  <Box display="flex" justifyContent="center" sx={{ ...boxSx }}>
    <CircularProgress size={200} color="success" {...props} />
  </Box>
);

export default Loader;

import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { CookieEnum } from '../../enums/cookie-enum';
import { useCheckMyCompanySubscriptionExistQuery } from '../../store/apiSlice/subscription/subscriptionApi';
import { Loading } from '../shared/loader/Loader';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../../store/selectors/authSelector';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authData = useSelector(selectAuthData);
  const isCompany =
    authData?.user?.role === 'COMPANY' ||
    Cookies.get(CookieEnum.ROLE) === 'COMPANY';

  const { data: isSubscriptionData, isLoading } =
    useCheckMyCompanySubscriptionExistQuery(null, {
      skip: !isCompany,
    });

  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const token = Cookies.get(CookieEnum.ACCESS_TOKEN);
    const role = Cookies.get(CookieEnum.ROLE);

    if (!token && location.pathname !== '/login') {
      navigate('/login');
      setIsChecking(false);
      return;
    }

    if (!isLoading) {
      if (
        role === 'COMPANY' &&
        (location.pathname !== '/subscription-payment' ||
          location.pathname !== '/subscription-successfully-paid') &&
        !isSubscriptionData?.data
      ) {
        navigate('/subscription-plans');
        setIsChecking(false);
        return;
      }
      setIsChecking(false);
    }
  }, [isLoading, isSubscriptionData, location.pathname, navigate]);

  if (isChecking || isLoading) {
    return <Loading />;
  }

  if (!Cookies.get(CookieEnum.ACCESS_TOKEN)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;

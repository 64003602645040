import * as yup from 'yup';
import { addMonths, addYears, subYears } from 'date-fns';

export const filterSchema = yup.object({
  startDate: yup
    .date()
    .required('Start date is required')
    .min(new Date(2023, 0, 1), 'Start date cannot be earlier than 2023')
    .max(new Date(), 'Start date cannot be in the future')
    .min(subYears(new Date(), 5), 'Start date must be within the last 5 years'),
  endDate: yup
    .date()
    .required('End date is required')
    .min(yup.ref('startDate'), 'End date must be greater than start date')
    .max(
      addMonths(new Date(), 3),
      'End date cannot be more than 3 month in the future'
    )
    .test(
      'within-5-years',
      'End date must be within 5 years from start date',
      function (value) {
        const { startDate } = this.parent;
        return value && startDate && value <= addYears(startDate, 5);
      }
    ),
  numberOfCandidates: yup.boolean().required(),
  numberOfAcceptedCandidates: yup.boolean().required(),
  numberOfJobPosting: yup.boolean().required(),
  numberOfRejectedCandidates: yup.boolean().required(),
});

export const reportSchema = filterSchema.shape({
  reportName: yup
    .string()
    .required('Report name is required')
    .min(3, 'Report name must be at least 3 characters')
    .max(50, 'Report name must be at most 50 characters'),
});

import { SvgIcon, SvgIconProps } from '@mui/material';

interface SubscriptionIconProps extends SvgIconProps {
  active?: boolean;
}

const SubscriptionInfoIcon = ({
  active,
  ...svgProps
}: SubscriptionIconProps) => {
  return (
    <SvgIcon
      sx={{ width: '19px', height: '18px', fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 18"
      {...svgProps}
    >
      <g stroke="#A1ABBA">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.583 9a6.75 6.75 0 1013.5 0 6.75 6.75 0 00-13.5 0z"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.334 6.75h.007"
        ></path>
        <path strokeLinecap="square" d="M8.584 9h.75v3h.75"></path>
      </g>
    </SvgIcon>
  );
};

export default SubscriptionInfoIcon;

import React from 'react';

const SendMessageIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.28516 4.28572L20.9994 12L4.28516 19.7143L6.49944 12L4.28516 4.28572Z"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M6.5 12H21"
        stroke="#727F93"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default SendMessageIcon;

import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import SearchContacts from '../NewPersonalChat/SearchContacts';
import { useGetFriendsByUserIdQuery } from '../../../../store/apiSlice/friendApi';
import { Box, Typography } from '@mui/material';
import SenderInfo from '../ChatHeader/SenderInfo';
import IconWithBackground from '../../../../components/MUIComponents/IconWithBackground';
import CheckIcon from '../../../../components/Icons/CheckIcon';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { ICreateGroupChat } from './NewGroupChat';
import { IUser } from '../../../../store/apiSlice/chat/chatApi';

interface IChatMembersFormProps {
  setFormData: Dispatch<SetStateAction<Partial<ICreateGroupChat>>>;
  setStep: Dispatch<SetStateAction<number>>;
}

const ChatMembersForm = ({ setFormData, setStep }: IChatMembersFormProps) => {
  const { theme } = useThemeContext();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedFriends, setSelectedFriends] = useState<IUser[]>([]);
  const { data: myFriends } = useGetFriendsByUserIdQuery();

  const filteredFriends: IUser[] | undefined = useMemo(
    () =>
      myFriends?.friends
        .filter(friend => {
          const firstName = friend.firstName?.toLocaleLowerCase();
          const lastName = friend.lastName?.toLocaleLowerCase();
          return [firstName, lastName].some(item =>
            item?.includes(searchValue.toLocaleLowerCase())
          );
        })
        .map(friend => ({
          id: friend.userId!,
          firstName: friend.firstName!,
          lastName: friend.lastName!,
          thumbnail: friend.avatarUrl!,
        })),
    [myFriends, searchValue]
  );

  const handleSelectFriend = (friend: IUser) => () => {
    if (selectedFriends.find(user => user.id === friend.id)) {
      setSelectedFriends(prev => prev.filter(user => user.id !== friend.id));
    } else {
      setSelectedFriends(prev => [...prev, friend]);
    }
  };

  const handleSubmitForm = () => {
    setFormData(prev => ({ ...prev, members: selectedFriends }));
    setStep(prev => prev + 1);
  };

  return (
    <>
      <SearchContacts
        onSearch={(e: ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        borderBottom={'1px solid'}
        borderColor={'background.dark'}
        flexGrow={1}
        sx={{ overflow: 'scroll', scrollbarWidth: 'none' }}
      >
        <Box
          p={'1.75rem 1rem'}
          gap={'1.75rem'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Typography variant="body3" fontWeight={700} color={'text.primary'}>
            Suggestions for you
          </Typography>
          <Box gap={'0.625rem'} display={'flex'} flexDirection={'column'}>
            {filteredFriends?.map((friend, index) => (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                onClick={handleSelectFriend(friend)}
                key={index}
                sx={{
                  '&:hover': { bgcolor: 'background.light', cursor: 'pointer' },
                }}
                p={'0.375rem'}
              >
                <SenderInfo
                  firstname={friend.firstName}
                  lastname={friend.lastName}
                  avatar={friend.thumbnail}
                />
                <Box
                  display={
                    selectedFriends.find(user => user.id === friend.id)
                      ? 'block'
                      : 'none'
                  }
                >
                  <IconWithBackground
                    icon={<CheckIcon />}
                    size={'2.5rem'}
                    bgcolor={'stepperConnector.light'}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        p={'1rem'}
        borderTop={`1px solid ${theme.palette.background.dark}`}
      >
        <CustomButton
          type="button"
          variantType={ButtonTypeEnum.SECONDARY}
          buttonWidth={'220px'}
          onClick={() => {
            setStep(prev => prev - 1);
          }}
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={handleSubmitForm}
          buttonWidth={'220px'}
          disabled={selectedFriends.length === 0}
        >
          Create chat
        </CustomButton>
      </Box>
    </>
  );
};

export default ChatMembersForm;

import { SvgIcon, SvgIconProps } from '@mui/material';

const PhotoArrowRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{ width: 24, height: 24, fill: 'none' }}
      {...props}
    >
      <path
        id="Vector"
        d="M9 6L15 12L9 18"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default PhotoArrowRightIcon;

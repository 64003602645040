import { Avatar, Box, IconButton, Input, Typography, useMediaQuery } from "@mui/material";
import SectionLayout from "../../../layouts/SectionLayout";
import { ButtonTypeEnum } from "../../../enums/button-type-enum";
import { userCompanySchema } from "../../../validation/user-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useUpdateCompanyAvatarMutation } from "../../../store/apiSlice/userApi";
import { ChangeEvent, useEffect, useState } from "react";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import { CompanyInfoFormInputs } from "../../../interfaces/user-company.interace";
import CompanyDetails from "../../../pages/company/CompanyRegistrationPage/components/CompanyDetails";
import { useGetMyCompanyQuery, useUpdateCompanyInfoMutation } from "../../../store/apiSlice/userCompanyInfoApi";
import CameraIcon from "../../../components/Icons/CameraIcon";
import CustomButton from "../../../components/MUIComponents/CustomButton";
import { useSelector } from "react-redux";
import { selectAuthData } from "../../../store/selectors/authSelector";
import Label from "../../../components/MUIComponents/Label";
import { LabelTypeEnum } from "../../../enums/label-enum";
import { SnackbarSeverityEnum } from "../../../enums/snackbar-severity-enum";
import SnackbarCustom from "../../../components/MUIComponents/SnackbarCustom";
import { errorHelper } from "../../../utils/helper/error-helper";

const PersonalInfo = () => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const authData = useSelector(selectAuthData);
  const [previewUrl, setPreviewUrl] = useState<string | null>(authData?.user?.avatar?.fileUrl || '');
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { data: getMyCompanyInfoResponseData, error, isLoading: getMyCompanyInfoIsLoading } = useGetMyCompanyQuery();

  const [updateCompanyInfo] = useUpdateCompanyInfoMutation();
  const [updateCompanyAvatar] = useUpdateCompanyAvatarMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const [industryFilterState, setIndustryFilterState] = useState<string>('');

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CompanyInfoFormInputs>({
    resolver: yupResolver<Omit<CompanyInfoFormInputs, 'verified'>>(userCompanySchema),
    defaultValues: {
      companyName: getMyCompanyInfoResponseData?.companyName || '',
      industryIds: getMyCompanyInfoResponseData?.industryIds || [],
      address: getMyCompanyInfoResponseData?.address || '',
      city: getMyCompanyInfoResponseData?.city || '',
      state: getMyCompanyInfoResponseData?.state || '',
      country: getMyCompanyInfoResponseData?.country || '',
      zipCode: getMyCompanyInfoResponseData?.zipCode || '',
      phone: getMyCompanyInfoResponseData?.phone || '',
      contactEmail: getMyCompanyInfoResponseData?.contactEmail || '',
      website: getMyCompanyInfoResponseData?.website
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (!getMyCompanyInfoIsLoading && getMyCompanyInfoResponseData) {
      const formData: CompanyInfoFormInputs = {
        companyName: getMyCompanyInfoResponseData.companyName || '',
        industryIds: getMyCompanyInfoResponseData.industryIds || [],
        address: getMyCompanyInfoResponseData.address || '',
        city: getMyCompanyInfoResponseData.city || '',
        country: getMyCompanyInfoResponseData.country || '',
        state: getMyCompanyInfoResponseData.state || '',
        zipCode: getMyCompanyInfoResponseData.zipCode || '',
        phone: getMyCompanyInfoResponseData.phone || '',
        contactEmail: getMyCompanyInfoResponseData.contactEmail || '',
        website: getMyCompanyInfoResponseData.website || ''
      };
      setIndustryFilterState(getMyCompanyInfoResponseData.industryIds.join(','));
      reset(formData);
    }
  }, [getMyCompanyInfoIsLoading, getMyCompanyInfoResponseData, reset]);

  const onSubmit: SubmitHandler<CompanyInfoFormInputs> = async (data) => {
    const { ...companyData } = data;
    const companyInfoRequest: any = {
      companyName: companyData.companyName || '',
      industryIds: industryFilterState.split(',') || [],
      address: companyData.address || '',
      city: companyData.city || '',
      state: companyData.state || '',
      country: companyData.country || '',
      zipCode: companyData.zipCode || '',
      phone: companyData.phone || '',
      contactEmail: companyData.contactEmail || '',
      website: companyData.website || ''
    };

    try {
      if (!getMyCompanyInfoResponseData?.id) return;
      await updateCompanyInfo({ id: getMyCompanyInfoResponseData?.id, data: companyInfoRequest }).unwrap();
      setSnackbarMessage(
        'Company information has been successfully updated!'
      );
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(
        errorHelper(error)
      );
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
    await uploadImage();
  };

  const uploadImage = async () => {
    const companyAvatarData = new FormData();

    if (selectedImage) {
      companyAvatarData.append('file', selectedImage);
    } else {
      return;
    }

    try {
      await updateCompanyAvatar(companyAvatarData).unwrap();
      setSnackbarMessage(
        'Company information has been successfully updated!'
      );
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  }

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <SectionLayout>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={mobileView ? 'flex-start' : 'center'}
            flexDirection={mobileView ? 'column-reverse' : 'row'}
            gap={mobileView ? '12px' : ''}
          >
            <Typography component='h2' variant='h2'>
              Company information
            </Typography>
            <Label labelType={authData?.user?.verified ? LabelTypeEnum.VERIFIED : LabelTypeEnum.UNDER_VERIFICATION}>
              verified
            </Label>
          </Box>
          <Typography component='h3' variant='h3' mt='48px'>
            Company details
          </Typography>
          <Box mt="40px" mb="36px" display="flex" flexDirection="column">
            <Box
              position="relative"
              display="inline-block"
              width="fit-content"
              sx={{ '&:hover .avatar-overlay': { opacity: 1 } }}
            >
              <Avatar
                alt="Profile"
                src={previewUrl || authData?.user?.avatar?.fileUrl || ''}
                sx={{ width: 140, height: 140 }}
              />
              <Box
                className="avatar-overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                }}
              >
                <IconButton
                  component="label"
                  sx={{
                    width: 60,
                    height: 60,
                    color: 'white',
                    bgcolor: 'text.accent'
                  }}
                >
                  <CameraIcon />
                  <Input
                    type="file"
                    sx={{ display: 'none' }}
                    inputProps={{ accept: 'image/*' }}
                    onChange={handleImageChange}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <CompanyDetails
            setIndustryFilterState={setIndustryFilterState}
            setValue={setValue}
            watch={watch}
            register={register}
            industryFilterState={industryFilterState}
            errors={errors}
            control={control}
          />
          <Box
            display="flex"
            flexDirection={mobileView ? 'column' : 'row'}
            mt="36px"
            gap="22px">
            <CustomButton
              buttonWidth={mobileView ? '100%' : '220px'}
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
            >
              save
            </CustomButton>
          </Box>
        </Box>
      </SectionLayout>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default PersonalInfo;

export enum CancelIntroductionReasonEnum {
  NOT_INTERESTED = 'Not interested',
  FOUND_SOMEONE_ELSE = 'Found someone else',
  TOO_BUSY = 'Too busy',
  CHANGED_MY_MIND = 'Changed my mind',
  SPAM = 'Spam',
  PRIVACY_CONCERNS = 'Privacy concerns',
  NO_COMMON_INTERESTS = 'No common interests',
  FAKE_PROFILE = 'Fake profile',
  UNSURE_ABOUT_SECURITY = 'Unsure about security',
  OTHER = 'Other',
}

import {
  Box,
  BoxProps,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import StepIndicator from './StepIndicator';
import { useMemo } from 'react';
import { colors } from '../../../../../theme/theme';
import CardStatus from './CardStatus';
import { IndividualStage } from '../../../../../interfaces/individual-stage.interface';

export enum CareerDevelopmentCardStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  INACTIVE = 'INACTIVE',
}

interface CareerDevelopmentCardProps extends BoxProps {
  cardData: Omit<IndividualStage, 'status'> & {
    status: CareerDevelopmentCardStatus;
    step: number;
  };
}

const CareerDevelopmentCard = ({
  cardData,
  ...rest
}: CareerDevelopmentCardProps) => {
  const theme = useTheme();
  const {
    cardBgColor,
    stepIndicatorBgColor,
    stepIndicatorBorderColor,
    textColor,
  } = useMemo(() => {
    switch (cardData.status) {
      case CareerDevelopmentCardStatus.COMPLETED: {
        return {
          cardBgColor: theme.palette.background.mint,
          stepIndicatorBgColor: theme.palette.success.main,
          stepIndicatorBorderColor: theme.palette.background.white,
          textColor: colors.white,
        };
      }
      case CareerDevelopmentCardStatus.IN_PROGRESS: {
        return {
          cardBgColor: theme.palette.background.light,
          stepIndicatorBgColor: colors.darkBg,
          stepIndicatorBorderColor: theme.palette.background.lightDark,
          textColor: colors.white,
        };
      }
      default: {
        return {
          cardBgColor: 'unset',
          stepIndicatorBgColor: theme.palette.background.lightDark,
          stepIndicatorBorderColor: theme.palette.background.light,
          textColor: 'unset',
        };
      }
    }
  }, [cardData.status, theme]);

  return (
    <Box
      p="36px"
      bgcolor={cardBgColor}
      border={
        cardData.status === CareerDevelopmentCardStatus.INACTIVE
          ? `1px solid ${theme.palette.background.lightDark}`
          : ''
      }
      {...rest}
    >
      <Grid container rowGap="36px">
        <Grid
          item
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" columnGap="13px" alignItems="center">
            <StepIndicator
              value={cardData.step}
              bgColor={stepIndicatorBgColor}
              textColor={textColor}
              borderColor={stepIndicatorBorderColor}
            />
            <Typography variant="body1">{cardData.title}</Typography>
          </Stack>
          {cardData.status === CareerDevelopmentCardStatus.COMPLETED && (
            <CardStatus textColor={textColor}>complete</CardStatus>
          )}
          {cardData.status === CareerDevelopmentCardStatus.IN_PROGRESS && (
            <CardStatus textColor={textColor} bgColor={colors.bgDark}>
              in progress
            </CardStatus>
          )}
        </Grid>
        <Grid
          item
          container
          color={theme.palette.text.primary}
          flexDirection="column"
          rowGap="28px"
        >
          <Grid item display="flex" flexDirection="column" rowGap="10px">
            <Typography variant="h4" color="inherit">
              Objective:
            </Typography>
            <Typography variant="body3">{cardData.objective}</Typography>
          </Grid>
          <Grid item display="flex" flexDirection="column" rowGap="10px">
            <Typography variant="h4" color="inherit">
              Outcome:
            </Typography>
            <Typography variant="body3">{cardData.outcome}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareerDevelopmentCard;

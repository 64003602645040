import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TIndustry } from '../../../../../interfaces/jobpost.interface';
import { capitalizeAndAddColon } from '../../../../../utils/capitalizeAndAddColon';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';

type ISelectItems = TIndustry[];

interface IJobPostFilterMultiSelect {
  label: string;
  selectItems: ISelectItems;
  placeholder: string;
  handleFilterChange: (industryArray: string[]) => void;
  filterState: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    sx: {
      '& .MuiMenu-list': {
        paddingTop: '0px',
        paddingBottom: '0px',
        maxHeight: '200px',
        overflowY: 'auto',
      },
    },
  },
};

const JobPostFilterMultiSelect: React.FC<IJobPostFilterMultiSelect> = ({
  selectItems,
  label,
  placeholder,
  handleFilterChange,
  filterState,
}) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    if (!filterState.length) {
      setSelectedItems([]);
      return;
    }

    const si = filterState
      ? filterState.split(',')
      : searchParams.get(label)?.split(',') || [];
    setSelectedItems(si);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const {
      target: { value },
    } = event;
    setSelectedItems(value as string[]);
    handleFilterChange(value as string[]);
  };

  const handleDelete = (value: string) => {
    if (!filterState.length) {
      setSelectedItems([]);
      return;
    }
    setSelectedItems(prevSelected =>
      prevSelected.filter(item => item !== value)
    );
    handleFilterChange(selectedItems.filter(item => item !== value));
  };

  const hasProperty = <K extends PropertyKey>(
    obj: object,
    key: K
  ): obj is Record<K, unknown> => {
    return key in obj;
  };

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Typography
        variant="body4"
        sx={{
          padding: '0 2px',
        }}
      >
        {capitalizeAndAddColon(label)}
      </Typography>
      <FormControl sx={{ width: '100%' }}>
        <Select
          IconComponent={props => (
            <ArrowDownIcon
              {...props}
              sx={{
                mr: '15px',
                mt: '4px',
                backgroundColor: 'transparent !important',
              }}
            />
          )}
          multiple={true}
          displayEmpty
          value={selectedItems}
          onChange={handleChange}
          onMouseDown={event => {
            const targetElement = event.target as Element;
            if (targetElement.closest('.MuiChip-root')) {
              event.stopPropagation();
            }
          }}
          sx={{
            '& .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input':
              {
                display: 'flex',
                alignItems: 'center',
                height: 'fit-content',
                minHeight: '50px',
                p: '9px 3px 9px 11px',
                borderRadius: 0,
              },
            '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input':
              {
                alignItems: 'center',
                height: 'fit-content',
                p: '12px 25px 12px 14px',
              },
            '& .MuiSelect-select:focus': {
              outline: 'none',
            },
            '& .MuiBox-root': {
              paddingRight: '40px',
            },
            '& .MuiSelect-select:focusWithin': {
              outline: 'none',
            },
            '& .MuiSelect-icon': {
              color: theme.palette.text.primary,
              backgroundColor: 'transparent',
            },
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 0,
              },
          }}
          input={<OutlinedInput />}
          renderValue={selected => {
            if (selected.length === 0) {
              return placeholder;
            }
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  maxHeight: '104px',
                  overflowY: 'auto',
                }}
              >
                {selected.map(value => {
                  const industry = selectItems.find(item => item.id === value);
                  if (industry && hasProperty(industry, label)) {
                    return (
                      <Chip
                        key={value}
                        label={industry.industry}
                        onMouseDown={event => event.stopPropagation()}
                        onDelete={event => {
                          event.stopPropagation();
                          handleDelete(value);
                        }}
                      />
                    );
                  }
                  return null;
                })}
              </Box>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {selectItems.map(item => (
            <MenuItem
              key={item.id}
              value={item.id}
              sx={{ fontSize: '14px', fontWeight: 400 }}
            >
              {item.industry}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default JobPostFilterMultiSelect;

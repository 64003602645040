import Cookies from 'js-cookie';
import { CookieEnum } from '../enums/cookie-enum';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../store/selectors/authSelector';

export const useFindIsCompany = () => {
  const authData = useSelector(selectAuthData);

  return (
    authData?.user?.role === 'COMPANY' ||
    Cookies.get(CookieEnum.ROLE) === 'COMPANY'
  );
};

import { Divider, Typography } from '@mui/material';

const OtherTab = () => {
  return (
    <>
      <Typography
        component="h2"
        variant="h2"
        mb={'24px'}
        color={'text.primary'}
      >
        Other
      </Typography>
      <Divider sx={{ bgcolor: 'background.darker' }} />
      🤷‍♂️
    </>
  );
};

export default OtherTab;
